const Dummy = () => <></>;

export default Dummy;

export const ColoredChecklistIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    display="inline-block"
    viewBox="0 0 75 75"
  >
    <image
      x="2"
      y="2"
      width="71"
      height="71"
      xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEcAAABHCAMAAABibqotAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAANlBMVEUAAAACl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UAAACwsoPOAAAAEHRSTlMAIEAwcK/Pn+8Q34Bgv49QR48iFwAAAAFiS0dEAIgFHUgAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfmARoUGBpqW6b7AAABtklEQVRYw7VYW7aDIAwMKggi1f2vttdWXoIgdO58VT0dkkyAJERlsOELRt0YJy52D8GnsZ1kmOWeQs5DC4malv0Oy6Sesmi5lyD1I6a1zPJhWqsszFRZDhjWbowwIn1ZNul1WVVvVupx0xe2132AeUSyXsOp1shpfhNuFS445wPA5tBhVaMphDEUIkvknZJbUYvNa8HTr95gU0sz5U1Kgr36yFAdftGL/MzZqh/QEGkXgziSpo0mIDJZr544dXEt8EzJHHkF1gXpZdHpqzrc4jp5szXQ/OXRdfWpw6vQs+l8todo653Azv8t38ehWSsLq9kQPbVfUSyyQPZFJ4iQPH6P+Z3yCDZ/j1PTqtWSOxYqUOw8d0QHDZHw55Bo26AxtDeiK5ctbE577TpqCfIiMZeFXTTOmwHN0yeX25uOpyebD5h/sgcVn595UPmDymfY/uK/CB/sd9T5gzoPUecz7L5A3V+o+xR2v6PqDVT9A6vHUPUhrF6F1c+oej5s4X7qL2D9Dqz/QvWDsP6Ukn5Z9PXLlO/fF5OZdtRGCqB5AqHmGwSbtxBq/vMBZB51So2Yjzn9Hs7r3lbWWOCNTHhOAAAAAElFTkSuQmCC"
    />
    <image
      x="20"
      y="24"
      width="37"
      height="30"
      xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAeCAMAAACc/SdfAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAY1BMVEUAAAACl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UCl5UAAAAP3hN2AAAAH3RSTlMACRzbadxo/VtX6y3xLzcV2tMk5OjI5/ZBQsv6RE5MkBUZKQAAAAFiS0dEAIgFHUgAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfmARoUGBpqW6b7AAAAmElEQVQ4y43Q2RbCMAgEUNw1cWvqXpX//0vb0+aEVkhmHof7ABDlMyMg88USQCvmNYJ4swVQiQ2oZTsAsfMA2h8QdDTR6YygSkE+IMjVAUDMkqkoXLoiMR3VfRWZiugay57piMJNsoTuj9HZ3fKRCfScvFCwl4kky6B/pqIpM9CYmUiyDEosiyJr3kRF1nyoFO8A1LKvMfgBr94l1ziS05UAAAAASUVORK5CYII="
    />
  </svg>
);

export const GrayChecklistIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 75 75"
  >
    <image
      x="2"
      y="2"
      width="71"
      height="71"
      xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEcAAABHCAQAAAD/uZKUAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfmARoUKh6ARjaTAAAD4klEQVRo3tWaLWPbMBCG36Rk0HBQZYWGG4ryC+qwDcWBQ2l/QS9wqCkssgOH4sKhKmzQsKweLDMdG3BcRyc5cfw171Aiy7rHr6SzfNIIjYwEhFaQUNKkvVEtCBcSEwi41ssxEuygKO4chyTm8OBUqpwiwoZUJzjkwMeSdU0VS/CAkNIWccjBDZYVNbHr9IB1FaQKOOTjvgFKgXRLYUMcEgggG6PkprA4PvMuTuiyxVUFNzFekOLjyXoCvnxTcS116B43R590hxiJPp3JhYCL65IQkNmabs/EIQcBvFKQDaLjA5MceJiXdnOEhf3+UUljzyXPF2JVPe6SwB1866UYUxuQBacU5uRALEGyTwYr0NhSMbDApJjRtM77iBKaYgZTCReBWduYWRTgi0WXz3XeQO+3v8hHfDIi+pV01E+9iHUW+RbmkBb1UbQH9Y3ChR4aNXVIYIsP7IZV+bQ8U6MnOTJGkZQ/VFr81cdOYLwMVkTtwAAAEVasyNF740Ad8o2wF7alzLtCSvJVkpC/izj9PnbIwSvTRtG0XZi9p2fWZSku8ylfdNYNg0kx6wIGMKa9U/TKqFSbGUUd4YA8bO365Or4Rkd1BgNQBMX08bMfOc6S3dFKpDlivP3lAQ5JFjHDZp8nFfRJEGoFgmShzpzVXlVosalxH/MCx9MuqK612eujtAJvj0MuG8abHrQx/TjkZupIVi3qCYf7kRnORCuMq36iNTVKEWsFkwxHaIVPPWlj+hIZjqur0yOO7ssFxiRYlaRHHOaLxJgvGZssQs81w5cY12mmO+M4cZ1GGlhyHCcdFo7TM444juMOC+cf29iY+25/zg1fydhYTIgexWC+KBnDEqp7M91XnI2dRCucVG2rBbvW/iUZzk4rlOT0w0IOU2eX4ShWz+tJG+5HZYvTmMXiecXmmpruJ6U4jzuRdkEai44OjARbFEdAjsMX63c9aMN9bIDiG/2VxYDLbj9uSOBVK0joslAHeGD1gwptNjHe/t5/jhOy4SzJ61Abz8jwhBoOpaY+XcUfckxtzHTT2kgCbdGNbY3E1jr/+Y5DKXgeUFIHI4jMHPxt8aF5kKpUseSJFVeOlGoVhox0qDpMh+rLr4WxVr5rOZHLo02qJ560NLdK5ZvxJpFSqFY+lCmw7I9903eSjR0b66aawqxZIoEcbC37NsZGm20DaWt5p6dY1E9ekmfJ5wMRGanige1nDX+3bw80nL3QfZPHd4pjPPW2U7xvvOr5ggQJRKVPohNnDQZ2ymBgZzAuTrejYvmIP3CNbclzUL7jK/06XfF/PL9zADWU000a1FDOfhlgLZ+M+wvPmlZjfm0rCgAAAABJRU5ErkJggg=="
    />
    <image
      x="20"
      y="24"
      width="37"
      height="30"
      xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAeCAQAAAABKh/mAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfmARoUKh6ARjaTAAABRUlEQVRIx63UsUvDQBTH8W/i5KKLxaHZjJvZnOVwcKgdOnQS/5j3x/gXOEu4QYeCSwk42bGoIAVdHBRxSIxJetfcJb4pOciHd4+8H/Qq2f573uoFRczUl77vTUmEJuZcveRYZ0oiNAcAjNRCZ52pCgQBE7XQWdAbyuubi/BfIFjx4N2VEXrnROaeXVmgM5mDV1cy4I5DAzQD8OhKBqR2qPIzSKJ29KoFOrJDZVeSkKIl7g4Vs5KElD1giZLHbhAEIDG37BfvBswNglBidAnBsHlNVwhCrhjWTmqYOwQhlzw3zkrMCH0wMUH5rH6HXq0lijcjNJYULJQVe+LYHSoXx4jhA1V2sBVrgWrrvBFrhRrJYMUcoLWQMWJOkCGv1jBHyBh9NeyTkdy4QMbok4xTXgto6gpZA1kSUnaZyrUrtKEkkbHfFz9yzoyAAWJizAAAAABJRU5ErkJggg=="
    />
  </svg>
);
