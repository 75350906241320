import {
  Box,
  Breadcrumb,
  Flex,
  Heading,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import styles from "./styles.module.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import ReviewList from "./reviewList";
import ReviewOverview from "./reviewOverview";
import Axios from "../../../services/axios";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import navguard from "../../auth/navguard";

const ProductReviewsDetail = () => {
  const { slug } = useParams();
  const [product, setProduct] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [showHidden, setShowHidden] = useState(false);

  const fetchProductBySlug = async () => {
    const mainUrl = "product";
    try {
      const { data } = await Axios({
        url: `/${mainUrl}/${slug}`,
        method: "GET",
      });

      setProduct(data.data.product);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProductBySlug();
  }, [slug]);

  return (
    <>
      <Breadcrumb title="Product Review" parent="Products" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Flex direction="row">
                  <Box>
                    <Text
                      fontSize="2xl"
                      style={{
                        textDecorationColor: "black",
                        color: "black",
                      }}
                    >
                      Product Review of <b>{product.name}</b>
                    </Text>

                    <Heading size={"sm"} as="h4" mt={3} mb={3}>
                      {product.productCategory}
                    </Heading>
                  </Box>
                  <Spacer />
                  <Box flex={1} alignSelf="center">
                    <ReviewOverview />
                  </Box>{" "}
                </Flex>
                <Tabs
                  value={tabIndex}
                  variant="soft-rounded"
                  colorScheme="teal"
                  onChange={(index) => setTabIndex(index)}
                >
                  <Flex>
                    <TabList>
                      <Tab>Need Review</Tab>
                      <Tab>Reviewed</Tab>
                    </TabList>
                    <Spacer />
                    {tabIndex === 1 && (
                      <Flex
                        className={styles["hide-button"]}
                        p={[1, 2]}
                        direction="row"
                        onClick={() => setShowHidden(!showHidden)}
                      >
                        <Box>
                          {showHidden ? (
                            <CheckIcon size="md" color="green.700" />
                          ) : (
                            <CloseIcon size="md" color="#555555" />
                          )}
                        </Box>
                        <Box ml={2}>
                          <Text>
                            {showHidden
                              ? "Show hidden reviews"
                              : "Don't show hidden reviews"}
                          </Text>
                        </Box>
                      </Flex>
                    )}
                  </Flex>
                  <TabPanels>
                    <TabPanel>
                      <ReviewList
                        panelNum={tabIndex}
                        showHidden={showHidden}
                      />
                    </TabPanel>
                    <TabPanel>
                      <ReviewList
                        panelNum={tabIndex}
                        showHidden={showHidden}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default navguard(ProductReviewsDetail);
