import { Box, Checkbox, Flex, Select, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import FilterDate from "./dateFilter";
import { isEmpty } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserBadgeProgram,
  getUserCommunity,
} from "../../../store/actions/userAction";
import { fetchResellerLevel } from "../../../store/actions/resellerLevelAction";

const AllFilters = () => {
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const communities = useSelector(({ users }) => users.community.communities);
  const { badgeProgram } = useSelector(({ users }) => users);
  const [filter, setFilter] = useState({
    sort: "createdAt,desc",
    status: "",
    program: "",
    community: "",
    verification: "",
    uncompletedData: "",
    resellerAccount: "",
  });
  const currentQuery = queryString.parse(search);
  const { resellerLevelList } = useSelector((state) => state.resellerLevel);

  useEffect(() => {
    getUserCommunity(dispatch);
    fetchUserBadgeProgram(dispatch, "userList");
    dispatch(fetchResellerLevel());
  }, [dispatch]);

  const filterByStatus = (value) => {
    setFilter({ ...filter, status: value });
    let queries = "";
    queries = queryString.stringify(
      {
        ...currentQuery,
        filter_reseller_status: value,
      },
      {
        skipEmptyString: true,
      }
    );

    history.push(`/users/all-user?${queries}`);
  };

  const filterByProgram = (value) => {
    setFilter({ ...filter, program: value });
    let queries = "";
    queries = queryString.stringify(
      {
        ...currentQuery,
        filter_reseller_program: value,
      },
      {
        skipEmptyString: true,
      }
    );

    history.push(`/users/all-user?${queries}`);
  };

  // const filterByCommunity = (value) => {
  //   setFilter({ ...filter, community: value });
  //   let queries = "";
  //   queries = queryString.stringify(
  //     {
  //       ...currentQuery,
  //       filter_community_id: value,
  //     },
  //     {
  //       skipEmptyString: true,
  //     }
  //   );
  //   history.push(`/users/all-user?${queries}`);
  // };

  const filterByVerification = (value) => {
    setFilter({ ...filter, verification: value });
    const queries = queryString.stringify(
      {
        ...currentQuery,
        filter_personal_verification_status: value,
      },
      {
        skipEmptyString: true,
      }
    );
    history.push(`/users/all-user?${queries}`);
  };

  const handleSortChange = (value) => {
    setFilter({ ...filter, sort: value });
    const queries = queryString.stringify(
      {
        ...currentQuery,
        sort: value,
      },
      {
        skipEmptyString: true,
      }
    );
    history.push(`/users/all-user?${queries}`);
  };

  const filterByResellerAccount = (value) => {
    setFilter({ ...filter, resellerAccount: value });
    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify(
      {
        ...currentQuery,
        filter_verification_status: value,
      },
      {
        skipEmptyString: true,
      }
    );
    history.push(`/users/all-user?${queries}`);
  };

  useEffect(() => {
    let tempFilter = { ...filter };
    if (currentQuery.sort) {
      tempFilter.sort = currentQuery.sort;
    }
    if (currentQuery.filter_reseller_status) {
      tempFilter.status = currentQuery.filter_reseller_status;
    }
    if (currentQuery.filter_reseller_program) {
      tempFilter.program = currentQuery.filter_reseller_program;
    }
    if (currentQuery.filter_community_id) {
      tempFilter.community = currentQuery.filter_community_id;
    }
    if (currentQuery.filter_personal_verification_status) {
      tempFilter.verification =
        currentQuery.filter_personal_verification_status;
    }
    if (currentQuery.only_show_uncompleted_data) {
      tempFilter.only_show_uncompleted_data =
        currentQuery.only_show_uncompleted_data;
    }
    if (currentQuery.filter_verification_status) {
      tempFilter.resellerAccount = currentQuery.filter_verification_status;
    }

    setFilter(tempFilter);
  }, [search]);

  return (
    <Flex>
      <Box flex={1} p={3}>
        <Flex m={1}>
          <Box flex={1}>
            <Text>Filter Registered Date: </Text>
          </Box>
          <Box flex={3}>
            <FilterDate />
          </Box>
        </Flex>
        <Flex m={1}>
          <Text flex={1}>Filter by Status: </Text>
          <Select
            onChange={(e) => filterByStatus(e.target.value)}
            flex={3}
            value={filter.status}>
            <option selected value="">
              All
            </option>
            {resellerLevelList.map((data) => (
              <option value={data?.resellerLevel} id={data.id}>
                {data.resellerLevel}
              </option>
            ))}
          </Select>
        </Flex>
        {/* <Flex m={1}>
          <Text flex={1}>Filter by Program: </Text>
          <Select
            onChange={(e) => filterByProgram(e.target.value)}
            flex={3}
            value={filter.program}>
            <option selected value="">
              All
            </option>
            <option value="no program">No Program</option>
            {badgeProgram?.map((item, index) => (
              <option value={item.name} key={index}>
                {item.name}
              </option>
            ))}
          </Select>
        </Flex> */}
        {/* <Flex m={1}>
          <Text flex={1}>Filter by Community: </Text>
          <Select
            onChange={(e) => filterByCommunity(e.target.value)}
            flex={3}
            value={filter.community}>
            <option selected value="">
              All
            </option>
            <option value="no community">No Community</option>
            {!isEmpty(communities) &&
              communities.map((each, index) => (
                <option key={index} value={each.id}>
                  {each.name}
                </option>
              ))}
          </Select>
        </Flex> */}
      </Box>
      <Flex
        direction="column"
        flex={1}
        alignContent="right"
        justifyContent="flex-start"
        p={3}>
        <Flex>
          <Text flex={1}>Sort by: </Text>
          <Select
            flex={3}
            placeholder="Select option"
            onChange={(e) => handleSortChange(e.target.value)}
            value={filter.sort}>
            <option value="createdAt,desc">Terbaru</option>
            <option value="createdAt,asc">Terlama</option>
            <option value="firstName,asc">A - Z</option>
            <option value="firstName,desc">Z - A</option>
          </Select>
        </Flex>
        <Flex mt={1}>
          <Text flex={1}>Filter by Personal Data Review</Text>
          <Select
            flex={3}
            onChange={(e) => filterByVerification(e.target.value)}
            value={filter.verification}>
            <option value="">All</option>
            <option value="need review">Need Review</option>
            <option value="verified">Verified</option>
            <option value="rejected">Rejected</option>
            <option value="not completed">Not Submitted</option>
          </Select>
        </Flex>
        <Flex mt={1}>
          <Text flex={1}>Filter by Account Verification</Text>
          <Select
            flex={3}
            onChange={(e) => filterByResellerAccount(e.target.value)}
            value={filter.resellerAccount}>
            <option value="">All</option>
            <option value="verified">Verified</option>
            <option value="not verified">Not Verified</option>
          </Select>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AllFilters;
