import { AddIcon, ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CloseButton,
  Flex,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spacer,
  Textarea,
  useDisclosure,
  useToast,
  Heading,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { Card, CardBody, Col, ModalHeader, Row } from "reactstrap";

const SectionImportantInformation = ({
  product,
  setProduct,
}) => {
  const toast = useToast();
  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();
  const [addImportantInfo, setAddImportantInfo] = useState("");

  const submitAddImportantInfo = () => {
    let tempProduct = { ...product };
    let arr = [...product.pdpImportantInformation.texts];

    if (!isEmpty(addImportantInfo)) {
      arr.push(addImportantInfo);

      tempProduct.pdpImportantInformation.texts = arr;
      setAddImportantInfo("");
      setProduct(tempProduct);
      onCloseAddModal();
    } else {
      toast({
        title: "Error.",
        description: "Mohon isi informasi yang ingin ditambahkan",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const moveArray = (fromIndex, toIndex) => {
    let tempProduct = { ...product };
    let arr = [...product.pdpImportantInformation.texts];
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    tempProduct.pdpImportantInformation.texts = arr;

    setProduct(tempProduct);
  };

  const deleteArray = (delIndex) => {
    let tempProduct = { ...product };
    let arr = [...product.pdpImportantInformation.texts];
    tempProduct.pdpImportantInformation.texts = arr.filter(
      (_, idx) => delIndex !== idx
    );
    setProduct(tempProduct);
  };

  const inputHandler = async (target, index) => {
    let tempProduct = { ...product };
    let { value } = target;
    tempProduct.pdpImportantInformation.texts[index] = value;
    setProduct(tempProduct);
  };

  return (
    <Card>
      <Modal isOpen={isOpenAddModal} onClose={onCloseAddModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Tambah Informasi Penting</ModalHeader>
          <Box m={4}>
            <Textarea onChange={(e) => setAddImportantInfo(e.target.value)} />
          </Box>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onCloseAddModal}>
              Close
            </Button>
            <Button
              variant="ghost"
              justifyContent="flex-start"
              backgroundColor={"#FF8084"}
              color={"white"}
              onClick={submitAddImportantInfo}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <CardBody>
        <Row>
          <Col xl={12}>
            <Box width="100%" borderBottomWidth="1px">
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "black",
                }}
              >
                Informasi Penting
              </p>
            </Box>
          </Col>
          <Col xl={12}>
            <Flex
              style={{
                marginTop: "16px",
              }}
            >
              <Heading as="h5" size="sm" mb="0.5rem">
                Texts
              </Heading>
              <Spacer />
              <IconButton
                icon={<AddIcon />}
                variant="ghost"
                onClick={onOpenAddModal}
              />
            </Flex>
            <Box>
              {product?.pdpImportantInformation?.texts?.map((item, i) => (
                <Flex borderRadius="md" borderWidth="1px" key={i} mt={1} mb={1}>
                  <Box flex={1} margin={2} mt={4}>
                    <Textarea
                      name="text"
                      value={item}
                      onChange={(e) => inputHandler(e.target, i)}
                    />
                  </Box>
                  <Flex flexDirection="column">
                    <IconButton
                      isDisabled={i === 0}
                      margin={3}
                      colorScheme="teal"
                      aria-label="Call Segun"
                      size="sm"
                      onClick={() => moveArray(i, i - 1)}
                      icon={<ChevronUpIcon />}
                    />
                    <IconButton
                      isDisabled={
                        i ===
                        product?.pdpImportantInformation?.texts?.length - 1
                      }
                      margin={3}
                      colorScheme="teal"
                      aria-label="Call Segun"
                      size="sm"
                      onClick={() => moveArray(i, i + 1)}
                      icon={<ChevronDownIcon />}
                    />
                  </Flex>
                  <CloseButton
                    style={{
                      height: "32px",
                      width: "32px",
                    }}
                    mt={3}
                    mr={3}
                    size="sm"
                    backgroundColor="#ff605c"
                    color="white"
                    onClick={() => deleteArray(i)}
                  />
                </Flex>
              ))}
            </Box>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SectionImportantInformation;
