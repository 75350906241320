import React, { useState } from "react";

import '../styles/_homeBanner.scss';
import {
    Flex,
    Spacer,
    IconButton,
    Button,
    Modal,
    ModalFooter,
    ModalBody,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalContent,
} from "@chakra-ui/react";
import {
    FaMobileAlt,
    FaDesktop,
} from "react-icons/fa";

import Slider from "react-slick";

const FullPreviewModal = ({ isPreview, getActiveBanner, previewClose }) => {
    const [fullPreviewMode, setFullPreviewMode] = useState("Desktop");

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
    };

    const changeFullPreview = (mode) => {
        setFullPreviewMode(mode);
    }
    
	return (
        <Modal onClose={previewClose} size="full" isOpen={isPreview}>
            <ModalOverlay />
            <ModalContent>
            <Flex>
                <ModalHeader flex="2">Preview Banner</ModalHeader>
                <Spacer />
                <IconButton
                    mr={4}
                    mt={2}
                    variant="outline"
                    aria-label="Call Sage"
                    fontSize="20px"
                    onClick={() => changeFullPreview("Desktop")}
                    icon={<FaDesktop />}
                />
                <IconButton
                    mr={16}
                    mt={2}
                    variant="outline"
                    aria-label="Call Sage"
                    fontSize="20px"
                    onClick={() => changeFullPreview("Mobile")}
                    icon={<FaMobileAlt />}
                />
                <ModalCloseButton />
            </Flex>
            {fullPreviewMode === "Desktop" ?                 
                <section className="p-0">
                    <Slider {...settings} className="qbee-home-slider">
                        {getActiveBanner().map((banner, id) => (
                            <div key={id}>
                                <img
                                    src={banner.imagePath}
                                    alt=""
                                    style={{
                                        aspectRatio: `21 / 9`,
                                        objectFit: 'cover',
                                        width: '100%',
                                    }}
                                    className="img-fluid image_zoom_1 blur-up lazyloaded"
                                />
                            </div>
                        ))}
                    </Slider>
                </section>
            :                
                <ModalBody
                    style={{
                        width: '30vw',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    <section className="p-0">
                        <Slider {...settings} className="qbee-home-slider">
                            {getActiveBanner().map((banner, id) => (
                                <div key={id}>
                                    <img
                                        src={banner.imageMobile}
                                        alt=""
                                        style={{
                                            aspectRatio: `1 / 1`,
                                            objectFit: 'cover',
                                            width: '100%',
                                        }}
                                        className="img-fluid image_zoom_1 blur-up lazyloaded"
                                    />
                                </div>
                            ))}
                        </Slider>
                    </section>
                </ModalBody>         
            }
            <ModalFooter>
                <Button onClick={previewClose}>Close</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
	);
};

export default FullPreviewModal;
