import {
  Box,
  Center,
  Divider,
  Flex,
  Input,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import "./styles/ListAdmin.css";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import Pagination from "../order/components/pagination";
import AddAdmin from "./components/AddAdmin";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Axios from "../../services/axios";
import { FcLock } from "react-icons/fc";

function ListAdmin(props) {
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState();
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { search } = useLocation();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  /// Field Data Add/Edit
  const [inputAdmin, setInputAdmin] = useState({
    username: "",
    email: "",
    adminRoleId: "",
    workingArea: "",
    password: "",
    isActive: true,
    warehouseId: "",
    isLdap: false,
    isSuspended: false,
    suspensionType: "",
    suspendOption: "false",
  });
  const [emailUser, setEmailUser] = useState("");
  const [submitType, setSubmitType] = useState("");
  const fetchData = async () => {
    try {
      const currentQuery = queryString.parse(search);
      const queries = queryString.stringify({
        ...currentQuery,
        limit: 10,
        page: page + 1,
        sort: "username,asc",
      });

      const { data } = await Axios({
        url: `/admin/admin-management/list?${queries}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      setTotalPage(data.data.totalPage);
      setData(data.data.admins);
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [page, search]);

  const handleActivate = async (email, isActive) => {
    try {
      await Axios({
        url: `/admin/admin-management/${email}`,
        method: "PATCH",
        data: {
          isActive,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      fetchData();

      toast({
        title: "Success.",
        description: "Berhasil mengubah status.",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    } catch (err) {
      console.log(err.response);
      toast({
        title: "Warning.",
        description: "Tidak berhasil mengubah status.",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  const columns = [
    {
      dataField: "username",
      text: "Username",
      style: { textAlign: "left" },
      headerStyle: { textAlign: "left" },
      formatter: (cell, row) => {
        return (
          <Flex align="center" justifyContent="space-between" maxW="280px">
            <p style={{ color: "black" }}>{cell}</p>
            {row?.isSuspended && <FcLock size="18px" />}
          </Flex>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "adminRole",
      text: "Role",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      sort: true,
      formatter: (cell, row) => {
        return <p style={{ color: "black" }}>{cell?.role}</p>;
      },
    },
    {
      dataField: "isActive",
      text: "Status",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => {
        return (
          <p style={{ color: "black" }}>{cell ? "Active" : "Not Active"}</p>
        );
      },
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "end" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => {
        return (
          <Flex justifyContent={"center"} width="100%">
            <Text
              cursor={"pointer"}
              onClick={() => handleClickModal({ type: "edit", data: row })}
            >
              Edit
            </Text>
            <Center height="20px" width="29px">
              <Divider
                orientation="vertical"
                color={"#C4C4C4"}
                opacity={"1"}
                borderLeftWidth={"1.5px"}
              />
            </Center>
            <Text
              cursor={"pointer"}
              onClick={() =>
                handleActivate(row.email, row.isActive ? false : true)
              }
            >
              {row.isActive ? "Deactivate" : "Activate"}
            </Text>
            <Center height="20px" width="29px">
              <Divider
                orientation="vertical"
                color={"#C4C4C4"}
                opacity={"1"}
                borderLeftWidth={"1.5px"}
              />
            </Center>
            <Text
              cursor={"pointer"}
              onClick={() => handleDeleteData(row.email)}
            >
              Delete
            </Text>
          </Flex>
        );
      },
    },
  ];

  const handlePage = (value) => {
    setPage(value.selected);
  };

  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setPage(0);
      const currentQuery = queryString.parse(search);
      const queries = queryString.stringify(
        {
          ...currentQuery,
          q: searchQuery,
        },
        { skipEmptyString: true }
      );

      history.push(`/admin/list-admin?${queries}`);
    }
  };

  const handleClickModal = ({ type, data }) => {
    setSubmitType(type);
    onOpen();
    let tempInput = { ...inputAdmin };
    if (type === "edit") {
      Object.keys(tempInput).forEach((key) => {
        if (key === "password") {
          tempInput[key] = "";
        } else {
          tempInput[key] = data[key];
        }
      });
      if (data.isSuspended) {
        tempInput.selectRestrict = "";
      }
      setEmailUser(data.email);
    } else {
      Object.keys(tempInput).forEach((key) => {
        if (key === "isLdap" || key === "isSuspended") {
          tempInput[key] = false;
        } else if (key === "isActive") {
          tempInput[key] = true;
        } else {
          tempInput[key] = "";
        }
      });
      setEmailUser("");
    }
    tempInput.prevUsername = tempInput.username;
    setInputAdmin(tempInput);
  };

  const handleDeleteData = async (email) => {
    try {
      await Axios({
        url: `/admin/admin-management/${email}`,
        method: "delete",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      fetchData();
      toast({
        title: "Success.",
        description: "Berhasil menghapus admin.",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    } catch (err) {
      console.log(err.response);
      const { data } = err.response;
      toast({
        title: "Warning.",
        description: data.errors[0].message,
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };
  return (
    <Box className="admin__list-admin-container">
      <Flex>
        <Text className="admin__list-admin-title">Admin Management</Text>
        <Spacer />
        <Button
          className="admin__list-admin-button-add"
          onClick={() => handleClickModal({ type: "add" })}
        >
          Add Admin
        </Button>
      </Flex>

      <Box className="admin__list-admin-table-container">
        <Input
          placeholder="Search admin using username or email"
          value={searchQuery}
          onChange={(event) => handleSearchQuery(event)}
          onKeyPress={(event) => handleKeyPressSearch(event)}
        />

        <ToolkitProvider
          data={data}
          columns={columns}
          search
          keyField="list-admin"
        >
          {(props) => (
            <div className="py-4">
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                bordered={false}
                trStyle={{ backgroundColor: "white" }}
              />
            </div>
          )}
        </ToolkitProvider>
        <Box mt="1em">
          <Pagination
            page={page}
            totalPage={totalPage}
            changePageHandler={handlePage}
          />
        </Box>
      </Box>
      <AddAdmin
        isOpen={isOpen}
        onClose={onClose}
        inputAdmin={inputAdmin}
        setInputAdmin={setInputAdmin}
        emailUser={emailUser}
        setEmailUser={setEmailUser}
        submitType={submitType}
        fetchData={fetchData}
      />
    </Box>
  );
}

export default ListAdmin;
