import { ArrowDownIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  Flex,
  IconButton,
  Input,
  Box,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Axios from "../../../services/axios";
import "../style/CampaignAnalytics.css";
import { isEmpty } from "lodash";

function CampaignAnalytics({ isOpen, onClose, dataDetail }) {
  const [detailAnalytic, setDetailAnalytic] = useState({});
  const handleFetchData = async () => {
    try {
      const { data } = await Axios({
        url: `/campaign/${dataDetail.id}?withConversion=true`,
        method: "get",
      });
      setDetailAnalytic({ ...data.data.campaign });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isOpen && !isEmpty(dataDetail)) {
      handleFetchData();
    }
  }, [isOpen, dataDetail]);

  const handleCloseModal = () => {
    setDetailAnalytic({});
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered>
      <ModalOverlay />
      <ModalContent maxWidth="845px" className="modal-campaign__container">
        <Flex align="center">
          <Text className="modal-campaign__title">
            Campaign Analytics of {dataDetail?.name}
          </Text>
          <Spacer />
          <IconButton
            icon={<CloseIcon />}
            onClick={handleCloseModal}
            variant="ghost"
          />
        </Flex>

        <Row className="modal-campaign__info-wrapper">
          <Col md="4">
            <Text className="modal-campaign__info-title">
              Number of visitors
            </Text>
          </Col>
          <Col md="4">
            <Input
              disabled
              value={detailAnalytic?.conversionDetail?.totalVisitor}
            />
          </Col>
        </Row>
        <Row className="modal-campaign__info-wrapper">
          <Col md="4" />
          <Col md="4">
            <Flex justifyContent="end" align="center">
              <Box>
                <ArrowDownIcon />
              </Box>
              <Text m="0 1em">Change</Text>
              <InputGroup width="100px">
                <Input
                  disabled
                  value={
                    detailAnalytic?.conversionDetail
                      ?.visitorToRegistrantPercentage || 0
                  }
                />
                <InputRightAddon children="%" />
              </InputGroup>
            </Flex>
          </Col>
        </Row>

        <Row className="modal-campaign__info-wrapper">
          <Col md="4">
            <Text className="modal-campaign__info-title">
              Number of registrant
            </Text>
          </Col>
          <Col md="4">
            <Input
              disabled
              value={detailAnalytic?.conversionDetail?.totalRegistrant}
            />
          </Col>
        </Row>

        <Row className="modal-campaign__info-wrapper">
          <Col md="4" />
          <Col md="4">
            <Flex justifyContent="end" align="center">
              <Box>
                <ArrowDownIcon />
              </Box>
              <Text m="0 1em">Change</Text>
              <InputGroup width="100px">
                <Input
                  disabled
                  value={
                    detailAnalytic?.conversionDetail
                      ?.registrantToResellerPercentage || 0
                  }
                />
                <InputRightAddon children="%" />
              </InputGroup>
            </Flex>
          </Col>
        </Row>

        <Row className="modal-campaign__info-wrapper">
          <Col md="4">
            <Text className="modal-campaign__info-title">
              Number of reseller
            </Text>
          </Col>
          <Col md="4">
            <Input
              disabled
              value={detailAnalytic?.conversionDetail?.totalReseller}
            />
          </Col>
        </Row>
        <Row className="modal-campaign__info-wrapper">
          <Col md="4">
            <Text className="modal-campaign__info-title">
              Sales generated (Rp)
            </Text>
          </Col>
          <Col md="4">
            <Input
              disabled
              value={detailAnalytic?.conversionDetail?.totalGeneratedSales}
            />
          </Col>
        </Row>
      </ModalContent>
    </Modal>
  );
}

export default CampaignAnalytics;
