import Axios from "../../services/axios";
import {
  SET_COMMISSION,
  SET_TOTAL_COMMISSION,
  SET_USER_COMMISSION,
  SET_DETAIL_COMMISSION,
  SET_ALL_COMMISSION,
  SET_COMMISSION_MANAGEMENT
} from "../actionTypes";

export function fetchCommissionManagement(queries) {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/admin/commission/list?${queries}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        }
      })
      dispatch({
        type: SET_COMMISSION_MANAGEMENT,
        payload: data.data.users 
      })
    } catch (error) {
      console.error(error)
    }
  }
}

export function fetchCommission(queries, type) {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/admin/commissionPayout?${queries}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      if (type === "all") {
        dispatch({
          type: SET_ALL_COMMISSION,
          payload: data.data.commissions,
        });
      } else {
        dispatch({
          type: SET_COMMISSION,
          payload: data.data.commissions,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({ type: SET_COMMISSION, payload: [] });
    }
  };
}

export function fetchTotalCommission(queries) {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `admin/commissionPayout/totalDue?${queries}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      dispatch({
        type: SET_TOTAL_COMMISSION,
        payload: data.data.totalDue,
      });
    } catch (err) {
      console.log(err);
      dispatch({ type: SET_TOTAL_COMMISSION, payload: 0 });
    }
  };
}

export function fetchUserCommission(userId, queries) {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/admin/user/${userId}/commission-log?${queries}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      dispatch({
        type: SET_USER_COMMISSION,
        payload: data.data,
      });
    } catch (err) {
      console.log(err);
      dispatch({ type: SET_USER_COMMISSION, payload: [] });
    }
  };
}

export function fetchDetailCommission(id) {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/admin/commissionPayout/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      dispatch({
        type: SET_DETAIL_COMMISSION,
        payload: data.data,
      });
    } catch (err) {
      console.log(err);
      dispatch({ type: SET_DETAIL_COMMISSION, payload: [] });
    }
  };
}
