import React, { Fragment, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Unlock } from "react-feather";
import { withRouter, useHistory } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useToast } from "@chakra-ui/react";
import Axios from "../../services/axios";
import { load } from "recaptcha-v3";

const LoginTabset = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };
  const toast = useToast();

  const routeChange = () => {
    history.push(`${process.env.PUBLIC_URL}/dashboard`);
  };

  const handleValidInput = () => {
    let statusValidation = {
      status: "success",
      message: "Berhasil melakukan login.",
    };

    if (!email) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon isi email dahulu.";
    } else if (!password) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon isi password dahulu.";
    }

    return statusValidation;
  };

  const loadCaptcha = async () => {
    const recaptcha = await load(process.env.REACT_APP_PBP_SITE_KEY);
    const token = await recaptcha.execute(process.env.REACT_APP_PBP_SITE_KEY, {
      action: "submit",
    });

    return token;
  };

  const handleLogin = async () => {
    let validation = handleValidInput();
    loadCaptcha().then(async (res) => {
      if (validation.status === "success") {
        try {
          const { data } = await Axios({
            url: "/admin/auth",
            method: "POST",
            data: {
              email,
              password,
              captchaToken: res,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });

          toast({
            title: "Success.",
            description: "User berhasil login.",
            status: "success",
            position: "top",
            duration: 1500,
            isClosable: true,
          });

          localStorage.setItem("userToken", data?.data?.token);
          localStorage.setItem("adminRoleId", data?.data?.adminRoleId);
          localStorage.setItem("workingArea", data?.data?.workingArea);
          if (data.data.workingArea === "Distribution Center") {
            localStorage.setItem("warehouseId", data?.data?.warehouseId);
          }

          if (!data.errorStatus) {
            setTimeout(() => {
              routeChange();
            }, 2000);
          }
        } catch (err) {
          console.error(err);
          if (err?.response?.data?.errors[0]?.adminData?.isSuspended) {
            toast({
              title: "Warning.",
              description: "Akun anda terkunci, mohon coba beberapa saat lagi.",
              status: "warning",
              position: "top",
              duration: 1500,
              isClosable: true,
            });
          } else {
            toast({
              title: "Warning.",
              description: "Email atau password salah. Silahkan coba lagi.",
              status: "warning",
              position: "top",
              duration: 1500,
              isClosable: true,
            });
          }
        }
      } else {
        toast({
          title: "Warning.",
          description: validation.message,
          status: validation.status,
          position: "top",
          duration: 1500,
          isClosable: true,
        });
      }
    });
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };

  return (
    <div>
      <Fragment>
        <Tabs>
          <TabList className="nav nav-tabs tab-coupon">
            <Tab className="nav-link" onClick={(e) => clickActive(e)}>
              <User />
              Login
            </Tab>
            <Tab
              className="nav-link"
              onClick={(e) => clickActive(e)}
              disabled
              style={{ cursor: "not-allowed" }}
            >
              <Unlock />
              Register
            </Tab>
          </TabList>

          <TabPanel>
            <Form className="form-horizontal auth-form">
              <FormGroup>
                <Input
                  required=""
                  name="login[username]"
                  type="email"
                  className="form-control"
                  placeholder="Username"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  onKeyDown={(e) => onKeyPress(e)}
                  id="login-page__button-email"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  required=""
                  name="login[password]"
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  onKeyDown={(e) => onKeyPress(e)}
                  id="login-page__input-password"
                />
              </FormGroup>
              <div className="form-terms">
                <div className="custom-control custom-checkbox mr-sm-2">
                  <Input
                    type="checkbox"
                    className="custom-control-input"
                    id="customControlAutosizing"
                  />
                  <Label className="d-block">
                    <Input
                      className="checkbox_animated"
                      id="chk-ani2"
                      type="checkbox"
                    />
                    Reminder Me{" "}
                    <span className="pull-right">
                      {" "}
                      <a href="/#" className="btn btn-default forgot-pass p-0">
                        lost your password
                      </a>
                    </span>
                  </Label>
                </div>
              </div>
              <div className="form-button">
                <Button
                  data-sitekey={process.env.REACT_APP_PBP_SITE_KEY}
                  data-action="submit"
                  data-callback="onSubmit"
                  color="primary"
                  id="login-page__button-login"
                  onClick={() => handleLogin()}
                >
                  Login
                </Button>
              </div>
              <div className="form-footer">
                <span>Or Login up with social platforms</span>
                <ul className="social">
                  <li>
                    <a href="/#">
                      <i className="icon-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="icon-twitter-alt"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="icon-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="icon-pinterest-alt"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Form>
          </TabPanel>
          <TabPanel>
            <Form className="form-horizontal auth-form">
              <FormGroup>
                <Input
                  required=""
                  name="login[username]"
                  type="email"
                  className="form-control"
                  placeholder="Username"
                  id="exampleInputEmail12"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  required=""
                  name="login[password]"
                  type="password"
                  className="form-control"
                  placeholder="Password"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  required=""
                  name="login[password]"
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                />
              </FormGroup>
              <div className="form-terms">
                <div className="custom-control custom-checkbox mr-sm-2">
                  <Input
                    type="checkbox"
                    className="custom-control-input"
                    id="customControlAutosizing"
                  />
                  <Label className="d-block">
                    <Input
                      className="checkbox_animated"
                      id="chk-ani2"
                      type="checkbox"
                    />
                    I agree all statements in{" "}
                    <span>
                      <a href="/#">Terms &amp; Conditions</a>
                    </span>
                  </Label>
                </div>
              </div>
              <div className="form-button">
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => routeChange()}
                >
                  Register
                </Button>
              </div>
              <div className="form-footer">
                <span>Or Sign up with social platforms</span>
                <ul className="social">
                  <li>
                    <a href="/#">
                      <i className="icon-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="icon-twitter-alt"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="icon-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="icon-pinterest-alt"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Form>
          </TabPanel>
        </Tabs>
      </Fragment>
    </div>
  );
};

export default withRouter(LoginTabset);
