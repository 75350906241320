import React from "react";

import "../styles/_homeBanner.scss";
import {
  Text,
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
} from "@chakra-ui/react";

const DeleteTestimony = ({
  isOpenDeleteTestimony,
  setIsOpenDeleteTestimony,
  testimony,
  isImage,
  deleteHandler,
  fetchMainTestimony,
  imageDeleteHandler,
  path,
}) => {
  return (
    <>
      <Modal
        onClose={() => setIsOpenDeleteTestimony(false)}
        size="xl"
        isOpen={isOpenDeleteTestimony}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              style={{
                fontWeight: "bold",
                color: "black",
                fontSize: "18px",
                marginBottom: "16px",
              }}
            >
              Apakah anda yakin ingin menghapus{" "}
              {isImage ? "image" : "testimony"} ini?
            </Text>
            {isImage && (
              <img
                src={path}
                alt="deleted"
                style={{ height: "40vh", width: "100%", objectFit: "contain" }}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button mr={4} onClick={() => setIsOpenDeleteTestimony(false)}>
              Close
            </Button>
            <Button
              variant="ghost"
              justifyContent="flex-start"
              backgroundColor={"#FF8084"}
              color={"white"}
              onClick={async () => {
                if (isImage) {
                  imageDeleteHandler();
                } else {
                  await deleteHandler(testimony.id);
                  await fetchMainTestimony();
                }

                setIsOpenDeleteTestimony(false);
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteTestimony;
