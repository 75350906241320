import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserStats } from "../../../store/actions/userAction";

const SummarizeData = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchUserStats(dispatch);
    // eslint-disable-next-line
  }, [dispatch]);

  const userStats = useSelector(({ users }) => users.userStats);

  return (
    <Flex>
      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        m={3}
        p={5}
        flex={1}>
        <Flex justifyContent="center" alignItems="center" direction="column">
          <Heading as="h6" fontSize="14px" size="sm">
            Total Registered
          </Heading>
          <Heading as="h1" size="lg">
            {userStats?.data?.totalUser}
          </Heading>
        </Flex>
      </Box>

      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        m={3}
        p={5}
        flex={1}>
        <Flex justifyContent="center" alignItems="center" direction="column">
          <Heading as="h6" fontSize="14px" size="sm">
            Inactive Resellers
          </Heading>
          <Heading as="h1" size="lg">
            {userStats?.data?.totalInactive}
          </Heading>
        </Flex>
      </Box>

      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        m={3}
        p={5}
        flex={1}>
        <Flex justifyContent="center" alignItems="center" direction="column">
          <Heading as="h6" fontSize="14px" size="sm">
            Power Resellers
          </Heading>
          <Heading as="h1" size="lg">
            {userStats?.data?.totalPowerReseller}
          </Heading>
        </Flex>
      </Box>

      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        m={3}
        p={5}
        flex={1}>
        <Flex justifyContent="center" alignItems="center" direction="column">
          <Heading as="h6" fontSize="14px" size="sm">
            Master Resellers
          </Heading>
          <Heading as="h1" size="lg">
            {userStats?.data?.totalMasterReseller}
          </Heading>
        </Flex>
      </Box>

      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        m={3}
        p={5}
        flex={1}>
        <Flex justifyContent="center" alignItems="center" direction="column">
          <Heading as="h6" fontSize="14px" size="sm">
            Champion Resellers
          </Heading>
          <Heading as="h1" size="lg">
            {userStats?.data?.totalChampionReseller}
          </Heading>
        </Flex>
      </Box>
    </Flex>
  );
};

export default SummarizeData;
