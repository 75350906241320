import { Input, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Tooltip from "../../tooltip";

const DiscountPromo = ({ handlePromoDataChange, promoData, isDisabled }) => {
  const [isInvalidPercentage, setIsInvalidPercentage] = useState("");

  useEffect(() => {
    if (promoData.discountType === "percentage") {
      if (promoData.promoValue > 100) {
        setIsInvalidPercentage(true);
      } else {
        setIsInvalidPercentage(false);
      }
    } else {
      setIsInvalidPercentage(false);
    }
  }, [promoData]);

  return (
    <>
      <Row className="input-promo__title-wrapper">
        <Col lg="3" xl="2">
          <Text className="input-promo__title">Discount Type</Text>
        </Col>
        <Col lg="9" xl="10">
          <Row>
            <Col md="4">
              <Select
                disabled={isDisabled}
                value={promoData.discountType}
                name="discountType"
                onChange={(event) => handlePromoDataChange(event)}>
                <option value="" selected disabled>
                  Select Discount Type
                </option>
                <option value="percentage">Percentage</option>
                <option value="fixed">Fixed</option>
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="input-promo__title-wrapper">
        <Col
          lg="3"
          xl="2"
          style={{
            display: "flex",
            alignItems: "center",
          }}>
          <Text className="input-promo__title">Promo Value</Text>
          <Tooltip content={"Persentase/nilai promo yang diberikan."} />
        </Col>
        <Col lg="9" xl="10">
          <Row>
            <Col md="4">
              <Input
                disabled={isDisabled}
                type="number"
                value={promoData.promoValue}
                name="promoValue"
                onChange={(event) => handlePromoDataChange(event)}
                isInvalid={isInvalidPercentage}
                errorBorderColor="crimson"
              />
              {isInvalidPercentage && (
                <Text color={"crimson"}>
                  Nominal persen tidak boleh melebihi 100 atau angka koma
                </Text>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {promoData.discountType === "percentage" && (
        <Row className="input-promo__title-wrapper">
          <Col
            lg="3"
            xl="2"
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            <Text className="input-promo__title">Maximum Percentage Value</Text>
            <Tooltip
              content={"Nilai maksimum dari promo, dituliskan dalam rupiah."}
            />
          </Col>
          <Col lg="9" xl="10">
            <Row>
              <Col md="4">
                <Input
                  disabled={isDisabled}
                  type="number"
                  value={promoData.maximumPercentageValue}
                  name="maximumPercentageValue"
                  onChange={(event) => handlePromoDataChange(event)}
                  errorBorderColor="crimson"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row className="input-promo__title-wrapper">
        <Col
          lg="3"
          xl="2"
          style={{
            display: "flex",
            alignItems: "center",
          }}>
          <Text className="input-promo__title">Promo Mark Option</Text>
          <Tooltip
            content={
              "Target pengurangan dari promo, terdapat dua value : <strong>Subtotal</strong> dan <strong>total Payment</strong>.<br><br>" +
              " <strong>Subtotal</strong> berarti pengurangan discount akan dilakukan pada nominal subtotal (Harga Total Produk).<br><br>" +
              " <strong>Total Payment</strong> akan mengurangi Total Payment (termasuk ongkir)"
            }
          />
        </Col>
        <Col lg="9" xl="10">
          <Row>
            <Col md="4">
              <Select
                disabled={isDisabled}
                value={promoData.promoMark}
                name="promoMark"
                onChange={(event) => handlePromoDataChange(event)}>
                <option value="">Select promo mark</option>
                <option value="payment">Total Payment</option>
                <option value="subtotal">Subtotal</option>
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DiscountPromo;
