import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { Input, Button, Flex, Spacer } from "@chakra-ui/react";

const ProductDataPrice = ({ product, changeHandler }) => {
  const formatter = new Intl.NumberFormat("id-ID", {});
  const [dataPrice, setDataPrice] = useState({
    price: product.price ? product.price : "",
    salePrice: product.salePrice ? product.salePrice : "",
    discount: product.discount ? product.discount : "",
  });
  const dataPriceHandler = (e) => {
    const temp = { ...dataPrice };
    let value = e.target.value;
    if (e.target.type === "number") {
      value = parseFloat(value);
    }
    temp[e.target.name] = value;

    changeHandler(e);
    setDataPrice(temp);
  };

  const dataDiscountHandler = (e) => {
    const temp = { ...dataPrice };
    let value = parseFloat(e.target.value);
    if (value > 100) {
      value = 100;
      e.target.value = 100;
    }
    temp[e.target.name] = value;

    changeHandler(e);
    setDataPrice(temp);
  };

  const triggerSamePrice = (type) => {
    const temp = { ...dataPrice };
    let e = {
      target: {
        name: type,
        value: 0,
      },
    };

    temp[type] = 0;

    changeHandler(e);
    setDataPrice(temp);
  };
  return (
    <>
      <Row style={{ padding: "16px" }}>
        <Col xl={6} style={{ marginBottom: "8px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Price
          </p>
          <Input
            type="number"
            placeholder="Price"
            name="price"
            onChange={(e) => dataPriceHandler(e)}
            value={dataPrice.price}
          />
        </Col>
        <Col xl={6} style={{ marginBottom: "8px" }}>
        </Col>
        <Col xl={6} style={{ marginBottom: "8px" }}>
          <Flex>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "8px",
                color: "black",
              }}
            >
              Sale Price
            </p>
            <Spacer />
            {dataPrice.price !== "" && (
              <Button
                color={"#FF8084"}
                size="sm"
                variant="link"
                mb={2}
                onClick={() => triggerSamePrice("discount")}
              >
                Same Price
              </Button>
            )}
          </Flex>
          <p
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Rp{" "}
            {product.salePrice === ""
              ? 0
              : product.salePrice === null
              ? 0
              : formatter.format(product.salePrice)}
          </p>
        </Col>
        <Col xl={6} style={{ marginBottom: "8px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Discount
          </p>
          <Input
            type="number"
            placeholder="Discount"
            name="discount"
            onChange={(e) => dataDiscountHandler(e)}
            value={dataPrice.discount}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProductDataPrice;
