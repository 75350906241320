import { Button, CloseButton, Flex, Input, Spacer } from "@chakra-ui/react";
import React, { useRef } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import one from "../../../assets/images/pro3/1.jpg";

function ProductVariantSKU({
  deleteVariant,
  variantType,
  index,
  data,
  listVariant,
  setListVariant,
}) {
  const formatter = new Intl.NumberFormat("id-ID", {});
  const changeHandler = (e, index) => {
    let temp = [...listVariant];
    if (e.target.name === "weight") {
      temp[index][e.target.name] = parseFloat(e.target.value);
    } else {
      temp[index][e.target.name] = e.target.value;
    }
    setListVariant(temp);
  };

  const dataPriceHandler = (e) => {
    let temp = [...listVariant];
    let discountValue;
    if (e.target.name === "discount") {
      let value = parseFloat(e.target.value);
      if (value > 100) {
        value = 100;
      }
      e.target.value = value;
      discountValue = value;
    } else {
      discountValue = temp[index].discount;
    }

    if (!discountValue) {
      discountValue = 0;
    }

    temp[index][e.target.name] = parseFloat(e.target.value);

    if (temp[index].discount && temp[index].price) {
      let salePrice = (temp[index].price * (100 - temp[index].discount)) / 100;
      temp[index].salePrice = parseInt(salePrice.toFixed(0));
    } else {
      temp[index].salePrice = temp[index].price;
    }
    setListVariant(temp);
  };

  const triggerSamePrice = () => {
    const temp = [...listVariant];

    temp[index].discount = 0;
    temp[index].salePrice = temp[index].price;
    setListVariant(temp);
  };
  const imgRef = useRef();

  const imageMaxSize = 2000000;
  const handleImageChange = (value, i) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (value.currentTarget.files.item(0).size > imageMaxSize) {
        toast({
          title: "Error.",
          description: "Mohon upload gambar berukuran kurang dari 2MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        const tempVariant = [...listVariant];
        let reader = new FileReader();
        const image = value.target.files[0];
        tempVariant[i].img = image;
        reader.onload = () => {
          tempVariant[i].imgSrc = reader.result;
          setListVariant(tempVariant);
        };
        reader.readAsDataURL(image);
      }
    }
  };

  return (
    <Card key={index}>
      <CardBody>
        <Row>
          <Col xl="10" />
          <Col xl="2">
            <CloseButton onClick={() => deleteVariant(index)} />
          </Col>
        </Row>
        <Row>
          <Col xl="6" style={{ marginBottom: "8px" }}>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "8px",
                color: "black",
              }}
            >
              Variant Name
            </p>
            <Input
              placeholder="Variant Name"
              name="name"
              onChange={(e) => changeHandler(e, index)}
              value={data.name}
            />

            <p
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "8px",
                marginTop: "8px",
                color: "black",
              }}
            >
              Odoo Code
            </p>
            <Input
              placeholder="Odoo Code"
              name="odooCode"
              onChange={(e) => changeHandler(e, index)}
              value={data.odooCode}
            />

            <p
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "8px",
                marginTop: "8px",
                color: "black",
              }}
            >
              Barcode
            </p>
            <Input
              placeholder="Barcode"
              name="barcode"
              onChange={(e) => changeHandler(e, index)}
              value={data.barcode}
            />
            <p
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "8px",
                marginTop: "8px",
                color: "black",
              }}
            >
              Weight
            </p>
            <Input
              placeholder="Weight in kg"
              name="weight"
              onChange={(e) => changeHandler(e, index)}
              value={data.weight}
              type="number"
            />
          </Col>
          <Col xl="6" style={{ marginBottom: "8px" }}>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "8px",
                color: "black",
              }}
            >
              Variant Image
            </p>

            <div style={{ height: "200px" }}>
              {!data.img && (
                <img
                  src={one}
                  style={{
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => imgRef.current.click()}
                />
              )}
              <div className="box-input-file-full">
                <input
                  className="upload"
                  style={{
                    cursor: "pointer",
                    display: "none",
                  }}
                  type="file"
                  id="main-image-input"
                  name="img"
                  accept="image/*"
                  ref={imgRef}
                  onChange={(e) => handleImageChange(e, index)}
                />
                <img
                  src={data.imgSrc || data.img}
                  alt=""
                  style={{
                    objectFit: "cover",
                    width: "240px",
                    height: "240px",
                    cursor: "pointer",
                    background: "#F0F0F0",
                  }}
                  className="img-fluid image_zoom_1 blur-up lazyloaded"
                  onClick={() => imgRef.current.click()}
                />
              </div>
            </div>

            {variantType === "color" && (
              <>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginBottom: "8px",
                    marginTop: "8px",
                    color: "black",
                  }}
                >
                  Hexcode
                </p>
                <Input
                  placeholder="Color Hex"
                  name="hexcode"
                  onChange={(e) => changeHandler(e, index)}
                  value={data.hexcode}
                />
              </>
            )}
          </Col>
          <Col xl="6" style={{ marginBottom: "8px" }}>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "8px",
                color: "black",
              }}
            >
              Price
            </p>
            <Input
              type="number"
              placeholder="Price"
              name="price"
              onChange={(e) => dataPriceHandler(e)}
              value={data.price}
            />
          </Col>
          <Col xl="6" style={{ marginBottom: "8px" }}>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "8px",
                color: "black",
              }}
            >
              Discount
            </p>
            <Input
              type="number"
              placeholder="Discount"
              name="discount"
              onChange={(e) => dataPriceHandler(e)}
              value={data.discount}
            />
          </Col>
          <Col xl="6" style={{ marginBottom: "8px" }}>
            <Flex>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "black",
                }}
              >
                Sale Price
              </p>
              <Spacer />
              {data.price && (
                <Button
                  color={"#FF8084"}
                  size="sm"
                  variant="link"
                  mb={2}
                  onClick={() => triggerSamePrice()}
                >
                  Same Price
                </Button>
              )}
            </Flex>
            <p
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                marginBottom: "8px",
                color: "black",
              }}
            >
              Rp {!data?.salePrice ? 0 : formatter.format(data?.salePrice)}
            </p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default ProductVariantSKU;
