import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { EditorState, ContentState, convertToRaw, RichUtils } from "draft-js";
import styles_wysiwyg from "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useState } from "react";
import stylesEditor from "../../products/styles/AddNewProduct.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { isEmpty } from "lodash";
import {
  editFaqAction,
  getFaqsAction,
} from "../../../store/actions/faqsAction";
import { useDispatch } from "react-redux";

const EditFaqs = ({ isOpen, onClose, rowData, page }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [faqsForm, setFaqsForm] = useState({
    question: "",
    answer: "",
  });

  const _toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const config = {
    // image: {
    //   uploadCallback: _uploadImageCallBack,
    //   previewImage: true,
    //   alt: { present: true, mandatory: false },
    //   inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    // },
    list: {
      inDropdown: true,
      toggleBlockType: _toggleBlockType,
      unordered: { className: stylesEditor["unordered-list"] },
      ordered: { className: stylesEditor["ordered-list"] },
    },
    textAlign: { inDropdown: true },
    blockType: {
      className: "demo-option-custom-wide",
      dropdownClassName: "demo-dropdown-custom",
    },
  };

  const editorFaqChangeHandler = (editorState) => {
    setEditorState(editorState);
    let tempFaqs = { ...faqsForm };
    tempFaqs.body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setFaqsForm({ ...faqsForm, answer: tempFaqs.body });
  };

  /// Use To Edit Article Body After Fetch Data
  const convertHtmlToDraft = (articleHTML) => {
    let contentBlock = htmlToDraft(articleHTML);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const article = EditorState.createWithContent(contentState);
      return article;
    }
  };

  useEffect(() => {
    if (!isEmpty(rowData)) {
      setFaqsForm({
        ...faqsForm,
        question: rowData.question,
        answer: rowData.answer,
      });

      setEditorState(convertHtmlToDraft(rowData.answer));
    } // eslint-disable-next-line
  }, [rowData]);

  const onHandleEditFaq = async () => {
    toast({
      title: "Sucess",
      description: `Berhasil mengubah FAQ`,
      status: "success",
      position: "top",
      duration: 1000,
      isClosable: true,
    });
    await editFaqAction(rowData.id, faqsForm);
    await getFaqsAction(dispatch, `page=${page + 1}`);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit FAQ</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={2}>
            <Text>Question</Text>
            <Textarea value={faqsForm.question} />
          </Box>
          <Box>
            <Text>Answer</Text>
            <Editor
              editorState={editorState}
              onEditorStateChange={editorFaqChangeHandler}
              editorStyle={{ padding: "5px" }}
              toolbarClassName={styles_wysiwyg["toolbarClassName"]}
              wrapperClassName={styles_wysiwyg["wrapperClassName"]}
              editorClassName={styles_wysiwyg["editorClassName"]}
              toolbar={config}
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="teal" mr={3} onClick={onHandleEditFaq}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditFaqs;
