import { Box, Flex, Text, IconButton } from "@chakra-ui/react";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RepeatIcon } from "@chakra-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import queryString from "query-string";
import "./styles/list-suggestion.css";
import Pagination from "../order/components/pagination";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Axios from "../../services/axios";
import { isEmpty } from "lodash";
import dateFormat from "dateformat";

function ListSuggestion(props) {
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const { search } = useLocation();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [totalPage, setTotalPage] = useState();

  const changePageHandler = (value) => {
    setPage(value.selected);
  };

  const changeDateHandler = ({ event, type }) => {
    const date = `${event.getFullYear()}-${
      event.getMonth() + 1
    }-${event.getDate()}`;
    const today = `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}`;

    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify({
      ...currentQuery,
      filter_created_date_min:
        type === "min" ? date : firstDate === "" ? date : firstDate,
      filter_created_date_max:
        type === "max" ? date : lastDate === "" ? today : lastDate,
    });

    history.push(`/community/list-suggestion?${queries}`);
  };

  const handleClearDate = () => {
    setFirstDate("");
    setLastDate("");

    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify(
      {
        ...currentQuery,
        filter_created_date_min: "",
        filter_created_date_max: "",
      },
      { skipEmptyString: true }
    );

    history.push(`/community/list-suggestion?${queries}`);
  };

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (
      currentQuery.filter_created_date_min ||
      currentQuery.filter_created_date_max
    ) {
      setFirstDate(currentQuery.filter_created_date_min);
      setLastDate(currentQuery.filter_created_date_max);
    }
  }, [search]);

  const columns = [
    {
      dataField: "suggestion",
      text: "Community Suggestion",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "userId",
      text: "Reseller Name",
      style: { textAlign: "center" },
      sort: true,
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => {
        return (
          <p
            style={{ color: "black" }}
          >{`${row.user.firstName} ${row.user.lastName}`}</p>
        );
      },
    },
    {
      dataField: "id",
      text: "Email",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => {
        return <p style={{ color: "black" }}>{row.user.email}</p>;
      },
    },
    {
      dataField: "id",
      text: "Phone",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => {
        return <p style={{ color: "black" }}>{row.user.phone}</p>;
      },
    },
    {
      dataField: "createdAt",
      text: "Submission Date",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell) => {
        return (
          <p style={{ color: "black" }}>
            {dateFormat(cell, "dd/mm/yyyy HH:MM:ss")}
          </p>
        );
      },
    },
  ];

  const fetchData = async () => {
    try {
      let currentQueries = queryString.parse(search);
      if (currentQueries?.filter_created_date_max) {
        let tempMaxDateArr = currentQueries.filter_created_date_max.split("-");
        let newMaxDate = dateFormat(
          new Date(
            parseInt(tempMaxDateArr[0]),
            parseInt(tempMaxDateArr[1]) - 1,
            parseInt(tempMaxDateArr[2]) + 1
          ),
          "yyyy-mm-dd"
        );
        currentQueries.filter_created_date_max = newMaxDate;
      }
      const queries = queryString.stringify({
        ...currentQueries,
        page: page + 1,
        limit: 5,
        sort: "createdAt,desc",
      });
      const { data } = await Axios({
        url: `/admin/community/suggestions?${queries}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      setTotalPage(data.data.totalPage);
      setDataTable(data.data.communities);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    } else {
      fetchData();
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [page]);

  return (
    <Box className="list-suggestion__container">
      <Flex alignItems="center" mb="2em">
        <Text className="list-suggestion__title">Community Suggestion</Text>
      </Flex>
      <Flex className="list-suggestion__datepicker__container">
        <Text className="list-suggestion__datepicker-text">
          Filter Submission Date
        </Text>
        <DatePicker
          selected={firstDate ? new Date(firstDate) : firstDate}
          onChange={(event) => changeDateHandler({ event, type: "min" })}
          dateFormat="dd/MM/yyyy"
          maxDate={lastDate ? new Date(lastDate) : new Date()}
        />
        -
        <DatePicker
          selected={lastDate ? new Date(lastDate) : lastDate}
          onChange={(event) => changeDateHandler({ event, type: "max" })}
          dateFormat="dd/MM/yyyy"
          minDate={new Date(firstDate)}
          maxDate={new Date()}
        />
        <IconButton
          colorScheme="teal"
          variant="outline"
          onClick={handleClearDate}
          icon={<RepeatIcon />}
        />
      </Flex>
      {!isEmpty(dataTable) && (
        <ToolkitProvider
          data={dataTable}
          columns={columns}
          search
          keyField="createdAt"
        >
          {(props) => (
            <div className="py-4 table-responsive list-community__table-container">
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                bordered={false}
                trStyle={{ backgroundColor: "white" }}
              />
            </div>
          )}
        </ToolkitProvider>
      )}
      {!isEmpty(dataTable) && (
        <Box mt="1em">
          <Pagination
            page={page}
            totalPage={totalPage}
            changePageHandler={changePageHandler}
          />
        </Box>
      )}
    </Box>
  );
}

export default ListSuggestion;
