import { Flex, Text, useToast } from "@chakra-ui/react";
import TextField from "@material-ui/core/TextField";
import styles from "./styles.module.css";

import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import { addProductShowcase } from "../../store/actions/productShowcaseAction";
import Breadcrumb from "../common/breadcrumb";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import ImageDesktopHandler from "./components/imageDesktopHandler";
// import ImageMobileHandler from "./components/imageMobileHandler";
// import ImageHandler from "./components/imageHandler";
import navguard from "../auth/navguard";
import { Autocomplete } from "@material-ui/lab";

const CreateProductShowcase = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [productId, setProductId] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [error, setError] = useState("");
  const [allProduct, setAllProduct] = useState([]);

  const stateImageDesktop = useSelector(
    ({ productShowcase }) => productShowcase.imageUploadDesk
  );

  // const stateImageMobile = useSelector(
  //   ({ productShowcase }) => productShowcase.imageUploadMob
  // );

  const history = useHistory();
  const toast = useToast();

  const onChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const onChangeProductId = (selectedOption) => {
    if (selectedOption) {
      setProductId(selectedOption.id);
      setSelectedProduct(selectedOption);
    }
  };

  const onValidateInput = () => {
    // Iterasi check product
    for (let i = 0; i < 5; i++) {
      if (stateImageDesktop[i].imageFile === stateImageDesktop[i].imagePath) {
        setError("Field Image Desktop masih ada yang kosong");
        return false;
      }
    }

    for (let i = 0; i < 5; i++) {
      if (stateImageDesktop[i].imageFile === stateImageDesktop[i].imagePath) {
        setError("Field Image Desktop masih ada yang kosong");
        return false;
      }
    }

    // stateImageMobile.slice(0, 5).map((item) => {
    //   if (!item.ImageFile) {
    //     setError("Field Image Desktop masih ada yang kosong");
    //     return false;
    //   }
    // });

    if (isEmpty(title)) {
      setError("Field Title wajib diisi");
      return false;
    } else if (isEmpty(productId)) {
      setError("Field Product Id wajib diisi");
      return false;
    } else {
      setError("");
      return true;
    }
  };

  const onSubmitCreateBanner = () => {
    try {
      const isValid = onValidateInput();
      if (!isValid) throw "Validation error";

      let data = new FormData();

      data.append("title", title);
      data.append("productId", productId);

      data.append(`image1`, stateImageDesktop[0].imageFile);
      data.append(`image2`, stateImageDesktop[1].imageFile);
      data.append(`image3`, stateImageDesktop[2].imageFile);
      data.append(`image4`, stateImageDesktop[3].imageFile);
      data.append(`image5`, stateImageDesktop[4].imageFile);

      dispatch(addProductShowcase(data));

      setTimeout(() => {
        history.push(`/content/list-productshowcase`);
      }, 2000);

      toast({
        title: "Success.",
        description: "Product Showcase berhasil ditambahkan",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Error.",
        description: "Product Showcase gagal ditambahkan:",
        err,
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const reloadData = async () => {
    let getData = [];
    try {
      getData = await axios.get(
        "https://api-qb.pti-cosmetics.com/product/list?sort=createdAt,desc"
      );
    } catch (e) {
      console.error(e);
    }

    setAllProduct(getData.data.data.products);
  };

  useEffect(() => {
    const fetchProductData = async () => {
      await reloadData();
    };

    fetchProductData();
  }, []);

  return (
    <Fragment>
      <Breadcrumb title="Create Banner" parent="Product showcase" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Add Product Showcase</h5>
              </CardHeader>
              <CardBody>
                <Form className="needs-validation">
                  <Flex>
                    <Label className="col-xl-3 col-md-4">Banner Title</Label>
                    <Input
                      className="form-control col-md-8"
                      id="validationCustom0"
                      type="text"
                      required=""
                      onChange={onChangeTitle}
                      value={title}
                    />
                  </Flex>
                  <Flex
                    justifyContent="flex-start"
                    alignItems="center"
                    mt={1}
                    mb={1}
                  >
                    <Label className="col-xl-3 col-md-4">
                      Banner ProductId
                    </Label>
                    <Autocomplete
                      disablePortal
                      className={styles["autocomplete-product"]}
                      value={selectedProduct}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      options={allProduct}
                      onChange={(event, value) => onChangeProductId(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select Product"
                        />
                      )}
                      placement="bottom"
                    />
                  </Flex>
                  <div className="form-group row">
                    <Label className="col-xl-3 col-md-4">
                      Upload Image Desktop
                    </Label>

                    {[0, 1, 2, 3, 4].map((i) => (
                      <ImageDesktopHandler key={i} imageIdx={i} />
                    ))}
                  </div>

                  <Text color="red">{error}</Text>
                  <Button
                    type="button"
                    color="primary"
                    onClick={onSubmitCreateBanner}
                  >
                    SUBMIT
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default navguard(CreateProductShowcase);
