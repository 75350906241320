import { Text } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import "./styles/orderInfo.scss";
import { isEmpty } from "lodash";

function DiscInfo({ order }) {
  const formatter = new Intl.NumberFormat("id-ID");
  const [discountInfo, setDiscountInfo] = useState([]);

  useEffect(() => {
    if (!isEmpty(order)) {
      let tempDisc = [];
      if (order?.promoUsages?.length > 0) {
        order?.promoUsages?.forEach((disc) => {
          tempDisc.push({
            text: disc.promoCode,
            value: disc.discountAmount,
          });
        });
      }
      setDiscountInfo(tempDisc);
    }
  }, [order]);

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader style={{ backgroundColor: "#F0F0F0" }}>
                <h5>Discount Information</h5>
              </CardHeader>
              <CardBody>
                <Row
                  style={{
                    backgroundColor: "#F0F0F0",
                    border: "1px solid #D8D8D8",
                    borderBottom: "0",
                  }}
                  className="p-2">
                  <Col lg={6}>
                    <Text className="title">Voucher Code</Text>
                  </Col>
                  <Col lg={6}>
                    <Text className="title"> Discount</Text>
                  </Col>
                </Row>
                {discountInfo.map((data, idx) => (
                  <Row
                    style={{
                      border: "1px solid #D8D8D8",
                    }}
                    className="p-2"
                    key={idx}>
                    <Col lg={6}>
                      <Text>{data.text}</Text>
                    </Col>
                    <Col lg={6}>
                      <Text>- Rp.{formatter.format(data.value)}</Text>
                    </Col>
                  </Row>
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DiscInfo;
