import { EDIT_LOYALTY_REDEMPTION } from "../actionTypes";

const initialState = {
  loyaltyRedemption: "",
};

function loyaltyReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_LOYALTY_REDEMPTION:
      return { ...state, loyaltyRedemption: action.payload };
    default:
      return state;
  }
}

export default loyaltyReducer;
