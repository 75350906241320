import jsPDF from "jspdf";
import "jspdf-barcode";
import LogoPbp from "../../assets/images/logo-pbp.png";

const StickerGenerator = (data) => {
  const doc = new jsPDF("l", "cm", [8, 10]);
  const orderCategory = [
    ...data.orderDetail
      .reduce((map, item) => map.set(item.productCategory, item), new Map())
      .values(),
  ]
    .map((each) => each.productCategory)
    .join(", ")
    .replace(/(.{34})..+/, "$1…");
  const categoryWrap = doc.splitTextToSize(orderCategory, 6);
  const noteWrap = doc.splitTextToSize(
    data.shippingAddress.note.substring(0, 36),
    5
  );
  const addressWrap = data.shippingAddress.fullAddress
    .substring(0, 100)
    .replace(/(.{40})/g, "$1\r\n");

  doc.addImage(
    data.shippingMethod.iconPath,
    "JPEG",
    data.shippingMethod.provider === "Parama"
      ? 6.2
      : data.shippingMethod.provider === "JNT"
      ? 8.5
      : 8.7,
    0.1,
    0,
    0.7
  );

  doc.setLineWidth(0.01);
  doc.setFontSize("8");
  doc.setFont("helvetica", "normal", "bold");

  if (!data?.isDropship) {
    doc.addImage(LogoPbp, "png", 0.25, 0.25, 2, 0.8);
  }

  if (data.shippingMethod.provider === "Grab") {
    doc.text(data.shippingMethodId.split("-")[1].toUpperCase(), 8.3, 1.25);
    doc.line(8.2, 0.9, 9.8, 0.9);
    doc.line(8.2, 1.4, 9.8, 1.4);
    doc.line(8.2, 0.9, 8.2, 1.4);
    doc.line(9.8, 0.9, 9.8, 1.4);
  } else {
    doc.text(data.shippingMethodId.split("-")[1].toUpperCase(), 8.95, 1.25);
    doc.line(8.7, 0.9, 9.8, 0.9);
    doc.line(8.7, 1.4, 9.8, 1.4);
    doc.line(8.7, 0.9, 8.7, 1.4);
    doc.line(9.8, 0.9, 9.8, 1.4);
  }

  doc.setFont("helvetica", "normal", "normal");
  if (data.shippingMethod.provider !== "JNT") {
    if (data.shippingMethod.provider === "Grab") {
      doc.text(`AWB Number : ${data.shippingVendorData.awb}`, 2.2, 1.8);
    } else {
      doc.text(`AWB Number : ${data.shippingVendorData.awb}`, 2.8, 1.5);
    }
  }

  if (data.shippingMethod.provider === "JNE") {
    doc.text(
      `${data.shippingAddress.address.jneData.originCode} - ${data.shippingAddress.address.jneData.destinationCode}`,
      3.4,
      1.85
    );
  }

  if (data.shippingMethod.provider === "JNT") {
    doc.setFontSize("24");
    doc.setFont("helvetica", "normal", "bold");
    doc.text("TUMPUK RESI DI SINI", 0.5, 2.85);
  } else {
    if (data.shippingMethod.provider === "Grab") {
      doc.barcode(
        data.shippingVendorData.awb
          ? data.shippingVendorData.awb
          : "1234567890",
        {
          fontSize: "35",
          textColor: "black",
          x: 0.1,
          y: 3.2,
        }
      );
    } else {
      doc.barcode(
        data.shippingVendorData.awb
          ? data.shippingVendorData.awb
          : "1234567890",
        {
          fontSize: "60",
          textColor: "black",
          x: 1.4,
          y: 3.85,
        }
      );
    }
  }

  doc.setFontSize("8");
  doc.setFont("helvetica", "normal", "bold");
  doc.text("Penerima :", 0.25, 4.2);
  doc.text("Pengirim :", 6.3, 4.2);
  doc.text("Kategori Produk :", 6.3, 5.2);
  doc.text("Note :", 0.25, 6.7);
  if (data.shippingMethod.provider === "JNT") {
    doc.text("AWB Number :", 4.8, 6.5);
  } else {
    doc.text("No Invoice :", 4.8, 6.5);
  }
  doc.setFontSize("8");
  doc.setFont("helvetica", "normal", "normal");
  doc.text(data.shippingAddress.recipientName, 1.8, 4.2);
  if (data.isDropship) {
    doc.text(data.senderAddress.recipientName, 7.8, 4.2);
    doc.text(`Telp: ${data.senderAddress.recipientPhone}`, 6.3, 4.53);
  } else {
    doc.text(data.warehouse.warehouseName, 7.8, 4.2);
    doc.text(`Telp: ${data.warehouse.phone}`, 6.3, 4.53);
  }
  doc.text(
    `${addressWrap},\r\n${data.shippingAddress.address.district}, ${data.shippingAddress.address.city}\r\n${data.shippingAddress.address.province}, ${data.shippingAddress.address.postalCode}\r\nTelp. ${data.shippingAddress.recipientPhone}`,
    0.25,
    4.53
  );
  doc.text(categoryWrap, 6.3, 5.5);
  doc.text(noteWrap, 0.25, 7);

  if (data.shippingMethod.provider === "JNT") {
    doc.text(data.shippingVendorData.awb, 7, 6.5);
    doc.barcode(data.shippingVendorData.awb, {
      fontSize: "33",
      textColor: "black",
      x: 4.5,
      y: 7.7,
    });
  } else {
    doc.text(data.invoiceNumber, 6.5, 6.5);
    doc.barcode(data.invoiceNumber, {
      fontSize: "33",
      textColor: "black",
      x: 3.5,
      y: 7.7,
    });
  }
  doc.save(`Sticker - ${data.invoiceNumber}.pdf`);
};

export default StickerGenerator;
