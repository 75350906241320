import { Flex, Box, Text, Input, Button, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
import BannerImageUpload from "./components/CampaignProductCatalog/BannerImageUpload";
import CampaignProductCatalog from "./components/CampaignProductCatalog/CampaignProductCatalog";
import "./style/AddCampaign.css";
import Axios from "../../services/axios";
import PublishSchedule from "./components/CampaignProductCatalog/PublishSchedule";
import { cloneDeep, find, isNumber } from "lodash";
import Loader from "../loader/Loader";
import ModalConfirmation from "./components/ModalConfirmation";

function AddCampaign() {
  const [inputData, setInputData] = useState({
    name: "",
    startDate: "",
    endDate: "",
    campaignProducts: [
      {
        discount: 0,
        price: "",
        product: "",
        img: "",
      },
    ],
  });
  const [bannerDesktop, setBannerDesktop] = useState({ img: "" });
  const [bannerMobile, setBannerMobile] = useState({ img: "" });
  const toast = useToast();
  const { id } = useParams();
  const [allProduct, setAllProduct] = useState([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalConfirmation, setIsOpenModalConfirmation] = useState(false);

  const fecthDataProduct = async () => {
    try {
      const { data } = await Axios({
        url: "/product/list?sort=name,asc",
        method: "get",
      });
      const products = data.data.products.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.id,
        };
      });
      setAllProduct(products);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchCampaignId = async (campaignId) => {
    setIsLoading(true);
    try {
      const { data } = await Axios({
        url: `/campaign/${campaignId}`,
        method: "GET",
      });

      let tempInput = cloneDeep(inputData);
      Object.keys(data.data.campaign).forEach((key) => {
        if (key === "posterDesktopPath") {
          setBannerDesktop({ ...bannerDesktop, img: data.data.campaign[key] });
        }

        if (key === "posterMobilePath") {
          setBannerMobile({ ...bannerMobile, img: data.data.campaign[key] });
        }

        if (
          key !== "id" &&
          key !== "slug" &&
          key !== "totalUniqueVisitor" &&
          key !== "timeoutDuration" &&
          key !== "isActive" &&
          key !== "posterDesktopPath" &&
          key !== "posterMobilePath" &&
          key !== "createdAt" &&
          key !== "updatedAt"
        ) {
          if (key === "campaignProducts") {
            tempInput[key] = data.data.campaign[key].map((item) => {
              return {
                discount: item.discountPercentage,
                price: parseInt(
                  (
                    item.finalPrice /
                    ((100 - item.discountPercentage) / 100)
                  ).toFixed(0)
                ),
                product: find(allProduct, { id: item.productId }),
                img: item.campaignCardPath,
                finalPrice: item.finalPrice,
              };
            });
          } else {
            tempInput[key] = data.data.campaign[key];
          }
        }
      });
      setInputData(tempInput);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchCampaignId(id);
    }
  }, [id, allProduct]);

  useEffect(() => {
    fecthDataProduct();
    return () => {
      setInputData({
        name: "",
        startDate: "",
        endDate: "",
        campaignProducts: [
          {
            discount: "",
            price: "",
            product: "",
            img: "",
          },
        ],
      });
      setBannerDesktop("");
      setBannerMobile("");
    };
  }, []);

  const handleAddCatalog = () => {
    let tempInput = cloneDeep(inputData);
    const keys = Object.keys(tempInput.campaignProducts[0]);
    let tempObj = {};

    keys.forEach((key) => {
      tempObj[key] = "";
    });

    tempInput.campaignProducts.push(tempObj);
    setInputData(tempInput);
  };

  const handleDeleteCatalog = (idx) => {
    let tempInput = cloneDeep(inputData);
    let temp = [];
    tempInput.campaignProducts.forEach((data, index) => {
      if (idx !== index) {
        temp.push(data);
      }
    });
    tempInput.campaignProducts = temp;
    toast({
      title: "Success.",
      description: `Catalog ke-${idx + 1} berhasil di hapus`,
      status: "success",
      position: "top",
      duration: 2000,
      isClosable: true,
    });

    setInputData(tempInput);
  };

  const handleValidation = () => {
    let validObj = {
      status: "success",
      message: "Campaign berhasil disubmit.",
    };

    if (!inputData.name) {
      validObj.status = "warning";
      validObj.message = "Mohon isi nama campaign.";
    } else if (!inputData.startDate) {
      validObj.status = "warning";
      validObj.message = "Mohon isi tanggal mulai.";
    } else if (!inputData.endDate) {
      validObj.status = "warning";
      validObj.message = "Mohoon isi tanggal berakhir.";
    } else if (!bannerMobile) {
      validObj.status = "warning";
      validObj.message = "Mohon isi banner mobile.";
    } else if (!bannerDesktop) {
      validObj.status = "warning";
      validObj.message = "Mohon isi banner desktop.";
    } else {
      let i = 0;
      let isValid = true;

      while (inputData.campaignProducts.length > i && isValid) {
        const data = inputData.campaignProducts[i];
        if (!isNumber(data.discount)) {
          isValid = false;
          validObj.status = "warning";
          validObj.message = "Mohon isi discount product.";
        } else if (!data.img) {
          isValid = false;
          validObj.status = "warning";
          validObj.message = "Mohon isi image product.";
        } else if (!data.product) {
          isValid = false;
          validObj.status = "warning";
          validObj.message = "Mohon pilih product.";
        }
        i++;
      }
    }

    return validObj;
  };

  const handleSubmit = async () => {
    const validation = handleValidation();

    if (validation.status !== "warning") {
      try {
        let postData = cloneDeep(inputData);
        postData.campaignProducts = postData.campaignProducts.map((item) => {
          return {
            productId: item.product.id,
            discountPercentage: item.discount,
            finalPrice: item.discount ? item.finalPrice : item.price,
            campaignCardPath: item.img,
          };
        });
        let campaignId = id ? id : null;
        if (campaignId) {
          delete postData.startDate;
          await Axios({
            url: `/campaign/${campaignId}`,
            method: "PATCH",
            data: postData,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });
        } else {
          const { data } = await Axios({
            url: "/campaign",
            method: "POST",
            data: postData,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });
          campaignId = data.data.campaignId;
        }
        if (bannerDesktop.imgSrc) {
          let data = new FormData();
          data.append("image", bannerDesktop.img);
          let config = {
            method: "PATCH",
            url: `/campaign/${campaignId}/upload-poster-desktop`,
            data: data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
          await Axios(config);
        }
        if (bannerMobile.imgSrc) {
          let data = new FormData();
          data.append("image", bannerMobile.img);
          let config = {
            method: "PATCH",
            url: `/campaign/${campaignId}/upload-poster-mobile`,
            data: data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
          await Axios(config);
        }
        toast({
          title: "Sukses.",
          description: validation.message,
          status: validation.status,
          position: "top",
          duration: 2000,
          isClosable: true,
        });

        setTimeout(() => {
          history.push("/campaign/list-campaign");
        }, 2000);
      } catch (err) {
        console.error(err.response);
        toast({
          title: "Error.",
          description: err.response.data.errors[0].message,
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Warning.",
        description: validation.message,
        status: validation.status,
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await Axios({
        url: `/campaign/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      toast({
        title: "Sukses.",
        description: "Berhasil menghapus campaign.",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });

      setTimeout(() => {
        history.push("/campaign/list-campaign");
      }, 2000);
    } catch (err) {
      console.error(err);
      toast({
        title: "Error.",
        description: err.response.data.errors[0].message,
        status: "warning",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => setIsOpenModalConfirmation(false);

  return (
    <Box className="add-campaign__container">
      {isLoading && <Loader />}
      <Flex>
        <Text className="add-campaign__title">Campaign Catalog Management</Text>
      </Flex>

      <Row>
        <Col md="8">
          <Card body>
            <Row style={{ alignItems: "center" }}>
              <Col md="4">
                <Text className="add-campaign__label">Campaign Name</Text>
              </Col>
              <Col md="8">
                <Input
                  placeholder="Write campaign name here ..."
                  value={inputData.name}
                  onChange={(event) =>
                    setInputData({ ...inputData, name: event.target.value })
                  }
                />
              </Col>
            </Row>
          </Card>

          <Card>
            <Text className="add-campaign-catalog__title">
              Campaign Product Catalog
            </Text>

            {inputData.campaignProducts.map((data, idx) => (
              <CampaignProductCatalog
                data={data}
                idx={idx}
                inputData={inputData}
                setInputData={setInputData}
                handleDeleteCatalog={handleDeleteCatalog}
                allProduct={allProduct}
              />
            ))}

            <Flex justify="center" pb="14px">
              <Button onClick={handleAddCatalog} bg={"#FF8084"} color="#ffff">
                Add Product
              </Button>
            </Flex>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <Text className="add-campaign-catalog__title">
              Campaign Banner (Desktop)
            </Text>
            <CardBody>
              <BannerImageUpload
                data={bannerDesktop}
                setData={setBannerDesktop}
              />
            </CardBody>
          </Card>

          <Card>
            <Text className="add-campaign-catalog__title">
              Campaign Banner (Mobile)
            </Text>
            <CardBody>
              <BannerImageUpload
                data={bannerMobile}
                setData={setBannerMobile}
              />
            </CardBody>
          </Card>

          <Card>
            <Text className="add-campaign-catalog__title">
              Publish Schedule
            </Text>
            <CardBody>
              <PublishSchedule
                inputData={inputData}
                setInputData={setInputData}
              />
              <Flex justify="center" mt="1em">
                <Button colorScheme="teal" width="80%" onClick={handleSubmit}>
                  Publish
                </Button>
              </Flex>
            </CardBody>
          </Card>

          {id && (
            <Flex justify="center">
              <Button
                bg={"#FF8084"}
                color="#ffff"
                onClick={() => setIsOpenModalConfirmation(true)}
              >
                Delete Campaign
              </Button>
            </Flex>
          )}
        </Col>
      </Row>

      <ModalConfirmation
        isOpen={isOpenModalConfirmation}
        onClose={handleCloseModal}
        content="Apakah anda yakin ini menghapus campaign ini?"
        buttonContent={"Delete"}
        handleClick={handleDelete}
      />
    </Box>
  );
}

export default AddCampaign;
