import React, { Fragment, useState, useEffect } from "react";

import { EditorState, ContentState, convertToRaw, RichUtils } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import FormData from "form-data";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles/AddNewProduct.css";
import stylesEditor from "./styles/wysiwyg.module.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  Input,
  Flex,
  Spacer,
  IconButton,
  Button,
  Text,
  Select,
  Switch,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { MdDelete, MdAdd, MdClose } from "react-icons/md";
import one from "../../assets/images/pro3/1.jpg";
import user from "../../assets/images/user.png";
import Breadcrumb from "../common/breadcrumb";
import ProductDataGeneral from "./component/ProductDataGeneral";
import ProductDataSKU from "./component/ProductDataSKU";
import ProductDataDetail from "./component/ProductDataDetail";
import ProductDataPrice from "./component/ProductDataPrice";
import Loader from "../loader/Loader";
import Axios from "../../services/axios";
import navguard from "../auth/navguard";
import SectionBanner from "./component/SectionBanner";
import SectionFAQ from "./component/SectionFAQ";
import SectionHTU from "./component/sectionHTU";
import SectionBenefit from "./component/sectionBenefit";
import SectionImportantInformation from "./component/SectionImportantInformation";
import SectionDescription from "./component/SectionDescription";
import { useParams } from "react-router-dom";
import ProductVariantSKU from "./component/ProductVariantSKU";
import { isEmpty, isArray } from "lodash";

const AddNewProduct = () => {
  const toast = useToast();
  const { productId } = useParams();
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [product, setProduct] = useState({
    name: "",
    description: "",
    howToUse: "",
    ingredient: "",
    productHighlight: false,
    productType: "Single",
    brandId: "",
    seriesId: "",
    categoryGroupId: "",
    productCategory: "",
    productSubCategory: "",
    label: "",
    descriptionProductName: "",
    descriptionProductVariantName: "",
    pdpHowToUse: [],
    pdpCautions: [],
    pdpIngredients: [],
    pdpFaqs: [],
    pdpBenefit: [],
    pdpBanner: [],
    pdpBenefitDescription: "",
    productSecondaryPackaging: "",
    productShape: "",
    pdpImportantInformation: {
      texts: [],
      images: [""],
    },
  });

  const [mainImage, setMainImage] = useState({
    img: one,
  });
  const [firstImage, setFirstImage] = useState({
    img: one,
  });

  const [productDetailsImage, setProductDetailsImage] = useState({
    img: one,
  });
  const [bannerImage, setBannerImage] = useState([]);
  const [savedProductThumbnail, setSavedProductThumbnail] = useState(null);
  const [savedProductFirstImage, setSavedProductFirstImage] = useState(null);
  const [galleryImage, setGalleryImage] = useState([]);
  const [deletedGalleryImage, setDeletedGalleryImage] = useState([]);
  const [savedProductGallery, setSavedProductGallery] = useState([]);
  const [faqProduct, setFaqProduct] = useState([]);

  const [selectedLayout, setSelectedLayout] = useState("general");
  const [listTag, setListTag] = useState([]);
  const [listVariant, setListVariant] = useState([
    {
      name: "",
      odooCode: "",
      sku: "",
      barcode: "",
      hexcode: "",
      img: "",
    },
  ]);
  const [deletedListVariant, setDeletedListVariant] = useState([]);
  const [productType, setProductType] = useState("single");
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  /* How to use */
  const [HTU, setHTU] = useState([
    {
      title: "",
      image: "",
      description: "",
      editorState: EditorState.createEmpty(),
    },
  ]);
  const [cautions, setCautions] = useState([""]);

  /* Product Benefit */
  const [benefit, setBenefit] = useState([
    {
      title: "",
      image: "",
      description: "",
      editorState: EditorState.createEmpty(),
    },
  ]);

  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty()
  );

  const imageMaxSize = 2000000;

  const fetchEditor = (articleHTML) => {
    let contentBlock = htmlToDraft(articleHTML);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const article = EditorState.createWithContent(contentState);
      return article;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await Axios({
        method: "GET",
        url: `/product/id/${productId}`,
      });
      if (!result.errorStatus) {
        const tempProduct = {};
        Object.keys(result.data.data?.product).forEach((keys) => {
          if (keys === "productThumbnail") {
            setMainImage({
              img: result.data.data.product[keys]
                ? result.data.data.product[keys]
                : one,
            });
          }

          if (keys === "productFirstImage") {
            setFirstImage({
              img: result.data.data.product[keys]
                ? result.data.data.product[keys]
                : one,
            });
          }

          if (keys === "productGalleries") {
            let images = [];
            if (isArray(result.data.data.product[keys])) {
              result.data.data.product[keys]?.forEach((gallery) => {
                images.push({
                  img: gallery.imagePath,
                  galleryId: gallery.id,
                });
              });
            }
            setGalleryImage(images);
          }

          if (keys === "pdpFaqs") {
            let faqs = [];

            if (isArray(result.data.data.product[keys])) {
              result.data.data.product[keys]?.forEach((faq) => {
                faqs.push({
                  question: faq.question,
                  answer: faq.answer,
                });
              });
            }
            setFaqProduct(faqs);
          }

          if (keys === "pdpHowToUse") {
            let tempHTU = [];
            if (isArray(result.data.data.product[keys])) {
              result.data.data.product[keys]?.forEach((each) => {
                tempHTU.push({
                  title: each.title,
                  image: each.image,
                  description: each.description,
                  editorState: fetchEditor(each.description),
                });
              });
            }
            setHTU(tempHTU);
          }

          if (keys === "pdpCautions") {
            let tempCautions = [];
            if (isArray(result.data.data.product[keys])) {
              result.data.data.product[keys]?.forEach((each) => {
                tempCautions.push(each);
              });
            }
            setCautions(tempCautions);
          }

          if (keys === "pdpBenefit") {
            let tempBenefit = [];
            if (isArray(result.data.data.product[keys])) {
              result.data.data.product[keys]?.forEach((each) => {
                tempBenefit.push({
                  title: each.title,
                  image: each.image,
                  description: each.description,
                  editorState: fetchEditor(each.description),
                });
              });
            }
            setBenefit(tempBenefit);
          }

          if (keys === "pdpBanner") {
            setBannerImage(result.data.data.product[keys]);
          }

          if (keys === "pdpThumbnail") {
            setProductDetailsImage({
              ...productDetailsImage,
              img:
                result.data.data.product[keys]?.length > 0
                  ? result.data.data.product[keys][0].image
                  : one,
            });
          }

          if (keys === "productVariants") {
            let tempVariant = [];
            if (isArray(result.data.data.product[keys])) {
              result.data.data.product[keys]?.forEach((each) => {
                tempVariant.push({
                  name: each.name,
                  odooCode: each.odooCode,
                  sku: each.sku,
                  hexcode: each.hexCode,
                  img: each.variantThumbnail,
                  barcode: each.barcode,
                  idVariant: each.id,
                  salePrice: each.salePrice,
                  price: each.price,
                  discount: each.discount,
                  weight: each.weight,
                });
                if (each.hexcode) {
                  tempProduct.variantType = "color";
                }
              });
            }
            setListVariant(tempVariant);
          }

          if (
            keys !== "id" &&
            keys !== "slug" &&
            keys !== "productVariants" &&
            keys !== "createdAt" &&
            keys !== "updatedAt" &&
            keys !== "productThumbnail" &&
            keys !== "productFirstImage" &&
            keys !== "productGalleries" &&
            keys !== "pdpFaqs" &&
            keys !== "pdpBanner" &&
            keys !== "pdpThumbnail" &&
            keys !== "totalRating" &&
            keys !== "avgRating" &&
            keys !== "totalSale" &&
            keys !== "averageReviewScore"
          ) {
            tempProduct[keys] = result.data.data.product[keys];
          }
        });

        setProduct(tempProduct);
        setListTag(result?.data?.data?.product?.productTag);
        setIsLoading(false);
      }
    };

    if (productId) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [productId]);

  useEffect(() => {
    const convertHtmlToDraft = (articleHTML, editorName) => {
      let contentBlock = htmlToDraft(articleHTML);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const article = EditorState.createWithContent(contentState);
        if (editorName === "description") {
          setEditorStateDesc(article);
        }
      }
    };

    if (isFirstLoad && product.name) {
      convertHtmlToDraft(
        product.description ? product.description : "",
        "description"
      );
      setIsFirstLoad(false);
    }
  }, [product, isFirstLoad]);

  const _handleImgChange = (value, i) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (value.currentTarget.files.item(0).size > imageMaxSize) {
        toast({
          title: "Error.",
          description: "Mohon upload gambar berukuran kurang dari 2MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        const tempGallery = [...galleryImage];
        const tempSavedGalery = [...savedProductGallery];
        let reader = new FileReader();
        const image = value.target.files[0];
        tempSavedGalery.push(image);
        setSavedProductGallery(tempSavedGalery);
        reader.onload = () => {
          tempGallery[i].img = reader.result;
          setFile({ file: file });
          setGalleryImage(tempGallery);
        };
        reader.readAsDataURL(image);
      }
    }
  };

  const handleMainImageChange = (value, type) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (value.currentTarget.files.item(0).size > imageMaxSize) {
        toast({
          title: "Error.",
          description: "Mohon upload gambar berukuran kurang dari 2MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        let reader = new FileReader();
        const image = value.target.files[0];
        if (type === "thumbnail") {
          setSavedProductThumbnail(image);
          reader.onload = () => {
            setMainImage({
              img: reader.result,
            });
          };
        } else {
          setSavedProductFirstImage(image);
          reader.onload = () => {
            setFirstImage({
              img: reader.result,
            });
          };
        }

        reader.readAsDataURL(image);
      }
    }
  };

  const deleteMainImage = (type) => {
    if (type === "thumbnail") {
      document.getElementById("main-image-input").value = null;
      setMainImage({
        img: one,
      });
      setSavedProductThumbnail(null);
    } else {
      document.getElementById("first-image-input").value = null;
      setFirstImage({
        img: one,
      });
      setSavedProductFirstImage(null);
    }
  };

  const addGallery = () => {
    const temp = [...galleryImage];
    temp.push({
      img: user,
    });

    setGalleryImage(temp);
  };

  const deleteGallery = (i) => {
    const temp = [...galleryImage];
    const result = [];
    temp.forEach((item, index) => {
      if (index !== i) {
        result.push(item);
      }
    });

    if (temp[i].galleryId && productId) {
      const tempDeleted = [...deletedGalleryImage];
      tempDeleted.push(temp[i].galleryId);
      setDeletedGalleryImage(tempDeleted);
    }

    setGalleryImage(result);
  };

  const changeHandler = (e) => {
    const productObj = { ...product };

    if (e.target.name === "productType") {
      setProductType(e.target.value);
    }

    if (e.target.name === "brandId") {
      productObj.seriesId = "";
    }

    if (e.target.name === "categoryGroupId") {
      productObj.productCategory = "";
      productObj.productSubCategory = "";
    }

    if (e.target.name === "productCategory") {
      productObj.productSubCategory = "";
    }

    let value = e.target.value;
    if (e.target.type === "number") {
      if (value === "") {
        value = null;
      } else {
        value = parseFloat(e.target.value);
      }
    }

    if (e.target.name === "productHighlight") {
      value = !productObj.productHighlight;
    }

    productObj[e.target.name] = value;

    if (productObj.price && productObj.discount) {
      let salePrice = (productObj.price * (100 - productObj.discount)) / 100;
      productObj.salePrice = parseInt(salePrice.toFixed(0));
    } else {
      productObj.salePrice = productObj.price;
    }

    if (productObj.price && productObj.resellerDiscount) {
      let resellerPrice =
        (productObj.salePrice * (100 - productObj.resellerDiscount)) / 100;
      productObj.priceReseller = parseInt(resellerPrice.toFixed(0));
    } else {
      productObj.priceReseller = productObj.salePrice;
    }
    setProduct(productObj);
  };

  const changeProductLabel = (event) => {
    const productObj = { ...product };
    productObj.label = event.target.value;
    setProduct(productObj);
  };

  const editorDescriptionChangeHandler = (editorState) => {
    setEditorStateDesc(editorState);
    const productObj = { ...product };
    productObj.description = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setProduct(productObj);
  };

  const editorHowToUseDescChangeHandler = (editorState, index) => {
    let temp = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let tempHTU = [...HTU];
    tempHTU[index]["editorState"] = editorState;
    tempHTU[index]["description"] = temp;
    setHTU(tempHTU);
  };

  const editorStateBenefitDescHandler = (editorState, index) => {
    let temp = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let tempBenefit = [...benefit];
    tempBenefit[index]["editorState"] = editorState;
    tempBenefit[index]["description"] = temp;
    setBenefit(tempBenefit);
  };

  const keyPress = (e, tag) => {
    if (e.target.name === "productTag") {
      if (e.key === "Enter") {
        const temp = [...listTag];
        const productObj = { ...product };
        e.preventDefault();
        temp.push(tag);
        setListTag(temp);

        productObj.productTag = temp.join(", ");
        setProduct(productObj);
      }
    }
  };

  const deleteTag = (deleteIndex) => {
    const temp = [];
    listTag.forEach((item, index) => {
      if (deleteIndex !== index) {
        temp.push(item);
      }
    });

    setListTag(temp);
  };

  const addVariant = () => {
    const temp = [...listVariant];
    const obj = {};
    Object.keys(listVariant[0]).forEach((key) => {
      if (key !== "idVariant") {
        obj[key] = "";
      }
    });
    temp.push(obj);
    setListVariant(temp);
  };

  const deleteVariant = (deleteIndex) => {
    let temp = [];
    let tempVariantId = [];
    if (listVariant.length > 1) {
      listVariant.forEach((item, index) => {
        if (deleteIndex !== index) {
          temp.push(item);
        }
        if (deleteIndex === index) {
          if (item?.idVariant) {
            tempVariantId.push(item.idVariant);
          }
        }
      });
      setListVariant(temp);
      setDeletedListVariant(tempVariantId);
    } else {
      toast({
        title: "Warning.",
        description: "Tidak dapat mengurangi variant produk.",
        status: "warning",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const submitValidation = () => {
    let statusValidation = {
      status: "success",
      message: "Produk berhasil disubmit.",
    };
    const isValidFaq = validateFaq();
    const isValidBanner = validateBanner();
    const isValidVariant = validateProductVariant();
    if (!product.name) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon isi nama produk";
    } else if (product.productType !== "Variant" && !product.price) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon isi harga produk";
    } else if (!product.productStatus) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon isi status produk";
    } else if (!product.weight) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon isi berat produk";
    } else if (mainImage.img === one) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon upload gambar untuk produk";
    } else if (firstImage.img === one) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon upload first image untuk produk";
    } else if (product.productType !== "Variant" && !product.barcode) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon isi barcode produk";
    } else if (isValidFaq.status === "warning") {
      statusValidation.status = isValidFaq.status;
      statusValidation.message = isValidFaq.message;
    } else if (isValidBanner.status === "warning") {
      statusValidation.status = isValidBanner.status;
      statusValidation.message = isValidBanner.message;
    } else if (
      product.productType === "Variant" &&
      isValidVariant.status === "warning"
    ) {
      statusValidation.status = isValidVariant.status;
      statusValidation.message = isValidVariant.message;
    }
    return statusValidation;
  };

  const getProductWithoutNullAttribute = () => {
    const tempProduct = {};
    const tempHTU = [];
    const tempBenefit = [];
    HTU.forEach((each) => {
      tempHTU.push({
        title: each.title,
        description: each.description,
        image: each.image,
      });
    });
    benefit.forEach((each) => {
      tempBenefit.push({
        title: each.title,
        description: each.description,
        image: each.image,
      });
    });
    Object.keys(product).forEach((keys) => {
      if (
        keys !== "productHighlight" &&
        keys !== "resellerDiscount" &&
        keys !== "discount"
      ) {
        if (product[keys]) {
          tempProduct[keys] = product[keys];
        }
      } else {
        if (keys === "discount" && !product[keys]) {
          tempProduct[keys] = 0;
        } else {
          tempProduct[keys] = product[keys];
        }
      }
    });

    if (productDetailsImage.img !== one) {
      const tempPdpThumbnail = [];
      tempPdpThumbnail.push({ image: productDetailsImage.img });
      tempProduct["pdpThumbnail"] = tempPdpThumbnail;
    }

    delete tempProduct["productCategoryGroup"];
    delete tempProduct["categoryGroup"];
    delete tempProduct["series"];
    delete tempProduct["brand"];
    delete tempProduct["brandName"];
    delete tempProduct["seriesName"];
    delete tempProduct["productThumbnailSource"];

    if (tempProduct["productType"] === "Variant") {
      delete tempProduct["price"];
      delete tempProduct["salePrice"];
    }

    tempProduct["pdpFaqs"] = faqProduct;
    tempProduct["pdpHowToUse"] = tempHTU;
    tempProduct["pdpCautions"] = cautions;
    tempProduct["pdpBenefit"] = tempBenefit;
    tempProduct["pdpBanner"] = bannerImage;

    return tempProduct;
  };

  const parseError = (response) => {
    if (response) {
      if (response.data.errors) {
        if (response.data.errors[0].message) {
          return response.data.errors[0].message;
        } else {
          return response.data.errors[0];
        }
      } else {
        if (response.data.includes("Validation error")) {
          return "Nama produk tidak boleh duplikat";
        } else {
          return "Terjadi kesalahan silahkan coba beberapa saat lagi";
        }
      }
    } else {
      return "Terjadi kesalahan silahkan coba beberapa saat lagi";
    }
  };

  const submitProduct = async () => {
    let isError = false;
    setIsLoading(true);
    let responseValidation = submitValidation();
    if (responseValidation.status === "success") {
      const cleanProduct = getProductWithoutNullAttribute();

      //temporary until product tag fixed
      delete cleanProduct.productTag;
      delete cleanProduct.ratingOverview;

      let usedProductId = productId;
      try {
        if (productId) {
          await Axios({
            method: "PATCH",
            url: `/product/${productId}`,
            data: cleanProduct,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });

          if (!isEmpty(deletedGalleryImage)) {
            for (let i = 0; i < deletedGalleryImage.length; i += 1) {
              await Axios({
                method: "DELETE",
                url: `/product/${productId}/gallery/${deletedGalleryImage[i]}`,
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
              });
            }
          }

          if (deletedListVariant.length > 0) {
            for (let i = 0; i < deletedListVariant.length; i++) {
              await Axios({
                method: "DELETE",
                url: `/product/${productId}/variant/${deletedListVariant[i]}`,
              });
            }
          }
        } else {
          let result = await Axios({
            method: "POST",
            url: "/product",
            data: cleanProduct,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });
          usedProductId = result.data.data.productId;
        }

        if (savedProductThumbnail) {
          let data = new FormData();
          data.append("image", savedProductThumbnail);

          let config = {
            method: "POST",
            url: `/product/${usedProductId}/thumbnail`,
            data: data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };

          await Axios(config);
        }

        if (savedProductFirstImage) {
          let data = new FormData();
          data.append("image", savedProductFirstImage);

          let config = {
            method: "POST",
            url: `/product/${usedProductId}/first-image`,
            data: data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };

          await Axios(config);
        }

        if (savedProductGallery.length) {
          let data;
          let config;

          for (let i = 0; i < savedProductGallery.length; i += 1) {
            data = new FormData();
            data.append("image", savedProductGallery[i]);

            config = {
              method: "POST",
              url: `/product/${usedProductId}/gallery`,
              data: data,
              headers: {
                Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              },
            };

            await Axios(config);
          }
        }
        if (cleanProduct.productType === "Variant") {
          let data;
          for (let i = 0; i < listVariant.length; i += 1) {
            data = {
              name: listVariant[i].name,
              sku: listVariant[i].sku,
              barcode: listVariant[i].barcode,
              odooCode: listVariant[i].odooCode,
              price: listVariant[i].price,
              discount: listVariant[i].discount,
              salePrice: listVariant[i].salePrice,
              weight: listVariant[i].weight,
            };
            if (listVariant[i].hexcode) {
              data.hexCode = listVariant[i].hexcode;
            }
            if (listVariant[i].idVariant) {
              await Axios({
                method: "PATCH",
                url: `/product/${usedProductId}/variant/${listVariant[i].idVariant}`,
                data: data,
              });

              if (listVariant[i].img && listVariant[i].imgSrc) {
                let file = new FormData();
                file.append("image", listVariant[i].img);
                await Axios({
                  url: `/product/${usedProductId}/variant/${listVariant[i].idVariant}/thumbnail`,
                  method: "POST",
                  data: file,
                });
              }
            } else {
              const response = await Axios({
                method: "POST",
                url: `/product/${usedProductId}/variant`,
                data: data,
              });

              if (listVariant[i].img && listVariant[i].imgSrc) {
                let file = new FormData();
                file.append("image", listVariant[i].img);

                await Axios({
                  method: "POST",
                  url: `/product/${usedProductId}/variant/${response.data.data.variantId}/thumbnail`,
                  data: file,
                });
              }
            }
          }
        }
      } catch (e) {
        const error = parseError(e.response);
        toast({
          title: "Error.",
          description: error,
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        isError = true;
      }

      if (!isError) {
        toast({
          title: "Sukses.",
          description: "Produk berhasil disubmit.",
          status: "success",
          position: "top",
          duration: 2000,
          isClosable: true,
        });

        setTimeout(() => {
          window.location.href = "/products/all-products";
        }, 2000);
      }
    } else {
      toast({
        title: "Error.",
        description: responseValidation.message,
        status: responseValidation.status,
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  const _uploadImageCallBack = (file) => {
    // convert to base64
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onerror = function (error) {
      console.error("Error onload: ", error);
    };

    return new Promise((resolve, reject) => {
      reader.onload = function () {
        resolve({
          data: {
            link: reader.result,
          },
        });
      };
    });
  };

  const _toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(editorStateDesc, blockType));
  };

  const config = {
    image: {
      uploadCallback: _uploadImageCallBack,
      previewImage: true,
      alt: { present: true, mandatory: false },
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    },
    list: {
      inDropdown: true,
      toggleBlockType: _toggleBlockType,
      unordered: { className: stylesEditor["unordered-list"] },
      ordered: { className: stylesEditor["ordered-list"] },
    },
    textAlign: { inDropdown: true },
    blockType: {
      className: "demo-option-custom-wide",
      dropdownClassName: "demo-dropdown-custom",
    },
  };

  const validateFaq = () => {
    const tempFaq = [...faqProduct];
    let index = 0;
    let statusValidation = {
      status: "success",
      message: "Faq berhasil ditambahkan.",
    };
    let isValid = true;
    while (index < tempFaq.length && isValid) {
      if (!tempFaq[index].question) {
        statusValidation.status = "warning";
        statusValidation.message = `Mohon isi pertanyaan (${
          index + 1
        }) terlebih dahulu`;
        isValid = false;
      } else if (!tempFaq[index].answer) {
        statusValidation.status = "warning";
        statusValidation.message = `Mohon isi jawaban (${
          index + 1
        }) terlebih dahulu`;
        isValid = false;
      }
      index++;
    }
    return statusValidation;
  };

  const validateBenefit = () => {
    const tempBenefit = [...benefit];
    let index = 0;
    let statusValidation = {
      status: "success",
      message: "Benefit berhasil ditambahkan",
    };
    let isValid = true;
    while (index < tempBenefit.length && isValid) {
      if (!tempBenefit[index].title) {
        statusValidation.status = "warning";
        statusValidation.message =
          "Mohon isi field benefit title terlebih dahulu";
        isValid = false;
      } else if (!tempBenefit[index].description) {
        statusValidation.status = "warning";
        statusValidation.message =
          "Mohon isi field benefit description terlebih dahulu";
        isValid = false;
      }
      index++;
    }
    return statusValidation;
  };

  const validateHTU = () => {
    const tempHTU = [...HTU];
    let index = 0;
    let statusValidation = {
      status: "success",
      message: "How to use berhasil ditambahkan",
    };
    let isValid = true;
    while (index < tempHTU.length && isValid) {
      if (!tempHTU[index].title) {
        statusValidation.status = "warning";
        statusValidation.message =
          "Mohon isi field how to use title terlebih dahulu";
        isValid = false;
      } else if (!tempHTU[index].description) {
        statusValidation.status = "warning";
        statusValidation.message =
          "Mohon isi field how to use description terlebih dahulu";
        isValid = false;
      }
      index++;
    }
    return statusValidation;
  };

  const validateBanner = () => {
    const tempBanner = [...bannerImage];
    let index = 0;
    let isValid = true;
    let statusValidation = {
      status: "success",
      message: "Banner berhasil ditambahkan.",
    };
    while (index < tempBanner.length && isValid) {
      if (bannerImage[index].image === one) {
        isValid = false;
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload banner terlebih dahulu";
      }
      index++;
    }
    return statusValidation;
  };

  const validateProductVariant = () => {
    const tempVariant = [...listVariant];
    let index = 0;
    let isValid = true;
    let statusObj = {
      status: "success",
      message: "Banner berhasil ditambahkan.",
    };
    while (index < tempVariant.length && isValid) {
      if (!tempVariant[index].name) {
        isValid = false;
        statusObj.status = "warning";
        statusObj.message = `Mohon mengisi nama variant`;
      } else if (!tempVariant[index].barcode) {
        isValid = false;
        statusObj.status = "warning";
        statusObj.message = `Mohon mengisi barcode variant`;
      } else if (!tempVariant[index].odooCode) {
        isValid = false;
        statusObj.status = "warning";
        statusObj.message = `Mohon mengisi kode odoo variant`;
      }
      // else if (!tempVariant[index].sku) {
      //   isValid = false;
      //   statusObj.status = "warning";
      //   statusObj.message = `Mohon mengisi sku variant`;
      // }
      else if (!tempVariant[index].price) {
        isValid = false;
        statusObj.status = "warning";
        statusObj.message = `Mohon mengisi harga variant`;
      } else if (
        product.variantType === "color" &&
        !tempVariant[index].hexcode
      ) {
        isValid = false;
        statusObj.status = "warning";
        statusObj.message = `Mohon mengisi hexcode variant`;
      }
      index++;
    }

    return statusObj;
  };

  return (
    <Fragment>
      {isLoading && <Loader />}
      <Breadcrumb
        title={`${productId ? "Edit" : "Add"} Product`}
        parent="Products"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="8" md="8" lg="8">
            <Card>
              <CardBody>
                <Row
                  className="product-adding"
                  style={{ marginBottom: "16px " }}>
                  <Col xl={12}>
                    <Input
                      className="form-control"
                      name="name"
                      placeholder="PRODUCT NAME"
                      size="lg"
                      onChange={(e) => changeHandler(e)}
                      value={product.name}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row
                  style={{
                    borderBottom: "1px solid rgba(0,0,0,0.2)",
                  }}>
                  <Col xl={2}>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginBottom: "16px",
                        color: "black",
                        lineHeight: "40px",
                      }}>
                      Product Data
                    </p>
                  </Col>
                  <Col xl={4}>
                    <Select
                      value={product.productType}
                      onChange={(e) => changeHandler(e)}
                      name="productType"
                      flex="3"
                      isDisabled={productId}>
                      <option value="Single">Single</option>
                      <option value="Variant">Variant</option>
                      <option value="GWP">GWP</option>
                      <option value="Bundle">Bundle</option>
                    </Select>
                  </Col>
                  {product.productType === "Variant" && (
                    <Col xl={2}>
                      <Checkbox
                        isChecked={product.variantType === "color"}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            variantType: e.target.checked ? "color" : null,
                          })
                        }>
                        Color Variant
                      </Checkbox>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col xl={2} style={{ borderRight: "1px solid #FF8084" }}>
                    <Row>
                      <Col xl={12} style={{ paddingRight: "0px" }}>
                        <Button
                          variant="ghost"
                          justifyContent="flex-start"
                          backgroundColor={
                            selectedLayout === "general" ? "#FF8084" : "white"
                          }
                          color={
                            selectedLayout === "general" ? "white" : "black"
                          }
                          style={{
                            borderRadius: "0px",
                            width: "100%",
                          }}
                          onClick={() => setSelectedLayout("general")}>
                          General
                        </Button>
                      </Col>
                      <Col xl={12} style={{ paddingRight: "0px" }}>
                        <Button
                          variant="ghost"
                          justifyContent="flex-start"
                          backgroundColor={
                            selectedLayout === "SKU" ? "#FF8084" : "white"
                          }
                          color={selectedLayout === "SKU" ? "white" : "black"}
                          style={{
                            borderRadius: "0px",
                            width: "100%",
                          }}
                          onClick={() => setSelectedLayout("SKU")}>
                          SKU
                        </Button>
                      </Col>
                      <Col xl={12} style={{ paddingRight: "0px" }}>
                        <Button
                          variant="ghost"
                          justifyContent="flex-start"
                          backgroundColor={
                            selectedLayout === "details" ? "#FF8084" : "white"
                          }
                          color={
                            selectedLayout === "details" ? "white" : "black"
                          }
                          style={{
                            borderRadius: "0px",
                            width: "100%",
                          }}
                          onClick={() => setSelectedLayout("details")}>
                          Details
                        </Button>
                      </Col>
                      <Col
                        xl={12}
                        style={
                          product?.productType === "Variant"
                            ? { display: "none" }
                            : { paddingRight: "0px" }
                        }>
                        <Button
                          variant="ghost"
                          justifyContent="flex-start"
                          backgroundColor={
                            selectedLayout === "price" ? "#FF8084" : "white"
                          }
                          color={selectedLayout === "price" ? "white" : "black"}
                          style={{
                            borderRadius: "0px",
                            width: "100%",
                          }}
                          onClick={() => setSelectedLayout("price")}>
                          Price
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xl={10}
                    className={
                      product?.productType === "Variant"
                        ? "container-variant"
                        : ""
                    }>
                    {selectedLayout === "general" && (
                      <ProductDataGeneral
                        product={product}
                        deleteTag={deleteTag}
                        changeHandler={changeHandler}
                        keyPress={keyPress}
                        listTag={listTag}
                        changeProductLabel={changeProductLabel}
                      />
                    )}
                    {selectedLayout === "SKU" &&
                      product.productType !== "Variant" && (
                        <ProductDataSKU
                          product={product}
                          changeHandler={changeHandler}
                        />
                      )}
                    {selectedLayout === "SKU" &&
                      product?.productType === "Variant" && (
                        <>
                          {listVariant.map((data, index) => (
                            <ProductVariantSKU
                              variantType={product?.variantType}
                              deleteVariant={deleteVariant}
                              index={index}
                              data={data}
                              listVariant={listVariant}
                              setListVariant={setListVariant}
                            />
                          ))}
                          <Button
                            variant="ghost"
                            justifyContent="center"
                            backgroundColor={"#FF8084"}
                            color={"white"}
                            onClick={() => addVariant()}>
                            Add Variant
                          </Button>
                        </>
                      )}

                    {selectedLayout === "details" && (
                      <ProductDataDetail
                        product={product}
                        changeHandler={changeHandler}
                      />
                    )}
                    {selectedLayout === "price" && (
                      <ProductDataPrice
                        product={product}
                        changeHandler={changeHandler}
                      />
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <SectionDescription
              config={config}
              editorDescriptionChangeHandler={editorDescriptionChangeHandler}
              editorStateDesc={editorStateDesc}
            />
            <SectionImportantInformation
              product={product}
              setProduct={setProduct}
            />
            <SectionHTU
              HTU={HTU}
              setHTU={setHTU}
              validateHTU={validateHTU}
              editorHowToUseDescChangeHandler={editorHowToUseDescChangeHandler}
              cautions={cautions}
              setCautions={setCautions}
            />
            <SectionBenefit
              product={product}
              setProduct={setProduct}
              benefit={benefit}
              setBenefit={setBenefit}
              validateBenefit={validateBenefit}
              editorStateBenefitDescHandler={editorStateBenefitDescHandler}
            />
            <SectionFAQ
              data={faqProduct}
              setData={setFaqProduct}
              type={"product"}
              validateFaq={validateFaq}
            />
          </Col>
          <Col sm="4" md="4" lg="4">
            <Card>
              <CardBody>
                <Flex>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "16px",
                      color: "black",
                    }}>
                    Publish
                  </p>
                  <Spacer />
                </Flex>
                <Flex>
                  <Text
                    flex="2"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "black",
                      lineHeight: "40px",
                    }}>
                    Product Status :
                  </Text>
                  <Select
                    value={product.productStatus}
                    flex="3"
                    placeholder="Product Status"
                    name="productStatus"
                    onChange={(e) => changeHandler(e)}>
                    <option value="Published">Published</option>
                    <option value="Draft">Draft</option>
                    <option value="Hidden">Hidden</option>
                  </Select>
                </Flex>
                <Flex mt={3}>
                  <Text
                    flex="2"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "black",
                      lineHeight: "40px",
                    }}>
                    Product Highlight :
                  </Text>
                  <Switch
                    isChecked={product.productHighlight}
                    name="productHighlight"
                    onChange={(e) => changeHandler(e)}
                    flex="3"
                    style={{ marginTop: "12px" }}
                    id="email-alerts"
                  />
                </Flex>
                <Flex>
                  <Spacer />
                  <Button
                    variant="ghost"
                    justifyContent="flex-start"
                    backgroundColor={"#FF8084"}
                    color={"white"}
                    mt={4}
                    onClick={() => submitProduct()}>
                    Publish
                  </Button>
                </Flex>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Flex>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "16px",
                      color: "black",
                    }}>
                    Product Image Thumbnail
                  </p>
                  <Spacer />
                  {mainImage.img !== one && (
                    <IconButton
                      variant="outline"
                      aria-label="Call Sage"
                      fontSize="20px"
                      onClick={() => deleteMainImage("thumbnail")}
                      icon={<MdDelete />}
                    />
                  )}
                </Flex>
                <div className="add-product">
                  <Row className="file-upload-product row">
                    <Col className="col">
                      <div className="box-input-file-full">
                        <input
                          className="upload"
                          style={{
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                          }}
                          type="file"
                          id="main-image-input"
                          name="img"
                          accept="image/*"
                          onChange={(e) =>
                            handleMainImageChange(e, "thumbnail")
                          }
                        />
                        <img
                          src={mainImage.img}
                          alt=""
                          className="img-fluid image_zoom_1 blur-up lazyloaded"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Flex>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "16px",
                      color: "black",
                    }}>
                    Product First Image
                  </p>
                  <Spacer />
                  {firstImage.img !== one && (
                    <IconButton
                      variant="outline"
                      aria-label="Call Sage"
                      fontSize="20px"
                      onClick={() => deleteMainImage("firstImage")}
                      icon={<MdDelete />}
                    />
                  )}
                </Flex>
                <div className="add-product">
                  <Row className="file-upload-product row">
                    <Col className="col">
                      <div className="box-input-file-full">
                        <input
                          className="upload"
                          style={{
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                          }}
                          type="file"
                          id="first-image-input"
                          name="img"
                          accept="image/*"
                          onChange={(e) =>
                            handleMainImageChange(e, "firstImage")
                          }
                        />
                        <img
                          src={firstImage.img}
                          alt=""
                          className="img-fluid image_zoom_1 blur-up lazyloaded"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Flex>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "16px",
                      color: "black",
                    }}>
                    Product Gallery
                  </p>
                  <Spacer />
                  <IconButton
                    variant="outline"
                    aria-label="Call Sage"
                    fontSize="20px"
                    onClick={() => addGallery()}
                    icon={<MdAdd />}
                  />
                </Flex>
                <div className="add-product">
                  <Row className="file-upload-product row">
                    {galleryImage.map((res, i) => {
                      return (
                        <Col key={i} xl="3" className="col">
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                            className="box-input-file-full">
                            <IconButton
                              variant="outline"
                              aria-label="Call Sage"
                              onClick={() => deleteGallery(i)}
                              icon={<MdClose />}
                              style={{
                                position: "absolute",
                                right: "4px",
                                top: "4px",
                                borderRadius: "50%",
                                fontSize: "24px",
                                width: "24px",
                                height: "24px",
                                color: "red",
                                border: "none",
                                minWidth: "unset",
                              }}
                            />
                            <input
                              className="upload"
                              type="file"
                              id="img"
                              name="img"
                              accept="image/*"
                              style={{
                                cursor: "pointer",
                              }}
                              onChange={(e) => _handleImgChange(e, i)}
                            />
                            <img
                              alt=""
                              src={res.img}
                              style={{
                                width: "100%",
                              }}
                            />
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </CardBody>
            </Card>
            <SectionBanner
              bannerImage={bannerImage}
              setBannerImage={setBannerImage}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default navguard(AddNewProduct);
