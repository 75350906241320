import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { EditorState, convertToRaw, RichUtils } from "draft-js";

import styles_wysiwyg from "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState } from "react";
import stylesEditor from "../../products/styles/AddNewProduct.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  createFaqAction,
  getFaqsAction,
} from "../../../store/actions/faqsAction";
import { useDispatch } from "react-redux";

const AddNewFaqs = ({ isOpen, onClose, page }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [faqsForm, setFaqsForm] = useState({
    question: "",
    answer: "",
    isActive: true,
  });

  const _toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const config = {
    // image: {
    //   uploadCallback: _uploadImageCallBack,
    //   previewImage: true,
    //   alt: { present: true, mandatory: false },
    //   inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    // },
    list: {
      inDropdown: true,
      toggleBlockType: _toggleBlockType,
      unordered: { className: stylesEditor["unordered-list"] },
      ordered: { className: stylesEditor["ordered-list"] },
    },
    textAlign: { inDropdown: true },
    blockType: {
      className: "demo-option-custom-wide",
      dropdownClassName: "demo-dropdown-custom",
    },
  };

  const editorFaqChangeHandler = (editorState) => {
    setEditorState(editorState);
    let tempFaqs = { ...faqsForm };
    tempFaqs.body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setFaqsForm({ ...faqsForm, answer: tempFaqs.body });
  };

  const onHandleCreateFaq = async () => {
    toast({
      title: "Sucess",
      description: `Berhasil menambah FAQ`,
      status: "success",
      position: "top",
      duration: 1000,
      isClosable: true,
    });
    await createFaqAction(dispatch, faqsForm);
    await getFaqsAction(dispatch, `page=${page + 1}`);

    setFaqsForm({
      question: "",
      answer: "",
      isActive: true,
    });
    setEditorState(() => EditorState.createEmpty());
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New FAQ</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={2}>
            <Text>Question</Text>
            <Textarea
              value={faqsForm.question}
              onChange={(e) =>
                setFaqsForm({ ...faqsForm, question: e.target.value })
              }
            />
          </Box>
          <Box>
            <Text>Answer</Text>
            <Editor
              editorState={editorState}
              onEditorStateChange={editorFaqChangeHandler}
              editorStyle={{ padding: "5px" }}
              toolbarClassName={styles_wysiwyg["toolbarClassName"]}
              wrapperClassName={styles_wysiwyg["wrapperClassName"]}
              editorClassName={styles_wysiwyg["editorClassName"]}
              toolbar={config}
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="teal" mr={3} onClick={onHandleCreateFaq}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddNewFaqs;
