import { Flex, Text } from "@chakra-ui/react";
import styles from "./styles.module.css";

const IconStar = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.289 7.64918C18.2366 7.4949 18.1402 7.35934 18.0117 7.25923C17.8831 7.15912 17.7281 7.09884 17.5657 7.08584L12.8149 6.70834L10.759 2.15751C10.6936 2.01094 10.5871 1.88645 10.4524 1.79906C10.3177 1.71167 10.1607 1.66513 10.0001 1.66504C9.8396 1.66495 9.68247 1.71132 9.54771 1.79856C9.41296 1.8858 9.30633 2.01018 9.24069 2.15668L7.18486 6.70834L2.43403 7.08584C2.27441 7.09849 2.12181 7.15685 1.99449 7.25395C1.86717 7.35105 1.77052 7.48278 1.7161 7.63337C1.66169 7.78396 1.65181 7.94704 1.68765 8.1031C1.72349 8.25916 1.80353 8.40158 1.91819 8.51334L5.42903 11.9358L4.18736 17.3125C4.14966 17.4753 4.16174 17.6456 4.22204 17.8014C4.28234 17.9572 4.38808 18.0913 4.52553 18.1862C4.66298 18.2812 4.82579 18.3326 4.99285 18.3339C5.1599 18.3352 5.32348 18.2862 5.46236 18.1933L9.99986 15.1683L14.5374 18.1933C14.6793 18.2876 14.8467 18.3361 15.0171 18.3324C15.1874 18.3286 15.3525 18.2728 15.4902 18.1724C15.6279 18.0721 15.7315 17.9319 15.7872 17.7709C15.8428 17.6099 15.8478 17.4356 15.8015 17.2717L14.2774 11.9383L18.0574 8.53668C18.3049 8.31334 18.3957 7.96501 18.289 7.64918Z"
      fill="#FFBB00"
    />
  </svg>
);

const FilterByRating = ({ filterByRating, onChangeRating }) => (
  <Flex className={styles["filter-rating__container"]} align="center">
    <Text className={styles["rating-text"]}>Penilaian</Text>
    {[5, 4, 3, 2, 1].map((item) => (
      <Flex
        key={item}
        className={`${styles["filter-rating__item"]} ${
          filterByRating.includes(item) && styles["filter-rating__active"]
        }`}
        onClick={() => onChangeRating(item)}
      >
        <IconStar />
        <Text className={styles["filter-rating__item-text"]}>{item}</Text>
      </Flex>
    ))}
  </Flex>
);

export default FilterByRating;
