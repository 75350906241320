import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Textarea,
  Text,
  useToast,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { provinceAction } from "../../store/actions/provinceAction";
import { cityAction } from "../../store/actions/cityAction";
import { warehouseDetailAction } from "../../store/actions/warehouseAction";
import { useParams, useHistory } from "react-router-dom";
import Zone from "./components/zone";

import Axios from "../../services/axios";

import "./styles/create.scss";
import navguard from "../auth/navguard";
import { districtAction } from "../../store/actions/districtAction";
import { urbanAction } from "../../store/actions/urbanAction";
import { Autocomplete } from "@material-ui/lab";
import { ChevronDownIcon } from "@chakra-ui/icons";

const EditWareHouse = () => {
  const { id } = useParams();
  const history = useHistory();
  const toast = useToast();
  const provinces = useSelector((state) => state.provinces.provinces);
  const cities = useSelector((state) => state.cities.cities);
  const districts = useSelector((state) => state.districts.districts);
  const urbans = useSelector((state) => state.urbans.urbans);
  const warehouse = useSelector((state) => state.warehouse.warehouse);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    province: "",
    city: "",
    district: "",
    urban: "",
    contactPerson: "",
    zone: [],
  });

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(warehouseDetailAction(id));
    }
  }, [id]);

  useEffect(() => {
    if (warehouse.id) {
      const { addressDetail, warehouseName, zone, isActive, location, phone } =
        warehouse;
      setFormData({
        warehouseName: warehouseName,
        province: location.province,
        city: location.city,
        district: location.district,
        urban: location.urban,
        addressId: location.addressId,
        addressDetail: addressDetail,
        phone: phone,
        zone,
        isActive,
      });
    }
  }, [warehouse]);

  useEffect(() => {
    dispatch(provinceAction());
  }, []);

  useEffect(() => {
    if (formData.province) {
      dispatch(cityAction(formData.province));
    }
  }, [formData.province, dispatch]);

  useEffect(() => {
    if (formData.province && formData.city) {
      dispatch(districtAction(formData.province, formData.city));
    }
  }, [formData.province, formData.city, dispatch]);

  useEffect(() => {
    if (formData.province && formData.city && formData.district) {
      dispatch(
        urbanAction(formData.province, formData.city, formData.district)
      );
    }
  }, [formData.province, formData.city, formData.district, dispatch]);

  const changeLocationHandler = (value, type) => {
    const temp = { ...formData };
    if (type === "province") {
      temp.city = "";
      temp.district = "";
      temp.urban = "";
      temp[type] = value;
    } else if (type === "city") {
      temp.district = "";
      temp.urban = "";
      temp[type] = value;
    } else if (type === "district") {
      temp.urban = "";
      temp[type] = value;
    } else if (type === "urban") {
      temp[type] = value.urban;
      temp.zipCode = value.postalCode;
      temp.addressId = value.id;
    }
    setFormData(temp);
  };

  const changeFieldInput = ({ event, type }) => {
    const temp = { ...formData };
    if (type === "zone") {
      temp[type] = event;
    } else if (type === "status") {
      temp.isActive = !temp.isActive;
    }
    setFormData(temp);
  };

  const submitHandler = async () => {
    let isErrorField = false;
    setIsError(false);

    Object.keys(formData).forEach((key) => {
      if (formData[key] === "") {
        setIsError(true);
        isErrorField = true;
      }
    });

    if (isErrorField === false) {
      const { warehouseName, addressId, addressDetail, phone, isActive } =
        formData;

      const data = {
        warehouseName,
        addressId,
        addressDetail,
        phone,
        isActive,
      };

      try {
        await Axios({
          url: `/warehouse/${id}`,
          method: "PATCH",
          data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });

        toast({
          title: "Sukses.",
          description: "DC berhasil disubmit.",
          status: "success",
          position: "top",
          duration: 2000,
          isClosable: true,
        });

        history.push("/warehouse/list-warehouse");
      } catch (error) {
        console.error(error);
        toast({
          title: "Error.",
          description: "DC gagal disubmit, mohon coba lagi.",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <div className="create-warehouse__container">
      <div className="create-warehouse__header">
        <h2 className="title">Stock Management</h2>
      </div>
      <div className="create-warehouse__body">
        <h2 className="title">Edit Warehouse</h2>
        <div className="create-warehouse__body-form">
          <div className="field-form">
            <h3 className="label-form">Warehouse Name</h3>
            <Input
              className="input-form field-form"
              value={formData.warehouseName}
              onChange={(event) =>
                changeFieldInput({ event, type: "warehouseName" })
              }
            />
            {isError && formData.warehouseName === "" && (
              <Text color="red">Warehouse Name is required!</Text>
            )}
          </div>

          <div className="field-form">
            <h3 className="label-form">Provinsi</h3>
            <Autocomplete
              disablePortal
              name="province"
              options={provinces}
              value={formData?.province}
              getOptionLabel={(option) => option}
              onChange={(_, value) => changeLocationHandler(value, "province")}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <InputGroup>
                    <Input
                      id="add-address__receiver-province"
                      placeholder="Pilih Provinsi"
                      {...params.inputProps}
                    />
                    <InputRightElement>
                      <ChevronDownIcon />
                    </InputRightElement>
                  </InputGroup>
                </div>
              )}
            />
            {isError && formData.province === "" && (
              <Text color="red">Provinsi is required!</Text>
            )}
          </div>

          <h3 className="label-form ">Kabupaten / Kota</h3>
          <Autocomplete
            disablePortal
            name="province"
            options={cities}
            value={formData?.city}
            getOptionLabel={(option) => option}
            onChange={(_, value) => changeLocationHandler(value, "city")}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <InputGroup>
                  <Input
                    id="add-address__receiver-province"
                    placeholder="Pilih Kota"
                    {...params.inputProps}
                  />
                  <InputRightElement>
                    <ChevronDownIcon />
                  </InputRightElement>
                </InputGroup>
              </div>
            )}
          />
          {isError && formData.city === "" && (
            <Text color="red">Kabupaten / Kota is required!</Text>
          )}

          <h3 className="label-form">Kelurahan</h3>
          <Autocomplete
            disablePortal
            name="province"
            options={districts}
            value={formData?.district}
            getOptionLabel={(option) => option}
            onChange={(_, value) => changeLocationHandler(value, "district")}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <InputGroup>
                  <Input
                    id="add-address__receiver-province"
                    placeholder="Pilih Kelurahan"
                    {...params.inputProps}
                  />
                  <InputRightElement>
                    <ChevronDownIcon />
                  </InputRightElement>
                </InputGroup>
              </div>
            )}
          />
          {isError && formData.district === "" && (
            <Text color="red">Kelurahan is required!</Text>
          )}
          <h3 className="label-form">Kecamatan</h3>
          <Autocomplete
            disablePortal
            name="province"
            options={urbans}
            value={{ urban: formData?.urban }}
            getOptionLabel={(option) => option.urban}
            onChange={(_, value) => changeLocationHandler(value, "urban")}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <InputGroup>
                  <Input
                    id="add-address__receiver-province"
                    placeholder="Pilih Kelurahan"
                    {...params.inputProps}
                  />
                  <InputRightElement>
                    <ChevronDownIcon />
                  </InputRightElement>
                </InputGroup>
              </div>
            )}
          />
          {isError && formData.urban === "" && (
            <Text color="red">Kecamatan is required!</Text>
          )}
          <h3 className="label-form">Address</h3>
          <Textarea
            className="text-area"
            onChange={(event) =>
              changeFieldInput({ event, type: "addressDetail" })
            }
            value={formData.addressDetail}
          />
          {isError && formData.addressDetail === "" && (
            <Text color="red">Address is required!</Text>
          )}
          <h3 className="label-form">Contact Person</h3>
          <Input
            className="input-form field-form"
            onChange={(event) => changeFieldInput({ event, type: "phone" })}
            value={formData.phone}
          />
          {isError && formData.phone === "" && (
            <Text color="red">Contact Person is required!</Text>
          )}
          <h3 className="label-form">Warehouse Status</h3>
          <Select
            className="input-form field-form"
            onChange={(event) => changeFieldInput({ event, type: "status" })}
            value={formData.isActive}
          >
            <option value="" disabled></option>
            <option value={true}>active</option>
            <option value={false}>non-active</option>
          </Select>
          {isError && formData.isActive === "" && (
            <Text color="red">Status is required!</Text>
          )}

          {/* <Zone
            provinces={provinces}
            formData={formData}
            changeFieldInput={changeFieldInput}
            isError={isError}
            isEdit
          /> */}
          <Button className="button-form" onClick={submitHandler}>
            Update Warehouse
          </Button>
        </div>
      </div>
    </div>
  );
};

export default navguard(EditWareHouse);
