import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  IconButton,
  Input,
  Select,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import FilterByDate from "./components/FilterByDate";
import RedemptionTab from "./components/RedemptionTab";
import "./styles/ListRedemption.css";
import dateFormat from "dateformat";
import { EditIcon } from "@chakra-ui/icons";
import queryString from "query-string";
import RedemptionDetailModal from "./components/RedemptionDetailModal";
import Axios from "../../services/axios";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../order/components/pagination";
import { isEmpty, intersection, find } from "lodash";
import { patchLoyaltyRedemption } from "../../store/actions/loyaltyAction";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";

function ListRedemption(props) {
  const [tabActive, setTabActive] = useState("submitted");
  const { search, pathname } = useLocation();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const formatter = new Intl.NumberFormat("id-ID");
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);
  const currentQuery = queryString.parse(search);
  const toast = useToast();
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [sort, setSort] = useState("");
  const [rewardFilter, setRewardFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedBulk, setSelectedBulk] = useState("");
  const [rewardOption, setRewardOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pointType, setPointType] = useState("");
  const dispatch = useDispatch();

  const history = useHistory();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (!currentQuery.status) {
        currentQuery.status = "submitted";
      }

      if (!currentQuery.sort) {
        currentQuery.sort = "createdAt,desc";
      }

      let queries = queryString.stringify({
        ...currentQuery,
        limit: 5,
        page: page + 1,
      });

      const { data } = await Axios({
        url: `/loyalty-redemption/list?${queries}`,
        method: "GET",
      });
      setData(data.data.loyaltyRedemptions);
      setTotalPage(data.data.totalPage);
    } catch (err) {
      console.log(err.response);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOptionReward = async () => {
    try {
      const { data } = await Axios({
        url: `/loyalty-reward/list`,
        method: "GET",
      });

      const rewards = data.data.loyaltyRewards.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.id,
        };
      });
      setRewardOption(rewards);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchOptionReward();
  }, []);

  useEffect(() => {
    if (page !== 0) setPage(0);
    else fetchData();
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [page]);

  const handleStatusBadge = (status) => {
    let objStatus = {
      text: "",
      background: "",
      color: "",
    };

    if (status === "submitted") {
      objStatus.text = "Menunggu konfirmasi";
      objStatus.background = "#E0E0E0";
      objStatus.color = "#1A1A1A";
    } else if (status === "approved") {
      objStatus.text = "Sedang diproses";
      objStatus.background = "#CCE2FF";
      objStatus.color = "#004199";
    } else if (status === "cancelled") {
      objStatus.text = "Gagal";
      objStatus.background = "#FFCCCC";
      objStatus.color = "#CC0019";
    } else {
      objStatus.text = "Selesai";
      objStatus.background = "#D9F2E3";
      objStatus.color = "#015352";
    }

    return objStatus;
  };
  const columns = [
    {
      dataField: "follow",
      formatter: (cell, row) => <Checkbox value={row.id} />,
      headerFormatter: () => (
        <Checkbox isChecked={isCheckAll} onChange={handleCheckAll} />
      ),
    },
    {
      dataFiled: "createdAt",
      text: "Submission Date",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{dateFormat(cell, "mm/dd/yyyy")}</p>
      ),
    },
    {
      dataField: "loyaltyRewardId",
      text: "Reseller Id",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{row.user.referralCode}</p>
      ),
    },
    {
      dataField: "user",
      text: "Name",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{row.user.fullName}</p>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "phone",
      text: "Phone",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "id",
      text: "Reward Name",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{row.loyaltyReward?.name}</p>
      ),
    },
    {
      dataField: "",
      text: "Point Redeemed",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{row.loyaltyReward?.point * row.qty}</p>
      ),
    },
    {
      dataField: "destinationAddress",
      text: "Address",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "status",
      text: "Status",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => {
        return <Text color={handleStatusBadge(cell).color}>{cell}</Text>;
      },
    },
    {
      dataField: "action",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => {
        return (
          <IconButton
            variant="ghost"
            colorScheme="#ff8084"
            icon={
              <EditIcon width="16px" onClick={() => handleOpenModal(row)} />
            }
            justifyItems="center"
          />
        );
      },
    },
  ];

  const handleOpenModal = (data) => {
    setIsOpenDetail(true);
    setDataDetail(data);
  };

  const handleCloseModal = () => {
    setDataDetail({});
    setIsOpenDetail(false);
  };

  const handleChangePage = (value) => {
    setPage(value.selected);
  };

  const handleFilter = ({ value, type }) => {
    let queries;

    if (type === "sort") {
      setSort(value);

      queries = queryString.stringify(
        {
          ...currentQuery,
          sort: value,
        },
        {
          skipEmptyString: true,
        }
      );
    } else if (type === "rewardName") {
      setRewardFilter(value);
      if (!isEmpty(value)) {
        queries = queryString.stringify({
          ...currentQuery,
          loyaltyRewardName: value.name,
        });
      } else {
        queries = queryString.stringify(
          {
            ...currentQuery,
            loyaltyRewardName: null,
          },
          {
            skipNull: true,
          }
        );
      }
    } else {
      setPointType(value);
      if (!isEmpty(value)) {
        queries = queryString.stringify({
          ...currentQuery,
          pointType: value.value,
        });
      } else {
        queries = queryString.stringify(
          {
            ...currentQuery,
            pointType: null,
          },
          {
            skipNull: true,
          }
        );
      }
    }
    history.push(`${pathname}?${queries}`);
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const currentQuery = queryString.parse(search);
      const queries = queryString.stringify(
        {
          ...currentQuery,
          q: searchQuery,
        },
        { skipEmptyString: true }
      );

      history.push(`${pathname}?${queries}`);
    }
  };

  const handleCheck = (value) => {
    let tempSelect = { ...selectedRow };
    let tempSelectPage = tempSelect[page] || [];

    if (tempSelectPage.length < value.length) {
      tempSelectPage.push(value[value.length - 1]);
    } else {
      tempSelectPage = intersection([...tempSelectPage], [...value]);
    }

    tempSelect[page] = tempSelectPage;
    setSelectedRow(tempSelect);
    setIsCheckAll(tempSelectPage.length === data.length);
  };

  const handleCheckAll = (e) => {
    let tempSelect = { ...selectedRow };
    if (e.target.checked) {
      let tempSelectPage = data.map((item) => item.id);
      tempSelect[page] = tempSelectPage;
      setSelectedRow(tempSelect);
    } else {
      tempSelect[page] = [];
      setSelectedRow(tempSelect);
    }

    setIsCheckAll(e.target.checked);
  };

  const objToArrSelectedRow = () => {
    let arrResult = [];
    for (let key in selectedRow) {
      arrResult = [...arrResult, ...selectedRow[page]];
    }
    return arrResult;
  };

  const bulkActionHandler = async () => {
    const selectedArr = objToArrSelectedRow();
    const queries = queryString.stringify({
      ...currentQuery,
      status: selectedBulk === "cancelled" ? "submitted" : selectedBulk,
    });
    if (selectedArr.length < 1) {
      toast({
        title: "Warning.",
        description: "Mohon pilih data terlebih dahulu.",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    } else {
      for (let i = 0; i < selectedArr.length; i++) {
        await patchLoyaltyRedemption(dispatch, {
          type: "status",
          postData: selectedBulk,
          id: selectedArr[i],
        });
      }
      toast({
        title: "Sukses.",
        description: "Berhasil mengubah status.",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
      setSelectedRow({});
      setSelectedBulk("");
      history.push(`${pathname}?${queries}`);
    }
  };

  useEffect(() => {
    setIsCheckAll(
      data?.length > 0 && selectedRow[page]?.length === data?.length
    );
  }, [page, data]);

  useEffect(() => {
    setSelectedRow({});
    setSelectedBulk("");
  }, [tabActive]);

  useEffect(() => {
    if (currentQuery.sort) {
      setSort(currentQuery.sort);
    }

    if (currentQuery.q) {
      setSearchQuery(currentQuery.q);
    }
  }, [search]);

  useEffect(() => {
    if (!isEmpty(rewardOption) && currentQuery.reward) {
      setRewardFilter(find(rewardOption, { name: currentQuery.reward }));
    }
  }, [search, rewardOption]);
  return (
    <Box className="redemption-list__container">
      {isLoading && (
        <div
          style={{
            position: "fixed",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 12,
          }}
        >
          <div
            style={{
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0,0,0,0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner color="white" size="lg" />
          </div>
        </div>
      )}
      <Text className="redemption-list__title">Reward Redemption</Text>
      <Box className="redemption-list__content-container">
        <RedemptionTab tabActive={tabActive} setTabActive={setTabActive} />

        <Flex className="redemption-list__content-filter-wrapper">
          <FilterByDate url={pathname} type="redemption" />

          <Flex align="center" width="200px">
            <Text className="redemption-list__content-filter">Sort By</Text>
            <Select
              value={sort}
              onChange={(event) =>
                handleFilter({ value: event.target.value, type: "sort" })
              }
            >
              <option value={""}>Sort</option>
              <option value={"createdAt,asc"}>Terlama</option>
              <option value={"createdAt,desc"}>Terbaru</option>
            </Select>
          </Flex>
        </Flex>

        <Flex className="redemption-list__content-filter-wrapper">
          <Flex align="center" width="506px">
            <Text className="redemption-list__content-filter">Reward Name</Text>
            <ReactSelect
              className="basic-single"
              classNamePrefix="select"
              options={rewardOption}
              isClearable
              isSearchable
              onChange={(e) => handleFilter({ value: e, type: "rewardName" })}
              value={rewardFilter}
              styles={{ width: "100%" }}
            />
          </Flex>

          <Flex align="center" width="300px">
            <Select
              value={selectedBulk}
              onChange={(event) => setSelectedBulk(event.target.value)}
            >
              <option value="">Bulk Action</option>
              {tabActive === "submitted" && (
                <>
                  <option value={"approved"}>Approved Selected Request</option>
                  <option value={"cancelled"}>
                    Cancelled Selected Request
                  </option>
                </>
              )}

              {tabActive === "approved" && (
                <option value={"completed"}>Completed Selected Request</option>
              )}
            </Select>
            <Button
              colorScheme="teal"
              ml="16px"
              onClick={bulkActionHandler}
              disabled={!selectedBulk}
            >
              Apply
            </Button>
          </Flex>
        </Flex>

        <Flex
          align="center"
          width="506px"
          className="redemption-list__content-filter-wrapper"
        >
          <Text className="redemption-list__content-filter">Reward Type</Text>
          <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            options={[
              { label: "Point redemption", value: "redemption" },
              { label: "Benefit claim", value: "benefit claim" },
            ]}
            isClearable
            onChange={(e) => handleFilter({ value: e, type: "pointType" })}
            value={pointType}
            styles={{ width: "100%" }}
          />
        </Flex>

        <Input
          placeholder="Search here"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
          onKeyPress={(event) => handleKeyPressSearch(event)}
        />

        {!isEmpty(data) ? (
          <>
            {" "}
            <ToolkitProvider
              data={data}
              keyField="partner_name"
              columns={columns}
              search
            >
              {(props) => (
                <div className="py-4 table-responsive list-redemption__table-container">
                  <CheckboxGroup
                    colorScheme="green"
                    defaultValue={selectedRow[page] ? selectedRow[page] : []}
                    onChange={handleCheck}
                    value={selectedRow[page] ? selectedRow[page] : []}
                  >
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      trStyle={{ backgroundColor: "white" }}
                    />
                  </CheckboxGroup>
                </div>
              )}
            </ToolkitProvider>
            <Box mt="0.5em">
              <Pagination
                page={page}
                totalPage={totalPage}
                changePageHandler={handleChangePage}
              />
            </Box>
          </>
        ) : (
          <Text
            textAlign={"center"}
            fontSize="16px"
            color="#000000"
            margin={"1em 0"}
          >
            There is no data entry
          </Text>
        )}

        <Flex align="center">
          <Select
            value={selectedBulk}
            onChange={(event) => setSelectedBulk(event.target.value)}
            width="200px"
          >
            <option value="">Bulk Action</option>
            {tabActive === "submitted" && (
              <>
                <option value={"approved"}>Approved Selected Request</option>
                <option value={"cancelled"}>Cancelled Selected Request</option>
              </>
            )}

            {tabActive === "approved" && (
              <option value={"completed"}>Completed Selected Request</option>
            )}
          </Select>
          <Button
            colorScheme="teal"
            ml="16px"
            onClick={bulkActionHandler}
            disabled={!selectedBulk}
          >
            Apply
          </Button>
        </Flex>
      </Box>

      <RedemptionDetailModal
        isOpen={isOpenDetail}
        onClose={handleCloseModal}
        dataDetail={dataDetail}
        setDataDetail={setDataDetail}
        fetchData={fetchData}
        handleStatusBadge={handleStatusBadge}
      />
    </Box>
  );
}

export default ListRedemption;
