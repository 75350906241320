import { Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Axios from "../../../services/axios";
import "../style/DefaultResellerList.css";
import AddModal from "./AddModal";

function DefaultResellerList(props) {
  const [data, setData] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});

  const columns = [
    {
      name: "Reseller Level",
      selector: "resellerLevel",
      style: {
        textAlign: "left",
      },
      sortable: true,
    },
    {
      name: "Discount*",
      selector: "discountPercentage",
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
    {
      name: "",
      id: "view",
      accessor: (str) => "view",
      cell: (row, index) => (
        <Text onClick={() => handleEditData(row)} cursor={"pointer"}>
          Edit
        </Text>
      ),
      button: true,
    },
  ];

  const fetchData = async () => {
    try {
      const { data } = await Axios({
        url: "/reseller-discount/list?type=reseller level",
        method: "GET",
      });

      setData(data.data.resellerDiscounts);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditData = (value) => {
    setIsOpenModal(true);
    setIsEdit(true);
    setEditData(value);
  };

  const handleCloseModal = () => {
    setIsEdit(false);
    setEditData({});
    setIsOpenModal(false);
  };
  return (
    <div className="default-table__container">
      <DataTable columns={columns} data={data} />
      <AddModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        isEdit={isEdit}
        data={editData}
        fetchData={fetchData}
      />
    </div>
  );
}

export default DefaultResellerList;
