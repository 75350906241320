import { Flex, IconButton, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { RepeatIcon } from "@chakra-ui/icons";

const FilterDate = () => {
  const { search } = useLocation();
  const [firstDate, setFirstDate] = useState(
    queryString.parse(search).filter_created_date_min || ""
  );
  const [lastDate, setLastDate] = useState(
    queryString.parse(search).filter_created_date_max || ""
  );

  const history = useHistory();

  const changeDateHandler = ({ event, type }) => {
    const date = `${event.getFullYear()}-${
      event.getMonth() + 1
    }-${event.getDate()}`;

    const today = `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}`;

    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify({
      ...currentQuery,
      filter_created_date_min:
        type === "min" ? date : firstDate === "" ? date : firstDate,
      filter_created_date_max:
        type === "max" ? date : lastDate === "" ? today : lastDate,
    });

    history.push(`/users/all-user?${queries}`);
  };

  const handleClearDate = () => {
    setFirstDate("");
    setLastDate("");

    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify(
      {
        ...currentQuery,
        filter_created_date_min: "",
        filter_created_date_max: "",
      },
      { skipEmptyString: true }
    );

    history.push(`/users/all-user?${queries}`);
  };

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (
      currentQuery.filter_created_date_min ||
      currentQuery.filter_created_date_max
    ) {
      setFirstDate(currentQuery.filter_created_date_min);
      setLastDate(currentQuery.filter_created_date_max);
    }
  }, [search]);

  return (
    <Flex>
      <DatePicker
        selected={firstDate ? new Date(firstDate) : firstDate}
        onChange={(event) => changeDateHandler({ event, type: "min" })}
        dateFormat="dd/MM/yyyy"
        maxDate={lastDate ? new Date(lastDate) : new Date()}
      />
      <Text color="black" ml={2} mr={2} mt={-1} fontSize={25}>
        -
      </Text>
      <DatePicker
        selected={lastDate ? new Date(lastDate) : lastDate}
        onChange={(event) => changeDateHandler({ event, type: "max" })}
        dateFormat="dd/MM/yyyy"
        minDate={new Date(firstDate)}
        maxDate={new Date()}
      />
      <IconButton
        icon={<RepeatIcon />}
        colorScheme="teal"
        variant="outline"
        onClick={handleClearDate}
        ml="8px"
      />
    </Flex>
  );
};

export default FilterDate;
