import React, { Fragment, useState, useEffect } from "react";

import "./styles/_homeBanner.scss";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Flex, Spacer, IconButton, useToast, Button } from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";

import one from "../../assets/images/pro3/1.jpg";
import Breadcrumb from "../common/breadcrumb";
import Loader from "../loader/Loader";
import DeleteImage from "./component/warning-delete";
import ResellerFullPreview from "./component/reseller-full-preview";
import Axios from "../../services/axios";
import navguard from "../auth/navguard";

const ResellerBanner = () => {
  const toast = useToast();
  const imageMaxSize = 2000000;
  const [isLoading, setIsLoading] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isImageDelete, setIsImageDelete] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const [banner, setBanner] = useState({
    title: null,
    preview: "Desktop",
  });

  const getBanner = async () => {
    try {
      let bannerData = await Axios({
        method: "GET",
        url: "/reseller-landing/banner/active",
      });
      if (bannerData.data) {
        let rawData = { ...bannerData.data.data.banner };
        setBanner({
          ...rawData,
          prevImagePath: rawData.imagePath,
          prevImageMobilePath: rawData.imageMobilePath,
        });
      } else {
        setBanner({
          title: null,
          preview: "Desktop",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchBanner = async () => {
      await getBanner();
      setIsLoading(false);
    };

    setIsLoading(true);
    fetchBanner();
  }, []);

  const handleMainImageChange = (value, item, type) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (value.currentTarget.files.item(0).size > imageMaxSize) {
        toast({
          title: "Error.",
          description: "Mohon upload gambar berukuran kurang dari 2MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        let reader = new FileReader();
        const image = value.target.files[0];
        let tempBanner = { ...banner };

        if (type === "Desktop") {
          tempBanner.fileImageDesktop = image;
        } else {
          tempBanner.fileImageMobile = image;
        }

        reader.onload = () => {
          if (type === "Desktop") {
            tempBanner.imagePath = reader.result;
          } else {
            tempBanner.imageMobilePath = reader.result;
          }
          setBanner(tempBanner);
        };

        reader.readAsDataURL(image);
      }
    }
  };

  const openDeleteImage = (banner, type) => {
    banner.typeSelected = type;
    setSelectedBanner(banner);
    setIsImageDelete(true);
  };

  const closeDeleteImage = () => {
    setIsImageDelete(false);
    setSelectedBanner(null);
  };

  const deleteMainImage = () => {
    document.getElementById("main-image-input").value = null;
    let tempDataBanner = { ...banner };

    if (selectedBanner.typeSelected === "Desktop") {
      tempDataBanner.imagePath = one;
      tempDataBanner.fileImageDesktop = undefined;
    } else {
      tempDataBanner.imageMobilePath = one;
      tempDataBanner.fileImageMobile = undefined;
    }

    setBanner(tempDataBanner);
    closeDeleteImage();
  };

  const checkIsBannerEdited = (banner) => {
    return (
      banner.prevImagePath !== banner.imagePath ||
      banner.prevImageMobilePath !== banner.imageMobilePath
    );
  };

  const updateValidation = () => {
    let statusValidation = {
      status: "success",
      message: "Banner berhasil disubmit",
    };
    if (banner.imagePath === one) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon upload gambar desktop banner";
    } else if (banner.imageMobilePath === one) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon upload gambar mobile banner";
    }

    return statusValidation;
  };

  const submitChanges = async () => {
    setIsLoading(true);
    let responseValidation = updateValidation();
    if (responseValidation.status === "success") {
      if (checkIsBannerEdited(banner)) {
        try {
          let data = new FormData();
          if (banner.prevImagePath !== banner.imagePath) {
            data.append("image", banner.fileImageDesktop);
          }

          if (banner.prevImageMobilePath !== banner.imageMobilePath) {
            data.append("imageMobile", banner.fileImageMobile);
          }
          data.append("title", "");
          data.append("link", "");

          let config = {
            method: banner.id ? 'PATCH' : 'POST',
            url: `/reseller-landing/banner${banner.id ? `/${banner.id}` : `/`}`,
            data: data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };

          const { data: responseData } = await Axios(config);

          if (!banner.id) {
            await Axios({
              method: 'PATCH',
              url: `/reseller-landing/banner/${responseData.data.banner.id}/setActive`,
              data: {
                isActive: true
              },
              headers: {
                Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              },
            })
          }

          await getBanner();

          toast({
            title: "Success.",
            description: "Banner berhasil diupdate",
            status: "success",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          setIsLoading(false);
        } catch (error) {
          toast({
            title: "Error.",
            description: "Terjadi kesalahan, mohon coba beberapa saat lagi",
            status: "error",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          setIsLoading(false);
        }
      }
    } else {
      toast({
        title: "Error.",
        description: responseValidation.message,
        status: responseValidation.status,
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const previewOpen = () => {
    setIsPreview(true);
  };

  const previewClose = () => {
    setIsPreview(false);
  };

  useEffect(() => {
    const checkIsEdited = () => {
      setIsEdited(checkIsBannerEdited(banner));
    };

    checkIsEdited();
  }, [banner]);

  return (
    <Fragment>
      {isLoading && <Loader />}
      <Breadcrumb title="Reseller Banner" parent="Content" />
      <ResellerFullPreview
        previewClose={previewClose}
        isPreview={isPreview}
        banner={banner}
      />
      <DeleteImage
        deleteMainImage={deleteMainImage}
        closeDeleteImage={closeDeleteImage}
        isImageDelete={isImageDelete}
        selectedImage={
          selectedBanner?.typeSelected === "Desktop"
            ? selectedBanner.imagePath
            : selectedBanner?.imageMobilePath
        }
        aspectRatio={
          selectedBanner?.typeSelected === "Desktop" ? "3 / 2" : "1 / 1"
        }
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col xl={12}>
                    <Flex>
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Reseller Page Banner
                      </p>
                      <Spacer />
                      <Button
                        mr={4}
                        variant="ghost"
                        justifyContent="flex-start"
                        border={"2px solid #FF8084"}
                        color={"#FF8084"}
                        onClick={() => previewOpen()}
                      >
                        Preview
                      </Button>
                      <Button
                        variant="ghost"
                        justifyContent="flex-start"
                        backgroundColor={"#FF8084"}
                        color={"white"}
                        isDisabled={!isEdited}
                        onClick={() => submitChanges()}
                      >
                        Simpan
                      </Button>
                    </Flex>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col sm="8" md="8" lg="8">
                    <Flex>
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          marginBottom: "16px",
                          color: "black",
                        }}
                      >
                        Desktop Preview
                      </p>
                      <Spacer />
                      {banner.imagePath !== one && (
                        <IconButton
                          mr={4}
                          variant="outline"
                          aria-label="Call Sage"
                          fontSize="20px"
                          onClick={() => openDeleteImage(banner, "Desktop")}
                          icon={<MdDelete />}
                        />
                      )}
                    </Flex>
                    <Row>
                      <Col xl={12}>
                        <div className="add-product">
                          <Row className="file-upload-product row">
                            <Col className="col">
                              <div className="box-input-file-full">
                                <input
                                  className="upload"
                                  style={{
                                    width: "100%",
                                    aspectRatio: `3 / 2`,
                                    objectFit: "cover",
                                    cursor: "pointer",
                                  }}
                                  type="file"
                                  id="main-image-input"
                                  name="img"
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleMainImageChange(e, banner, "Desktop")
                                  }
                                />
                                <img
                                  src={banner.imagePath}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    aspectRatio: `3 / 2`,
                                    objectFit: "cover",
                                  }}
                                  className="img-fluid image_zoom_1 blur-up lazyloaded"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="4" md="4" lg="4">
                    <Flex>
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          marginBottom: "16px",
                          color: "black",
                        }}
                      >
                        Mobile Preview
                      </p>
                      <Spacer />
                      {banner.imageMobilePath !== one && (
                        <IconButton
                          mr={4}
                          variant="outline"
                          aria-label="Call Sage"
                          fontSize="20px"
                          onClick={() => openDeleteImage(banner, "Mobile")}
                          icon={<MdDelete />}
                        />
                      )}
                    </Flex>
                    <Row>
                      <Col xl={12}>
                        <div className="add-product">
                          <Row className="file-upload-product row">
                            <Col className="col">
                              <div className="box-input-file-full">
                                <input
                                  className="upload"
                                  style={{
                                    width: "100%",
                                    aspectRatio: `1 / 1`,
                                    objectFit: "cover",
                                    cursor: "pointer",
                                  }}
                                  type="file"
                                  id="main-image-input"
                                  name="img"
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleMainImageChange(e, banner, "Mobile")
                                  }
                                />
                                <img
                                  src={banner.imageMobilePath}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    aspectRatio: `1 / 1`,
                                    objectFit: "cover",
                                  }}
                                  className="img-fluid image_zoom_1 blur-up lazyloaded"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default navguard(ResellerBanner);
