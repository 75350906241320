import React, { useEffect, useState } from "react";
import { Flex, Box, Text, Spacer, Button } from "@chakra-ui/react";
import FilterDate from "./filterDate";
import "../styles/list-commission.css";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

function HeaderList({ isPayoutPage }) {
  const history = useHistory();
  const today = new Date();
  const { search } = useLocation();
  const { totalCommission } = useSelector((state) => state.commission);
  const formatter = new Intl.NumberFormat("id-ID");
  const [isRunningMonth, setIsRunningMonth] = useState(true);
  const [totalResellerCommission, setTotalResellerCommission] = useState(0);
  const { commissionManagement } = useSelector((state) => state.commission);

  const changePayout = () => {
    const currentQuery = queryString.parse(search);
    if (!currentQuery.month) {
      currentQuery.month = today.getMonth() + 1;
    }

    if (!currentQuery.year) {
      currentQuery.year = today.getFullYear();
    }
    history.push(
      `/commission/list-payout?month=${currentQuery.month}&year=${currentQuery.year}`
    );
  };

  useEffect(() => {
    if (!search) {
      setIsRunningMonth(true);
      return;
    }

    const searchQuery = queryString.parse(search);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;

    setIsRunningMonth(parseInt(searchQuery.month) === currentMonth);
  }, [search]);

  useEffect(() => {
    if (commissionManagement) {
      const temp = commissionManagement.reduce((prev, curr) => {
        return prev + curr.commission
      }, 0)
  
      setTotalResellerCommission(temp)
    }
  }, [commissionManagement])

  return (
    <Flex>
      <Box>
        <Text className="list-commission__title">
          Commission {isPayoutPage && " Payout"} Management
        </Text>
        <FilterDate isPayoutPage={isPayoutPage} />
      </Box>
      <Spacer />
      <Flex
        flexDir={"column"}
        justifyContent="center"
        align={"center"}
        className="list-commission__total-payout-container"
      >
        <Text className="list-commission__title-total-payout">
          {isPayoutPage ? 
            "Total Commission Payout" : "Total Reseller Commission"
          }
        </Text>
        <Text className="list-commission__subtitle-total-payout">
          Rp {isPayoutPage ? formatter.format(totalCommission || 0) : formatter.format(totalResellerCommission)}
        </Text>
        {!isPayoutPage && (
          <Button
            colorScheme="teal"
            size="md"
            ml="4px"
            onClick={changePayout}
            hidden={isRunningMonth}
          >
            Pay Commission
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

export default HeaderList;
