import {
  Box,
  Button,
  Flex,
  Input,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AddModal from "./AddModal";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import Axios from "../../../services/axios";
import DataTable from "react-data-table-component";
import { isEmpty } from "lodash";
import Pagination from "../../order/components/pagination";
import "../style/RoleBasedList.css";

function RoleBasedList(props) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [totalPage, setTotalPage] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const { search } = useLocation();
  const history = useHistory();
  const toast = useToast();

  const handleEditData = (value) => {
    setIsOpenModal(true);
    setIsEdit(true);
    setEditData(value);
  };

  const handleDeleteData = async (id) => {
    try {
      await Axios({
        url: `/reseller-discount/${id}`,
        method: "DELETE",
      });
      toast({
        title: "Success.",
        description: "Berhasil menghapus data",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true,
      });
      const response = await fetchData();

      if (page !== 0 && response.resellerDiscounts.length <= 0) {
        if (response.totalPage > 1) {
          setPage(page - 1);
        } else {
          setPage(0);
        }
      }
    } catch (err) {
      console.log(err);
      toast({
        title: "Warning.",
        description: "Mohon mencoba beberapa saat lagi",
        status: "warning",
        position: "top",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const columns = [
    {
      name: "Reseller Level",
      selector: "communityId",
      style: {
        textAlign: "left",
      },
      sortable: true,
      cell: (row, index) => <Text>{row.community.name}</Text>,
    },
    {
      name: "Discount*",
      selector: "discountPercentage",
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
    {
      name: "",
      id: "view",
      accessor: (str) => "view",
      cell: (row, index) => (
        <Flex>
          <Text onClick={() => handleEditData(row)} mr="1em" cursor="pointer">
            Edit
          </Text>
          <Box borderRight={"1px solid black"} />
          <Text
            onClick={() => handleDeleteData(row.id)}
            ml="1em"
            cursor="pointer"
          >
            Delete
          </Text>
        </Flex>
      ),
      button: true,
    },
  ];

  const handleCloseModal = () => {
    setIsEdit(false);
    setEditData({});
    setIsOpenModal(false);
  };

  const handleSearchData = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const currentQueries = queryString.parse(search);
      const queries = queryString.stringify(
        {
          ...currentQueries,
          q: searchQuery,
        },
        { skipEmptyString: true }
      );

      history.push(`/reseller-discount/list-discount?${queries}`);
    }
  };

  const fetchData = async () => {
    try {
      const currentQuery = queryString.parse(search);
      const queries = queryString.stringify({
        ...currentQuery,
        limit: 5,
        page: page + 1,
        type: "role based",
      });
      const { data } = await Axios({
        url: `/reseller-discount/list?${queries}`,
        method: "GET",
      });

      setData(data.data.resellerDiscounts);
      setTotalPage(data.data.totalPage);

      return data.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (page !== 0) setPage(0);
    fetchData();
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [page]);

  const handlePage = (value) => {
    setPage(value.selected);
  };

  return (
    <>
      <Flex margin={"6px 0"}>
        <Input
          placeholder="Search reseller role here ..."
          width={"30%"}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
          onKeyPress={(event) => handleSearchData(event)}
        />
        <Spacer />
        <Button
          onClick={() => setIsOpenModal(true)}
          bg="#ff8084"
          color="#ffffff"
        >
          Create New Rule
        </Button>
      </Flex>

      <div className="role-based-table__container">
        <DataTable columns={columns} data={data} />
      </div>

      {!isEmpty(data) && (
        <Pagination
          page={page}
          totalPage={totalPage}
          changePageHandler={handlePage}
        />
      )}
      <AddModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        isEdit={isEdit}
        data={editData}
        fetchData={fetchData}
      />
    </>
  );
}

export default RoleBasedList;
