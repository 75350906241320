import React, { useState, useEffect } from "react";
import "../styles/stocklist.scss";
import {
  Input,
  Flex,
  Button,
  useToast,
  Box,
  Divider,
  Text,
  Spacer,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { isEmpty, uniqBy } from "lodash";

const StockVariantCard = ({
  product,
  id,
  variant,
  bulkUpdate,
  setBulkUpdate,
  index,
}) => {
  const toast = useToast();

  const [variantState, setVariantState] = useState({});
  const dispatch = useDispatch();

  const changeFieldInput = ({ type, event }) => {
    const objTemp = { ...variantState };
    const variantId = objTemp.productVariant.id;
    const tempBulkUpdate = [...bulkUpdate];
    objTemp[type] = event.target.value;
    tempBulkUpdate[index] = {
      ...objTemp,
      variantId,
      productId: product.productId,
    };
    setBulkUpdate(tempBulkUpdate);
    setVariantState(objTemp);
  };

  useEffect(() => {
    if (!isEmpty(variant.productVariant)) {
      setVariantState({ ...variant });
    }
  }, [variant]);

  return (
    <Flex justify="space-between" align="center">
      <div
        className="input-wrapper"
        style={{
          width: "20%",
          padding: "32px",
          borderRight: "1px solid #C4C4C4",
        }}>
        {variant.productVariant.name}
      </div>
      <Divider orientation="vertical" width="1px" />
      <div
        className="input-wrapper"
        style={{
          width: "10%",
          padding: "32px",
          borderRight: "1px solid #C4C4C4",
        }}>
        {variant.availableStock === 0
          ? "Out of Stock"
          : variant.availableStock < variant.minSafetyStock
          ? "Low Stock"
          : "Ready Stock"}
      </div>
      <div className="input-wrapper" style={{ width: "60%", padding: "16px" }}>
        <Row>
          <Col xl={6}>
            <Col xl={12} style={{ padding: "8px" }}>
              <Flex align="center">
                <Text>Available Stock</Text>
                <Spacer />
                <Input
                  value={variantState?.availableStock}
                  onChange={(event) =>
                    changeFieldInput({
                      event,
                      type: "availableStock",
                    })
                  }
                />
              </Flex>
            </Col>
            <Col xl={12} style={{ padding: "8px" }}>
              <Flex align="center">
                <Text>Min Safety Stock</Text>
                <Spacer />
                <Input
                  value={variantState?.minSafetyStock}
                  onChange={(event) =>
                    changeFieldInput({
                      event,
                      type: "minSafetyStock",
                    })
                  }
                />
              </Flex>
            </Col>
          </Col>
          <Col xl={6}>
            <Col xl={12} style={{ padding: "8px" }}>
              <Flex align="center">
                <Text>Reserved Stock</Text>
                <Spacer />
                <Input disabled value={variantState?.reservedStock} />
              </Flex>
            </Col>
            <Col xl={12} style={{ padding: "8px" }}>
              <Flex align="center">
                <Text>QC Stock</Text>
                <Spacer />
                <Input disabled value={variantState?.qcStock} />
              </Flex>
            </Col>
          </Col>
        </Row>
      </div>
    </Flex>
  );
};

export default StockVariantCard;
