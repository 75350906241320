import {
  SET_CREATE_USER,
  SET_PATCH_USER,
  SET_USER_COMMUNITY,
  SET_USER_DETAIL_DATA,
  SET_USER_LIST,
  SET_USER_ALL,
  SET_USER_STATS,
  SET_USER_BADGE_PROGRAM,
} from "../actionTypes";
import Axios from "../../services/axios";
import dateFormat from "dateformat";

export const getUsersAction = async (dispatch, queries, type) => {
  try {
    const { data } = await Axios({
      url: `/admin/user/list?${queries}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    dispatch({
      type: type === "all" ? SET_USER_ALL : SET_USER_LIST,
      payload: data.data.users,
    });

    return {
      totalPage: data.data.totalPage,
      totalData: data.data.totalData,
    };
  } catch (error) {
    console.error(error);
  }
};

export const getUserDetailAction = async (dispatch, id) => {
  try {
    const { data } = await Axios({
      url: `/admin/user/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    dispatch({
      type: SET_USER_DETAIL_DATA,
      payload: data.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const cleanUserDetailAction = (dispatch) => {
  try {
    dispatch({
      type: SET_USER_DETAIL_DATA,
      payload: {},
    });
  } catch (error) {
    console.error(error);
  }
};

export const getUserCommunity = async (dispatch) => {
  try {
    const { data } = await Axios({
      url: `/admin/community/list`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    dispatch({
      type: SET_USER_COMMUNITY,
      payload: data.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const postUserAction = async (dispatch, dataValues) => {
  Object.keys(dataValues).forEach((data) => {
    if (!dataValues[data]) {
      delete dataValues[data];
    }
  });
  try {
    const { data } = await Axios({
      url: `/admin/user/create`,
      method: "POST",
      data: dataValues,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    dispatch({
      type: SET_CREATE_USER,
      payload: data,
    });
    return true;
  } catch (error) {
    console.error(error);
    return { data: error.response.data, status: error.response.status };
  }
};

export const patchUserAction = async (dispatch, userId, dataValues) => {
  try {
    const emptyData = [null, ""];
    Object.keys(dataValues).forEach((data) => {
      if (
        data !== "resellerProgramName" &&
        data !== "resellerProgramEndDate" &&
        data !== "resellerProgramStartDate"
      ) {
        if (emptyData.includes(dataValues[data])) {
          delete dataValues[data];
        }
      }
    });

    const { data } = await Axios({
      url: `/admin/user/${userId}`,
      method: "PATCH",
      data: dataValues,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    dispatch({
      type: SET_PATCH_USER,
      payload: data,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const clearUserResponseState = async (dispatch) => {
  try {
    dispatch({
      type: SET_PATCH_USER,
      payload: {},
    });
  } catch (error) {
    console.error(error);
  }
};

export const fetchUserStats = async (dispatch) => {
  try {
    const { data } = await Axios({
      url: `/admin/user/status-recap`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    dispatch({
      type: SET_USER_STATS,
      payload: data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const fetchUserBadgeProgram = async (dispatch, type, isVerified) => {
  try {
    const today = dateFormat(new Date(), "yyyy-mm-dd");
    const { data } = await Axios({
      url: `/admin/reseller-program`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    let filteredData;
    let usedData = [...data];

    if (type === "userList") {
      filteredData = [...usedData.sort((a, b) => (a.name > b.name ? 1 : -1))];
    } else {
      usedData = usedData.filter(
        (item) =>
          item.name.toLowerCase().includes("influencer") ||
          item.isVerified === true ||
          item.isVerified === null
      );

      if (type === "user") {
        filteredData = [
          ...usedData.filter(
            (item) =>
              item.name.toLowerCase().includes("influencer") ||
              item.isVerified ||
              !item.isVerified ||
              item.isVerified === null
          ),
        ];
      } else {
        filteredData = [
          ...usedData.filter(
            (item) =>
              item.name.toLowerCase().includes("influencer") ||
              item.isVerified === true ||
              item.isVerified === null
          ),
        ];
        filteredData.push({ name: "Tanpa program" });
      }
    }

    dispatch({
      type: SET_USER_BADGE_PROGRAM,
      payload: filteredData,
    });
  } catch (error) {
    console.error(error);
  }
};
