import {
  Home,
  Box,
  Tag,
  Camera,
  Archive,
  ShoppingBag,
  TrendingUp,
  Gift,
  User,
  HelpCircle,
  Users,
  Flag,
  Percent,
} from "react-feather";

export const MENUITEMS = [
  {
    path: "/dashboard",
    title: "Home",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    title: "User",
    icon: User,
    type: "sub",
    active: false,
    children: [
      {
        path: "/users/all-user",
        title: "List User",
        type: "link",
      },
      {
        path: "/users/create-user",
        title: "Create User",
        type: "link",
      },
    ],
  },
  {
    title: "Content",
    icon: Camera,
    type: "sub",
    active: false,
    children: [
      { 
        path: "/content/home-banner",
        title: "Home Banner",
        type: "link"
      },
      {
        path: "/content/landing-banner",
        title: "Landing Page Content",
        type: "link",
      },
      {
        path: "/content/testimony",
        title: "Testimony",
        type: "link",
      },
    ],
  },
  {
    title: "Products",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      { 
        path: "/products/all-products",
        title: "All Product",
        type: "link"
      },
      { 
        path: "/products/add-product",
        title: "Add Product",
        type: "link"
      },
      { 
        path: "/products/brands",
        title: "Brand & Series",
        type: "link"
      },
      { 
        path: "/products/categories",
        title: "Categories",
        type: "link"
      },
      {
        path: "/products/package-recommendation",
        title: "Product Package",
        type: "link",
      },
      {
        path: "/products/tagging",
        title: "Product Tagging",
        type: "link",
      },
    ],
  },
  {
    title: "Stock Management",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        path: "/warehouse/create-warehouse",
        title: "Create Warehouse",
        type: "link",
      },
      {
        path: "/warehouse/list-warehouse",
        title: "List Warehouse",
        type: "link",
      },
    ],
  },
  {
    title: "Order",
    icon: ShoppingBag,
    type: "sub",
    active: false,
    children: [
      {
        path: "/order-list",
        title: "List Order",
        type: "link",
      },
    ],
  },
  {
    title: "Promo",
    icon: Tag,
    type: "sub",
    active: false,
    children: [
      { path: "/promo/list-promo", title: "List Promo", type: "link" },
      {
        path: "/promo/create-promo",
        title: "Create Promo",
        type: "link",
      },
    ],
  },
  {
    title: "Commission",
    icon: Gift,
    type: "sub",
    active: false,
    children: [
      {
        path: "/commission/list-commission",
        title: "List Commission",
        type: "link",
      },
    ],
  },
  {
    title: "Community",
    icon: Gift,
    type: "sub",
    active: false,
    children: [
      { path: "/community/list-community", title: "Community", type: "link" },
      {
        path: "/community/list-suggestion",
        title: "Community Suggestion",
        type: "link",
      },
    ],
  },
  {
    title: "Help Center",
    icon: HelpCircle,
    type: "sub",
    active: false,
    children: [
      { path: "/help-center/feedback", title: "Feedback", type: "link" },
      { path: "/help-center/article", title: "Article", type: "link" },
      { path: "/help-center/subtopic", title: "Subtopic", type: "link" },
      { path: "/help-center/faq", title: "FAQ", type: "link" },
    ],
  },

  {
    title: "Admin",
    icon: Users,
    type: "sub",
    active: false,
    children: [
      { path: "/admin/list-admin", title: "List Admin", type: "link" },
    ],
  },

  {
    title: "Reseller Discount",
    icon: Percent,
    type: "sub",
    active: false,
    children: [
      {
        path: "/reseller-discount/list-discount",
        title: "Reseller Discount",
        type: "link",
      },
    ],
  },

  {
    title: "Loyalty",
    icon: Gift,
    type: "sub",
    active: false,
    children: [
      { path: "/loyalty/list-points", title: "Loyalty Point", type: "link" },
      { path: "/loyalty/list-reward", title: "Loyalty Reward", type: "link" },
      {
        path: "/loyalty/list-redemption",
        title: "Reward Redemption",
        type: "link",
      },
    ],
  },
];
