const PassStrengthMeter = () => <></>;

export default PassStrengthMeter;

export const passStrengths = (password) => {
  const check1 = password.length >= 8;
  const check2 = new RegExp("^(?=.*[A-Z]).+$").test(password);
  const check3 = new RegExp("^(?=.*[-+_!@#$%^&*.,?]).+$").test(password);

  return [check1, check2, check3];
};
