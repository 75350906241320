import React, { useEffect, useRef, useState } from "react";
import "./style/subtopic.css";
import {
  Box,
  Button,
  Flex,
  Select,
  Spacer,
  Text,
  useDisclosure,
  Divider,
  Center,
  useToast,
} from "@chakra-ui/react";
import AddSubtopic from "./components/addSubtopic";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../order/components/pagination";
import queryString from "query-string";
import Axios from "../../services/axios";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import navguard from "../auth/navguard";
import AlertModal from "../alertModal";

function Subtopic(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);
  const { search } = useLocation();
  const [dataTable, setDataTable] = useState([]);
  const [filterTopic, setFilterTopic] = useState("");
  const [inputSubtopic, setInputSubtopic] = useState({
    topic: "",
    subtopic: "",
  });
  const [submitType, setSubmitType] = useState();
  const [idSubtopic, setIdSubtopic] = useState("");
  const history = useHistory();
  const toast = useToast();
  const cancelRef = useRef();
  const [id, setId] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [totalArticle, setTotalArticle] = useState(0);

  const onCloseModal = () => setIsOpenModal(false);

  const openAlertModal = (id, totalArticle) => {
    setId(id);
    setTotalArticle(totalArticle);
    setIsOpenModal(true);
  };

  const columns = [
    {
      dataField: "topic",
      text: "Topic",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "subtopic",
      text: "Subtopic",
      style: { textAlign: "center" },
      sort: true,
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "totalArticle",
      text: "Number of Articles",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      sort: true,
    },
    {
      dataField: "id",
      style: { textAlign: "end" },
      formatter: (cell, row) => {
        return (
          <Flex justifyContent={"center"} width="100%">
            <Text
              cursor={"pointer"}
              onClick={() =>
                handleAddSubtopicModal({ type: "edit", data: row })
              }
            >
              Edit
            </Text>
            <Center height="20px" width="29px">
              <Divider
                orientation="vertical"
                color={"#C4C4C4"}
                opacity={"1"}
                borderLeftWidth={"1.5px"}
              />
            </Center>
            <Text
              cursor={"pointer"}
              onClick={() => openAlertModal(cell, row.totalArticle)}
            >
              Delete
            </Text>
          </Flex>
        );
      },
    },
  ];

  const handlePage = (value) => {
    setPage(value.selected);
  };

  const fetchData = async () => {
    try {
      const currentQuery = queryString.parse(search);
      const queries = queryString.stringify({
        ...currentQuery,
        limit: 5,
        page: page + 1,
      });

      const { data } = await Axios({
        url: `/admin/subtopic/list?${queries}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      setTotalPage(data.data.totalPage);
      setDataTable(data.data.subtopics);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [search, page]);

  const handleDeleteSubtopic = async () => {
    if (totalArticle === "0") {
      try {
        await Axios({
          url: `/admin/subtopic/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });
        toast({
          title: "Success",
          description: "Berhasil menghapus Subtopic",
          status: "success",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
        fetchData();
        setIsOpenModal(false);
      } catch (err) {
        console.log(err);
        toast({
          title: "Warning",
          description: "Tidak berhasil menghapus Subtopic",
          status: "warning",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Warning",
        description: "Subtopic ini memiliki Article, tidak dapat di hapus!",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  const handleFilterTopic = (event) => {
    setPage(0);
    setFilterTopic(event.target.value);

    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify(
      {
        ...currentQuery,
        filter_topic: event.target.value,
      },
      { skipEmptyString: true }
    );

    history.push(`/help-center/subtopic?${queries}`);
  };

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (currentQuery.filter_topic) {
      setFilterTopic(currentQuery.filter_topic);
    }
  }, [search]);

  const handleAddSubtopicModal = ({ type, data }) => {
    setSubmitType(type);
    onOpen();
    let tempInput = { ...inputSubtopic };
    if (type === "edit") {
      Object.keys(tempInput).forEach((key) => {
        tempInput[key] = data[key];
      });
      setIdSubtopic(data.id);
    } else {
      Object.keys(tempInput).forEach((key) => {
        tempInput[key] = "";
      });
      setIdSubtopic("");
    }
    setInputSubtopic(tempInput);
  };
  return (
    <Box className="help-center__subtopic-container">
      <Flex>
        <Text className="help-center__subtopic-title">
          Help Center Subtopic
        </Text>
        <Spacer />
        <Button
          className="help-center__subtopic-button"
          onClick={() => handleAddSubtopicModal({ type: "add" })}
        >
          Add New Subtopic
        </Button>
      </Flex>

      <Box className="help-center__subtopic-table-container">
        <Flex alignItems={"center"}>
          <Text textAlign={"center"} fontSize={"16px"} marginRight={"1em"}>
            Choose Topic :
          </Text>
          <Select
            width={"200px"}
            value={filterTopic}
            onChange={(event) => handleFilterTopic(event)}
          >
            <option value="">All</option>
            <option value="pengenalan">Pengenalan</option>
            <option value="transaksi">Transaksi</option>
            <option value="akun saya">Akun Saya</option>
            <option value="promo">Keuntungan & Promo</option>
            <option value="kebijakan">Akun & Website</option>
          </Select>
        </Flex>

        {!isEmpty(dataTable) && (
          <ToolkitProvider
            data={dataTable}
            columns={columns}
            keyField="subtopic"
          >
            {(props) => (
              <div className="py-4">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                  trStyle={{ backgroundColor: "white" }}
                />
              </div>
            )}
          </ToolkitProvider>
        )}

        {!isEmpty(dataTable) && (
          <Box mt={"1em"}>
            <Pagination
              page={page}
              totalPage={totalPage}
              changePageHandler={handlePage}
            />
          </Box>
        )}
      </Box>
      <AddSubtopic
        isOpen={isOpen}
        onClose={onClose}
        type={submitType}
        inputSubtopic={inputSubtopic}
        setInputSubtopic={setInputSubtopic}
        fetchData={fetchData}
        idSubtopic={idSubtopic}
      />
      <AlertModal
        isOpen={isOpenModal}
        cancelRef={cancelRef}
        onClose={onCloseModal}
        title={"Delete Subtopic"}
        subtitle={"Are you sure? You can't undo this action afterwards."}
        alertHandler={() => handleDeleteSubtopic()}
      />
    </Box>
  );
}

export default navguard(Subtopic);
