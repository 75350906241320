import React, { useEffect, useState } from "react";
import {
  CardHeader,
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import Breadcrumb from "../../common/breadcrumb";
import dateFormat from "dateformat";
import "./promo-usage.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import BootstrapTable from "react-bootstrap-table-next";
import navguard from "../../auth/navguard";
import { useParams } from "react-router-dom";
import Axios from "../../../services/axios";
import { useDispatch, useSelector } from "react-redux";
import { setSinglePromo } from "../../../store/actions/promoAction";
import Pagination from "../../order/components/pagination";
import { isEmpty } from "lodash";
import { Text } from "@chakra-ui/react";

const PromoUsage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [dataLog, setDataLog] = useState([]);
  const [dataDetail, setDataDetail] = useState({});
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState();
  const singlePromo = useSelector(({ promos }) => promos.singlePromo);

  const columns = [
    {
      dataField: "invoiceNumber",
      text: "Invoice Number",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "id",
      text: "Reseller ID",
      style: { textAlign: "center" },
      sort: true,
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{row?.user?.referralCode}</p>
      ),
    },
    {
      dataField: "createdAt",
      text: "Order Date",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>
          {dateFormat(cell, "dd/mm/yyyy HH:MM:ss")}
        </p>
      ),
    },
  ];

  const fetchPromo = async () => {
    try {
      const { data } = await Axios({
        url: `/promo/${id}/order?page=${page + 1}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      setDataLog(data.data.orders);
      setTotalPage(data.data.totalPage);
    } catch (err) {
      console.log(err);
    }
  };

  const changePageHandler = (value) => {
    setPage(value.selected);
  };

  useEffect(() => {
    dispatch(setSinglePromo(id));
  }, [id]);

  useEffect(() => {
    setDataDetail(singlePromo);
  }, [singlePromo]);

  useEffect(() => {
    fetchPromo();
  }, [page]);

  useEffect(() => {
    return () => {
      setDataLog([]);
      setDataDetail({});
      setPage(0);
      setTotalPage();
    };
  }, []);

  return (
    <>
      <Breadcrumb title={"Promo Usage"} parent="Promo" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <h5>Promo Usage</h5>
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="12" style={{ paddingRight: "2rem" }}>
                <div className="form-group row">
                  <Label className="col-xl-3 col-md-4">Promo Code</Label>
                  <Input
                    className="form-control col-md-3"
                    type="text"
                    value={dataDetail?.code}
                    disabled
                  />
                  <Input
                    style={{ marginLeft: "auto" }}
                    className="form-control col-md-3"
                    type="text"
                    value={dataDetail?.isActive ? "Active" : "Not Active"}
                    disabled
                  />
                </div>
                <div className="form-group row">
                  <Label className="col-xl-3 col-md-4">Description</Label>
                  <textarea
                    disabled
                    className="form-control col-xl-9 promo-usage-textarea"
                    value={dataDetail?.description}
                  />
                </div>
                <div className="form-group row">
                  <Label className="col-xl-3 col-md-4">Promo Category</Label>
                  <Input
                    className="form-control col-md-3"
                    type="text"
                    value={dataDetail?.category}
                    disabled
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h5>Promo Data</h5>
          </CardHeader>
          <CardBody style={{ paddingTop: "0" }}>
            {!isEmpty(dataLog) ? (
              <ToolkitProvider
                data={dataLog}
                keyField="partner_name"
                columns={columns}
              >
                {(props) => (
                  <div className="py-4 table-responsive list-promo__table-container">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      trStyle={{ backgroundColor: "white" }}
                    />
                  </div>
                )}
              </ToolkitProvider>
            ) : (
              <Text textAlign="center" padding="1em">
                There is no data entry
              </Text>
            )}

            {!isEmpty(dataLog) && (
              <Pagination
                page={page}
                changePageHandler={changePageHandler}
                totalPage={totalPage}
              />
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default navguard(PromoUsage);
