import { Text } from "@chakra-ui/react";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { warehouseAction } from "../../../store/actions/warehouseAction";
import { TextField } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

function FilterByDc({ setPage }) {
  const warehouses = useSelector((state) => state.warehouse.warehouses);
  const dispatch = useDispatch();
  const [queryWarehouse, setQueryWarehouse] = useState(null);
  const { search } = useLocation();
  const history = useHistory();
  const currentQuery = queryString.parse(search);
  const [isDisabled] = useState(
    localStorage.getItem("workingArea") === "Distribution Center" &&
      localStorage.getItem("warehouseId")
  );

  useEffect(() => {
    dispatch(warehouseAction());
  }, [dispatch]);

  const handleChangeWarehouse = (value) => {
    let query;
    setPage(0);
    if (value) {
      setQueryWarehouse(value);
      query = queryString.stringify({
        ...currentQuery,
        page: 0,
        warehouseId: value.id,
      });
      history.push(`/order-list?${query}`);
    } else {
      setQueryWarehouse(null);
      query = queryString.stringify(
        {
          ...currentQuery,
          page: 0,
          warehouseId: "",
        },
        { skipEmptyString: true }
      );
      history.push(`/order-list?${query}`);
    }
  };

  useEffect(() => {
    if (currentQuery.warehouseId) {
      let tempWarehouse = warehouses.find(
        (each) => each.id === currentQuery.warehouseId
      );
      setQueryWarehouse(tempWarehouse);
    }
  }, [search, warehouses]);

  useEffect(() => {
    if (
      localStorage.getItem("workingArea") === "Distribution Center" &&
      localStorage.getItem("warehouseId")
    ) {
      let tempWarehouse = warehouses.find(
        (each) => each.id === localStorage.getItem("warehouseId")
      );
      setQueryWarehouse(tempWarehouse);
    }
  }, [warehouses]);
  return (
    <Row style={{ width: "40%", alignItems: "center" }}>
      <Col
        md="4"
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}>
        <Text color="black" fontSize="16px">
          Filter By DC
        </Text>
      </Col>
      <Col md="8">
        <Autocomplete
          disablePortal
          id="warehouse"
          options={warehouses}
          value={queryWarehouse}
          getOptionLabel={(warehouse) => warehouse.warehouseName}
          size="medium"
          style={{ width: "100%" }}
          disabled={isDisabled}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Distribution Center"
            />
          )}
          placement="bottom"
          onChange={(event, value) => handleChangeWarehouse(value)}
        />
      </Col>
    </Row>
  );
}

export default FilterByDc;
