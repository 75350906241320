import React, { useState, useEffect } from "react";

import Breadcrumb from "../common/breadcrumb";
import Loader from "../loader/Loader";
import "./styles/Categories.scss";

import { Col, Container, Row } from "reactstrap";
import { useToast } from "@chakra-ui/react";
import AddCategory from "./component/AddCategory";
import CategoryList from "./component/CategoryList";

import iconDefault from "../../assets/images/icon_default.png";
import imageDefault from "../../assets/images/image_default.png";
import Axios from "../../services/axios";
import navguard from "../auth/navguard";

const Categories = () => {
  const toast = useToast();
  const imageMaxSize = 2000000;
  const [isLoading, setIsLoading] = useState(false);
  const [fuzzyscore] = useState(75);
  const [query, setQuery] = useState("");
  const [searchType, setSearchType] = useState("Category Group");

  const [listGroupCategory, setListGroupCategory] = useState([]);
  const [listCategory, setListCategory] = useState([]);
  const [listSubCategory, setListSubCategory] = useState([]);

  const [allGroupCategory, setAllGroupCategory] = useState([]);
  const [filteredGroupCategory, setFilteredGroupCategory] = useState([]);

  const [allCategory, setAllCategory] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState([]);

  const [allSubCategory, setAllSubCategory] = useState([]);
  const [filteredSubCategory, setFilteredSubCategory] = useState([]);

  const [categoryObj, setCategoryObj] = useState({
    type: "Group Category",
    image: iconDefault,
    banner: imageDefault,
    description: "",
    iconActive: iconDefault,
  });

  const reloadGroupCatData = async () => {
    setAllGroupCategory(listGroupCategory);
    setFilteredGroupCategory(listGroupCategory);
    await reloadListCategory();
  };

  const reloadCategory = async () => {
    setAllCategory(listCategory);
    setFilteredCategory(listCategory);
    await reloadListSubCategory();
  };

  const reloadSubCategory = async () => {
    setAllSubCategory(listSubCategory);
    setFilteredSubCategory(listSubCategory);
  };

  useEffect(() => {
    const fetchGroupCatData = async () => {
      await reloadGroupCatData();
    };

    fetchGroupCatData();
  }, [listGroupCategory]);

  useEffect(() => {
    const fetchCatData = async () => {
      await reloadCategory();
    };

    fetchCatData();
  }, [listCategory]);

  useEffect(() => {
    const fetchSubCatData = async () => {
      await reloadSubCategory();
    };

    fetchSubCatData();
  }, [listSubCategory]);

  const reloadListGroupCategory = async () => {
    try {
      let result = await Axios({
        method: "GET",
        url: `/category-group`,
      });
      let tempListGroupCategory = result.data.data.categoryGroups;
      setListGroupCategory(tempListGroupCategory.sort( function ( a, b ) { return a.ordering - b.ordering; } ));
    } catch (e) {
      setListGroupCategory([]);
    }
  };

  const reloadListCategory = async () => {
    try {
      let result = await Axios({
        method: "GET",
        url: `/category/list`,
      });
      let tempListCategory = result.data.data.categories;
      setListCategory(tempListCategory.sort( function ( a, b ) { return a.ordering - b.ordering; } ));
    } catch (e) {
      setListCategory([]);
    }
  };

  const reloadListSubCategory = async () => {
    let listSubCategory = [];
    let result;
    for (let i = 0; i < listCategory.length; i += 1) {
      try {
        result = await Axios({
          method: "GET",
          url: `/category/${listCategory[i].name}/subcategory/list`,
        });
        result.data.data.subCategories.forEach((subCategory) => {
          listSubCategory.push(subCategory);
        });
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    }

    setListSubCategory(listSubCategory);
  };

  const updateGroupCategorySequence = async (groupCategoryData, value) => {
    let ordering = value === "Increase" ? "move-up" : "move-down"
    try {
      setIsLoading(true);
      await Axios({
        method: "PATCH",
        url: `/category-group/${groupCategoryData.id}/${ordering}`,
      }).then((result) => {
        toast({
          title: "Sukses!",
          description: `Data urutan Kategori Grup ${groupCategoryData.name} berhasil diubah`,
          status: "success",
          position: "top",
          duration: 1500,
          isClosable: true,
          onCloseComplete: () => {
            setIsLoading(false);
            window.location.reload()
          }
        });
      });
    } catch (e) {
      console.error(e);
      toast({
        title: "Error.",
        description: e,
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const updateCategorySequence = async (categoryData, value) => {
    let ordering = value === "Increase" ? "moveUp" : "moveDown"
    try {
      setIsLoading(true);
      await Axios({
        method: "POST",
        url: `/category/${categoryData.name}/${ordering}`,
      }).then((result) => {
        toast({
          title: "Sukses!",
          description: `Data urutan Kategori ${categoryData.name} berhasil diubah`,
          status: "success",
          position: "top",
          duration: 1500,
          isClosable: true,
          onCloseComplete: () => {
            setIsLoading(false);
            window.location.reload()
          }
        });
      });
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      toast({
        title: "Error.",
        description: e,
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateSubCategorySequence = async (subCategoryData, value) => {
    let ordering = value === "Increase" ? "moveUp" : "moveDown"
    try {
      setIsLoading(true);
      await Axios({
        method: "POST",
        url: `/category/${subCategoryData.categoryName}/subcategory/${subCategoryData.name}/${ordering}`,
      }).then((result) => {
        toast({
          title: "Sukses!",
          description: `Data urutan Sub Kategori ${subCategoryData.name} berhasil diubah`,
          status: "success",
          position: "top",
          duration: 1500,
          isClosable: true,
          onCloseComplete: () => {
            setIsLoading(false);
            window.location.reload()
          }
        });
      });
    } catch (e) {
      console.error(e);
      toast({
        title: "Error.",
        description: e,
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    reloadListGroupCategory();
  }, []);

  const queryHandler = (event) => {
    setQuery(event.target.value);
  };

  const searchByCategoryGroup = () => {
    setSearchType("Category Group");
  }

  const searchByCategory = () => {
    setSearchType("Category");
  }

  const searchBySubCategory = () => {
    setSearchType("Sub Category");
  }

  const searchFunc = (tempData, query) => {
    const fuzzball = require("fuzzball");
    const result = [];
    let fuzzballScore;

    tempData.forEach((data) => {
      fuzzballScore = fuzzball.partial_ratio(
        query.toLowerCase(),
        data.name.toLowerCase()
      );

      if (fuzzballScore >= fuzzyscore) {
        result.push(data);
      }
    });

    return result;
  }

  const searchHandler = (event) => {
    let query = event.target.value;
    let tempData;

    tempData = (
      searchType === "Category Group" ? [...allGroupCategory]
      : "Category" ? [...allCategory]
      : "Sub Category" ? [...allSubCategory]
      : []
    );

    tempData = [...allGroupCategory];

    tempData = (
      searchType === "Category Group"
      ? [...allGroupCategory]
      : searchType === "Category" ? [...allCategory]
      : searchType === "Sub Category" ? [...allSubCategory]
      : []
    );

    switch (searchType) {
      case "Category Group":
        if (query === "") {
          setFilteredGroupCategory(allGroupCategory);
        } else {
          setFilteredGroupCategory(searchFunc(tempData, query));
        }
        break;
      
      case "Category":
        if (query === "") {
          setFilteredCategory(allCategory);
        } else {
          setFilteredCategory(searchFunc(tempData, query));
        }
        break;

      case "Sub Category":
        if (query === "") {
          setFilteredSubCategory(allSubCategory);
        } else {
          setFilteredSubCategory(searchFunc(tempData, query));
        }
        break;
    
      default:
        break;
    }
  };

  const changeHandler = (e) => {
    let temp = { ...categoryObj };
    if (e.target.name === "type") {
      delete temp["categoryName"];
      delete temp["subCategoryName"];
      delete temp["categoryGroupName"];
    }
    temp[e.target.name] = e.target.value;

    setCategoryObj(temp);
  };

  const submitValidation = () => {
    let statusValidation = {
      status: "success",
      message: `${categoryObj.type} berhasil ditambahkan`,
    };

    if (categoryObj.type === "Category") {
      if (!categoryObj.categoryName) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon isi nama kategori";
      } else if (!categoryObj.description) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon isi description kategori";
      } else if (!categoryObj.parentGroupCategory) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon pilih Group Category";
      } else if (
        !categoryObj.savedBanner ||
        categoryObj.banner === imageDefault
      ) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon upload banner";
      } else if (categoryObj.image === iconDefault) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload icon";
      } else if (categoryObj.iconActive === iconDefault) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload active icon";
      }
    } else if (categoryObj.type === "Sub Category") {
      if (!categoryObj.subCategoryName) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon isi nama sub kategori";
      } else if (!categoryObj.description) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon isi deskripsi sub kategori";
      } else if (!categoryObj.parentCategory) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon pilih parent kategori";
      } else if (
        !categoryObj.savedBanner ||
        categoryObj.banner === imageDefault
      ) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon upload banner";
      } else if (categoryObj.image === iconDefault) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload icon";
      }
    } else if (categoryObj.type === "Category Group") {
      if (!categoryObj.groupCategoryName) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon isi nama grup kategori";
      } else if (!categoryObj.description) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon isi deskripsi grup kategori";
      } else if (
        !categoryObj.savedBanner ||
        categoryObj.banner === imageDefault
      ) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon upload banner";
      } else if (categoryObj.image === iconDefault) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload icon";
      } else if (categoryObj.iconActive === iconDefault) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload active icon";
      }
    }

    return statusValidation;
  };

  const submitNewGroupCategory = async () => {
    let resultData;
    try {
      await Axios({
        method: "POST",
        url: `/category-group`,
        data: {
          name: categoryObj.groupCategoryName,
          description: categoryObj.description,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }).then((result) => {
        resultData = result.data;
      });

      if (!resultData.errorStatus) {
        let newGroupCategoryId = resultData.data.id;
        
        let data = new FormData();
        data.append("image", categoryObj.savedImage);

        let config = {
          method: "PATCH",
          url: `/category-group/${newGroupCategoryId}/icon`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);

        let dataIconActive = new FormData();
        dataIconActive.append("image", categoryObj.savedActiveImage);

        let configIconActive = {
          method: "PATCH",
          url: `/category-group/${newGroupCategoryId}/icon-active`,
          data: dataIconActive,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(configIconActive);

        let banner = new FormData();
        banner.append("image", categoryObj.savedBanner);

        let configBanner = {
          method: "PATCH",
          url: `/category-group/${newGroupCategoryId}/banner`,
          data: banner,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(configBanner);

        toast({
          title: "Sukses.",
          description: `${categoryObj.type} ${categoryObj.groupCategoryName} berhasil ditambahkan`,
          status: "success",
          position: "top",
          duration: 1500,
          isClosable: true,
          onCloseComplete: () => {
            window.location.reload()
          }
        });
        setCategoryObj({
          type: "Category",
        });
        reloadListCategory();
        setIsLoading(false);
      } else {
        toast({
          title: "Error.",
          description: resultData.message || resultData.errors[0].message,
          status: "error",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "Error.",
        description: error.response.data.message || error.response.data.errors[0].message,
        status: "error",
        position: "top",
        duration: 1500,
        isClosable: true,
      })
      setIsLoading(false);
    }
  };

  const submitNewCategory = async () => {
    let resultData;
    try {
      await Axios({
        method: "POST",
        url: `/category`,
        data: {
          name: categoryObj.categoryName,
          hexCode: categoryObj.backgroundColor,
          description: categoryObj.description,
          categoryGroupId: categoryObj.parentGroupCategory,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }).then((result) => {
        resultData = result.data;
      });

      if (!resultData.errorStatus) {
        let data = new FormData();
        let dataIconActive = new FormData();
        let newCategoryName = resultData.data.categoryName;

        data.append("image", categoryObj.savedImage);

        let config = {
          method: "POST",
          url: `/category/${newCategoryName}/icon`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);

        dataIconActive.append("image", categoryObj.savedActiveImage);

        let configIconActive = {
          method: "POST",
          url: `/category/${newCategoryName}/activeIcon`,
          data: dataIconActive,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(configIconActive);

        let banner = new FormData();
        banner.append("image", categoryObj.savedBanner);

        let configBanner = {
          method: "POST",
          url: `/category/${newCategoryName}/banner`,
          data: banner,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(configBanner);

        toast({
          title: "Sukses.",
          description: `${categoryObj.type} ${newCategoryName} berhasil ditambahkan`,
          status: "success",
          position: "top",
          duration: 1500,
          isClosable: true,
          onCloseComplete: () => {
            window.location.reload()
          }
        });
        setCategoryObj({
          type: "Category",
        });
        reloadListCategory();
        setIsLoading(false);
      } else {
        toast({
          title: "Error.",
          description: resultData.message || resultData.errors[0].message,
          status: "error",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast({
        title: "Error.",
        description: error.response.data.message || error.response.data.errors[0].message,
        status: "error",
        position: "top",
        duration: 1500,
        isClosable: true,
      })
      setIsLoading(false);
    }
  };

  const submitNewSubCategory = async () => {
    let resultData;
    try {
      const { data } = await Axios({
        method: "POST",
        url: `/category/${categoryObj.parentCategory}/subcategory`,
        data: {
          name: categoryObj.subCategoryName,
          description: categoryObj.description,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      resultData = data;
      if (!resultData.errorStatus) {
        let data = new FormData();
        let newSubCategoryName = resultData.data.subCategoryName;

        data.append("image", categoryObj.savedImage);

        let config = {
          method: "POST",
          url: `/category/${categoryObj.parentCategory}/subcategory/${newSubCategoryName}/icon`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);
        
        let banner = new FormData();
        banner.append("image", categoryObj.savedBanner);

        let configBanner = {
          method: "POST",
          url: `/category/${categoryObj.parentCategory}/subcategory/${newSubCategoryName}/banner`,
          data: banner,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(configBanner);
        toast({
          title: "Sukses.",
          description: `${categoryObj.type} ${newSubCategoryName} berhasil ditambahkan`,
          status: "success",
          position: "top",
          duration: 1500,
          isClosable: true,
          onCloseComplete: () => {
            window.location.reload()
          }
        });
        setCategoryObj({
          type: "Category",
        });
        reloadListCategory();
        setIsLoading(false);
      } else {
        toast({
          title: "Error.",
          description: resultData.message || resultData.errors[0].message,
          status: "error",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast({
        title: "Error.",
        description: error.response.data.message || error.response.data.errors[0].message,
        status: "error",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const submitCategory = () => {
    setIsLoading(true);
    let statusValidation = submitValidation();

    if (statusValidation.status === "success") {
      if (categoryObj.type === "Category") {
        submitNewCategory();
      } else if (categoryObj.type === "Sub Category") {
        submitNewSubCategory();
      } else if (categoryObj.type === "Group Category") {
        submitNewGroupCategory();
      }
    } else {
      toast({
        title: "Error.",
        description: statusValidation.message,
        status: statusValidation.status,
        position: "top",
        duration: 1500,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const getCategoryGroupId = (category) => {
    const result = listGroupCategory.find((item) => item.name === category);
    return result;
  };

  const deleteGroupCategory = (item) => {
    setIsLoading(true);
    const parent = getCategoryGroupId(item);
    Axios({
      method: "DELETE",
      url: `/category-group/${parent.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    })
      .then((result) => {
        if (!result.data.errorStatus) {
          toast({
            title: "Sukses.",
            description: `Data Kategori Grup berhasil dihapus`,
            status: "success",
            position: "top",
            duration: 1500,
            isClosable: true,
            onCloseComplete: () => {
              window.location.reload()
            }
          });
          setCategoryObj({
            type: "Category",
          });
          reloadListCategory();

          setIsLoading(false);
        } else {
          toast({
            title: "Error.",
            description: result.data.errors[0].message,
            status: "error",
            position: "top",
            duration: 1500,
            isClosable: true,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  const deleteCategory = (item) => {
    setIsLoading(true);
    Axios({
      method: "DELETE",
      url: `/category/${item}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    })
      .then((result) => {
        if (!result.data.errorStatus) {
          toast({
            title: "Sukses.",
            description: `${item} berhasil dihapus`,
            status: "success",
            position: "top",
            duration: 1500,
            isClosable: true,
            onCloseComplete: () => {
              window.location.reload()
            }
          });
          setCategoryObj({
            type: "Category",
          });
          reloadListCategory();

          setIsLoading(false);
        } else {
          toast({
            title: "Error.",
            description: result.data.errors[0].message,
            status: "error",
            position: "top",
            duration: 1500,
            isClosable: true,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  const getParentCategory = (subCategory) => {
    const result = listSubCategory.find((item) => item.name === subCategory);
    return result;
  };

  const deleteSubCategory = (item) => {
    setIsLoading(true);
    const parent = getParentCategory(item);
    Axios({
      method: "DELETE",
      url: `/category/${parent.categoryName}/subcategory/${item}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    })
      .then((result) => {
        if (!result.data.errorStatus) {
          toast({
            title: "Sukses.",
            description: `${item} berhasil dihapus`,
            status: "success",
            position: "top",
            duration: 1500,
            isClosable: true,
            onCloseComplete: () => {
              window.location.reload()
            }
          });
          setCategoryObj({
            type: "Category",
          });
          reloadListCategory();

          setIsLoading(false);
        } else {
          toast({
            title: "Error.",
            description: result.data.errors[0].message,
            status: "error",
            position: "top",
            duration: 1500,
            isClosable: true,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  const groupingSubCategory = (parent) => {
    return filteredSubCategory.filter(
      (subCategory) => parent === subCategory.categoryName
    );
  };

  const groupingCategory = (parent) => {
    return filteredCategory.filter(
      (category) => parent === category.categoryGroupId
    );
  };

  const getCountCategoryChild = (parent) => {
    let count = 0;
    filteredSubCategory.forEach((subCategory) => {
      if (parent === subCategory.categoryName) {
        count += 1;
      }
    });

    return count;
  };

  const getCountCategoryGroupChild = (parent) => {
    let count = 0;
    filteredCategory.forEach((category) => {
      if (parent === category.categoryGroupId) {
        count += 1;
      }
    });

    return count;
  };

  const deleteImage = (fileAttr, srcAttr) => {
    let inputImageElmnt = document.getElementById("icon-img");
    let inputImageElmnt2 = document.getElementById("banner-img");
    const tempCategoryObj = { ...categoryObj };
    let defaultValue = iconDefault;
    if (srcAttr === "banner") {
      defaultValue = imageDefault;
      inputImageElmnt2.value = null;
    }
    tempCategoryObj[srcAttr] = defaultValue;
    tempCategoryObj[fileAttr] = undefined;
    inputImageElmnt.value = null;
    setCategoryObj(tempCategoryObj);
  };

  const _handleImgChange = (value, fileAttr, srcAttr) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (value.currentTarget.files.item(0).size > imageMaxSize) {
        toast({
          title: "Error.",
          description: "Mohon upload gambar berukuran kurang dari 2MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        const tempCategoryObj = { ...categoryObj };
        let reader = new FileReader();
        const image = value.target.files[0];
        tempCategoryObj[fileAttr] = image;
        reader.onload = () => {
          tempCategoryObj[srcAttr] = reader.result;
          setCategoryObj(tempCategoryObj);
        };
        reader.readAsDataURL(image);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Breadcrumb title="Categories" parent="Products" />
      <Container fluid={true}>
        <Row>
          <Col sm="5">
            <AddCategory
              listCategory={listCategory}
              listGroupCategory={listGroupCategory}
              categoryObj={categoryObj}
              setCategoryObj={setCategoryObj}
              changeHandler={changeHandler}
              submitCategory={submitCategory}
              _handleImgChange={_handleImgChange}
              deleteImage={deleteImage}
            />
          </Col>
          <Col sm="7">
            <CategoryList
              query={query}
              searchType={searchType}
              deleteCategory={deleteCategory}
              deleteSubCategory={deleteSubCategory}
              deleteGroupCategory={deleteGroupCategory}
              queryHandler={queryHandler}
              searchHandler={searchHandler}
              searchByCategoryGroup={searchByCategoryGroup}
              searchByCategory={searchByCategory}
              searchBySubCategory={searchBySubCategory}
              filteredGroupCategory={filteredGroupCategory}
              groupingCategory={groupingCategory}
              groupingSubCategory={groupingSubCategory}
              getCountCategoryChild={getCountCategoryChild}
              getCountCategoryGroupChild={getCountCategoryGroupChild}
              updateCategorySequence={updateCategorySequence}
              updateSubCategorySequence={updateSubCategorySequence}
              updateGroupCategorySequence={updateGroupCategorySequence}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default navguard(Categories);
