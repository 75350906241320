import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import "./styles/RedemptionTab.css";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

function RedemptionTab({ tabActive, setTabActive }) {
  const { search } = useLocation();
  const currentQuery = queryString.parse(search);
  const history = useHistory();
  const changeTabActiveHandler = (value) => {
    setTabActive(value);

    const query = queryString.stringify({
      ...currentQuery,
      status: value,
    });

    history.push(`/loyalty/list-redemption?${query}`);
  };

  useEffect(() => {
    if (currentQuery.status) {
      setTabActive(currentQuery.status);
    }
  }, [search]);
  return (
    <Flex className="redemption-list__section-tab-container">
      <Box
        className={`redemption-list__section-tab__item ${
          tabActive === "submitted" && "tab-active"
        }`}
        onClick={() => changeTabActiveHandler("submitted")}
      >
        Submitted
      </Box>

      <Box
        className={`redemption-list__section-tab__item ${
          tabActive === "approved" && "tab-active"
        }`}
        onClick={() => changeTabActiveHandler("approved")}
      >
        Approved
      </Box>

      <Box
        className={`redemption-list__section-tab__item ${
          tabActive === "completed" && "tab-active"
        }`}
        onClick={() => changeTabActiveHandler("completed")}
      >
        Completed
      </Box>

      <Box
        className={`redemption-list__section-tab__item ${
          tabActive === "cancelled" && "tab-active"
        }`}
        onClick={() => changeTabActiveHandler("cancelled")}
      >
        Cancelled
      </Box>
    </Flex>
  );
}

export default RedemptionTab;
