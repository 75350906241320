import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Spacer,
  Text,
  Center,
  Divider,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../order/components/pagination";
import "./style/list-article.css";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import Axios from "../../services/axios";
import { isEmpty } from "lodash";
import navguard from "../auth/navguard";

function ListArticle(props) {
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState();
  const history = useHistory();
  const { search } = useLocation();
  const [dataTable, setDataTable] = useState([]);
  const [optionSubtopic, setOptionSubtopic] = useState([]);
  const [filter, setFilter] = useState({
    topic: "",
    subtopic: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const toast = useToast();

  const handlePage = (value) => {
    setPage(value.selected);
  };

  const handleChangeIsActive = async (id) => {
    try {
      await Axios({
        url: `/admin/article/${id}/toggle`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      toast({
        title: "Sucess",
        description: "Berhasil mengubah status artikel",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
      fetchData();
    } catch (err) {
      console.log(err);
      toast({
        title: "Warning",
        description: "Mohon coba beberapa saat lagi",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  const columns = [
    {
      dataField: "helpCenterSubtopic",
      text: "Subtopic",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell) => <p style={{ color: "black" }}>{cell.subtopic}</p>,
    },
    {
      dataField: "title",
      text: "Title",
      style: { textAlign: "center" },
      sort: true,
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "body",
      text: "Article Preview",
      style: { textAlign: "center", width: "500px" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p
          style={{
            color: "black",
          }}
          className="help-center__preview-ellipsis"
          dangerouslySetInnerHTML={{ __html: cell }}
        ></p>
      ),
    },
    {
      dataField: "isActive",
      text: "Status",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{cell ? "Active" : "Not Active"}</p>
      ),
    },
    {
      dataField: "ratings",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => {
        return (
          <>
            <Flex alignItems={"center"} mb={"1em"}>
              <FaRegThumbsUp size={30} color={"#015352"} />
              <Text fontSize={"20px"} color={"black"} ml={"4px"}>
                {"0"}
              </Text>
            </Flex>
            <Flex alignItems={"center"}>
              <FaRegThumbsDown size={30} color={"#ff8084"} />
              <Text fontSize={"20px"} color={"black"} ml={"4px"}>
                {"0"}
              </Text>
            </Flex>
          </>
        );
      },
    },
    {
      dataField: "id",
      style: { textAlign: "end" },
      formatter: (cell, row) => {
        return (
          <Flex justifyContent={"center"} width="100%">
            <Text
              cursor={"pointer"}
              onClick={() =>
                history.push(`/help-center/edit-article/${row.id}`)
              }
            >
              Edit
            </Text>
            <Center height="20px" width="29px">
              <Divider
                orientation="vertical"
                color={"#C4C4C4"}
                opacity={"1"}
                borderLeftWidth={"1.5px"}
              />
            </Center>
            <Text
              cursor={"pointer"}
              onClick={() =>
                history.push(`/help-center/feedback-article/${row.id}`)
              }
            >
              Feedback
            </Text>
            <Center height="20px" width="29px">
              <Divider
                orientation="vertical"
                color={"#C4C4C4"}
                opacity={"1"}
                borderLeftWidth={"1.5px"}
              />
            </Center>
            <Text
              cursor={"pointer"}
              onClick={() => handleChangeIsActive(row.id)}
            >
              {row.isActive ? "Deactivate" : "Activate"}
            </Text>
          </Flex>
        );
      },
    },
  ];

  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setPage(0);
      const currentQuery = queryString.parse(search);
      const queries = queryString.stringify(
        {
          ...currentQuery,
          q: searchQuery,
        },
        { skipEmptyString: true }
      );

      history.push(`/help-center/article?${queries}`);
    }
  };

  const handleFilter = (e) => {
    let tempFilter = { ...filter };
    tempFilter[e.target.name] = e.target.value;
    setFilter(tempFilter);
    setPage(0);
    const currentQuery = queryString.parse(search);
    let queries;
    if (e.target.name === "topic") {
      queries = queryString.stringify(
        {
          ...currentQuery,
          filter_topic: e.target.value,
        },
        { skipEmptyString: true }
      );
    }

    if (e.target.name === "subtopic") {
      queries = queryString.stringify(
        {
          ...currentQuery,
          filter_subtopic: e.target.value,
        },
        { skipEmptyString: true }
      );
    }

    history.push(`/help-center/article?${queries}`);
  };

  const fetchData = async () => {
    try {
      const currentQuery = queryString.parse(search);
      const queries = queryString.stringify({
        ...currentQuery,
        limit: 5,
        page: page + 1,
        sort: "createdAt,desc",
      });

      const { data } = await Axios({
        url: `/admin/article/list?${queries}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      setTotalPage(data.data.totalPage);
      setDataTable(data.data.articles);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDataSubtopic = async () => {
    try {
      const { data } = await Axios({
        url: `/admin/subtopic/list`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      let tempOptionSubtopic;
      if (filter.topic !== "") {
        tempOptionSubtopic = data.data.subtopics.filter(
          (subtopic) => subtopic.topic === filter.topic
        );
      } else {
        tempOptionSubtopic = data.data.subtopics;
      }
      setOptionSubtopic(tempOptionSubtopic);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [search, page]);

  useEffect(() => {
    fetchDataSubtopic();
    // eslint-disable-next-line
  }, [filter.topic]);

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    let tempFilter = { ...filter };
    if (currentQuery.filter_topic) {
      tempFilter.topic = currentQuery.filter_topic;
    }
    if (currentQuery.filter_subtopic) {
      tempFilter.subtopic = currentQuery.filter_subtopic;
    }
    if (currentQuery.q) {
      setSearchQuery(currentQuery.q);
    }
    setFilter(tempFilter);
    // eslint-disable-next-line
  }, [search]);

  return (
    <Box className="help-center__article-container">
      <Flex mb={"1em"}>
        <Text className="help-center__acticle-title">Help Center Article</Text>
        <Spacer />
        <Button
          className="help-center__article-button"
          onClick={() => history.push("/help-center/add-article")}
        >
          Add New Article
        </Button>
      </Flex>

      <Box className="help-center__article-table-container">
        <Flex alignItems="center">
          <Text className="help-center__article-label">Choose Topic :</Text>
          <Select
            width="200px"
            name="topic"
            onChange={(event) => handleFilter(event)}
            value={filter.topic}
          >
            <option value="">All</option>
            <option value="pengenalan">Pengenalan</option>
            <option value="transaksi">Transaksi</option>
            <option value="akun saya">Akun Saya</option>
            <option value="promo">Keuntungan & Promo</option>
            <option value="kebijakan">Akun & Website</option>
          </Select>
        </Flex>

        <Flex alignItems="center" mt="1em">
          <Text className="help-center__article-label">Choose Subtopic :</Text>
          <Select
            width="200px"
            name="subtopic"
            onChange={(event) => handleFilter(event)}
            value={filter.subtopic}
          >
            <option value="">All</option>
            {optionSubtopic.length > 0 &&
              optionSubtopic.map((subtopic, idx) => (
                <option key={idx} value={subtopic.id}>
                  {subtopic.subtopic}
                </option>
              ))}
          </Select>
          <Spacer />
          <Input
            placeholder="Search article"
            width="400px"
            value={searchQuery}
            onChange={(event) => handleSearchQuery(event)}
            onKeyPress={(event) => handleKeyPressSearch(event)}
          />
        </Flex>

        {!isEmpty(dataTable) && (
          <ToolkitProvider
            data={dataTable}
            columns={columns}
            keyField="partner_name"
          >
            {(props) => (
              <div className="py-4 table-responsive help-center__article-table-container">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                  trStyle={{ backgroundColor: "white" }}
                />
              </div>
            )}
          </ToolkitProvider>
        )}

        {!isEmpty(dataTable) && (
          <Box mt={"1em"}>
            <Pagination
              page={page}
              totalPage={totalPage}
              changePageHandler={handlePage}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default navguard(ListArticle);
