import { CloseIcon } from "@chakra-ui/icons";
import { Flex, Spacer } from "@chakra-ui/react";
import "../styles/product-list__modal.scss";

const ProductListModal = ({ setIsPopUp, products }) => {
  return (
    <div className="product-list__container">
      <div className="overlay" onClick={() => setIsPopUp(false)} />
      <div className="main">
        <div className="header">
          <p className="header-text">List Product Selected</p>
          <CloseIcon
            fontSize="10px"
            cursor="pointer"
            onClick={() => setIsPopUp(false)}
          />
        </div>
        <Flex
          justifyContent="center"
          alignItems="center"
          className="product"
          fontWeight="bold"
        >
          <p className="number" style={{ width: "2em" }}>
            No.
          </p>
          <p className="text">Nama</p>
          <Spacer />
          <p className="text">Qty</p>
        </Flex>
        {products.length > 0 &&
          products.map((item, idx) => (
            <Flex
              justifyContent="center"
              alignItems="center"
              className="product"
              key={item.id}
            >
              <p className="number" style={{ width: "2em" }}>
                {idx + 1}.
              </p>
              <p className="text">{item?.product?.name}</p>
              <Spacer />
              <p className="text">{item.defaultQuantity}</p>
            </Flex>
          ))}
      </div>
    </div>
  );
};

export default ProductListModal;
