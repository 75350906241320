import jsPDF from "jspdf";
import "jspdf-barcode";
import PbpLogo from "../../assets/images/logo-pbp.png";
import dateFormat from "dateformat";

const StickerGenerator = (data) => {
  const doc = new jsPDF("l", "cm", [8, 10]);
  data.forEach((each, index) => {
    doc.addImage(
      each.shippingMethod.iconPath,
      "JPEG",
      each.shippingMethod.provider === "Parama"
        ? 6.2
        : each.shippingMethod.provider === "JNT"
        ? 8.5
        : 8.7,
      0.1,
      0,
      0.7
    );

    const orderCategory = [
      ...each.orderDetail
        .reduce((map, item) => map.set(item.productCategory, item), new Map())
        .values(),
    ]
      .map((each) => each.productCategory)
      .join(", ")
      .replace(/(.{34})..+/, "$1…");
    const categoryWrap = doc.splitTextToSize(orderCategory, 6);
    const noteWrap = doc.splitTextToSize(
      each.shippingAddress.note.substring(0, 36),
      5
    );
    const addressWrap = each.shippingAddress.fullAddress
      .substring(0, 100)
      .replace(/(.{40})/g, "$1\r\n");

    doc.setLineWidth(0.01);
    doc.setFontSize("8");
    doc.setFont("helvetica", "normal", "bold");

    if (!each?.isDropship) {
      doc.addImage(PbpLogo, "png", 0.25, 0.25, 2, 0.8);
    }

    if (each.shippingMethod.provider === "Grab") {
      doc.text(each.shippingMethodId.split("-")[1].toUpperCase(), 8.3, 1.25);
      doc.line(8.2, 0.9, 9.8, 0.9);
      doc.line(8.2, 1.4, 9.8, 1.4);
      doc.line(8.2, 0.9, 8.2, 1.4);
      doc.line(9.8, 0.9, 9.8, 1.4);
    } else {
      doc.text(each.shippingMethodId.split("-")[1].toUpperCase(), 8.95, 1.25);
      doc.line(8.7, 0.9, 9.8, 0.9);
      doc.line(8.7, 1.4, 9.8, 1.4);
      doc.line(8.7, 0.9, 8.7, 1.4);
      doc.line(9.8, 0.9, 9.8, 1.4);
    }

    doc.setFont("helvetica", "normal", "normal");
    if (each.shippingMethod.provider !== "JNT") {
      if (each.shippingMethod.provider === "Grab") {
        doc.text(`AWB Number : ${each.shippingVendorData.awb}`, 2.2, 1.8);
      } else {
        doc.text(`AWB Number : ${each.shippingVendorData.awb}`, 2.8, 1.5);
      }
    }

    if (each.shippingMethod.provider === "JNE") {
      doc.text(
        `${each.shippingAddress.address.jneData.originCode} - ${each.shippingAddress.address.jneData.destinationCode}`,
        3.4,
        1.85
      );
    }

    if (each.shippingMethod.provider === "JNT") {
      doc.setFontSize("24");
      doc.setFont("helvetica", "normal", "bold");
      doc.text("TUMPUK RESI DI SINI", 0.5, 2.85);
    } else {
      if (each.shippingMethod.provider === "Grab") {
        doc.barcode(each.shippingVendorData.awb, {
          fontSize: "35",
          textColor: "black",
          x: 0.1,
          y: 3.2,
        });
      } else {
        doc.barcode(each.shippingVendorData.awb, {
          fontSize: "60",
          textColor: "black",
          x: 1.4,
          y: 3.85,
        });
      }
    }

    doc.setFontSize("8");
    doc.setFont("helvetica", "normal", "bold");
    doc.text("Penerima :", 0.25, 4.2);
    doc.text("Pengirim :", 6.3, 4.2);
    doc.text("Kategori Produk :", 6.3, 5.2);
    doc.text("Note :", 0.25, 6.7);
    if (each.shippingMethod.provider === "JNT") {
      doc.text("AWB Number :", 4.8, 6.5);
    } else {
      doc.text("No Invoice :", 4.8, 6.5);
    }
    doc.setFontSize("8");
    doc.setFont("helvetica", "normal", "normal");
    doc.text(each.shippingAddress.recipientName, 1.8, 4.2);
    if (each.isDropship) {
      doc.text(each.senderAddress.recipientName, 7.8, 4.2);
      doc.text(`Telp: ${each.senderAddress.recipientPhone}`, 6.3, 4.53);
    } else {
      doc.text(each.warehouse.warehouseName, 7.8, 4.2);
      doc.text(`Telp: ${each.warehouse.phone}`, 6.3, 4.53);
    }
    doc.text(
      `${addressWrap},\r\n${each.shippingAddress.address.district}, ${each.shippingAddress.address.city}\r\n${each.shippingAddress.address.province}, ${each.shippingAddress.address.postalCode}\r\nTelp. ${each.shippingAddress.recipientPhone}`,
      0.25,
      4.53
    );
    doc.text(categoryWrap, 6.3, 5.5);
    doc.text(noteWrap, 0.25, 7);

    if (each.shippingMethod.provider === "JNT") {
      doc.text(each.shippingVendorData.awb, 7, 6.5);
      doc.barcode(each.shippingVendorData.awb, {
        fontSize: "33",
        textColor: "black",
        x: 4.5,
        y: 7.7,
      });
    } else {
      doc.text(each.invoiceNumber, 6.5, 6.5);
      doc.barcode(each.invoiceNumber, {
        fontSize: "33",
        textColor: "black",
        x: 3.5,
        y: 7.7,
      });
    }

    if (index !== data.length - 1) {
      doc.addPage([8, 10], "l");
    }
  });
  doc.save(`Bulk Sticker - ${dateFormat(new Date(), "d mmmm yyyy")}.pdf`);
};

export default StickerGenerator;
