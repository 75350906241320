import {
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { Button, Col, Row } from "reactstrap";
import one from "../../../assets/images/pro3/1.jpg";
import Axios from "../../../services/axios";

function AddModalReward({
  isOpen,
  onClose,
  inputReward,
  setInputReward,
  submitType,
  fetchData,
}) {
  const toast = useToast();
  const imageRef = useRef();
  const imageMaxSize = 2000000;

  const handleChangeInput = (e) => {
    let tempInput = { ...inputReward };
    let value = e.target.value;

    if (e.target.type === "number") {
      if (value === "") {
        value = null;
      } else {
        value = parseFloat(e.target.value);
      }
    }

    tempInput[e.target.name] = value;
    setInputReward(tempInput);
  };

  const handleImageChange = (value) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (value.currentTarget.files.item(0).size > imageMaxSize) {
        toast({
          title: "Error.",
          description: "Mohon upload gambar berukuran kurang dari 2MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        let tempInput = { ...inputReward };
        let reader = new FileReader();
        const image = value.target.files[0];
        tempInput.img = image;
        reader.onload = () => {
          tempInput.imgSrc = reader.result;
          setInputReward(tempInput);
        };

        reader.readAsDataURL(image);
      }
    }
  };

  const submitValidation = () => {
    let validObj = {
      status: "success",
      message: `Berhasil ${
        submitType === "add" ? "menambahkan" : "mengubah"
      } data reward`,
    };

    let index = 0;
    let isValid = true;
    const keys = Object.keys(inputReward);

    while (keys.length > index && isValid) {
      if (keys[index] !== "imgSrc") {
        if (!inputReward[keys[index]] && keys[index] !== "isActive") {
          isValid = false;
          validObj.status = "warning";
          validObj.message = `Mohon mengisi ${
            keys[index] === "img" ? "image" : keys[index]
          } terlebih dahulu`;
        }
      }
      index++;
    }

    if (inputReward.isActive === "") {
      validObj.status = "warning";
      validObj.message = "Mohon mengisi status terlebih dahulu";
    }

    return validObj;
  };

  const handleSubmit = async () => {
    const validation = submitValidation();
    if (validation.status !== "warning") {
      try {
        let idsubmit;
        let postData = {
          name: inputReward.name,
          isActive: inputReward.isActive === "true" ? true : false,
          point: inputReward.point,
        };
        if (submitType === "add") {
          const { data } = await Axios({
            url: "/loyalty-reward",
            method: "POST",
            data: postData,
          });
          idsubmit = data.data.loyaltyRewardId;
        } else {
          idsubmit = inputReward?.id;
          await Axios({
            url: `/loyalty-reward/${idsubmit}`,
            method: "PATCH",
            data: postData,
          });
        }

        if (inputReward?.imgSrc) {
          let file = new FormData();
          file.append("image", inputReward.img);
          await Axios({
            url: `/loyalty-reward/${idsubmit}/upload-image`,
            method: "POST",
            data: file,
          });
        }

        toast({
          title: "Success.",
          description: validation.message,
          status: validation.status,
          position: "top",
          duration: 2000,
          isClosable: true,
        });

        fetchData();
        handleClose();
      } catch (err) {
        console.log(err.response);
      }
    } else {
      toast({
        title: "Warning.",
        description: validation.message,
        status: validation.status,
        position: "top",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleClose = () => {
    let tempInput = {
      name: "",
      point: "",
      isActive: "",
      img: "",
    };
    setInputReward(tempInput);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent maxW={"1000px"}>
        <Text className="modal-title">
          {submitType === "add" ? "Add New Reward" : "Edit Reward"}
        </Text>
        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3">
            <Text className="modal-label">Reward Name *</Text>
          </Col>
          <Col md="6">
            <Input
              name="name"
              placeholder="Write reward name here"
              value={inputReward.name}
              onChange={(event) => handleChangeInput(event)}
            />
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3">
            <Text className="modal-label">Loyalty Point *</Text>
          </Col>
          <Col md="6">
            <Input
              name="point"
              placeholder="Cost of loyalty point"
              value={inputReward.point}
              onChange={(event) => handleChangeInput(event)}
              type="number"
            />
          </Col>
        </Row>

        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3">
            <Text className="modal-label">Status *</Text>
          </Col>
          <Col md="6">
            <Select
              name="isActive"
              placeholder="Choose status"
              value={inputReward.isActive}
              onChange={(event) => handleChangeInput(event)}
            >
              <option value={"true"}>Active</option>
              <option value={"false"}> Not Active</option>
            </Select>
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3">
            <Text className="modal-label">Reward Image *</Text>
          </Col>
          <Col
            md="6"
            style={{
              height: "30vh",
            }}
          >
            {!inputReward.img && (
              <img
                src={one}
                style={{
                  height: "100%",
                  // width: "100%",
                  cursor: "pointer",
                }}
                onClick={() => imageRef.current.click()}
              />
            )}
            <Input
              type="file"
              display={"none"}
              ref={imageRef}
              id="reward-image-input"
              name="img"
              accept="image/*"
              onChange={(e) => handleImageChange(e)}
            />
            <img
              src={inputReward.imgSrc || inputReward.img}
              style={{ height: "100%" }}
              className="img-fluid image_zoom_1 blur-up lazyloaded"
              onClick={() => imageRef.current.click()}
            />
          </Col>
        </Row>

        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3" />
          <Col md="6">
            <Button className="modal-save__button" onClick={handleSubmit}>
              Save
            </Button>
          </Col>
        </Row>
      </ModalContent>
    </Modal>
  );
}

export default AddModalReward;
