import React from "react";
import {
  Flex,
  Input,
  IconButton,
  useToast,
  Box,
  Text,
  CloseButton,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Card, CardBody, Col, Row } from "reactstrap";

function SectionFAQ({ data, setData, type, validateFaq }) {
  const toast = useToast();
  const changeHandlerFaq = (target, index) => {
    let tempFaq = [...data];
    let { value } = target;
    tempFaq[index][target.name] = value;
    setData(tempFaq);
  };

  const addFaq = () => {
    const isValid = validateFaq();
    if (isValid.status === "warning") {
      toast({
        title: "Warning.",
        description: isValid.message,
        status: isValid.status,
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    } else {
      const tempObj = {
        question: "",
        answer: "",
      };
      setData([...data, tempObj]);
    }
  };

  const deleteFaq = (i) => {
    let temp = [];
    data.forEach((faq, idx) => {
      if (i !== idx) {
        temp.push(faq);
      }
    });
    setData(temp);
  };

  const moveOrder = (fromIndex, toIndex) => {
    let tempData = [...data];
    let faq = tempData[fromIndex];
    tempData.splice(fromIndex, 1);
    tempData.splice(toIndex, 0, faq);
    setData(tempData);
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col xl={12} style={{ marginBottom: "16px" }}>
            <Box
              style={{
                width: "100%",
                borderBottomWidth: "1px",
                marginBottom: "2rem"
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "black",
                }}
              >
                FAQ {type}
              </p>
            </Box>
            {data.map((input, index) => {
              return (
                <Flex
                  key={index}
                  mb="2em"
                  borderRadius="md"
                  borderWidth="1px"
                  padding="8px"
                >
                  <Box flex={1}>
                    <Box>
                      <Text fontWeight="bold">Question ({index + 1})</Text>
                      <Input
                        value={input.question}
                        name="question"
                        onChange={(e) => changeHandlerFaq(e.target, index)}
                        type="text"
                      />
                    </Box>
                    <Box>
                      <Text fontWeight="bold" mt={4}>
                        Answer ({index + 1})
                      </Text>
                      <Input
                        value={input.answer}
                        name="answer"
                        onChange={(e) => changeHandlerFaq(e.target, index)}
                        type="text"
                      />
                    </Box>
                  </Box>
                  <Flex flexDirection="column">
                    <IconButton
                      isDisabled={index === 0}
                      icon={<ChevronUpIcon />}
                      colorScheme="teal"
                      size="sm"
                      margin={3}
                      onClick={() => moveOrder(index, index - 1)}
                    />
                    <IconButton
                      isDisabled={index === data.length - 1}
                      icon={<ChevronDownIcon />}
                      colorScheme="teal"
                      size="sm"
                      margin={3}
                      onClick={() => moveOrder(index, index + 1)}
                    />
                  </Flex>

                  <Box>
                    <CloseButton
                      style={{
                          height: "32px",
                          width: "32px",
                      }}
                      mt={3}
                      mr={3}
                      size="sm"
                      backgroundColor="#ff605c"
                      color="white"
                      onClick={() => deleteFaq(index)}
                    />
                  </Box>
                </Flex>
              );
            })}
            <Flex justifyContent="end" marginTop="8px">
              <IconButton icon={<AddIcon />} onClick={addFaq} variant="ghost" />
            </Flex>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default SectionFAQ;
