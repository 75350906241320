import React, { useEffect, useState } from "react";
import { Flex, Box, Spacer, Select, Text } from "@chakra-ui/react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

function FilterListContent(props) {
  const { search } = useLocation();
  const history = useHistory();
  const [sort, setSort] = useState("");
  const [accountStatus, setAccounStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");

  const changeSortHandler = (event) => {
    setSort(event.target.value);
    const currentQuery = queryString.parse(search);
    const query = queryString.stringify({
      ...currentQuery,
      sort: event.target.value,
    });

    history.push(`/commission/list-commission?${query}`);
  };

  const changeStatusHandler = (event) => {
    const currentQuery = queryString.parse(search);
    let query;
    if (event.target.name === "account") {
      setAccounStatus(event.target.value);
      query = queryString.stringify(
        {
          ...currentQuery,
          accountStatus: event.target.value,
        },
        {
          skipEmptyString: true,
        }
      );
    } else if (event.target.name === "payment") {
      setPaymentStatus(event.target.value);
      query = queryString.stringify(
        {
          ...currentQuery,
          isPaid: event.target.value,
        },
        {
          skipEmptyString: true,
        }
      );
    }

    history.push(`/commission/list-commission?${query}`);
  };

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (currentQuery.accountStatus || currentQuery.isPaid) {
      setAccounStatus(currentQuery.accountStatus);
      setPaymentStatus(currentQuery.isPaid);
    }
    if (currentQuery.sort) {
      setSort(currentQuery.sort);
    }
  }, [search]);

  return (
    <Flex>
      <Box>
        <Flex direction={"column"}>
          <Flex mb={"1rem"}>
            <Text color="black" fontSize="16px" mr="16px">
              Account Status
            </Text>

            <Spacer />
            <Select
              width="120px"
              value={accountStatus}
              name="account"
              onChange={(event) => changeStatusHandler(event)}
            >
              <option value={""}>All</option>
              <option value={"uncomplete"}>Uncomplete</option>
              <option value={"not eligible"}>Not Eligible</option>
              <option value={"eligible"}>Eligible</option>
            </Select>
          </Flex>

          <Flex align={"center"}>
            <Text color="black" fontSize="16px" mr="16px">
              Payment Status
            </Text>
            <Spacer />

            <Select
              width={"120px"}
              value={paymentStatus}
              name="payment"
              onChange={(event) => changeStatusHandler(event)}
            >
              <option value={""}>All</option>
              <option value={"unpaid"}>Not Paid</option>
              <option value={"paid"}>Paid</option>
            </Select>
          </Flex>
        </Flex>
      </Box>

      <Spacer />

      <Box>
        <Flex align="center">
          <Text mr="16px" color="black" fontSize="16px">
            Sort By
          </Text>
          <Select
            width="120px"
            value={sort}
            onChange={(event) => changeSortHandler(event)}
          >
            <option value="firstName,desc">A-Z</option>
            <option value="firstName,asc">Z-A</option>
            <option value="commission,asc">Terkecil</option>
            <option value="commission,desc">Terbesar</option>
          </Select>
        </Flex>
      </Box>
    </Flex>
  );
}

export default FilterListContent;
