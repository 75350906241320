import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  ModalContent,
  ModalOverlay,
  Text,
  Modal,
  Button,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import SkeletonProductTable from "./component/skeletonProductTable";
import {
  DeleteIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  AddIcon,
  EditIcon,
} from "@chakra-ui/icons";
import { Switch } from "@chakra-ui/react";
import AddModal from "./component/AddPackageModal";
import ProductListModal from "./component/ProductList";
import "./styles/product-package.scss";
import Axios from "../../services/axios";
import navguard from "../auth/navguard";

const ProductPackage = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isProductPopUp, setIsProductPopUp] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [packageSelected, setPackageSelected] = useState({});
  const [isOpenDeleteHandler, setIsOpenDeleteHandler] = useState(false);
  const fetchingProductPackage = async (useLoading) => {
    if (useLoading) {
      setIsLoading(true);
    }
    try {
      const { data } = await Axios({
        method: "GET",
        url: "/package-recommendation/list?sort=order,desc",
      });
      setPackages(data.data.packages);
      setIsLoading(false);
    } catch (error) {
      setPackages([]);
      setIsLoading(false);
    }
  };

  const changeStatusHandler = async ({ value, productId, packageType }) => {
    try {
      await Axios({
        method: "PATCH",
        url: `/package-recommendation/${productId}`,
        data: {
          isActive: value,
          packageType,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      fetchingProductPackage();
      toast({
        title: "Success!",
        description: "Berhasil mengubah status",
        status: "success",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Error!",
        description: "",
        status: "error",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  const movingHandler = async ({ state, id }) => {
    try {
      await Axios({
        method: "POST",
        url: `/package-recommendation/${id}/${state}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      fetchingProductPackage();
      toast({
        title: "Success!",
        description: "Berhasil memindahkan",
        status: "success",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Error!",
        description: "",
        status: "error",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  const deleteHandler = async ({ productId }) => {
    try {
      await Axios({
        method: "DELETE",
        url: `/package-recommendation/${productId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      fetchingProductPackage();
      setIsOpenDeleteHandler(false);
      toast({
        title: "Success!",
        description: "Berhasil menghapus",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Error!",
        description: "",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const editHandler = ({ packageSelected }) => {
    setPackageSelected(packageSelected);
    setIsOpenAddModal(true);
  };

  useEffect(() => {
    fetchingProductPackage(true);
  }, []);

  return (
    <div className="product-package__recommendation-container">
      {isOpenAddModal && (
        <AddModal
          setIsModal={setIsOpenAddModal}
          fetchingProductPackage={fetchingProductPackage}
          packageSelected={packageSelected}
        />
      )}
      {isProductPopUp && (
        <ProductListModal
          products={selectedProduct}
          setIsPopUp={setIsProductPopUp}
        />
      )}
      <Modal
        isOpen={isOpenDeleteHandler}
        onClose={() => setIsOpenDeleteHandler(false)}
      >
        <ModalOverlay />
        <ModalContent p="32px" d="flex" justify="center">
          <ModalCloseButton />

          <Box mt="16px">
            <Text className="popup-text__confirmation-package">
              Apakah anda yakin ingin menghapus product package ini?
            </Text>
          </Box>
          <Flex w="100%" justify="center">
            <Button
              mr="16px"
              variant="ghost"
              onClick={() => setIsOpenDeleteHandler(false)}
            >
              Cancel
            </Button>
            <Button
              ml="16px"
              color="white"
              bg="#ff8084"
              onClick={() =>
                deleteHandler({ productId: packageSelected.productId })
              }
            >
              Yakin
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
      <div className="list-testimony__container">
        <div className="header">
          <p className="title">List Product Package</p>
          <button
            className="button"
            onClick={() => {
              setPackageSelected({});
              setIsOpenAddModal(true);
            }}
          >
            <AddIcon />
          </button>
        </div>
        <div className="testimony-card__container">
          {isLoading && <SkeletonProductTable />}

          {!isLoading && (
            <Flex className="table" flexDirection="column">
              <Flex className="table-head">
                <Box className="table-head__item" width="5%">
                  <Text>No</Text>
                </Box>
                <Box className="table-head__item" width="15%">
                  <Text>Package Name</Text>
                </Box>
                <Box className="table-head__item" width="10%">
                  <Text>Status</Text>
                </Box>
                <Box className="table-head__item" width="20%">
                  <Text>Type</Text>
                </Box>
                <Box className="table-head__item" width="30%">
                  <Text>Tagged Products</Text>
                </Box>
                <Box className="table-head__item" width="20%">
                  <Text>Actions</Text>
                </Box>
              </Flex>
            </Flex>
          )}
          {!isLoading &&
            packages.length > 0 &&
            packages.map((item, idx) => (
              <div className="package-card" key={item.id}>
                <Box className="package-text" width="5%">
                  <p>{idx + 1}</p>
                </Box>
                <Box className="package-text" width="15%">
                  <p className="name">{item.name}</p>
                </Box>
                <Box className="package-text" width="10%">
                  {item.isActive ? "Active" : "Inactive"}
                </Box>
                <Box className="package-text" width="20%">
                  {item.packageType}
                </Box>
                <Box className="package-text" width="30%">
                  <p
                    className="text-product"
                    onClick={() => {
                      setSelectedProduct(item.productPackageRecommendations);
                      setIsProductPopUp(true);
                    }}
                  >
                    Lihat
                  </p>
                </Box>
                <Box className="icon-container" width="20%">
                  <EditIcon
                    color="#3b818d"
                    fontSize="18px"
                    mr="16px"
                    cursor="pointer"
                    onClick={() => editHandler({ packageSelected: item })}
                  />
                  <DeleteIcon
                    color="#ff8084"
                    fontSize="18px"
                    mr="16px"
                    cursor="pointer"
                    onClick={() => {
                      setIsOpenDeleteHandler(true);
                      setPackageSelected({ productId: item.id });
                    }}
                  />

                  <Switch
                    margin="0"
                    size="sm"
                    mr="24px"
                    className="switch"
                    isChecked={item.isActive}
                    onChange={(e) =>
                      changeStatusHandler({
                        value: e.target.checked,
                        productId: item.id,
                        packageType: item.packageType,
                      })
                    }
                  />
                  <ChevronUpIcon
                    fontSize="24px"
                    cursor={idx === 0 ? "none" : "pointer"}
                    mr="16px"
                    onClick={() =>
                      movingHandler({ state: "moveDown", id: item.id })
                    }
                    opacity={idx === 0 ? 0 : 1}
                  />

                  <ChevronDownIcon
                    fontSize="24px"
                    cursor="pointer"
                    mr="24px"
                    onClick={() =>
                      movingHandler({ state: "moveUp", id: item.id })
                    }
                  />
                </Box>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default navguard(ProductPackage);
