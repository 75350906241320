import {
  Box,
  Center,
  Divider,
  Flex,
  Input,
  Spacer,
  Text,
  useDisclosure,
  useToast,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import navguard from "../auth/navguard";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import Pagination from "../order/components/pagination";
import AddModalReward from "./components/AddModalReward";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Axios from "../../services/axios";
import "./styles/ListReward.css";

function ListReward(props) {
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState();
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { search } = useLocation();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [submitType, setSubmitType] = useState("");

  const [inputReward, setInputReward] = useState({
    name: "",
    point: "",
    isActive: "",
    img: "",
  });

  const formatter = new Intl.NumberFormat("id-ID");

  const handlePage = (value) => {
    setPage(value.selected);
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const currentQuery = queryString.parse(search);
      const queries = queryString.stringify(
        {
          ...currentQuery,
          q: searchQuery,
        },
        { skipEmptyString: true }
      );

      history.push(`/loyalty/list-reward?${queries}`);
    }
  };

  const handleClickModal = ({ type, data }) => {
    setSubmitType(type);
    onOpen();
    let tempInput = { ...inputReward };
    if (type === "edit") {
      Object.keys(tempInput).forEach((key) => {
        if (key === "img") {
          tempInput[key] = data["imagePath"];
        } else if (key === "isActive") {
          tempInput[key] = data[key] ? "true" : "false";
        } else {
          tempInput[key] = data[key];
        }
      });
      tempInput["id"] = data["id"];
    } else {
      Object.keys(tempInput).forEach((key) => {
        tempInput[key] = "";
      });
    }
    setInputReward(tempInput);
  };

  const fetchData = async () => {
    try {
      const currentQuery = queryString.parse(search);
      const queries = queryString.stringify({
        ...currentQuery,
        page: page + 1,
        limit: 10,
        sort: "createdAt,desc",
      });
      const { data } = await Axios({
        url: `/loyalty-reward/list?${queries}`,
        method: "GET",
      });

      setData(data.data.loyaltyRewards);
      setTotalPage(data.data.totalPage);

      return data.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteData = async (id) => {
    try {
      await Axios({
        url: `/loyalty-reward/${id}`,
        method: "delete",
      });
      const response = await fetchData();
      toast({
        title: "Success.",
        description: "Berhasil menghapus data.",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });

      if (page !== 0 && response.loyaltyRewards.length <= 0) {
        if (response.totalPage > 1) {
          setPage(page - 1);
        } else {
          setPage(0);
        }
      }
    } catch (err) {
      console.log(err.response);
      const { data } = err.response;
      toast({
        title: "Warning.",
        description: data.errors[0].message,
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  const handleActivate = async (id, data) => {
    try {
      await Axios({
        url: `/loyalty-reward/${id}`,
        method: "PATCH",
        data: {
          isActive: data.isActive ? false : true,
        },
      });
      toast({
        title: "Success.",
        description: "Berhasil mengubah status data.",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
      fetchData();
    } catch (err) {
      console.log(err.response);
      const { data } = err.response;
      toast({
        title: "Warning.",
        description: data.errors[0].message,
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (page !== 0) setPage(0);
    fetchData();
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [page]);

  const columns = [
    {
      dataField: "name",
      text: "Reward Name",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "point",
      text: "Point",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => {
        return <p style={{ color: "black" }}>{formatter.format(cell)}</p>;
      },
    },
    {
      dataField: "isActive",
      text: "Status",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      sort: true,
      formatter: (cell, row) => {
        return (
          <p style={{ color: "black" }}>{cell ? "Active" : "Not Active"}</p>
        );
      },
    },
    {
      dataField: "redeemCount",
      text: "Number of Redeemed",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => {
        return <p style={{ color: "black" }}>{formatter.format(cell)}</p>;
      },
    },
    {
      dataField: "id",
      text: "",
      style: { textAlign: "end" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => {
        return (
          <Flex justifyContent={"center"} width="100%">
            <Text
              cursor={"pointer"}
              onClick={() => handleClickModal({ type: "edit", data: row })}
            >
              Edit
            </Text>
            <Center height="20px" width="29px">
              <Divider
                orientation="vertical"
                color={"#C4C4C4"}
                opacity={"1"}
                borderLeftWidth={"1.5px"}
              />
            </Center>
            <Text
              cursor={"pointer"}
              onClick={() => handleActivate(row.id, row)}
            >
              {row.isActive ? "Deactivate" : "Activate"}
            </Text>
            <Center height="20px" width="29px">
              <Divider
                orientation="vertical"
                color={"#C4C4C4"}
                opacity={"1"}
                borderLeftWidth={"1.5px"}
              />
            </Center>
            <Text cursor={"pointer"} onClick={() => handleDeleteData(row.id)}>
              Delete
            </Text>
          </Flex>
        );
      },
    },
  ];
  return (
    <Box className="list-reward__container">
      <Flex>
        <Text className="list-reward__title">Loyalty Reward Management</Text>
        <Spacer />
        <Button
          className="list-reward__button"
          onClick={() => handleClickModal({ type: "add" })}
        >
          Add Reward
        </Button>
      </Flex>

      <Box className="list-reward__table-container">
        <Input
          placeholder="Search reward name"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
          onKeyPress={(event) => handleKeyPressSearch(event)}
        />

        <ToolkitProvider
          data={data}
          columns={columns}
          search
          keyField="list-reward"
        >
          {(props) => (
            <div className="py-4">
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                bordered={false}
                trStyle={{ backgroundColor: "white" }}
              />
            </div>
          )}
        </ToolkitProvider>
        <Box mt="1em">
          <Pagination
            page={page}
            totalPage={totalPage}
            changePageHandler={handlePage}
          />
        </Box>
      </Box>
      <AddModalReward
        isOpen={isOpen}
        onClose={onClose}
        inputReward={inputReward}
        setInputReward={setInputReward}
        submitType={submitType}
        fetchData={fetchData}
      />
    </Box>
  );
}

export default navguard(ListReward);
