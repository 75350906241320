import {
  Container,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
} from "@chakra-ui/react";
import React from "react";
import "./invoiceCommissionPayout.css";
import PbpLogo from "../../../assets/images/logo-pbp.png";
import dateFormat from "dateformat";

const CommissionPayoutPdf = ({ data }) => {
  const formatter = new Intl.NumberFormat("id-ID");

  return (
    <Container className={"payout-container"}>
      <Flex justify={"space-between"} align="center">
        <Box width={"33"}>
          <Text className={"payout-head-title"}>COMMISSION PAYOUT INVOICE</Text>
        </Box>
        <Box width={"33"} />
        <Box width={"33"} className="logo">
          <img alt="icon-logo" src={PbpLogo} />
        </Box>
      </Flex>
      <Flex>
        <Text className={"payout-text"}>
          <span style={{ fontWeight: "bold" }}>To :</span> PT Paranova Global
          Optima
        </Text>
      </Flex>
      <Flex>
        <Text className={"payout-text"}>
          <span style={{ fontWeight: "bold" }}>At :</span> Jl. Ciledug Raya
          No.10, Ulujami, Pesanggrahan, Jakarta Selatan - Indonesia.{" "}
        </Text>
      </Flex>

      <Flex mt={"14px"}>
        <Text className={"payout-text"}>
          <span style={{ fontWeight: "bold" }}>Invoice Number :</span>{" "}
          {data?.commissionPayout.invoiceNumber}
        </Text>
      </Flex>
      <Flex>
        <Text className={"payout-text"}>
          <span style={{ fontWeight: "bold" }}>Project :</span> Pembayaran
          Komisi {dateFormat(data?.commissionPayout.paidDate, "mm yyyy")}
        </Text>
      </Flex>

      <Text className={"payout-text"} textAlign={"start"}>
        Commission Payment Detail
      </Text>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th className={"payout-th payout-item"}>Item</Th>
            <Th className={"payout-th"}>Qty</Th>
            <Th className={"payout-th"}>Currency</Th>
            <Th className={"payout-th"} isNumeric>
              Price
            </Th>
            <Th className={"payout-th"} isNumeric>
              Total
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.commissionDetail?.length > 0 &&
            data.commissionDetail.map((each) => (
              <Tr>
                <Td className={"payout-td payout-item"}>
                  Komisi reseller {dateFormat(each.date, "mmmm yyyy")} an.
                  {` ${data?.commissionPayout?.user.firstName} ${data?.commissionPayout?.user.lastName}`}
                </Td>
                <Td className={"payout-td"}>1</Td>
                <Td className={"payout-td"}>IDR</Td>{" "}
                <Td className={"payout-td numeric"} isNumeric>
                  {formatter.format(each.totalCommission.toFixed(1))}
                </Td>{" "}
                <Td className={"payout-td numeric"} isNumeric>
                  {formatter.format(each.totalCommission.toFixed(1))}
                </Td>
              </Tr>
            ))}
        </Tbody>
        <Tbody>
          <Tr>
            <Td padding="6px" />
            <Td padding="6px" />
            <Td padding="6px" />
            <Td className={"payout-th"} isNumeric>
              Total Payment
            </Td>
            <Td className={"payout-td"} isNumeric>
              {formatter.format(data?.commissionPayout?.totalCommission)}
            </Td>
          </Tr>
        </Tbody>
      </Table>

      <Flex className={"payout-bank-wrapper"}>
        <Text className={"payout-text"}>Please Transfer to</Text>
        <Text className={"payout-text-bank"}>
          {data?.commissionPayout.bankName}
        </Text>
        <Text className={"payout-text-bank"}>
          {data?.commissionPayout.accountNumber}
        </Text>
        <Text className={"payout-text-bank"}>
          {data?.commissionPayout.accountName}
        </Text>
      </Flex>

      <Flex className={"payout-restrict-id-wrapper"}>
        <Text className={"payout-text"}>
          <span style={{ fontWeight: "bold" }}>NIK :</span>{" "}
          {data?.commissionPayout?.user?.nik}
        </Text>
        <Text className={"payout-text"}>
          <span style={{ fontWeight: "bold" }}>NPWP :</span>{" "}
          {data?.commissionPayout?.user?.npwp}
        </Text>
        <Text className={"payout-text"}>
          Jakarta, {dateFormat(new Date(), "dd-mm-yyyy")}
        </Text>
        <Text className={"payout-text"}>
          *This Document is computer generated, no signature required
        </Text>
      </Flex>
    </Container>
  );
};

export default CommissionPayoutPdf;
