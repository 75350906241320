import { Box, Input } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import one from "../../../../assets/images/pro3/1.jpg";

function BannerImageUpload({ data, setData }) {
  const imgRef = useRef();
  const imageMaxSize = 2000000;

  const handleImageChange = (value) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (value.currentTarget.files.item(0).size > imageMaxSize) {
        toast({
          title: "Error.",
          description: "Mohon upload gambar berukuran kurang dari 2MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        let tempImage = { ...data };
        let reader = new FileReader();
        const image = value.target.files[0];
        tempImage.img = image;
        reader.onload = () => {
          tempImage.imgSrc = reader.result;
          setData(tempImage);
        };
        reader.readAsDataURL(image);
      }
    }
  };

  return (
    <Box>
      <Input
        type="file"
        accept="image/*"
        className="upload"
        ref={imgRef}
        display="none"
        onChange={(e) => handleImageChange(e)}
      />
      <img
        style={{ cursor: "pointer" }}
        alt="banner-desktop"
        src={data.imgSrc || data.img || one}
        onClick={() => imgRef.current.click()}
      />
    </Box>
  );
}

export default BannerImageUpload;
