import React, { useState, useEffect } from "react";
import "../styles/stocklist.scss";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  useToast,
  Divider,
  Tag,
  Flex,
  Icon,
  Text,
  Button,
} from "@chakra-ui/react";
import StockSingleCard from "./stockSingleCard";
import StockVariantCard from "./stockVariantCard";
import { MdErrorOutline } from "react-icons/md";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import {
  warehouseProductAction,
  warehouseProductUpdate,
} from "../../../store/actions/warehouseAction";

const StockListCard = ({ product, id, page, setPage }) => {
  const [productState, setProductState] = useState({});
  const [filteredVariant, setFilteredVariant] = useState([]);
  const [bulkUpdate, setBulkUpdate] = useState([]);
  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    if (product.productId) {
      setProductState({ ...product });
    }

    if (product.productType !== "Single") {
      const filterWarehouse = product.warehouseStocks.filter(
        (item) => item.productVariant
      );
      const sortProduct = filterWarehouse.sort((a, b) =>
        a.productVariant.name > b.productVariant.name ? 1 : -1
      );
      setFilteredVariant(sortProduct);
    }
  }, [product]);

  const handleSaveBulkProduct = async () => {
    if (!isEmpty(bulkUpdate)) {
      try {
        for (let i = 0; i < bulkUpdate.length; i++) {
          const data = bulkUpdate[i];
          if (data) {
            await dispatch(
              warehouseProductUpdate({
                warehouseId: id,
                stock: [
                  {
                    productId: data.productId,
                    productVariantId: data.variantId,
                    availableStock: Number(data.availableStock),
                    minSafetyStock: Number(data.minSafetyStock),
                    qcStock: Number(data.qcStock),
                  },
                ],
              })
            );
          }
        }

        toast({
          title: "Success.",
          description: "Berhasil mengedit product.",
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        await dispatch(
          warehouseProductAction({ id, isInitiated: true, page: page + 1 })
        );
      } catch (err) {
        console.error(err);
      }
      return;
    }

    toast({
      title: "Warning.",
      description: "Mohon edit stock product terlebih dahulu.",
      status: "warning",
      position: "top",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Accordion
      allowToggle
      className="accordian-container"
      key={product?.productId || 1}>
      <AccordionItem mb={2}>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" display="flex" alignItems="center">
              {productState.productName}
              <Tag ml="4" borderRadius="full" className="variant-capsule">
                {product.productType}
              </Tag>
            </Box>
            <Flex justifyContent="flex-end">
              {product.productType === "Single" ? (
                <>
                  {product?.warehouseStocks[0]?.availableStock > 0
                    ? "Ready Stock"
                    : "Out of Stock"}{" "}
                  ({product.warehouseStocks[0].availableStock})
                </>
              ) : (
                <Flex alignItems="center">
                  {product.variantCount.outOfStock > 0 && (
                    <>
                      <Icon as={MdErrorOutline} color="#FF5959" mr="2" />
                      <Text mr="6">
                        {product.variantCount.outOfStock} Variant Out of Stock
                      </Text>
                    </>
                  )}
                  {product.variantCount.lowStock > 0 && (
                    <>
                      <Icon as={MdErrorOutline} color="#FFA959" mr="2" />
                      <Text>{product.variantCount.lowStock} Variant Low</Text>
                    </>
                  )}
                </Flex>
              )}
            </Flex>
            <AccordionIcon boxSize={10} />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} className="expand-accordian-container">
          {product.productType === "Single" ? (
            <StockSingleCard
              product={product}
              id={id}
              setPage={setPage}
              page={page}
            />
          ) : (
            <>
              {filteredVariant.map((variant, index) => (
                <>
                  <StockVariantCard
                    variant={variant}
                    product={product}
                    id={id}
                    bulkUpdate={bulkUpdate}
                    setBulkUpdate={setBulkUpdate}
                    index={index}
                  />

                  {index !== filteredVariant.length - 1 && (
                    <Divider borderColor="#C4C4C4" />
                  )}
                </>
              ))}
              <Flex justifyContent="end">
                <Button
                  onClick={handleSaveBulkProduct}
                  background="#ff8084"
                  color="white">
                  Update Stock
                </Button>
              </Flex>
            </>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default StockListCard;
