import { Flex, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

function FilterSort(props) {
  const history = useHistory();
  const { search } = useLocation();
  const [sort, setSort] = useState("");

  const changeSortHandler = (event) => {
    setSort(event.target.value);
    const currentQueries = queryString.parse(search);
    const queries = queryString.stringify(
      {
        ...currentQueries,
        sort: event.target.value,
      },
      { skipEmptyString: true }
    );

    history.push(`/loyalty/list-points?${queries}`);
  };

  useEffect(() => {
    const currentQueries = queryString.parse(search);
    if (currentQueries.sort) {
      setSort(currentQueries.sort);
    }
  }, [search]);
  return (
    <Flex align={"center"}>
      <Text mr={"10px"} fontSize="16px" color={"black"}>
        Sort By
      </Text>
      <Select width="120px" onChange={changeSortHandler} value={sort}>
        <option value={""}>Sorted by</option>
        <option value={"createdAt,desc"}>Terbaru</option>
        <option value={"createdAt,asc"}>Terlama</option>
      </Select>
    </Flex>
  );
}

export default FilterSort;
