import { SET_CITY } from "../actionTypes";
import Axios from "../../services/axios";

export function cityAction(province) {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/user-address/cities?provinceName=${province}`,
        method: "GET",
      });
      dispatch({
        type: SET_CITY,
        payload: data.data.cities,
      });
    } catch (error) {
      console.log(error);
    }
  };
}
