import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  Text,
  Checkbox,
  CheckboxGroup,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FilterListContent from "./components/filterListContent";
import "./styles/list-commission.css";
import MiniTab from "../order/components/miniTab";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { Search2Icon } from "@chakra-ui/icons";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Pagination from "../order/components/pagination";
import HeaderList from "./components/headerList";
import { useDispatch, useSelector } from "react-redux";
import { fetchCommissionManagement } from "../../store/actions/commissionAction";
import { isEmpty, find } from "lodash";
import { CSVLink } from "react-csv";
import { months } from "./components/const";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import navguard from "../auth/navguard";

function ListCommission(props) {
  const history = useHistory();
  const { search } = useLocation();
  const [subTabActive, setSubTabActive] = useState("All");
  const [selectedRow, setSelectedRow] = useState([]);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const { commissionManagement } = useSelector((state) => state.commission);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [upperLimit, setUpperLimit] = useState(0);
  const [dataCommission, setDataCommission] = useState([]);
  const [bulkAction, setBulkAction] = useState("");
  const [bulkData, setBulkData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchData = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const currentQuery = queryString.parse(search);
      const queries = queryString.stringify(
        {
          ...currentQuery,
          q: searchQuery,
        },
        {
          skipEmptyString: true,
        }
      );

      history.push(`/commission/list-commission?${queries}`);
    }
  };

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (currentQuery.q) {
      setSearchQuery(currentQuery.q);
    }
  }, [search]);

  const toDetailCommission = (userId) => {
    const today = new Date();
    const currentQuery = queryString.parse(search);
    if (!currentQuery.month) {
      currentQuery.month = today.getMonth() + 1;
    }
    if (!currentQuery.year) {
      currentQuery.year = today.getFullYear();
    }
    history.push(
      `/commission/detail-commission/${userId}?month=${currentQuery.month}&year=${currentQuery.year}`
    );
  };

  const fetchData = () => {
    const today = new Date();
    const currentQuery = queryString.parse(search);
    let queries;
    if (!currentQuery.month && !currentQuery.year) {
      currentQuery.month = today.getMonth() + 1;
      currentQuery.year = today.getFullYear();
    } else if (!currentQuery.year) {
      currentQuery.year = today.getFullYear();
    } else if (!currentQuery.month) {
      currentQuery.month = today.getMonth() + 1;
    }

    queries = queryString.stringify({
      ...currentQuery,
    });
    dispatch(fetchCommissionManagement(queries));
  };

  useEffect(() => {
    if (page !== 0) setPage(0);
    fetchData();
  }, [search]);

  const formatter = (value) =>
    new Intl.NumberFormat("id-ID", {
      minimumFractionDigits: 0,
      style: "currency",
      currency: "IDR",
    }).format(value);

  const columns = [
    {
      dataField: "follow",
      formatter: (cell, row) => <Checkbox value={row.id.toString()} />,
      headerFormatter: () => <Checkbox />,
    },
    {
      dataField: "referralCode",
      text: "Reseller ID",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell) => <p style={{ color: "black" }}>{cell}</p>,
    },
    {
      text: "Name",
      style: { textAlign: "center" },
      sort: true,
      headerStyle: { textAlign: "center" },
      formatter: (_, row) => (
        <p style={{ color: "black" }}>{`${row?.firstName} ${row?.lastName}`}</p>
      ),
    },
    {
      dataField: "resellerStatus",
      text: "Level",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>
          {cell ? cell : row.onBoardingData.selectedResellerStatus}
        </p>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell) => <p style={{ color: "black" }}>{cell}</p>,
    },
    {
      dataField: "phone",
      text: "Phone",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell) => <p style={{ color: "black" }}>{cell}</p>,
    },
    {
      dataField: "commission",
      text: "Commission",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell) => formatter(cell),
    },
    {
      dataField: "commissionAccountStatus",
      text: "Account Status",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell) => <p style={{ color: "black" }}>{cell}</p>,
    },
    {
      dataField: "isPaid",
      text: "Payment Status",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell) => (
        <p style={{ color: "black" }}>{cell ? "Piad" : "Unpaid"}</p>
      ),
    },

    {
      dataField: "actions",
      style: { textAlign: "center" },
      formatter: (_, row) => (
        <Flex>
          <Text
            style={{ cursor: "pointer" }}
            onClick={() => toDetailCommission(row.id)}>
            View
          </Text>
        </Flex>
      ),
    },
  ];

  const changePageHandler = (value) => {
    setPage(value.selected);
  };

  const changeEventHandler = (event) => {
    setSelectedRow(event);
  };

  const populateData = () => {
    let selectedData = [
      [
        "Reseller ID",
        "Name",
        "Level",
        "Email",
        "Phone",
        "Commission",
        "Account Status",
        "Payment",
      ],
    ];
    const temp = selectedRow.map((item) => {
      const findData = find(commissionManagement, { id: item });
      if (findData) {
        return [
          findData.user.referralCode,
          `${findData.user.firstName} ${findData.user.lastName}`,
          findData.user.resellerStatus,
          findData.user.email,
          findData.user.phone,
          findData.totalCommission,
          findData.accountStatus,
          findData.isPaid ? "Paid" : "Not Paid",
        ];
      }
    });

    selectedData = [...selectedData, ...temp];

    return selectedData;
  };

  const exportToXLS = () => {
    const populated = populateData();

    const ws = XLSX.utils.json_to_sheet(populated, { skipHeader: 1 });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: ".xlsx" });

    FileSaver.saveAs(data, `${commissionPayoutNameHandler()}.xlsx`);
  };

  const populateDataCSV = () => {
    const populated = populateData();
    setBulkData(populated);
  };

  const generatedCSV = () => {
    return bulkData;
  };

  const changeBulkHandler = (event) => {
    setBulkAction(event.target.value);
  };

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    let queries;
    if (subTabActive === "All") {
      queries = queryString.stringify(
        {
          ...currentQuery,
          resellerStatus: "",
        },
        { skipEmptyString: true }
      );
    } else {
      queries = queryString.stringify({
        ...currentQuery,
        resellerStatus: subTabActive.toLowerCase(),
      });
    }
    history.push(`/commission/list-commission?${queries}`);
  }, [subTabActive, search]);

  useEffect(() => {
    setLowerLimit(page * 10);
    setUpperLimit((page + 1) * 10);
  }, [page]);

  useEffect(() => {
    if (commissionManagement) {
      setTotalPage(Math.ceil(commissionManagement.length / 10));
      setDataCommission(commissionManagement.slice(lowerLimit, upperLimit));
    }
  }, [upperLimit, lowerLimit, commissionManagement]);

  const commissionPayoutNameHandler = () => {
    const date = new Date();
    const currentQuery = queryString.parse(search);
    const selectedMonth = currentQuery.month - 1 || date.getMonth();
    const selectedYear = currentQuery.year || date.getFullYear();
    const today = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    return `Commission Payout ${months[selectedMonth]} ${selectedYear} - ${today}`;
  };

  return (
    <Box className="list-commission__container">
      <HeaderList isPayoutPage={false} />

      <Box className="list-commission__content-container">
        <Box className="list-commission__container-filter">
          <FilterListContent />
        </Box>

        <Flex>
          <MiniTab
            list={["All", "Champion Reseller"]}
            subTabActive={subTabActive}
            setSubTabActive={setSubTabActive}
          />
          <Spacer />

          <Flex align={"center"}>
            <Select
              width={"200px"}
              mr={"1rem"}
              onChange={(event) => changeBulkHandler(event)}
              value={bulkAction}>
              <option value="">Bulk action</option>
              <option value="csv">Export CSV</option>
              <option value="xls">Export to XLS</option>
              <option value="payout" disabled>
                Confirm Payout
              </option>
            </Select>

            {bulkAction === "csv" ? (
              <CSVLink
                data={generatedCSV()}
                asyncOnClick={true}
                onClick={populateDataCSV}
                target="_blank"
                filename={commissionPayoutNameHandler()}>
                <Button colorScheme="teal" size="md" ml="4px">
                  Download CSV
                </Button>
              </CSVLink>
            ) : bulkAction === "xls" ? (
              <Button
                colorScheme="teal"
                size="md"
                ml="4px"
                onClick={exportToXLS}>
                Download Excel
              </Button>
            ) : (
              <Button colorScheme="teal" size="md" ml="4px">
                Apply
              </Button>
            )}
          </Flex>
        </Flex>

        <InputGroup mt={"1rem"}>
          <InputLeftElement
            pointerEvents="none"
            children={<Search2Icon color={"gray.300"} />}
          />

          <Input
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={(e) => handleSearchData(e)}
          />
        </InputGroup>

        {!isEmpty(dataCommission) ? (
          <ToolkitProvider
            data={dataCommission}
            keyField="partner_name"
            columns={columns}>
            {(props) => (
              <div className="py-4 table-responsive list-commission__table-container">
                <CheckboxGroup
                  colorScheme="green"
                  defaultValue={selectedRow}
                  onChange={changeEventHandler}>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                    trStyle={{ backgroundColor: "white" }}
                  />
                </CheckboxGroup>
              </div>
            )}
          </ToolkitProvider>
        ) : (
          <Text className="empty-state">There is no data entry</Text>
        )}
        {!isEmpty(dataCommission) && (
          <Pagination
            page={page}
            totalPage={totalPage}
            changePageHandler={changePageHandler}
          />
        )}
      </Box>
    </Box>
  );
}

export default navguard(ListCommission);
