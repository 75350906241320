import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Center,
  Divider,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import dateFormat from "dateformat";
import MiniTab from "../../order/components/miniTab";
import PromoTab from "./promoTab";
import DateFilter from "./dateFilter";
import SortFilter from "./sortFilter";
import Pagination from "../../order/components/pagination";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { useHistory, useLocation } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import {
  setPromo,
  deletePromo,
  updatePromo,
  searchPromoAction,
} from "../../../store/actions/promoAction";
import "./list-promo.css";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import queryString from "query-string";
import AlertModal from "../../alertModal";
import navguard from "../../auth/navguard";
import moment from "moment";

const ListPromo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();
  const { search } = useLocation();
  const [tabActive, setTabActive] = useState("promo");
  const [subTabActive, setSubTabActive] = useState("All");
  const [page, setPage] = useState(0);
  const [success, setSuccess] = useState(false);
  const [searchPromo, setSearchPromo] = useState("");
  const [id, setId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();
  const promoList = useSelector(({ promos }) => promos.promos);

  const onClose = () => setIsOpen(false);

  const openAlertModal = (id) => {
    setId(id);
    setIsOpen(true);
  };

  const fetchPromo = () => {
    const currentQuery = queryString.parse(search);
    if (!currentQuery.sort) {
      const queries = queryString.stringify({
        ...currentQuery,
        sort: "createdAt,desc",
        filterIsAutoApplied: tabActive === "promo",
        page: page + 1,
        limit: 10,
      });
      dispatch(setPromo(queries));
    } else {
      const queries = queryString.stringify({
        ...currentQuery,
        filterIsAutoApplied: tabActive === "promo",
        page: page + 1,
        limit: 10,
      });
      dispatch(setPromo(queries));
    }
  };

  useEffect(() => {
    fetchPromo();
  }, [page, success]);

  useEffect(() => {
    if (page === 0) {
      if (searchPromo) {
        dispatch(searchPromoAction(search));
      } else {
        fetchPromo();
      }
    } else {
      setPage(0);
    }
  }, [search]);

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify({
      ...currentQuery,
      filterIsAutoApplied: tabActive === "promo",
    });

    history.push(`/promo/list-promo?${queries}`);
  }, [tabActive]);

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify({
      ...currentQuery,
      filterCategory: subTabActive.toLowerCase(),
    });

    if (subTabActive !== "All") {
      history.push(`/promo/list-promo?${queries}`);
    } else {
      const noTab = queryString.stringify({
        sort: currentQuery.sort,
      });
      history.push(`/promo/list-promo?${noTab}`);
    }
  }, [subTabActive]);

  const changePageHandler = (value) => {
    setPage(value.selected);
  };

  const deletePromoHandler = () => {
    dispatch(deletePromo(id, setSuccess, toast));
    setIsOpen(false);
  };

  const updatePromoHandler = (id, isActive) => {
    const data = {
      isActive: !isActive,
    };

    try {
      dispatch(updatePromo(id, setSuccess, data));
      toast({
        title: "Success.",
        description: "Promo berhasil diupdate",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Failed.",
        description: "Promo gagal diupdate",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const searchPromoHandler = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const currentQuery = queryString.parse(search);

      const queries = queryString.stringify({
        ...currentQuery,
        q: searchPromo,
      });

      history.push(`/promo/list-promo?${queries}`);
    }
  };

  const formatter = (value) =>
    new Intl.NumberFormat("id-ID", {
      minimumFractionDigits: 0,
      style: "currency",
      currency: "IDR",
    }).format(value);

  const promoValueFormatter = (row) => {
    if (row.category === "discount") {
      return `${
        row.discountAmount
          ? formatter(row.discountAmount)
          : `${row.discountPercentage}%`
      }`;
    } else if (row.category === "shipping") {
      return `${formatter(row.discountAmount)}`;
    } else if (row.category === "gwp") {
      let str = "";
      row.gwpProducts?.map((each) => {
        return (str += `(${each?.qty}) ${each?.productName}${
          row.gwpProducts.length > 1 ? "," : ""
        } `);
      });

      return (
        <div
          style={{
            width: "320px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}>
          {str}
        </div>
      );
    }
  };

  const columns = [
    {
      dataField: "code",
      text: "Promo Code",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => <p style={{ color: "black" }}>{cell}</p>,
    },
    {
      dataField: "isActive",
      text: "Status",
      style: { textAlign: "center" },
      sort: true,
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{cell ? "Active" : "Not Active"}</p>
      ),
    },
    {
      dataField: "category",
      text: "Category",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      text: "Value",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => {
        return promoValueFormatter(row);
      },
    },
    {
      dataField: "startDate",
      text: "Start Date",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>
          {dateFormat(moment(cell).format(), "dd/mm/yyyy HH:MM:ss")}
        </p>
      ),
    },
    {
      dataField: "endDate",
      text: "Exp Date",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>
          {dateFormat(moment(cell).format(), "dd/mm/yyyy HH:MM:ss")}
        </p>
      ),
    },
    {
      dataField: "totalUsage",
      text: "Usage",
      style: { textAlign: "center", cursor: "pointer" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <Link to={`/promo/promo-usage/${row.id}`}>
          <p style={{ color: "black" }}>
            {row.totalUsage}/{row.quotaPerPromo !== 0 ? row.quotaPerPromo : "∞"}
          </p>
        </Link>
      ),
    },
    {
      dataField: "id",
      style: { textAlign: "center" },
      formatter: (cell, row) => (
        <Flex justifyContent={"center"}>
          <Link to={`/promo/edit-promo/${cell}`}>
            <Text cursor={"pointer"}>Edit</Text>
          </Link>
          <Center height="20px" width="29px">
            <Divider
              orientation="vertical"
              color={"#C4C4C4"}
              opacity={"1"}
              borderLeftWidth={"1.5px"}
            />
          </Center>
          <Text
            onClick={() => updatePromoHandler(cell, row.isActive)}
            cursor={"pointer"}>
            {row.isActive ? "Deactivate" : "Activate"}
          </Text>
          <Center height="20px" width="29px">
            <Divider
              orientation="vertical"
              color={"#C4C4C4"}
              opacity={"1"}
              borderLeftWidth={"1.5px"}
            />
          </Center>
          <Text onClick={() => openAlertModal(cell)} cursor={"pointer"}>
            Delete
          </Text>
        </Flex>
      ),
    },
  ];

  return (
    <Box className="list-promo__container">
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Text className="list-promo__title">Promo Management</Text>
        <Link to="/promo/create-promo">
          <Button className="list-promo__btn-create">Create New Promo</Button>
        </Link>
      </Flex>
      <Box className="list-promo__content-container">
        <PromoTab
          tabActive={tabActive}
          setTabActive={setTabActive}
          search={search}
          subTabActive={subTabActive}
          setSubTabActive={setSubTabActive}
        />
        <Flex justify="space-between">
          <InputGroup maxWidth="300px">
            <InputLeftElement
              pointerEvents="none"
              children={<Search2Icon color="gray.300" />}
            />
            <Input
              onKeyPress={searchPromoHandler}
              value={searchPromo}
              onChange={(e) => setSearchPromo(e.target.value)}
              placeholder="Search"
              className="list-promo__search"
            />
          </InputGroup>
          <DateFilter />
        </Flex>
        <Flex mt="48px" justify="space-between" align="center" width="100%">
          <SortFilter />
        </Flex>
        {tabActive === "promo" && (
          <MiniTab
            list={["All", "Discount", "GWP", "Shipping"]}
            setSubTabActive={setSubTabActive}
            subTabActive={subTabActive}
            setPage={setPage}
          />
        )}

        {tabActive === "voucher" && (
          <MiniTab
            list={["All", "Discount", "GWP", "Shipping"]}
            setSubTabActive={setSubTabActive}
            subTabActive={subTabActive}
            setPage={setPage}
          />
        )}

        {!isEmpty(promoList) && (
          <ToolkitProvider
            data={promoList.promos}
            keyField="partner_name"
            columns={columns}>
            {(props) => (
              <div className="py-4 table-responsive list-promo__table-container">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                  trStyle={{ backgroundColor: "white" }}
                />
              </div>
            )}
          </ToolkitProvider>
        )}

        {!isEmpty(promoList) && (
          <Pagination
            page={page}
            changePageHandler={changePageHandler}
            totalPage={promoList.totalPage}
          />
        )}
      </Box>
      <AlertModal
        isOpen={isOpen}
        cancelRef={cancelRef}
        onClose={onClose}
        title={"Delete Promo"}
        subtitle={"Are you sure? You can't undo this action afterwards."}
        alertHandler={() => deletePromoHandler()}
      />
    </Box>
  );
};

export default navguard(ListPromo);
