import "../../styles/Brands.scss";

import {
  Card,
  CardBody,
  Col,
  Input,
  Row
} from "reactstrap";

import {
  Spacer,
  Flex,
  Select,
  Button,
  IconButton,
  Textarea,
} from "@chakra-ui/react";

import {
  MdClose,
} from "react-icons/md";

import iconDefault from "../../../../assets/images/icon_default.png";
import imageDefault from "../../../../assets/images/image_default.png";

const AddBrand = ({
  title,
  isEdit,
  brandObj,
  listBrand,
  changeHandler,
  submitBrand,
  _handleImgChange,
  deleteImage
}) => {
	return (
    <>
      <Card>
        <CardBody>
          <p className="card-title">
            {title ? title : `Add New ${brandObj.type}`}
          </p>
          <Row>
            <Col xl={12} className="mb-8">
              <p className="card-label">Type</p>
              {isEdit ? (
                <p className="card-value">{brandObj.type}</p>
              ) : (
                <Select
                  name="type"
                  flex="3"
                  value={brandObj?.type || ""}
                  onChange={(e) => changeHandler(e)}
                >
                  <option value="Brand">Brand</option>
                  <option value="Series">Series</option>
                </Select>
              )}
            </Col>
            {brandObj.type === "Series" && (
              <>
                <Col xl={12} className="mb-8">
                  <p className="card-label">Parent Brand</p>
                  <Select
                    name="parentBrand"
                    flex="3"
                    value={
                      brandObj?.parentBrand || ""
                    }
                    onChange={(e) => changeHandler(e)}
                  >
                    <option value=""></option>
                    {listBrand?.map((brand) => (
                      <option key={brand.name} value={brand.id}>
                        {brand.name}
                      </option>
                    ))}
                  </Select>
                </Col>
                <Col xl={12} className="mb-8">
                  <p className="card-label">Series Name</p>
                  <Input
                    placeholder="Series Name"
                    name="seriesName"
                    value={
                      brandObj?.seriesName || ""
                    }
                    onChange={(e) => changeHandler(e)}
                  />
                </Col>
                <Col xl={12} className="mb-8">
                  <p className="card-label">Description</p>
                  <Textarea
                    className="mb-8"
                    value={
                      brandObj?.description || ""
                    } 
                    onChange={(e) => changeHandler(e)} 
                    name="description"
                  />
                </Col>
              </>
            )}
            {brandObj.type === "Brand" && (
              <Col xl={12} className="mb-8">
                <p className="card-label">Brand Name</p>
                <Input
                  className="mb-8"
                  placeholder="Brand Name"
                  name="brandName"
                  value={
                    brandObj?.brandName || ""
                  }
                  onChange={(e) => changeHandler(e)}
                />
                <p className="card-label">Description</p>
                <Textarea
                  className="mb-8"
                  value={
                    brandObj?.brandDescription || ""
                  }
                  onChange={(e) => changeHandler(e)} 
                  name="brandDescription"
                />
              </Col>
            )}
            <Col xl={3} className="mb-8">
              <p className="card-label">Icon</p>
              <div className="add-category">
                <Row className="file-upload-product row">
                  <Col xl="12" className="col">
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      className="box-input-file-full"
                    >
                      {brandObj?.image !== iconDefault && (
                        <IconButton
                          variant="outline"
                          aria-label="Call Sage"
                          onClick={() => deleteImage("saveImage", "image")}
                          icon={<MdClose />}
                          style={{
                            position: "absolute",
                            right: "4px",
                            top: "4px",
                            borderRadius: "50%",
                            fontSize: "24px",
                            width: "24px",
                            height: "24px",
                            color: "red",
                            border: "none",
                            minWidth: "unset",
                            zIndex: "100",
                          }}
                        />
                      )}
                      <input
                        className="upload"
                        type="file"
                        id="icon-img"
                        name="img"
                        accept="image/*"
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "100%",
                        }}
                        onChange={(e) =>
                          _handleImgChange(e, "savedImage", "image")
                        }
                      />
                      <img
                        alt=""
                        src={
                          brandObj?.image || iconDefault
                        }
                        style={{
                          width: "100%",
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xl={3} className="mb-8">
              <p className="card-label">Icon Active</p>
              <div className="add-category">
                <Row className="file-upload-product row">
                  <Col xl="12" className="col">
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      className="box-input-file-full"
                    >
                      {brandObj?.iconActive !== iconDefault && (
                        <IconButton
                          variant="outline"
                          aria-label="Call Sage"
                          onClick={() => deleteImage("savedActiveImage", "iconActive")}
                          icon={<MdClose />}
                          style={{
                            position: "absolute",
                            right: "4px",
                            top: "4px",
                            borderRadius: "50%",
                            fontSize: "24px",
                            width: "24px",
                            height: "24px",
                            color: "red",
                            border: "none",
                            minWidth: "unset",
                            zIndex: "100",
                          }}
                        />
                      )}
                      <input
                        className="upload"
                        type="file"
                        id="icon-img"
                        name="img"
                        accept="image/*"
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "100%",
                        }}
                        onChange={(e) =>
                          _handleImgChange(e, "savedActiveImage", "iconActive")
                        }
                      />
                      <img
                        alt=""
                        src={
                          brandObj?.iconActive || iconDefault
                        }
                        style={{
                          width: "100%",
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xl={6}>
              <p className="card-label">Banner</p>
              <div className="add-category">
                <Row className="file-upload-product row">
                  <Col xl="12" className="col">
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      className="box-input-file-full"
                    >
                      {brandObj?.banner !== imageDefault && (
                        <IconButton
                          variant="outline"
                          aria-label="Call Sage"
                          onClick={() =>
                            deleteImage("savedBanner", "banner")
                          }
                          icon={<MdClose />}
                          style={{
                            position: "absolute",
                            right: "4px",
                            top: "4px",
                            borderRadius: "50%",
                            fontSize: "24px",
                            width: "24px",
                            height: "24px",
                            color: "red",
                            border: "none",
                            minWidth: "unset",
                            zIndex: "100",
                          }}
                        />
                      )}
                      <input
                        className="upload"
                        type="file"
                        id="banner-img"
                        name="img"
                        accept="image/*"
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "100%",
                        }}
                        onChange={(e) =>
                          _handleImgChange(e, "savedBanner", "banner")
                        }
                      />
                      <img
                        alt=""
                        src={
                          brandObj?.banner || imageDefault
                        }
                        style={{
                          width: "100%",
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xl={12}>
              <Flex>
                <Spacer />
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  backgroundColor={"#FF8084"}
                  color={"white"}
                  mt={4}
                  onClick={() => submitBrand(isEdit)}
                >
                  Submit
                </Button>
              </Flex>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default AddBrand;
