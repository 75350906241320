import { useHistory } from "react-router-dom";

const navguard = (WrappedComponents) => {
  return (props) => {
    if (typeof window !== "undefined") {
      const router = useHistory();

      const userToken = localStorage.getItem("userToken");

      if (!userToken) {
        router.replace("/auth/login");
      } else {
        return <WrappedComponents {...props} />;
      }
    }

    return null;
  };
};

export default navguard;
