import React, { useState } from "react";

import '../styles/_homeBanner.scss';
import {
    Row,
    Col,
} from "reactstrap";
import {
    Flex,
    Spacer,
    IconButton,
    Button,
    Modal,
    ModalFooter,
    ModalBody,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalContent,
    InputGroup,
    Input,
    InputRightElement
} from "@chakra-ui/react";
import {
    FaMobileAlt,
    FaDesktop,
} from "react-icons/fa";

import "../styles/resellerFullPreview.scss";

const ResellerFullPreview = ({ isPreview, banner, previewClose }) => {
    const [fullPreviewMode, setFullPreviewMode] = useState("Desktop");

    const changeFullPreview = (mode) => {
        setFullPreviewMode(mode);
    }
    
	return (
        <Modal onClose={previewClose} size="full" isOpen={isPreview}>
            <ModalOverlay />
            <ModalContent>
            <Flex>
                <ModalHeader flex="2">Preview Banner</ModalHeader>
                <Spacer />
                <IconButton
                    mr={4}
                    mt={2}
                    variant="outline"
                    aria-label="Call Sage"
                    fontSize="20px"
                    onClick={() => changeFullPreview("Desktop")}
                    icon={<FaDesktop />}
                />
                <IconButton
                    mr={16}
                    mt={2}
                    variant="outline"
                    aria-label="Call Sage"
                    fontSize="20px"
                    onClick={() => changeFullPreview("Mobile")}
                    icon={<FaMobileAlt />}
                />
                <ModalCloseButton />
            </Flex>
            {fullPreviewMode === "Desktop" ?                 
                <Row className={"section-banner__container"}>
                    <Col xl="4" className={"left-section"}>
                        <div className={"left-section__title"}>Reseller</div>
                        <div className={"left-section__main-title"}>
                            Jual Mudah Untung Berlimpah
                        </div>
                        <div className={"left-section__content"}>
                            Dapatkan keuntungan dan berbagai manfaat dari berbisnis dengan cara
                            reseller dan dropship produk kami
                        </div>
                        <div className={"left-section__button-copy"}>
                            Daftar dan produktif dengan 10ribu reseller dan komunitas
                        </div>
                        <InputGroup size="md" className={"input-group"}>
                            <Input
                                placeholder="Tuliskan nama lengkapmu"
                                className={"input"}
                            />
                            <InputRightElement className={"button-container"}>
                            <Button
                                className={"button"}
                                id="page-reseller__button-join"
                            >
                                Daftar
                            </Button>
                            </InputRightElement>
                        </InputGroup>
                        <div className={"left-section__contact-us"}>
                            Ada yang ingin kamu tanyakan?
                            <p className={"contact-us"}> Hubungi kami</p>
                        </div>
                    </Col>
                    <Col xl="8" className={"right-section"}>
                        <img
                            alt="asd"
                            className={"image-container"}
                            src={banner.imagePath}
                        />
                    </Col>
                </Row>
            :                
                <ModalBody
                    style={{
                        width: '30vw',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    <Row className={"mobile-section-banner__container"}>
                        <Col xl="12" className={"mobile-left-section"}>
                            <div className={"mobile-left-section__title"}>Reseller</div>
                            <div className={"mobile-left-section__main-title"}>
                                Jual Mudah Untung Berlimpah
                            </div>
                            <div className={"mobile-left-section__content"}>
                                Dapatkan keuntungan dan berbagai manfaat dari berbisnis dengan cara
                                reseller dan dropship produk kami
                            </div>
                            <div className={"mobile-left-section__button-copy"}>
                                Daftar dan produktif dengan 10ribu reseller dan komunitas
                            </div>
                            <InputGroup size="md" className={"mobile-input-group"}>
                                <Input
                                    placeholder="Tuliskan nama lengkapmu"
                                    className={"mobile-input"}
                                />
                                <InputRightElement className={"mobile-button-container"}>
                                <Button
                                    className={"button"}
                                    id="page-reseller__button-join"
                                >
                                    Daftar
                                </Button>
                                </InputRightElement>
                            </InputGroup>
                            <div className={"mobile-left-section__contact-us"}>
                                Ada yang ingin kamu tanyakan?
                                <p className={"mobile-contact-us"}> Hubungi kami</p>
                            </div>
                        </Col>
                        <Col xl="12" className={"right-section"}>
                            <img
                                alt="asd"
                                className={"mobile-image-container"}
                                src={banner.imageMobilePath}
                            />
                        </Col>
                    </Row>
                </ModalBody>         
            }
            <ModalFooter>
                <Button onClick={previewClose}>Close</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
	);
};

export default ResellerFullPreview;
