import React, { useState } from "react";

import "../styles/_homeBanner.scss";
import { Col, Row } from "reactstrap";
import {
  Flex,
  Spacer,
  IconButton,
  useToast,
  Input,
  Text,
  Switch,
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { FaMobileAlt, FaDesktop } from "react-icons/fa";

import one from "../../../assets/images/pro3/1.jpg";
import Axios from "../../../services/axios";

const AddNewBanner = ({
  setIsLoading,
  getBannerList,
  isAddNew,
  setIsAddNew,
  isLandingPage,
  bannerList,
}) => {
  const toast = useToast();
  const imageMaxSize = 2000000;
  const [addedBanner, setAddedBanner] = useState({
    title: "",
    link: "",
    imagePath: one,
    imageMobile: one,
    preview: "Desktop",
    isActive: false,
  });

  const handleNewBannerImageChange = (value, item) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (value.currentTarget.files.item(0).size > imageMaxSize) {
        toast({
          title: "Error.",
          description: "Mohon upload gambar berukuran kurang dari 2MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        let reader = new FileReader();
        const image = value.target.files[0];
        let tempAddedBanner = { ...addedBanner };

        if (item.preview === "Desktop") {
          tempAddedBanner.fileImageDesktop = image;
        } else {
          tempAddedBanner.fileImageMobile = image;
        }

        reader.onload = () => {
          if (item.preview === "Desktop") {
            tempAddedBanner.imagePath = reader.result;
          } else {
            tempAddedBanner.imageMobile = reader.result;
          }
          setAddedBanner(tempAddedBanner);
          let componentImageUploader =
            document.getElementById("new-image-input");
          componentImageUploader.value = null;
        };

        reader.readAsDataURL(image);
      }
    }
  };

  const deleteImageNewBanner = (item) => {
    document.getElementById("new-image-input").value = null;
    let tempAddedBanner = { ...addedBanner };

    if (item.preview === "Desktop") {
      tempAddedBanner.imagePath = one;
      tempAddedBanner.fileImageDesktop = undefined;
    } else {
      tempAddedBanner.imageMobile = one;
      tempAddedBanner.fileImageMobile = undefined;
    }

    setAddedBanner(tempAddedBanner);
  };

  const newBannerHandler = (e) => {
    let tempAddedBanner = { ...addedBanner };
    if (e.target.name === "isActive") {
      tempAddedBanner.isActive = !tempAddedBanner.isActive;
    } else {
      tempAddedBanner[e.target.name] = e.target.value;
    }

    setAddedBanner(tempAddedBanner);
  };

  const changePreviewNewBanner = (value) => {
    let tempAddedBanner = { ...addedBanner };
    tempAddedBanner.preview = value;
    setAddedBanner(tempAddedBanner);
  };

  const submitValidation = () => {
    let statusValidation = {
      status: "success",
      message: "Banner berhasil disubmit",
    };

    if (!addedBanner.title && !isLandingPage) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon isi nama banner";
    } else if (addedBanner.imagePath === one) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon upload gambar banner untuk desktop";
    } else if (addedBanner.imageMobile === one) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon upload gambar banner untuk mobile";
    }

    return statusValidation;
  };

  const addBanner = async () => {
    setIsLoading(true);
    let responseValidation = submitValidation();
    if (responseValidation.status === "success") {
      try {
        let data = new FormData();
        data.append("image", addedBanner.fileImageDesktop);
        data.append("imageMobile", addedBanner.fileImageMobile);
        if (isLandingPage) {
          data.append("title", bannerList.length + 1);
          data.append("subtitle", addedBanner.title);
        } else {
          data.append("title", addedBanner.title);
          data.append("label", addedBanner.title);
        }
        data.append("link", addedBanner.link);

        let config = {
          method: "POST",
          url: !isLandingPage ? `/banner` : "/main-landing/banner",
          data: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);
        await getBannerList();
        toast({
          title: "Success.",
          description: "Banner baru berhasil ditambahkan",
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        addNewClose();
        setIsLoading(false);
      } catch (error) {
        toast({
          title: "Error.",
          description: "Terjadi kesalahan mohon coba lagi",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } else {
      toast({
        title: "Error.",
        description: responseValidation.message,
        status: responseValidation.status,
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  const addNewClose = () => {
    setIsAddNew(false);
    setAddedBanner({
      title: "",
      link: "",
      imagePath: one,
      imageMobile: one,
      preview: "Desktop",
      isActive: false,
    });
  };

  return (
    <>
      <Modal onClose={addNewClose} size="full" isOpen={isAddNew}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Banner</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Row style={{ margin: "0px 32px" }}>
              <Col sm="4" md="4" lg="4">
                {!isLandingPage && (
                  <>
                    <Text
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginBottom: "8px",
                        color: "black",
                      }}
                    >
                      {!isLandingPage ? "Title" : "Urutan ke"}
                    </Text>
                    <Input
                      mb={4}
                      placeholder="Title"
                      name="title"
                      disabled={isLandingPage}
                      onChange={(e) => newBannerHandler(e)}
                      value={
                        isLandingPage ? bannerList.length : addedBanner.title
                      }
                      type={!isLandingPage ? "text" : "number"}
                    />
                  </>
                )}
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginBottom: "8px",
                    color: "black",
                  }}
                >
                  URL
                </Text>
                <Input
                  mb={4}
                  placeholder="URL"
                  name="link"
                  onChange={(e) => newBannerHandler(e)}
                  value={addedBanner.link}
                />
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginBottom: "8px",
                    color: "black",
                  }}
                >
                  Active
                </Text>
                <Switch
                  isChecked={addedBanner.isActive}
                  name="isActive"
                  onChange={(e) => newBannerHandler(e)}
                  style={{ marginTop: "12px" }}
                />
              </Col>
              <Col sm="8" md="8" lg="8">
                <Flex>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "16px",
                      color: "black",
                    }}
                  >
                    {addedBanner.preview} Preview
                  </p>
                  <Spacer />
                  {addedBanner.imagePath !== one && (
                    <IconButton
                      mr={4}
                      variant="outline"
                      aria-label="Call Sage"
                      fontSize="20px"
                      onClick={() => deleteImageNewBanner(addedBanner)}
                      icon={<MdDelete />}
                    />
                  )}
                  <IconButton
                    mr={4}
                    variant="outline"
                    aria-label="Call Sage"
                    fontSize="20px"
                    onClick={() => changePreviewNewBanner("Desktop")}
                    icon={<FaDesktop />}
                  />
                  <IconButton
                    mr={4}
                    variant="outline"
                    aria-label="Call Sage"
                    fontSize="20px"
                    onClick={() => changePreviewNewBanner("Mobile")}
                    icon={<FaMobileAlt />}
                  />
                </Flex>
                <Row>
                  <Col xl={addedBanner.preview === "Desktop" ? 12 : 4}>
                    <div className="add-product">
                      <Row className="file-upload-product row">
                        <Col className="col">
                          <div className="box-input-file-full">
                            <input
                              className="upload"
                              style={{
                                width: "100%",
                                aspectRatio: `${
                                  addedBanner.preview === "Desktop"
                                    ? isLandingPage
                                      ? "21 / 9"
                                      : "144 / 71"
                                    : "1 / 1"
                                }`,
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              type="file"
                              id="new-image-input"
                              name="img"
                              accept="image/*"
                              onChange={(e) =>
                                handleNewBannerImageChange(e, addedBanner)
                              }
                            />
                            <img
                              src={
                                addedBanner.preview === "Desktop"
                                  ? addedBanner.imagePath
                                  : addedBanner.imageMobile
                              }
                              alt=""
                              style={{
                                aspectRatio: `${
                                  addedBanner.preview === "Desktop"
                                    ? isLandingPage
                                      ? "21 / 9"
                                      : "144 / 71"
                                    : "1 / 1"
                                }`,
                                width: "100%",
                                objectFit: "cover",
                              }}
                              className="img-fluid image_zoom_1 blur-up lazyloaded"
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button mr={4} onClick={addNewClose}>
              Close
            </Button>
            <Button
              variant="ghost"
              justifyContent="flex-start"
              backgroundColor={"#FF8084"}
              color={"white"}
              onClick={addBanner}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddNewBanner;
