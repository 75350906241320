import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Text, Flex, IconButton } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import "./styles/date-picker.css";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { CSVLink } from "react-csv";
import dateFormat from "dateformat";
import Axios from "../../../services/axios";
import { RepeatIcon } from "@chakra-ui/icons";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const FilterByDate = ({ csvData, setPage, handleMappingData, setIsLoading }) => {
  const today = new Date();
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const { search } = useLocation();
  const history = useHistory();
  const [populateData, setPopulateData] = useState([]);
  const orderToBeProcessed = useSelector(({ orders }) => orders.allorders);

  const toPopulateData = async () => {
    setIsLoading(true);
    const data = handleMappingData();
    const temp = [
      [
        "Invoice Number",
        "Order Date",
        "Status",
        "DC",
        "Total",
        "Shipping Method",
      ],
    ];
    let usedData;
    
    if (!isEmpty(data)) {
      usedData = [...data];
    } else {
      const currentQuery = queryString.parse(search);
      if (currentQuery.tab_active || currentQuery.sub_tab) {
        delete currentQuery.tab_active;
        delete currentQuery.sub_tab;
      }
      const queries = queryString.stringify({
        ...currentQuery,
        sort: "orderDate,desc",
      });
      const { data } = await Axios({
        url: `/admin-order?${queries}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      })
      usedData = [...data.data.orders]
    }
    usedData.forEach((item) => {
      const {
        invoiceNumber,
        orderDate,
        orderStatus,
        subtotal,
        resellerDiscount,
        shippingMethodId,
      } = item;

      temp.push([
        invoiceNumber,
        orderDate,
        orderStatus,
        "DC",
        subtotal,
        shippingMethodId,
      ]);
    });

    setIsLoading(false);

    return temp;
  }

  const populateDataHandler = async (event, done) => {
    const populated = await toPopulateData(done);
    const ws = XLSX.utils.json_to_sheet(populated, { skipHeader: 1 });

    const csv = XLSX.utils.sheet_to_csv(ws)
    const CSV_TYPE = "text/csv;charset=utf-8";
    const data = new Blob([csv], { type: CSV_TYPE });

    FileSaver.saveAs(data, `Orderlist-${dateFormat(today, "dd-mm-yyyy")}`)

    setPopulateData(populated);
  };

  const changeDateHandler = ({ event, type }) => {
    const date = `${event.getFullYear()}-${
      event.getMonth() + 1
    }-${event.getDate()}`;
    const today = `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}`;

    setPage(0);
    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify({
      ...currentQuery,
      page: 0,
      filter_order_date_min:
        type === "min" ? date : firstDate === "" ? date : firstDate,
      filter_order_date_max:
        type === "max" ? date : lastDate === "" ? today : lastDate,
    });

    history.push(`/order-list?${queries}`);
  };

  const handleClearDate = () => {
    setFirstDate("");
    setLastDate("");

    setPage(0);

    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify(
      {
        ...currentQuery,
        page: 0,
        filter_order_date_min: "",
        filter_order_date_max: "",
      },
      { skipEmptyString: true }
    );

    history.push(`/order-list?${queries}`);
  };

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (
      currentQuery.filter_order_date_min ||
      currentQuery.filter_order_date_max
    ) {
      setFirstDate(currentQuery.filter_order_date_min);
      setLastDate(currentQuery.filter_order_date_max);
    }
  }, [search]);
  return (
    <Flex className="order-list__datepicker__container">
      <Text className="order-list__datepicker-text">Order Date</Text>
      <DatePicker
        selected={firstDate ? new Date(firstDate) : firstDate}
        onChange={(event) => changeDateHandler({ event, type: "min" })}
        dateFormat="dd/MM/yyyy"
        maxDate={lastDate ? new Date(lastDate) : new Date()}
      />
      -
      <DatePicker
        selected={lastDate ? new Date(lastDate) : lastDate}
        onChange={(event) => changeDateHandler({ event, type: "max" })}
        dateFormat="dd/MM/yyyy"
        minDate={new Date(firstDate)}
        maxDate={new Date()}
      />
      <IconButton
        colorScheme="teal"
        variant="outline"
        onClick={handleClearDate}
        icon={<RepeatIcon />}
      />
      <Button
        colorScheme="teal"
        size="md"
        ml="4px"
        onClick={populateDataHandler}
      >
        Export
      </Button>
    </Flex>
  );
};

export default FilterByDate;
