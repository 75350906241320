import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { UploadCloud } from "react-feather";
import { useLocation } from "react-router-dom";
import "../styles/uploadTax.css";
import queryString from "query-string";
import Axios from "../../../services/axios";
import { find } from "lodash";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { File } from "react-feather";

function UploadTax({ isOpen, onClose, uploadType }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        uploadType.type !== "detail"
          ? acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          : [
              acceptedFiles.map((file) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                })
              )[0],
            ]
      );
    },
  });
  const [files, setFiles] = useState([]);
  const [filesName, setFilesName] = useState([]);
  const { search } = useLocation();
  const currentQuery = queryString.parse(search);
  const [checkName, setCheckName] = useState([]);
  const today = new Date();
  const [period] = useState({
    month: currentQuery.month ? currentQuery.month : today.getMonth() + 1,
    year: currentQuery.year ? currentQuery.year : today.getFullYear(),
  });

  const toast = useToast();

  const acceptedFileItems = filesName.map((file) => (
    <li key={file.path} style={{ width: "100%" }}>
      <Flex justify="space-between">
        <Flex align="center">
          <Box mr="4px">
            <File width="16px" />
          </Box>
          <Text>
            {file.path} - {file.size} bytes{" "}
          </Text>
        </Flex>
        <Spacer />
        <Box>
          {file.valid ? <CheckIcon color="green" /> : <CloseIcon color="red" />}
        </Box>
      </Flex>
    </li>
  ));

  const handleCheckName = async () => {
    try {
      const { data } = await Axios({
        url: "/admin/commissionPayout/tax/checkFiles",
        data: {
          period: { ...period },
          fileNames: files.map((file) => file.name),
        },
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      const validNameArr = data.data.validation.map((file) => file.valid);
      let arrayInvalidName = [];
      if (!validNameArr.every((file) => file)) {
        data.data.validation.forEach((file) => {
          if (!file.valid) {
            toast({
              title: "Warning",
              description: `Nama file (${file.file}) tidak sesuai dengan ketentuan.`,
              status: "warning",
              position: "top",
              duration: 3000,
              isClosable: true,
            });
          }
        });
      } else {
        toast({
          title: "Success",
          description: "Nama file sesuai dengan ketentuan",
          status: "success",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
      }
      data.data.validation.forEach((file) => {
        const findData = find(files, { path: file.file });
        arrayInvalidName.push({ ...findData, ...file });
      });
      setCheckName(data.data.validation.map((file) => file.valid));
      setFilesName(arrayInvalidName);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (files.length > 0) {
      handleCheckName();
    }
  }, [files]);

  const handleSubmit = async () => {
    try {
      for (let i = 0; i < files.length; i++) {
        let form = new FormData();
        form.append("year", period.year);
        form.append("month", period.month);
        form.append("files", files[i]);
        await Axios({
          url: "/admin/commissionPayout/tax/upload",
          method: "POST",
          data: form,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });
      }
      toast({
        title: "Success",
        description: "Berhasil upload file",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
      handleCloseModal();
    } catch (err) {
      console.log(err.response);
      toast({
        title: "Warning",
        description: err.response.data.errors[0],
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  const handleCloseModal = () => {
    setFiles([]);
    setFilesName([]);
    onClose();
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent maxWidth="600px">
        <section className="container upload-tax__container">
          <Text className="upload-tax__title">
            Upload Withholding Tax{" "}
            {`${
              uploadType.id &&
              `${uploadType?.id?.user?.firstName} ${uploadType?.id?.user?.lastName}`
            }`}
          </Text>
          <Box {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <Flex margin="auto" justify="center">
              <UploadCloud />
            </Flex>
            <Text>Drag and drop files here or click</Text>
            <Text as="em">(Only *.pdf will be accepted)</Text>
          </Box>

          <Box m="1em 0">
            <Text>Filename Format : NIK_MMYYYY</Text>
            <Text className="upload-tax__subtitle">Selected files</Text>
            <ul>{acceptedFileItems}</ul>
          </Box>

          <Flex margin="auto" justify="center">
            <Button
              width="60%"
              color="#ffffff"
              bg="#ff8084"
              disabled={
                files.length < 1 || !checkName.every((filename) => filename)
              }
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Flex>
        </section>
      </ModalContent>
    </Modal>
  );
}

export default UploadTax;
