import {
  Box,
  Heading,
  Text,
  Flex,
  Input,
  IconButton,
  useToast,
  CloseButton,
  Textarea,
} from "@chakra-ui/react";
import { AddIcon, ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { Editor } from "react-draft-wysiwyg";
import styles_wysiwyg from "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { RichUtils, EditorState } from "draft-js";
import stylesEditor from ".././styles/wysiwyg.module.css";
import { Card, CardBody, Col, Row } from "reactstrap";
import { setProductLink } from "../../../store/actions/productShowcaseAction";
import { useDispatch } from "react-redux";

const SectionHTU = ({
  HTU,
  setHTU,
  validateHTU,
  editorHowToUseDescChangeHandler,
  cautions,
  setCautions,
  editorStateDesc,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const validateCautions = () => {
    const tempCautions = [...cautions];
    let index = 0;
    let statusValidation = {
      status: "success",
      message: "Cautions berhasil ditambahkan",
    };
    let isValid = true;
    while (index < tempCautions.length && isValid) {
      if (!tempCautions[index]) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon isi field caution terlebih dahulu";
        isValid = false;
      }
      index++;
    }
    return statusValidation;
  };

  const inputHandlerCautions = (target, index) => {
    let tempCautions = [...cautions];
    let { value } = target;
    tempCautions[index] = value;
    setCautions(tempCautions);
  };

  const addCautions = () => {
    const isValid = validateCautions();
    if (isValid.status === "warning") {
      toast({
        title: "Warning.",
        description: isValid.message,
        status: isValid.status,
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    } else {
      const tempStr = "";
      setCautions([...cautions, tempStr]);
    }
  };

  const deleteCautions = (i) => {
    let temp = [];
    cautions.forEach((each, idx) => {
      if (i !== idx) {
        temp.push(each);
      }
    });
    setCautions(temp);
  };

  const deleteHowToUse = (i) => {
    let temp = [];
    HTU.forEach((each, idx) => {
      if (i !== idx) {
        temp.push(each);
      }
    });
    setHTU(temp);
  };

  const moveCautionOrder = (fromIndex, toIndex) => {
    let tempData = [...cautions];
    let caution = tempData[fromIndex];
    tempData.splice(fromIndex, 1);
    tempData.splice(toIndex, 0, caution);
    setCautions(tempData);
  };

  const moveHowToUseOrder = (fromIndex, toIndex) => {
    let tempData = [...HTU];
    let howToUse = tempData[fromIndex];
    tempData.splice(fromIndex, 1);
    tempData.splice(toIndex, 0, howToUse);
    setHTU(tempData);
  };

  const addHTU = () => {
    const isValid = validateHTU();
    if (isValid.status === "warning") {
      toast({
        title: "Warning.",
        description: isValid.message,
        status: isValid.status,
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    } else {
      const tempObj = {
        title: "",
        image: "",
        description: "",
        editorState: EditorState.createEmpty(),
      };
      setHTU([...HTU, tempObj]);
    }
  };

  const inputHTUHandler = async (target, index) => {
    let tempHTU = [...HTU];
    let { value, files } = target;

    if (target.name === "image") {
      const formData = new FormData();

      formData.append("image", files[0]);
      await dispatch(setProductLink(formData))
        .then((data) => {
          tempHTU[index]["image"] = data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      tempHTU[index][target.name] = value;
    }
    setHTU(tempHTU);
  };

  const _uploadImageCallBack = (file) => {
    // convert to base64
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onerror = function (error) {
      console.error("Error onload: ", error);
    };

    return new Promise((resolve, reject) => {
      reader.onload = function () {
        resolve({
          data: {
            link: reader.result,
          },
        });
      };
    });
  };

  const _toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(editorStateDesc, blockType));
  };

  const config = {
    image: {
      uploadCallback: _uploadImageCallBack,
      previewImage: true,
      alt: { present: true, mandatory: false },
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    },
    list: {
      inDropdown: true,
      toggleBlockType: _toggleBlockType,
      unordered: { className: stylesEditor["unordered-list"] },
      ordered: { className: stylesEditor["ordered-list"] },
    },
    textAlign: { inDropdown: true },
    blockType: {
      className: "demo-option-custom-wide",
      dropdownClassName: "demo-dropdown-custom",
    },
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col xl={12} style={{ marginBottom: "16px" }}>
            <Box width="100%" borderBottomWidth="1px">
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "black",
                }}
              >
                How To Use
              </p>
            </Box>
            <div className="description-sm">
              <Heading as="h5" size="sm" mt="2rem" mb="0.5rem">
                How To Use List
              </Heading>
              {HTU.map((each, index) => (
                <Flex
                  key={index}
                  mb="2em"
                  borderRadius="md"
                  borderWidth="1px"
                  padding="12px"
                >
                  <Box flex={1}>
                    <Heading as="h5" size="sm" mb="0.5rem">
                      Title
                    </Heading>
                    <Input
                      onChange={(e) => inputHTUHandler(e.target, index)}
                      type={"text"}
                      placeholder={"How to Use Title"}
                      mb={"3"}
                      mt={"1"}
                      name={"title"}
                      value={each.title}
                    />
                    <Heading as="h5" size="sm" mb="0.5rem">
                      Description
                    </Heading>
                    <Editor
                      editorState={each.editorState}
                      onEditorStateChange={(e) =>
                        editorHowToUseDescChangeHandler(e, index)
                      }
                      editorStyle={{ padding: "5px" }}
                      handlePastedText={() => false}
                      toolbarClassName={styles_wysiwyg["toolbarClassName"]}
                      wrapperClassName={styles_wysiwyg["wrapperClassName"]}
                      editorClassName={styles_wysiwyg["editorClassName"]}
                      toolbar={config}
                    />
                    <Heading as="h5" size="sm" margin="0.5rem 0">
                      Image
                    </Heading>
                    <Flex alignItems={"center"}>
                      <label className="browse-file-btn">
                        <Input
                          onChange={(e) => inputHTUHandler(e.target, index)}
                          type={"file"}
                          mb={"3"}
                          mt={"1"}
                          name={"image"}
                        />
                        Browse File
                      </label>
                      <Text
                        marginLeft={"1rem"}
                        textDecoration={"underline"}
                        width={"600px"}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                      >
                        {each.image}
                      </Text>
                    </Flex>
                  </Box>
                  <Flex flexDirection="column">
                    <IconButton
                      isDisabled={index === 0}
                      icon={<ChevronUpIcon />}
                      colorScheme="teal"
                      size="sm"
                      margin={3}
                      onClick={() => moveHowToUseOrder(index, index - 1)}
                    />
                    <IconButton
                      isDisabled={index === HTU.length - 1}
                      icon={<ChevronDownIcon />}
                      colorScheme="teal"
                      size="sm"
                      margin={3}
                      onClick={() => moveHowToUseOrder(index, index + 1)}
                    />
                  </Flex>
                  <Box>
                    <CloseButton
                      style={{
                        height: "32px",
                        width: "32px",
                      }}
                      mt={3}
                      mr={3}
                      size="sm"
                      backgroundColor="#ff605c"
                      color="white"
                      onClick={() => deleteHowToUse(index)}
                    />
                  </Box>
                </Flex>
              ))}
              <Flex
                justifyContent={"end"}
                paddingBottom={"2rem"}
                marginTop={"8px"}
                borderBottomWidth="1px"
              >
                <IconButton
                  variant={"ghost"}
                  onClick={addHTU}
                  icon={<AddIcon />}
                />
              </Flex>
              <Heading as="h5" size="sm" mt="2rem">
                Cautions
              </Heading>
              {cautions.map((each, index) => (
                <Flex>
                  <Textarea
                    key={index}
                    value={each}
                    style={{ margin: "10px 0" }}
                    type="text"
                    onChange={(e) => inputHandlerCautions(e.target, index)}
                  />
                  <Flex flexDirection="column">
                    <IconButton
                      isDisabled={index === 0}
                      icon={<ChevronUpIcon />}
                      colorScheme="teal"
                      size="sm"
                      margin={3}
                      onClick={() => moveCautionOrder(index, index - 1)}
                    />
                    <IconButton
                      isDisabled={index === cautions.length - 1}
                      icon={<ChevronDownIcon />}
                      colorScheme="teal"
                      size="sm"
                      margin={3}
                      onClick={() => moveCautionOrder(index, index + 1)}
                    />
                  </Flex>
                  <Box>
                    <CloseButton
                      style={{
                        height: "32px",
                        width: "32px",
                      }}
                      mt={3}
                      mr={3}
                      size="sm"
                      backgroundColor="#ff605c"
                      color="white"
                      onClick={() => deleteCautions(index)}
                    />
                  </Box>
                </Flex>
              ))}
              <Flex justifyContent="end" marginTop="8px">
                <IconButton
                  icon={<AddIcon />}
                  onClick={addCautions}
                  variant="ghost"
                />
              </Flex>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SectionHTU;
