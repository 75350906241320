import { SET_RESELLER_LEVEL } from "../actionTypes";

const initialState = {
  resellerLevelList: [],
};

function resellerLevelReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RESELLER_LEVEL:
      return { ...state, resellerLevelList: action.payload };
    default:
      return state;
  }
}

export default resellerLevelReducer;
