import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  Text,
  VStack,
} from "@chakra-ui/react";
import Pagination from "../order/components/pagination";
import DataTable from "react-data-table-component";
import FilterDate from "./components/filterDate";
import Breadcrumb from "../common/breadcrumb";
import navguard from "../auth/navguard";
import Axios from "../../services/axios";
import { useLocation, useParams } from "react-router-dom";
import { isEmpty } from "lodash";

const DetailCommission = () => {
  const formatter = new Intl.NumberFormat("id-ID");
  const [page, setPage] = useState(0);
  const [dataCommision, setDataCommission] = useState({});
  const [tableCommission, setTableCommission] = useState([]);
  const [responseTableCommission, setResponseTableCommission] = useState([]);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [upperLimit, setUpperLimit] = useState(0);
  const { id } = useParams();
  const changePageHandler = (value) => {
    setPage(value.selected);
  };
  const { search } = useLocation();
  const [totalPage, setTotalPage] = useState(0);

  const fetchDataDetail = async (id) => {
    try {
      const { data: logData } = await Axios({
        url: `/admin/user/${id}/commission-log${search}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      const tempLog = logData?.data?.commissionLogs?.filter(
        (item) => item.commission > 0
      );
      setResponseTableCommission(tempLog);

      const tempCommissionDetail = {
        ...logData.data.user,
        totalCommission: logData.data.totalCommission,
        totalOrderCommission: logData.data.totalOrderCommission,
        totalReferralCommission: logData.data.totalReferralCommission
      }

      setDataCommission(tempCommissionDetail);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataDetail(id);
  }, [id, search]);

  const columns = [
    {
      name: <b>Date</b>,
      header: <b>Date</b>,
      selector: "formattedDate",
      style: {
        textAlign: "left",
      },
      sortable: true,
      cell: (row, index) => {
        return <span>{row.formattedDate}</span>;
      },
    },
    {
      name: <b>Source</b>,
      header: <b>Source</b>,
      selector: "source",
      cell: null,
      style: {
        textAlign: "center",
        textTransform: "capitalize",
      },
      sortable: true,
    },
    {
      name: <b>Notes</b>,
      header: <b>Notes</b>,
      selector: "notes",
      cell: null,
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
    {
      name: <b>Commission</b>,
      header: <b>Commission</b>,
      selector: "commission",
      cell: (row, index) => {
        return <span>Rp{formatter.format(row.commission)}</span>;
      },
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
  ];

  useEffect(() => {
    setLowerLimit(page * 10);
    setUpperLimit((page + 1) * 10);
  }, [page]);

  useEffect(() => {
    setTableCommission(responseTableCommission.slice(lowerLimit, upperLimit));
  }, [upperLimit, lowerLimit, responseTableCommission]);

  useEffect(() => {
    const tempTotalPages = Math.ceil(responseTableCommission.length / 10);
    setTotalPage(tempTotalPages);
  }, [responseTableCommission]);
  return (
    <>
      <Breadcrumb title={"Detail Commission"} parent="Commission" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <Flex
              alignItems={"center"}
              justifyContent={"space-between"}
              marginBottom={"2rem"}
            >
              <h5>
                Commission of{" "}
                <b>{`${dataCommision?.firstName} ${dataCommision?.lastName}`}</b>
              </h5>
              <FilterDate isDetailCommission id={id} />
            </Flex>
            <Grid templateColumns="repeat(5, 1fr)" gap={4}>
              <GridItem w="100%" h="10" colSpan={1}>
                <Text>Email</Text>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={2}>
                <InputGroup flex={1}>
                  <Input
                    type="email"
                    placeholder="email"
                    isDisabled
                    value={dataCommision?.email}
                  />
                </InputGroup>
              </GridItem>

              <GridItem w="100%" h="10" colSpan={2} rowSpan={2}>
                <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
                  <VStack p={4} m={[5, 0]}>
                    <Text color={"black"}>Total Commission</Text>
                    <Heading as="h2">
                      Rp
                      {formatter.format(
                        dataCommision?.totalCommission
                      )}
                    </Heading>
                  </VStack>
                </Box>
              </GridItem>

              <GridItem w="100%" h="10" colSpan={1}>
                <Text>Reseller ID</Text>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={2}>
                <InputGroup flex={1}>
                  <Input
                    type="text"
                    placeholder="referral id"
                    value={dataCommision?.referralCode}
                    isDisabled
                  />
                </InputGroup>
              </GridItem>

              <GridItem w="100%" h="10" colSpan={1}>
                <Text>Phone</Text>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={2}>
                <InputGroup flex={1}>
                  <Input
                    type="tel"
                    placeholder="phone"
                    isDisabled
                    value={dataCommision?.phone}
                  />
                </InputGroup>
              </GridItem>

              <GridItem w="100%" h="10" colSpan={1} rowSpan={2}>
                <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
                  <VStack p={4} m={[5, 0]}>
                    <Text color={"black"}>Transaction Commission</Text>
                    <Heading as="h4" size="md">
                      Rp{formatter.format(dataCommision?.totalOrderCommission)}
                    </Heading>
                    {/* <Text color={"black"}>(Commission Bonus 4%)</Text> */}
                  </VStack>
                </Box>
              </GridItem>

              <GridItem w="100%" h="10" colSpan={1} rowSpan={2}>
                <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
                  <VStack p={4} m={[5, 0]}>
                    <Text color={"black"}>Referral Commission</Text>
                    <Heading as="h4" size="md">
                      Rp
                      {formatter.format(
                        dataCommision?.totalReferralCommission
                      )}
                    </Heading>
                  </VStack>
                </Box>
              </GridItem>

              <GridItem w="100%" h="10" colSpan={1}>
                <Text>Level</Text>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={2}>
                <InputGroup flex={1}>
                  <Input
                    type="text"
                    isDisabled
                    value={dataCommision?.resellerStatus}
                  />
                </InputGroup>
              </GridItem>
            </Grid>
          </CardHeader>
          <CardBody>
            <Heading as="h2" size="md">
              Commission Log
            </Heading>
            <Divider
              mt={5}
              mb={5}
              borderWidth={1}
              color="black"
              borderColor="black"
              background="black"
            />
            <DataTable
              noHeader={true}
              data={tableCommission}
              columns={columns}
              striped={true}
              center={true}
            />
            {!isEmpty(tableCommission) && (
              <Box margin={"2rem 0"}>
                <Pagination
                  page={page}
                  totalPage={totalPage}
                  changePageHandler={changePageHandler}
                />
              </Box>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default navguard(DetailCommission);
