import { Badge, Button, Flex, Text, useToast } from "@chakra-ui/react";
import React from "react";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Axios from "../../../services/axios";
import { getUserDetailAction } from "../../../store/actions/userAction";

function VerifyUser({ userData }) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const toast = useToast();
  const handleVerified = async () => {
    try {
      const currentQuery = queryString.parse(search);

      await Axios({
        url: `/admin/user/${currentQuery.id}/verify`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      getUserDetailAction(dispatch, currentQuery.id);
      toast({
        title: "Success.",
        description: "Sukses melakukan verifikasi user.",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    } catch (err) {
      console.error(err);
      toast({
        title: "Warning.",
        description: "Gagal melakukan verifikasi user.",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };
  return (
    <Flex align="center" mb={2}>
      <Text
        fontSize="24px"
        color="black"
      >{`${userData?.firstName} ${userData?.lastName}`}</Text>
      <Badge
        colorScheme={(userData?.verificationStatus === "verified") ? "green" : "red"}
        borderRadius="35px"
        padding="8px"
        margin="0 1.5em"
      >
        {userData?.verificationStatus === "verified"
          ? "Verified" : "Not Verified"
        }
      </Badge>
      {userData?.verificationStatus === "not verified" && (
        <Button colorScheme="teal" variant="ghost" onClick={handleVerified}>
          Verify
        </Button>
      )}
    </Flex>
  );
}

export default VerifyUser;
