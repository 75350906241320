import React, { useState } from "react";
import { Box, Image } from "@chakra-ui/react";
import styles from "./styles.module.css";
import ModalView from "./modalView";

const PlayIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles["play-icon"]}
  >
    <path
      d="M18 3C9.729 3 3 9.729 3 18C3 26.271 9.729 33 18 33C26.271 33 33 26.271 33 18C33 9.729 26.271 3 18 3ZM18 30C11.3835 30 6 24.6165 6 18C6 11.3835 11.3835 6 18 6C24.6165 6 30 11.3835 30 18C30 24.6165 24.6165 30 18 30Z"
      fill="white"
    />
    <path d="M13.5 25.5L25.5 18L13.5 10.5V25.5Z" fill="white" />
  </svg>
);

function ReviewImage({ item, each, key, duration, roundTime }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <Box
      onClick={() => setIsOpenModal(true)}
      key={key}
      className={styles["image-uploaded-wrapper"]}
    >
      {each.type === "image" ? (
        <Image src={each.mediaLink} className={styles["image-uploaded"]} />
      ) : (
        <>
          <video className={styles["video"]} src={each.mediaLink} />
          <Box className={styles["overlay-wrapper"]}>
            <Box className={styles["overlay"]} />
            <PlayIcon />
          </Box>
        </>
      )}
      {isOpenModal && (
        <ModalView
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          reviews={item.productReviewMedia}
          mainItem={item}
          duration={duration}
          roundTime={roundTime}
        />
      )}
    </Box>
  );
}

export default ReviewImage;
