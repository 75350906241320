import React, { lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./index.scss";
import App from "./components/app";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Provider } from "react-redux";
import store from "./store";

// Components
import Dashboard from "./components/dashboard";

// Products physical
import AllProducts from "./components/products/AllProducts";

//Sales
import Orders from "./components/sales/orders";
import Transactions_sales from "./components/sales/transactions-sales";

//Promo
import ListPromo from "./components/promo/List Promo/list-promo";
import CreatePromo from "./components/promo/Create Promo/createPromo";
import EditPromo from "./components/promo/Edit Promo/edit-promo";
import PromoUsage from "./components/promo/Promo Usage";

// Product Showcase
import ListProductShowcase from "./components/showcase/list-product-showcase";
import CreateProductShowcase from "./components/showcase/create-showcase";
import EditProductShowcase from "./components/showcase/edit-showcase";

import ListLoyaltyPoint from "./components/loyalty/ListLoyaltyPoint";
import DetaiLoyaltyPoint from "./components/loyalty/DetailLoyaltyPoint";
import Login from "./components/auth/login";
import AddNewProduct from "./components/products/AddNewProduct";
import Categories from "./components/products/Categories";
import EditCategory from "./components/products/EditCategory";
import Brands from "./components/products/Brands";
import EditBrand from "./components/products/EditBrand";
import ListWarehouse from "./components/warehouse/list-warehouse";
import CreateWarehouse from "./components/warehouse/create-warehouse";
import EditWareHouse from "./components/warehouse/edit-warehouse";
import StockList from "./components/warehouse/stock-list";
import HomeBanner from "./components/content/home-banner";
import HighlightBanner from "./components/content/highlight-banner";
import LandingBanner from "./components/content/landing-banner";
import ResellerBanner from "./components/content/reseller-banner";
import Testimony from "./components/content/testimony";
import OrderDetail from "./components/order";
import ListOrder from "./components/order/list-order";
import ProductPackage from "./components/products/ProductPackage";
import ProductReviewsDetail from "./components/products/component/ProductReviewsDetail";
import ListCommission from "./components/commission/list-commission";
import ListCommissionPayout from "./components/commission/list-commission-payout";
import DetailCommission from "./components/commission/detail-commission";
import ProductTagging from "./components/products/Tagging";
import ListUser from "./components/users/list-user";
import CreateUser from "./components/users/create-user";
import ListCommunity from "./components/community/list-community";
import ListSuggestion from "./components/community/list-suggestion";
import Feedback from "./components/helpCenter/feedback";
import EditUser from "./components/users/edit-user";
import ListArticle from "./components/helpCenter/list-article";
import AddArticle from "./components/helpCenter/AddArticle";
import Subtopic from "./components/helpCenter/subtopic";
import ArticleFeedback from "./components/helpCenter/ArticleFeedback";
import GeneralFaq from "./components/helpCenter/listFaqs";
import DetailCommissionPayout from "./components/commission/detail-commission-payout";
import ListAdmin from "./components/admin/ListAdmin";
import ListReward from "./components/loyalty/ListReward";
import ResellerDiscount from "./components/resellerDiscount/ListDiscount";
import ListRedemption from "./components/loyalty/ListRedemption";
import ListCampaign from "./components/campaign/ListCampaign";
import AddCampaign from "./components/campaign/AddCampaign";
import LoginProvider from "./components/LoginProvider";
import { ErrorBoundary } from "react-error-boundary";
import loggingError from "./services/loggingError";
import FallbackErrorPage from "./components/error/FallbackErrorPage";

const FallbackErrorNotFound = lazy(() =>
  import("./components/error/FallbackErrorNotFound")
);

const theme = extendTheme({
  components: {
    Tooltip: {
      variants: {
        white: {
          bg: "white",
          color: "black",
          padding: "16px",
          borderRadius: "4px",
        },
      },
    },
  },
});

const Root = () => {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <ErrorBoundary
          onError={loggingError}
          FallbackComponent={FallbackErrorPage}>
          <BrowserRouter basename={"/"}>
            <LoginProvider>
              <PerfectScrollbar>
                <Switch>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/`}
                    component={Login}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/auth/login`}
                    component={Login}
                  />
                  <App>
                    <Route
                      path={`${process.env.PUBLIC_URL}/dashboard`}
                      component={Dashboard}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/content/home-banner`}
                      component={HomeBanner}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/content/testimony`}
                      component={Testimony}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/content/highlight-banner`}
                      component={HighlightBanner}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/content/landing-banner`}
                      component={LandingBanner}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/content/reseller-banner`}
                      component={ResellerBanner}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/content/list-productshowcase`}
                      component={ListProductShowcase}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/content/create-productshowcase`}
                      component={CreateProductShowcase}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/content/edit-productshowcase`}
                      component={EditProductShowcase}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/products/all-products`}
                      component={AllProducts}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/products/tagging`}
                      component={ProductTagging}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/products/add-product`}
                      component={AddNewProduct}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/products/edit-product/:productId`}
                      component={AddNewProduct}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/products/brands`}
                      component={Brands}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/products/edit-brand/:brandId`}
                      component={EditBrand}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/products/edit-series/:seriesId`}
                      component={EditBrand}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/products/categories`}
                      component={Categories}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/products/reviews-detail/:slug`}
                      component={ProductReviewsDetail}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/products/edit-category/:category`}
                      component={EditCategory}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/products/package-recommendation`}
                      component={ProductPackage}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/products/edit-group-category/:groupCategory`}
                      component={EditCategory}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/products/edit-sub-category/:subCategory`}
                      component={EditCategory}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/warehouse/create-warehouse`}
                      component={CreateWarehouse}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/warehouse/edit-warehouse/:id`}
                      component={EditWareHouse}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/warehouse/list-warehouse/:id`}
                      component={StockList}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/warehouse/list-warehouse`}
                      component={ListWarehouse}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/order-detail/:id`}
                      component={OrderDetail}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/order-list`}
                      component={ListOrder}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/sales/orders`}
                      component={Orders}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/sales/transactions`}
                      component={Transactions_sales}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/promo/list-promo`}
                      component={ListPromo}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/promo/create-promo`}
                      component={CreatePromo}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/promo/edit-promo/:id`}
                      component={EditPromo}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/promo/promo-usage/:id`}
                      component={PromoUsage}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/commission/list-commission`}
                      component={ListCommission}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/commission/detail-commission/:id`}
                      component={DetailCommission}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/commission/list-payout`}
                      component={ListCommissionPayout}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/commission/detail-commission-payout/:id`}
                      component={DetailCommissionPayout}
                    />

                    {/* User */}
                    <Route
                      path={`${process.env.PUBLIC_URL}/users/all-user`}
                      component={ListUser}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/users/create-user`}
                      component={CreateUser}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/users/edit-user`}
                      component={EditUser}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/community/list-community`}
                      component={ListCommunity}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/community/list-suggestion`}
                      component={ListSuggestion}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/help-center/feedback`}
                      component={Feedback}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/help-center/article`}
                      component={ListArticle}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/help-center/add-article`}
                      component={AddArticle}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/help-center/edit-article/:id`}
                      component={AddArticle}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/help-center/subtopic`}
                      component={Subtopic}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/help-center/feedback-article/:id`}
                      component={ArticleFeedback}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/help-center/faq`}
                      component={GeneralFaq}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/admin/list-admin`}
                      component={ListAdmin}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/campaign/list-campaign`}
                      component={ListCampaign}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/campaign/add-campaign`}
                      component={AddCampaign}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/campaign/edit-campaign/:id`}
                      component={AddCampaign}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/loyalty/list-reward`}
                      component={ListReward}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/reseller-discount/list-discount`}
                      component={ResellerDiscount}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/loyalty/list-points`}
                      component={ListLoyaltyPoint}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/loyalty/detail-point/:id`}
                      component={DetaiLoyaltyPoint}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/loyalty/list-redemption`}
                      component={ListRedemption}
                    />

                    <script
                      src={`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_PBP_SITE_KEY}`}
                      async
                      defer
                    ></script>
                  </App>
                </Switch>
              </PerfectScrollbar>
            </LoginProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </ChakraProvider>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
