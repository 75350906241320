import {
  Checkbox,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import Axios from "../../../services/axios";
import { warehouseAction } from "../../../store/actions/warehouseAction";
import ConfirmationModal from "./ConfirmationModal";

function AddAdmin({
  isOpen,
  onClose,
  inputAdmin,
  setInputAdmin,
  emailUser,
  setEmailUser,
  submitType,
  fetchData,
}) {
  const dispatch = useDispatch();
  const { warehouses } = useSelector((state) => state.warehouse);
  const {
    isOpen: isOpenConfirmation,
    onClose: onCloseConfirmation,
    onOpen: onOpenConfirmation,
  } = useDisclosure();
  const [roleOption, setRoleOption] = useState([]);
  const [initRestrict, setInitRestrict] = useState("false");
  const toast = useToast();

  const getRoleData = async () => {
    try {
      const { data } = await Axios({
        url: "/admin/role/list",
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      setRoleOption(data.data.roles);
    } catch (err) {
      console.log(err.response);
    }
  };
  useEffect(() => {
    getRoleData();
    dispatch(warehouseAction());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (inputAdmin.isSuspended === true) {
      if (inputAdmin.suspensionType === "permanent") {
        setInitRestrict("permanent");
      } else {
        setInitRestrict("temporary");
      }
    } else {
      setInitRestrict("false");
    }
  }, [inputAdmin]);

  const handleClose = () => {
    let tempInput = { ...inputAdmin };
    Object.keys(tempInput).forEach((key) => {
      if (key === "isLdap" || key === "isSuspended") {
        tempInput[key] = false;
      } else if (key === "isActive") {
        tempInput[key] = true;
      } else {
        tempInput[key] = "";
      }
    });
    setEmailUser("");
    setInputAdmin(tempInput);
    onClose();
  };

  const handleChangeInput = (e) => {
    const { name } = e.target;
    let tempInput = { ...inputAdmin };
    if (name === "isLdap") {
      tempInput[name] = e.target.checked;
    } else if (name === "isSuspended") {
      tempInput["suspendOption"] = e.target.value;
      if (e.target.value === "false") {
        tempInput[name] = false;
        tempInput["suspensionType"] = null;
      } else if (e.target.value === "temporary") {
        tempInput[name] = true;
        tempInput["suspensionType"] = "temporary";
      } else {
        tempInput[name] = true;
        tempInput["suspensionType"] = "permanent";
      }
    } else {
      tempInput[name] = e.target.value;
    }
    setInputAdmin(tempInput);
  };

  const submitValidation = () => {
    let isValid = {
      status: "success",
      message: `Berhasil ${
        submitType === "add" ? "menambahkah" : "mengubah"
      } user admin`,
    };

    if (!inputAdmin.email) {
      isValid.status = "warning";
      isValid.message = "Mohon mengisi email terlebih dahulu";
    } else if (!inputAdmin.username) {
      isValid.status = "warning";
      isValid.message = "Mohon mengisi username terlebih dahulu";
    } else if (!inputAdmin.workingArea) {
      isValid.status = "warning";
      isValid.message = "Mohon mengisi area kerja terlebih dahulu";
    } else if (!inputAdmin.adminRoleId) {
      isValid.status = "warning";
      isValid.message = "Mohon mengisi role terlebih dahulu";
    } else if (
      submitType === "add" &&
      !inputAdmin.isLdap &&
      !inputAdmin.password
    ) {
      isValid.status = "warning";
      isValid.message = "Mohon mengisi password terlebih dahulu";
    } else if (
      inputAdmin.workingArea === "Distribution Center" &&
      !inputAdmin.warehouseId
    ) {
      isValid.status = "warning";
      isValid.message = "Mohon mengisi warehouse terlebih dahulu";
    }

    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = submitValidation();

    if (isValid.status !== "warning") {
      try {
        if (submitType === "add") {
          await Axios({
            url: "/admin/admin-management",
            data: inputAdmin,
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });
        } else {
          const editData = {
            adminRoleId: inputAdmin.adminRoleId,
            workingArea: inputAdmin.workingArea,
            isActive: inputAdmin.isActive,
            isLdap: inputAdmin.isLdap,
            isSuspended: inputAdmin.isSuspended,
            suspensionType: inputAdmin.suspensionType,
          };

          if (inputAdmin.prevUsername !== inputAdmin.username) {
            editData.username = inputAdmin.username;
          }

          delete editData.prevUsername;

          if (!inputAdmin.isLdap && inputAdmin.password) {
            editData.password = inputAdmin.password;
          }

          if (inputAdmin.warehouseId) {
            editData.warehouseId = inputAdmin.warehouseId;
          }

          await Axios({
            url: `/admin/admin-management/${emailUser}`,
            data: editData,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });
        }
        fetchData();
        toast({
          title: "Success.",
          description: isValid.message,
          status: isValid.status,
          position: "top",
          duration: 1500,
          isClosable: true,
        });
        handleClose();
      } catch (err) {
        const { data } = err.response;
        toast({
          title: "Warning.",
          description: data.errors[0].message,
          status: "warning",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Warning.",
        description: isValid.message,
        status: isValid.status,
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  const handleEditSubmit = () => {
    if (inputAdmin.password) {
      onOpenConfirmation();
    } else {
      handleSubmit();
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent maxW={"1000px"}>
        <Text className="modal-title">
          {submitType === "add" ? "Create New Admin" : "Edit Admin"}
        </Text>
        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3">
            <Text className="modal-label">Username *</Text>
          </Col>
          <Col md="6">
            <Input
              name="username"
              placeholder="Write username here"
              value={inputAdmin.username}
              onChange={(event) => handleChangeInput(event)}
            />
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3">
            <Text className="modal-label">Email *</Text>
          </Col>
          <Col md="6">
            <Input
              name="email"
              placeholder="Write or select email here"
              value={inputAdmin.email}
              onChange={(event) => handleChangeInput(event)}
              disabled={submitType === "edit"}
            />
          </Col>
        </Row>
        {submitType === "add" && (
          <Row style={{ alignItems: "center", marginBottom: "1em" }}>
            <Col md="3" />
            <Col md="6">
              <Checkbox
                name="isLdap"
                value={inputAdmin.isLdap}
                onChange={(event) => handleChangeInput(event)}
              >
                Connect With LDAP
              </Checkbox>
            </Col>
          </Row>
        )}
        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3">
            <Text className="modal-label">Role *</Text>
          </Col>
          <Col md="6">
            <Select
              name="adminRoleId"
              placeholder="Choose admin role"
              value={inputAdmin.adminRoleId}
              onChange={(event) => handleChangeInput(event)}
            >
              {roleOption.map((data, idx) => (
                <option key={idx} value={data.id}>
                  {data.role}
                </option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3">
            <Text className="modal-label">Working Area *</Text>
          </Col>
          <Col md="6">
            <Select
              name="workingArea"
              placeholder="Choose admin working area"
              value={inputAdmin.workingArea}
              onChange={(event) => handleChangeInput(event)}
            >
              <option value="Head Office">Head Office</option>
              <option value="Distribution Center">Distribution Center</option>
            </Select>
          </Col>
        </Row>
        {inputAdmin.workingArea === "Distribution Center" && (
          <Row style={{ alignItems: "center", marginBottom: "1em" }}>
            <Col md="3">
              <Text className="modal-label">Warehose *</Text>
            </Col>
            <Col md="6">
              <Select
                name="warehouseId"
                placeholder="Choose admin warehouse"
                value={inputAdmin.warehouseId}
                onChange={(event) => handleChangeInput(event)}
              >
                {warehouses.map((data, idx) => (
                  <option value={data.id}>{data.warehouseName}</option>
                ))}
              </Select>
            </Col>
          </Row>
        )}
        {!inputAdmin.isLdap && (
          <Row style={{ alignItems: "center", marginBottom: "1em" }}>
            <Col md="3">
              <Text className="modal-label">Create Password *</Text>
            </Col>
            <Col md="6">
              <Input
                name="password"
                placeholder="Write password here ...."
                value={inputAdmin.password}
                onChange={(event) => handleChangeInput(event)}
                type="password"
              />
            </Col>
          </Row>
        )}
        {submitType !== "add" && (
          <Row style={{ alignItems: "center", marginBottom: "1em" }}>
            <Col md="3">
              <Text className="modal-label">Restrict Account</Text>
            </Col>
            <Col md="6">
              <Select
                name="isSuspended"
                value={initRestrict}
                onChange={(event) => handleChangeInput(event)}
              >
                <option value="false">Account Active</option>
                <option value="temporary">Suspend Account 10 minutes</option>
                <option value="permanent">Suspend Account Permanent</option>
              </Select>
            </Col>
          </Row>
        )}

        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3" />
          <Col md="6">
            <Button
              className="modal-save__button"
              onClick={submitType === "add" ? handleSubmit : handleEditSubmit}
            >
              Save
            </Button>
          </Col>
        </Row>
        <ConfirmationModal
          isOpen={isOpenConfirmation}
          onClose={onCloseConfirmation}
          handleSubmit={handleSubmit}
        />
      </ModalContent>
    </Modal>
  );
}

export default AddAdmin;
