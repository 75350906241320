import {
  Flex,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Tooltip from "../../tooltip";
import "./styles/RedemptionDetailModal.css";
import dateFormat from "dateformat";
import { useDispatch } from "react-redux";
import { patchLoyaltyRedemption } from "../../../store/actions/loyaltyAction";
import { isEmpty } from "lodash";

function RedemptionDetailModal({
  isOpen,
  onClose,
  dataDetail,
  setDataDetail,
  fetchData,
  handleStatusBadge,
}) {
  const toast = useToast();
  const dispatch = useDispatch();
  const [notesStatus, setNotesStatus] = useState("good");
  const [characterCount, setCharacterCount] = useState(0);

  const handleChangeNotes = (e) => {
    let tempData = { ...dataDetail };
    tempData[e.target.name] = e.target.value;
    setDataDetail(tempData);
  };

  const handleSubmit = async () => {
    try {
      await patchLoyaltyRedemption(dispatch, {
        type: "notes",
        postData: dataDetail?.adminNote,
        id: dataDetail?.id,
      });
      toast({
        title: "Sukses.",
        description: "Berhasil menambahkan notes",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
      fetchData();
      onClose();
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    setCharacterCount(!isEmpty(dataDetail) ? dataDetail.adminNote.length : 0);
  }, [dataDetail]);

  useEffect(() => {
    if (!isEmpty(dataDetail)) {
      if (characterCount >= 144) {
        setNotesStatus("maxed");
      } else if (characterCount > 122) {
        setNotesStatus("warning");
      } else {
        setNotesStatus("good");
      }
    }
  }, [characterCount]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {!isEmpty(dataDetail) && (
        <ModalContent maxW="1000px" className="redemption-modal__container">
          <Text className="redemption-modal__title">
            Point Redemption Detail
          </Text>
          <Row>
            <Col md="6">
              <Row className="redemption-input__container">
                <Col md="6">
                  <Text className="redemption-input__label">
                    Submission Date
                  </Text>
                </Col>
                <Col md="6">
                  <Text>{dateFormat(dataDetail?.createdAt, "mm/dd/yyyy")}</Text>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Flex justify="end">
                <Text
                  className="redemption-input__status"
                  color={handleStatusBadge(dataDetail?.status).color}
                  bg={handleStatusBadge(dataDetail?.status).background}
                >
                  {dataDetail?.status}
                </Text>
              </Flex>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <Row className="redemption-input__container">
                <Col md="6">
                  <Text className="redemption-input__label">Reseller Name</Text>
                </Col>
                <Col md="6">
                  <Input disabled value={dataDetail?.user?.fullName} />
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Row className="redemption-input__container">
                <Col md="6">
                  <Text className="redemption-input__label">Reseller Id</Text>
                </Col>
                <Col md="6">
                  <Input disabled value={dataDetail?.user?.referralCode} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <Row className="redemption-input__container">
                <Col md="6">
                  <Text className="redemption-input__label">Email</Text>
                </Col>
                <Col md="6">
                  <Input disabled value={dataDetail?.email} />
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Row className="redemption-input__container">
                <Col md="6">
                  <Text className="redemption-input__label">Phone</Text>
                </Col>
                <Col md="6">
                  <Input disabled value={dataDetail?.phone} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Box className="redemption-input__divider" />

          <Row>
            <Col md="4">
              <Row className="redemption-input__container">
                <Col md="6">
                  <Text className="redemption-input__label">Reward</Text>
                </Col>
                <Col md="6">
                  <Input disabled value={dataDetail?.loyaltyReward?.point} />
                </Col>
              </Row>
            </Col>
            <Col md="4">
              <Row className="redemption-input__container">
                <Col md="6">
                  <Text className="redemption-input__label">Kuantitas</Text>
                </Col>
                <Col md="6">
                  <Input disabled value={dataDetail?.qty} />
                </Col>
              </Row>
            </Col>
            <Col md="4">
              <Row className="redemption-input__container">
                <Col md="6">
                  <Text className="redemption-input__label">
                    Point Redeemed
                  </Text>
                </Col>
                <Col md="6">
                  <Input disabled value={dataDetail?.loyaltyReward?.point * dataDetail?.qty} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="redemption-input__container">
            <Col md="3">
              <Text className="redemption-input__label">Address</Text>
            </Col>
            <Col md="9">
              <Input disabled value={dataDetail?.destinationAddress} />
            </Col>
          </Row>

          <Box className="redemption-input__divider" />

          <Row className="redemption-input__container">
            <Col
              md="3"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text className="redemption-input__label">Notes</Text>
              <Box cursor="pointer">
                <Tooltip
                  content={
                    "Pesan yang dituliskan pada bagian note ini akan muncul pada catatan pengajuan di sisi reseller"
                  }
                />
              </Box>
            </Col>
            <Col md="9">
              <Textarea
                name="adminNote"
                value={dataDetail?.adminNote}
                onChange={(e) => handleChangeNotes(e)}
                maxLength={144}
                focusBorderColor={notesStatus === "maxed" && "#cc0019"}
                isInvalid={notesStatus === "maxed" ? true : false}
              />
              <Flex justify="end">
                <Text>
                  <span
                    style={
                      notesStatus === "warning"
                        ? { color: "#997000" }
                        : notesStatus === "maxed"
                        ? { color: "#990013" }
                        : { color: "#4c4c4c" }
                    }
                  >
                    {characterCount}
                  </span>
                  /144
                </Text>
              </Flex>
            </Col>
          </Row>

          <Row>
            <Col md="4" />
            <Col md="4">
              <Button
                width="100%"
                bg="#ff8084"
                color="#ffffff"
                disabled={!dataDetail?.adminNote}
                onClick={handleSubmit}
              >
                Submit Note
              </Button>
            </Col>
          </Row>
        </ModalContent>
      )}
    </Modal>
  );
}

export default RedemptionDetailModal;
