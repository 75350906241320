import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import stockReducer from "./reducers/stockReducer";
import provinceReducer from "./reducers/provinceReducer";
import cityReducer from "./reducers/cityReducer";
import warehouseReducer from "./reducers/warehouseReducer";
import orderListReducer from "./reducers/order-list";
import promoReducer from "./reducers/promoReducer";
import growthPointReducer from "./reducers/growthPointReducer";
import productShowcaseReducer from "./reducers/productShowcaseReducer";
import productReviewReducer from "./reducers/productReviewReducer";
import commissionReducers from "./reducers/commissionReducers";
import districtReducer from "./reducers/districtReducer";
import urbanReducer from "./reducers/urbanReducer";
import userReducer from "./reducers/userReducer";
import faqsReducer from "./reducers/faqsReducer";
import loyaltyReducer from "./reducers/loyaltyReducers";
import sidebarReducer from "./reducers/sideBarReducer";
import resellerLevelReducer from "./reducers/resellerLevelReducers";

const reducers = combineReducers({
  stock: stockReducer,
  provinces: provinceReducer,
  cities: cityReducer,
  districts: districtReducer,
  urbans: urbanReducer,
  warehouse: warehouseReducer,
  orders: orderListReducer,
  promos: promoReducer,
  growthPoint: growthPointReducer,
  productShowcase: productShowcaseReducer,
  productReview: productReviewReducer,
  commission: commissionReducers,
  users: userReducer,
  faqs: faqsReducer,
  loyalty: loyaltyReducer,
  sidebar: sidebarReducer,
  resellerLevel: resellerLevelReducer,
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;
