import { Flex, Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { months } from "./const";

function FilterDate({ isPayoutPage, isDetailCommission, id }) {
  const today = new Date();
  const history = useHistory();
  const { search } = useLocation();
  const [month, setMonth] = useState(today.getMonth() + 1);
  const [year, setYear] = useState(today.getFullYear());
  const [monthOptions, setMonthOptions] = useState([]);

  useEffect(() => {
    let tempOptions;
    if (parseInt(year) === today.getFullYear()) {
      tempOptions = months.filter((_, idx) => idx < today.getMonth() + 1);
    } else {
      tempOptions = months;
    }
    setMonthOptions(tempOptions);
  }, [year]);

  const changeDateHandler = (event) => {
    const currentQuery = queryString.parse(search);
    let query;
    if (event.target.name === "month") {
      setMonth(event.target.value);
      query = queryString.stringify({
        ...currentQuery,
        month: event.target.value,
      });
    } else if (event.target.name === "year") {
      setYear(event.target.value);
      if (parseInt(event.target.value) === today.getFullYear()) {
        setMonth(today.getMonth() + 1);
      }
      query = queryString.stringify({
        ...currentQuery,
        year: event.target.value,
        month:
          parseInt(event.target.value) === today.getFullYear()
            ? today.getMonth() + 1
            : month,
      });
    }

    if (isPayoutPage) {
      history.push(`/commission/list-payout?${query}`);
    } else if (isDetailCommission) {
      history.push(`/commission/detail-commission/${id}?${query}`);
    } else {
      history.push(`/commission/list-commission?${query}`);
    }
  };

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (currentQuery.month) setMonth(currentQuery.month);
    if (currentQuery.year) setYear(currentQuery.year);
  }, [search]);

  return (
    <Flex mt="1rem">
      <Select
        disabled={isPayoutPage}
        width="120px"
        mr="1rem"
        value={year}
        name="year"
        onChange={(event) => changeDateHandler(event)}
      >
        <option value={today.getFullYear() - 5}>
          {today.getFullYear() - 5}
        </option>
        <option value={today.getFullYear() - 4}>
          {today.getFullYear() - 4}
        </option>
        <option value={today.getFullYear() - 3}>
          {today.getFullYear() - 3}
        </option>
        <option value={today.getFullYear() - 2}>
          {today.getFullYear() - 2}
        </option>
        <option value={today.getFullYear() - 1}>
          {today.getFullYear() - 1}
        </option>
        <option value={today.getFullYear()}>{today.getFullYear()}</option>
      </Select>
      <Select
        disabled={isPayoutPage}
        width="180px"
        value={month}
        onChange={(event) => changeDateHandler(event)}
        name="month"
      >
        {monthOptions.map((month, id) => {
          return (
            <option value={id + 1} key={id}>
              {month}
            </option>
          );
        })}
      </Select>
    </Flex>
  );
}

export default FilterDate;
