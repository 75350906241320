import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  InputGroup,
  Input,
  Text,
  Button,
  Center,
  Grid,
  GridItem,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyGpUser,
  postGrowthPoint,
  setUserByReferral,
} from "../../../store/actions/growthPointAction";
import { isEmpty } from "lodash";

const AddPointModal = ({
  isOpen,
  onClose,
  isFromDetail,
  gpTotal,
  fetchDataLog,
  fetchData,
}) => {
  const toast = useToast();
  const [amount, setAmount] = useState(0);
  const [pointInfo, setPointInfo] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [refCode, setRefCode] = useState("");
  const dispatch = useDispatch();
  const { gpUser } = useSelector(({ growthPoint }) => growthPoint);

  useEffect(() => {
    if (isFromDetail) {
      setRefCode(gpTotal.referralCode);
      setEmail(gpTotal.email);
      setPhone(gpTotal.phone);
    } else if (!isEmpty(gpUser)) {
      setRefCode(gpUser.referralCode);
      setEmail(gpUser.email);
      setPhone(gpUser.phone);
    }
  }, [gpUser, gpTotal, isFromDetail]);

  const onHandleAmount = (amt) => {
    setAmount(amt);
  };

  const onValidation = () => {
    if (pointInfo === "") {
      setError("Point Info masih Kosong");
      return false;
    } else if (amount === "") {
      setError("Amount masih Kosong");
      return false;
    } else if (gpUser.id === "") {
      setError("LP User ID masih Kosong");
      return false;
    } else {
      setError("");
      return true;
    }
  };

  const onHandleSubmit = async () => {
    const value = {
      userId: gpUser?.id,
      pointAmount: parseInt(amount),
      pointType: "collection",
      pointInfo: pointInfo,
    };

    try {
      const isValid = onValidation();
      if (!isValid) {
        throw "Input is not Valid";
      }

      await postGrowthPoint(dispatch, value);
      if (isFromDetail) {
        fetchDataLog();
        setAmount("");
        setPointInfo("");
      } else {
        fetchData();
        setRefCode("");
        setEmail("");
        setPhone("");
        setAmount("");
        setPointInfo("");
      }
      toast({
        title: "Success.",
        description: "Berhasil Add Point",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });

      onClose();
    } catch (err) {
      toast({
        title: "Error.",
        description: "Gagal Add Point",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const onHandleSyncUser = async (refCode) => {
    try {
      const isGpUserExist = await setUserByReferral(dispatch, refCode);
      if (!isGpUserExist) {
        toast({
          title: "Error.",
          description: "Referral code tidak sesuai",
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.error("Error add point modal: ", err);
    }
  };

  const onHandleCloseModal = () => {
    if (!isFromDetail) {
      emptyGpUser(dispatch);

      setRefCode("");
      setEmail("");
      setPhone("");
      setAmount("");
      setPointInfo("");
    }
    onClose();
  };

  useEffect(() => {
    let tempAmount = 0;
    setAmount(tempAmount);
  }, []);

  const disableButton = (value) => {
    return !value || !amount || !pointInfo || !refCode;
  };

  return (
    <Modal isOpen={isOpen} onClose={onHandleCloseModal} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Add point for{" "}
          {isFromDetail
            ? `${gpTotal.firstName} ${gpTotal.lastName}`
            : !isEmpty(gpUser) && gpUser.name}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <Grid templateColumns="repeat(5, 1fr)" gap={2}>
              <GridItem colSpan={2} h="10">
                <Text>Referral Code</Text>
              </GridItem>
              <GridItem colStart={3} colEnd={6} h="10">
                <Flex align="center" alignContent="center">
                  <InputGroup flex={1}>
                    <Input
                      type="text"
                      placeholder="Ref. Code"
                      onChange={(e) => setRefCode(e.target.value.toUpperCase())}
                      value={refCode}
                      isDisabled={!isEmpty(gpUser?.id)}
                    />
                  </InputGroup>
                  <Button
                    colorScheme="teal"
                    size="xs"
                    m={1}
                    onClick={() => onHandleSyncUser(refCode)}
                    isDisabled={!isEmpty(gpUser?.id) || !refCode}
                  >
                    Sync Get User
                  </Button>
                </Flex>
              </GridItem>

              <GridItem colSpan={2} h="10">
                <Text>Email</Text>
              </GridItem>
              <GridItem colStart={3} colEnd={6} h="10">
                <InputGroup flex={1}>
                  <Input
                    type="email"
                    value={email}
                    placeholder="email"
                    onChange={(e) => setEmail(e.target.value)}
                    isDisabled
                  />
                </InputGroup>
              </GridItem>
              <GridItem colSpan={2} h="10">
                <Text>Phone</Text>
              </GridItem>
              <GridItem colStart={3} colEnd={6} h="10">
                <InputGroup flex={1}>
                  <Input
                    type="tel"
                    placeholder="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    isDisabled
                  />
                </InputGroup>
              </GridItem>
              <GridItem colSpan={2} h="10">
                <Text textAlign={"left"}>Point Type</Text>
              </GridItem>
              <GridItem colStart={3} colEnd={6} h="10">
                <InputGroup flex={1}>
                  <Input
                    type="email"
                    value="Admin Entry - Collection"
                    placeholder="point_type"
                    isDisabled
                  />
                </InputGroup>
              </GridItem>
              <GridItem colSpan={2} h="10">
                <Text>Point Info</Text>
              </GridItem>
              <GridItem colStart={3} colEnd={6} h="10">
                <InputGroup flex={1}>
                  <Input
                    type="tel"
                    placeholder="set point info..."
                    onChange={(e) => setPointInfo(e.target.value)}
                    value={pointInfo}
                  />
                </InputGroup>
              </GridItem>
              <GridItem colSpan={2} h="10">
                <Text>Amount</Text>
              </GridItem>
              <GridItem colStart={3} colEnd={6} h="10">
                <InputGroup flex={1}>
                  <Input
                    type="number"
                    placeholder="enter amount..."
                    value={amount}
                    onChange={(e) => onHandleAmount(e.target.value)}
                    isDisabled={!refCode}
                  />
                </InputGroup>
              </GridItem>
            </Grid>
            {error ? 
              <Center pt={3}>
                <Text color={"red"}>{error}</Text>
              </Center>
            : null}
            <Center>
              <Button
                colorScheme="blue"
                m={4}
                onClick={onHandleSubmit}
                isDisabled={disableButton(email) && disableButton(phone)}
              >
                Add Point
              </Button>
            </Center>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddPointModal;
