import { Box, Input, useToast } from "@chakra-ui/react";
import React, { useCallback, useRef, useState } from "react";
import one from "../../../../assets/images/pro3/1.jpg";
import Axios from "../../../../services/axios";
import { cloneDeep } from "lodash";

function ProuctImageUpload({ data, inputData, setInputData, idx }) {
  const imgRef = useRef();
  const maxImageSize = 20000000;
  const toast = useToast();

  const handleUploadImage = async (value, idx) => {
    try {
      value.preventDefault();
      if (value.currentTarget.files.length > 0) {
        if (value.currentTarget.files.item(0).size > maxImageSize) {
          toast({
            title: "Error.",
            description: "Mohon upload gambar berukuran kurang dari 2MB",
            status: "warning",
            position: "top",
            duration: 3000,
            isClosable: true,
          });

          value.currentTarget.value = null;
        } else {
          const tempImage = cloneDeep(inputData);
          const image = value.target.files[0];
          let fileData = new FormData();
          fileData.append("image", image);
          const { data } = await Axios({
            url: "/product-media",
            method: "POST",
            data: fileData,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });
          toast({
            title: "Success.",
            description: "Product Image berhasil di upload",
            status: "success",
            position: "top",
            duration: 2000,
            isClosable: true,
          });
          tempImage.campaignProducts[idx].img = data.data.productMedia.imageUrl;
          setInputData(tempImage);
        }
      }
    } catch (err) {
      console.error(err);
      toast({
        title: "Warning.",
        description: "Product image tidak berhasil di upload",
        status: "warning",
        position: "top",
        duration: 2000,
        isClosable: true,
      });
    }
  };
  return (
    <Box className="box-input-file-full" height={"200px"}>
      <Input
        type="file"
        accept="image/*"
        className="upload"
        ref={imgRef}
        cursor="pointer"
        display="none"
        onChange={(e) => handleUploadImage(e, idx)}
      />
      <img
        alt="campaign-product"
        src={data.img || one}
        onClick={() => imgRef.current.click()}
        style={{ cursor: "pointer", width: "100%", height: "100%" }}
      />
    </Box>
  );
}

export default ProuctImageUpload;
