import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import Loader from "../loader/Loader";
import "./styles/Categories.scss";

import { Col, Container, Row } from "reactstrap";
import { useToast } from "@chakra-ui/react";
import AddCategory from "./component/AddCategory";
import iconDefault from "../../assets/images/icon_default.png";
import imageDefault from "../../assets/images/image_default.png";
import { isEmpty } from "lodash";
import Axios from "../../services/axios";
import navguard from "../auth/navguard";

const EditCategory = () => {
  const imageMaxSize = 1500000;
  const { category: categoryParameter } = useParams();
  const { subCategory: subCategoryParameter } = useParams();
  const { groupCategory: groupCategoryParameter } = useParams();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [categoryObj, setCategoryObj] = useState();

  const [listGroupCategory, setListGroupCategory] = useState([]);
  const [listCategory, setListCategory] = useState([]);
  const [listSubCategory, setListSubCategory] = useState([]);

  const reloadListSubCategory = async () => {
    let listSubCategory = [];
    let result;
    for (let i = 0; i < listCategory.length; i += 1) {
      try {
        result = await Axios({
          method: "GET",
          url: `/category/${listCategory[i].name}/subcategory/list`,
        });
        result.data.data.subCategories.forEach((subCategory) => {
          listSubCategory.push(subCategory);
        });
      } catch (e) {
        console.error(e);
      }
    }

    setListSubCategory(listSubCategory);
  };

  const reloadListCategory = async () => {
    try {
      let result = await Axios({
        method: "GET",
        url: "/category/list",
      });
      setListCategory(result.data.data.categories);
    } catch (e) {
      setListCategory([]);
    }
  };

  const reloadListGroupCategory = async () => {
    try {
      let result = await Axios({
        method: "GET",
        url: `/category-group`,
      });
      setListGroupCategory(result.data.data.categoryGroups);
    } catch (e) {
      setListGroupCategory([]);
    }
  };

  useEffect(() => {
    reloadListGroupCategory();
    reloadListCategory();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await reloadListSubCategory();
    };

    fetchData();
  }, [listCategory]);

  useEffect(() => {
    if (categoryParameter) {
      if (listCategory.length) {
        let tempCategory = categoryParameter.split("-").join(" ");
        let selectedCategory = listCategory.find(
          (item) => item.name === tempCategory
        );

        setTitle("Edit Category");
        let parentGroupCategory = selectedCategory?.categoryGroup.id;
        setCategoryObj({
          prevParentCategory: parentGroupCategory,
          parentGroupCategory,
          type: "Category",
          categoryName: tempCategory,
          prevCategoryName: tempCategory,
          image: selectedCategory?.iconPath,
          description: selectedCategory?.description,
          iconActive: selectedCategory?.iconActivePath,
          banner: selectedCategory?.bannerPath,
        });
      }
    } else if (subCategoryParameter) {
      let tempSubCategory = subCategoryParameter.split("-").join(" ");
      setTitle("Edit Sub Category");
      if (listSubCategory.length) {
        let selectedSubCategory = listSubCategory.find(
          (item) => item.name === tempSubCategory
        );
        let parentCategory = selectedSubCategory?.categoryName;
        setCategoryObj({
          prevParentCategory: parentCategory,
          parentCategory,
          type: "Sub Category",
          subCategoryName: tempSubCategory,
          prevSubCategoryName: tempSubCategory,
          image: selectedSubCategory?.iconPath,
          iconActive: selectedSubCategory?.iconActivePath,
          banner: selectedSubCategory?.bannerPath,
          description: selectedSubCategory?.description,
          prevDescription: selectedSubCategory?.description,
        });
      }
    } else {
      let tempGroupCategory = groupCategoryParameter.split("-").join(" ");
      setTitle("Edit Group Category");
      if (listGroupCategory.length) {
        let selectedGroupCategory = listGroupCategory.find(
          (item) => item.name === tempGroupCategory
        );
        setCategoryObj({
          type: "Group Category",
          groupCategoryName: tempGroupCategory,
          prevGroupCategoryName: tempGroupCategory,
          groupCategoryId: selectedGroupCategory?.id,
          image: selectedGroupCategory?.iconPath,
          banner: selectedGroupCategory?.bannerPath,
          description: selectedGroupCategory?.description,
          iconActive: selectedGroupCategory?.iconActivePath,
        });
      }
    }
  }, [categoryParameter, subCategoryParameter, listSubCategory, listCategory, listGroupCategory]);

  const changeHandler = (e) => {
    let temp = { ...categoryObj };
    if (e.target.name === "type") {
      delete temp["groupCategoryName"];
      delete temp["categoryName"];
      delete temp["subCategoryName"];
    }
    temp[e.target.name] = e.target.value;

    setCategoryObj(temp);
  };

  const submitValidation = () => {
    let statusValidation = {
      status: "success",
      message: `${categoryObj.type} berhasil ditambahkan`,
    };

    if (categoryObj.type === "Category") {
      if (!categoryObj.categoryName) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon isi nama kategori";
      } else if (!categoryObj.description) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon isi description kategori";
      } else if (!categoryObj.parentGroupCategory) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon pilih parent kategori grup";
      } else if (
        !categoryObj.savedBanner &&
        categoryObj.banner === imageDefault
      ) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon upload banner";
      } else if (categoryObj.image === iconDefault) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload icon";
      } else if (categoryObj.iconActive === iconDefault) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload active icon";
      }
    } else if (categoryObj.type === "Sub Category") {
      if (!categoryObj.subCategoryName) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon isi nama sub kategori";
      } else if (!categoryObj.description) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon isi deskripsi sub kategori";
      } else if (!categoryObj.parentCategory) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon pilih parent kategori";
      } else if (
        !categoryObj.savedBanner &&
        categoryObj.banner === imageDefault
      ) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon upload banner";
      } else if (categoryObj.image === iconDefault) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload icon";
      } else if (categoryObj.iconActive === iconDefault) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload active icon";
      }
    } else if (categoryObj.type === "Category Group") {
      if (!categoryObj.groupCategoryName) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon isi nama kategori grup";
      } else if (!categoryObj.description) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon isi deskripsi kategori grup";
      } else if (
        !categoryObj.savedBanner &&
        categoryObj.banner === imageDefault
      ) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon upload banner";
      } else if (categoryObj.image === iconDefault) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload icon";
      } else if (categoryObj.iconActive === iconDefault) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload active icon";
      }
    }

    return statusValidation;
  };

  const updateGroupCategory = async () => {
    if (categoryObj.savedImage) {
      try {
        let data = new FormData();

        data.append("image", categoryObj.savedImage);

        let config = {
          method: "PATCH",
          url: `/category-group/${categoryObj.groupCategoryId}/icon`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);
      } catch (error) {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    if (categoryObj.savedActiveImage) {
      try {
        let dataActiveIcon = new FormData();

        dataActiveIcon.append("image", categoryObj.savedActiveImage);

        let config = {
          method: "PATCH",
          url: `/category-group/${categoryObj.groupCategoryId}/icon-active`,
          data: dataActiveIcon,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);
      } catch (error) {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    if (categoryObj.savedBanner) {
      try {
        let banner = new FormData();
        banner.append("image", categoryObj.savedBanner);

        let configBanner = {
          method: "PATCH",
          url: `/category-group/${categoryObj.groupCategoryId}/banner`,
          data: banner,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(configBanner);
      } catch (error) {
        console.error(error);
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    Axios({
      method: "PATCH",
      url: `/category-group/${categoryObj.groupCategoryId}`,
      data: {
        description: categoryObj.description,
        name: categoryObj.groupCategoryName,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    })
      .then((result) => {
        if (!result.data.errorStatus) {
          toast({
            title: "Sukses.",
            description: `"${categoryObj.prevGroupCategoryName}" berhasil diupdate"`,
            status: "success",
            position: "top",
            duration: 2000,
            isClosable: true,
          });
          setCategoryObj({
            type: "Category",
          });
          reloadListCategory();
          setIsLoading(false);

          setTimeout(() => {
            window.location.href = "/products/categories";
          }, 500);
        } else {
          toast({
            title: "Error.",
            description: result.data.errors[0].message,
            status: "error",
            position: "top",
            duration: 2000,
            isClosable: true,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 2000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  }

  const updateCategory = async () => {
    if (categoryObj.savedImage) {
      try {
        let data = new FormData();
        data.append("image", categoryObj.savedImage);

        let config = {
          method: "POST",
          url: `/category/${categoryObj.prevCategoryName}/icon`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);
      } catch (error) {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    if (categoryObj.savedActiveImage) {
      try {
        let dataActiveIcon = new FormData();

        dataActiveIcon.append("image", categoryObj.savedActiveImage);

        let configIconActive = {
          method: "post",
          url: `/category/${categoryObj.prevCategoryName}/activeIcon`,
          data: dataActiveIcon,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(configIconActive);
      } catch (error) {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    if (categoryObj.savedBanner) {
      try {
        let banner = new FormData();
        banner.append("image", categoryObj.savedBanner);

        let configBanner = {
          method: "POST",
          url: `/category/${categoryObj.prevCategoryName}/banner`,
          data: banner,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(configBanner);
      } catch (error) {
        console.error(error);
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    let updatedItem = {};

    if (categoryObj.prevCategoryName !== categoryObj.categoryName) {
      updatedItem.name = categoryObj.categoryName;
    } else {
      updatedItem.name = categoryObj.prevCategoryName;
    }

    if (categoryObj.prevParentCategory !== categoryObj.parentGroupCategory) {
      updatedItem.categoryGroupId = categoryObj.parentGroupCategory;
    } else {
      updatedItem.categoryGroupId = categoryObj.prevParentCategory;
    }

    if (categoryObj.prevDescription !== categoryObj.description) {
      updatedItem.description = categoryObj.description;
    }

    if (!isEmpty(updatedItem)) {
      Axios({
        method: "PATCH",
        url: `/category/${categoryObj.prevCategoryName}`,
        data: updatedItem,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      })
        .then((result) => {
          if (!result.data.errorStatus) {
            toast({
              title: "Sukses.",
              description: `"${categoryObj.prevCategoryName}" berhasil diupdate"`,
              status: "success",
              position: "top",
              duration: 2000,
              isClosable: true,
            });
            setCategoryObj({
              type: "Category",
            });
            reloadListCategory();
            setIsLoading(false);

            setTimeout(() => {
              window.location.href = "/products/categories";
            }, 2000);
          } else {
            toast({
              title: "Error.",
              description: result.data.errors[0].message,
              status: "error",
              position: "top",
              duration: 3000,
              isClosable: true,
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          toast({
            title: "Error.",
            description: error.response.data.errors[0].message,
            status: "error",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          setIsLoading(false);
        });
    } else {
      toast({
        title: "Sukses.",
        description: `"${categoryObj.prevCategoryName} berhasil diupdate"`,
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      setCategoryObj({
        type: "Category",
      });
      reloadListCategory();
      setIsLoading(false);

      setTimeout(() => {
        window.location.href = "/products/categories";
      }, 50000);
    }
  };

  const updateSubCategory = async () => {
    if (categoryObj.savedImage) {
      try {
        let data = new FormData();
        data.append("image", categoryObj.savedImage);

        let config = {
          method: "post",
          url: `/category/${categoryObj.prevParentCategory}/subcategory/${categoryObj.prevSubCategoryName}/icon`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);
      } catch (error) {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    if (categoryObj.savedActiveImage) {
      try {
        let dataActiveIcon = new FormData();
        dataActiveIcon.append("image", categoryObj.savedActiveImage);

        let config = {
          method: "post",
          url: `/category/${categoryObj.prevParentCategory}/subcategory/${categoryObj.prevSubCategoryName}/activeIcon`,
          data: dataActiveIcon,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);
      } catch (error) {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    if (categoryObj.savedBanner) {
      try {
        let banner = new FormData();
        banner.append("image", categoryObj.savedBanner);

        let configBanner = {
          method: "post",
          url: `/category/${categoryObj.prevParentCategory}/subcategory/${categoryObj.prevSubCategoryName}/banner`,
          data: banner,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(configBanner);
      } catch (error) {
        console.error(error);
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    let updatedItem = {};

    if (categoryObj.prevSubCategoryName !== categoryObj.subCategoryName) {
      updatedItem.name = categoryObj.subCategoryName;
    }

    if (categoryObj.prevParentCategory !== categoryObj.parentCategory) {
      updatedItem.categoryName = categoryObj.parentCategory;
    }

    if (categoryObj.prevDescription !== categoryObj.description) {
      updatedItem.description = categoryObj.description;
    }

    if (!isEmpty(updatedItem)) {
      Axios({
        method: "PATCH",
        url: `/category/${categoryObj.prevParentCategory}/subcategory/${categoryObj.prevSubCategoryName}`,
        data: updatedItem,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      })
        .then((result) => {
          if (!result.data.errorStatus) {
            toast({
              title: "Sukses.",
              description: `"${categoryObj.prevSubCategoryName}" berhasil diupdate"`,
              status: "success",
              position: "top",
              duration: 2000,
              isClosable: true,
            });
            setCategoryObj({
              type: "Category",
            });
            reloadListCategory();
            setIsLoading(false);

            setTimeout(() => {
              window.location.href = "/products/categories";
            }, 500);
          } else {
            toast({
              title: "Error.",
              description: result.data.errors[0].message,
              status: "error",
              position: "top",
              duration: 3000,
              isClosable: true,
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          toast({
            title: "Error.",
            description: error.response.data.errors[0].message,
            status: "error",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          setIsLoading(false);
        });
    } else {
      toast({
        title: "Sukses.",
        description: `"${categoryObj.prevSubCategoryName} berhasil diupdate"`,
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true,
      });
      setCategoryObj({
        type: "Category",
      });
      reloadListCategory();
      setIsLoading(false);

      setTimeout(() => {
        window.location.href = "/products/categories";
      }, 500);
    }
  };

  const submitCategory = () => {
    setIsLoading(true);
    let statusValidation = submitValidation();

    if (statusValidation.status === "success") {
      if (categoryObj.type === "Category") {
        updateCategory();
      } else if (categoryObj.type === "Sub Category") {
        updateSubCategory();
      } else {
        updateGroupCategory();
      }
    } else {
      toast({
        title: "Error.",
        description: statusValidation.message,
        status: statusValidation.status,
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const deleteImage = (fileAttr, srcAttr) => {
    let inputImageElmnt = document.getElementById("icon-img");
    let inputImageElmnt2 = document.getElementById("banner-img");
    const tempCategoryObj = { ...categoryObj };
    let defaultValue = iconDefault;
    if (srcAttr === "banner") {
      defaultValue = imageDefault;
      inputImageElmnt2.value = null;
    }
    tempCategoryObj[srcAttr] = defaultValue;
    tempCategoryObj[fileAttr] = undefined;
    inputImageElmnt.value = null;
    setCategoryObj(tempCategoryObj);
  };

  const _handleImgChange = (value, fileAttr, srcAttr) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (value.currentTarget.files.item(0).size > imageMaxSize) {
        toast({
          title: "Error.",
          description: "Mohon upload gambar berukuran kurang dari 2MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        const tempCategoryObj = { ...categoryObj };
        let reader = new FileReader();
        const image = value.target.files[0];
        tempCategoryObj[fileAttr] = image;
        reader.onload = () => {
          tempCategoryObj[srcAttr] = reader.result;
          setCategoryObj(tempCategoryObj);
        };
        reader.readAsDataURL(image);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Breadcrumb title={title} parent="Products" />
      <Container fluid={true}>
        <Row>
          <Col sm="5">
            {categoryObj && (
              <AddCategory
                title={
                  categoryObj.prevCategoryName
                    ? `Edit ${categoryObj.prevCategoryName}`
                    : categoryObj.prevSubCategoryName
                      ? `Edit ${categoryObj.prevSubCategoryName}`
                      : `Edit ${categoryObj.prevGroupCategoryName}`
                }
                isEdit={true}
                listCategory={listCategory}
                listGroupCategory={listGroupCategory}
                categoryObj={categoryObj}
                changeHandler={changeHandler}
                submitCategory={submitCategory}
                _handleImgChange={_handleImgChange}
                deleteImage={deleteImage}
                setCategoryObj={setCategoryObj}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default navguard(EditCategory);
