import React, { useState } from "react";

import '../styles/_homeBanner.scss';
import {
	Col,
	Row,
} from "reactstrap";
import {
    Flex,
    Spacer,
    IconButton,
    useToast,
    Input,
    Text,
    Switch,
    Button,
    Modal,
    ModalFooter,
    ModalBody,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalContent,
} from "@chakra-ui/react";
import {
    MdDelete,
} from "react-icons/md";

import one from "../../../assets/images/pro3/1.jpg";
import Axios from "../../../services/axios";

const AddNewHighlight = ({
    setIsLoading, getHighlightList, isAddNew, setIsAddNew
}) => {
    const toast = useToast()
    const imageMaxSize = 2000000;
    const [addedBanner, setAddedBanner] = useState({
        title: '',
        subtitle: '',
        link: '',
        imagePath: one,
        isActive: false 
    })

    const handleNewBannerImageChange = (value, item) => {
        value.preventDefault()
        if (value.currentTarget.files.length > 0) {
            if (value.currentTarget.files.item(0).size > imageMaxSize) {
                toast({
                    title: "Error.",
                    description: 'Mohon upload gambar berukuran kurang dari 2MB',
                    status: "warning",
                    position: "top",
                    duration: 3000,
                    isClosable: true,
                })
                
              value.currentTarget.value = null;
            } else {
                let reader = new FileReader();
                const image = value.target.files[0];
                let tempAddedBanner = {...addedBanner};

                tempAddedBanner.fileImage = image;

                reader.onload = () => {
                    tempAddedBanner.imagePath = reader.result;
                    setAddedBanner(tempAddedBanner)
                }
        
                reader.readAsDataURL(image)
            }
        } 
    }

    const deleteImageNewBanner = (item) => {
        document.getElementById("new-image-input").value = null;
        let tempAddedBanner = {...addedBanner};
        
        tempAddedBanner.imagePath = one;
        tempAddedBanner.fileImage = undefined;
        setAddedBanner(tempAddedBanner)
    }

    const newBannerHandler = (e) => {
        let tempAddedBanner = {...addedBanner};
        if (e.target.name === 'isActive') {
            tempAddedBanner.isActive = !tempAddedBanner.isActive
        } else {
            tempAddedBanner[e.target.name] = e.target.value;
        }

        setAddedBanner(tempAddedBanner)
    }

    const submitValidation = () => {
        let statusValidation = {
            status: "success",
            message: "Banner berhasil disubmit"
        }

        if (!addedBanner.title) {
            statusValidation.status = "warning"
            statusValidation.message = "Mohon isi nama banner"
        } else if (!addedBanner.subtitle) {
            statusValidation.status = "warning"
            statusValidation.message = "Mohon isi subtitle banner"
        } else if (!addedBanner.link) {
            statusValidation.status = "warning"
            statusValidation.message = "Mohon isi link banner"
        } else if (addedBanner.imagePath === one) {
            statusValidation.status = "warning"
            statusValidation.message = "Mohon upload gambar banner"
        }

        return statusValidation
    }

    const addBanner = async() => {
        setIsLoading(true)
        let responseValidation = submitValidation();
        if (responseValidation.status === "success") {
            try {
    
                let data = new FormData()
                data.append('image', addedBanner.fileImage);
                data.append('title', addedBanner.title);
                data.append('subtitle', addedBanner.subtitle);
                data.append('link', addedBanner.link);
    
                let config = {
                    method: 'POST',
                    url: `/banner-highlight`,
                    data: data,
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    },
                };
    
                let result = await Axios(config)

                if (addedBanner.isActive) {
                    await Axios({
                        method: "PATCH",
                        url: `/banner-highlight/${result.data.data.bannerHighlight.id}/setActive`,
                        data: {
                            isActive: true
                        },
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                        },
                    })
                }
                await getHighlightList()
                toast({
                    title: "Success.",
                    description: 'Banner baru berhasil ditambahkan',
                    status: "success",
                    position: "top",
                    duration: 3000,
                    isClosable: true,
                })
                addNewClose()
                setIsLoading(false)
            } catch (error) {
                toast({
                    title: "Error.",
                    description: 'Terjadi kesalahan mohon coba lagi',
                    status: "warning",
                    position: "top",
                    duration: 3000,
                    isClosable: true,
                })
                setIsLoading(false)
            }
        } else {
            toast({
                title: "Error.",
                description: responseValidation.message,
                status: responseValidation.status,
                position: "top",
                duration: 3000,
                isClosable: true,
            })
        }

        setIsLoading(false);
    }

    const addNewClose = () => {
        setIsAddNew(false)
        setAddedBanner({
            title: '',
            subtitle: '',
            link: '',
            imagePath: one,
            isActive: false 
        })
    }
    
	return (
		<>
            <Modal onClose={addNewClose} size="full" isOpen={isAddNew}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Add New Banner</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Row style={{margin: "0px 32px"}}>
                        <Col xl={1}>
                        </Col>
                        <Col sm="4" md="4" lg="4">
                            <Text
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    marginBottom: '8px',
                                    color: 'black'
                                }}
                            >
                                Title
                            </Text>
                            <Input
                                mb={4}
                                placeholder="Title"
                                name="title"
                                onChange={(e) => newBannerHandler(e)}
                                value={addedBanner.title}
                            />
                            <Text
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    marginBottom: '8px',
                                    color: 'black'
                                }}
                            >
                                Sub Title
                            </Text>
                            <Input
                                mb={4}
                                placeholder="Sub Title"
                                name="subtitle"
                                onChange={(e) => newBannerHandler(e)}
                                value={addedBanner.subtitle}
                            />
                            <Text
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    marginBottom: '8px',
                                    color: 'black'
                                }}
                            >
                                URL
                            </Text>
                            <Input
                                mb={4}
                                placeholder="URL"
                                name="link"
                                onChange={(e) => newBannerHandler(e)}
                                value={addedBanner.link}
                            />
                            <Text
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    marginBottom: '8px',
                                    color: 'black',
                                }}
                            >
                                Active
                            </Text>
                            <Switch isChecked={addedBanner.isActive} name="isActive" onChange={(e) => newBannerHandler(e)} style={{marginTop: '12px'}} />
                
                        </Col>
                        <Col sm="6" md="6" lg="6">
                            <Flex>
                                <p
                                    style={{
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                        marginBottom: '16px',
                                        color: 'black'
                                    }}
                                >
                                    Image Preview
                                </p>
                                <Spacer />
                                {addedBanner.imagePath !== one && (
                                    <IconButton
                                        mr={4}
                                        variant="outline"
                                        aria-label="Call Sage"
                                        fontSize="20px"
                                        onClick={() => deleteImageNewBanner(addedBanner)}
                                        icon={<MdDelete />}
                                    />
                                )}
                            </Flex>
                            <Row>
                                <Col xl={12}>
                                    <div className="add-product">
                                        <Row className="file-upload-product row">
                                            <Col className="col">
                                                <div className="box-input-file-full">
                                                    <input
                                                        className="upload"
                                                        style={{
                                                            width: '100%',
                                                            aspectRatio: `16 / 9`,
                                                            objectFit: 'cover',
                                                            cursor: 'pointer',
                                                        }}
                                                        type="file"
                                                        id="new-image-input"
                                                        name="img"
                                                        accept="image/*"
                                                        onChange={(e) => handleNewBannerImageChange(e, addedBanner)}
                                                    />
                                                    <img
                                                        src={addedBanner.imagePath}
                                                        alt=""
                                                        style={{
                                                            width: '100%',
                                                            aspectRatio: `16 / 9`,
                                                            objectFit: 'cover',
                                                        }}
                                                        className="img-fluid image_zoom_1 blur-up lazyloaded"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button mr={4} onClick={addNewClose}>Close</Button>
                    <Button
                        variant="ghost"
                        justifyContent="flex-start"
                        backgroundColor={'#FF8084'}
                        color={'white'}
                        onClick={addBanner}
                    >
                        Submit
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
	);
};

export default AddNewHighlight;
