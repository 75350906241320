import { Flex, Text } from "@chakra-ui/react";
import styles from "./styles.module.css";

const FilterByUserType = ({ filterByUserType, onChangeFilter }) => (
  <Flex className={styles["filter-user__container"]}>
    <Text
      className={`${styles["filter-user__item"]} ${
        filterByUserType === "all" && styles["filter-user__active"]
      }`}
      onClick={() => onChangeFilter("all")}
    >
      Semua pembeli (100)
    </Text>
    <Text
      className={`${styles["filter-user__item"]} ${
        filterByUserType === "reseller" && styles["filter-user__active"]
      }`}
      onClick={() => onChangeFilter("reseller")}
    >
      Ulasan reseller (80)
    </Text>
    <Text
      className={`${styles["filter-user__item"]} ${
        filterByUserType === "customer" && styles["filter-user__active"]
      }`}
      onClick={() => onChangeFilter("customer")}
    >
      Ulasan pelanggan (20)
    </Text>
  </Flex>
);

export default FilterByUserType;
