import "./styles/testimony.scss";
import Axios from "../../services/axios";
import {
  DeleteIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  AddIcon,
  EditIcon,
  AttachmentIcon,
} from "@chakra-ui/icons";
import {
  Switch,
  Flex,
  Box,
  Text,
  Input,
  useToast,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useState, useEffect } from "react";
import AddModal from "./component/add-testimony";
import { Skeleton, SkeletonCircle } from "@chakra-ui/react";
import DeleteTestimony from "./component/delete-testimony";
import navguard from "../auth/navguard";

const Testimony = () => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [testimonies, setTestimonies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editData, setEditData] = useState({});
  const isEditMain = false;
  const imageMaxSize = 1000000;
  const toast = useToast();
  const addModalToggleHandler = () => setIsOpenAddModal(!isOpenAddModal);
  const [mainImageDesktop, setMainImageDesktop] = useState({});
  const [mainImageMobile, setMainImageMobile] = useState({});
  const [mainTestimony, setMainTestimony] = useState({});
  const [isOpenDeleteTestimony, setIsOpenDeleteTestimony] = useState(false);
  const [selectedDeleteTestimony, setSelectedDeleteTestimony] = useState({});

  const fetchTestimony = async ({ withLoading }) => {
    if (withLoading) {
      setIsLoading(true);
    }
    try {
      const { data } = await Axios({
        method: "GET",
        url: `/reseller-landing/testimony/list`,
      });

      setTestimonies(data.data.testimonies);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const editHandler = (testimony) => {
    addModalToggleHandler();
    setEditData(testimony);
  };

  const changeIsActiveHandler = async ({ checked, data }) => {
    const { name, occupation, city, testimonyText, id } = data;
    try {
      await Axios({
        method: "PATCH",
        url: `/reseller-landing/testimony/${id}`,
        data: {
          isActive: checked,
          name,
          occupation,
          city,
          testimonyText,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      fetchTestimony({ withLoading: false });
      toast({
        title: "Success!",
        description: "",
        status: "success",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Failed",
        description: "Gagal mengaktifkan testimony",
        status: "error",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  const deleteTestimonyHandler = async (id) => {
    setIsLoading(true);
    try {
      await Axios({
        method: "DELETE",
        url: `/reseller-landing/testimony/${id}/avatar`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      await Axios({
        method: "DELETE",
        url: `/reseller-landing/testimony/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      toast({
        title: "Success",
        description: "Berhasil menghapus",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      fetchTestimony({ withLoading: true });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast({
        title: "Failed",
        description: "Gagal menghapus",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchMainTestimony = async () => {
    try {
      const { data } = await Axios({
        method: "GET",
        url: `/reseller-landing/testimony/main`,
      });
      setMainTestimony(data.data.testimony);
      const { imageDesktopPath, imageMobilePath } = data.data.testimony;

      setMainImageDesktop({
        savedImage: imageDesktopPath,
        image: imageDesktopPath,
        isEdited: true,
      });
      setMainImageMobile({
        savedImage: imageMobilePath,
        image: imageMobilePath,
        isEdited: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTestimony({ withLoading: true });
    fetchMainTestimony();
  }, []);

  const movingHandler = async ({ state, id }) => {
    try {
      await Axios({
        method: "POST",
        url: `/reseller-landing/testimony/${id}/${state}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      toast({
        title: "Success",
        description: "",
        status: "success",
        position: "top",
        duration: 1000,
        isClosable: true,
      });

      fetchTestimony({ withLoading: false });
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "",
        status: "error",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  /// Main testimony handler
  const handleImgChange = (value, fileAttr, srcAttr, type) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (
        value.currentTarget.files.item(0).size > imageMaxSize ||
        value.currentTarget.files.item(0).type === "image/webp"
      ) {
        toast({
          title: "Error.",
          description:
            value.currentTarget.files.item(0).type === "image/webp"
              ? "File not supported"
              : "Mohon upload gambar berukuran kurang dari 1MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        const tempImg =
          type === "mobile" ? { ...mainImageMobile } : { ...mainImageDesktop };
        tempImg.isEdited = false;
        let reader = new FileReader();
        const getImage = value.target.files[0];
        tempImg[fileAttr] = getImage;
        reader.onload = () => {
          tempImg[srcAttr] = reader.result;
          if (type === "mobile") {
            setMainImageMobile(tempImg);
          } else {
            setMainImageDesktop(tempImg);
          }
        };
        reader.readAsDataURL(getImage);
      }
    }
  };

  const mainTestimonyHandler = async (id) => {
    setIsLoading(true);
    try {
      await Axios({
        method: "PATCH",
        url: `/reseller-landing/testimony/${id}/setMain`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      toast({
        title: "Success!",
        description: "Berhasil mengganti main testimony",
        position: "top",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      await fetchMainTestimony();

      fetchTestimony({ withLoading: true });
    } catch (error) {
      console.log(error);

      toast({
        title: "Gagal!",
        description: "",
        status: "warning",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  /// Main testimony handler
  const updateMainImage = async () => {
    let isEmpty = true;
    let formData = new FormData();
    if (mainImageDesktop.savedImage) {
      formData.append("imageDesktop", mainImageDesktop.savedImage);
      isEmpty = false;
    }

    if (mainImageMobile.savedImage) {
      formData.append("imageMobile", mainImageMobile.savedImage);
      isEmpty = false;
    }

    try {
      await Axios({
        method: "POST",
        url: `/reseller-landing/testimony/${mainTestimony.id}/image`,
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      toast({
        title: "Success!",
        description: "Berhasil mengganti image main testimony",
        position: "top",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: isEmpty ? "" : "Gagal!",
        description: isEmpty ? "Tidak ada image yg di upload" : "",
        status: "warning",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="testimony-container">
      <AddModal
        isOpenAddModal={isOpenAddModal}
        addModalToggleHandler={addModalToggleHandler}
        fetchTestimony={fetchTestimony}
        setIsLoading={setIsLoading}
        setEditData={setEditData}
        editData={editData}
        fetchMainTestimony={fetchMainTestimony}
      />
      <DeleteTestimony
        isOpenDeleteTestimony={isOpenDeleteTestimony}
        setIsOpenDeleteTestimony={setIsOpenDeleteTestimony}
        testimony={selectedDeleteTestimony.testimony}
        deleteHandler={deleteTestimonyHandler}
        fetchMainTestimony={fetchMainTestimony}
        isImage={selectedDeleteTestimony.isImage}
        path={selectedDeleteTestimony.path}
        imageDeleteHandler={
          selectedDeleteTestimony.isMobile
            ? () => setMainImageMobile({})
            : () => setMainImageDesktop({})
        }
      />

      <div className="list-testimony__container">
        <div className="header">
          <p className="title">List Testimony</p>
          <button className="button" onClick={addModalToggleHandler}>
            <AddIcon />
          </button>
        </div>
        <div className="testimony-card__container">
          {isLoading &&
            [1, 2, 3, 4, 5].map((item) => (
              <Flex
                height="64px"
                padding="8px"
                className="skeleton-container"
                bg="white"
                align="center"
                justify="space-between"
                mb="16px"
                key={item}>
                <SkeletonCircle size="48px" />
                <Box ml="14px">
                  <Skeleton height="16px" mb="4px" width="100px" />
                  <Skeleton height="12px" width="120px" />
                </Box>
                <Skeleton height="32px" flex={1} ml="32px" />
              </Flex>
            ))}
          {!isLoading &&
            testimonies.length > 0 &&
            testimonies.map((testimony, idx) => (
              <div className="testimony-card" key={testimony.id}>
                <div className="flex-center">
                  <img className="image" alt="img" src={testimony.avatarPath} />
                  <div className="profile">
                    <p className="name">{testimony.name}</p>
                    <p className="status">{testimony.occupation}</p>
                  </div>
                </div>
                <p className="city">{testimony.city}</p>
                <p className="desc">{testimony.testimonyText}</p>
                <div className="icon-container">
                  <EditIcon
                    color="#3b818d"
                    fontSize="18px"
                    mr="16px"
                    cursor="pointer"
                    onClick={() => editHandler(testimony)}
                  />
                  <DeleteIcon
                    color="#ff8084"
                    fontSize="18px"
                    mr="16px"
                    cursor="pointer"
                    onClick={() => {
                      setSelectedDeleteTestimony({ isImage: false, testimony });
                      setIsOpenDeleteTestimony(true);
                    }}
                  />

                  <Switch
                    margin="0"
                    size="sm"
                    mr="24px"
                    className="switch"
                    isChecked={testimony.isActive}
                    onChange={(e) =>
                      changeIsActiveHandler({
                        checked: e.target.checked,
                        data: testimony,
                      })
                    }
                  />

                  <ChevronUpIcon
                    fontSize="24px"
                    cursor={idx === 0 ? "none" : "pointer"}
                    mr="16px"
                    onClick={() =>
                      movingHandler({ state: "moveUp", id: testimony.id })
                    }
                    opacity={idx === 0 ? 0 : 1}
                  />

                  <ChevronDownIcon
                    fontSize="24px"
                    cursor="pointer"
                    mr="24px"
                    onClick={() =>
                      movingHandler({ state: "moveDown", id: testimony.id })
                    }
                  />
                  <Checkbox
                    mr="16px"
                    size="lg"
                    mb="0"
                    cursor="pointer"
                    onChange={() => mainTestimonyHandler(testimony.id)}
                    isChecked={mainTestimony.id === testimony.id}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* <div className="main-testimony__container">
        <div className="header">
          <p className="title">Main Testimony</p>
          <Button
            fontSize="12px"
            mr="8px"
            color="white"
            bg="#ff8084"
            letterSpacing="0"
            _hover={{
              background: "#ff8084",
            }}
            height="36px"
            onClick={updateMainImage}
          >
            Update
          </Button>
        </div>
        <Flex mt="24px" direction="column">
          <Box width="420px">
            {!isEmpty(mainTestimony) && (
              <Box className="main-testimony__card">
                <Flex align="center">
                  <img
                    src={mainTestimony.avatarPath}
                    alt="avatar-img"
                    className="avatar-image__main"
                  />
                  <Flex ml="16px" direction="column">
                    <Text className="name-text">{mainTestimony.name}</Text>
                    <Flex align="center">
                      <Text className="status-text">
                        {mainTestimony.occupation},
                      </Text>
                      <Text className="city-text">{mainTestimony.city}</Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Text className="desc-text">{mainTestimony.testimonyText}</Text>
              </Box>
            )}
          </Box>
          <Flex mt="32px">
            <Flex width="330px" direction="column" mr="64px">
              <Text
                letterSpacing="0"
                fontSize="16px"
                mb="16px"
                fontWeight="600"
                color="#1a1a1a"
              >
                Main Image Testimony Mobile
              </Text>
              {mainImageMobile.image ? (
                <Box position="relative">
                  <DeleteIcon
                    color="#ff8084"
                    fontSize="22px"
                    mr="16px"
                    cursor="pointer"
                    position="absolute"
                    top="0"
                    right="-46px"
                    onClick={() => {
                      setSelectedDeleteTestimony({
                        isImage: true,
                        path: mainImageMobile.image,
                        isMobile: true,
                      });
                      setIsOpenDeleteTestimony(true);
                    }}
                  />
                  <Flex
                    width="100%"
                    border="4px dashed #efefef"
                    borderRadius="8px"
                    padding="8px"
                  >
                    <img
                      src={mainImageMobile.image}
                      alt="main-image__testi"
                      className="main-image"
                      style={{
                        width: "330px",
                        height: "330px",
                        objectFit: "cover",
                      }}
                    />
                  </Flex>
                </Box>
              ) : (
                <Flex
                  width="330px"
                  height="330px"
                  border="4px dashed #efefef"
                  borderRadius="8px"
                  justify="center"
                  align="center"
                  direction="column"
                  cursor={isEditMain ? "pointer" : "not-allowed"}
                  position="relative"
                >
                  <Text
                    letterSpacing="0"
                    fontSize="14px"
                    mb="4px"
                    color="#1a1a1a"
                  >
                    Select Image
                  </Text>
                  <AttachmentIcon fontSize="20px" />
                  <Input
                    type="file"
                    id="icon-img"
                    name="img"
                    accept="image/*"
                    height="100% !important"
                    width="100% !important"
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      opacity: 0,
                      zIndex: 1,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      top: 0,
                    }}
                    onChange={(e) =>
                      handleImgChange(e, "savedImage", "image", "mobile")
                    }
                  />
                </Flex>
              )}
            </Flex>

            <Flex width="330px" direction="column" mr="64px">
              <Text
                letterSpacing="0"
                fontSize="16px"
                mb="16px"
                fontWeight="600"
                color="#1a1a1a"
              >
                Main Image Testimony Desktop
              </Text>
              {mainImageDesktop.image ? (
                <Box position="relative">
                  <DeleteIcon
                    color="#ff8084"
                    fontSize="22px"
                    mr="16px"
                    cursor="pointer"
                    position="absolute"
                    top="0"
                    right="-46px"
                    onClick={() => {
                      setSelectedDeleteTestimony({
                        isImage: true,
                        path: mainImageMobile.image,
                        isMobile: false,
                      });
                      setIsOpenDeleteTestimony(true);
                    }}
                  />
                  <Flex
                    width="100%"
                    border="4px dashed #efefef"
                    borderRadius="8px"
                    padding="8px"
                  >
                    <img
                      src={mainImageDesktop.image}
                      alt="main-image__testi"
                      className="main-image"
                      style={{
                        width: "100%",
                        height: "516px",
                        objectFit: "cover",
                      }}
                    />
                  </Flex>
                </Box>
              ) : (
                <Flex
                  width="100%"
                  border="4px dashed #efefef"
                  borderRadius="8px"
                  height="516px"
                  justify="center"
                  align="center"
                  direction="column"
                  cursor={isEditMain ? "pointer" : "not-allowed"}
                  position="relative"
                >
                  <Text
                    letterSpacing="0"
                    fontSize="14px"
                    mb="4px"
                    color="#1a1a1a"
                  >
                    Select Image
                  </Text>
                  <AttachmentIcon fontSize="20px" />
                  <Input
                    type="file"
                    id="icon-img"
                    name="img"
                    accept="image/*"
                    height="100% !important"
                    width="100% !important"
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      opacity: 0,
                      zIndex: 1,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      top: 0,
                    }}
                    onChange={(e) =>
                      handleImgChange(e, "savedImage", "image", "desktop")
                    }
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </div> */}
    </div>
  );
};

export default navguard(Testimony);
