import {
  SET_WAREHOUSE,
  SET_WAREHOUSE_DETAIL,
  SET_WAREHOUSE_PRODUCT_INCLUDE,
  SET_WAREHOUSE_PRODUCT_EXCLUDE,
  SET_WAREHOUSE_PRODUCT_IMPORT,
} from "../actionTypes";
import Axios from "../../services/axios";
import queryString from "query-string";

export function warehouseAction() {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/warehouse/list`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      dispatch({
        type: SET_WAREHOUSE,
        payload: data.data.warehouses,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function warehouseDetailAction(id) {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/warehouse/${id}?isAdmin=true`,
        method: "GET",
      });

      dispatch({
        type: SET_WAREHOUSE_DETAIL,
        payload: data.data.warehouse,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function warehouseExportAction(id) {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/stock-pbp/list-import/${id}`,
        method: `GET`,
      });

      dispatch({
        type: SET_WAREHOUSE_PRODUCT_IMPORT,
        payload: data.data.stocks,
      });

      return data.data.stocks;
    } catch (error) {
      console.error(error);
    }
  };
}

export function warehouseProductAction({
  id,
  isInitiated,
  limit = 10,
  page = 1,
  query = "",
}) {
  return async (dispatch) => {
    try {
      const queries = queryString.stringify(
        {
          limit,
          page,
          q: query,
        },
        { skipEmptyString: true }
      );

      const { data } = await Axios({
        url: `/stock-pbp/list/${id}${
          !isInitiated ? "/uninitiated" : `?${queries}`
        }`,
        method: "GET",
      });

      if (isInitiated) {
        dispatch({
          type: SET_WAREHOUSE_PRODUCT_INCLUDE,
          payload: data.data.stocks,
        });
      } else {
        dispatch({
          type: SET_WAREHOUSE_PRODUCT_EXCLUDE,
          payload: data.data.products,
        });
      }

      return data;
    } catch (error) {
      console.log(error);
    }
  };
}

export function warehouseProductUpdate({ warehouseId, stock }) {
  return async (dispatch) => {
    try {
      await Axios({
        url: `/stock`,
        method: "POST",
        data: {
          warehouseId,
          stock,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(warehouseExportAction(warehouseId));
    }
  };
}
