import React, { Fragment, useState, useEffect } from "react";

import Breadcrumb from "../common/breadcrumb";
import ProductTable from "../common/ProductTable";
import Loader from "../loader/Loader";

// import Modal from "react-responsive-modal";
import {
  Modal,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Spacer, Flex, Center, useToast } from "@chakra-ui/react";
import Axios from "../../services/axios";
import navguard from "../auth/navguard";

const AllProducts = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [fuzzyscore, setFuzzyscore] = useState(75);
  const [query, setQuery] = useState("");
  const [allProduct, setAllProduct] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);

  const reloadData = async () => {
    let getData = [];
    try {
      getData = await Axios({
        method: "GET",
        url: "/product/list?includeReview=true&sort=createdAt,desc",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
    } catch (e) {
      console.log(e);
    }

    setAllProduct(getData.data.data.products);
    setFilteredProduct(getData.data.data.products);
  };

  useEffect(() => {
    const fetchData = async () => {
      await reloadData();
    };

    fetchData();
  }, []);

  const onCloseModal = () => {
    setOpen(false);
  };

  const queryHandler = (event) => {
    setQuery(event.target.value);
  };

  const searchHandler = () => {
    const fuzzball = require("fuzzball");
    const tempData = [...allProduct];
    const result = [];
    let fuzzballScore;
    if (query === "") {
      setFilteredProduct(allProduct);
    } else {
      tempData.forEach((data) => {
        fuzzballScore = fuzzball.partial_ratio(
          query.toLowerCase(),
          data.name.toLowerCase()
        );

        if (fuzzballScore >= fuzzyscore) {
          result.push(data);
        }
      });
      setFilteredProduct(result);
    }
  };

  const keypressHandler = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchHandler();
    }
  };

  const deleteProduct = async (product) => {
    let isError = false;
    setIsLoading(true);
    try {
      await Axios({
        method: "DELETE",
        url: `/product/${product.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
    } catch (e) {
      console.log(e);
      isError = true;
      toast({
        title: "Error.",
        description: "Produk gagal disubmit, mohon coba lagi.",
        status: "warning",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }

    await reloadData();

    setQuery("");

    if (!isError) {
      toast({
        title: "Sukses.",
        description: "Produk berhasil disubmit.",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (query.split(" ").length > 2) {
      setFuzzyscore(100);
    } else {
      setFuzzyscore(75);
    }
  }, [query]);

  return (
    <Fragment>
      {isLoading && <Loader />}
      <Breadcrumb title="All Products" parent="Products" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Flex>
                  <div>
                    <Button
                      type="button"
                      color="secondary"
                      href="/products/add-product"
                      data-toggle="modal"
                      data-original-title="test"
                      data-target="#exampleModal"
                      size="sm"
                    >
                      Add Products
                    </Button>
                  </div>
                  <Spacer />
                  <div
                    style={{
                      display: "inherit",
                    }}
                  >
                    <Input
                      type="text"
                      className="form-control"
                      style={{
                        marginRight: "8px",
                      }}
                      value={query}
                      onChange={(e) => queryHandler(e)}
                      onKeyDown={(e) => keypressHandler(e)}
                    />
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => searchHandler()}
                      size="sm"
                    >
                      Search
                    </Button>
                    <Modal isOpen={open} toggle={onCloseModal}>
                      <ModalHeader toggle={onCloseModal}>
                        <h5
                          className="modal-title f-w-600"
                          id="exampleModalLabel2"
                        >
                          Add Digital Product
                        </h5>
                      </ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                            <Label
                              htmlFor="recipient-name"
                              className="col-form-label"
                            >
                              Name :
                            </Label>
                            <Input type="text" className="form-control" />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              htmlFor="message-text"
                              className="col-form-label"
                            >
                              Category Image :
                            </Label>
                            <Input
                              className="form-control"
                              id="validationCustom02"
                              type="file"
                            />
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          type="button"
                          color="primary"
                          onClick={onCloseModal}
                        >
                          Save
                        </Button>
                        <Button
                          type="button"
                          color="secondary"
                          onClick={onCloseModal}
                        >
                          Close
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                </Flex>
                <div className="clearfix"></div>
                <div id="basicScenario" className="product-physical">
                  {filteredProduct.length > 0 ? (
                    <ProductTable
                      deleteProduct={deleteProduct}
                      multiSelectOption={true}
                      myData={filteredProduct}
                      pageSize={10}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  ) : (
                    <Center m={8}>There are no data to display</Center>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default navguard(AllProducts);
