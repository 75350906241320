import React, { useState } from "react";

import '../styles/_homeBanner.scss';
import {
	Col,
	Row,
} from "reactstrap";
import {
    Flex,
    Spacer,
    IconButton,
    Icon,
    Text,
    Button,
    Modal,
    ModalFooter,
    ModalBody,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalContent,
    HStack,
} from "@chakra-ui/react";
import {
    MdChevronRight,
} from "react-icons/md";
import {
    FaMobileAlt,
    FaDesktop,
} from "react-icons/fa";

const HighlightFullPreview = ({
    previewClose,
    isPreview,
    getActiveBanner,
}) => {
    const [fullPreviewMode, setFullPreviewMode] = useState("Desktop");

    const changeFullPreview = (mode) => {
        setFullPreviewMode(mode);
    }
	return (
        <Modal onClose={previewClose} size="full" isOpen={isPreview}>
            <ModalOverlay />
            <ModalContent>
            <Flex>
                <ModalHeader flex="2">Preview Banner</ModalHeader>
                <Spacer />
                <IconButton
                    mr={4}
                    mt={2}
                    variant="outline"
                    aria-label="Call Sage"
                    fontSize="20px"
                    onClick={() => changeFullPreview("Desktop")}
                    icon={<FaDesktop />}
                />
                <IconButton
                    mr={16}
                    mt={2}
                    variant="outline"
                    aria-label="Call Sage"
                    fontSize="20px"
                    onClick={() => changeFullPreview("Mobile")}
                    icon={<FaMobileAlt />}
                />
                <ModalCloseButton />
            </Flex>
            {fullPreviewMode === "Desktop" ?
                <Row style={{ margin: '0px' }}>
                    {getActiveBanner().map((banner, id) => (
                        <Col key={id} xl={6} style={{ padding: '0px', position: 'relative' }}>
                            <Text
                                style={{
                                    position: 'absolute',
                                    top: '16px',
                                    left: '32px',
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: '24px'
                                }}
                            >
                                {banner.title}
                            </Text>
                            <HStack
                                style={{
                                    position: 'absolute',
                                    top: '56px',
                                    left: '32px',
                                }}
                                mt={0}
                                mb={3}
                            >
                                    <Text
                                        style={{
                                            color: 'black',
                                            fontSize: '14px',
                                            position: 'relative',
                                            marginTop: '2px'
                                        }}
                                    >
                                        {banner.subtitle} 
                                    </Text>
                                    <Icon
                                        ml={1}
                                        style={{
                                            fontSize: '24px',
                                        }}
                                        as={MdChevronRight} />
                                </HStack>
                            <img
                                src={banner.imagePath}
                                alt=""
                                style={{
                                    aspectRatio: `16 / 9`,
                                    objectFit: 'cover',
                                    width: '100%',
                                }}
                                className="img-fluid image_zoom_1 blur-up lazyloaded"
                            />
                        </Col>
                    ))}
                </Row>
            :                
                <ModalBody
                    style={{
                        width: '30vw',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >

                    <Row style={{ margin: '0px' }}>
                        {getActiveBanner().map((banner, id) => (
                            <Col key={id} xl={12} style={{ padding: '0px', position: 'relative' }}>                                    
                                <img
                                    src={banner.imagePath}
                                    alt=""
                                    style={{
                                        aspectRatio: `16 / 9`,
                                        objectFit: 'cover',
                                        width: '100%',
                                    }}
                                    className="img-fluid image_zoom_1 blur-up lazyloaded"
                                />
                                <Text
                                    mt={3}
                                    mb={1}
                                    style={{
                                        color: 'black',
                                        fontWeight: 'bold',
                                        fontSize: '24px',
                                        lineHeight: '1'
                                    }}
                                >
                                    {banner.title}
                                </Text>
                                <HStack
                                    mt={0}
                                    mb={3}
                                >
                                    <Text
                                        style={{
                                            color: 'black',
                                            fontSize: '14px',
                                            position: 'relative',
                                            marginTop: '2px'
                                        }}
                                    >
                                        {banner.subtitle} 
                                    </Text>
                                    <Icon
                                        ml={1}
                                        style={{
                                            fontSize: '24px',
                                        }}
                                        as={MdChevronRight} />
                                </HStack>
                            </Col>
                        ))}
                    </Row>
                </ModalBody>         
            }
            <ModalFooter>
                <Button onClick={previewClose}>Close</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
	);
};

export default HighlightFullPreview;
