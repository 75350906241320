import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import dateFormat from "dateformat";

import { useDispatch, useSelector } from "react-redux";
import { setShowPopup } from "../../store/actions/stockAction";
import "./styles/stocklist.scss";
import {
  warehouseProductAction,
  warehouseDetailAction,
  warehouseExportAction,
} from "../../store/actions/warehouseAction";
import PopupModal from "./popup";
import StockListCard from "./components/stock-list__card";
import navguard from "../auth/navguard";
import Loader from "../loader/Loader";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ImportModal from "./importModal";
import Pagination from "../order/components/pagination";

function StockList() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const {
    isOpen: isImportOpen,
    onOpen: openImport,
    onClose: closeImport,
  } = useDisclosure();
  const products = useSelector(
    (state) => state.warehouse.warehouseProductsInclude
  );
  const productsImport = useSelector(
    (state) => state.warehouse.warehouseProductsImport
  );
  const warehouse = useSelector((state) => state.warehouse.warehouse);

  const showPopup = useSelector((state) => state.stock.popup);

  const [search, setSearch] = useState("");
  const [searchConfirm, setSearchConfirm] = useState("");
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const dispatch = useDispatch();

  const handleSearchChange = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  const handleSearchConfirm = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setSearchConfirm(search);
    }
  };

  const fetchProductInWarehouse = async () => {
    try {
      const { data } = await dispatch(
        warehouseProductAction({
          id,
          isInitiated: true,
          limit: 10,
          page: page + 1,
          query: search,
        })
      );
      setTotalPage(data.totalPage);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    dispatch(warehouseDetailAction(id));
  }, [id, dispatch, showPopup]);

  useEffect(() => {
    fetchProductInWarehouse();
  }, [id, page]);

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    } else {
      fetchProductInWarehouse();
    }
  }, [id, searchConfirm]);

  const handleShowPopup = () => {
    dispatch(setShowPopup(true));
  };

  const handleStockStatus = (stock, safetyStock) => {
    if (safetyStock === null || safetyStock === undefined) {
      return "Ready Stock";
    } else {
      if (stock <= 0) {
        return "Out of Stock";
      } else if (stock < safetyStock) {
        return "Low Stock";
      } else {
        return "Ready Stock";
      }
    }
  };

  const populateData = async () => {
    const temp = [
      [
        "Product ID",
        "Product Name",
        "Product Type",
        "Variant ID",
        "Variant Name",
        "Odoo Code",
        "Stock Status",
        "Available Stock",
        "Safety Stock",
      ],
    ];
    const dataExport = await dispatch(warehouseExportAction(id));
    dataExport.forEach((product) => {
      const {
        productId,
        productName,
        productType,
        productVariantId,
        productVariantName,
        productOdooCode,
        productVariantOdooCode,
        availableStock,
        minSafetyStock,
      } = product;

      temp.push([
        productId,
        productName,
        productType,
        productVariantId,
        productVariantName,
        productType === "Variant" ? productVariantOdooCode : productOdooCode,
        handleStockStatus(availableStock, minSafetyStock),
        availableStock,
        minSafetyStock,
      ]);
    });

    return temp;
  };

  const exportStock = async () => {
    setIsLoading(true);
    const today = new Date();
    const populated = await populateData();
    const ws = XLSX.utils.json_to_sheet(populated, { skipHeader: 1 });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: ".xlsx" });

    setIsLoading(false);
    FileSaver.saveAs(
      data,
      `Stock ${warehouse.warehouseName} - ${dateFormat(
        today,
        "yyyy-mm-dd"
      )}.xlsx`
    );
  };

  const handleChangePage = (value) => {
    setPage(value.selected);
  };

  const handleOpenImportModal = async () => {
    await dispatch(warehouseExportAction(id));
    openImport();
  };
  return (
    <>
      {isLoading && <Loader />}
      {showPopup && (
        <PopupModal
          warehouseName={warehouse.warehouseName}
          fetchProductInWarehouse={fetchProductInWarehouse}
        />
      )}
      {isImportOpen && (
        <ImportModal
          isOpen={isImportOpen}
          onClose={closeImport}
          setIsLoading={setIsLoading}
        />
      )}
      <div className="stock-list-container">
        <div className="stock-list-header">
          <h2 className="title">Stock Management</h2>
          <button
            onClick={handleShowPopup}
            id="btn-showPopup"
            className="stock-list-button"
          >
            Manage Product
          </button>
        </div>
        <div className="stock-list-body">
          <Flex className="stock-list-title">
            <h2 className="dc-title">
              {warehouse.warehouseName} Products Stock
            </h2>
            <Spacer />
            <Button mr="16px" borderRadius="16px" onClick={exportStock}>
              Export Stock
            </Button>
            <Button borderRadius="16px" onClick={handleOpenImportModal}>
              Import Stock
            </Button>
          </Flex>
          <Box m="16px 24px">
            <Input
              value={search}
              onChange={handleSearchChange}
              onKeyDown={handleSearchConfirm}
              placeholder="Search Products"
              size="md"
              className="product-search"
            />
          </Box>
          {products.length > 0 && (
            <>
              {products.map((product) => {
                return (
                  <StockListCard
                    product={product}
                    id={id}
                    setPage={setPage}
                    page={page}
                  />
                );
              })}
            </>
          )}
          <Box>
            <Pagination
              page={page}
              totalPage={totalPage}
              changePageHandler={handleChangePage}
            />
          </Box>
        </div>
      </div>
    </>
  );
}

export default navguard(StockList);
