import {
  Accordion,
  Box,
  Button,
  Flex,
  Input,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import navguard from "../auth/navguard";
import TableCampaign from "./components/TableCampaign";
import "./style/ListCampaign.css";
import Axios from "../../services/axios";
import queryString from "query-string";
import Pagination from "../order/components/pagination";

function ListCampaign() {
  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();
  const [page, setPage] = useState(0);
  const { search } = useLocation();
  const currentQuery = queryString.parse(search);
  const [tableData, setTableData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);

  const fetchData = async () => {
    try {
      let query = queryString.stringify({
        ...currentQuery,
        page: page + 1,
        limit: 5,
      });

      const { data } = await Axios({
        url: `/campaign/list?${query}`,
        method: "get",
      });

      setTableData(data.data.campaigns);
      setTotalPage(data.data.totalPage);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [search]);

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setPage(0);
      const queries = queryString.stringify(
        {
          ...currentQuery,
          q: searchQuery,
        },
        { skipEmptyString: true }
      );

      history.push(`/campaign/list-campaign?${queries}`);
    }
  };

  const handleChangePage = (value) => {
    setPage(value.selected);
    const queries = queryString.stringify(
      {
        ...currentQuery,
        page: value.selected + 1,
      },
      { skipEmptyString: true }
    );

    history.push(`/campaign/list-campaign?${queries}`);
  };
  return (
    <Box className="campaign__container">
      <Flex>
        <Text className="campaign__title">Campaign Catalog Management</Text>
        <Spacer />
        <Button
          className="campaign__button-add"
          bg={"#ff8084"}
          onClick={() => history.push("/campaign/add-campaign")}
        >
          Add Campaign
        </Button>
      </Flex>

      <Box className="campaign__list-campaign-table-container">
        <Text className="campaign__subtitle">Campaign List</Text>
        <Input
          placeholder="Search campaign name here ..."
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
          onKeyPress={(event) => handleKeyPressSearch(event)}
        />

        {tableData.map((data) => (
          <TableCampaign data={data} fetchData={fetchData} />
        ))}

        <Box>
          <Pagination
            page={page}
            totalPage={totalPage}
            changePageHandler={handleChangePage}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default navguard(ListCampaign);
