export const dummyData = [
  {
    id: 1,
    email: "dimastesting12@gmail.com",
    topic: "Community A",
    feedback:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil, et!Dolore quidem commodi omnis voluptate reiciendis, deserunt excepturi cumdignissimos. Cumque blanditiis aperiam quis aspernatur commodi essesuscipit voluptatem exercitationem.",
  },
  {
    id: 2,
    email: "dimastesting12@gmail.com",
    topic: "Community A",
    feedback:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil, et!Dolore quidem commodi omnis voluptate reiciendis, deserunt excepturi cumdignissimos. Cumque blanditiis aperiam quis aspernatur commodi essesuscipit voluptatem exercitationem.",
  },
  {
    id: 3,
    email: "dimastesting12@gmail.com",
    topic: "Community A",
    feedback:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil, et!Dolore quidem commodi omnis voluptate reiciendis, deserunt excepturi cumdignissimos. Cumque blanditiis aperiam quis aspernatur commodi essesuscipit voluptatem exercitationem.",
  },
  {
    id: 4,
    email: "dimastesting12@gmail.com",
    topic: "Community A",
    feedback:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil, et!Dolore quidem commodi omnis voluptate reiciendis, deserunt excepturi cumdignissimos. Cumque blanditiis aperiam quis aspernatur commodi essesuscipit voluptatem exercitationem.",
  },
  {
    id: 5,
    email: "dimastesting12@gmail.com",
    topic: "Community A",
    feedback:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil, et!Dolore quidem commodi omnis voluptate reiciendis, deserunt excepturi cumdignissimos. Cumque blanditiis aperiam quis aspernatur commodi essesuscipit voluptatem exercitationem.",
  },
  {
    id: 6,
    email: "dimastesting12@gmail.com",
    topic: "Community A",
    feedback:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil, et!Dolore quidem commodi omnis voluptate reiciendis, deserunt excepturi cumdignissimos. Cumque blanditiis aperiam quis aspernatur commodi essesuscipit voluptatem exercitationem.",
  },
];

export default dummyData;
