import { Box, Input, Text, Flex, Button, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { SearchIcon, SmallCloseIcon, MinusIcon } from "@chakra-ui/icons";
import "../styles/add-tagging__modal.scss";
import Axios from "../../../services/axios";

const AddTagModal = ({ setIsModal, selectedTag, resetTagList }) => {
  const toast = useToast();
  const [query, setQuery] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [showAllSelectedProduct, setShowAllSelectedProduct] = useState(false);
  const [tagName, setTagName] = useState("");

  useEffect(() => {
    if (selectedTag?.tag) {
      setTagName(selectedTag.tag);
      setSelectedProduct(selectedTag.products);
    }
  }, [selectedTag]);

  const fetchProduct = async (value) => {
    try {
      const { data } = await Axios({
        method: "GET",
        url: `/product/search?filter_status=Published&q=${value}`,
      });

      setProducts(data.data.products);
    } catch (error) {
      console.log(error);
    }
  };

  const showAllSelectedProductHandler = () => setShowAllSelectedProduct(true);

  const searchHandler = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length > 2) {
      fetchProduct(value);
    } else {
      setProducts([]);
    }
  };

  const removeSelected = (value) => {
    const temp = selectedProduct.filter((item) => item.id !== value.id);
    setSelectedProduct(temp);
  };

  const chooseProductHandler = (value) => {
    let isDuplicated = false;
    selectedProduct.forEach((product) => {
      if (product.id === value.id) {
        isDuplicated = true;
      }
    });

    if (!isDuplicated) {
      setSelectedProduct([...selectedProduct, value]);
    }
  };

  const submitTagging = async () => {
    const products = selectedProduct.map((item) => {
      return { productId: item.id };
    });
    try {
      if (selectedTag?.tag) {
        await Axios({
          method: "PATCH",
          url: `/product-tag/${selectedTag.tag}`,
          data: {
            tag: tagName,
            products,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });
      } else {
        await Axios({
          method: "POST",
          url: `/product-tag`,
          data: {
            tag: tagName,
            products,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });
      }

      resetTagList();
      setIsModal(false);
      toast({
        title: "Success!",
        description: "Berhasil menambahkan tag",
        status: "success",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Error!",
        description: "Gagal mensubmit tag!",
        status: "error",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  return (
    <Box className="add-tagging__container">
      <Box
        className="overlay"
        cursor="pointer"
        onClick={() => setIsModal(false)}
      />
      <Box className="main">
        <Box className="header">
          <Text className="title">Add Tag</Text>
          <SmallCloseIcon
            cursor="pointer"
            fontSize="18px"
            color="black"
            onClick={() => setIsModal(false)}
          />
        </Box>
        <Box className="body">
          <Box className="input-container">
            <Text className="label">Tagging Name</Text>
            <Input
              className="input"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
            />
          </Box>
          <Box minHeight="-webkit-fill-available">
            <Box className="choose-product__container">
              <Text className="label">Choose Product</Text>
              <Box position="relative">
                <Box position="absolute" top="8px" left="16px">
                  <SearchIcon />
                </Box>
                <Input
                  className="search-product"
                  pl="40px"
                  value={query}
                  onChange={searchHandler}
                />
              </Box>
            </Box>
            <Box className="products-container">
              {products.length > 0 &&
                products.map((item) => {
                  return (
                    <Text
                      className="product"
                      onClick={() => chooseProductHandler(item)}
                      pb="8px"
                      mb="24px !important"
                      borderBottom="1px solid #eaeaea"
                      key={item.name}
                    >
                      {item.name}
                    </Text>
                  );
                })}
            </Box>
          </Box>
          {selectedProduct.length > 0 && (
            <Box className="selected-product__container">
              {showAllSelectedProduct ? (
                <Flex className="selected-product__header">
                  <Text className="selected-product__text">
                    Selected Product
                  </Text>
                  <MinusIcon
                    cursor="pointer"
                    fontSize="16px"
                    color="white"
                    onClick={() => setShowAllSelectedProduct(false)}
                  />
                </Flex>
              ) : (
                <Flex
                  className="selected-product"
                  justify="space-between"
                  align="flex-end"
                  onClick={showAllSelectedProductHandler}
                  cursor="pointer"
                >
                  <Box>
                    <Text className="total-text">TOTAL</Text>
                    <Text className="product-text">
                      {selectedProduct.length} Product Selected
                    </Text>
                  </Box>
                  <Text className="lihat-text">Lihat</Text>
                </Flex>
              )}
              <Box
                style={{
                  maxHeight: showAllSelectedProduct ? "70vh" : 0,
                  overflow: "auto",
                }}
                className="product-selected__container-float"
              >
                <Box className="products-container">
                  {selectedProduct.length > 0 &&
                    selectedProduct.map((item) => {
                      return (
                        <Flex
                          justify="space-between"
                          align="center"
                          borderBottom="1px solid #efefef"
                          mb="24px"
                          key={item.name}
                        >
                          <Text className="product">{item.name}</Text>
                          <SmallCloseIcon
                            cursor="pointer"
                            fontSize="18px"
                            color="black"
                            onClick={() => removeSelected(item)}
                          />
                        </Flex>
                      );
                    })}
                </Box>
                <Box className="button-submit">
                  <Button
                    bg="#ff8084"
                    color="white"
                    _hover={{ backgroundColor: "#ff8084" }}
                    onClick={submitTagging}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          <Box className="footer"></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddTagModal;
