import React, { useEffect, useState } from "react";
import {
  Row,
  Col
} from "reactstrap";
import {
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import Tooltip from "../../tooltip";
import { useDispatch, useSelector } from "react-redux";
import { warehouseAction } from "../../../store/actions/warehouseAction";
import { provinceAction } from "../../../store/actions/provinceAction";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";


const ShippingPromo = ({
  handlePromoDataChange,
  promoData,
  setPromoData,
  isDisabled
}) => {
  const dispatch = useDispatch();
  const warehouses = useSelector((state) => state.warehouse.warehouses)
  const provinces = useSelector((state) => state.provinces.provinces);

  useEffect(() => {
    dispatch(warehouseAction());
    dispatch(provinceAction());
  }, [])

  const handleAutocompleteSelected = ({ value, type }) => {
    let tempPromoData = { ...promoData };
    tempPromoData[type] = value?.id ? value.id : value;
    setPromoData(tempPromoData)
  }
  
  const selectedWarehouse = warehouses?.find((warehouse) => warehouse.id === promoData.originWarehouseId);
  

  return (
    <>
      <Row className="input-promo__title-wrapper">
        <Col
          md="2"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text className="input-promo__title">Promo Value</Text>
          <Tooltip content={"Nilai promo yang diberikan."} />
        </Col>
        <Col md="10">
          <Row>
            <Col md="4">
              <Input
                disabled={isDisabled}
                name="promoValue"
                type="number"
                value={promoData.promoValue}
                onChange={(event) => handlePromoDataChange(event)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="input-promo__title-wrapper">
        <Col
          md="2"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text className="input-promo__title">Origin DC</Text>
          <Tooltip content={"Asal DC pengiriman yang akan menerima promo"} />
        </Col>
        <Col md="10">
          <Row>
            <Col md="4">
              {warehouses.length > 0 && (
                <Autocomplete
                  disabled={isDisabled}
                  disablePortal
                  options={warehouses}
                  getOptionLabel={(option) => option.warehouseName}
                  value={selectedWarehouse}
                  onChange={(_, value) => handleAutocompleteSelected({
                    value,
                    type: "originWarehouseId"
                  })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select DC"
                    />
                  )}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="input-promo__title-wrapper">
        <Col
          md="2"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text className="input-promo__title">Destination Province</Text>
          <Tooltip content={"Provinsi tujuan yang akan menerima promo"} />
        </Col>
        <Col md="10">
          <Row>
            <Col md="4">
              {provinces.length > 0 && (
                <Autocomplete
                  disabled={isDisabled}
                  disablePortal
                  options={provinces}
                  getOptionLabel={(option) => option}
                  multiple
                  value={promoData.destinationProvinces}
                  onChange={(_, value) => handleAutocompleteSelected({
                    value,
                    type: "destinationProvinces"
                  })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select province"
                    />
                  )}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default ShippingPromo;