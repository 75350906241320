import React, { useRef } from "react";
import { MdClose } from "react-icons/md";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Col } from "reactstrap";
import { IconButton } from "@chakra-ui/react";

function BannerImage({
  i,
  handleDeleteBanner,
  handleImageChange,
  moveOrder,
  data,
}) {
  const bannerPdp = useRef();
  return (
    <Col key={i} xl="12" style={{ marginBottom: "2em" }}>
      <div
        style={{ marginBottom: "1em", cursor: "pointer" }}
        className="box-input-file-full"
      >
        <IconButton
          variant="outline"
          aria-label="Call Sage"
          onClick={() => handleDeleteBanner(i)}
          icon={<MdClose />}
          style={{
            position: "absolute",
            right: "4px",
            top: "4px",
            borderRadius: "50%",
            fontSize: "24px",
            width: "24px",
            height: "24px",
            color: "red",
            border: "none",
            minWidth: "unset",
          }}
        />
        <IconButton
          variant="outline"
          icon={<FaChevronUp />}
          onClick={() => moveOrder(i, i - 1)}
          marginRight="1em"
        />
        <IconButton
          variant="outline"
          icon={<FaChevronDown />}
          onClick={() => moveOrder(i, i + 1)}
        />
        <input
          className="upload"
          type="file"
          accept="image/*"
          style={{
            cursor: "pointer",
            display: "none",
          }}
          ref={bannerPdp}
          onChange={(e) => handleImageChange(e, i)}
        />

        <img
          alt=""
          src={data.image}
          style={{
            width: "100%",
          }}
          onClick={() => bannerPdp.current.click()}
        />
      </div>
    </Col>
  );
}

export default BannerImage;
