import React, { Fragment, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import {
  deleteProductShowcase,
  editProductShowcase,
  fetchProductShowcase,
  moveDownProductShowcase,
  moveUpProductShowcase,
  updateProductShowcaseActive,
} from "../../store/actions/productShowcaseAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Image,
  useToast,
  Button,
  Flex,
  Spacer,
  Switch,
} from "@chakra-ui/react";
import navguard from "../auth/navguard";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

const ListProductShowcase = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const productShowcase = useSelector(
    (state) => state.productShowcase.productShowcase
  );

  const handleEditClick = (
    id,
    title,
    subtitle,
    link,
    isActive,
    ordering,
    image1Path,
    image2Path,
    image3Path,
    image4Path,
    image5Path,
    product
  ) => {
    const value = {
      id,
      title,
      subtitle,
      link,
      isActive,
      ordering,
      image1Path,
      image2Path,
      image3Path,
      image4Path,
      image5Path,
      product,
    };
    dispatch(editProductShowcase(value));
  };

  const handleDeleteClick = async (id) => {
    await deleteProductShowcase(id);
    toast({
      title: "Success.",
      description: "ProductShowcase berhasil dihapus",
      status: "success",
      position: "top",
      duration: 3000,
      isClosable: true,
    });
    await dispatch(fetchProductShowcase());
  };

  useEffect(() => {
    dispatch(fetchProductShowcase()); // eslint-disable-next-line
  }, []);

  const onHandleIsActive = (checked, id) => {
    const value = {
      isActive: checked,
    };

    dispatch(updateProductShowcaseActive(value, id));
    if (checked) {
      toast({
        title: "Success.",
        description: "Product berhasil diaktifkan",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Success.",
        description: "Product berhasil diaktifkan",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true,
      });
    }
    dispatch(fetchProductShowcase());
  };

  const onHandleChangeOrder = async (id, isMoveUp = false) => {
    if (isMoveUp) {
      await moveUpProductShowcase(dispatch, id);
    } else {
      await moveDownProductShowcase(dispatch, id);
    }

    toast({
      title: "Success.",
      description: "Urutan berhasil diubah",
      status: "success",
      position: "top",
      duration: 2000,
      isClosable: true,
    });

    await dispatch(fetchProductShowcase());
  };

  return (
    <Fragment>
      <Breadcrumb title="Highlight" parent="Banner" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Flex>
                  <h5>Manage Product Showcase Highlight</h5>
                  <Spacer />
                  <Link to="/content/create-productshowcase">
                    <Button colorScheme={"teal"}>Add Showcase</Button>
                  </Link>
                </Flex>
              </CardHeader>

              <CardBody>
                <div
                  id="batchDelete"
                  className="category-table order-table coupon-list-delete"
                >
                  {/* Data Table with Chakra UI */}
                  <Table variant="striped">
                    <TableCaption>
                      Edit data dengan melakukan click icon pencil
                    </TableCaption>
                    <Thead>
                      <Tr>
                        <Th>Ordering</Th>
                        <Th>Title</Th>
                        <Th>Gambar Product</Th>
                        <Th>Image 1</Th>
                        <Th>Image 2</Th>
                        <Th>Image 3</Th>
                        <Th>Image 4</Th>
                        <Th>Image 5</Th>
                        <Th>isActive</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {productShowcase.map((item, i) => (
                        <Tr key={i}>
                          <Td>
                            <Flex
                              justifyContent="space-around"
                              alignItems="center"
                            >
                              <ChevronUpIcon
                                fontSize="24px"
                                onClick={() =>
                                  onHandleChangeOrder(item.id, true)
                                }
                                cursor={i === 0 ? "none" : "pointer"}
                                opacity={i === 0 ? 0 : 1}
                              />

                              <ChevronDownIcon
                                fontSize="24px"
                                cursor="pointer"
                                onClick={() => onHandleChangeOrder(item.id)}
                              />
                            </Flex>
                          </Td>
                          <Td>{item.title}</Td>
                          <Td>
                            <Image
                              boxSize={85}
                              w={105}
                              alt=""
                              style={{
                                aspectRatio: `16 / 9`,
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              src={item.product.productThumbnail}
                            />
                          </Td>
                          {/* Start: Image 1 - 5 */}
                          <Td>
                            <Image
                              boxSize={85}
                              w={105}
                              alt=""
                              style={{
                                aspectRatio: `16 / 9`,
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              src={item.image1Path}
                            />
                          </Td>
                          <Td>
                            <Image
                              boxSize={85}
                              w={105}
                              alt=""
                              style={{
                                aspectRatio: `16 / 9`,
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              src={item.image2Path}
                            />
                          </Td>
                          <Td>
                            <Image
                              boxSize={85}
                              w={105}
                              alt=""
                              style={{
                                aspectRatio: `16 / 9`,
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              src={item.image3Path}
                            />
                          </Td>
                          <Td>
                            <Image
                              boxSize={85}
                              w={105}
                              alt=""
                              style={{
                                aspectRatio: `16 / 9`,
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              src={item.image4Path}
                            />
                          </Td>
                          <Td>
                            <Image
                              boxSize={85}
                              w={105}
                              alt=""
                              style={{
                                aspectRatio: `16 / 9`,
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              src={item.image5Path}
                            />
                          </Td>
                          {/* End: Image 1 - 5 */}
                          <Td>
                            <Switch
                              size="md"
                              defaultChecked={item.isActive}
                              onChange={(e) =>
                                onHandleIsActive(e.target.checked, item.id)
                              }
                            />
                          </Td>
                          <Td>
                            <Link
                              to="/content/edit-productshowcase"
                              onClick={() =>
                                handleEditClick(
                                  item.id,
                                  item.title,
                                  item.subtitle,
                                  item.link,
                                  item.isActive,
                                  item.ordering,
                                  item.image1Path,
                                  item.image2Path,
                                  item.image3Path,
                                  item.image4Path,
                                  item.image5Path,
                                  item.product
                                )
                              }
                            >
                              <span>
                                <i
                                  className="fa fa-pencil"
                                  style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "rgb(40, 167, 69)",
                                  }}
                                ></i>
                              </span>
                            </Link>
                            <Link
                              to="/content/list-productshowcase"
                              onClick={() => handleDeleteClick(item.id)}
                            >
                              <span>
                                <i
                                  className="fa fa-trash"
                                  style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "#e4566e",
                                  }}
                                ></i>
                              </span>
                            </Link>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                  {/* End of Datatable Chakra UI */}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default navguard(ListProductShowcase);
