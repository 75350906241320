import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Input,
  Select,
  Text,
  Textarea,
  useToast,
  CloseButton,
} from "@chakra-ui/react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import ReactDatePicker from "react-datepicker";
import "../styles/createPromo.css";
import Breadcrumb from "../../common/breadcrumb";
import {
  setSinglePromo,
  updatePromo,
} from "../../../store/actions/promoAction";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import dateFormat from "dateformat";
import Axios from "../../../services/axios";
import navguard from "../../auth/navguard";
import { isEmpty } from "lodash";
import Tooltip from "../../tooltip";
import {
  fetchUserBadgeProgram,
  getUsersAction,
} from "../../../store/actions/userAction";
import queryString from "query-string";
import GwpPromo from "../components/GwpPromo";
import DiscountPromo from "../components/DiscountPromo";
import ShippingPromo from "../components/ShippingPromo";
import { fetchResellerLevel } from "../../../store/actions/resellerLevelAction";

function EditPromo({ match }) {
  const toast = useToast();
  const dispatch = useDispatch();
  const today = new Date();
  const tomorrow = new Date(today);
  const id = match.params.id;

  const singlePromo = useSelector(({ promos }) => promos.singlePromo);
  const [allProduct, setAllProduct] = useState([]);
  const [successEdit, setSuccessEdit] = useState(false);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(
    tomorrow.setDate(tomorrow.getDate() + 1)
  );
  const [input, setInput] = useState({
    code: "",
    description: "",
    isActive: false,
    minimumPurchase: 0,
    maximumPurchase: 0,
    quotaPerUser: "",
    quotaPerPromo: "",
    category: "discount",
    isAutoApplied: false,
    newResellerOnly: false,
    canBeCombined: true,
    directPromoAvailable: true,
    resellerLevel: [],
    resellerProgramName: [],
  });
  const [promoData, setPromoData] = useState({
    gwpProducts: [
      {
        productId: "",
        qty: "",
      },
    ],
    requiredProducts: [""],
    discountType: "",
    promoValue: 0,
    maximumPercentageValue: 0,
    promoMark: "",
  });

  const [optionsResellerLevel, setOptionResellerLevel] = useState([]);
  const [optionsAssignedUser, setOptionsAssignedUser] = useState([]);

  const [optionResellerProgramName, setOptionResellerProgramName] = useState(
    []
  );

  const { users, badgeProgram } = useSelector(({ users }) => users);
  const { resellerLevelList } = useSelector((state) => state.resellerLevel);

  useEffect(() => {
    dispatch(fetchResellerLevel());
  }, []);

  useEffect(() => {
    if (!isEmpty(resellerLevelList)) {
      setOptionResellerLevel(
        resellerLevelList.map((data) => data.resellerLevel)
      );
    }
  }, [resellerLevelList]);

  useEffect(() => {
    if (badgeProgram) {
      const tempBadgeProg = badgeProgram.map((item) => {
        return item.name;
      });

      setOptionResellerProgramName(tempBadgeProg);
    }
  }, [badgeProgram]);

  useEffect(() => {
    if (users) {
      setOptionsAssignedUser(users);
    }
  }, [users]);

  const fecthDataProduct = async () => {
    try {
      const { data } = await Axios({
        url: "/product/list?sort=name,asc",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      setAllProduct(data.data.products);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fecthDataProduct();
    fetchUserBadgeProgram(dispatch, "promo");
  }, []);

  const handlePromoDataChange = (category, e, i, isAuto) => {
    let tempPromodata = { ...promoData };
    if (isAuto) {
      if (e) {
        if (category === "gwp") tempPromodata.gwpProducts[i].productId = e.id;
        tempPromodata.requiredProducts[i] = e.id;
      } else {
        if (category === "gwp") tempPromodata.gwpProducts[i].productId = "";
        tempPromodata.requiredProducts[i] = "";
      }
    } else {
      let { name, value, type } = e.target;
      if (type === "number") {
        if (value === "") {
          value = null;
        } else {
          value = parseFloat(value);
        }
      }
      if (name === "qty") {
        tempPromodata.gwpProducts[i][name] = value;
        tempPromodata.requiredProducts[i][name] = value;
      } else if (name === "discountType") {
        tempPromodata[name] = value;
        tempPromodata.promoValue = 0;
        tempPromodata.maximumPercentageValue = 0;
      } else {
        tempPromodata[name] = value;
      }
    }

    setPromoData(tempPromodata);
  };

  const handleInputChange = (e) => {
    let tempInput = { ...input };
    let tempPromodata = { ...promoData };
    let { name, value, type } = e.target;
    if (name === "isActive") {
      value = value === "true" ? true : false;
    }
    if (type === "number") {
      if (value === "") {
        value = null;
      } else {
        value = parseFloat(value);
      }
    } else if (type === "checkbox") {
      if (name === "canBeCombined" || name === "directPromoAvailable") {
        value = !e.target.checked;
      } else {
        value = e.target.checked;
      }
    }

    if (name === "category") {
      tempPromodata.gwpProducts = [
        {
          productId: "",
          qty: "",
        },
      ];
      tempPromodata.requiredProducts = [""];
      tempPromodata.discountType = "";
      tempPromodata.promoMark = "";
      tempPromodata.promoValue = 0;
      setPromoData(tempPromodata);
    } else if (name === "code") {
      value = value.toUpperCase();
    }
    tempInput[name] = value;
    setInput(tempInput);
  };

  /// Handle Multiple ResellerLevel and ResellerProgramName
  const handleInputReseller = ({ value, type }) => {
    let tempInput = { ...input };
    tempInput[type] = value;
    setInput(tempInput);
  };

  const handleSavePromo = () => {
    const data = {
      ...input,
      description: input.description,
      isActive: input.isActive,
      requiredProducts: promoData.requiredProducts,
      minimumPurchase: input.minimumPurchase ? input.minimumPurchase : 0,
      maximumPurchase: input.maximumPurchase ? input.maximumPurchase : 0,
      maximumPercentageValue: promoData.maximumPercentageValue
        ? promoData.maximumPercentageValue
        : 0,
      quotaPerPromo: input.quotaPerPromo ? input.quotaPerPromo : 0,
      quotaPerUser: input.quotaPerUser ? input.quotaPerUser : 0,
      startDate: dateFormat(startDate, "yyyy-mm-dd"),
      endDate: dateFormat(endDate, "yyyy-mm-dd"),
      resellerLevel: input.resellerLevel,
      resellerProgramName: input.resellerProgramName?.map((item) => {
        if (item === "Tanpa program") {
          return null;
        } else {
          return item;
        }
      }),
    };

    try {
      dispatch(updatePromo(id, setSuccessEdit, data));
      toast({
        title: "Success.",
        description: "Promo berhasil diupdate",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 1500);
    } catch (error) {
      toast({
        title: "Failed.",
        description: "Promo gagal diupdate",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    dispatch(setSinglePromo(id));
  }, [id]);

  useEffect(() => {
    if (!isEmpty(singlePromo)) {
      setInput({
        ...input,
        code: singlePromo.code,
        description: singlePromo.description,
        isActive: singlePromo.isActive,
        minimumPurchase: singlePromo.minimumPurchase,
        maximumPurchase: singlePromo.maximumPurchase,
        quotaPerUser: singlePromo.quotaPerUser,
        quotaPerPromo: singlePromo.quotaPerPromo,
        category: singlePromo.category,
        isAutoApplied: singlePromo.isAutoApplied,
        newResellerOnly: singlePromo.newResellerOnly,
        canBeCombined: singlePromo.canBeCombined,
        directPromoAvailable: singlePromo.directPromoAvailable,
        resellerLevel: singlePromo.resellerLevel
          ? singlePromo.resellerLevel
          : [],
        resellerProgramName: singlePromo.resellerProgramName
          ? singlePromo.resellerProgramName.map((item) => {
              if (item === "") {
                return "Tanpa program";
              } else {
                return item;
              }
            })
          : [],
      });
      setStartDate(new Date(singlePromo.startDate));
      setEndDate(new Date(singlePromo.endDate));
      setPromoData({
        ...promoData,
        gwpProducts: singlePromo.gwpProducts,
        requiredProducts: singlePromo.requiredProducts,
        discountType: singlePromo.discountAmount ? "fixed" : "percentage",
        promoValue: singlePromo.discountAmount
          ? singlePromo.discountAmount
          : singlePromo.discountPercentage,
        maximumPercentageValue: singlePromo.maximumPercentageValue,
        promoMark: singlePromo.promoMark,
        originWarehouseId: singlePromo.originWarehouseId,
        destinationProvinces: singlePromo.destinationProvinces,
      });
    }
  }, [singlePromo]);

  const handleInputAssignedUser = ({ newValue, type }) => {
    let tempInput = { ...input };
    let newObj = newValue;
    tempInput["isUserAssigned"] = true;
    tempInput[type] = newObj;
    setInput(tempInput);
  };

  useEffect(() => {
    getUsersAction(dispatch, "", "all");
  }, []);

  const filterOptionsAssignedUser = (inputValue) => {
    const queries = queryString.stringify({
      q: inputValue,
      limit: 10,
    });

    getUsersAction(dispatch, queries);
  };

  const handleAddRequiredProduct = () => {
    let tempPromodata = { ...promoData };

    tempPromodata.requiredProducts.push("");
    setPromoData(tempPromodata);

    toast({
      title: "Success.",
      description: "Berhasil menambah product",
      status: "success",
      position: "top",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleDeleteProduct = (idx) => {
    let tempPromodata = { ...promoData };

    if (tempPromodata.requiredProducts.length > 0) {
      tempPromodata.requiredProducts.splice(idx, 1);
      toast({
        title: "Success.",
        description: "Berhasil mengurangi product",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      setPromoData(tempPromodata);
    } else {
      toast({
        title: "Warning.",
        description: "Product tidak boleh kosong",
        status: "warning",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {!isEmpty(singlePromo) && (
        <>
          <Breadcrumb title="Edit Promo" parent="Promo" />
          <Card>
            <CardHeader>
              <Text className="input-promo__header-title">Edit Promo</Text>
            </CardHeader>
            <CardBody>
              <Row className="input-promo__title-wrapper">
                <Col lg="3" xl="2">
                  <Text className="input-promo__title">Promo code</Text>
                </Col>
                <Col lg="9" xl="10">
                  <Row>
                    <Col md="4">
                      <Input
                        disabled
                        type={"text"}
                        maxLength={20}
                        value={input.code}
                        name="code"
                        onChange={(event) => handleInputChange(event)}
                        placeholder="Promo code"
                      />
                    </Col>
                    <Col md="4" />
                    <Col md="4">
                      <Select
                        name="isActive"
                        value={input.isActive}
                        onChange={(event) => handleInputChange(event)}
                      >
                        <option value={false}>Not Active</option>
                        <option value={true}>Active</option>
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="input-promo__title-wrapper">
                <Col lg="3" xl="2">
                  <Text className="input-promo__title">Description</Text>
                </Col>
                <Col lg="9" xl="10">
                  <Textarea
                    name="description"
                    value={input.description}
                    onChange={(event) => handleInputChange(event)}
                    placeholder="Description"
                  />
                </Col>
              </Row>

              <Row className="input-promo__title-wrapper">
                <Col lg="3" xl="2">
                  <Text className="input-promo__title">Promo category</Text>
                </Col>
                <Col lg="9" xl="10">
                  <Row>
                    <Col md="4">
                      <Select
                        disabled
                        value={input.category}
                        name="category"
                        onChange={(event) => handleInputChange(event)}
                      >
                        <option value={"discount"}>Discount</option>
                        <option value={"gwp"}>GWP</option>
                        <option value={"shipping"}>Shipping</option>
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>

            <CardHeader>
              <Text className="input-promo__header-title">Promo Data</Text>
              <Box borderBottom={"1px solid #FF8084"} mt="1em" />
            </CardHeader>
            <CardBody style={{ paddingTop: "0" }}>
              {input.category === "gwp" && !isEmpty(allProduct) && (
                <>
                  {promoData.gwpProducts.map((item, idx) => (
                    <GwpPromo
                      allProduct={allProduct}
                      handlePromoDataChange={handlePromoDataChange}
                      handleDeleteProduct={() => {}}
                      isDisabled={true}
                      item={item}
                      idx={idx}
                    />
                  ))}
                </>
              )}

              {input.category === "discount" && (
                <DiscountPromo
                  handlePromoDataChange={handlePromoDataChange}
                  promoData={promoData}
                  isDisabled={true}
                />
              )}

              {input.category === "shipping" && (
                <ShippingPromo
                  handlePromoDataChange={handlePromoDataChange}
                  promoData={promoData}
                  setPromoData={setPromoData}
                  isDisabled={true}
                />
              )}
              <Box borderBottom={"1px solid #FF8084"} mt="1em" />
            </CardBody>

            <CardBody>
              <Row className="input-promo__title-wrapper">
                <Col lg="3" xl="2">
                  <Text className="input-promo__title">Start date</Text>
                </Col>
                <Col lg="9" xl="10">
                  <Row>
                    <Col md="4">
                      <ReactDatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                      />
                    </Col>
                    <Col md="4" />
                    <Col
                      md="4"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        disabled
                        className="input-promo__title"
                        colorScheme={"pink"}
                        name="directPromoAvailable"
                        value={!input.directPromoAvailable}
                        isChecked={!input.directPromoAvailable}
                        onChange={(event) => handleInputChange(event)}
                      >
                        Exclude Product Sales/Direct Promo
                      </Checkbox>
                      <Tooltip
                        content={
                          "Centang jika promo tidak berlaku pada produk yang memiliki product discount (harga coret)"
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="input-promo__title-wrapper">
                <Col lg="3" xl="2">
                  <Text className="input-promo__title">End date</Text>
                </Col>
                <Col lg="9" xl="10">
                  <Row>
                    <Col md="4">
                      <ReactDatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                      />
                    </Col>
                    <Col md="4" />
                    <Col
                      md="4"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        className="input-promo__title"
                        colorScheme={"pink"}
                        name="isAutoApplied"
                        value={input.isAutoApplied}
                        isChecked={input.isAutoApplied}
                        onChange={(event) => handleInputChange(event)}
                      >
                        Auto Applied
                      </Checkbox>
                      <Tooltip
                        content={
                          "Centang jika promo ingin ter-apply secara otomatis (Promo)" +
                          "<br><br>Jika tidak dicentang, maka user harus memilih promo terlebih dahulu pada saat checkout (juga disebut voucher)"
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="input-promo__title-wrapper">
                <Col
                  lg="3"
                  xl="2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text className="input-promo__title">Minimum purchase</Text>
                  <Tooltip
                    content={
                      "Nominal minimum transaksi agar promo dapat digunakan."
                    }
                  />
                </Col>
                <Col lg="9" xl="10">
                  <Row>
                    <Col md="4">
                      <Input
                        type="number"
                        name="minimumPurchase"
                        value={input.minimumPurchase}
                        onChange={(event) => handleInputChange(event)}
                      />
                    </Col>
                    <Col md="4" />
                    <Col
                      md="4"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        disabled
                        className="input-promo__title"
                        colorScheme={"pink"}
                        name="newResellerOnly"
                        value={input.newResellerOnly}
                        isChecked={input.newResellerOnly}
                      >
                        New Reseller Only
                      </Checkbox>
                      <Tooltip
                        content={
                          "Promo hanya berlaku pada reseller yang belum pernah melakukan transaksi"
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="input-promo__title-wrapper">
                <Col
                  lg="3"
                  xl="2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text className="input-promo__title">Maximum purchase</Text>
                  <Tooltip
                    content={
                      "Nominal maksimum transaksi suatu promo masih dapat digunakan."
                    }
                  />
                </Col>
                <Col lg="9" xl="10">
                  <Row>
                    <Col md="4">
                      <Input
                        type="number"
                        name="maximumPurchase"
                        value={input.maximumPurchase}
                        onChange={(event) => handleInputChange(event)}
                      />
                    </Col>
                    <Col md="4" />
                    <Col
                      md="4"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        disabled
                        className="input-promo__title"
                        colorScheme={"pink"}
                        name="canBeCombined"
                        value={!input.canBeCombined}
                        isChecked={!input.canBeCombined}
                      >
                        Only for Individual Use
                      </Checkbox>
                      <Tooltip
                        content={
                          "Promo tidak dapat digunakan bersamaan dengan promo lain jika ada promo yang sudah terapply maka promo tersebut akan tercancel dan akan meng-apply promo ini"
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="input-promo__title-wrapper">
                <Col
                  lg="3"
                  xl="2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text className="input-promo__title">Reseller Level</Text>
                  <Tooltip
                    content={
                      "Promo hanya dapat digunakan oleh level yang dipilih saja, jika dikosongkan maka akan dapat digunakan oleh semua level"
                    }
                  />
                </Col>
                <Col lg="9" xl="10">
                  <Row>
                    <Col md="4">
                      <Autocomplete
                        disablePortal
                        options={optionsResellerLevel}
                        getOptionLabel={(option) => option}
                        multiple
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Reseller Level"
                          />
                        )}
                        value={input.resellerLevel}
                        placement="bottom"
                        onChange={(event, value) =>
                          handleInputReseller({ value, type: "resellerLevel" })
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* <Row className="input-promo__title-wrapper">
                <Col
                  lg="3"
                  xl="2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Text className="input-promo__title">Reseller Program</Text>
                  <Tooltip
                    content={
                      "Promo hanya dapat digunakan oleh reseller dengan program yang dipilih saja, jika dikosongkan maka akan dapat digunakan oleh semua program"
                    }
                  />
                </Col>
                <Col lg="9" xl="10">
                  <Row>
                    <Col md="4">
                      <Autocomplete
                        disablePortal
                        options={optionResellerProgramName}
                        getOptionLabel={(option) => option}
                        multiple
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Reseller Program Name"
                          />
                        )}
                        value={input.resellerProgramName}
                        placement="bottom"
                        onChange={(event, value) =>
                          handleInputReseller({
                            value,
                            type: "resellerProgramName",
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row> */}
              <Row className="input-promo__title-wrapper">
                <Col
                  lg="3"
                  xl="2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text className="input-promo__title">
                    Usage Limit per User
                  </Text>
                  <Tooltip
                    content={
                      "Jumlah maksimal pemakaian promo yang dapat digunakan oleh setiap reseller. Tulis 0 untuk tidak ada limit"
                    }
                  />
                </Col>
                <Col lg="9" xl="10">
                  <Row>
                    <Col md="4">
                      <Input
                        type="number"
                        name="quotaPerUser"
                        value={input.quotaPerUser}
                        onChange={(event) => handleInputChange(event)}
                      />
                    </Col>
                    <Col md="4" />
                  </Row>
                </Col>
              </Row>
              <Row className="input-promo__title-wrapper">
                <Col
                  lg="3"
                  xl="2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text className="input-promo__title">
                    Usage Limit per Promo
                  </Text>
                  <Tooltip
                    content={
                      "Jumlah maksimal pemakaian promo yang dapat digunakan oleh reseller (jumlah promo beredar). Tulis 0 untuk tidak ada limit"
                    }
                  />
                </Col>
                <Col lg="9" xl="10">
                  <Row>
                    <Col md="4">
                      <Input
                        type="number"
                        name="quotaPerPromo"
                        value={input.quotaPerPromo}
                        onChange={(event) => handleInputChange(event)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col
                  lg="3"
                  xl="2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "1em 0",
                  }}
                >
                  <Text className="input-promo__title">Allowed Emails</Text>
                  <Tooltip
                    content={
                      "Promo hanya dapat digunakan oleh reseller dengan email yang diinput (dapat diisi beberapa email). Kosongkan jika dapat digunakan oleh semua reseller"
                    }
                  />
                </Col>
                <Col md="12">
                  <Autocomplete
                    disablePortal
                    multiple
                    defaultValue={singlePromo.assignedUser}
                    options={optionsAssignedUser.map((item) => item["email"])}
                    getOptionLabel={(option) => option || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Insert emails"
                        defaultValue={singlePromo.assignedUser}
                      />
                    )}
                    placement="bottom"
                    onChange={(event, newValue) => {
                      return handleInputAssignedUser({
                        newValue,
                        type: "assignedUser",
                      });
                    }}
                    onInputChange={(event, inputValue) => {
                      filterOptionsAssignedUser(inputValue);
                    }}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: "2em" }}>
                <Col
                  lg="3"
                  xl="2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "1em 0",
                  }}
                >
                  <Text className="input-promo__title">Required Products</Text>
                </Col>
                <Col md="12">
                  <>
                    {!isEmpty(allProduct) &&
                      promoData?.requiredProducts?.map((item, idx) => {
                        return (
                          <Row className="input-promo__title-wrapper" key={idx}>
                            <Col md="12">
                              <Row>
                                <Col md="4">
                                  <Autocomplete
                                    disablePortal
                                    value={
                                      allProduct.find(
                                        (data) => data.id === item
                                      ) || ""
                                    }
                                    options={allProduct}
                                    getOptionLabel={(product) => product.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                      />
                                    )}
                                    placement="bottom"
                                    onChange={(event, value) =>
                                      handlePromoDataChange(
                                        input.category,
                                        value,
                                        idx,
                                        true
                                      )
                                    }
                                    name="requiredProductId"
                                  />
                                </Col>
                                <Col md="1" />
                                <Col md="3"></Col>
                                <Col
                                  md="4"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <CloseButton
                                    onClick={() => handleDeleteProduct(idx)}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        );
                      })}
                    <Box width={"30%"} mt={"0em"} mb={"2em"}>
                      <Button
                        size="sm"
                        width={"50%"}
                        onClick={handleAddRequiredProduct}
                        colorScheme="teal"
                      >
                        Add Required Product
                      </Button>
                    </Box>
                  </>
                </Col>
              </Row>

              <Box margin="auto" width={"30%"} mt={"1.5em"}>
                <Button
                  width={"100%"}
                  variant="ghost"
                  backgroundColor={"#FF8084"}
                  color={"white"}
                  onClick={handleSavePromo}
                >
                  Save
                </Button>
              </Box>
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
}

export default navguard(EditPromo);
