import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "react-data-table-component";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import {
  fetchGrowthPoint,
  setGpUser,
} from "../../store/actions/growthPointAction";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import "../order/components/styles/date-picker.css";
import {
  Button,
  useDisclosure,
  Flex,
  Spacer,
  Divider,
  HStack,
  InputGroup,
  Link,
  InputLeftElement,
  Input,
  Box,
} from "@chakra-ui/react";
import AddPointModal from "./components/AddPointModal";
import { Link as LinkRouter, useHistory, useLocation } from "react-router-dom";
import FilterByDate from "./components/FilterByDate";
import FilterSort from "./components/FilterSort";
import NoDataDisplay from "./components/NoDataDisplay";
import { Search2Icon } from "@chakra-ui/icons";
import Pagination from "../order/components/pagination";
import { isEmpty } from "lodash";
import navguard from "../auth/navguard";

const ListLoyaltyPoint = () => {
  const dispatch = useDispatch();
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const [searchData, setSearchData] = useState("");
  const [page, setPage] = useState(0);
  const { growthPoint } = useSelector((state) => state.growthPoint);
  const [allData, setAllData] = useState([]);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [phone, setPhone] = useState("");
  const formatter = new Intl.NumberFormat("id-ID");

  const fetchData = () => {
    const currentQueries = queryString.parse(search);
    const queries = queryString.stringify({
      ...currentQueries,
      page: page + 1,
      limit: 10,
    });
    fetchGrowthPoint(dispatch, queries);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (page === 0) {
      fetchData();
    } else {
      setPage(0);
    }
  }, [search]);

  const searchDataHandler = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const currentQueries = queryString.parse(search);
      const queries = queryString.stringify(
        {
          ...currentQueries,
          q: searchData,
        },
        { skipEmptyString: true }
      );

      history.push(`/loyalty/list-points?${queries}`);
    }
  };

  useEffect(() => {
    const currentQueries = queryString.parse(search);

    if (currentQueries.q) {
      setSearchData(currentQueries.q);
    }
  }, [search]);

  const changePageHandler = (value) => {
    setPage(value.selected);
  };

  const columns = [
    {
      name: <b>Reseller ID</b>,
      header: <b>Reseller ID</b>,
      selector: "referralCode",
      style: {
        textAlign: "left",
      },
      sortable: true,
    },
    {
      name: <b>Name</b>,
      header: <b>Name</b>,
      selector: "name",
      cell: null,
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
    {
      name: <b>Email</b>,
      header: <b>Email</b>,
      selector: "email",
      cell: null,
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
    {
      name: <b>Phone</b>,
      header: <b>Phone</b>,
      selector: "phone",
      cell: null,
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
    {
      name: <b>Loyalty Point</b>,
      header: <b>Loyalty Point</b>,
      selector: "totalGrowthPoint",
      cell: (row, index) => <p>{formatter.format(row.totalGrowthPoint)}</p>,
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
    {
      name: <b>Action</b>,
      id: "view",
      accessor: (str) => "view",
      cell: (row, index) => (
        <HStack>
          <LinkRouter
            to={`/loyalty/detail-point/${row.id}`}
            onClick={() =>
              onHandleView(
                row.id,
                row.referralCode,
                row.name,
                row.email,
                row.phone
              )
            }>
            <span>View</span>
          </LinkRouter>
          <Divider
            orientation="vertical"
            size={15}
            variant="solid"
            borderColor="black"
            background="black"
          />
          <Link
            onClick={() =>
              onHandleAddPoint(
                row.id,
                row.referralCode,
                row.name,
                row.email,
                row.phone
              )
            }>
            Add Point
          </Link>
        </HStack>
      ),
      style: {
        textAlign: "center",
      },
      sortable: false,
    },
  ];

  const onHandleView = (id, referralCode, name, email, phone) => {
    const values = {
      id,
      referralCode,
      name,
      email,
      phone,
    };

    setId(id);
    setReferralCode(referralCode);
    setName(name);
    setEmail(email);
    setPhone(phone);

    setGpUser(dispatch, values);
  };

  const onHandleAddPoint = (id, referralCode, name, email, phone) => {
    const values = {
      id,
      referralCode,
      name,
      email,
      phone,
    };

    setGpUser(dispatch, values);
    onOpenModal();
  };

  const addPointModal = () => {
    const values = {
      id: "",
      referralCode: "",
      name: "",
      email: "",
      phone: "",
    };

    setGpUser(dispatch, values);
    onOpenModal();
  };

  useEffect(() => {
    if (growthPoint.users) {
      setAllData([...growthPoint.users]);
    }
  }, [growthPoint]);

  return (
    <Fragment>
      <Breadcrumb title="All Loyalty Point " parent="Loyalty Point" />

      <Container fluid={true}>
        <Card>
          <CardHeader>
            <Flex>
              <h5>Loyalty Point Management</h5>
              <Spacer />
              <Button
                colorScheme={"teal"}
                background="#0F9B9B"
                color="white"
                onClick={addPointModal}>
                Add Point
              </Button>
            </Flex>
          </CardHeader>
          <CardBody>
            <Flex direction={"row"}>
              <HStack>
                <FilterByDate url={pathname} />
              </HStack>
              <Spacer />
              <HStack>
                <FilterSort />
              </HStack>
            </Flex>

            <Flex mt={"1.5em"}>
              <InputGroup>
                <InputLeftElement
                  pointerEvents={"none"}
                  children={<Search2Icon color={"gray.300"} />}
                />
                <Input
                  placeholder="Search"
                  onChange={(event) => setSearchData(event.target.value)}
                  onKeyPress={searchDataHandler}
                  value={searchData}
                />
              </InputGroup>
            </Flex>
            <div className="clearfix"></div>
            <div
              id="batchDelete"
              className="category-table user-list order-table coupon-list-delete">
              <Datatable
                data={allData}
                columns={columns}
                pageSize={10}
                striped={true}
                center={true}
                noDataComponent={
                  <NoDataDisplay
                    headingText="There is no loyalty point data"
                    mainText="Reseller loyalty point data will be displayed here"
                  />
                }
              />
              <Flex mt={"1em"} justifyContent={"flex-end"}>
                <Box>
                  <Pagination
                    page={page}
                    totalPage={!isEmpty(growthPoint) && growthPoint.totalPage}
                    changePageHandler={changePageHandler}
                  />
                </Box>
              </Flex>
            </div>
          </CardBody>
        </Card>
      </Container>
      <AddPointModal isOpen={isOpenModal} onClose={onCloseModal} name={name} />
    </Fragment>
  );
};

export default navguard(ListLoyaltyPoint);
