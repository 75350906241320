import Axios from "../../services/axios";
import { EDIT_LOYALTY_REDEMPTION } from "../actionTypes";

export const patchLoyaltyRedemption = async (
  dispatch,
  { type, postData, id }
) => {
  try {
    let putData = {};
    if (type === "status") {
      putData.status = postData;
    } else {
      putData.adminNote = postData;
    }

    const { data } = await Axios({
      url: `/loyalty-redemption/${id}`,
      method: "PATCH",
      data: putData,
    });

    dispatch({
      type: EDIT_LOYALTY_REDEMPTION,
      payload: data,
    });
  } catch (err) {
    console.error(err);
  }
};
