import Axios from "../../services/axios";
import { SET_RESELLER_LEVEL } from "../actionTypes";

export function fetchResellerLevel() {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: "/reseller-discount/list?type=reseller level",
        method: "GET",
      });

      dispatch({
        type: SET_RESELLER_LEVEL,
        payload: data.data.resellerDiscounts,
      });
    } catch (err) {
      console.error(err);
    }
  };
}
