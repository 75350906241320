import { Flex, Box, Button } from "@chakra-ui/react";
import { useState } from "react";
import "./styles/section-tab.css";

const MiniTab = ({
  list,
  buttonShow,
  buttonWording,
  setSubTabActive,
  subTabActive,
  actionHandler,
  selectedRow,
  setPage,
}) => {
  const [buttonWordingEmptySelected] = useState("Process All Order");
  const changeSubTabActiveHandler = (value) => {
    setSubTabActive(value);
    setPage(0);
  }

  return (
    <Flex
      className="order-list__section-tab__container order-list__section-tab__container-payment"
      justify="space-between"
    >
      <Flex>
        {list.map((item) => {
          return (
            <Box
              key={item}
              className={`order-list__section-tab__item ${
                subTabActive === item && "tab-active"
              }`}
              onClick={() => changeSubTabActiveHandler(item)}
            >
              {item}
            </Box>
          );
        })}
      </Flex>
      {subTabActive === buttonShow && (
        <Button colorScheme="teal" onClick={actionHandler}>
          {selectedRow < 1 ? buttonWordingEmptySelected : buttonWording}
        </Button>
      )}
    </Flex>
  );
};

export default MiniTab;
