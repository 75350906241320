import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import Datatable from "react-data-table-component";
import FilterByDate from "./components/FilterByDate";
import { Link as LinkRouter, useLocation, useParams } from "react-router-dom";
import AddPointModal from "./components/AddPointModal";
import Pagination from "../order/components/pagination";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import dateFormat from "dateformat";
import { isEmpty } from "lodash";
import {
  fetchGpTotal,
  fetchGrowthPointLog,
  setGpUser,
} from "../../store/actions/growthPointAction";
import Axios from "../../services/axios";
import navguard from "../auth/navguard";
import FilterType from "./components/FilterType";
import NoDataDisplay from "./components/NoDataDisplay";

const DetailLoyaltyPoint = () => {
  const { pathname } = useLocation();
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const [page, setPage] = useState(0);
  const changePageHandler = (value) => {
    setPage(value.selected);
  };
  const dispatch = useDispatch();
  const { growthPointLog } = useSelector((state) => state.growthPoint);
  const { id } = useParams();
  const { search } = useLocation();
  const formatter = new Intl.NumberFormat("id-ID");
  const { gpUser } = useSelector(({ growthPoint }) => growthPoint);
  const { gpTotal } = useSelector(({ growthPoint }) => growthPoint);
  const [isFromDetail] = useState(true);
  const [gpCollected, setGpCollected] = useState({});

  const fetchDataCollected = async (id) => {
    try {
      const { data } = await Axios({
        url: `/admin/user/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      setGpCollected(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchDataLog = () => {
    const currentQueries = queryString.parse(search);
    const queries = queryString.stringify({
      ...currentQueries,
      sort: "createdAt,desc",
      page: page + 1,
      limit: 10,
    });
    dispatch(fetchGrowthPointLog(queries, id));
  };
  const columns = [
    {
      name: <b>Date</b>,
      header: <b>Date</b>,
      selector: "createdAt",
      style: {
        textAlign: "left",
      },
      sortable: true,
      cell: (row, index) => {
        return <span>{dateFormat(row.createdAt, "dd/mm/yyyy HH:MM:ss")}</span>;
      },
    },
    {
      name: <b>Source / Info</b>,
      header: <b>Source / Info</b>,
      selector: "pointInfo",
      cell: null,
      style: {
        textAlign: "center",
        textTransform: "capitalize",
      },
      sortable: true,
    },
    {
      name: <b>Type</b>,
      header: <b>Type</b>,
      selector: "pointType",
      cell: null,
      style: {
        textAlign: "center",
        textTransform: "capitalize",
      },
      sortable: true,
    },
    {
      name: <b>Loyalty Point</b>,
      header: <b>Loyalty Point</b>,
      selector: "pointAmount",
      cell: (row, index) => {
        return (
          <span>
            {row.pointAmount > 0 ? "+" : "-"}{" "}
            {formatter.format(Math.abs(row.pointAmount))} Point
          </span>
        );
      },
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
  ];

  const onHandleCloseModal = () => {
    onCloseModal();
  };

  useEffect(() => {
    fetchDataLog();
  }, [page]);

  useEffect(() => {
    if (page === 0) fetchDataLog();
    setPage(0);
  }, [search]);

  useEffect(() => {
    fetchGpTotal(dispatch, id);
  }, [growthPointLog]);

  useEffect(() => {
    setGpUser(dispatch, {
      id: gpTotal.id,
      referralCode: gpTotal.referralCode,
      name: `${gpTotal.firstName} ${gpTotal.lastName}`,
      email: gpTotal.email,
      phone: gpTotal.phone,
    });

    fetchDataCollected(gpTotal.id);
  }, [gpTotal]);

  return (
    <Fragment>
      <Breadcrumb title="Detail Loyalty Point" parent="Loyalty Point" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <Grid templateColumns="repeat(5, 1fr)" gap={4}>
              <GridItem w="100%" h="10" colSpan={3} rowSpan={1}>
                <h5>
                  Loyalty Point of <strong>{gpUser.name}</strong>
                </h5>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={2} rowSpan={2}>
                {/* <LinkRouter to="/loyalty/reseller-data">
                </LinkRouter> */}
                <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
                  <VStack p={4} m={[5, 0]}>
                    <Text color={"black"}>Total Loyalty Point</Text>
                    <Heading as="h2">
                      { !isEmpty(gpTotal) && gpTotal.totalGrowthPoint
                        ? formatter.format(gpTotal.totalGrowthPoint)
                        : "0" } LP
                    </Heading>
                  </VStack>
                </Box>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={1}>
                <Text mt={2}>Reseller Id </Text>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={2}>
                <InputGroup flex={1}>
                  <Input
                    type="text"
                    placeholder="reseller id"
                    value={gpUser.referralCode}
                    isDisabled
                  />
                </InputGroup>
              </GridItem>

              <GridItem w="100%" h="10" colSpan={1}>
                <Text mt={2}>Email</Text>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={2}>
                <InputGroup flex={1}>
                  <Input
                    type="email"
                    placeholder="email"
                    isDisabled
                    value={gpUser.email}
                  />
                </InputGroup>
              </GridItem>

              <GridItem w="100%" h="10" colSpan={1} rowSpan={2}>
                <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
                  <VStack p={4} m={[5, 0]}>
                    <Text color={"black"}>Loyalty Point Collected</Text>
                    <Heading as="h4" size="md">
                      {!isEmpty(gpCollected) && gpCollected.totalCollected
                        ? formatter.format(
                            parseInt(gpCollected.totalCollected.amount)
                          )
                        : "0"}{" "}
                      LP
                    </Heading>
                  </VStack>
                </Box>
              </GridItem>

              <GridItem w="100%" h="10" colSpan={1} rowSpan={2}>
                <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
                  <VStack p={4} m={[5, 0]}>
                    <Text color={"black"}>Loyalty Point Redeemed</Text>
                    <Heading as="h4" size="md">
                      {!isEmpty(gpCollected) && gpCollected.totalRedeemed
                        ? formatter.format(
                            parseInt(gpCollected.totalRedeemed?.amount)
                          )
                        : "0"}{" "}
                      LP
                    </Heading>
                  </VStack>
                </Box>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={1}>
                <Text mt={2}>Phone</Text>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={2}>
                <InputGroup flex={1}>
                  <Input
                    type="tel"
                    placeholder="phone"
                    isDisabled
                    value={gpUser.phone}
                  />
                </InputGroup>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={4} />
              <GridItem w="100%" h="10" colSpan={1}>
                <Button
                  w="100%"
                  colorScheme={"teal"}
                  mt={5}
                  onClick={onOpenModal}
                >
                  Add Point
                </Button>
              </GridItem>
            </Grid>
          </CardHeader>
          <CardBody>
            <Heading as="h2" size="md">
              Loyalty Point Log
            </Heading>
            <Divider
              mt={5}
              mb={5}
              borderWidth={1}
              color="black"
              borderColor="black"
              background="black"
            />
            <Flex direction={"row"} mb={5}>
              <FilterByDate url={pathname} />
              <Spacer />
              <FilterType url={pathname} />
            </Flex>
            <Datatable
              noHeader={true}
              data={growthPointLog?.growthPointLog}
              columns={columns}
              striped={true}
              center={true}
              noDataComponent={
                <NoDataDisplay
                  headingText="There is no loyalty point log data"
                  mainText="Reseller loyalty point collection and redemption data will be displayed here"
                />
              }
            />

            <Flex mt={"1em"} justifyContent={"flex-end"}>
              <Box>
                <Pagination
                  page={page}
                  totalPage={
                    !isEmpty(growthPointLog) && growthPointLog.totalPage
                  }
                  changePageHandler={changePageHandler}
                />
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </Container>
      <AddPointModal
        isOpen={isOpenModal}
        onClose={onHandleCloseModal}
        isFromDetail={isFromDetail}
        gpTotal={gpTotal}
        fetchDataLog={fetchDataLog}
      />
    </Fragment>
  );
};

export default navguard(DetailLoyaltyPoint);
