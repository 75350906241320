import React from "react";

import "../styles/_homeBanner.scss";
import {
  useToast,
  Text,
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
} from "@chakra-ui/react";
import Axios from "../../../services/axios";

const DeleteBanner = ({
  setIsLoading,
  getBannerList,
  isDelete,
  closeDeleteBanner,
  selectedBanner,
  isLandingPage,
}) => {
  const toast = useToast();
  const confirmDeleteBanner = async () => {
    setIsLoading(true);
    try {
      await Axios({
        method: "DELETE",
        url: !isLandingPage
          ? `/banner/${selectedBanner.id}`
          : `/main-landing/banner/${selectedBanner.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      await getBannerList();
      closeDeleteBanner();
      toast({
        title: "Success.",
        description: "Banner berhasil dihapus",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    } catch (error) {
      toast({
        title: "Error.",
        description: "Terjadi kesalahan mohon coba lagi",
        status: "warning",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal onClose={closeDeleteBanner} size="xl" isOpen={isDelete} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              style={{
                fontWeight: "bold",
                color: "black",
                fontSize: "18px",
                marginBottom: "16px",
              }}
            >
              Apakah anda yakin ingin menghapus banner ini?
            </Text>
            <img src={selectedBanner?.imagePath} alt={selectedBanner?.title} />
          </ModalBody>
          <ModalFooter>
            <Button mr={4} onClick={closeDeleteBanner}>
              Close
            </Button>
            <Button
              variant="ghost"
              justifyContent="flex-start"
              backgroundColor={"#FF8084"}
              color={"white"}
              onClick={confirmDeleteBanner}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteBanner;
