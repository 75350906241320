import React, { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import "./tooltip.css";

const TooltipIcon = ({ setIsTooltipVisible }) => (
  <svg
    onMouseEnter={() => setIsTooltipVisible(true)}
    onMouseLeave={() => setIsTooltipVisible(false)}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99998 1.33301C4.32398 1.33301 1.33331 4.32367 1.33331 7.99967C1.33331 11.6757 4.32398 14.6663 7.99998 14.6663C11.676 14.6663 14.6666 11.6757 14.6666 7.99967C14.6666 4.32367 11.676 1.33301 7.99998 1.33301ZM7.99998 13.333C5.05931 13.333 2.66665 10.9403 2.66665 7.99967C2.66665 5.05901 5.05931 2.66634 7.99998 2.66634C10.9406 2.66634 13.3333 5.05901 13.3333 7.99967C13.3333 10.9403 10.9406 13.333 7.99998 13.333Z"
      fill="#808080"
    />
    <path
      d="M7.33331 7.33366H8.66665V11.3337H7.33331V7.33366ZM7.33331 4.66699H8.66665V6.00033H7.33331V4.66699Z"
      fill="#808080"
    />
  </svg>
);

const Tooltip = ({ content }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <Box className="tooltip-container">
      <TooltipIcon setIsTooltipVisible={setIsTooltipVisible} />
      <Box
        className={`tooltip-content-container ${
          isTooltipVisible ? "fade-in-animation" : "hidden"
        }`}
      >
        <Text
          dangerouslySetInnerHTML={{ __html: content }}
          className="tooltip-text"
        ></Text>
      </Box>
    </Box>
  );
};

export default Tooltip;
