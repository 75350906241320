import { Select, Flex, Text } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useState } from "react";

const SortFilter = () => {
  const history = useHistory();
  const [sort, setSort] = useState("createdAt,desc");
  const { search } = useLocation();

  const changeSortHandler = (event) => {
    setSort(event.target.value);
    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify({
      ...currentQuery,
      sort: event.target.value,
    });

    history.push(`/promo/list-promo?${queries}`);
  };

  return (
    <Flex align="center">
      <Text mr="16px" color="black" fontSize="16px">
        Sort By
      </Text>
      <Select width="120px" onChange={changeSortHandler} value={sort}>
        <option value="createdAt,desc">Terbaru</option>
        <option value="createdAt,asc">Terlama</option>
      </Select>
    </Flex>
  );
};

export default SortFilter;
