import {
  GET_PRODUCT_SHOWCASE,
  GET_PRODUCT_SHOWCASE_EDIT,
  POST_NEW_PRODUCT_SHOWCASE,
  UPDATE_PRODUCT_SHOWCASE,
  SET_IMAGE_DESKTOP_PRODUCT_SHOWCASE,
  SET_IMAGE_MOBILE_PRODUCT_SHOWCASE,
  SET_MOVE_UP_PRODUCT_SHOWCASE,
  SET_MOVE_DOWN_PRODUCT_SHOWCASE,
  EMPTY_SHOWCASE_STATE,
} from "../actionTypes";
import Axios from "../../services/axios";

export function setProductShowcase(input) {
  return {
    type: GET_PRODUCT_SHOWCASE,
    payload: input,
  };
}

export function fetchProductShowcase() {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        method: "GET",
        url: `/category-showcase/highlight/list`,
      });

      dispatch(setProductShowcase(data.data.banners));
    } catch (error) {
      console.error(error);
    }
  };
}

export function editProductShowcase(value) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_PRODUCT_SHOWCASE_EDIT,
        payload: value,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function addProductShowcase(value) {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        method: "POST",
        url: `/category-showcase/hightlight`,
        data: value,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      dispatch({
        type: POST_NEW_PRODUCT_SHOWCASE,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export const deleteProductShowcase = async (id) => {
  try {
    await Axios({
      method: "DELETE",
      url: `/category-showcase/highlight/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export function updateProductShowcaseActive(value, id) {
  return async (dispatch) => {
    try {
      const { response } = await Axios({
        method: "PATCH",
        url: `/category-showcase/highlight/${id}/setActive`,
        data: value,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      dispatch({
        type: UPDATE_PRODUCT_SHOWCASE,
        payload: response,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateProductShowcaseValue(value, id) {
  return async (dispatch) => {
    try {
      const { response } = await Axios({
        method: "PATCH",
        url: `/category-showcase/highlight/${id}`,
        data: value,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      dispatch({
        type: UPDATE_PRODUCT_SHOWCASE,
        payload: response,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export const postImageDesktopProductShowcase = async (
  dispatch,
  image_value
) => {
  const imageData = await image_value;
  dispatch({
    type: SET_IMAGE_DESKTOP_PRODUCT_SHOWCASE,
    payload: imageData,
  });
};

export const postImageMobileProductShowcase = async (
  dispatch,
  imageMob_value
) => {
  const imageData = await imageMob_value;
  dispatch({
    type: SET_IMAGE_MOBILE_PRODUCT_SHOWCASE,
    payload: imageData,
  });
};

export const moveUpProductShowcase = async (dispatch, id) => {
  const { response } = await Axios({
    method: "POST",
    url: `/category-showcase/highlight/${id}/moveUp`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  dispatch({
    type: SET_MOVE_UP_PRODUCT_SHOWCASE,
    payload: response,
  });
};

export const moveDownProductShowcase = async (dispatch, id) => {
  const { response } = await Axios({
    method: "POST",
    url: `/category-showcase/highlight/${id}/moveDown`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  dispatch({
    type: SET_MOVE_DOWN_PRODUCT_SHOWCASE,
    payload: response,
  });
};

export function setProductLink(picData) {
  return () => {
    return new Promise((resolve, reject) => {
      Axios({
        url: "/product-media",
        method: "POST",
        data: picData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      })
        .then((data) => {
          resolve(data.data.data.productMedia.imageUrl);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export const emptyProductShowcaseState = (dispatch) => {
  dispatch({ type: EMPTY_SHOWCASE_STATE, payload: [] });
};
