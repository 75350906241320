import React from "react";

import '../styles/_homeBanner.scss';
import {
    Text,
    Button,
    Modal,
    ModalFooter,
    ModalBody,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalContent,
} from "@chakra-ui/react";

const DeleteImage = ({
    deleteMainImage,
    isImageDelete,
    closeDeleteImage,
    selectedImage,
    aspectRatio
}) => {
	return (
		<>
            <Modal onClose={closeDeleteImage} size="xl" isOpen={isImageDelete} isCentered>
                <ModalOverlay />
                    <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text
                            style={{
                                fontWeight: "bold",
                                color: "black",
                                fontSize: "18px",
                                marginBottom: "16px",
                            }}
                        >
                            Apakah anda yakin ingin menghapus banner ini?
                        </Text>
                        <img
                            src={selectedImage}
                            alt={'deleted'}
                            style={{
                                width: '100%',
                                aspectRatio: `${aspectRatio}`,
                                objectFit: 'cover'
                            }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button mr={4} onClick={closeDeleteImage}>Close</Button>
                        <Button 
                            variant="ghost"
                            justifyContent="flex-start"
                            backgroundColor={'#FF8084'}
                            color={'white'}
                            onClick={deleteMainImage}
                        >
                            Delete
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
	);
};

export default DeleteImage;
