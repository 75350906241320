import {
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalContent,
  ModalOverlay,
  Select,
  Button,
  Text,
  Box,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Percent } from "react-feather";
import { Col, Row } from "reactstrap";
import "../style/AddModal.css";
import { isEmpty } from "lodash";
import Axios from "../../../services/axios";

function AddModal({ isOpen, onClose, isEdit, data, fetchData }) {
  const [input, setInput] = useState({
    role: "",
    percent: "",
  });

  const [optionRole, setOptionRole] = useState([]);
  const toast = useToast();

  const fetchDataRole = async () => {
    try {
      const { data } = await Axios({
        url: "/admin/community/list?sort=code,desc",
        method: "GET",
      });

      let community = [];
      if (isEdit) {
        const response = await Axios({
          url: "/reseller-discount/list?type=reseller level",
          method: "GET",
        });
        community = response.data.data.resellerDiscounts.map((item) => {
          return {
            id: item.id,
            name: item.resellerLevel,
          };
        });
      }

      setOptionRole([...data.data.communities, ...community]);
    } catch (err) {
      console.error(err);
    }
  };

  //Clear state if modal close
  useEffect(() => {
    fetchDataRole();
  }, [isEdit]);

  useEffect(() => {
    if (!isOpen) {
      setInput({
        role: "",
        percent: "",
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isEmpty(data)) {
      setInput({
        ...data,
        role: data.communityId || data.id,
        percent: data.discountPercentage,
      });
    }
  }, [data]);

  const submit = async () => {
    let validation = submitValidation();
    if (validation.status !== "warning") {
      try {
        const sendData = {
          communityId: isEdit ? data.communityId : input.role,
          type: isEdit ? input.type : "role based",
          discountPercentage: input.percent,
          resellerLevel: isEdit ? input.resellerLevel : null,
        };
        await Axios({
          url: isEdit ? `/reseller-discount/${data.id}` : "/reseller-discount",
          method: isEdit ? "PATCH" : "POST",
          data: sendData,
        });
        fetchData();
        toast({
          title: "Sucess",
          description: validation.message,
          status: validation.status,
          position: "top",
          duration: 2000,
          isClosable: true,
        });

        onClose();
      } catch (err) {
        console.log(err.response);
        toast({
          title: "Warning",
          description: err.response.data.errors[0],
          status: "warning",
          position: "top",
          duration: 2000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Warning.",
        description: validation.message,
        status: validation.status,
        position: "top",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleChangeData = (e) => {
    let tempInput = { ...input };
    let value = e.target.value;
    if (e.target.type === "number") {
      if (value === "") {
        value = null;
      } else {
        if (parseFloat(e.target.value) > 100) {
          value = 100;
        } else if (parseFloat(e.target.value) < 0) {
          value = 0;
        } else {
          value = parseFloat(e.target.value);
        }
      }
    }

    tempInput[e.target.name] = value;

    setInput(tempInput);
  };

  const submitValidation = () => {
    let validObj = {
      status: "success",
      message: `Berhasil ${
        isEdit ? "mengubah" : "menambahkan"
      } reseller discount`,
    };

    if (!input.role) {
      validObj.status = "warning";
      validObj.message = "Mohon mengisi role terlebih dahulu";
    } else if (!input.percent) {
      validObj.status = "warning";
      validObj.message = "Mohon mengisi discount terlebih dahulu";
    }

    return validObj;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW={"845px"}>
        <Box className="reseller-discount__add-modal">
          <Text className="reseller-discount__add-modal-title">
            {isEdit ? "Edit" : "Create"} Discount Reseller
          </Text>

          <Row className="reseller-discount__add-modal-wrapper">
            <Col md="3">
              <Text className="reseller-discount__add-modal-subtitle">
                Reseller Role *
              </Text>
            </Col>
            <Col md="6">
              <Select
                placeholder="Choose reseller role ..."
                isDisabled={isEdit}
                value={input.role}
                name="role"
                onChange={(event) => handleChangeData(event)}
              >
                {optionRole.map((role) => (
                  <option value={role.id}>{role.name}</option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row className="reseller-discount__add-modal-wrapper">
            <Col md="3">
              <Text className="reseller-discount__add-modal-subtitle">
                Discount Percentage *
              </Text>
            </Col>
            <Col md="6">
              <InputGroup>
                <Input
                  placeholder="Discount percentage ..."
                  value={input.percent}
                  onChange={(event) => handleChangeData(event)}
                  name="percent"
                  type={"number"}
                />
                <InputRightAddon children={<Percent />} />
              </InputGroup>
            </Col>
          </Row>

          <Row className="reseller-discount__add-modal-wrapper">
            <Col md="3" />
            <Col md="6">
              <Button
                width={"100%"}
                bg="#ff8084"
                color="#ffffff"
                onClick={submit}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Box>
      </ModalContent>
    </Modal>
  );
}

export default AddModal;
