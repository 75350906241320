import { Box } from "@chakra-ui/react";
import { Col, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import one from "../../../assets/images/pro3/1.jpg";
import { postImageDesktopProductShowcase } from "../../../store/actions/productShowcaseAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ImageDesktopHandler = (props) => {
  const { imageIdx, imagePathShowcase } = props;

  const location = useLocation();

  const productShowcase = useSelector(
    (state) => state.productShowcase.showcaseEdit
  );

  const [imagePath, setImagePath] = useState(one);
  const [imageFile, setImageFile] = useState(one);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      location.pathname === "/content/edit-productshowcase" &&
      productShowcase
    ) {
      switch (imageIdx) {
        case 0:
          setImagePath(productShowcase.image1Path);
        case 1:
          setImagePath(productShowcase.image2Path);
        case 2:
          setImagePath(productShowcase.image3Path);
        case 3:
          setImagePath(productShowcase.image4Path);
        case 4:
          setImagePath(productShowcase.image5Path);
      }
    }
  }, [productShowcase]);

  useEffect(() => {
    setImagePath(imagePathShowcase);
  }, [imagePathShowcase]);

  const onChangeImage = (event) => {
    let reader = new FileReader();
    const image = event.target.files[0];

    reader.onload = () => {
      const pathOfImage = reader.result;
      setImagePath(pathOfImage);
      setImageFile(image);
    };
    reader.readAsDataURL(image);
  };

  useEffect(() => {
    const imgValue = {
      index: imageIdx,
      imageFile: imageFile,
      imagePath: imagePath,
    };
    postImageDesktopProductShowcase(dispatch, imgValue); // eslint-disable-next-line
  }, [imageFile]);

  return (
    <Box className="add-product" m={2} w={150} h={150}>
      <Row className="file-upload-product row">
        <Col className="col">
          <div className="box-input-file-full">
            <input
              className="upload"
              style={{
                width: "100%",
                aspectRatio: `16 / 9`,
                objectFit: "cover",
                cursor: "pointer",
              }}
              type="file"
              id="new-image-input"
              name="img"
              accept="image/*"
              required=""
              onChange={onChangeImage}
            />

            <img
              className="img-fluid image_zoom_1 blur-up lazyloaded"
              style={{
                width: "100%",
                aspectRatio: `16 / 9`,
                objectFit: "cover",
              }}
              alt=""
              src={imagePath}
              mr={3}
              mb={3}
            />
          </div>
        </Col>
      </Row>
    </Box>
  );
};

export default ImageDesktopHandler;
