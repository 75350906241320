import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { BarChart2, Copy, Edit } from "react-feather";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Axios from "../../../services/axios";
import "../style/TableCampaign.scss";
import CampaignAnalytics from "./CampaignAnalytics";
import ModalConfirmation from "./ModalConfirmation";
import dateFormat from "dateformat";

function TableCampaign({ data, fetchData }) {
  const now = dateFormat(new Date(), "yyyy-mm-dd");
  const [isOpenAnalytics, setIsOpenAnalytics] = useState(false);
  const [analyticData, setAnalyticData] = useState({});
  const [isOpenModalStatus, setIsOpenModalStatus] = useState(false);

  const handleOpenAnalytic = () => {
    setIsOpenAnalytics(true);
    setAnalyticData(data);
  };

  const handleCloseAnalytic = () => {
    setAnalyticData({});
    setIsOpenAnalytics(false);
  };
  const toast = useToast();
  const history = useHistory();

  const handleCopyUrl = () => {
    toast({
      title: "Success.",
      description: "Berhasil mensalin url.",
      status: "success",
      position: "top",
      duration: 1500,
      isClosable: true,
    });
  };

  const handleCloseModalStatus = () => {
    setIsOpenModalStatus(false);
  };

  const handleChangeStatus = async () => {
    try {
      await Axios({
        url: `/campaign/${data.id}/set-active`,
        method: "PATCH",
        data: {
          isActive: data.isActive ? false : true,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      toast({
        title: "Success.",
        description: "Berhasil mengubah status.",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
      fetchData();
      handleCloseModalStatus();
    } catch (err) {
      console.log(err);
    }
  };
  const handleEdit = (status, id) => {
    if (status) {
      toast({
        title: "Warning.",
        description: "Mohon mengubah status terlebih dahulu.",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    } else {
      history.push(`/campaign/edit-campaign/${id}`);
    }
  };

  return (
    <Accordion allowToggle className="campaign-accordion__container">
      <AccordionItem>
        <AccordionButton display="block" width="100%">
          <Row>
            <Col md="5">
              <Text textAlign={"start"}>{data?.name}</Text>
            </Col>
            <Col md="1">
              <Text
                className="data-campaign__status"
                bg={
                  now >= data.startDate && now <= data.endDate
                    ? "#59CE8F"
                    : "#FF1E00"
                }
                // onClick={() => setIsOpenModalStatus(true)}
              >
                {now >= data.startDate && now <= data.endDate
                  ? "Active"
                  : "Not Active"}
              </Text>
            </Col>
            <Col md="3">
              <Text>
                {data.startDate?.split("-").join("/")} -{" "}
                {data.endDate?.split("-").join("/")}
              </Text>
            </Col>
            <Col md="1">
              <Text>{data.campaignProducts.length} Products</Text>
            </Col>
            <Col md="1">
              <Flex
                align="center"
                className="data-campaign__bullet"
                onClick={handleOpenAnalytic}
              >
                <Box mr="4px">
                  <BarChart2 width="16px" />
                </Box>

                <Text>Analytics</Text>
              </Flex>
            </Col>
            <Col md="1">
              <Flex
                align="center"
                className="data-campaign__bullet"
                onClick={() => handleEdit(data.isActive, data.id)}
              >
                <Box mr="4px">
                  <Edit width="16px" />
                </Box>
                <Text>Edit</Text>
                <AccordionIcon />
              </Flex>
            </Col>
          </Row>
        </AccordionButton>

        <AccordionPanel className="expand-campaign-accordion__container">
          <Flex justify="space-between">
            <Text>Campaign URL</Text>
            <Text className="data-campaign__url">
              {`${process.env.REACT_APP_QBEE_USER_URL}/campaign/${data.slug}`}
            </Text>
            <CopyToClipboard
              text={`${process.env.REACT_APP_QBEE_USER_URL}/campaign/${data.slug}`}
              onCopy={handleCopyUrl}
            >
              <Flex
                align="center"
                className="data-campaign__bullet"
                cursor="pointer"
              >
                <Box mr="4px">
                  <Copy width="16px" />
                </Box>
                <Text>Copy</Text>
              </Flex>
            </CopyToClipboard>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
      <CampaignAnalytics
        isOpen={isOpenAnalytics}
        onClose={handleCloseAnalytic}
        dataDetail={analyticData}
      />

      <ModalConfirmation
        isOpen={isOpenModalStatus}
        onClose={handleCloseModalStatus}
        content="Apakah anda ingin mengubah status data?"
        buttonContent={"Submit"}
        handleClick={handleChangeStatus}
      />
    </Accordion>
  );
}

export default TableCampaign;
