import { Box, Flex, Text } from "@chakra-ui/react";
import styles from "./styles.module.css";
import { useState, useEffect } from "react";
import Axios from "../../../../services/axios";
import { forInRight } from "lodash";
import { StarIcon24, StarIcon16 } from "../../../common/icons";
import { useParams } from "react-router-dom";

const ReviewOverview = () => {
  const { slug } = useParams();
  const [rating, setRating] = useState(0);
  const [ratings, setRatings] = useState([]);
  const [totalRating, setTotalRating] = useState(0);

  const fetchRatingTemporary = async () => {
    const mainUrl = "product";

    try {
      const { data } = await Axios({
        url: `/${mainUrl}/${slug}/review-summary`,
        method: "GET",
      });

      const { byUserType, byRating } = data.data.summary;
      const getTotalRating = byUserType.reseller + byUserType.customer;

      setTotalRating(getTotalRating);

      let avgRating = 0;

      Object.keys(byRating).forEach((key) => {
        avgRating += byRating[key] * key;
      });
      avgRating = avgRating / getTotalRating;

      const arrTemp = [];
      forInRight(byRating, (value, key) => {
        arrTemp.push({
          rating: key,
          total: value,
          value: (value / getTotalRating) * 100,
        });
      });

      setRatings(arrTemp);
      setRating(avgRating);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRatingTemporary();
  }, [slug]);

  return (
    <Box className={styles["container"]}>
      {totalRating > 0 && (
        <Flex>
          <Box className={styles["container-left"]}>
            <Flex align="flex-end" justify="center" mb="14px" p="0 !important">
              <Text className={styles["number-bold"]}>{rating.toFixed(1)}</Text>
              <Text className={styles["number-grey"]}>/5.0</Text>
            </Flex>
            <Flex justify="center" mb="16px" p="0 !important">
              {[1, 2, 3, 4, 5].map((item) => (
                <Box mr={item < 5 && "8px"} key={item}>
                  <StarIcon24 rating={rating} index={item} />
                </Box>
              ))}
            </Flex>
            <Text className={styles["total-review__text"]}>
              {totalRating} ulasan untuk produk ini
            </Text>
          </Box>
          <Box className={styles["container-right"]}>
            {ratings.map((item, idx) => {
              return (
                <Flex
                  width="210px"
                  align="center"
                  key={item.rating}
                  p="0 !important"
                  mb={idx < ratings.length ? "4px" : 0}
                >
                  <StarIcon16 />
                  <Text className={styles["star-text"]}>{item.rating}</Text>
                  <Box className={styles["box-outside"]}>
                    <Box
                      className={styles["box-inside"]}
                      style={{
                        width: `${item.value}%`,
                      }}
                    ></Box>
                  </Box>
                  <Text className={styles["total-review__text-right"]}>
                    {item.total}
                  </Text>
                </Flex>
              );
            })}
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default ReviewOverview;
