import { Icon } from "@chakra-ui/react";
export default function dummy() {}

export const CircleIcon = (props) => (
  <Icon viewBox="0 0 200 200" {...props}>
    <path
      fill="currentColor"
      d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
    />
  </Icon>
);

export const StarIcon24 = ({ rating, index }) => {
  let gap = 100;
  if (index > rating) {
    if (rating - index > -1) {
      gap = 50;
    } else {
      gap = 0;
    }
  }
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {gap < 100 && gap > 0 && (
        <defs>
          <linearGradient id="grad2">
            <stop offset="0%" stopColor="#FFBB00" />
            <stop offset={`${gap}%`} stopColor="#FFBB00" />
            <stop offset={`${gap}%`} stopColor="#CCCCCC" />
            <stop offset="100%" stopColor="#CCCCCC" />
          </linearGradient>
        </defs>
      )}
      <path
        d="M22.447 9.17901C22.3842 8.99388 22.2685 8.83121 22.1142 8.71107C21.96 8.59094 21.7739 8.51861 21.579 8.50301L15.878 8.05001L13.411 2.58901C13.3325 2.41313 13.2047 2.26374 13.0431 2.15887C12.8815 2.05401 12.693 1.99815 12.5004 1.99805C12.3077 1.99794 12.1192 2.05359 11.9575 2.15828C11.7957 2.26296 11.6678 2.41221 11.589 2.58801L9.12203 8.05001L3.42103 8.50301C3.22948 8.51819 3.04636 8.58823 2.89358 8.70475C2.7408 8.82127 2.62482 8.97934 2.55952 9.16004C2.49422 9.34075 2.48236 9.53645 2.52537 9.72372C2.56838 9.91099 2.66443 10.0819 2.80203 10.216L7.01503 14.323L5.52503 20.775C5.47978 20.9703 5.49428 21.1747 5.56665 21.3617C5.63901 21.5486 5.76589 21.7095 5.93083 21.8235C6.09577 21.9374 6.29115 21.9991 6.49161 22.0007C6.69208 22.0022 6.88837 21.9434 7.05503 21.832L12.5 18.202L17.945 21.832C18.1154 21.9451 18.3162 22.0033 18.5207 21.9988C18.7251 21.9944 18.9232 21.9274 19.0884 21.8069C19.2536 21.6865 19.378 21.5183 19.4448 21.3251C19.5116 21.1318 19.5176 20.9228 19.462 20.726L17.633 14.326L22.169 10.244C22.466 9.97601 22.575 9.55801 22.447 9.17901V9.17901Z"
        fill={gap < 100 ? (gap === 0 ? "#CCCCCC" : "url(#grad2)") : "#FFBB00"}
      />
    </svg>
  );
};

export const StarIcon16 = ({ rating, index }) => {
  let gap = 100;
  if (index > rating) {
    if (rating - index > -1) {
      gap = 50;
    } else {
      gap = 0;
    }
  }
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {gap < 100 && gap > 0 && (
        <defs>
          <linearGradient id="grad2">
            <stop offset="0%" stop-color="#FFBB00" />
            <stop offset={`${gap}%`} stop-color="#FFBB00" />
            <stop offset={`${gap}%`} stop-color="#CCCCCC" />
            <stop offset="100%" stop-color="#CCCCCC" />
          </linearGradient>
        </defs>
      )}
      <path
        d="M15.1313 6.11934C15.0894 5.99592 15.0122 5.88747 14.9094 5.80738C14.8066 5.72729 14.6825 5.67908 14.5526 5.66868L10.7519 5.36668L9.10727 1.72601C9.05489 1.60875 8.9697 1.50916 8.86197 1.43925C8.75424 1.36934 8.62858 1.3321 8.50015 1.33203C8.37173 1.33196 8.24603 1.36906 8.13822 1.43885C8.03041 1.50864 7.94511 1.60814 7.8926 1.72534L6.24794 5.36668L2.44727 5.66868C2.31957 5.67879 2.19749 5.72548 2.09564 5.80316C1.99378 5.88084 1.91646 5.98622 1.87293 6.1067C1.8294 6.22717 1.82149 6.35763 1.85017 6.48248C1.87884 6.60733 1.94287 6.72127 2.0346 6.81068L4.84327 9.54868L3.84994 13.85C3.81977 13.9802 3.82944 14.1165 3.87768 14.2411C3.92592 14.3657 4.01051 14.473 4.12047 14.549C4.23043 14.6249 4.36068 14.6661 4.49433 14.6671C4.62797 14.6681 4.75883 14.629 4.86994 14.5547L8.49994 12.1347L12.1299 14.5547C12.2435 14.6301 12.3774 14.6689 12.5137 14.6659C12.65 14.6629 12.7821 14.6183 12.8922 14.538C13.0023 14.4577 13.0853 14.3456 13.1298 14.2167C13.1743 14.0879 13.1783 13.9485 13.1413 13.8173L11.9219 9.55068L14.9459 6.82934C15.1439 6.65068 15.2166 6.37201 15.1313 6.11934Z"
        fill={gap < 100 ? (gap === 0 ? "#CCCCCC" : "url(#grad2)") : "#FFBB00"}
      />
    </svg>
  );
};
