import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CloseButton,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

const GwpPromo = ({
  allProduct,
  handlePromoDataChange,
  handleDeleteProduct,
  isDisabled,
  item,
  idx,
}) => {
  const [selectedProduct, setSelectedProduct] = useState({});
  const [variantOption, setVariantOption] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState({});

  useEffect(() => {
    const findProduct = allProduct.find((data) => data.id === item.productId);
    if (findProduct) {
      if (findProduct.productType === "Variant") {
        setVariantOption(findProduct.productVariants);
      } else {
        setVariantOption([]);
      }
    }
    setSelectedProduct(findProduct);
  }, [item.productId]);

  useEffect(() => {
    if (variantOption) {
      const findVariant = variantOption.find(
        (data) => data.id === item.variantId
      );
      if (findVariant) {
        setSelectedVariant(findVariant);
      } else {
        setSelectedVariant({});
      }
    }
  }, [item.variantId, variantOption]);

  return (
    <>
      <Row className="input-promo__title-wrapper" key={idx}>
        <Col md="12">
          <Row>
            <Col md="4">
              <Flex flexDirection="column">
                <Text className="input-promo__title">Promo Product Select</Text>
                <Autocomplete
                  disablePortal
                  value={selectedProduct}
                  options={allProduct}
                  getOptionLabel={(product) => product.name}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <InputGroup>
                        <Input {...params.inputProps} />
                        <InputRightElement>
                          <ChevronDownIcon />
                        </InputRightElement>
                      </InputGroup>
                    </div>
                  )}
                  placement="bottom"
                  onChange={(event, value) =>
                    handlePromoDataChange(event, idx, true, value, "productId")
                  }
                  name="productId"
                  disabled={isDisabled}
                />
              </Flex>
            </Col>
            <Col md="4">
              {variantOption.length > 0 && (
                <Flex flexDirection="column">
                  <Text className="input-promo__title">
                    Promo Variant Select
                  </Text>
                  <Autocomplete
                    disablePortal
                    value={selectedVariant}
                    options={variantOption}
                    getOptionLabel={(product) => product.name}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <InputGroup>
                          <Input {...params.inputProps} />
                          <InputRightElement>
                            <ChevronDownIcon />
                          </InputRightElement>
                        </InputGroup>
                      </div>
                    )}
                    placement="bottom"
                    onChange={(event, value) =>
                      handlePromoDataChange(
                        event,
                        idx,
                        true,
                        value,
                        "variantId"
                      )
                    }
                    name="variantId"
                    disabled={isDisabled}
                  />
                </Flex>
              )}
            </Col>
            <Col md="3">
              <Flex flexDirection="column">
                <Text className="input-promo__title">Qty</Text>
                <InputGroup>
                  <InputLeftElement children={<Text>Qty</Text>} />
                  <Input
                    disabled={isDisabled}
                    value={item.qty}
                    type="number"
                    onChange={(event) => handlePromoDataChange(event, idx)}
                    name="qty"
                  />
                </InputGroup>
              </Flex>
            </Col>
            <Col
              md="1"
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Flex flexDirection="column">
                <Text className="input-promo__title">&nbsp;</Text>
                {!isDisabled && (
                  <CloseButton onClick={() => handleDeleteProduct(idx)} />
                )}
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default GwpPromo;
