import { RepeatIcon } from "@chakra-ui/icons";
import { Flex, IconButton } from "@chakra-ui/react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "./styles/PublishSchedule.css";
import { cloneDeep } from "lodash";

function PublishSchedule({ inputData, setInputData }) {
  const changeDateHandler = ({ event, type }) => {
    const date = `${event.getFullYear()}-${
      event.getMonth() + 1 < 10
        ? `0${event.getMonth() + 1}`
        : event.getMonth() + 1
    }-${event.getDate() < 10 ? `0${event.getDate()}` : event.getDate()}`;
    let tempInput = cloneDeep(inputData);

    if (type === "min") {
      tempInput.startDate = date;
    } else if (type === "max") {
      tempInput.endDate = date;
    }
    setInputData(tempInput);
  };

  const handleClearDate = () => {
    setInputData({ ...inputData, startDate: "", endDate: "" });
  };

  return (
    <Flex className="campaign-list__datepicker__container">
      <DatePicker
        selected={
          inputData.startDate
            ? new Date(inputData.startDate)
            : inputData.startDate
        }
        onChange={(event) => changeDateHandler({ event, type: "min" })}
        dateFormat="dd/MM/yyyy"
        maxDate={new Date(inputData.endDate)}
      />
      -
      <DatePicker
        selected={
          inputData.endDate ? new Date(inputData.endDate) : inputData.endDate
        }
        onChange={(event) => changeDateHandler({ event, type: "max" })}
        dateFormat="dd/MM/yyyy"
        minDate={new Date(inputData.startDate)}
      />
      <IconButton
        colorScheme="teal"
        variant="outline"
        onClick={handleClearDate}
        icon={<RepeatIcon />}
      />
    </Flex>
  );
}

export default PublishSchedule;
