import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Input, Select, Tag } from "@chakra-ui/react";
import PropTypes from "prop-types";
import Axios from "../../../services/axios";

const ProductDataGeneral = ({
  product,
  changeHandler,
  keyPress,
  listTag,
  deleteTag,
  changeProductLabel,
}) => {
  const [tag, setTag] = useState("");

  const [selectedBrand, setSelectedBrand] = useState(
    product.brandId ? product.brandId : ""
  );
  const [selectedGroupCategory, setSelectedGroupCategory] = useState(
    product.categoryGroupId ? product.categoryGroupId : ""
  );
  const [selectedCategory, setSelectedCategory] = useState(
    product.productCategory ? product.productCategory : ""
  );

  const [listBrand, setListBrand] = useState([]);
  const [filteredListSeries, setFilteredListSeries] = useState([]);
  const [listGroupCategory, setListGroupCategory] = useState([]);
  const [filteredListSubCategory, setFilteredListSubCategory] = useState([]);
  const [filteredListCategory, setFilteredListCategory] = useState([]);

  const tagKeyPress = (e) => {
    if (e.key === "Enter") {
      keyPress(e, tag);
      setTag("");
    }
  };

  const tagChangeHandler = (e) => {
    setTag(e.target.value);
  };

  const brandChangeHandler = (e) => {
    changeHandler(e);
    setSelectedBrand(e.target.value);
  }

  const categoryGroupChangeHandler = (e) => {
    changeHandler(e);
    setSelectedGroupCategory(e.target.value);
  }

  const categoryChangeHandler = (e) => {
    changeHandler(e);
    setSelectedCategory(e.target.value);
  };

  useEffect(() => {
    const fetchBrand = async () => {
      try {
        let result = await Axios({
          method: "GET",
          url: `/brand`,
        });
        setListBrand(result.data.data.brands);
      } catch (e) {
        setListBrand([]);
      }
    };

    fetchBrand();
  }, []);

  useEffect(() => {
    const fetchBrandSeries = async () => {
      let tempBrand = selectedBrand;
      if (product.brandId) {
        tempBrand = product.brandId;
      }
      if (tempBrand === "") {
        setFilteredListSeries([]);
      } else {
        try {
          let result = await Axios({
            method: "GET",
            url: "/series",
          });

          let tempSeriesData = []

          result.data.data.seriesList.forEach((serie) => {
            if (serie?.brand?.id === tempBrand) {
              tempSeriesData.push(serie)
            }
          });

          setFilteredListSeries(tempSeriesData);
        } catch (error) {
          console.error(error);
          setFilteredListSeries([]);
        }
      }
    };

    fetchBrandSeries();
  }, [selectedBrand, product]);

  useEffect(() => {
    const fetchGroupCategory = async () => {
      try {
        let result = await Axios({
          method: "GET",
          url: `/category-group`,
        });
        setListGroupCategory(result.data.data.categoryGroups);
      } catch (e) {
        setListGroupCategory([]);
      }
    };

    fetchGroupCategory();
  }, []);

  useEffect(() => {
    const fetchCategory = async () => {
      let tempGroupCategory = selectedGroupCategory;
      if (product.categoryGroupId) {
        tempGroupCategory = product.categoryGroupId;
      }
      if (tempGroupCategory === "") {
        setFilteredListCategory([]);
      } else {
        try {
          let result = await Axios({
            method: "GET",
            url: "/category/list",
          });

          let tempCategoriesData = []

          result.data.data.categories.forEach((category) => {
            if (category?.categoryGroup?.id === tempGroupCategory) {
              tempCategoriesData.push(category)
            }
          });

          setFilteredListCategory(tempCategoriesData);
        } catch (error) {
          console.error(error);
          setFilteredListCategory([]);
        }
      }
    };

    fetchCategory();
  }, [selectedGroupCategory, product]);

  useEffect(() => {
    const fetchSubCategory = async () => {
      let tempCategory = selectedCategory;
      if (product.productCategory) {
        tempCategory = product.productCategory;
      }
      if (tempCategory === "") {
        setFilteredListSubCategory([]);
      } else {
        try {
          let result = await Axios({
            method: "GET",
            url: `/category/${tempCategory}/subcategory/list`,
          });

          const tempSubCateg = [];
          result.data.data.subCategories.forEach((subCategory) => {
            tempSubCateg.push(subCategory.name);
          });

          setFilteredListSubCategory(tempSubCateg);
        } catch (error) {
          console.error(error);
          setFilteredListSubCategory([]);
        }
      }
    };
    fetchSubCategory();
  }, [selectedCategory, product]);

  return (
    <>
      <Row style={{ padding: "16px" }}>
        <Col xl={6} style={{ marginBottom: "12px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Brand
          </p>
          <Select
            name="brandId"
            onChange={(e) => brandChangeHandler(e)}
            flex="3"
            value={product.brandId}
          >
            <option value=""></option>
            {listBrand?.map((brand) => (
              <option key={brand.name} value={brand.id}>
                {brand.name}
              </option>
            ))}
          </Select>
        </Col>
        <Col xl={6} style={{ marginBottom: "12px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Brand Series
          </p>
          <Select
            name="seriesId"
            onChange={(e) => changeHandler(e)}
            flex="3"
            value={product.seriesId}
            isDisabled={
              selectedBrand === "" && product.brandId === ""
            }
          >
            <option value=""></option>
            {filteredListSeries?.map((serie) => (
              <option key={serie.name} value={serie.id}>
                {serie.name}
              </option>
            ))}
          </Select>
        </Col>
        <Col xl={12} style={{ marginBottom: "8px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Category Group
          </p>
          <Select
            name="categoryGroupId"
            onChange={(e) => categoryGroupChangeHandler(e)}
            flex="3"
            value={product.categoryGroupId}
          >
            <option value=""></option>
            {listGroupCategory?.map((categoryGroup) => (
              <option key={categoryGroup.name} value={categoryGroup.id}>
                {categoryGroup.name}
              </option>
            ))}
          </Select>
        </Col>
        <Col xl={6} style={{ marginBottom: "8px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Category
          </p>
          <Select
            name="productCategory"
            onChange={(e) => categoryChangeHandler(e)}
            flex="3"
            value={product.productCategory}
            isDisabled={
              selectedGroupCategory === "" && product.categoryGroupId === ""
            }
          >
            <option value=""></option>
            {filteredListCategory?.map((category) => (
              <option key={category.name} value={category.name}>
                {category.name}
              </option>
            ))}
          </Select>
        </Col>
        <Col xl={6} style={{ marginBottom: "8px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Sub Category
          </p>

          <Select
            name="productSubCategory"
            onChange={(e) => changeHandler(e)}
            flex="3"
            isDisabled={
              selectedCategory === "" && product.productCategory === ""
            }
            value={product.productSubCategory}
          >
            <option value=""></option>
            {filteredListSubCategory?.map((subCategory) => (
              <option key={subCategory} value={subCategory}>
                {subCategory}
              </option>
            ))}
          </Select>
        </Col>
        <Col xl={6} style={{ marginBottom: "8px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Product Label
          </p>
          <Select flex="3" onChange={changeProductLabel} value={product.label}>
            <option value="No Label">No Label</option>
            <option value="Terlaris">Terlaris</option>
            <option value="Baru">Baru</option>
          </Select>
        </Col>
        <Col
          xl={6}
          style={{
            marginBottom: "24px",
          }}
        ></Col>
        <Col xl={12}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Tag
          </p>
        </Col>
        <Col
          xl={6}
          style={{
            marginBottom: "24px",
          }}
        >
          <Input
            placeholder="Tag"
            name="productTag"
            value={tag}
            onChange={(e) => tagChangeHandler(e)}
            onKeyDown={(e) => tagKeyPress(e)}
          />
        </Col>
        <Col xl={6}>
          {listTag.map((tag, index) => (
            <Tag
              key={index}
              cursor="pointer"
              mr={2}
              onClick={() => deleteTag(index)}
            >
              {tag}
            </Tag>
          ))}
        </Col>
      </Row>
    </>
  );
};

ProductDataGeneral.propTypes = {
  deleteTag: PropTypes.func,
  changeHandler: PropTypes.func,
  keyPress: PropTypes.func,
  listTag: PropTypes.array,
};

ProductDataGeneral.defaultProps = {
  deleteTag: () => {},
  changeHandler: () => {},
  keyPress: () => {},
  listTag: [],
};

export default ProductDataGeneral;
