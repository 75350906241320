import { Box } from '@chakra-ui/react';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import styles_wysiwyg from "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Card, CardBody, Col, Row } from 'reactstrap';

const SectionDescription = ({
  editorDescriptionChangeHandler,
  editorStateDesc,
  config
}) => {

  return (
    <Card>
      <CardBody>
        <Row>
          <Col xl={12} style={{ marginBottom: "16px" }}>
            <Box
              style={{
                width: "100%",
                borderBottomWidth: "1px",
                marginBottom: "2rem"
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "black",
                }}
              >
                Description
              </p>
            </Box>

            <div className="description-sm">
              <Editor
                editorState={editorStateDesc}
                onEditorStateChange={editorDescriptionChangeHandler}
                editorStyle={{ padding: "5px" }}
                handlePastedText={() => false}
                toolbarClassName={styles_wysiwyg["toolbarClassName"]}
                wrapperClassName={styles_wysiwyg["wrapperClassName"]}
                editorClassName={styles_wysiwyg["editorClassName"]}
                toolbar={config}
              />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default SectionDescription;