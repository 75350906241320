import React, { useEffect } from "react";

import { Button, Text, Flex } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { Edit3 } from "react-feather";
import { warehouseAction } from "../../store/actions/warehouseAction";
import { useDispatch, useSelector } from "react-redux";

import "./styles/list.scss";
import navguard from "../auth/navguard";

const ListWarehouse = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const warehouses = useSelector((state) => state.warehouse.warehouses);
  useEffect(() => {
    dispatch(warehouseAction());
  }, [dispatch]);

  const goToAddWarehouse = () => {
    history.push("/warehouse/create-warehouse");
  };

  const goToEditHandler = (id) => {
    history.push("/warehouse/edit-warehouse/" + id);
  };

  const goToDetailDC = (id) => {
    history.push(`/warehouse/list-warehouse/${id}`);
  };

  return (
    <div className="list-warehouse__container">
      <div className="list-warehouse__header">
        <h2 className="title">Stock Management</h2>
        <Button
          className="button-add__warehouse"
          id="add-warehouse"
          onClick={goToAddWarehouse}
        >
          Add Warehouse
        </Button>
      </div>
      <div className="list-warehouse__body">
        <h2 className="title">Warehouse List</h2>
        <div className="list-warehouse__wrapper">
          {warehouses.length > 0 &&
            warehouses.map((warehouse) => {
              return (
                <div className="list-warehouse__item">
                  <Text
                    fontSize="18px"
                    fontWeigth="600"
                    cursor="pointer"
                    onClick={() => goToDetailDC(warehouse.id)}
                  >
                    {warehouse.warehouseName}
                  </Text>
                  <div className="list-warehouse__item-right">
                    <Flex flexDirection="column" mr="64px">
                      <Text>{warehouse.totalNotAvailableStock} SKU</Text>
                      <Text>Out Of Stock</Text>
                    </Flex>
                    <Flex flexDirection="column" mr="64px">
                      <Text>{warehouse.totalAvailableStock} SKU</Text>
                      <Text>Ready Stock</Text>
                    </Flex>
                    <Edit3
                      color="#ff8084"
                      fontSize="18px"
                      marginRight="32px"
                      cursor="pointer"
                      onClick={() => goToEditHandler(warehouse.id)}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default navguard(ListWarehouse);
