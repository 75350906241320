import {
  Box,
  Button,
  CloseButton,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { isEmpty, isNumber } from "lodash";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import {
  warehouseProductAction,
  warehouseProductUpdate,
} from "../../store/actions/warehouseAction";
import { useParams } from "react-router-dom";

const ImportModal = ({ isOpen, onClose, setIsLoading }) => {
  const { id } = useParams();
  const toast = useToast();
  const dispatch = useDispatch();
  const productsImport = useSelector(
    (state) => state.warehouse.warehouseProductsImport
  );
  const [file, setFile] = useState({});
  const [invalidRows, setInvalidRows] = useState([]);
  const [importedData, setImportedData] = useState([]);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          `.xlsx`,
        ],
      },
      maxFiles: 1,
    });

  const closeModal = () => {
    setFile({});
    setInvalidRows([]);
    setImportedData([]);
    onClose();
  };

  const validateData = (data) => {
    const invalidMessages = [];
    const validData = [];
    let isInvalid;

    data.forEach((each) => {
      isInvalid = false;
      if (each["Product Type"] === "Variant") {
        if (isEmpty(each["Variant ID"]) || isEmpty(each["Product ID"])) {
          isInvalid = true;
          invalidMessages.push({
            message: `${each["Product Name"]} - ${each["Variant Name"]} (IDs) : Produk ID atau variant ID tidak valid`,
          });
        } else {
          const findProduct = productsImport.find(
            (product) =>
              product.productId === each["Product ID"] &&
              product.productVariantId === each["Variant ID"]
          );
          if (isEmpty(findProduct)) {
            isInvalid = true;
            invalidMessages.push({
              message: `${each["Product Name"]} (Not found) : Produk dengan produk id ${each["Product ID"]} dan variant id ${each["Variant ID"]}tidak ditemukan`,
            });
          }
        }

        if (isEmpty(each["Odoo Code"]) && !isNumber(each["Odoo Code"])) {
          isInvalid = true;
          invalidMessages.push({
            message: `${each["Product Name"]} - ${each["Variant Name"]} (Odoo Code) : Odoo code tidak valid`,
          });
        } else {
          const findOdoo = productsImport.find(
            (variant) => variant.productVariantOdooCode === each["Odoo Code"]
          );
          if (isEmpty(findOdoo)) {
            isInvalid = true;
            invalidMessages.push({
              message: `${each["Product Name"]} - ${each["Variant Name"]} (Odoo Code) : Odoo code tidak ditemukan`,
            });
          }
        }
      } else {
        if (isEmpty(each["Product ID"])) {
          isInvalid = true;
          invalidMessages.push({
            message: `${each["Product Name"]} (IDs) : Produk ID tidak valid`,
          });
        } else {
          const findProduct = productsImport.find(
            (product) => product.productId === each["Product ID"]
          );
          if (isEmpty(findProduct)) {
            isInvalid = true;
            invalidMessages.push({
              message: `${each["Product Name"]} (Not found) : Produk dengan produk id ${each["Product ID"]} tidak ditemukan`,
            });
          }
        }

        if (isEmpty(each["Odoo Code"]) && !isNumber(each["Odoo Code"])) {
          isInvalid = true;
          invalidMessages.push({
            message: `${each["Product Name"]} (Odoo Code) : Odoo code tidak valid`,
          });
        } else {
          const findOdoo = productsImport.find(
            (product) => product.productOdooCode === each["Odoo Code"]
          );
          if (isEmpty(findOdoo)) {
            isInvalid = true;
            invalidMessages.push({
              message: `${each["Product Name"]} (Odoo Code) : Odoo code tidak ditemukan`,
            });
          }
        }
      }

      if (!isInvalid) {
        validData.push(each);
      }
    });

    setInvalidRows(invalidMessages);
    return validData;
  };

  const parseData = (data) => {
    const temp = data.map((each) => {
      return {
        productId: each["Product ID"],
        productVariantId: each["Variant ID"],
        availableStock: each["Available Stock"],
        minSafetyStock: each["Safety Stock"],
        qcStock: each["Safety Stock"],
      };
    });

    setImportedData(temp);
  };

  const submitImportData = async () => {
    setIsLoading(true);
    await dispatch(
      warehouseProductUpdate({
        warehouseId: id,
        stock: [...importedData],
      })
    );
    await dispatch(warehouseProductAction({ id, isInitiated: true }));
    setIsLoading(false);
    toast({
      title: "Success.",
      description: "Berhasil mengedit product.",
      status: "success",
      position: "top",
      duration: 3000,
      isClosable: true,
    });
    closeModal();
  };

  useEffect(() => {
    if (!isEmpty(file)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        const validData = validateData(json);
        parseData(validData);
      };
      reader.readAsArrayBuffer(file);
    }
  }, [file]);

  useEffect(() => {
    if (!isEmpty(acceptedFiles)) {
      setFile(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  const handleFileRejection = () => {
    const findTooMany = fileRejections.find(
      (file) => file.errors[0].code === "too-many-files"
    );
    if (!isEmpty(findTooMany)) {
      return "Error: Hanya bisa mengupload satu file saja";
    }
    const findInvalid = fileRejections.find(
      (file) => file.errors[0].code === "file-invalid-type"
    );
    if (!isEmpty(findInvalid)) {
      return "Error: Hanya bisa mengupload tipe file .xlsx saja";
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Import Stock
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          {!isEmpty(file) ? (
            <Flex alignItems="center" flexDirection="column">
              <Flex
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="120px"
                border="3px dashed #ff8084"
                borderRadius="8px"
                textAlign="center"
                flexDirection="column"
                position="relative"
              >
                <CloseButton
                  position="absolute"
                  top="0"
                  right="0"
                  onClick={() => setFile({})}
                />
                <Text>Uploaded Files:</Text>
                <Text>{file.name}</Text>
              </Flex>
              {invalidRows.length > 0 && (
                <Box width="100%" textAlign="left">
                  <Text mt={4}>Note :</Text>
                  <Box width="100%" maxHeight="30vh" overflowY="auto">
                    <li>
                      {invalidRows.map((row, index) => (
                        <ul key={index}>
                          <Text>
                            {index + 1}. {row.message}
                          </Text>
                        </ul>
                      ))}
                    </li>
                  </Box>
                </Box>
              )}
              <Flex flexDirection="row">
                <Button
                  backgroundColor="white"
                  color="#ff8084"
                  border="2px solid #ff8084"
                  mr="32px"
                  mt="16px"
                  width="180px"
                  onClick={() => setFile({})}
                >
                  Kembali
                </Button>
                <Button
                  disabled={importedData.length < 1}
                  backgroundColor="#ff8084"
                  color="white"
                  mt="16px"
                  width="180px"
                  onClick={submitImportData}
                >
                  Submit
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Box>
              <Box {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>
                  Masukkan file stok di sini atau klik disini untuk memilih file
                </p>
              </Box>
              {!isEmpty(fileRejections) && (
                <Text ml="8px" mt="16px" color="#ff8084" fontWeight="bold">
                  {handleFileRejection()}
                </Text>
              )}
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImportModal;
