import { Flex, Spacer, IconButton, useToast } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import React from "react";
import { Card, CardBody, Row } from "reactstrap";
import "../styles/AddNewProduct.css";
import one from "../../../assets/images/pro3/1.jpg";
import Axios from "../../../services/axios";
import BannerImage from "./BannerImage";

function SectionBanner({ bannerImage, setBannerImage }) {
  const imageMaxSize = 2000000;
  const toast = useToast();
  const addBannerGallery = () => {
    let temp = [...bannerImage];
    temp.push({
      image: one,
    });
    setBannerImage(temp);
  };

  const handleImageChange = async (value, i) => {
    try {
      value.preventDefault();
      if (value.currentTarget.files.length > 0) {
        if (value.currentTarget.files.item(0).size > imageMaxSize) {
          toast({
            title: "Error.",
            description: "Mohon upload gambar berukuran kurang dari 2MB",
            status: "warning",
            position: "top",
            duration: 3000,
            isClosable: true,
          });

          value.currentTarget.value = null;
        } else {
          const tempImage = [...bannerImage];
          const image = value.target.files[0];
          let fileData = new FormData();
          fileData.append("image", image);
          const { data } = await Axios({
            url: "/product-media",
            method: "POST",
            data: fileData,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });
          toast({
            title: "Success.",
            description: "Banner Image berhasil di upload",
            status: "success",
            position: "top",
            duration: 2000,
            isClosable: true,
          });
          tempImage[i].image = data.data.productMedia.imageUrl;
          setBannerImage(tempImage);
        }
      }
    } catch (err) {
      console.error(err);
      const { data } = err.response;
      toast({
        title: "Warning.",
        description: data?.errors[0]?.message,
        status: "warning",
        position: "top",
        duration: 2000,
        isClosable: true,
      });
    }
  };
  const handleDeleteBanner = (i) => {
    const tempData = [...bannerImage];
    let result = [];
    tempData.forEach((img, idx) => {
      if (i !== idx) {
        result.push(img);
      }
    });
    toast({
      title: "Success.",
      description: `Banner image ke-${i + 1} berhasil di hapus`,
      status: "success",
      position: "top",
      duration: 2000,
      isClosable: true,
    });
    setBannerImage(result);
  };

  const moveOrder = (fromIndex, toIndex) => {
    let tempData = [...bannerImage];
    let banner = tempData[fromIndex];
    tempData.splice(fromIndex, 1);
    tempData.splice(toIndex, 0, banner);
    setBannerImage(tempData);
  };

  return (
    <Card>
      <CardBody>
        <Flex>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "16px",
              color: "black",
            }}>
            Banner Gallery
          </p>
          <Spacer />
          <IconButton
            variant="outline"
            aria-label="Call Sage"
            fontSize="20px"
            icon={<MdAdd />}
            onClick={addBannerGallery}
          />
        </Flex>
        <div>
          <Row>
            {bannerImage.map((data, i) => {
              return (
                <BannerImage
                  key={i}
                  i={i}
                  data={data}
                  handleDeleteBanner={handleDeleteBanner}
                  handleImageChange={handleImageChange}
                  moveOrder={moveOrder}
                />
              );
            })}
          </Row>
        </div>
      </CardBody>
    </Card>
  );
}

export default SectionBanner;
