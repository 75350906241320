import React, { useEffect, useRef, useState } from "react";
import { Button, Flex, Input, Select, Text, useToast } from "@chakra-ui/react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import "./style/AddArticle.css";
import stylesEditor from "../products/styles/AddNewProduct.css";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw, RichUtils } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import styles_wysiwyg from "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useHistory, useParams } from "react-router-dom";
import Axios from "../../services/axios";
import navguard from "../auth/navguard";
import AlertModal from "../alertModal";

function AddArticle(props) {
  const [article, setArticle] = useState({
    topic: "",
    subtopic: "",
    isActive: false,
    title: "",
    body: "",
  });
  const { id } = useParams();

  const [editorStateArticle, setEditorStateArticle] = useState(() =>
    EditorState.createEmpty()
  );
  const [optionSubtopic, setOptionSubtopic] = useState([]);
  const toast = useToast();
  const history = useHistory();
  const [idModal, setIdModal] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const cancelRef = useRef();

  const onCloseModal = () => setIsOpenModal(false);

  const openAlertModal = (id) => {
    setIdModal(id);
    setIsOpenModal(true);
  };

  const editorArticleChangeHandler = (editorState) => {
    setEditorStateArticle(editorState);
    let tempArticle = { ...article };
    tempArticle.body = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setArticle(tempArticle);
  };

  /// Use To Edit Article Body After Fetch Data
  const convertHtmlToDraft = (articleHTML) => {
    let contentBlock = htmlToDraft(articleHTML);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const article = EditorState.createWithContent(contentState);
      return article;
    }
  };

  const _uploadImageCallBack = (file) => {
    // convert to base64
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onerror = function (error) {
      console.error("Error onload: ", error);
    };

    return new Promise((resolve, reject) => {
      reader.onload = function () {
        resolve({
          data: {
            link: reader.result,
          },
        });
      };
    });
  };

  const _toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(editorStateArticle, blockType));
  };

  const config = {
    image: {
      uploadCallback: _uploadImageCallBack,
      previewImage: true,
      alt: { present: true, mandatory: false },
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    },
    list: {
      inDropdown: true,
      toggleBlockType: _toggleBlockType,
      unordered: { className: stylesEditor["unordered-list"] },
      ordered: { className: stylesEditor["ordered-list"] },
    },
    textAlign: { inDropdown: true },
    blockType: {
      className: "demo-option-custom-wide",
      dropdownClassName: "demo-dropdown-custom",
    },
  };

  const fetchData = async () => {
    try {
      const { data } = await Axios({
        url: `/admin/article/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      let tempArticle = { ...article };
      let unusedKeys = [
        "createdAt",
        "helpCenterSubtopic",
        "id",
        "updatedAt",
        "thumbsUp",
        "thumbsDown",
      ];
      Object.keys(data.data.article).forEach((key) => {
        if (!unusedKeys.includes(key)) {
          tempArticle[key] = data.data.article[key];
        }
        if (key === "body") {
          setEditorStateArticle(convertHtmlToDraft(data.data.article[key]));
        }
      });

      setArticle(tempArticle);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDataSubtopic = async () => {
    try {
      const { data } = await Axios({
        url: `/admin/subtopic/list`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      let tempOptionSubtopic = data.data.subtopics.filter(
        (subtopic) => subtopic.topic === article.topic
      );
      setOptionSubtopic(tempOptionSubtopic);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id]);

  ///Clean Up Function
  useEffect(() => {
    return () => {
      let tempArticle = { ...article };
      Object.keys(tempArticle).forEach((key) => {
        tempArticle[key] = "";
      });

      setArticle("");
      setEditorStateArticle(() => EditorState.createEmpty());
      setOptionSubtopic([]);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchDataSubtopic();
    // eslint-disable-next-line
  }, [article.topic]);

  const handleChangeArticle = (e) => {
    let tempArticle = { ...article };
    tempArticle[e.target.name] = e.target.value;
    setArticle(tempArticle);
  };

  const validationSubmit = () => {
    let validation = {
      status: "success",
      message: `Berhasil ${id ? "mengubah" : "menambahkan"} article`,
    };

    let key = Object.keys(article);
    let index = 0;
    let isValid = true;

    while (key.length > index && isValid) {
      if (key[index] !== "isActive") {
        if (!article[key[index]]) {
          validation.status = "warning";
          validation.message = `Mohon mengisi ${key[index]} terlebih dahulu`;
          isValid = false;
        }
      }
      index++;
    }
    return validation;
  };

  const handleSave = async () => {
    let isValid = validationSubmit();
    if (isValid.status === "success") {
      try {
        if (id) {
          await Axios({
            url: `/admin/article/${id}`,
            method: "PUT",
            data: {
              ...article,
              isActive: article.isActive === "true",
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });
        } else {
          await Axios({
            url: `/admin/article`,
            method: "POST",
            data: {
              ...article,
              isActive: article.isActive === "true",
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });
        }
        toast({
          title: "Success",
          description: isValid.message,
          status: isValid.status,
          position: "top",
          duration: 1500,
          isClosable: true,
        });
        setTimeout(() => {
          history.push("/help-center/article");
        }, 2000);
      } catch (err) {
        console.log(err.response);
        toast({
          title: "Warning",
          description: err.response.data.errors[0].message,
          status: "warning",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Warning",
        description: isValid.message,
        status: isValid.status,
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  const deleteArticle = async ({ id, status }) => {
    if (!status) {
      try {
        await Axios({
          method: "DELETE",
          url: `/admin/article/${id}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });
        toast({
          title: "Success",
          description: "Berhasil menghapus",
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        fetchData();
        setTimeout(() => {
          history.push("/help-center/article");
        }, 2000);
      } catch (error) {
        console.log(error);
        toast({
          title: "Failed",
          description: "Gagal menghapus",
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Warning",
        description: "Mohon ubah status data menjadi deactive",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Breadcrumb
        title={id ? "Edit Article" : "Add Article"}
        parent="Article"
      />

      <Container fluid={true}>
        {id && (
          <Flex justifyContent={"end"} className="add-article__wrapper">
            <Button
              width={"250px"}
              className="help-center__article-button"
              onClick={() => openAlertModal(id)}
            >
              Delete
            </Button>
          </Flex>
        )}
        <Card>
          <CardBody>
            <Row className="add-article__wrapper">
              <Col md="2">
                <Text className="add-article__title">Topic: </Text>
              </Col>
              <Col md="4">
                <Select
                  placeholder="Choose Topic Here"
                  value={article.topic}
                  name="topic"
                  onChange={(event) => handleChangeArticle(event)}
                >
                  <option value="pengenalan">Pengenalan</option>
                  <option value="transaksi">Transaksi</option>
                  <option value="akun saya">Akun Saya</option>
                  <option value="promo">Keuntungan & Promo</option>
                  <option value="kebijakan">Akun & Website</option>
                </Select>
              </Col>
              <Col md="2" />
              <Col md="4">
                <Select
                  value={article.isActive}
                  name="isActive"
                  onChange={(event) => handleChangeArticle(event)}
                >
                  <option value={false}>Not Active</option>
                  <option value={true}>Active</option>
                </Select>
              </Col>
            </Row>

            <Row className="add-article__wrapper">
              <Col md="2">
                <Text className="add-article__title">Subtopic: </Text>
              </Col>

              <Col md="4">
                <Select
                  placeholder="Choose Subtopic Here"
                  value={article.subtopic}
                  name="subtopic"
                  disabled={!article.topic || optionSubtopic.length === 0}
                  onChange={(event) => handleChangeArticle(event)}
                >
                  {optionSubtopic.length > 0 &&
                    optionSubtopic.map((subtopic, idx) => (
                      <option key={idx} value={subtopic.id}>
                        {subtopic.subtopic}
                      </option>
                    ))}
                </Select>
              </Col>
            </Row>

            <Row className="add-article__wrapper">
              <Col md="2">
                <Text className="add-article__title">Article Title: </Text>
              </Col>

              <Col md="10">
                <Input
                  placeholder="Write Article Title Here ..."
                  value={article.title}
                  name="title"
                  onChange={(event) => handleChangeArticle(event)}
                />
              </Col>
            </Row>

            <Row className="add-article__wrapper">
              <Col md="2">
                <Text className="add-article__title">Article Body: </Text>
              </Col>

              <Col md="10">
                <div className="description-sm">
                  <Editor
                    editorState={editorStateArticle}
                    onEditorStateChange={editorArticleChangeHandler}
                    editorStyle={{ padding: "5px" }}
                    handlePastedText={() => false}
                    toolbarClassName={styles_wysiwyg["toolbarClassName"]}
                    wrapperClassName={styles_wysiwyg["wrapperClassName"]}
                    editorClassName={styles_wysiwyg["editorClassName"]}
                    toolbar={config}
                  />
                </div>
              </Col>
            </Row>

            <Row style={{ justifyContent: "center" }}>
              <Col md="3">
                <Button
                  width={"100%"}
                  onClick={() => handleSave()}
                  className="help-center__article-button"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <AlertModal
          isOpen={isOpenModal}
          cancelRef={cancelRef}
          onClose={onCloseModal}
          title={"Delete Article"}
          subtitle={
            "Apa kamu yakin? Anda tidak dapat membatalkan tindakan ini setelahnya."
          }
          alertHandler={() =>
            deleteArticle({ id: idModal, status: article.isActive })
          }
        />
      </Container>
    </>
  );
}

export default navguard(AddArticle);
