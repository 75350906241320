import React, { useState } from "react";

import { Link } from "react-router-dom";
import "../styles/Categories.scss";

import {
  Card,
  CardBody,
  Col,
  Row
} from "reactstrap";

import {
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Spacer,
  Flex,
  Box,
  Center,
  Button,
  IconButton,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  InputGroup,
  Input,
  InputRightElement,
  InputLeftElement,
} from "@chakra-ui/react";

import {
  Search2Icon,
  ChevronDownIcon,
} from '@chakra-ui/icons';

import {
  MdEdit,
  MdDelete,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown
} from "react-icons/md";

const Categories = ({
  query,
  searchType,
  searchByCategoryGroup,
  searchByCategory,
  searchBySubCategory,
  deleteCategory,
  deleteSubCategory,
  deleteGroupCategory,
  searchHandler,
  filteredGroupCategory,
  groupingCategory,
  groupingSubCategory,
  getCountCategoryGroupChild,
  getCountCategoryChild,
  updateCategorySequence,
  updateSubCategorySequence,
  updateGroupCategorySequence,
}) => {

  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedType, setSelectedType] = useState('');

  const deleteClicked = (item, type) => {
    if (type === "Group Category") {
      if (getCountCategoryGroupChild(item) > 0) {
        setIsErrorOpen(true);
        setErrorMessage("Kategori Grup tidak bisa dihapus, harap hapus kategori & sub kategori terlebih dahulu")
      }  else {
        openWarningModal(item, type)
      }
    } else if (type === "Category") {
      if (getCountCategoryChild(item) > 0) {
        setIsErrorOpen(true);
        setErrorMessage("Kategori tidak bisa dihapus, harap hapus sub kategori terlebih dahulu")
      }  else {
        openWarningModal(item, type)
      }
    } else {
      openWarningModal(item, type)
    }
  }

  const openWarningModal = (item, type) => {
    setIsWarningOpen(true);
    setSelectedCategory(item)
    setSelectedType(type);
  }

  const closeWarningModal = () => {
    setIsWarningOpen(false);
    setSelectedCategory(null)
  }

  const closeErrorModal = () => {
    setIsErrorOpen(false);
    setErrorMessage('');
  }

  const deleteHandler = () => {
    closeWarningModal();
    if (selectedType === "Category") {
      deleteCategory(selectedCategory);
    } else if (selectedType === "Sub Category") {
      deleteSubCategory(selectedCategory);
    } else if (selectedType === "Group Category") {
      deleteGroupCategory(selectedCategory);
    }
  }

	return (
		<>
      <Card>
          <CardBody>
              <Flex className="pb-16">
                  <Box width='50%'>
                    <p className="card-title">
                      Categories
                    </p>
                  </Box>
                  <Spacer />
                  <Box width='45%'>
                    <InputGroup size='md'>
                      <InputLeftElement pointerEvents="none">
                        <Search2Icon color="gray.300" />
                      </InputLeftElement>
                      <Input
                        type="text"
                        className="form-control search-bar"
                        defaultValue={query}
                        onChange={(e) => searchHandler(e)}
                        placeholder={
                          searchType === "Category Group" ? "Search Category Group" 
                          : searchType === "Category" ? "Search Category"
                          : searchType === "Sub Category" ? "Search Sub Category"
                          : null
                        }
                        mr='-1rem'
                      />
                      <InputRightElement width="3rem">
                      <Menu>
                        <MenuButton
                          as={Button}
                          roundedStart="0px"
                          variant="outline"
                          bgColor="transparent"
                          borderBottomColor="transparent"
                          borderTopColor="transparent"
                          borderRightColor="transparent"
                        >
                          <ChevronDownIcon />
                        </MenuButton>
                        <MenuList>
                          <MenuItem onClick={searchByCategoryGroup}>Search by Category Group</MenuItem>
                          <MenuItem onClick={searchByCategory}>Search by Category</MenuItem>
                          <MenuItem onClick={searchBySubCategory}>Search by Sub Category</MenuItem>
                        </MenuList>
                      </Menu>
                      </InputRightElement>
                    </InputGroup>
                  </Box>
              </Flex>
              <div className="clearfix"></div>
              <div id="basicScenario" className="category">
                <Modal isCentered isOpen={isWarningOpen} onClose={closeWarningModal}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalHeader textAlign="center">Apakah anda yakin ingin menghapus kategori grup / kategori / sub kategori ini?</ModalHeader>
                    <h1 style={{textAlign:"center"}}>{selectedCategory}</h1>
                    <Button
                      backgroundColor={'#FF8084'}
                      color={'white'}
                      w="70%"
                      marginLeft="auto"
                      marginRight="auto"
                      marginTop="24px"
                      marginBottom="24px"
                      className="button-main"
                      onClick={() => deleteHandler()}
                    >
                      Hapus
                    </Button>
                  </ModalContent>
                </Modal>

                <Modal isCentered isOpen={isErrorOpen} onClose={closeErrorModal}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalHeader textAlign="center">{errorMessage}</ModalHeader>

                    <Button
                      backgroundColor={'#FF8084'}
                      color={'white'}
                      w="70%"
                      marginLeft="auto"
                      marginRight="auto"
                      marginTop="24px"
                      marginBottom="24px"
                      className="button-main"
                      onClick={() => closeErrorModal()}
                    >
                      Tutup
                    </Button>
                  </ModalContent>
                </Modal>
                  {filteredGroupCategory.length > 0 ? (
                      <>
                          {filteredGroupCategory.map((item, indexCategory) => (
                              <Row key={item.name} className="groupcategory-row">
                                  <Col
                                      sm="12"
                                      style={{
                                          backgroundColor: 'rgba(255,128,132, 0.5)',
                                          paddingTop: "0.5em",
                                          paddingBottom: "0.5em",
                                      }}
                                  >
                                      <Flex className="pv-8">
                                          <Center>
                                              <p className="groupcategory-title">
                                                  {item.name}
                                              </p>
                                          </Center>
                                          <Spacer />
                                          <IconButton
                                              onClick={() => deleteClicked(item.name, 'Group Category')}
                                              variant="ghost"
                                              size="sm"
                                              icon={<Icon as={MdDelete} />}
                                          />
                                          <Link to={`/products/edit-group-category/${item.name.split(" ").join("-")}`}>
                                              <IconButton
                                                  variant="ghost"
                                                  size="sm"
                                                  icon={<Icon as={MdEdit} />}
                                              />
                                          </Link>
                                          <IconButton
                                              onClick={() => updateGroupCategorySequence(item, 'Increase')}
                                              disabled={
                                                  item.ordering === 1
                                                  || item.ordering === 0
                                                  || indexCategory === 0
                                              }
                                              variant="ghost"
                                              size="sm"
                                              fontSize='20px'
                                              icon={<Icon as={MdKeyboardArrowUp} />}
                                          />
                                          <IconButton
                                              onClick={() => updateGroupCategorySequence(item, 'Decrease')}
                                              disabled={
                                                  item.ordering === filteredGroupCategory.length 
                                                  || filteredGroupCategory.length === indexCategory + 1
                                              }
                                              variant="ghost"
                                              size="sm"
                                              fontSize='20px'
                                              icon={<Icon as={MdKeyboardArrowDown} />}
                                          />
                                      </Flex>
                                  </Col>
                                  <Col sm="12">
                                      {getCountCategoryGroupChild(item.id) > 0 && (
                                          <>
                                              {groupingCategory(item.id).map((cat, index) => (
                                              <Row key={cat.name}>
                                                  <Col
                                                      sm="12"
                                                      style={{ paddingLeft: "36px" }}
                                                  >
                                                      <Flex className="pv-16">
                                                          <Center>
                                                              <p className="category-title">
                                                                  {cat.name}
                                                              </p>
                                                          </Center>
                                                          <Spacer />
                                                          <IconButton
                                                              onClick={() => deleteClicked(cat.name, 'Category')}
                                                              variant="ghost"
                                                              size="sm"
                                                              icon={<Icon as={MdDelete} />}
                                                          />
                                                          <Link to={`/products/edit-category/${cat.name.split(" ").join("-")}`}>
                                                              <IconButton
                                                                  variant="ghost"
                                                                  size="sm"
                                                                  icon={<Icon as={MdEdit} />}
                                                              />
                                                          </Link>
                                                          <IconButton
                                                              onClick={() => updateCategorySequence(cat, 'Increase')}
                                                              disabled={
                                                                  cat.ordering === 1
                                                                  || cat.ordering === 0
                                                                  || index === 0
                                                              }
                                                              variant="ghost"
                                                              size="sm"
                                                              fontSize='20px'
                                                              icon={<Icon as={MdKeyboardArrowUp} />}
                                                          />
                                                          <IconButton
                                                              onClick={() => updateCategorySequence(cat, 'Decrease')}
                                                              disabled={
                                                                  item.ordering === groupingCategory(item.id).length 
                                                                  || groupingCategory(item.id).length === index + 1
                                                              }
                                                              variant="ghost"
                                                              size="sm"
                                                              fontSize='20px'
                                                              icon={<Icon as={MdKeyboardArrowDown} />}
                                                          />
                                                      </Flex>
                                                      {groupingSubCategory(cat.name).map((sub, index) => (
                                                          <Row key={sub.name}>
                                                              <Col sm="12"
                                                                  style={{
                                                                      backgroundColor: index % 2 === 0 ? 'rgba(255,128,132, 0.1)' : null
                                                                  }}
                                                              >
                                                                  <Row>
                                                                      <Col sm="12"
                                                                          style={{
                                                                              paddingLeft: "36px",
                                                                          }}
                                                                      >
                                                                          <Flex className="pv-16">
                                                                              <Center>
                                                                                  <p className="subcategory-title">
                                                                                      {sub.name}
                                                                                  </p>
                                                                              </Center>
                                                                              <Spacer />
                                                                              <IconButton
                                                                                  onClick={() => deleteClicked(sub.name, 'Sub Category')}
                                                                                  variant="ghost"
                                                                                  size="sm"
                                                                                  icon={<Icon as={MdDelete} />}
                                                                              />
                                                                              <Link to={`/products/edit-sub-category/${sub.name.split(" ").join("-")}`}>
                                                                                  <IconButton
                                                                                      variant="ghost"
                                                                                      size="sm"
                                                                                      icon={<Icon as={MdEdit} />}
                                                                                  />
                                                                              </Link>
                                                                              <IconButton
                                                                                  onClick={() => updateSubCategorySequence(sub, 'Increase')}
                                                                                  disabled={
                                                                                      sub.ordering === 1
                                                                                      || sub.ordering === 0
                                                                                      || index === 0
                                                                                  }
                                                                                  variant="ghost"
                                                                                  size="sm"
                                                                                  fontSize='20px'
                                                                                  icon={<Icon as={MdKeyboardArrowUp} />}
                                                                              />
                                                                              <IconButton
                                                                                  onClick={() => updateSubCategorySequence(sub, 'Decrease')}
                                                                                  disabled={
                                                                                      cat.ordering === groupingSubCategory(cat.name).length 
                                                                                      || groupingSubCategory(cat.name).length === index + 1
                                                                                  }
                                                                                  variant="ghost"
                                                                                  size="sm"
                                                                                  fontSize='20px'
                                                                                  icon={<Icon as={MdKeyboardArrowDown} />}
                                                                              />
                                                                          </Flex>
                                                                      </Col>
                                                                  </Row>
                                                              </Col>
                                                          </Row>
                                                      ))}
                                                  </Col>
                                              </Row>
                                              ))}
                                          </>
                                      )}
                                  </Col>
                              </Row>
                          ))}
                      </>
                  )
                  :
                  (
                    <Center m={8}>
                      There are no data to display
                    </Center>
                  )
                  }
              </div>
          </CardBody>
      </Card>
		</>
	);
};

export default Categories;
