import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { Input } from "@chakra-ui/react";

const ProductDataSKU = ({ product, changeHandler }) => {
  const [dataSKU, setDataSKU] = useState({
    sku: product.sku ? product.sku : "",
    barcode: product.barcode ? product.barcode : "",
    odooCode: product.odooCode ? product.odooCode : "",
  });
  const dataSkuHandler = (e) => {
    const temp = { ...dataSKU };
    temp[e.target.name] = e.target.value;
    setDataSKU(temp);
    changeHandler(e);
  };
  return (
    <>
      <Row style={{ padding: "16px" }}>
        <Col xl={6} style={{ marginBottom: "8px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            No SKU
          </p>
          <Input
            placeholder="No SKU"
            name="sku"
            onChange={(e) => dataSkuHandler(e)}
            value={dataSKU.sku}
          />
        </Col>
        <Col xl={6} style={{ marginBottom: "8px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Parent SKU
          </p>
          <p
            style={{
              fontSize: "24px",
              marginBottom: "8px",
              color: "black",
            }}
          >
            xxx-xxx-xxx
          </p>
        </Col>
        <Col xl={6} style={{ marginBottom: "8px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Barcode
          </p>
          <Input
            placeholder="Barcode"
            name="barcode"
            onChange={(e) => dataSkuHandler(e)}
            value={dataSKU.barcode}
          />
        </Col>
        <Col xl={6} style={{ marginBottom: "8px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Odoo Code Masuk
          </p>
          <Input
            placeholder="Odoo Code"
            name="odooCode"
            onChange={(e) => dataSkuHandler(e)}
            value={dataSKU.odooCode}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProductDataSKU;
