import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Center,
  Divider,
  Button,
  useDisclosure,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import "./style/feedback.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../order/components/pagination";
import navguard from "../auth/navguard";
import AddNewFaqs from "./components/addFaqs";
import EditFaqs from "./components/editFaqs";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  deleteQuestion,
  getFaqsAction,
  moveQuestionDown,
  moveQuestionUp,
  toggleQuestion,
} from "../../store/actions/faqsAction";
import { useDispatch, useSelector } from "react-redux";

function GeneralFAQ(props) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [editRowData, setEditRowData] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const toast = useToast();

  const { articles, totalPage, totalData } = useSelector(
    ({ faqs }) => faqs.faqs
  );

  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();

  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();

  const columns = [
    {
      dataField: "",
      text: "Urutan",
      style: { textAlign: "justify" },
      headerStyle: { textAlign: "center", width: "2em" },
      formatter: (cell, row, key) => {
        return (
          <Box>
            <Flex flexDirection="column">
              <IconButton
                isDisabled={row.order === 1}
                icon={<ChevronUpIcon />}
                colorScheme="teal"
                size="sm"
                margin={3}
                onClick={() => onHandleMoveUpQuestion(row.id)}
              />
              <IconButton
                isDisabled={row.order === totalData}
                icon={<ChevronDownIcon />}
                colorScheme="teal"
                size="sm"
                margin={3}
                onClick={() => onHandleMoveDownQuestion(row.id)}
              />
            </Flex>
          </Box>
        );
      },
    },
    {
      dataField: "question",
      text: "Question",
      style: { textAlign: "left" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "answer",
      text: "Answer",
      style: { textAlign: "justify" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => {
        return <Box dangerouslySetInnerHTML={{ __html: row.answer }}></Box>;
      },
    },
    {
      dataField: "isActive",
      text: "Status",
      style: { textAlign: "justify", width: "2em" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => {
        return <Box>{row.isActive === true ? "Active" : "Not Active"}</Box>;
      },
    },
    {
      dataField: "id",
      style: { textAlign: "end" },
      formatter: (cell, row) => {
        return (
          <Flex justifyContent={"center"} width="100%">
            <Text cursor={"pointer"} onClick={() => onHandleEditClick(row)}>
              Edit
            </Text>
            <Center height="20px" width="29px">
              <Divider
                orientation="vertical"
                color={"#C4C4C4"}
                opacity={"1"}
                borderLeftWidth={"1.5px"}
              />
            </Center>
            <Text
              cursor={"pointer"}
              onClick={() => onHandleToggleQuestion(row.id, row.isActive)}
            >
              {row.isActive ? "Deactivate" : "Activate"}
            </Text>
            <Center height="20px" width="29px">
              <Divider
                orientation="vertical"
                color={"#C4C4C4"}
                opacity={"1"}
                borderLeftWidth={"1.5px"}
              />
            </Center>
            <Text
              cursor={"pointer"}
              onClick={() => onHandleDeleteQuestion(row.id, row.isActive)}
            >
              Delete
            </Text>
          </Flex>
        );
      },
    },
  ];

  const onHandleToggleQuestion = async (faqId, isActive) => {
    toast({
      title: "Sucess",
      description: `Berhasil ${
        isActive ? "Menonaktifkan" : "Mengaktifkan"
      } FAQ`,
      status: "success",
      position: "top",
      duration: 1000,
      isClosable: true,
    });
    await toggleQuestion(faqId);
    await getFaqsAction(dispatch, `page=${page + 1}`);
  };

  const onHandleDeleteQuestion = async (faqId, isActive) => {
    if (isActive) {
      toast({
        title: "Failed",
        description: "Tidak dapat menghapus FAQ yang masih Aktif",
        status: "error",
        position: "top",
        duration: 2000,
        isClosable: false,
      });
    } else {
      toast({
        title: "Sucess",
        description: "Berhasil menghapus FAQ",
        status: "success",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
      await deleteQuestion(faqId);
      await getFaqsAction(dispatch, `page=${page + 1}`);
    }
  };

  const onHandleMoveUpQuestion = async (faqId) => {
    toast({
      title: "Sucess",
      description: "Berhasil mengubah urutan FAQ",
      status: "success",
      position: "top",
      duration: 1000,
      isClosable: true,
    });
    await moveQuestionUp(faqId);
    await getFaqsAction(dispatch, `page=${page + 1}`);
  };

  const onHandleMoveDownQuestion = async (faqId) => {
    toast({
      title: "Sucess",
      description: "Berhasil mengubah urutan FAQ",
      status: "success",
      position: "top",
      duration: 1000,
      isClosable: true,
    });
    await moveQuestionDown(faqId);
    await getFaqsAction(dispatch, `page=${page + 1}`);
  };

  const onHandleEditClick = (row) => {
    setEditRowData(row);
    onOpenEdit();
  };

  const changePageHandler = (value) => {
    setPage(value.selected);
  };

  useEffect(() => {
    getFaqsAction(dispatch, `page=${page + 1}`); // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    setDataTable(articles);
  }, [articles]);

  return (
    <>
      <AddNewFaqs isOpen={isOpenAdd} onClose={onCloseAdd} page={page} />
      <EditFaqs
        isOpen={isOpenEdit}
        onClose={onCloseEdit}
        rowData={editRowData}
        page={page}
      />
      <Box className="help-center__feedback-container">
        <Text className="help-center__feedback-title">Help center</Text>
        <Box className="help-center__feedback-content-container">
          <Flex justifyContent="space-between">
            <Text className="help-center__feedback-content-title">FAQ</Text>
            <Button onClick={onOpenAdd} colorScheme="teal">
              Add new FAQ
            </Button>
          </Flex>

          {dataTable && (
            <ToolkitProvider data={dataTable} keyField="id" columns={columns}>
              {(props) => (
                <div className="py-4 table-responsive list-order__table-container">
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                    trStyle={{ backgroundColor: "white" }}
                  />
                </div>
              )}
            </ToolkitProvider>
          )}
          {totalPage && (
            <Pagination
              page={page}
              changePageHandler={changePageHandler}
              totalPage={totalPage}
            />
          )}
        </Box>
      </Box>
    </>
  );
}

export default navguard(GeneralFAQ);
