import { Box, Flex, Input, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { CardBody, Container, Card, Col, Row } from "reactstrap";
import navguard from "../auth/navguard";
import Breadcrumb from "../common/breadcrumb";
import styles_wysiwyg from "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Axios from "../../services/axios";
import { useParams } from "react-router-dom";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import stylesEditor from "../products/styles/AddNewProduct.css";
import "./style/ArticleFeedback.css";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import dummyData from "./components/const/articleDummy";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../order/components/pagination";

function ArticleFeedback(props) {
  const [articleData, setArticleData] = useState({
    title: "",
    body: "",
  });
  const [editorStateArticle, setEditorStateArticle] = useState(() =>
    EditorState.createEmpty()
  );
  const { id } = useParams();
  const [page, setPage] = useState(0);
  // const [totalPage, setTotalPage] = useState()

  const handlePage = (value) => {
    setPage(value.selected);
  };

  const convertHtmlToDraft = (articleHTML) => {
    let contentBlock = htmlToDraft(articleHTML);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const article = EditorState.createWithContent(contentState);
      return article;
    }
  };

  const config = {
    image: {
      previewImage: true,
      alt: { present: true, mandatory: false },
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    },
    list: {
      inDropdown: true,
      unordered: { className: stylesEditor["unordered-list"] },
      ordered: { className: stylesEditor["ordered-list"] },
    },
    textAlign: { inDropdown: true },
    blockType: {
      className: "demo-option-custom-wide",
      dropdownClassName: "demo-dropdown-custom",
    },
  };

  const fetchData = async () => {
    try {
      const { data } = await Axios({
        url: `/admin/article/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      let tempArticle = { ...articleData };
      Object.keys(data.data.article).forEach((key) => {
        if (key === "title" || key === "body") {
          tempArticle[key] = data.data.article[key];
        }
        if (key === "body") {
          setEditorStateArticle(convertHtmlToDraft(data.data.article[key]));
        }
      });

      setArticleData(tempArticle);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [id]);

  const columns = [
    {
      dataField: "email",
      text: "Email",
      style: { textAlign: "center", width: "250px" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "topic",
      text: "Feedback Topic",
      style: { textAlign: "center" },
      sort: true,
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "feedback",
      text: "Feedback Description",
      style: { textAlign: "center", width: "700px" },
      headerStyle: { textAlign: "center" },
    },
  ];
  return (
    <>
      <Breadcrumb title={"Feedback Article"} parent="Article" />
      <Container fluid={true}>
        <Card>
          <CardBody>
            <Row className="feedback-article__wrapper">
              <Col md="2">
                <Text className="feedback-article__title">Aritcle Title: </Text>
              </Col>
              <Col>
                <Input
                  placeholder="title"
                  value={articleData.title}
                  readOnly={true}
                />
              </Col>
            </Row>

            <Row className="feedback-article__wrapper">
              <Col md="2">
                <Text className="feedback-article__title">Article Body: </Text>
              </Col>
              <Col>
                <Editor
                  readOnly={true}
                  editorStyle={{ padding: "5px" }}
                  toolbarClassName={styles_wysiwyg["toolbarClassName"]}
                  wrapperClassName={styles_wysiwyg["wrapperClassName"]}
                  editorClassName={styles_wysiwyg["editorClassName"]}
                  toolbar={config}
                  editorState={editorStateArticle}
                />
              </Col>
            </Row>

            <Row className="feedback-article__wrapper">
              <Col md="2">
                <Text className="feedback-article__title">
                  List of Feedback
                </Text>
              </Col>
              <Col md="8" />
              <Col md="2">
                <Flex justifyContent={"space-evenly"}>
                  <Flex alignItems={"center"}>
                    <FaRegThumbsUp size={30} color={"#015352"} />
                    <Text fontSize={"20px"} color={"black"}>
                      20
                    </Text>
                  </Flex>
                  <Flex alignItems={"center"}>
                    <FaRegThumbsDown size={30} color={"#ff8084"} />
                    <Text fontSize={"20px"} color={"black"}>
                      0
                    </Text>
                  </Flex>
                </Flex>
              </Col>
            </Row>

            <ToolkitProvider
              data={dummyData}
              columns={columns}
              search
              keyField="partner_name"
            >
              {(props) => (
                <div className="py-4 table-responsive feedback__article-table-container">
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                    trStyle={{ backgroundColor: "white" }}
                  />
                </div>
              )}
            </ToolkitProvider>

            <Box mt="1em">
              <Pagination
                page={page}
                totalPage={5}
                changePageHandler={handlePage}
              />
            </Box>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default navguard(ArticleFeedback);
