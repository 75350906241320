import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";

const ProductTable = ({ myData, myClass, pagination, deleteProduct }) => {
  const [data, setData] = useState(myData);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  useEffect(() => {
    setData(myData);
  }, [myData]);

  const handleDelete = (index) => {
    setIsDeleteConfirmationOpen(true);
    setSelectedProduct(myData[index]);
  };

  const onCloseDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
    setSelectedProduct({});
  };

  const confirmDelete = () => {
    onCloseDeleteConfirmation();
    deleteProduct(selectedProduct);
  };

  const columns = [
    {
      name: <b>Name</b>,
      header: <b>Name</b>,
      selector: "name",
      cell: (row, index) => (
        <div>
          <Link to={"/products/edit-product/" + row.id}>
            <span>{row.name}</span>
          </Link>
        </div>
      ),
      style: {
        textAlign: "left",
      },
      sortable: true,
    },
    {
      name: <b>Brand</b>,
      header: <b>Brand</b>,
      selector: "productCategory",
      cell: (data) => (
        <div>
          <span>{data?.brand?.name}</span>
        </div>
      ),
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
    {
      name: <b>Brand Series</b>,
      header: <b>Brand Series</b>,
      selector: "productCategory",
      cell: (data) => (
        <div>
          <span>{data?.series?.name}</span>
        </div>
      ),
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
    {
      name: <b>Category Group</b>,
      header: <b>Category Group</b>,
      selector: "productCategory",
      cell: (data) => (
        <div>
          <span>{data?.categoryGroup?.name}</span>
        </div>
      ),
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
    {
      name: <b>Category</b>,
      header: <b>Category</b>,
      selector: "productCategory",
      cell: null,
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
    {
      name: <b>Sub Category</b>,
      header: <b>Sub Category</b>,
      selector: "productSubCategory",
      cell: null,
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
    {
      name: <b>Product Type</b>,
      header: <b>Product Type</b>,
      selector: "productType",
      cell: null,
      style: {
        textAlign: "center",
      },
      sortable: true,
    },
    {
      name: <b>Tags</b>,
      header: <b>Tags</b>,
      selector: "tags",
      style: {
        textAlign: "center",
      },
      sortable: true,
      cell: (row, index) => (
        <Flex key={row}>
          {row.productTag.map((item) => {
            return (
              <Text
                color="#4c4c4c"
                letterSpacing={0}
                border="1px solid grey"
                borderRadius="8px"
                padding="4px 12px"
                margin="4px !important"
              >
                {item}
              </Text>
            );
          })}
        </Flex>
      ),
    },
    {
      name: <b>Action</b>,
      id: "delete",
      accessor: (str) => "delete",
      cell: (row, index) => {
        return (
          <div>
            <>
              <Link to={`/products/reviews-detail/${row.slug}`}>
                <Button
                  colorScheme={"gray"}
                  variant="solid"
                  mb={1}
                  style={{ position: "relative", width: "100px" }}
                >
                  {row.newReviewCount > 0 && (
                    <Box
                      boxSize={6}
                      style={{
                        backgroundColor: "#FFBB00",
                        position: "absolute",
                        top: "-10px",
                        left: "-10px",
                        borderRadius: "50%",
                      }}
                    >
                      <Text color="black">{row.newReviewCount}</Text>
                    </Box>
                  )}

                  <Text
                    style={{
                      position: "absolute",
                      margin: "auto",
                    }}
                  >
                    See Review
                  </Text>
                </Button>
              </Link>

              <span onClick={() => handleDelete(index)}>
                <i
                  className="fa fa-trash"
                  style={{
                    width: 35,
                    fontSize: 20,
                    padding: 11,
                    color: "#e4566e",
                    cursor: "pointer",
                  }}
                ></i>
              </span>
            </>
          </div>
        );
      },
      style: {
        textAlign: "center",
      },
      sortable: false,
    },
  ];
  return (
    <div>
      <Fragment>
        <Modal
          isOpen={isDeleteConfirmationOpen}
          onClose={onCloseDeleteConfirmation}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader style={{ textAlign: "center" }}>
              Apakah Anda yakin ingin menghapus produk ini?{" "}
            </ModalHeader>
            <p style={{ textAlign: "center" }}>
              {selectedProduct.name} - {selectedProduct.productCategory}
            </p>
            <ModalFooter borderRadius="16px">
              <Button
                variant="ghost"
                mr={3}
                onClick={onCloseDeleteConfirmation}
              >
                Close
              </Button>

              <Button
                variant="ghost"
                justifyContent="flex-start"
                backgroundColor={"#FF8084"}
                color={"white"}
                onClick={() => confirmDelete()}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <DataTable
          data={data}
          columns={columns}
          className={myClass}
          pagination={pagination}
          striped={true}
          center={true}
        />
      </Fragment>
    </div>
  );
};

export default ProductTable;
