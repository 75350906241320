import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import Loader from "../loader/Loader";
import "./styles/Categories.scss";

import { Col, Container, Row } from "reactstrap";
import { useToast } from "@chakra-ui/react";
import AddBrand from "./component/brands/AddBrand";
import iconDefault from "../../assets/images/icon_default.png";
import imageDefault from "../../assets/images/image_default.png";
import { isEmpty } from "lodash";
import Axios from "../../services/axios";
import navguard from "../auth/navguard";

const EditBrand = () => {
  const imageMaxSize = 1500000;
  const { brandId: brandParameter } = useParams();
  const { seriesId: seriesParameter } = useParams();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [brandSeriesObj, setBrandSeriesObj] = useState();

  const [listBrand, setListBrand] = useState([]);
  const [listSeries, setListSeries] = useState([]);

  const reloadListSeries = async () => {
    try {
      let result = await Axios({
        method: "GET",
        url: `/series`,
      });
      setListSeries(result.data.data.seriesList);
    } catch (e) {
      setListSeries([]);
    }
  };

  const reloadListBrand = async () => {
    try {
      let result = await Axios({
        method: "GET",
        url: "/brand",
      });
      setListBrand(result.data.data.brands);
    } catch (e) {
      setListBrand([]);
    }
  };

  useEffect(() => {
    reloadListBrand();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await reloadListSeries();
    };

    fetchData();
  }, [listBrand]);

  useEffect(() => {
    if (brandParameter) {
      if (listBrand.length) {
        let selectedBrand = listBrand.find(
          (item) => item.id === brandParameter
        );

        setTitle("Edit Brand");
        setBrandSeriesObj({
          type: "Brand",
          brandName: selectedBrand?.name,
          prevBrandName: selectedBrand?.name,
          brandId: selectedBrand?.id,
          image: selectedBrand?.iconPath,
          iconActive: selectedBrand?.iconActivePath,
          banner: selectedBrand?.bannerPath,
          brandDescription: selectedBrand?.description,
        });
      }
    } else {
      setTitle("Edit Series");
      if (listSeries.length) {
        let selectedSeries = listSeries.find(
          (item) => item.id === seriesParameter
        );
        let parentBrand = selectedSeries?.brandId;
        setBrandSeriesObj({
          parentBrand,
          type: "Series",
          seriesId: selectedSeries?.id,
          seriesName: selectedSeries?.name,
          prevSeriesName: selectedSeries?.name,
          image: selectedSeries?.iconPath,
          iconActive: selectedSeries?.iconActivePath,
          banner: selectedSeries?.bannerPath,
          description: selectedSeries?.description,
          prevDescription: selectedSeries?.description,
        });
      }
    }
  }, [brandParameter, seriesParameter, listSeries, listBrand]);

  const changeHandler = (e) => {
    let temp = { ...brandSeriesObj };
    if (e.target.name === "type") {
      delete temp["brandName"];
      delete temp["seriesName"];
    }
    temp[e.target.name] = e.target.value;

    setBrandSeriesObj(temp);
  };

  const submitValidation = () => {
    let statusValidation = {
      status: "success",
      message: `${brandSeriesObj.type} berhasil ditambahkan`,
    };

    if (brandSeriesObj.type === "Brand") {
      if (!brandSeriesObj.brandName) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon isi nama brand";
      } else if (!brandSeriesObj.brandDescription) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon isi deskripsi brand";
      }
    } else if (brandSeriesObj.type === "Series") {
      if (!brandSeriesObj.seriesName) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon isi nama series";
      } else if (!brandSeriesObj.description) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon isi deskripsi series";
      } else if (!brandSeriesObj.parentBrand) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon pilih parent brand";
      }
    }

    if (
      (!brandSeriesObj.savedImage && brandSeriesObj.image === iconDefault) ||
      brandSeriesObj.image === iconDefault
    ) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon upload icon";
    } else if (
      (!brandSeriesObj.savedIconActive && brandSeriesObj.iconActive === iconDefault) ||
      brandSeriesObj.iconActive === iconDefault
    ) {
      statusValidation.status = "error";
      statusValidation.message = "Mohon upload active icon";
    } else if (
      !brandSeriesObj.savedBanner &&
      brandSeriesObj.banner === imageDefault
    ) {
      statusValidation.status = "error";
      statusValidation.message = "Mohon upload banner";
    }

    return statusValidation;
  };

  const updateBrand = async () => {
    if (brandSeriesObj.savedImage) {
      try {
        let data = new FormData();

        data.append("image", brandSeriesObj.savedImage);

        let config = {
          method: "patch",
          url: `/brand/${brandSeriesObj.brandId}/icon`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);
      } catch (error) {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    if (brandSeriesObj.savedIconActive) {
      try {
        let data = new FormData();

        data.append("image", brandSeriesObj.savedIconActive);

        let config = {
          method: "patch",
          url: `/brand/${brandSeriesObj.brandId}/icon-active`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);
      } catch (error) {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    if (brandSeriesObj.savedBanner) {
      try {
        let data = new FormData();

        data.append("image", brandSeriesObj.savedBanner);

        let config = {
          method: "patch",
          url: `/brand/${brandSeriesObj.brandId}/banner`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);
      } catch (error) {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    Axios({
      method: "PATCH",
      url: `/brand/${brandSeriesObj.brandId}`,
      data: {
        description: brandSeriesObj.brandDescription,
        name: brandSeriesObj.brandName,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    })
      .then((result) => {
        if (!result.data.errorStatus) {
          toast({
            title: "Sukses.",
            description: `"${brandSeriesObj.prevBrandName}" berhasil diupdate"`,
            status: "success",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          setBrandSeriesObj({
            type: "Brand",
          });
          reloadListBrand();
          setIsLoading(false);

          setTimeout(() => {
            window.location.href = "/products/brands";
          }, 500);
        } else {
          toast({
            title: "Error.",
            description: result.data.errors[0].message,
            status: "error",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  const updateSeries = async () => {
    if (brandSeriesObj.savedImage) {
      try {
        let data = new FormData();
        data.append("image", brandSeriesObj.savedImage);

        let config = {
          method: "patch",
          url: `/series/${brandSeriesObj.seriesId}/icon`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);
      } catch (error) {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    if (brandSeriesObj.savedIconActive) {
      try {
        let data = new FormData();

        data.append("image", brandSeriesObj.savedIconActive);

        let config = {
          method: "patch",
          url: `/series/${brandSeriesObj.seriesId}/icon-active`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);
      } catch (error) {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    if (brandSeriesObj.savedBanner) {
      try {
        let banner = new FormData();
        banner.append("image", brandSeriesObj.savedBanner);

        let configBanner = {
          method: "patch",
          url: `/series/${brandSeriesObj.seriesId}/banner`,
          data: banner,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(configBanner);
      } catch (error) {
        console.error(error);
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }

    Axios({
      method: "PATCH",
      url: `/series/${brandSeriesObj.seriesId}`,
      data: {
        description: brandSeriesObj.description,
        name: brandSeriesObj.seriesName,
        brandId: brandSeriesObj.parentBrand,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    })
      .then((result) => {
        if (!result.data.errorStatus) {
          toast({
            title: "Sukses.",
            description: `"${brandSeriesObj.prevSeriesName}" berhasil diupdate"`,
            status: "success",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          setBrandSeriesObj({
            type: "Brand",
          });
          reloadListBrand();
          setIsLoading(false);

          setTimeout(() => {
            window.location.href = "/products/brands";
          }, 500);
        } else {
          toast({
            title: "Error.",
            description: result.data.errors[0].message,
            status: "error",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  const submitBrand = () => {
    setIsLoading(true);
    let statusValidation = submitValidation();

    if (statusValidation.status === "success") {
      if (brandSeriesObj.type === "Brand") {
        updateBrand();
      } else {
        updateSeries();
      }
    } else {
      toast({
        title: "Error.",
        description: statusValidation.message,
        status: statusValidation.status,
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const deleteImage = (fileAttr, srcAttr) => {
    let inputImageElmnt = document.getElementById("icon-img");
    let inputImageElmnt2 = document.getElementById("banner-img");
    const tempBrandSeriesObj = { ...brandSeriesObj };
    let defaultValue = iconDefault;
    if (srcAttr === "banner") {
      defaultValue = imageDefault;
      inputImageElmnt2.value = null;
    }
    tempBrandSeriesObj[srcAttr] = defaultValue;
    tempBrandSeriesObj[fileAttr] = undefined;
    inputImageElmnt.value = null;
    setBrandSeriesObj(tempBrandSeriesObj);
  };

  const _handleImgChange = (value, fileAttr, srcAttr) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (value.currentTarget.files.item(0).size > imageMaxSize) {
        toast({
          title: "Error.",
          description: "Mohon upload gambar berukuran kurang dari 2MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        const tempBrandSeriesObj = { ...brandSeriesObj };
        let reader = new FileReader();
        const image = value.target.files[0];
        tempBrandSeriesObj[fileAttr] = image;
        reader.onload = () => {
          tempBrandSeriesObj[srcAttr] = reader.result;
          setBrandSeriesObj(tempBrandSeriesObj);
        };
        reader.readAsDataURL(image);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Breadcrumb title={title} parent="Products" />
      <Container fluid={true}>
        <Row>
          <Col sm="5">
            {brandSeriesObj && (
              <AddBrand
                title={
                  brandSeriesObj.prevBrandName
                    ? `Edit ${brandSeriesObj.prevBrandName}`
                    : `Edit ${brandSeriesObj.prevSeriesName}`
                }
                isEdit={true}
                listBrand={listBrand}
                brandObj={brandSeriesObj}
                changeHandler={changeHandler}
                submitBrand={submitBrand}
                _handleImgChange={_handleImgChange}
                deleteImage={deleteImage}
                setBrandSeriesObj={setBrandSeriesObj}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default navguard(EditBrand);
