import {
  Box,
  Button,
  Flex,
  Input,
  Spacer,
  Text,
  Divider,
  Center,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import navguard from "../auth/navguard";
import "./styles/list-community.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Pagination from "../order/components/pagination";
import AddModal from "./components/addModal";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import Axios from "../../services/axios";
import { isEmpty } from "lodash";
import dateFormat from "dateformat";
import DateFilter from "./components/dateFilter";

function ListCommunity(props) {
  const [page, setPage] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clickType, setClickType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();
  const { search } = useLocation();
  const [dataTable, setDataTable] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [input, setInput] = useState({
    name: "",
    code: "",
  });

  const changePageHandler = (value) => {
    setPage(value.selected);
  };

  const toast = useToast();
  const columns = [
    {
      dataField: "code",
      text: "Community Code",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "name",
      text: "Community Name",
      style: { textAlign: "center" },
      sort: true,
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "createdAt",
      text: "Registered Date",
      style: { textAlign: "center" },
      sort: true,
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{dateFormat(cell, "dd/mm/yyyy")}</p>
      ),
    },
    {
      dataField: "isActive",
      text: "Status",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{cell ? "Active" : "Not Active"}</p>
      ),
    },
    {
      dataField: "totalMember",
      text: "Number of Member",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "id",
      style: { textAlign: "end" },
      formatter: (cell, row) => {
        return (
          <Flex justifyContent={"center"} width="100%">
            <Text
              cursor={"pointer"}
              onClick={() => handleAddCommunity({ type: "edit", data: row })}
            >
              Edit
            </Text>
            <Center height="20px" width="29px">
              <Divider
                orientation="vertical"
                color={"#C4C4C4"}
                opacity={"1"}
                borderLeftWidth={"1.5px"}
              />
            </Center>
            <Text
              cursor={"pointer"}
              onClick={() =>
                handleChangeStatus({ id: row.code, toggle: row.isActive })
              }
            >
              {row.isActive ? "Deactivate" : "Activate"}
            </Text>
            <Center height="20px" width="29px">
              <Divider
                orientation="vertical"
                color={"#C4C4C4"}
                opacity={"1"}
                borderLeftWidth={"1.5px"}
              />
            </Center>
            <Text
              onClick={() =>
                handleDeleteData({ id: row.code, status: row.isActive })
              }
              cursor={"pointer"}
            >
              Delete
            </Text>
          </Flex>
        );
      },
    },
  ];

  const fetchData = async () => {
    try {
      const currentQueries = queryString.parse(search);
      const queries = queryString.stringify({
        ...currentQueries,
        page: page + 1,
        limit: 5,
      });

      const { data } = await Axios({
        url: `/admin/community/list?${queries}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      setTotalPage(data.data.totalPage);
      setDataTable(data.data.communities);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    } else {
      fetchData();
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [page]);

  const handleAddCommunity = ({ type, data }) => {
    setClickType(type);
    onOpen();
    let tempObj = { ...input };
    if (type === "edit") {
      Object.keys(tempObj).forEach((key) => {
        tempObj[key] = data[key];
      });
    } else {
      Object.keys(tempObj).forEach((key) => {
        tempObj[key] = "";
      });
    }
    setInput(tempObj);
  };

  const searchDataHandler = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const currentQueries = queryString.parse(search);
      const queries = queryString.stringify(
        {
          ...currentQueries,
          q: searchQuery,
        },
        { skipEmptyString: true }
      );
      history.push(`/community/list-community?${queries}`);
    }
  };

  useEffect(() => {
    const currentQueries = queryString.parse(search);
    if (currentQueries.q) {
      setSearchQuery(currentQueries.q);
    }
  }, [search]);

  const handleChangeStatus = async ({ id, toggle }) => {
    try {
      const data = {
        isActive: !toggle,
      };
      await Axios({
        url: `/admin/community/${id}/toggle-active`,
        method: "PATCH",
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      toast({
        title: "Success",
        description: "Berhasil mengubah status data komunitas",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
      fetchData();
    } catch (err) {
      console.error(err);
      toast({
        title: "Warning",
        description: "Tidak berhasil mengubah status data komunitas",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  const handleDeleteData = async ({ id, status }) => {
    if (!status) {
      try {
        await Axios({
          url: `/admin/community/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });
        toast({
          title: "Success",
          description: "Berhasil menghapus data komunitas",
          status: "success",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
        fetchData();
      } catch (err) {
        console.error(err);
        toast({
          title: "Warning",
          description: "Tidak berhasil menghapus data komunitas",
          status: "warning",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Warning",
        description: "Mohon ubah status data menjadi deactive",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  return (
    <Box className="list-community__container">
      <Flex alignItems="center" mb="2em">
        <Text className="list-community__title">Community Management</Text>
        <Spacer />
        <Button
          className="list-community__button"
          onClick={() => handleAddCommunity({ type: "add" })}
        >
          Add Community
        </Button>
      </Flex>

      <Flex justifyContent="flex-end" alignItems="center" mb={3}>
        <DateFilter />
      </Flex>

      <Input
        placeholder="Search community using community code or name ..."
        mb="0.5em"
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
        onKeyPress={(event) => searchDataHandler(event)}
      />

      {!isEmpty(dataTable) && (
        <ToolkitProvider
          data={dataTable}
          columns={columns}
          search
          keyField="partner_name"
        >
          {(props) => (
            <div className="py-4 table-responsive list-community__table-container">
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                bordered={false}
                trStyle={{ backgroundColor: "white" }}
                noDataIndication={"There is no data entry"}
              />
            </div>
          )}
        </ToolkitProvider>
      )}

      {totalPage ? (
        <Box mt="1em">
          <Pagination
            page={page}
            totalPage={totalPage}
            changePageHandler={changePageHandler}
          />
        </Box>
      ) : (
        <Center>There is no data entry</Center>
      )}

      <AddModal
        isOpen={isOpen}
        onClose={onClose}
        type={clickType}
        input={input}
        setInput={setInput}
        fetchData={fetchData}
      />
    </Box>
  );
}

export default navguard(ListCommunity);
