import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import "./styles/orderInfo.scss";
import {
  Button,
  useToast,
  Text,
  Textarea,
  Flex,
  Box,
  Select,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  paymentProcessAction,
  toShipProcessAction,
  confirmReturnAction,
  approveRejectAction,
  updateNotesAction,
  reRequestPickupAction,
  completeReturnApprove,
} from "../../store/actions/order-list";
import { useHistory } from "react-router-dom";

function OrderAction({ order }) {
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const [successUpdate, setSuccessUpdate] = useState(null);
  const [approveRejectDesc, setApproveRejectDesc] = useState("");
  const [actionTaken, setActionTaken] = useState("");
  const requestDetail = useSelector(({ orders }) => orders.requestDetail);

  useEffect(() => {
    if (successUpdate) {
      toast({
        title: "Success.",
        description: "Order Berhasil Diupdate",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        history.push("/order-list");
      }, 3000);
    } else if (successUpdate === false) {
      toast({
        title: "Failed.",
        description: "Order Tidak Berhasil Diupdate",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [successUpdate, history, toast]);

  useEffect(() => {
    if (
      order.orderStatus === "return confirmed" ||
      order.orderStatus === "completed return reject" ||
      order.orderStatus === "completed return approve"
    ) {
      setActionTaken(requestDetail.actionDetail);
      setApproveRejectDesc(requestDetail.description);
    }
  }, [order, requestDetail]);

  const processHandler = () => {
    if (order.orderStatus === "paid") {
      dispatch(
        paymentProcessAction({
          invoiceNumber: order.invoiceNumber,
          setSuccessUpdate,
        })
      );
    } else if (order.orderStatus === "to process") {
      dispatch(
        toShipProcessAction({
          invoiceNumber: order.invoiceNumber,
          setSuccessUpdate,
        })
      );
    } else if (order.orderStatus === "return requested") {
      dispatch(
        confirmReturnAction({
          invoiceNumber: order.invoiceNumber,
          setSuccessUpdate,
        })
      );
    } else if (order.orderStatus === "return confirmed") {
      dispatch(
        completeReturnApprove({
          invoiceNumber: order.invoiceNumber,
          data: {
            approve: true,
          },
          setSuccessUpdate,
        })
      );
    } else if (order.orderStatus === "cancelled by courier") {
      dispatch(
        reRequestPickupAction({
          invoiceNumber: order.invoiceNumber,
          setSuccessUpdate,
        })
      );
    }
  };

  const approveRejectHandler = (value) => {
    if (!actionTaken) {
      toast({
        title: "Error.",
        description: "Mohon isi field action taken",
        status: "warning",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    } else {
      const tempApproveReject = {
        action: value,
        actionDetail: actionTaken,
        description: approveRejectDesc,
      };
      dispatch(
        approveRejectAction({
          invoiceNumber: order.invoiceNumber,
          data: tempApproveReject,
          setSuccessUpdate,
        })
      );
    }
  };

  const updateNotesHandler = () => {
    const data = {
      description: approveRejectDesc,
    };
    dispatch(
      updateNotesAction({
        invoiceNumber: order.invoiceNumber,
        description: data,
        setSuccessUpdate,
      })
    );
  };

  return (
    <>
      {(order.orderStatus === "paid" ||
        order.orderStatus === "to process" ||
        order.orderStatus === "return requested" ||
        order.orderStatus === "in returning" ||
        order.orderStatus === "return confirmed" ||
        order.orderStatus === "completed return approve" ||
        order.orderStatus === "completed return reject" ||
        order.orderStatus === "cancelled by courier") && (
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader style={{ backgroundColor: "#F0F0F0" }}>
                  <h5>Order Action</h5>
                </CardHeader>
                <CardBody>
                  {order.orderStatus === "paid" ||
                  order.orderStatus === "to process" ||
                  order.orderStatus === "cancelled by courier" ? (
                    <Row style={{ justifyContent: "center" }}>
                      <Col lg={8}>
                        <Button
                          onClick={processHandler}
                          colorScheme="teal"
                          size="md"
                          style={{ width: "100%" }}
                        >
                          {order.orderStatus === "paid"
                            ? "Process Order"
                            : order.orderStatus === "cancelled by courier"
                            ? "Re-request Pickup"
                            : "Request Pickup"}
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <Row style={{ justifyContent: "center" }}>
                      <Col lg={11} style={{ marginBottom: "2rem" }}>
                        <Text className="action-header">Return Request</Text>
                        <Text className="action-content">
                          <span style={{ fontWeight: "700" }}>
                            Unboxing Video
                          </span>{" "}
                          :{" "}
                          <span
                            onClick={() =>
                              window.open(requestDetail?.videoLink)
                            }
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {requestDetail?.videoLink}
                          </span>
                        </Text>
                        <Text className="action-content">
                          <span style={{ fontWeight: "700" }}>Topic</span> :{" "}
                          {requestDetail?.reason?.reasonType?.join(", ")}
                        </Text>
                        <Text className="action-content">
                          <span style={{ fontWeight: "700" }}>Product</span> :
                        </Text>
                        {requestDetail?.products?.map((each, index) => (
                          <Text className="action-content">
                            {index + 1}. {each.name} ({each.qty} pcs)
                          </Text>
                        ))}
                        <Text className="action-content">
                          <span style={{ fontWeight: "700" }}>Description</span>{" "}
                          : {requestDetail?.reason?.detail}
                        </Text>
                      </Col>
                      {order.orderStatus === "return requested" ? (
                        <Col lg={8}>
                          <Button
                            onClick={processHandler}
                            colorScheme="teal"
                            size="md"
                            style={{ width: "100%" }}
                          >
                            Process Return
                          </Button>
                        </Col>
                      ) : (
                        <Col lg={11}>
                          <Text fontWeight={"700"} className="action-content">
                            Action Taken (Mandatory):
                          </Text>
                          <Select
                            disabled={
                              order.orderStatus === "return confirmed" ||
                              order.orderStatus === "completed return reject" ||
                              order.orderStatus === "completed return approve"
                            }
                            onChange={(e) => setActionTaken(e.target.value)}
                          >
                            {(order.orderStatus === "return confirmed" ||
                              order.orderStatus === "completed return reject" ||
                              order.orderStatus ===
                                "completed return approve") && (
                              <option selected>{actionTaken}</option>
                            )}
                            <option
                              selected={
                                order.orderStatus !== "return confirmed" &&
                                order.orderStatus !==
                                  "completed return reject" &&
                                order.orderStatus !== "completed return approve"
                              }
                              disabled
                              hidden
                            >
                              Select action
                            </option>
                            <option
                              value={
                                "Approved : Return the product, create voucher"
                              }
                            >
                              Approved : Return the product, create voucher
                            </option>
                            <option
                              value={
                                "Approved : Return the product, send product"
                              }
                            >
                              Approved : Return the product, send product
                            </option>
                            <option
                              value={
                                "Approved : No need to return, create voucher"
                              }
                            >
                              Approved : No need to return, create voucher
                            </option>
                            <option
                              value={
                                "Approved : No need to return, send product"
                              }
                            >
                              Approved : No need to return, send product
                            </option>
                            <option value={"Reject Return"}>
                              Reject Return
                            </option>
                          </Select>
                          <Text fontWeight={"700"} className="action-content">
                            Note (Optional):
                          </Text>
                          <Textarea
                            name="description"
                            onChange={(e) =>
                              setApproveRejectDesc(e.target.value)
                            }
                            value={approveRejectDesc}
                            placeholder="Write a note here ..."
                            maxLength="255"
                            disabled={
                              order.orderStatus === "completed return reject" ||
                              order.orderStatus === "completed return approve"
                                ? true
                                : false
                            }
                          />
                          <Text
                            style={{
                              fontSize: "12px",
                              fontWeight: "normal",
                              color:
                                approveRejectDesc?.length >= 255
                                  ? "#990013"
                                  : approveRejectDesc?.length >= 230
                                  ? "#997000"
                                  : "black",
                              alignSelf: "flex-end",
                            }}
                          >
                            {approveRejectDesc?.length}/255
                          </Text>
                          <Flex
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            marginTop={"1rem"}
                          >
                            {order.orderStatus === "return confirmed" ||
                            order.orderStatus === "completed return reject" ||
                            order.orderStatus === "completed return approve" ? (
                              <>
                                <Text className="action-status">
                                  {order.orderStatus ===
                                  "completed return reject"
                                    ? "Rejected"
                                    : "Accepted"}
                                </Text>
                                {order.orderStatus === "return confirmed" && (
                                  <Button
                                    colorScheme="teal"
                                    size="md"
                                    style={{ width: "45%" }}
                                    onClick={() => updateNotesHandler()}
                                  >
                                    Submit Note
                                  </Button>
                                )}
                              </>
                            ) : (
                              <>
                                <Button
                                  colorScheme="red"
                                  size="md"
                                  style={{ width: "45%" }}
                                  onClick={() =>
                                    approveRejectHandler("rejected")
                                  }
                                >
                                  Reject
                                </Button>
                                <Button
                                  colorScheme="teal"
                                  size="md"
                                  style={{ width: "45%" }}
                                  onClick={() =>
                                    approveRejectHandler("approved")
                                  }
                                >
                                  Approve
                                </Button>
                              </>
                            )}
                          </Flex>
                          {order.orderStatus === "return confirmed" && (
                            <Box
                              marginTop={"2rem"}
                              display={"flex"}
                              justifyContent={"center"}
                            >
                              <Button
                                onClick={processHandler}
                                colorScheme="teal"
                                size="md"
                                style={{ width: "45%", alignSelf: "center" }}
                              >
                                Complete Return
                              </Button>
                            </Box>
                          )}
                        </Col>
                      )}
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default OrderAction;
