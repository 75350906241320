import { CloseIcon } from "@chakra-ui/icons";
import "../styles/product-list__modal.scss";

const ProductListModal = ({ setIsPopUp, products }) => {
  return (
    <div className="product-list__container">
      <div className="overlay" onClick={() => setIsPopUp(false)} />
      <div className="main">
        <div className="header">
          <p className="header-text">List Product Selected</p>
          <CloseIcon
            fontSize="10px"
            cursor="pointer"
            onClick={() => setIsPopUp(false)}
          />
        </div>
        {products.length > 0 &&
          products.map((item, idx) => (
            <div className="product" key={item.id}>
              <p className="number">{idx + 1}.</p>
              <p className="text">{item.name}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProductListModal;
