import { Box, Flex, Skeleton } from "@chakra-ui/react";

const SkeletonProductTable = () => {
  return (
    <Box mb="16px">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, idx) => (
        <Flex mb="16px" justify="space-between" key={idx}>
          <Skeleton height="32px" width="calc(5% - 20px)" />
          <Skeleton height="32px" width="calc(15% - 20px)" />
          <Skeleton height="32px" width="calc(10% - 20px)" />
          <Skeleton height="32px" width="calc(20% - 20px)" />
          <Skeleton height="32px" width="calc(30% - 20px)" />
          <Skeleton height="32px" width="calc(20% - 20px)" />
        </Flex>
      ))}
    </Box>
  );
};
export default SkeletonProductTable;
