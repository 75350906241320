import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import "../styles/tableCommissionPayoutDetail.css";
import React from "react";
import dateFormat from "dateformat";

const CommissionPayoutDetailTable = ({ data, dataCommissionPayout }) => {
  const formatter = new Intl.NumberFormat("id-ID");
  
  return (
    <TableContainer mt={"14px"} padding="8px 0">
      <Table variant="simple">
        <Thead>
          <Tr className={"table_container-tr"}>
            <Th className={"detailPayout-th"}>Item</Th>
            <Th className={"detailPayout-th"}>Qty</Th>
            <Th className={"detailPayout-th"}>Currency</Th>
            <Th className={"detailPayout-th"} isNumeric>
              Commission
            </Th>
            <Th className={"detailPayout-th"} isNumeric>
              Tax Percentage
            </Th>
            <Th className={"detailPayout-th"} isNumeric>
              Tax Amount
            </Th>
            <Th className={"detailPayout-th"} isNumeric>
              Total
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.length > 0 &&
            data.map((each) => (
              <Tr>
                <Td className={"detailPayout-td"}>
                  Komisi reseller {dateFormat(each.date, "mmmm yyyy")}
                </Td>
                <Td className={"detailPayout-td"}>1</Td>
                <Td className={"detailPayout-td"}>IDR</Td>{" "}
                <Td className={"detailPayout-td"} isNumeric>
                  {formatter.format(each.totalCommission)}
                </Td>{" "}
                <Td className={"detailPayout-td"} isNumeric>
                  {each.taxPercentage}%
                </Td>
                <Td className={"detailPayout-td"} isNumeric>
                  {formatter.format(
                    Math.floor(
                      each.totalCommission - each.totalCommissionAfterTax
                    )
                  )}
                </Td>
                <Td className={"detailPayout-td"} isNumeric>
                  {formatter.format(each.totalCommissionAfterTax.toFixed(1))}
                </Td>
              </Tr>
            ))}
        </Tbody>
        <Tbody>
          <Tr>
            <Td padding="6px" />
            <Td padding="6px" />
            <Td padding="6px" />
            <Td padding="6px" />
            <Td padding="6px" />
            <Td className={"detailPayoutTotal-th"} isNumeric>
              Total Payment
            </Td>
            <Td className={"detailPayout-td"} isNumeric>
              {formatter.format(dataCommissionPayout?.totalTransfer)}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default CommissionPayoutDetailTable;
