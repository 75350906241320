import React, { Fragment, useState, useEffect } from "react";

import "./styles/_homeBanner.scss";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  Flex,
  Spacer,
  IconButton,
  useToast,
  Input,
  Text,
  Switch,
  Button,
} from "@chakra-ui/react";
import { MdDelete, MdAdd } from "react-icons/md";
import {
  FaMobileAlt,
  FaDesktop,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa";

import one from "../../assets/images/pro3/1.jpg";
import Breadcrumb from "../common/breadcrumb";
import Loader from "../loader/Loader";
import FullPreviewModal from "./component/full-preview";
import AddNewBanner from "./component/add-new-banner";
import DeleteBanner from "./component/delete-banner";
import DeleteImage from "./component/warning-delete";
import Axios from "../../services/axios";
import navguard from "../auth/navguard";

const HomeBanner = () => {
  const toast = useToast();
  const imageMaxSize = 2000000;
  const [isLoading, setIsLoading] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedBannerIndex, setSelectedBannerIndex] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [isImageDelete, setIsImageDelete] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const [isAddNew, setIsAddNew] = useState(false);

  const getBannerList = async () => {
    try {
      let bannerList = await Axios({
        method: "GET",
        url: "/banner/list",
      });
      let tempBannerList = [];
      if (bannerList.data) {
        bannerList.data.data.banners.forEach((item) => {
          tempBannerList.push({
            ...item,
            prevImagePath: item.imagePath,
            prevImageMobilePath: item.imageMobilePath,
            prevTitle: item.title,
            prevLink: item.link,
            preview: "Desktop",
            isActive: item.isActive,
            prevIsActive: item.isActive,
            imageMobile: item.imageMobilePath,
          });
        });
      }
      setBannerList(tempBannerList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchBanner = async () => {
      await getBannerList();
      setIsLoading(false);
    };

    setIsLoading(true);
    fetchBanner();
  }, []);

  const handleMainImageChange = (value, index, item) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (value.currentTarget.files.item(0).size > imageMaxSize) {
        toast({
          title: "Error.",
          description: "Mohon upload gambar berukuran kurang dari 2MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        let reader = new FileReader();
        const image = value.target.files[0];
        let tempBannerList = [...bannerList];

        if (item.preview === "Desktop") {
          tempBannerList[index].fileImageDesktop = image;
        } else {
          tempBannerList[index].fileImageMobile = image;
        }

        reader.onload = () => {
          if (item.preview === "Desktop") {
            tempBannerList[index].imagePath = reader.result;
          } else {
            tempBannerList[index].imageMobile = reader.result;
          }
          setBannerList(tempBannerList);
          let componentImageUploader =
            document.getElementById("main-image-input");
          componentImageUploader.value = null;
        };

        reader.readAsDataURL(image);
      }
    }
  };

  const openDeleteImage = (index, banner) => {
    setSelectedBanner(banner);
    setSelectedBannerIndex(index);
    setIsImageDelete(true);
  };

  const closeDeleteImage = () => {
    setIsImageDelete(false);
    setSelectedBanner(null);
    setSelectedBannerIndex(null);
  };

  const deleteMainImage = () => {
    document.getElementById("main-image-input").value = null;
    let tempDataBanner = [...bannerList];

    if (selectedBanner.preview === "Desktop") {
      tempDataBanner[selectedBannerIndex].imagePath = one;
      tempDataBanner[selectedBannerIndex].fileImageDesktop = undefined;
    } else {
      tempDataBanner[selectedBannerIndex].imageMobile = one;
      tempDataBanner[selectedBannerIndex].fileImageMobile = undefined;
    }

    setBannerList(tempDataBanner);
    closeDeleteImage();
  };

  const dataBannerHandler = (e, index) => {
    let tempDataBanner = [...bannerList];
    if (e.target.name === "isActive") {
      tempDataBanner[index].isActive = !tempDataBanner[index].isActive;
    } else {
      tempDataBanner[index][e.target.name] = e.target.value;
    }
    setBannerList(tempDataBanner);
  };

  const changePreview = (index, value) => {
    let tempDataBanner = [...bannerList];
    tempDataBanner[index].preview = value;
    setBannerList(tempDataBanner);
  };

  const deleteBanner = (banner) => {
    setSelectedBanner(banner);
    setIsDelete(true);
  };

  const closeDeleteBanner = () => {
    setIsDelete(false);
    setSelectedBanner(null);
  };

  const moveUp = async (banner) => {
    setIsLoading(true);
    await Axios({
      method: "POST",
      url: `/banner/${banner.id}/moveUp`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    await getBannerList();
    setIsLoading(false);
  };

  const moveDown = async (banner) => {
    setIsLoading(true);
    await Axios({
      method: "POST",
      url: `/banner/${banner.id}/moveDown`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    await getBannerList();
    setIsLoading(false);
  };

  const previewClose = () => {
    setIsPreview(false);
  };

  const previewOpen = () => {
    setIsPreview(true);
  };

  const addNewOpen = () => {
    setIsAddNew(true);
  };

  const getActiveBanner = () => {
    return bannerList.filter((item) => item.isActive);
  };

  const checkIsBannerEdited = (banner) => {
    return (
      banner.prevTitle !== banner.title ||
      banner.prevLink !== banner.link ||
      banner.prevIsActive !== banner.isActive ||
      banner.prevImagePath !== banner.imagePath ||
      banner.prevImageMobilePath !== banner.imageMobile
    );
  };

  const updateValidation = () => {
    let statusValidation = {
      status: "success",
      message: "Banner berhasil disubmit",
    };
    let i = 0;
    while (statusValidation.status === "success" && i < bannerList.length) {
      if (!bannerList[i].title) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon isi nama banner";
      } else if (bannerList[i].imagePath === one) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload gambar banner";
      }

      i += 1;
    }

    return statusValidation;
  };

  const submitChanges = async () => {
    setIsLoading(true);
    let responseValidation = updateValidation();
    if (responseValidation.status === "success") {
      for (let i = 0; i < bannerList.length; i += 1) {
        if (checkIsBannerEdited(bannerList[i])) {
          try {
            let data = new FormData();
            if (bannerList[i].prevImagePath !== bannerList[i].imagePath) {
              data.append("image", bannerList[i].fileImageDesktop);
            }
            if (
              bannerList[i].prevImageMobilePath !==
              bannerList[i].imageMobile
            ) {
              data.append("imageMobile", bannerList[i].fileImageMobile);
            }
            data.append("title", bannerList[i].title);
            data.append("label", bannerList[i].title);
            data.append("link", bannerList[i].link);

            let config = {
              method: "PATCH",
              url: `/banner/${bannerList[i].id}`,
              data: data,
              headers: {
                Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              },
            };

            await Axios(config);

            if (bannerList[i].prevIsActive !== bannerList[i].isActive) {
              await Axios({
                method: "PATCH",
                url: `/banner/${bannerList[i].id}/setActive`,
                data: {
                  isActive: bannerList[i].isActive,
                },
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
              });
            }

            await getBannerList();

            toast({
              title: "Success.",
              description: "Banner berhasil diupdate",
              status: "success",
              position: "top",
              duration: 3000,
              isClosable: true,
            });
          } catch (error) {
            setIsLoading(false);
            toast({
              title: "Error.",
              description: "Terjadi kesalahan, mohon coba lagi",
              status: "error",
              position: "top",
              duration: 3000,
              isClosable: true,
            });
          }
        }
      }
    } else {
      toast({
        title: "Error.",
        description: responseValidation.message,
        status: responseValidation.status,
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const checkIsEdited = () => {
      let edited = false;
      if (bannerList.length) {
        let findEdited = bannerList.find(
          (item) =>
            item.prevTitle !== item.title ||
            item.prevLink !== item.link ||
            item.prevIsActive !== item.isActive ||
            item.prevImagePath !== item.imagePath ||
            item.prevImageMobilePath !== item.imageMobile
        );

        edited = findEdited;
      }
      setIsEdited(edited);
    };

    checkIsEdited();
  }, [bannerList]);

  return (
    <Fragment>
      {isLoading && <Loader />}
      <Breadcrumb title="Home Banner" parent="Content" />
      <FullPreviewModal
        previewClose={previewClose}
        isPreview={isPreview}
        getActiveBanner={getActiveBanner}
      />
      <AddNewBanner
        setIsLoading={setIsLoading}
        getBannerList={getBannerList}
        setIsAddNew={setIsAddNew}
        isAddNew={isAddNew}
      />
      <DeleteBanner
        setIsLoading={setIsLoading}
        getBannerList={getBannerList}
        closeDeleteBanner={closeDeleteBanner}
        isDelete={isDelete}
        selectedBanner={selectedBanner}
      />
      <DeleteImage
        deleteMainImage={deleteMainImage}
        closeDeleteImage={closeDeleteImage}
        isImageDelete={isImageDelete}
        selectedImage={
          selectedBanner?.preview === "Desktop"
            ? selectedBanner.imagePath
            : selectedBanner?.imageMobile
        }
        aspectRatio={selectedBanner?.preview === "Desktop" ? "21 / 9" : "1 / 1"}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col xl={12}>
                    <Flex>
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Banner List
                      </p>
                      <Spacer />
                      <IconButton
                        mr={4}
                        variant="outline"
                        aria-label="Call Sage"
                        fontSize="20px"
                        onClick={() => addNewOpen()}
                        icon={<MdAdd />}
                      />
                      <Button
                        mr={4}
                        variant="ghost"
                        justifyContent="flex-start"
                        border={"2px solid #FF8084"}
                        color={"#FF8084"}
                        onClick={() => previewOpen()}
                      >
                        Preview
                      </Button>
                      <Button
                        variant="ghost"
                        justifyContent="flex-start"
                        backgroundColor={"#FF8084"}
                        color={"white"}
                        isDisabled={!isEdited}
                        onClick={() => submitChanges()}
                      >
                        Simpan
                      </Button>
                    </Flex>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="12" lg="12">
            {bannerList.map((banner, index) => (
              <Card key={banner.id}>
                <CardBody>
                  <Row
                    style={{
                      marginBottom: "16px",
                      paddingBottom: "16px",
                      borderBottom: "2px solid rgba(0,0,0,0.05)",
                    }}
                  >
                    <Col sm="12" md="12" lg="12">
                      <Flex>
                        <Spacer />
                        <IconButton
                          mr={4}
                          variant="outline"
                          aria-label="Call Sage"
                          fontSize="20px"
                          onClick={() => moveDown(banner)}
                          icon={<FaChevronDown />}
                        />
                        <IconButton
                          mr={4}
                          variant="outline"
                          aria-label="Call Sage"
                          fontSize="20px"
                          onClick={() => moveUp(banner)}
                          icon={<FaChevronUp />}
                        />
                        <Button
                          variant="ghost"
                          justifyContent="flex-start"
                          backgroundColor={"#FF8084"}
                          color={"white"}
                          onClick={() => deleteBanner(banner)}
                        >
                          Hapus
                        </Button>
                      </Flex>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4" md="4" lg="4">
                      <Text
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          marginBottom: "8px",
                          color: "black",
                        }}
                      >
                        Title
                      </Text>
                      <Input
                        mb={4}
                        placeholder="Title"
                        name="title"
                        onChange={(e) => dataBannerHandler(e, index)}
                        value={banner.title}
                      />
                      <Text
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          marginBottom: "8px",
                          color: "black",
                        }}
                      >
                        URL
                      </Text>
                      <Input
                        mb={4}
                        placeholder="URL"
                        name="link"
                        onChange={(e) => dataBannerHandler(e, index)}
                        value={banner.link}
                      />
                      <Text
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          marginBottom: "8px",
                          color: "black",
                        }}
                      >
                        Active
                      </Text>
                      <Switch
                        isChecked={banner.isActive}
                        name="isActive"
                        onChange={(e) => dataBannerHandler(e, index)}
                        style={{ marginTop: "12px" }}
                      />
                    </Col>
                    <Col sm="8" md="8" lg="8">
                      <Flex>
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            marginBottom: "16px",
                            color: "black",
                          }}
                        >
                          {banner.preview} Preview
                        </p>
                        <Spacer />
                        {banner.imagePath !== one && (
                          <IconButton
                            mr={4}
                            variant="outline"
                            aria-label="Call Sage"
                            fontSize="20px"
                            onClick={() => openDeleteImage(index, banner)}
                            icon={<MdDelete />}
                          />
                        )}
                        <IconButton
                          mr={4}
                          variant="outline"
                          aria-label="Call Sage"
                          fontSize="20px"
                          onClick={() => changePreview(index, "Desktop")}
                          icon={<FaDesktop />}
                        />
                        <IconButton
                          mr={4}
                          variant="outline"
                          aria-label="Call Sage"
                          fontSize="20px"
                          onClick={() => changePreview(index, "Mobile")}
                          icon={<FaMobileAlt />}
                        />
                      </Flex>
                      <Row>
                        <Col xl={banner.preview === "Desktop" ? 12 : 4}>
                          <div className="add-product">
                            <Row className="file-upload-product row">
                              <Col className="col">
                                <div className="box-input-file-full">
                                  <input
                                    className="upload"
                                    style={{
                                      width: "100%",
                                      aspectRatio: `${
                                        banner.preview === "Desktop"
                                          ? "144 / 71"
                                          : "1 / 1"
                                      }`,
                                      objectFit: "cover",
                                      cursor: "pointer",
                                    }}
                                    type="file"
                                    id="main-image-input"
                                    name="img"
                                    accept="image/*"
                                    onChange={(e) =>
                                      handleMainImageChange(e, index, banner)
                                    }
                                  />
                                  <img
                                    src={
                                      banner.preview === "Desktop"
                                        ? banner.imagePath
                                        : banner.imageMobile
                                    }
                                    alt=""
                                    style={{
                                      width: "100%",
                                      aspectRatio: `${
                                        banner.preview === "Desktop"
                                          ? "144 / 71"
                                          : "1 / 1"
                                      }`,
                                      objectFit: "cover",
                                    }}
                                    className="img-fluid image_zoom_1 blur-up lazyloaded"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ))}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default navguard(HomeBanner);
