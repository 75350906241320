import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Input,
  Select,
  Text,
  Textarea,
  useToast,
  CloseButton,
} from "@chakra-ui/react";
import queryString from "query-string";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import ReactDatePicker from "react-datepicker";
import "../styles/createPromo.css";
import Breadcrumb from "../../common/breadcrumb";
import Axios from "../../../services/axios";
import dateFormat from "dateformat";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import navguard from "../../auth/navguard";
import Tooltip from "../../tooltip";
import {
  fetchUserBadgeProgram,
  getUsersAction,
} from "../../../store/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import ShippingPromo from "../components/ShippingPromo";
import GwpPromo from "../components/GwpPromo";
import DiscountPromo from "../components/DiscountPromo";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { fetchResellerLevel } from "../../../store/actions/resellerLevelAction";

function CreatePromo() {
  const dispatch = useDispatch();
  const toast = useToast();
  const today = new Date();
  const tomorrow = new Date(today);

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(
    tomorrow.setDate(tomorrow.getDate() + 1)
  );
  const [input, setInput] = useState({
    code: "",
    description: "",
    isActive: true,
    minimumPurchase: 0,
    maximumPurchase: 0,
    quotaPerUser: 0,
    quotaPerPromo: 0,
    category: "discount",
    isAutoApplied: false,
    newResellerOnly: false,
    canBeCombined: true,
    directPromoAvailable: true,
  });

  const [promoData, setPromoData] = useState({
    gwpProducts: [
      {
        productId: "",
        qty: "",
      },
    ],
    requiredProducts: [],
    discountType: "",
    promoValue: 0,
    maximumPercentageValue: 0,
    promoMark: "",
  });

  const [allProduct, setAllProduct] = useState([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [optionsResellerLevel, setOptionResellerLevel] = useState([]);
  const [optionsAssignedUser, setOptionsAssignedUser] = useState([]);
  const [optionResellerProgramName, setOptionResellerProgramName] = useState(
    []
  );

  const { users, badgeProgram } = useSelector(({ users }) => users);
  const { resellerLevelList } = useSelector((state) => state.resellerLevel);

  useEffect(() => {
    dispatch(fetchResellerLevel());
  }, []);

  useEffect(() => {
    if (!isEmpty(resellerLevelList)) {
      setOptionResellerLevel(
        resellerLevelList.map((data) => data.resellerLevel)
      );
    }
  }, [resellerLevelList]);

  useEffect(() => {
    if (badgeProgram) {
      const tempBadgeProg = badgeProgram.map((item) => {
        return item.name;
      });

      setOptionResellerProgramName(tempBadgeProg);
    }
  }, [badgeProgram]);

  useEffect(() => {
    if (users) {
      setOptionsAssignedUser(users);
    }
  }, [users]);

  const fecthDataProduct = async () => {
    try {
      const { data } = await Axios({
        url: "/product/list?sort=name,asc",
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      setAllProduct(data.data.products);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fecthDataProduct();
    fetchUserBadgeProgram(dispatch, "promo");
  }, []);

  const validationQty = () => {
    let tempPromodata = { ...promoData };
    let isValid = true;
    let idx = 0;
    while (isValid && idx < tempPromodata.gwpProducts.length) {
      if (!tempPromodata.gwpProducts[idx].qty) {
        isValid = false;
      } else {
        idx += 1;
      }
    }

    return isValid;
  };

  const handleAddPromoData = () => {
    let tempPromodata = { ...promoData };

    const isValid = validationQty();

    if (isValid) {
      tempPromodata.gwpProducts.push({
        productId: "",
        qty: "",
      });
      setPromoData(tempPromodata);

      toast({
        title: "Success.",
        description: "Berhasil menambah product",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error.",
        description: "Mohon mengisi qty gwp",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddRequiredProduct = () => {
    let tempPromodata = { ...promoData };

    tempPromodata.requiredProducts.push("");
    setPromoData(tempPromodata);

    toast({
      title: "Success.",
      description: "Berhasil menambah product",
      status: "success",
      position: "top",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleDeletePromoData = (idx) => {
    let tempPromodata = { ...promoData };
    if (tempPromodata.gwpProducts.length > 0) {
      tempPromodata.gwpProducts.splice(idx, 1);
      toast({
        title: "Success.",
        description: "Berhasil mengurangi product",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      setPromoData(tempPromodata);
    } else {
      toast({
        title: "Warning.",
        description: "Product tidak boleh kosong",
        status: "warning",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteRequiredProduct = (idx) => {
    let tempPromodata = { ...promoData };
    if (tempPromodata.requiredProducts.length > 0) {
      tempPromodata.requiredProducts.splice(idx, 1);
      toast({
        title: "Success.",
        description: "Berhasil mengurangi product",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      setPromoData(tempPromodata);
    } else {
      toast({
        title: "Warning.",
        description: "Product tidak boleh kosong",
        status: "warning",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRequiredProductChange = (category, e, i, isAuto) => {
    let tempPromodata = { ...promoData };

    if (isAuto) {
      if (e) {
        tempPromodata.requiredProducts[i] = e.id;
      } else {
        tempPromodata.requiredProducts[i] = "";
      }
    } else {
      let { name, value, type } = e.target;
      if (type === "number") {
        if (value === "") {
          value = null;
        } else {
          value = parseFloat(value);
        }
      } else if (name === "discountType") {
        tempPromodata[name] = value;
        tempPromodata.promoValue = 0;
        tempPromodata.maximumPercentageValue = 0;
      } else {
        tempPromodata[name] = value;
      }
    }

    setPromoData(tempPromodata);
  };

  const handlePromoDataChange = (e, i, isAuto, selectedGWPProduct, name) => {
    let tempPromodata = { ...promoData };

    if (isAuto) {
      if (e) {
        tempPromodata.gwpProducts[i][name] = selectedGWPProduct.id;
        if (name === "productId") {
          tempPromodata.gwpProducts[i].variantId = "";
        }
      } else {
        tempPromodata.gwpProducts[i][name] = "";
      }
    } else {
      let { name, value, type } = e.target;
      if (type === "number") {
        if (value === "") {
          value = null;
        } else {
          value = parseFloat(value);
        }
      }
      if (name === "qty") {
        tempPromodata.gwpProducts[i][name] = value;
      } else if (name === "discountType") {
        tempPromodata[name] = value;
        tempPromodata.promoValue = 0;
        tempPromodata.maximumPercentageValue = 0;
      } else {
        tempPromodata[name] = value;
      }
    }

    setPromoData(tempPromodata);
  };

  const handleInputChange = (e) => {
    let tempInput = { ...input };
    let tempPromodata = { ...promoData };
    let { name, value, type } = e.target;
    if (type === "number") {
      if (value === "") {
        value = null;
      } else {
        value = parseFloat(value);
      }
    } else if (type === "checkbox") {
      if (name === "canBeCombined" || name === "directPromoAvailable") {
        value = !e.target.checked;
      } else {
        value = e.target.checked;
      }
    }

    if (name === "category") {
      tempPromodata.gwpProducts = [
        {
          productId: "",
          qty: "",
        },
      ];
      tempPromodata.discountType = "";
      tempPromodata.promoMark = "";
      tempPromodata.promoValue = 0;
      setPromoData(tempPromodata);
    } else if (name === "code") {
      value = value.toUpperCase();
    }
    tempInput[name] = value;
    setInput(tempInput);
  };

  const invalidHandler = (statusValidation, errorText) => {
    if (errorText) {
      statusValidation.status = "warning";
      statusValidation.message = errorText;
    }

    return statusValidation;
  };

  const submitValidation = () => {
    let statusValidation = {
      status: "success",
      message: "Promo berhasil disubmit.",
    };

    if (!input.code) {
      invalidHandler(statusValidation, "Mohon isi nilai kode promo");
    } else if (input.category === "shipping") {
      if (promoData.promoValue === 0) {
        invalidHandler(statusValidation, "Mohon isi nilai promo value");
      }
    } else if (input.category === "discount") {
      if (!promoData.discountType) {
        invalidHandler(statusValidation, "Mohon isi nilai discount type");
      } else if (!promoData.promoValue) {
        invalidHandler(statusValidation, "Mohon isi nilai promo value");
      } else if (!promoData.promoMark) {
        invalidHandler(statusValidation, "Mohon isi promo mark (acuan diskon)");
      } else if (promoData.discountType === "percentage") {
        if (promoData.promoValue > 100) {
          invalidHandler(
            statusValidation,
            "Nominal persen tidak boleh lebih dari 100"
          );
        }
      }
    } else if (input.category === "gwp") {
      let isInvalid = false;
      let idx = 0;
      let tempPromodata = { ...promoData };
      let errMessage;

      if (tempPromodata.gwpProducts.length <= 0) {
        invalidHandler(
          statusValidation,
          "Mohon tambahkan product dan qty product"
        );
      }

      while (isInvalid && idx < tempPromodata.gwpProducts.length) {
        if (
          !tempPromodata.gwpProducts[idx].productId ||
          !tempPromodata.gwpProducts[idx].qty
        ) {
          isInvalid = true;
          errMessage = "Mohon isi product dan qty product";
        }
      }

      if (isInvalid) {
        invalidHandler(statusValidation, errMessage);
      }
    }

    if (input.maximumPurchase === 0) {
      return statusValidation;
    }

    if (input.maximumPurchase < input.minimumPurchase) {
      invalidHandler(
        statusValidation,
        "Nilai maximum purchase tidak dapat lebih rendah dari minimum purchase"
      );
    }

    return statusValidation;
  };

  const handleSavePromo = async () => {
    let validation = submitValidation();
    let postData = {
      ...input,
      gwpProducts:
        input.category === "gwp"
          ? promoData.gwpProducts.map((item) => {
              const obj = {
                productId: item.productId,
                qty: item.qty,
              };

              if (item.variantId) {
                obj.variantId = item.variantId;
              }

              return obj;
            })
          : [],
      requiredProducts: promoData.requiredProducts.map((item) => {
        return item;
      }),
      promoMark:
        input.category === "discount" ? promoData.promoMark : "payment",
      startDate: dateFormat(startDate, "yyyy-mm-dd"),
      endDate: dateFormat(endDate, "yyyy-mm-dd"),
      discountAmount:
        input.category === "shipping" ||
        (input.category === "discount" && promoData.discountType === "fixed")
          ? promoData.promoValue
          : 0,
      discountPercentage:
        input.category === "discount" && promoData.discountType === "percentage"
          ? promoData.promoValue > 100
            ? 100
            : promoData.promoValue
          : 0,
      maximumPercentageValue:
        input.category === "discount" && promoData.discountType === "percentage"
          ? promoData.maximumPercentageValue
          : 0,
      minimumPurchase: input.minimumPurchase ? input.minimumPurchase : 0,
      maximumPurchase: input.maximumPurchase ? input.maximumPurchase : 0,
      quotaPerPromo: input.quotaPerPromo ? input.quotaPerPromo : 0,
      quotaPerUser: input.quotaPerUser ? input.quotaPerUser : 0,
      isActive: input.isActive ? true : false,
      originWarehouseId:
        input.category === "shipping" ? promoData.originWarehouseId : "",
      destinationProvinces:
        input.category === "shipping" ? promoData.destinationProvinces : [],
      resellerProgramName: input.resellerProgramName?.map((item) => {
        if (item === "Tanpa program") {
          return "";
        } else {
          return item;
        }
      }),
    };

    setIsLoading(true);
    if (validation.status === "success") {
      try {
        await Axios({
          url: "/promo",
          method: "POST",
          data: {
            ...postData,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });
        setIsLoading(false);
        toast({
          title: "Success.",
          description: validation.message,
          status: validation.status,
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          history.push("/promo/list-promo");
        }, 1500);
      } catch ({ response }) {
        setIsLoading(false);
        if (response.data.errors.length) {
          if (response.data.errorType === "General Error") {
            toast({
              title: "Warning.",
              description: response.data.errors[0].message,
              status: "error",
              position: "top",
              duration: 3000,
              isClosable: true,
            });
          } else if (response.data.errorType === "Bad Request Error") {
            response.data.errors.map((error) => {
              return toast({
                title: "Warning.",
                description: error,
                status: "error",
                position: "top",
                duration: 3000,
                isClosable: true,
              });
            });
          }
        } else {
          toast({
            title: "Warning.",
            description: "Promo gagal disubmit",
            status: "error",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    } else {
      setIsLoading(false);
      toast({
        title: "Warning.",
        description: validation.message,
        status: validation.status,
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleInputReseller = ({ value, type }) => {
    let tempInput = { ...input };
    tempInput[type] = value;
    setInput(tempInput);
  };

  const handleInputAssignedUser = ({ newValue, type }) => {
    let tempInput = { ...input };
    let newObj = newValue.map((item) => item["email"]);
    tempInput["isUserAssigned"] = true;
    tempInput[type] = newObj;
    setInput(tempInput);
  };

  const filterOptionsAssignedUser = (inputValue) => {
    const queries = queryString.stringify({
      q: inputValue,
      limit: 10,
    });

    getUsersAction(dispatch, queries);
  };

  return (
    <>
      <Breadcrumb title="Create Promo" parent="Promo" />
      <Card>
        <CardHeader>
          <Text className="input-promo__header-title">Create Promo</Text>
        </CardHeader>
        <CardBody>
          <Row className="input-promo__title-wrapper">
            <Col lg="3" xl="2">
              <Text className="input-promo__title">Promo code</Text>
            </Col>
            <Col lg="9" xl="10">
              <Row>
                <Col md="4">
                  <Input
                    type={"text"}
                    maxLength={20}
                    value={input.code}
                    name="code"
                    onChange={(event) => handleInputChange(event)}
                    placeholder="Promo code"
                  />
                </Col>
                <Col md="4" />
                <Col md="4">
                  <Select
                    name="isActive"
                    value={input.isActive}
                    onChange={(event) => handleInputChange(event)}>
                    <option value={"true"}>Active</option>
                    <option value={""}>Not Active</option>
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="input-promo__title-wrapper">
            <Col lg="3" xl="2">
              <Text className="input-promo__title">Description</Text>
            </Col>
            <Col lg="9" xl="10">
              <Textarea
                name="description"
                value={input.description}
                onChange={(event) => handleInputChange(event)}
                placeholder="Description"
              />
            </Col>
          </Row>

          <Row className="input-promo__title-wrapper">
            <Col lg="3" xl="2">
              <Text className="input-promo__title">Promo category</Text>
            </Col>
            <Col lg="9" xl="10">
              <Row>
                <Col md="4">
                  <Select
                    value={input.category}
                    name="category"
                    onChange={(event) => handleInputChange(event)}>
                    <option value={"discount"}>Discount</option>
                    <option value={"gwp"}>GWP</option>
                    <option value={"shipping"}>Shipping</option>
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>

        <CardHeader>
          <Text className="input-promo__header-title">Promo Data</Text>
          <Box borderBottom={"1px solid #FF8084"} mt="1em" />
        </CardHeader>
        <CardBody style={{ paddingTop: "0" }}>
          {input.category === "gwp" && (
            <>
              {promoData.gwpProducts.map((item, idx) => (
                <GwpPromo
                  allProduct={allProduct}
                  handlePromoDataChange={handlePromoDataChange}
                  handleDeleteProduct={handleDeletePromoData}
                  item={item}
                  idx={idx}
                />
              ))}

              <Box margin="auto" width={"30%"} mt={"1.5em"}>
                <Button
                  width={"100%"}
                  onClick={handleAddPromoData}
                  variant="ghost"
                  backgroundColor={"#FF8084"}
                  color={"white"}>
                  Add product
                </Button>
              </Box>
            </>
          )}

          {input.category === "discount" && (
            <DiscountPromo
              handlePromoDataChange={handlePromoDataChange}
              promoData={promoData}
            />
          )}

          {input.category === "shipping" && (
            <ShippingPromo
              handlePromoDataChange={handlePromoDataChange}
              promoData={promoData}
              setPromoData={setPromoData}
            />
          )}
          <Box borderBottom={"1px solid #FF8084"} mt="1em" />
        </CardBody>

        <CardBody>
          <Row className="input-promo__title-wrapper">
            <Col lg="3" xl="2">
              <Text className="input-promo__title">Start date</Text>
            </Col>
            <Col lg="9" xl="10">
              <Row>
                <Col md="4">
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
                <Col md="2" />
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    alignItems: "left",
                  }}>
                  <Checkbox
                    style={{ margin: "0", padding: "0" }}
                    className="input-promo__title"
                    colorScheme={"pink"}
                    name="directPromoAvailable"
                    value={!input.directPromoAvailable}
                    isChecked={!input.directPromoAvailable}
                    onChange={(event) => handleInputChange(event)}>
                    Exclude Product Sales/Direct Promo
                  </Checkbox>
                  <Tooltip
                    content={
                      "Centang jika promo tidak berlaku pada produk yang memiliki product discount (harga coret)"
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="input-promo__title-wrapper">
            <Col lg="3" xl="2">
              <Text className="input-promo__title">End date</Text>
            </Col>
            <Col lg="9" xl="10">
              <Row>
                <Col md="4">
                  <ReactDatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
                <Col md="2" />
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Checkbox
                    style={{ margin: "0", padding: "0" }}
                    className="input-promo__title"
                    colorScheme={"pink"}
                    name="isAutoApplied"
                    value={input.isAutoApplied}
                    isChecked={input.isAutoApplied}
                    onChange={(event) => handleInputChange(event)}>
                    Auto Applied
                  </Checkbox>
                  <Tooltip
                    content={
                      "Centang jika promo ingin ter-apply secara otomatis (Promo)" +
                      "<br><br>Jika tidak dicentang, maka user harus memilih promo terlebih dahulu pada saat checkout (juga disebut voucher)"
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="input-promo__title-wrapper">
            <Col
              lg="3"
              xl="2"
              style={{
                display: "flex",
                alignItems: "center",
              }}>
              <Text className="input-promo__title">Minimum purchase</Text>
              <Tooltip
                content={
                  "Nominal minimum transaksi agar promo dapat digunakan."
                }
              />
            </Col>
            <Col lg="9" xl="10">
              <Row>
                <Col md="4">
                  <Input
                    type="number"
                    name="minimumPurchase"
                    value={input.minimumPurchase}
                    onChange={(event) => handleInputChange(event)}
                  />
                </Col>
                <Col md="2" />
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Checkbox
                    style={{ margin: "0", padding: "0" }}
                    className="input-promo__title"
                    colorScheme={"pink"}
                    name="newResellerOnly"
                    value={input.newResellerOnly}
                    isChecked={input.newResellerOnly}
                    onChange={(event) => handleInputChange(event)}>
                    New Reseller Only
                  </Checkbox>
                  <Tooltip
                    content={
                      "Promo hanya berlaku pada reseller yang belum pernah melakukan transaksi"
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="input-promo__title-wrapper">
            <Col
              lg="3"
              xl="2"
              style={{
                display: "flex",
                alignItems: "center",
              }}>
              <Text className="input-promo__title">Maximum purchase</Text>
              <Tooltip
                content={
                  "Nominal maksimum transaksi suatu promo masih dapat digunakan."
                }
              />
            </Col>
            <Col lg="9" xl="10">
              <Row>
                <Col md="4">
                  <Input
                    type="number"
                    name="maximumPurchase"
                    value={input.maximumPurchase}
                    onChange={(event) => handleInputChange(event)}
                  />
                </Col>
                <Col md="2" />
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Checkbox
                    style={{ margin: "0", padding: "0" }}
                    className="input-promo__title"
                    colorScheme={"pink"}
                    name="canBeCombined"
                    value={!input.canBeCombined}
                    isChecked={!input.canBeCombined}
                    onChange={(event) => handleInputChange(event)}>
                    Only for Individual Use
                  </Checkbox>
                  <Tooltip
                    content={
                      "Promo tidak dapat digunakan bersamaan dengan promo lain jika ada promo yang sudah terapply maka promo tersebut akan tercancel dan akan meng-apply promo ini"
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="input-promo__title-wrapper">
            <Col
              lg="3"
              xl="2"
              style={{
                display: "flex",
                alignItems: "center",
              }}>
              <Text className="input-promo__title">Reseller Level</Text>
              <Tooltip
                content={
                  "Promo hanya dapat digunakan oleh level yang dipilih saja, jika dikosongkan maka akan dapat digunakan oleh semua level"
                }
              />
            </Col>
            <Col lg="9" xl="10">
              <Row>
                <Col md="4">
                  <Autocomplete
                    disablePortal
                    options={optionsResellerLevel}
                    getOptionLabel={(option) => option}
                    multiple
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Reseller Level"
                      />
                    )}
                    placement="bottom"
                    onChange={(event, value) =>
                      handleInputReseller({ value, type: "resellerLevel" })
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row className="input-promo__title-wrapper">
            <Col
              lg="3"
              xl="2"
              style={{
                display: "flex",
                alignItems: "center",
              }}>
              <Text className="input-promo__title">Reseller Program</Text>
              <Tooltip
                content={
                  "Promo hanya dapat digunakan oleh reseller dengan program yang dipilih saja, jika dikosongkan maka akan dapat digunakan oleh semua program"
                }
              />
            </Col>
            <Col lg="9" xl="10">
              <Row>
                <Col md="4">
                  <Autocomplete
                    disablePortal
                    options={optionResellerProgramName}
                    getOptionLabel={(option) => option}
                    multiple
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Reseller Program Name"
                      />
                    )}
                    placement="bottom"
                    onChange={(event, value) =>
                      handleInputReseller({
                        value,
                        type: "resellerProgramName",
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row> */}
          <Row className="input-promo__title-wrapper">
            <Col
              lg="3"
              xl="2"
              style={{
                display: "flex",
                alignItems: "center",
              }}>
              <Text className="input-promo__title">Usage Limit per User</Text>
              <Tooltip
                content={
                  "Jumlah maksimal pemakaian promo yang dapat digunakan oleh setiap reseller. Tulis 0 untuk tidak ada limit"
                }
              />
            </Col>
            <Col lg="9" xl="10">
              <Row>
                <Col md="4">
                  <Input
                    type="number"
                    name="quotaPerUser"
                    value={input.quotaPerUser}
                    onChange={(event) => handleInputChange(event)}
                  />
                </Col>
                <Col md="4" />
              </Row>
            </Col>
          </Row>
          <Row className="input-promo__title-wrapper">
            <Col
              lg="3"
              xl="2"
              style={{
                display: "flex",
                alignItems: "center",
              }}>
              <Text className="input-promo__title">Usage Limit per Promo</Text>
              <Tooltip
                content={
                  "Jumlah maksimal pemakaian promo yang dapat digunakan oleh reseller (jumlah promo beredar). Tulis 0 untuk tidak ada limit"
                }
              />
            </Col>
            <Col lg="9" xl="10">
              <Row>
                <Col md="4">
                  <Input
                    type="number"
                    name="quotaPerPromo"
                    value={input.quotaPerPromo}
                    onChange={(event) => handleInputChange(event)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col
              lg="3"
              xl="2"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "1em 0",
              }}>
              <Text className="input-promo__title">Allowed Emails</Text>
              <Tooltip
                content={
                  "Promo hanya dapat digunakan oleh reseller dengan email yang diinput (dapat diisi beberapa email). Kosongkan jika dapat digunakan oleh semua reseller"
                }
              />
            </Col>
            <Col md="12">
              <Autocomplete
                disablePortal
                multiple
                options={optionsAssignedUser}
                getOptionLabel={(option) => option.email || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Insert emails"
                  />
                )}
                placement="bottom"
                onChange={(event, newValue) => {
                  return handleInputAssignedUser({
                    newValue,
                    type: "assignedUser",
                  });
                }}
                onInputChange={(event, inputValue) => {
                  filterOptionsAssignedUser(inputValue);
                }}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "2em" }}>
            <Col
              lg="3"
              xl="2"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "1em 0",
              }}>
              <Text className="input-promo__title">Required Products</Text>
            </Col>
            <Col md="12">
              {!isEmpty(promoData.requiredProducts) && (
                <>
                  {promoData.requiredProducts.map((item, idx) => {
                    return (
                      <Row className="input-promo__title-wrapper" key={idx}>
                        <Col md="12">
                          <Row>
                            <Col md="4">
                              <Autocomplete
                                disablePortal
                                value={allProduct.find(
                                  (data) => data.id === item?.productId
                                )}
                                options={allProduct}
                                getOptionLabel={(product) => product.name}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                                placement="bottom"
                                onChange={(event, value) =>
                                  handleRequiredProductChange(
                                    input.category,
                                    value,
                                    idx,
                                    true
                                  )
                                }
                                name="productId"
                              />
                            </Col>
                            <Col md="1" />
                            <Col lg="3" xl="2" />
                            <Col
                              md="4"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}>
                              <CloseButton
                                onClick={() => handleDeleteRequiredProduct(idx)}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              )}
              <Box width={"30%"} mt={"0em"} mb={"2em"}>
                <Button
                  size="sm"
                  width={"50%"}
                  onClick={handleAddRequiredProduct}
                  colorScheme="teal">
                  Add Required Product
                </Button>
              </Box>
            </Col>
          </Row>

          <Box margin="auto" width={"30%"} mt={"1.5em"}>
            <Button
              width={"100%"}
              variant="ghost"
              backgroundColor={"#FF8084"}
              color={"white"}
              onClick={handleSavePromo}
              isLoading={isLoading}>
              Save
            </Button>
          </Box>
        </CardBody>
      </Card>
    </>
  );
}

export default navguard(CreatePromo);
