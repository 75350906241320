import { SET_PROVINCE } from "../actionTypes";
import Axios from "../../services/axios";

export function provinceAction() {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/user-address/provinces`,
        method: "GET",
      });
      dispatch({
        type: SET_PROVINCE,
        payload: data.data.provinces,
      });
    } catch (error) {
      console.log(error);
    }
  };
}
