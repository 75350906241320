import { Text } from "@chakra-ui/layout";
import React from "react";
import { Container, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { isEmpty } from "lodash";
import "./styles/orderInfo.scss";
import dateFormat from "dateformat";

function OrderInfo({ order }) {
  return (
    <>
      {!isEmpty(order) && (
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader style={{ backgroundColor: "#F0F0F0" }}>
                  <h5>Order Information</h5>
                </CardHeader>
                <CardBody>
                  <Row className="mb-1">
                    <Col lg={4}>
                      <Text className="title">Order Date Time :</Text>
                      <Text>
                        {order.orderDate
                          ? dateFormat(order.orderDate, "dd-mm-yyyy HH:MM:ss")
                          : "-"}
                      </Text>
                    </Col>
                    <Col lg={4}>
                      <Text className="title">Shipping Address :</Text>
                      <Text>{order?.shippingAddress?.recipientName}</Text>
                      <Text>
                        {order?.shippingAddress?.fullAddress},{" "}
                        {order?.shippingAddress?.address?.district},{" "}
                        {order?.shippingAddress?.address?.city},{" "}
                        {order?.shippingAddress?.address?.province},{" "}
                        {order?.shippingAddress?.address?.province === "-"
                          ? ""
                          : order?.shippingAddress?.address?.urban}
                        , {order?.shippingAddress?.address?.postalCode} Telp.{" "}
                        {order?.shippingAddress?.recipientPhone}
                      </Text>
                    </Col>
                    {order?.isDropship ? (
                      <Col lg={4}>
                        <Text className="title">Sender Address :</Text>
                        <Text>{order?.senderAddress?.recipientName}</Text>
                        <Text>
                          {order?.senderAddress?.fullAddress},{" "}
                          {order?.senderAddress?.address?.district},{" "}
                          {order?.senderAddress?.address?.city},{" "}
                          {order?.senderAddress?.address?.province},{" "}
                          {order?.senderAddress?.address?.province === "-"
                            ? ""
                            : order?.senderAddress?.address?.urban}
                          , {order?.senderAddress?.address?.postalCode} Telp.{" "}
                          {order?.senderAddress?.recipientPhone}
                        </Text>
                      </Col>
                    ) : (
                      <Col lg={4}>
                        <Text className="title">Sender Address :</Text>
                        <Text>{order?.warehouse?.warehouseName}</Text>
                        <Text>
                          {order?.warehouse?.fullAddress},{" "}
                          {order?.warehouse?.location?.district},{" "}
                          {order?.warehouse?.location?.city},{" "}
                          {order?.warehouse?.location?.province},{" "}
                          {order?.warehouse?.location?.province === "-"
                            ? ""
                            : order?.warehouse?.location?.urban}
                          , {order?.warehouse?.location?.postalCode} Telp.{" "}
                          {order?.warehouse?.phone}
                        </Text>
                      </Col>
                    )}
                  </Row>
                  <Row className="mb-1">
                    <Col lg={4}>
                      <Text className="title">Status :</Text>
                      <Text>{order.orderStatus}</Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Text className="title">Email :</Text>
                      <Text>{order.user.email}</Text>
                    </Col>
                    <Col lg={4}>
                      <Text className="title">Additional Notes :</Text>
                      <Text>{order?.shippingAddress?.note}</Text>
                    </Col>
                    <Col lg={4}>
                      <Text className="title">Order Weight :</Text>
                      <Text>{order.totalWeight} Kg</Text>
                    </Col>
                  </Row>

                  <div className="card-bottom">
                    <Row className="mt-3">
                      <Col lg={4}>
                        <Text className="title">Payment Mehod :</Text>
                        <Text>{order.paymentMethodId}</Text>
                      </Col>
                      <Col lg={4}>
                        <Text className="title">Shipping Method :</Text>
                        <Text>{order.shippingMethodId}</Text>
                      </Col>
                      <Col lg={4}>
                        <Text className="title">Pickup Datetime :</Text>
                        <Text>
                          {order.pickupDate
                            ? dateFormat(
                                order.pickupDate,
                                "dd-mm-yyyy HH:MM:ss"
                              )
                            : "-"}
                        </Text>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col lg={4}>
                        <Text className="title">Paid Datetime :</Text>
                        <Text>
                          {order.paidDate
                            ? dateFormat(order.paidDate, "dd-mm-yyyy HH:MM:ss")
                            : "-"}
                        </Text>
                      </Col>
                      <Col lg={4}>
                        <Text className="title">AWB Number:</Text>
                        <Text>{order.shippingVendorData.awb}</Text>
                      </Col>
                      <Col lg={4}>
                        <Text className="title">Received Datetime :</Text>
                        <Text>
                          {order.receivedDate
                            ? dateFormat(
                                order.receivedDate,
                                "dd-mm-yyyy HH:MM:ss"
                              )
                            : "-"}
                        </Text>

                        <Text className="title"> Complete Datetime :</Text>
                        <Text>
                          {order.completedDate
                            ? dateFormat(
                                order.completedDate,
                                "dd-mm-yyyy HH:MM:ss"
                              )
                            : "-"}
                        </Text>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default OrderInfo;
