import { SET_PROMO, SET_SINGLE_PROMO } from "../actionTypes";
import Axios from "../../services/axios";

export const setPromo = (queries) => {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/promo/list?${queries}`,
        method: "GET",
      });

      dispatch({
        type: SET_PROMO,
        payload: data.data,
      });
    } catch (err) {
      console.log(err.response);
    }
  };
};

export const setSinglePromo = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/promo/${id}`,
        method: "GET",
      });

      dispatch({
        type: SET_SINGLE_PROMO,
        payload: data.data.promo,
      });
    } catch (err) {
      console.log(err.response);
    }
  };
};

export const searchPromoAction = (queries) => {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/promo/search${queries}`,
        method: "GET",
      });

      dispatch({
        type: SET_PROMO,
        payload: data.data,
      });
    } catch (err) {
      console.log(err.response);
    }
  };
};

export const deletePromo = (id, setSuccessDelete, toast) => {
  return async () => {
    setSuccessDelete(false);
    try {
      await Axios({
        url: `/promo/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      toast({
        title: "Success.",
        description: "Promo berhasil didelete",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });

      setSuccessDelete(true);
    } catch (err) {
      console.log(err.response);
      const { data } = err.response;
      toast({
        title: "Failed.",
        description: `${data?.errorType} ${data?.errors[0].message}`,
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };
};

export const updatePromo = (id, setSuccess, data) => {
  return async () => {
    setSuccess(false);
    try {
      await Axios({
        url: `/promo/${id}`,
        method: "PATCH",
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      setSuccess(true);
    } catch (err) {
      console.log(err.response);
      throw err;
    }
  };
};
