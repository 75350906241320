import React, { Fragment, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./DataTable.css";
import { isEmpty } from "lodash";

const Datatable = ({ myData, order }) => {
  const [data, setData] = useState(myData);
  const formatter = new Intl.NumberFormat("id-ID", {
    minimumFractionDigits: 2,
  });

  useEffect(() => {
    setData(myData);
  }, [myData]);

  const renderEditable = (cellInfo) => {
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          data[cellInfo.index][cellInfo.index.id] = e.target.innerHTML;
          setData({ myData: data });
        }}
        dangerouslySetInnerHTML={{
          __html: myData[cellInfo.index][cellInfo.index.id],
        }}
      />
    );
  };

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const columns = [];
  for (var key in myData[0]) {
    let editable = renderEditable;
    if (key === "image") {
      editable = null;
    }
    if (key === "status") {
      editable = null;
    }
    if (key === "avtar") {
      editable = null;
    }
    if (key === "vendor") {
      editable = null;
    }
    if (key === "order_status") {
      editable = null;
    }
    if (key === "product_name") {
      columns.push({
        name: <b>{Capitalize(key.toString())}</b>,
        header: <b>{Capitalize(key.toString())}</b>,
        selector: key,
        cell: (row, index) => (
          <div>
            <a
              href={"/products/physical/add-product?id=" + index}
              target="_blank"
              rel="noreferrer">
              <span>{row.product_name}</span>
            </a>
          </div>
        ),
        style: {
          textAlign: "center",
        },
      });
    } else {
      columns.push({
        name: <b>{Capitalize(key.toString())}</b>,
        header: <b>{Capitalize(key.toString())}</b>,
        selector: key,
        Cell: editable,
        style: {
          textAlign: "center",
        },
      });
    }
  }

  return (
    <div>
      <Fragment>
        <table>
          <tr>
            <th>Product Name</th>
            <th>Barcode</th>
            <th>Regular Price</th>
            <th>Final Price</th>
            <th>Qty</th>
            <th>Sub Total</th>
          </tr>
          {myData.length &&
            myData.map((each, idx) => (
              <tr key={idx}>
                <td>{each["Product Name"]}</td>
                <td>{each.Barcode}</td>
                <td>Rp {formatter.format(each["Regular Price"])}</td>
                <td>Rp {formatter.format(each["Final Price"])}</td>
                <td>{each.Qty}</td>
                <td className="text-align-right">
                  <div className="space-between">
                    <span>&nbsp;Rp</span>
                    <span>{formatter.format(each["Sub Total"])}</span>
                  </div>
                </td>
              </tr>
            ))}
          {!isEmpty(order) && (
            <>
              <tr className="hidden-tr">
                <td />
                <td />
                <td>something</td>
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td className="text-align-right" colspan="2">
                  Product Subtotal (HET)
                </td>
                <td className="text-align-right">
                  <div className="space-between">
                    <span>&nbsp;Rp</span>
                    <span>{formatter.format(order.subtotal)}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td className="text-align-right" colspan="2">
                  Product Discount
                </td>
                <td className="text-align-right">
                  <div className="space-between">
                    <span>-Rp</span>
                    <span>{formatter.format(order.productDiscount)}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td className="text-align-right" colspan="2">
                  Reseller Discount
                </td>
                <td className="text-align-right">
                  <div className="space-between">
                    <span>-Rp</span>
                    <span>{formatter.format(order.resellerDiscount)}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td className="text-align-right" colspan="2">
                  Volume Discount
                </td>
                <td className="text-align-right">
                  <div className="space-between">
                    <span>-Rp</span>
                    <span>{formatter.format(order.volumeDiscount)}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td className="text-align-right" colspan="2">
                  Other Discount
                </td>
                <td className="text-align-right">
                  <div className="space-between">
                    <span>-Rp</span>
                    <span>{formatter.format(order.otherDiscount)}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td className="text-align-right" colspan="2">
                  Shipping Fee
                </td>
                <td className="text-align-right">
                  <div className="space-between">
                    <span>&nbsp;Rp</span>
                    <span>{formatter.format(order.shippingFee)}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td className="text-align-right" colspan="2">
                  Shipping Discount
                </td>
                <td className="text-align-right">
                  <div className="space-between">
                    <span>-Rp</span>
                    <span>{formatter.format(order.shippingDiscount)}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td className="text-align-right" colspan="2">
                  Total Payment
                </td>
                <td className="text-align-right">
                  <div className="space-between">
                    <span>&nbsp;Rp</span>
                    <span>{formatter.format(order.totalPayment)}</span>
                  </div>
                </td>
              </tr>
            </>
          )}
        </table>
      </Fragment>
    </div>
  );
};

export default Datatable;
