import { Flex, Box } from "@chakra-ui/react";
import "./styles/section-tab.css";
import { useEffect } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
const SectionTab = ({
  page,
  setPage,
  tabActive,
  setTabActive,
  search,
  subTabActive,
  setSubTabActive,
}) => {
  const history = useHistory();
  const changeTabActiveHandler = (value) => {
    setTabActive(value);
    setSubTabActive("All");
    setPage(0);
  };

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    let status = "";
    if (tabActive === "payment") {
      if (subTabActive === "All") {
        status = ["paid", "payment pending"];
      } else if (subTabActive === "Pending") {
        status = ["payment pending"];
      } else if (subTabActive === "Paid") {
        status = ["paid"];
      }
    } else if (tabActive === "To Ship") {
      if (subTabActive === "All") {
        status = ["to process", "processed"];
      } else if (subTabActive === "To Process") {
        status = ["to process"];
      } else if (subTabActive === "Processed") {
        status = ["processed"];
      } else {
        subTabActive = "All"
      }
    } else if (tabActive === "Shipping") {
      if (subTabActive === "All") {
        status = ["in shipping", "received"];
      } else if (subTabActive === "In Shipping") {
        status = ["in shipping"];
      } else if (subTabActive === "Received") {
        status = ["received"];
      } else if (subTabActive === "Cancelled by Courier") {
        status = ["cancelled by courier"];
      }
    } else if (tabActive === "Completed") {
      status = [
        "completed",
        "completed return reject",
        "completed return approve",
      ];
    } else if (tabActive === "Cancelled") {
      status = ["cancelled"];
    } else if (tabActive === "Return") {
      if (subTabActive === "All") {
        status = ["return requested", "in returning", "return confirmed"];
      } else if (subTabActive === "Requested") {
        status = ["return requested"];
      } else if (subTabActive === "Processing") {
        status = ["in returning"];
      } else if (subTabActive === "Confirmed") {
        status = ["return confirmed"];
      }
    } else if (tabActive === "all") {
      status = "";
    } else if (tabActive === "Early Bird") {
      if (subTabActive === "All") {
        status = ["draft","payment pending","paid","cancelled"]
      } else  {
        status = [subTabActive.toLowerCase()]
      }
    }
    let queries = "";


    if (status && tabActive !== "all") {
      if (tabActive === "Early Bird") {
        queries = queryString.stringify(
          {
            ...currentQuery,
            page: page,
            orderStatus: status,
            tab_active: tabActive,
            sub_tab: subTabActive,
          },
          { arrayFormat: "comma" }
        );
      } else {
        queries = queryString.stringify(
          {
            ...currentQuery,
            page: page,
            filter_status: status,
            tab_active: tabActive,
            sub_tab: subTabActive,
          },
          { arrayFormat: "comma" }
        );
      }

      history.push(`/order-list?${queries}`);
    } else {
      status = [
        "paid",
        "payment pending",
        "to process",
        "processed",
        "in shipping",
        "received",
        "completed",
        "completed return reject",
        "completed return approve",
        "cancelled",
        "return requested",
        "in returning",
        "return confirmed",
      ];

      const filter = {
        ...currentQuery,
        page: page,
        filter_status: status,
        sort: currentQuery.sort,
        filter_order_date_min: currentQuery.filter_order_date_min,
        filter_order_date_max: currentQuery.filter_order_date_max,
        q: currentQuery.q,
        warehouseId: currentQuery.warehouseId,
        tab_active: tabActive,
        sub_tab: subTabActive,
      };

      queries = queryString.stringify(
        { ...filter },
        {
          skipEmptyString: true,
          arrayFormat: "comma",
        }
      );
      if (queries) {
        history.push(`/order-list?${queries}`);
      } else {
        history.push(`/order-list`);
      }
    }
  }, [tabActive, subTabActive, history, page]);

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (currentQuery.tab_active) {
      setTabActive(currentQuery.tab_active);
    } else {
      setTabActive("all");
    }
    if (currentQuery.sub_tab) {
      setSubTabActive(currentQuery.sub_tab);
    } else {
      setSubTabActive("All");
    }
  }, [search]);

  return (
    <Flex className="order-list__section-tab__container">
      <Box
        className={`order-list__section-tab__item ${
          tabActive === "all" && "tab-active"
        }`}
        onClick={() => changeTabActiveHandler("all")}
      >
        All
      </Box>
      <Box
        className={`order-list__section-tab__item ${
          tabActive === "payment" && "tab-active"
        }`}
        onClick={() => changeTabActiveHandler("payment")}
      >
        Payment
      </Box>
      <Box
        className={`order-list__section-tab__item ${
          tabActive === "To Ship" && "tab-active"
        }`}
        onClick={() => changeTabActiveHandler("To Ship")}
      >
        To Ship
      </Box>
      <Box
        className={`order-list__section-tab__item ${
          tabActive === "Shipping" && "tab-active"
        }`}
        onClick={() => changeTabActiveHandler("Shipping")}
      >
        Shipping
      </Box>
      <Box
        className={`order-list__section-tab__item ${
          tabActive === "Completed" && "tab-active"
        }`}
        onClick={() => changeTabActiveHandler("Completed")}
      >
        Completed
      </Box>
      <Box
        className={`order-list__section-tab__item ${
          tabActive === "Cancelled" && "tab-active"
        }`}
        onClick={() => changeTabActiveHandler("Cancelled")}
      >
        Cancelled
      </Box>
      <Box
        className={`order-list__section-tab__item ${
          tabActive === "Return" && "tab-active"
        }`}
        onClick={() => changeTabActiveHandler("Return")}
      >
        Return
      </Box>
      <Box
        className={`order-list__section-tab__item ${
          tabActive === "Early Bird" && "tab-active"
        }`}
        onClick={() => changeTabActiveHandler("Early Bird")}
      >
        Early Bird
      </Box>
    </Flex>
  );
};

export default SectionTab;
