import React, { useState, useEffect } from "react";
import "../styles/stocklist.scss";
import { Input, Flex, Button, useToast } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import {
  warehouseProductAction,
  warehouseProductUpdate,
} from "../../../store/actions/warehouseAction";
import { isEmpty } from "lodash";

const StockSingleCard = ({ product, id, setPage, page }) => {
  const toast = useToast();

  const [productState, setProductState] = useState({});
  const dispatch = useDispatch();

  const changeFieldInput = ({ type, event }) => {
    const objTemp = { ...productState };
    objTemp.warehouseStocks[0][type] = event.target.value;
    setProductState(objTemp);
  };

  const saveProductHandler = async () => {
    const { productId } = productState;
    const { qcStock, availableStock, minSafetyStock } =
      productState.warehouseStocks[0];
    try {
      await dispatch(
        warehouseProductUpdate({
          warehouseId: id,
          stock: [
            {
              productId: productId,
              availableStock: Number(availableStock),
              minSafetyStock: Number(minSafetyStock),
              qcStock: Number(qcStock),
            },
          ],
        })
      );

      await dispatch(
        warehouseProductAction({ id, isInitiated: true, page: page + 1 })
      );
      toast({
        title: "Success.",
        description: "Berhasil mengedit product.",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Error.",
        description: "Failed, mohon coba lagi.",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (product.productId) {
      setProductState({ ...product });
    }
  }, [product]);

  return (
    <Flex justify="space-between" align="flex-end">
      {!isEmpty(productState) && (
        <>
          <div className="input-wrapper">
            <span className="input-label">Available Stock</span>
            <div className="input">
              <Input
                value={productState?.warehouseStocks[0]?.availableStock}
                onChange={(event) =>
                  changeFieldInput({
                    event,
                    type: "availableStock",
                  })
                }
              />
            </div>
          </div>
          <div className="input-wrapper">
            <span className="input-label">Min Safety Stock</span>
            <div className="input">
              <Input
                placeholder
                value={productState?.warehouseStocks[0]?.minSafetyStock}
                onChange={(event) =>
                  changeFieldInput({
                    event,
                    type: "minSafetyStock",
                  })
                }
              />
            </div>
          </div>
          <div className="input-wrapper">
            <span className="input-label">QC Stock</span>
            <div className="input">
              <Input
                value={productState?.warehouseStocks[0]?.qcStock}
                onChange={(event) =>
                  changeFieldInput({ event, type: "qcStock" })
                }
              />
            </div>
          </div>
          <div className="input-wrapper">
            <span className="input-label">Reserved Stock</span>
            <div className="input">
              <Input
                disabled
                value={productState?.warehouseStocks[0]?.reservedStock}
              />
            </div>
          </div>
        </>
      )}
      <Button
        onClick={saveProductHandler}
        background="#ff8084"
        color="white"
        style={{ float: "right", height: "32px", fontSize: "14px" }}>
        Update
      </Button>
    </Flex>
  );
};

export default StockSingleCard;
