import { Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import { Col, Row } from "reactstrap";
import queryString from "query-string";
import { isEmpty, find, uniqBy } from "lodash";
import Axios from "../../../services/axios";

function FilterByShippingMethod({ setPage }) {
  const [selected, setSelected] = useState([]);
  const [fullOption, setFullOption] = useState([]);
  const [option, setOption] = useState([]);
  const { search } = useLocation();
  const history = useHistory();

  const fetchOption = async () => {
    try {
      const { data } = await Axios({
        url: "/admin/shippingMethod",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      const uniqueOption = [];

      const result = data.data.methods.map((item) => {
        if (uniqueOption.indexOf(item.name) === -1) {
          uniqueOption.push(item.name);
        }
        return {
          value: item.id,
          label: item.name,
        };
      });
      setFullOption(result);

      const uniqueResult = uniqueOption.map((item) => {
        return {
          value: item,
          label: item,
        };
      });

      setOption(uniqueResult);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchOption();
  }, []);

  const getShippingId = (value) => {
    let result = [];

    value.forEach((each) => {
      result = [
        ...result,
        ...fullOption.filter((item) => item.label === each.label),
      ];
    });

    return result;
  };

  const handleSelected = (value) => {
    setSelected(value);
    setPage(0);
    let shippingId = getShippingId(value);

    const currentQuery = queryString.parse(search);
    let query;
    if (!isEmpty(value)) {
      query = queryString.stringify({
        ...currentQuery,
        page: 0,
        shippingMethodId: shippingId.map((ship) => ship.value).join(","),
      });
    } else {
      query = queryString.stringify(
        {
          ...currentQuery,
          page: 0,
          shippingMethodId: "",
        },
        { skipEmptyString: true }
      );
    }
    history.push(`/order-list?${query}`);
  };

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (currentQuery.shippingMethodId && fullOption.length > 0) {
      const queries = uniqBy(
        currentQuery.shippingMethodId.split(",").map((ship) => {
          const findData = find(fullOption, { value: ship });
          return {
            value: findData.label,
            label: findData.label,
          };
        }),
        "label"
      );
      setSelected(queries);
    }
  }, [search, fullOption]);

  return (
    <Row style={{ width: "40%", alignItems: "center" }}>
      <Col
        md="4"
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <Text color="black" fontSize="16px">
          Shipping method :
        </Text>
      </Col>
      <Col md="8">
        <ReactSelect
          isMulti
          className="basic-multi-select"
          classNamePrefix="select"
          options={option}
          isSearchable
          onChange={(value) => handleSelected(value)}
          value={selected}
          placeholder="Choose Delivery Service"
        />
      </Col>
    </Row>
  );
}

export default FilterByShippingMethod;
