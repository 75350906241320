import React, { Fragment, useEffect, useState } from "react";
import styles from "./styles.module.css";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import { Box, useToast, Text, Heading, Flex } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyProductShowcaseState,
  updateProductShowcaseValue,
} from "../../store/actions/productShowcaseAction";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import ImageDesktopHandler from "./components/imageDesktopHandler";
import navguard from "../auth/navguard";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import axios from "axios";

const EditProductShowcase = () => {
  const productShowcase = useSelector(
    (state) => state.productShowcase.showcaseEdit
  );

  const stateImageDesktop = useSelector(
    ({ productShowcase }) => productShowcase.imageUploadDesk
  );

  const dispatch = useDispatch();

  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [ordering, setOrdering] = useState(0);
  const [error, setError] = useState("");
  const [productId, setProductId] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [allProduct, setAllProduct] = useState([]);

  const history = useHistory();
  const toast = useToast();

  const onValidateInput = () => {
    if (isEmpty(title)) {
      setError("Field Title wajib diisi");
      return false;
    } else {
      setError("");
      return true;
    }
  };

  const onSubmitEdit = () => {
    try {
      const isValid = onValidateInput();
      if (!isValid) throw "Validation error";

      let data = new FormData();

      data.append("title", title);
      data.append("productId", productId);

      // 👇 NOTE: Image upload on edit still 500 Internal error on Backend, so this code commented
      // data.append(`image1`, stateImageDesktop[0].imageFile);
      // data.append(`image2`, stateImageDesktop[1].imageFile);
      // data.append(`image3`, stateImageDesktop[2].imageFile);
      // data.append(`image4`, stateImageDesktop[3].imageFile);
      // data.append(`image5`, stateImageDesktop[4].imageFile);

      dispatch(updateProductShowcaseValue(data, id));

      setTimeout(() => {
        history.push(`/content/list-productshowcase`);
      }, 2000);

      toast({
        title: "Success.",
        description: "Product Showcase berhasil diubah",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Error.",
        description: "Product Showcase gagal diupdate:",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (productShowcase) {
      setId(productShowcase.id);
      setTitle(productShowcase.title);
      setProductId(productShowcase.product.id);
      setOrdering(productShowcase.ordering);
      setSelectedProduct(productShowcase.product);
    }
  }, [productShowcase]);

  const onChangeProductId = (selectedOption) => {
    if (selectedOption) {
      setProductId(selectedOption.id);
      setSelectedProduct(selectedOption);
    }
  };

  const reloadData = async () => {
    let getData = [];
    try {
      getData = await axios.get(
        "https://api-qb.pti-cosmetics.com/product/list?sort=createdAt,desc"
      );
    } catch (e) {
      console.error(e);
    }

    setAllProduct(getData.data.data.products);
  };

  useEffect(() => {
    const fetchProductData = async () => {
      await reloadData();
    };

    fetchProductData();

    return () => {
      emptyProductShowcaseState(dispatch);
    };
  }, []);

  return (
    <Fragment>
      <Breadcrumb title="Edit banner" parent="Banner" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Add Menu</h5>
              </CardHeader>
              <CardBody>
                <Form className="needs-validation">
                  <Flex className="form-group row">
                    <Label className="col-xl-3 col-md-4">Banner Title</Label>
                    <Input
                      className="form-control col-md-8"
                      id="validationCustom0"
                      type="text"
                      required=""
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Flex>

                  <Flex
                    justifyContent="flex-start"
                    alignItems="center"
                    mt={1}
                    mb={1}
                    ml={-1}
                  >
                    <Label className="col-xl-3 col-md-4">
                      Banner ProductId
                    </Label>
                    <Autocomplete
                      disablePortal
                      className={styles["autocomplete-product"]}
                      value={selectedProduct}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      options={allProduct}
                      onChange={(event, value) => onChangeProductId(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select Product"
                        />
                      )}
                      placement="bottom"
                    />
                  </Flex>

                  <div className="form-group row">
                    <Label className="col-xl-3 col-md-4">
                      Upload Image Desktop
                    </Label>

                    {productShowcase && (
                      <>
                        <ImageDesktopHandler
                          imageIdx={0}
                          imagePathShowcase={productShowcase.image1Path}
                        />
                        <ImageDesktopHandler
                          imageIdx={1}
                          imagePathShowcase={productShowcase.image2Path}
                        />
                        <ImageDesktopHandler
                          imageIdx={2}
                          imagePathShowcase={productShowcase.image3Path}
                        />
                        <ImageDesktopHandler
                          imageIdx={3}
                          imagePathShowcase={productShowcase.image4Path}
                        />
                        <ImageDesktopHandler
                          imageIdx={4}
                          imagePathShowcase={productShowcase.image5Path}
                        />
                      </>
                    )}
                  </div>

                  <Box
                    maxW="sm"
                    borderWidth="1px"
                    w={100}
                    h="fit-content"
                    borderRadius="lg"
                    overflow="hidden"
                  >
                    <Flex
                      direction="column"
                      align="center"
                      alignContent="center"
                    >
                      <Text>Ordering</Text>
                      <Heading as="h4" size="lg" color="black">
                        {ordering}
                      </Heading>
                    </Flex>
                  </Box>

                  <Box mt={5}>
                    <Text color="red">{error}</Text>

                    <Button
                      style={{ margin: "5px" }}
                      type="button"
                      color="primary"
                      onClick={onSubmitEdit}
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default navguard(EditProductShowcase);
