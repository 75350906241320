import { Box, Text } from "@chakra-ui/react";
import React from "react";

function NoDataDisplay({ headingText, mainText }) {
  return (
    <Box my={3}>
      { headingText ? <Text color="black" textAlign="center" fontWeight="bold" fontSize="md">{headingText}</Text> : null  }
      { mainText ? <Text color="black">{mainText}</Text> : null }
    </Box>
  );
}

export default NoDataDisplay;
