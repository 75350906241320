import {
  SET_ALL_ORDER_LIST,
  SET_ORDER_LIST,
  SET_ORDER_DETAIL,
  SET_REQUEST_DETAIL,
} from "../actionTypes";
import Axios from "../../services/axios";

export function orderListACtion(queries, isEarlyBird) {
  return async (dispatch) => {
    try {
      if (!isEarlyBird) {
        const { data } = await Axios({
          url: `/admin-order?${queries}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });

        dispatch({
          type: SET_ORDER_LIST,
          payload: {
            orders: data.data.orders,
            totalData: data.data.totalData,
            totalPage: data.data.totalPage,
          },
        });
      } else {
        const { data } = await Axios({
          url: `/admin/growthPointVoucher/order?${queries}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });
        dispatch({
          type: SET_ORDER_LIST,
          payload: {
            orders: data.data.orders,
            totalData: data.data.totalData,
            totalPage: data.data.totalPage,
          },
        });
      }
    } catch (error) {
      console.error(error.response);
    }
  };
}

export function allOrderListACtion(queries) {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/admin-order?${queries}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      dispatch({
        type: SET_ALL_ORDER_LIST,
        payload: {
          orders: data.data.orders,
          totalData: data.data.totalData,
          totalPage: data.data.totalPage,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export const paymentProcessAction = ({ invoiceNumber, setSuccessUpdate }) => {
  return async () => {
    return new Promise(async (resolve) => {
      setSuccessUpdate(null);
      try {
        await Axios({
          url: `/admin-order/${invoiceNumber}/process`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });
        setSuccessUpdate({
          condition: true,
          message: "Bulk action berhasil",
        });

        resolve(true);
      } catch (error) {
        console.log(error.response);
        setSuccessUpdate({
          condition: false,
          message: "Pembuatan AWB tidak berhasil, silahkan coba lagi",
        });

        resolve(false);
      }
    });
  };
};

export const toShipProcessAction = ({ invoiceNumber, setSuccessUpdate }) => {
  return async () => {
    return new Promise(async (resolve) => {
      setSuccessUpdate(null);
      try {
        await Axios({
          url: `/admin-order/${invoiceNumber}/process-complete`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });
        setSuccessUpdate({
          condition: true,
          message: "Bulk action berhasil",
        });
        resolve(true);
      } catch (error) {
        console.log(error);
        setSuccessUpdate({
          condition: false,
          message: "Pembuatan AWB tidak berhasil, silahkan coba lagi",
        });
        resolve(false);
      }
    });
  };
};

export const confirmReturnAction = ({ invoiceNumber, setSuccessUpdate }) => {
  return async () => {
    setSuccessUpdate(null);
    try {
      await Axios({
        url: `/admin/order-return/${invoiceNumber}/process`,
        method: "POST",
        data: {
          approve: true,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      setSuccessUpdate({
        condition: true,
        message: "Bulk action berhasil",
      });
    } catch (error) {
      console.log(error.response);
      setSuccessUpdate({
        condition: false,
        message: "Bulk action tidak berhasil",
      });
    }
  };
};

export const reRequestPickupAction = ({ invoiceNumber, setSuccessUpdate }) => {
  return async () => {
    setSuccessUpdate(null);
    try {
      await Axios({
        url: `/admin-order/${invoiceNumber}/re-request-pickup`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      setSuccessUpdate({
        condition: true,
        message: "Bulk action berhasil",
      });
    } catch (error) {
      console.log(error.response);
      setSuccessUpdate({
        condition: false,
        message: "Bulk action tidak berhasil",
      });
    }
  };
};

export const approveRejectAction = ({
  invoiceNumber,
  data,
  setSuccessUpdate,
}) => {
  return async () => {
    setSuccessUpdate(null);
    try {
      await Axios({
        url: `/admin/order-return/${invoiceNumber}/response`,
        method: "POST",
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      setSuccessUpdate({
        condition: true,
        message: "Bulk action berhasil",
      });
    } catch (error) {
      console.log(error.response);
      setSuccessUpdate({
        condition: false,
        message: "Bulk action tidak berhasil",
      });
    }
  };
};

export const completeReturnApprove = ({
  invoiceNumber,
  data,
  setSuccessUpdate,
}) => {
  return async () => {
    setSuccessUpdate(null);
    try {
      await Axios({
        url: `/admin/order-return/${invoiceNumber}/complete-return-approve`,
        method: "POST",
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      setSuccessUpdate({
        condition: true,
        message: "Bulk action berhasil",
      });
    } catch (error) {
      console.log(error.response);
      setSuccessUpdate({
        condition: false,
        message: "Bulk action tidak berhasil",
      });
    }
  };
};

export const updateNotesAction = ({
  invoiceNumber,
  description,
  setSuccessUpdate,
}) => {
  return async () => {
    setSuccessUpdate(null);
    try {
      await Axios({
        url: `/admin/order-return/${invoiceNumber}/response`,
        method: "PUT",
        data: description,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      setSuccessUpdate({
        condition: true,
        message: "Bulk action berhasil",
      });
    } catch (error) {
      console.log(error.response);
      setSuccessUpdate({
        condition: false,
        message: "Bulk action tidak berhasil",
      });
    }
  };
};

export const getOrderDetail = (invoiceNumber) => {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/admin-order/${invoiceNumber}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      dispatch({
        type: SET_ORDER_DETAIL,
        payload: data.data.order,
      });
    } catch (error) {
      console.log(error.response);
    }
  };
};

export const getRequestDetail = (invoiceNumber) => {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/admin/order-return/${invoiceNumber}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      dispatch({
        type: SET_REQUEST_DETAIL,
        payload: data.data.requestDetail,
      });
    } catch (error) {
      console.log(error.response);
    }
  };
};
