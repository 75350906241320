import React, { useState, useEffect } from "react";
import styles from "./style.module.css";
import dateFormat from "dateformat";
import {
  Box,
  Flex,
  Image,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import JsBarcode from "jsbarcode";
import { Col, Row } from "reactstrap";
import pbplogo from "../../../assets/images/logo-pbp.png";
import { isEmpty } from "lodash";

function PackingSlipPdf({ data }) {
  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  const [shippingIcon, setShippingIcon] = useState("");
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (
      data?.shippingVendorData?.awb &&
      data?.shippingVendorData?.awb !== "-"
    ) {
      JsBarcode(
        `#inv-${data.shippingVendorData.awb}`,
        data.shippingVendorData.awb,
        {
          format: "CODE128",
          width: 1,
          height: 20,
          displayValue: false,
        }
      );
    }

    toDataURL(data?.shippingMethod?.iconPath, function (dataUrl) {
      setShippingIcon(dataUrl);
    });

    const products = !isEmpty(data?.orderDetail)
      ? data.orderDetail.map((item) => {
          return {
            name: `${item.name}${
              item?.productType === "Variant"
                ? ` - ${item?.selectedVariant?.name}`
                : ""
            }`,
            barcode: item.productType.includes("Variant")
              ? item?.selectedVariant?.barcode
              : item.barcode,
            odooCode: item.productType.includes("Variant")
              ? item?.selectedVariant?.odooCode
              : item?.odooCode,
            qty: `${item.qty} pcs`,
          };
        })
      : [];
    setTableData([...products]);
  }, [data]);
  return (
    <Box className={styles["invoice-container"]}>
      <Flex className={styles["invoice-header"]}>
        <Box>Invoice {data.invoiceNumber}</Box>
        <Spacer />
        {!data.isDropship && <Image src={pbplogo} height={"72px"} />}
      </Flex>
      <Text className={styles["invoice-title"]}>Informasi Pesanan</Text>
      <Box className={styles["invoice-divider"]} />
      <Row>
        <Col xs={4}>
          <Box className={styles["invoice-info-section"]}>
            <Text className={styles["invoice-info-title"]}>
              Order Datetime:
            </Text>
            <Text className={styles["invoice-info-desc"]}>
              {data.orderDate}
            </Text>
          </Box>
          <Box className={styles["invoice-info-section"]}>
            <Text className={styles["invoice-info-title"]}>Status:</Text>
            <Text className={styles["invoice-info-desc"]}>
              {data.orderStatus}
            </Text>
          </Box>
          <Box className={styles["invoice-info-section"]}>
            <Text className={styles["invoice-info-title"]}>Email:</Text>
            <Text className={styles["invoice-info-desc"]}>
              {data.user?.email}
            </Text>
          </Box>
        </Col>
        <Col xs={4}>
          <Box className={styles["invoice-info-section"]}>
            <Text className={styles["invoice-info-title"]}>Penerima:</Text>
            <Text className={styles["invoice-info-desc"]}>
              {data?.shippingAddress?.recipientName}
            </Text>
            <Text className={styles["invoice-info-desc"]}>
              {data?.shippingAddress?.fullAddress}
            </Text>
            <Text className={styles["invoice-info-desc"]}>
              {data?.shippingAddress?.address?.urban},{" "}
              {data?.shippingAddress?.address?.district},{" "}
              {data?.shippingAddress?.address?.city},{" "}
              {data?.shippingAddress?.address?.province},{" "}
              {data?.shippingAddress?.address?.postalCode}
            </Text>
            <Text className={styles["invoice-info-desc"]}>
              Telp. {data?.shippingAddress?.recipientPhone}
            </Text>
          </Box>
          <Box className={styles["invoice-info-section"]}>
            <Text className={styles["invoice-info-title"]}>Note:</Text>
            <Text className={styles["invoice-info-desc"]}>
              {data?.shippingAddress?.note}
            </Text>
          </Box>
        </Col>
        <Col xs={4}>
          <Box className={styles["invoice-info-section"]}>
            <Text className={styles["invoice-info-title"]}>Pengirim:</Text>
            {data?.isDropship ? (
              <>
                <Text className={styles["invoice-info-desc"]}>
                  {data?.senderAddress?.recipientName}
                </Text>
                <Text className={styles["invoice-info-desc"]}>
                  {data?.senderAddress?.fullAddress}
                </Text>
                <Text className={styles["invoice-info-desc"]}>
                  {data?.senderAddress?.address?.urban},{" "}
                  {data?.senderAddress?.address?.district},{" "}
                  {data?.senderAddress?.address?.city},{" "}
                  {data?.senderAddress?.address?.province},{" "}
                  {data?.senderAddress?.address?.postalCode}
                </Text>
                <Text className={styles["invoice-info-desc"]}>
                  Telp. {data?.senderAddress?.recipientPhone}
                </Text>
              </>
            ) : (
              <>
                <Text className={styles["invoice-info-desc"]}>
                  {data?.warehouse?.warehouseName}
                </Text>
                <Text className={styles["invoice-info-desc"]}>
                  {data?.warehouse?.addressDetail}
                </Text>
                <Text className={styles["invoice-info-desc"]}>
                  Telp. {data?.warehouse?.phone}
                </Text>
              </>
            )}
          </Box>
          <Box className={styles["invoice-info-section"]}>
            <Text className={styles["invoice-info-title"]}>Berat Pesanan:</Text>
            <Text className={styles["invoice-info-desc"]}>
              {data.totalWeight} Kg
            </Text>
          </Box>
        </Col>
      </Row>
      <Box className={styles["invoice-divider"]} />
      <Row>
        <Col xs={4}>
          <Box className={styles["invoice-info-section"]}>
            <Text className={styles["invoice-info-title"]}>
              Metode Pesanan:
            </Text>
            <Text className={styles["invoice-info-desc"]}>
              {data.shippingMethodId}
            </Text>
          </Box>
          <Image width={"60%"} src={shippingIcon} />
        </Col>
        <Col xs={4}>
          <Box className={styles["invoice-info-section"]}>
            <Text className={styles["invoice-info-title"]}>Nomor AWB:</Text>
            <Text className={styles["invoice-info-desc"]}>
              {data.shippingVendorData.awb}
            </Text>
          </Box>
          {data.shippingVendorData.awb && (
            <Image id={`inv-${data.shippingVendorData.awb}`} />
          )}
        </Col>
      </Row>
      <Box className={styles["invoice-empty-space"]} />
      <Text className={styles["invoice-title"]}>Informasi Produk</Text>
      <Box className={styles["invoice-divider"]} />
      <TableContainer>
        <Table variant="simple" className={styles["invoice-table"]}>
          <Thead>
            <Tr>
              <Th className={styles["invoice-info-title"]}>Nama Produk</Th>
              <Th className={styles["invoice-info-title"]}>Odoo</Th>
              <Th className={styles["invoice-info-title"]}>Barcode</Th>
              <Th className={styles["invoice-info-title"]}>Jumlah</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tableData.map((data, idx) => (
              <Tr key={idx}>
                <Td>{data.name}</Td>
                <Td>{data?.odooCode}</Td>
                <Td>{data.barcode}</Td>
                <Td>{data.qty}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PackingSlipPdf;
