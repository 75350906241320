import React from "react";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Box,
  Image,
} from "@chakra-ui/react";
import styles from "./styles.module.css";

const IconStar = ({ active }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6315 6.11934C14.5896 5.99592 14.5125 5.88747 14.4096 5.80738C14.3068 5.72729 14.1828 5.67908 14.0528 5.66868L10.2522 5.36668L8.60751 1.72601C8.55514 1.60875 8.46994 1.50916 8.36221 1.43925C8.25448 1.36934 8.12882 1.3321 8.0004 1.33203C7.87197 1.33196 7.74627 1.36906 7.63846 1.43885C7.53066 1.50864 7.44535 1.60814 7.39285 1.72534L5.74818 5.36668L1.94751 5.66868C1.81982 5.67879 1.69774 5.72548 1.59588 5.80316C1.49403 5.88084 1.41671 5.98622 1.37318 6.1067C1.32964 6.22717 1.32174 6.35763 1.35041 6.48248C1.37908 6.60733 1.44311 6.72127 1.53485 6.81068L4.34351 9.54868L3.35018 13.85C3.32002 13.9802 3.32969 14.1165 3.37793 14.2411C3.42617 14.3657 3.51076 14.473 3.62071 14.549C3.73067 14.6249 3.86093 14.6661 3.99457 14.6671C4.12821 14.6681 4.25908 14.629 4.37018 14.5547L8.00018 12.1347L11.6302 14.5547C11.7437 14.6301 11.8777 14.6689 12.0139 14.6659C12.1502 14.6629 12.2823 14.6183 12.3924 14.538C12.5026 14.4577 12.5855 14.3456 12.63 14.2167C12.6746 14.0879 12.6786 13.9485 12.6415 13.8173L11.4222 9.55068L14.4462 6.82934C14.6442 6.65068 14.7168 6.37201 14.6315 6.11934Z"
      fill={active ? "#FFBB00" : "#CCCCCC"}
    />
  </svg>
);

function ModalView({
  isOpen,
  onClose,
  reviews,
  mainItem,
  duration,
  roundTime,
}) {
  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    dotsClass: styles.button__bar,
  };

  return (
    <Modal onClose={onClose} size={"lg"} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent paddingTop={"70px"} className={styles["modal-content"]}>
        <ModalCloseButton />
        <ModalBody padding={"16px"}>
          <Flex pb={"8px !important"}>
            <Box className={styles["image-container"]}>
              <img
                className={styles["image"]}
                src={mainItem.user?.displayPicturePath || ""}
              />
            </Box>
            <Box>
              <Flex
                align="center"
                padding="0 !important"
                mb={"12px"}
                position="relative"
              >
                <Text className={styles["review-user__name"]}>
                  {mainItem.user
                    ? `${mainItem.user.firstName} ${mainItem.user.lastName}`
                    : mainItem.customerData.customerName}
                </Text>
                <Text
                  className={styles["review-user__type"]}
                  style={{
                    textTransform: "capitalize",
                    background:
                      mainItem.reviewerType === "reseller"
                        ? "#E9DBF0"
                        : "#d9ebaf",
                  }}
                >
                  {mainItem.reviewerType}
                </Text>
                <Text className={styles["review-user__time"]}>
                  {duration === 0
                    ? "Hari ini"
                    : duration === -1
                    ? "1 hari yang lalu"
                    : roundTime.fromNow()}
                </Text>
              </Flex>
              <Text className={styles["review-user__time-mobile"]}>
                {duration === 0
                  ? "Hari ini"
                  : duration === -1
                  ? "1 hari yang lalu"
                  : roundTime.fromNow()}
              </Text>
              <Flex
                width="96px"
                justify="space-between"
                mb="12px"
                padding="0 !important"
              >
                {[1, 2, 3, 4, 5].map((number) => {
                  return <IconStar active={number <= mainItem.rating} />;
                })}
              </Flex>
            </Box>
          </Flex>
          <div className={styles["slider-wrapper"]}>
            <div className="slider-about-qbee_mobile">
              <Slider {...settings} className="qbee-point-slider">
                {reviews.map((item, i) => {
                  return (
                    <Box
                      className={styles["image-review-container"]}
                      style={{
                        borderRadius: "8px",
                      }}
                      key={i}
                    >
                      {item.type === "image" ? (
                        <Image
                          className={styles["image-wrapper"]}
                          src={item.mediaLink}
                        />
                      ) : (
                        <ReactPlayer
                          width="100%"
                          height="100%"
                          controls={true}
                          url={item.mediaLink}
                        />
                      )}
                    </Box>
                  );
                })}
              </Slider>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ModalView;
