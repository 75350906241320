import React from "react";
import {
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Button,
  Select,
  useToast,
} from "@chakra-ui/react";
import "../style/subtopic.css";
import { Row, Col } from "reactstrap";
import Axios from "../../../services/axios";

function AddSubtopic({
  isOpen,
  onClose,
  type,
  inputSubtopic,
  setInputSubtopic,
  fetchData,
  idSubtopic,
}) {
  const toast = useToast();
  const handleChangeInput = (e) => {
    let tempInput = { ...inputSubtopic };
    tempInput[e.target.name] = e.target.value;
    setInputSubtopic(tempInput);
  };

  const validationSubmit = () => {
    let isValid = {
      status: "success",
      message: `Berhasil ${
        type === "add" ? "menambahkah" : "mengubah"
      } subtopic`,
    };

    Object.keys(inputSubtopic).forEach((key) => {
      if (!inputSubtopic[key]) {
        isValid.status = "warning";
        isValid.message = "Mohon mengisi topic/subtopic terlebih dahulu";
      }
    });

    return isValid;
  };

  const handleAddSubtopic = async () => {
    let isValid = validationSubmit();
    if (isValid.status === "success") {
      try {
        if (type === "add") {
          await Axios({
            url: "/admin/subtopic",
            method: "POST",
            data: inputSubtopic,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });
        } else if (type === "edit") {
          await Axios({
            url: `/admin/subtopic/${idSubtopic}`,
            method: "PUT",
            data: inputSubtopic,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });
        }
        fetchData();
        onClose();
        toast({
          title: "Success",
          description: isValid.message,
          status: isValid.status,
          position: "top",
          duration: 1500,
          isClosable: true,
        });
      } catch (err) {
        console.log(err);
        toast({
          title: "Warning",
          description: err.response.data.errors[0].message,
          status: "warning",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Warning",
        description: isValid.message,
        status: isValid.status,
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={"1000px"}>
        <Text className="modal-title">Create New Subtopic</Text>
        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3">
            <Text className="modal-label">Topic</Text>
          </Col>
          <Col md="6">
            <Select
              name="topic"
              value={inputSubtopic.topic}
              onChange={(event) => handleChangeInput(event)}
              disabled={type === "edit"}
            >
              <option value="">All</option>
              <option value="pengenalan">Pengenalan</option>
              <option value="transaksi">Transaksi</option>
              <option value="akun saya">Akun Saya</option>
              <option value="promo">Keuntungan & Promo</option>
              <option value="kebijakan">Akun & Website</option>
            </Select>
          </Col>
        </Row>
        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3">
            <Text className="modal-label">Subtopic</Text>
          </Col>
          <Col md="6">
            <Input
              placeholder="Write Subtopic Name Here ...."
              name="subtopic"
              value={inputSubtopic.subtopic}
              onChange={(event) => handleChangeInput(event)}
            />
          </Col>
        </Row>

        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3" />
          <Col md="6">
            <Button className="modal-save__button" onClick={handleAddSubtopic}>
              Save
            </Button>
          </Col>
        </Row>
      </ModalContent>
    </Modal>
  );
}

export default AddSubtopic;
