import React, { useEffect, useState, useRef } from "react";
import HeaderList from "./components/headerList";
import "./styles/list-commission.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Pagination from "../order/components/pagination";
import navguard from "../auth/navguard";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  Text,
  Checkbox,
  CheckboxGroup,
  Center,
  Divider,
  useToast,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom";
import { Search2Icon } from "@chakra-ui/icons";
import queryString from "query-string";
import {
  fetchCommission,
  fetchTotalCommission,
} from "../../store/actions/commissionAction";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, find, intersection } from "lodash";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { months } from "./components/const";
import CommissionPayoutPdf from "../helpers/invoiceCommissionPayout/invoiceCommissionPayout";
import Axios from "../../services/axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import dateFormat from "dateformat";
import AlertModalConfirm from "./components/modalConfirm";
import UploadTax from "./components/uploadTax";
import ModalInputId from "./components/modalInputId";

function ListCommissionPayout() {
  const [page, setPage] = useState(0);
  const [bulkAction, setBulkAction] = useState("");
  const [sort, setSort] = useState("");
  const history = useHistory();
  const { search } = useLocation();
  const [selectedRow, setSelectedRow] = useState({});
  const [dataCommissionPayout, setDataCommissionPayout] = useState([]);
  const { commission, allCommission } = useSelector((state) => state.commission);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [upperLimit, setUpperLimit] = useState(0);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [dataToExport, setDataToExport] = useState({});
  const [dataReadyToExport, setDataReadyToExport] = useState([]);
  const [dataDetailReadyToExport, setDataDetailReadyToExport] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const today = new Date();
  const toast = useToast();
  const [totalPage, setTotalPage] = useState(0);
  const hsbcCustomerId = "ABC98503001";
  const hsbcNetId = "IDHBAPGID050041334";
  const financeAccount = "financeAccount";
  const [isCommissionNpwpAvail, setIsCommissionNpwpAvail] = useState(false);
  const [isCommissionNonNpwpAvail, setIsCommissionNonNpwpAvail] = useState(false);
  const [tfmId, setTfmId] = useState(null);
  const [activityNpwpId, setActivityNpwpId] = useState(null);
  const [activityNonNpwpId, setActivityNonNpwpId] = useState(null);
  const [idModal, setIdModal] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const cancelRef = useRef();
  const [taxModal, setTaxModal] = useState(false);
  const [taxUploadType, setTaxUploadType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    {
      dataField: "follow",
      formatter: (cell, row) => <Checkbox value={row.id} />,
      headerFormatter: () => (
        <Checkbox onChange={checkAllCheckbox} isChecked={isCheckAll} />
      ),
    },
    {
      dataField: "invoiceNumber",
      text: "Payout ID",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "user",
      text: "Reseller ID",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{cell.referralCode}</p>
      ),
    },
    {
      dataField: "user",
      text: "Account Name",
      style: { textAlign: "center" },
      sort: true,
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{`${row.accountName}`}</p>
      ),
    },
    {
      dataField: "bankName",
      text: "Destination Bank",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "accountNumber",
      text: "Bank Account Number",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "taxPercentage",
      text: "Tax %",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{cell}%</p>
      ),
    },
    {
      dataField: "totalCommission",
      text: "Commission",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => formatter(cell),
    },
    {
      dataField: "tax",
      text: "Tax Amount",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => formatter(cell),
    },
    {
      dataField: "totalTransfer",
      text: "Transfer Amount",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => formatter(cell),
    },
    {
      dataField: "isPaid",
      text: "Status",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{cell ? "Paid" : "Not Paid"}</p>
      ),
    },
    {
      dataField: "actions",
      style: { textAlign: "center" },
      formatter: (cell, row) => (
        <Flex>
          <Text style={{ cursor: "pointer" }} onClick={() => viewUser(row)}>
            View
          </Text>
          <Center height="20px" width="15px">
            <Divider
              orientation="vertical"
              color={"#C4C4C4"}
              opacity={"1"}
              borderLeftWidth={"1.5px"}
            />
          </Center>
          <Text onClick={() => openAlertModal(row.id)} cursor="pointer">
            Pay
          </Text>
          <Center height="20px" width="15px">
            <Divider
              orientation="vertical"
              color={"#C4C4C4"}
              opacity={"1"}
              borderLeftWidth={"1.5px"}
            />
          </Center>
          <Text onClick={() => downloadInvoicePayout(row.id)} cursor="pointer">
            Invoice
          </Text>
          <Center height="20px" width="15px">
            <Divider
              orientation="vertical"
              color={"#C4C4C4"}
              opacity={"1"}
              borderLeftWidth={"1.5px"}
            />
          </Center>
          {isEmpty(row.withholdingTaxPath) ? (
            <Text
              cursor={"pointer"}
              onClick={() => handleOpenWithholdingTax({ type: "detail", id: row })}
            >
              Upload Withholding Tax
            </Text>
          ) : (
            <Text
              cursor={"pointer"}
              onClick={() => handleOpenWithholdingTax({ type: "detail", id: row })}
            >
              See Withholding Tax
            </Text>
          )}
        </Flex>
      ),
    },
  ];
  const {
    isOpen: isInputIdOpen,
    onOpen: openInputId,
    onClose: closeInputId,
  } = useDisclosure();

  const fetchDataDetail = async (id) => {
    try {
      const { data } = await Axios({
        url: `/admin/commissionPayout/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  const fetchSelectedDataDetail = async (datas) => {
    let obj = {};
    for (let i = 0; i < datas.length; i++) {
      const { data } = await fetchDataDetail(
        datas[i].id ? datas[i].id : datas[i]
      );
      if (obj[page]) {
        obj[page].push(data);
      } else {
        obj[page] = [data];
      }
    }
    return obj;
  };

  const onCloseModal = () => setIsOpenModal(false);

  const openAlertModal = (id) => {
    setIdModal(id);
    setIsOpenModal(true);
  };

  const changePageHandler = (value) => {
    setPage(value.selected);
  };

  const changeSortHandler = (event) => {
    setSort(event.target.value);
    const currentQuery = queryString.parse(search);
    const query = queryString.stringify(
      {
        ...currentQuery,
        sort: event.target.value,
      },
      {
        skipEmptyString: true,
      }
    );

    history.push(`/commission/list-payout?${query}`);
  };

  const changeBulkHandler = (event) => {
    setBulkAction(event.target.value);
  };

  const changeEventHandler = async (arr) => {
    const obj = { ...selectedRow };
    let temp = obj[page] || [];
    if (temp.length < arr.length) {
      temp.push(arr[arr.length - 1]);
    } else {
      temp = intersection([...temp], [...arr]);
    }

    if (temp.length > 0) {
      let objDataExport = await fetchSelectedDataDetail(temp);
      setDataToExport(objDataExport);
    } else {
      setDataToExport({});
    }

    obj[page] = temp;
    setSelectedRow(obj);
    setIsCheckAll(temp.length === dataCommissionPayout?.length);
  };

  const checkAllCheckbox = async (e) => {
    const obj = {};
    if (e.target.checked) {
      obj[page] = dataCommissionPayout.map((item) => item.id);
      setIsCheckAll(true);
    } else {
      obj[page] = [];
      setIsCheckAll(false);
    }

    if (obj[page]) {
      let objDataExport = await fetchSelectedDataDetail(obj[page]);
      setDataToExport(objDataExport);
    }
    setSelectedRow({ ...selectedRow, ...obj });
  };

  useEffect(() => {
    if (page !== 0) setPage(0);
    fetchData();
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    let result = objToArrRow(dataToExport);
    setDataReadyToExport(result);
  }, [dataToExport]);

  const handleSearchData = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const currentQuery = queryString.parse(search);
      const queries = queryString.stringify(
        {
          ...currentQuery,
          q: searchQuery,
        },
        {
          skipEmptyString: true,
        }
      );

      history.push(`/commission/list-payout?${queries}`);
    }
  };

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (currentQuery.q) {
      setSearchQuery(currentQuery.q);
    }
    if (currentQuery.sort) {
      setSort(currentQuery.sort);
    }
  }, [search]);

  const fetchData = () => {
    const today = new Date();
    const currentQuery = queryString.parse(search);
    currentQuery.isPaid = false;
    let queries;
    if (!currentQuery.month && !currentQuery.year) {
      queries = queryString.stringify({
        ...currentQuery,
        month: today.getMonth() + 1,
        year: today.getFullYear(),
        isPaid: false,
        accountStatus: "Eligible",
      });
    } else if (!currentQuery.year) {
      queries = queryString.stringify({
        ...currentQuery,
        year: today.getFullYear(),
        isPaid: false,
        accountStatus: "Eligible",
      });
    } else if (!currentQuery.month) {
      queries = queryString.stringify({
        ...currentQuery,
        month: today.getMonth() + 1,
        isPaid: false,
        accountStatus: "Eligible",
      });
    } else {
      queries = queryString.stringify({
        ...currentQuery,
        isPaid: false,
        accountStatus: "Eligible",
      });
    }
    const currentQueryTotal = queryString.parse(queries);
    delete currentQueryTotal.sort;
    
    const allCommissionQuery = queryString.parse(queries);
    delete allCommissionQuery.q;

    const queriesTotal = queryString.stringify(currentQueryTotal);

    dispatch(fetchCommission(queries));
    dispatch(fetchCommission(queries, "all"))
    dispatch(fetchTotalCommission(queriesTotal));
  };

  const formatter = (value) =>
    new Intl.NumberFormat("id-ID", {
      minimumFractionDigits: 0,
      style: "currency",
      currency: "IDR",
    }).format(value);

  const getBatchHeader = (arr) => {
    let usedMonth;
    if (today.getDate() > 5) {
      usedMonth = today.getMonth() + 1;
    } else {
      usedMonth = today.getMonth();
    }

    const nextPaymentDate = new Date(today.getFullYear(), usedMonth, 5);
    const paymentDate = dateFormat(nextPaymentDate, "yyyymmdd");

    let totalTransfer = arr.reduce((prev, curr) => {
      return prev + curr.totalTransfer;
    }, 0);

    return [
      "BATHDR",
      "ACH-CR",
      arr.length,
      null,
      null,
      null,
      null,
      null,
      null,
      "@1st@",
      paymentDate,
      financeAccount,
      "IDR",
      totalTransfer,
      null,
      null,
      "ID",
      "HBAP",
      "IDR",
      null,
      "PT Paranova",
      "Jakarta Selatan",
      null,
      null,
      null,
      "HN1",
      null,
    ];
  };

  const getFileHeader = (arr) => {
    const currentDate = dateFormat(today, "yyyy/mm/dd");
    const currentTime = dateFormat(today, "HH:MM:ss");
    const totalRow = arr.length + 2;

    return [
      "IFH",
      "IFILE",
      "CSV",
      hsbcCustomerId,
      hsbcNetId,
      `BE_COMMISSION_PAYOUT_${dateFormat(new Date(), "mmyyyy")}`,
      currentDate,
      currentTime,
      "P",
      "1.0",
      totalRow,
    ];
  };

  const getCommissionList = (arr) => {
    const currentDate = dateFormat(today, "yyyy/mm/dd");
    const commissionList = [];

    arr.forEach((item, index) => {
      let commissionRow = [
        "SECPTY",
        item.accountNumber,
        item.accountName,
        index + 1,
        item.bankLcc,
        null,
        null,
        item.totalTransfer,
        currentDate,
        null,
        null,
        null,
        null,
        null,
        "N",
        "N",
        null,
        null,
        null,
        null,
        null,
        "@LVP@",
      ];

      const totalNull = 43;

      for (let i = 0; i < totalNull; i += 1) {
        commissionRow.push(null);
      }

      commissionRow.push("/SKN/11");
      commissionList.push(commissionRow);
    });

    return commissionList;
  };

  const getCommissionDetailList = (arr) => {
    return arr.map((item) => item.commissionPayout);
  };

  const populateHSBCData = () => {
    const arr = !isEmpty(dataReadyToExport)
      ? getCommissionDetailList(dataReadyToExport)
      : dataCommissionPayout;

    const fileHeader = getFileHeader(arr);
    const batchHeader = getBatchHeader(arr);
    const commissionList = getCommissionList(arr);

    let selectedData = [];

    selectedData.push(fileHeader);
    selectedData.push(batchHeader);
    selectedData = [...selectedData, ...commissionList];

    return selectedData;
  };

  const exportHSBCFile = () => {
    setIsLoading(true);
    const populated = populateHSBCData();

    const ws = XLSX.utils.json_to_sheet(populated, { skipHeader: 1 });

    const csv = XLSX.utils.sheet_to_csv(ws);
    const CSV_TYPE = "text/csv;charset=utf-8";
    const data = new Blob([csv], { type: CSV_TYPE });

    setIsLoading(false);
    FileSaver.saveAs(data, `${commissionPayoutNameHandler()}.csv`);
  };

  const exportPayoutData = () => {
    setIsLoading(true);
    const populated = populatePayoutData();

    const ws = XLSX.utils.json_to_sheet(populated, { skipHeader: 1 });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: ".xlsx" });

    setIsLoading(false);
    FileSaver.saveAs(data, `${fileNameHandler("data")}.xlsx`)
  }

  const exportTaxData = () => {
    setIsLoading(true);
    const populated = populateData()

    const ws = XLSX.utils.json_to_sheet(populated, { skipHeader: 1 });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: ".xlsx" });

    setIsLoading(false);
    FileSaver.saveAs(data, `${fileNameHandler("tax")}.xlsx`)
  }

  const bulkActionHandler = async (datas, type) => {
    if (!isEmpty(datas)) {
      let pdf = new jsPDF("portrait", "px", "A4", "false");
      for (let i = 0; i < datas.length; i++) {
        let canvas = await html2canvas(
          document.querySelector(
            `${
              type === "Detail"
                ? `#commission-payout-detail-${i}`
                : `#commission-payout-${i}`
            }`
          )
        );
        let img = canvas.toDataURL("image/png");
        if (i !== 0) {
          pdf.addPage("portrait", "px", "A4", "false");
        }
        pdf.addImage(img, "PNG", 0, 0);
      }
      pdf.save(
        datas.length > 1
          ? "Bulk Download Invoice Payout.pdf"
          : `Detail Download Payout ${datas[0]?.commissionPayout?.invoiceNumber}`
      );
    } else {
      toast({
        title: "Warning",
        description: "Mohon check salah satu data",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: false,
      });
    }
  };

  const downloadInvoicePayout = async (id) => {
    const { data } = await fetchDataDetail(id);
    setDataDetailReadyToExport([data]);
    await bulkActionHandler([data], "Detail");
  };

  const handleDataWithNpwp = (arr, type) => {
    let tempResult = [];
    let tempTotal = 0;
    arr
      .filter((user) => (type === "has" ? user.npwp : !user.npwp))
      .forEach((item) => {
        tempTotal += item.tax;
        tempResult.push([
          item.invoiceNumber,
          item.user.npwp,
          "",
          `${item.user.firstName} ${item.user.lastName}`,
          "",
          item.totalCommission,
          item.taxPercentage,
          item.tax,
        ]);
      });

    tempResult.push(["", "", "", "", "", "", "Total", tempTotal]);
    return tempResult;
  };

  const populateData = () => {
    let selectedData = [
      [
        "Nomor Invoice",
        "NPWP",
        "NIK",
        "Nama Lengkap",
        "Alamat KTP",
        "DPP (Commission Amount)",
        "Tarif (2,5% atau 3%)",
        "PPh (Tax Amount)",
      ],
    ];
    if (isEmpty(selectedRow)) {
      const temp = handleDataWithNpwp(commission, "none");
      const tempHasNpwp = handleDataWithNpwp(commission, "has");
      selectedData = [...selectedData, ...tempHasNpwp, ...temp];
    } else {
      const allData = objToArrRow(selectedRow).map((item) => {
        const findData = find(commission, { id: item });
        return findData;
      });

      const temp = handleDataWithNpwp(allData, "none");
      const tempHasNpwp = handleDataWithNpwp(allData, "has");

      selectedData = [...selectedData, ...tempHasNpwp, ...temp];
    }
    return selectedData;
  };

  const populatePayoutData = () => {
    let selectedData = [
      [
        "Payout ID",
        "Reseller ID",
        "Account Name",
        "Destination Bank",
        "Bank Account Number",
        "NPWP",
        "Tax Percentage",
        "NIK",
        "KTP Name",
        "KTP Address",
        "Commission",
        "PPh (Tax Amount)",
        "Transfer Amount",
        "Status"
      ],
    ];
    let usedData;
    if (isEmpty(selectedRow)) {
      usedData = [...commission];
    } else {
      const allData = objToArrRow(selectedRow).map((item) => {
        const findData = find(commission, { id: item });
        return findData;
      });
      usedData = [...allData];
    }
    usedData.forEach((item) => {
      selectedData.push([
        item.invoiceNumber,
        item.user.referralCode,
        item.accountName,
        item.bankName,
        item.accountNumber?.toString(),
        item.npwp?.toString(),
        item.taxPercentage,
        item.nik?.toString(),
        item.ktpName,
        item.nikAddress,
        item.totalCommission,
        item.tax,
        item.totalTransfer,
        item.isPaid ? "Paid" : "Not Paid",
      ])
    })
    return selectedData;
  }

  const payCommissionDetail = async (id) => {
    try {
      await Axios({
        url: `/admin/commissionPayout/${id}/pay`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      toast({
        title: "Success.",
        description: "Berhasil membayar komisi.",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Warning",
        description: "Tidak berhasil melakukan pembayaran",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  const commissionPayoutNameHandler = () => {
    const date = new Date();
    const currentQuery = queryString.parse(search);
    const selectedMonth = currentQuery.month - 1 || date.getMonth();
    const selectedYear = currentQuery.year || date.getFullYear();
    const today = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    return `Commission Payout ${months[selectedMonth]} ${selectedYear} - ${today}`;
  };

  const fileNameHandler = (type) => {
    const date = new Date();
    const currentQuery = queryString.parse(search);
    const selectedMonth = currentQuery.month - 1 || date.getMonth();
    const selectedYear = currentQuery.year || date.getFullYear();
    const today = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    if (type === "tax") {
      return `Tax Report ${months[selectedMonth]} ${selectedYear} - ${today}`;
    } else {
      return `Payout Data ${months[selectedMonth]} ${selectedYear} - ${today}`;
    }
  };

  const viewUser = (user) => {
    history.push(`/commission/detail-commission-payout/${user.id}`);
  };

  const objToArrRow = (data) => {
    let result = [];
    for (const key in data) {
      result = [...result, ...data[key]];
    }
    return result;
  };

  const handleOpenWithholdingTax = ({ type, id }) => {
    setTaxModal(true);
    if (type === "detail") {
      setTaxUploadType({ type, id });
    } else {
      setTaxUploadType({ type, id: "" });
    }
  };

  const handleCloseWithholdingTax = () => {
    setTaxModal(false);
    setTaxUploadType("");
  };

  const handleClickProcessPayout = () => {
    const allCondition = isCommissionNonNpwpAvail && isCommissionNpwpAvail && (
      isEmpty(tfmId) ||
      isEmpty(activityNpwpId) ||
      isEmpty(activityNonNpwpId)
    );
    const npwpOnly = isCommissionNpwpAvail && (
      isEmpty(tfmId) ||
      isEmpty(activityNpwpId)
    );
    const nonNpwpOnly = isCommissionNonNpwpAvail && (
      isEmpty(tfmId) ||
      isEmpty(activityNonNpwpId)
    );
    
    if (allCondition || npwpOnly || nonNpwpOnly) {
      openInputId();
    } else {
      alert('Process HSBC')
    }
  }

  const getTfmActivityId = () => {
    const currentQuery = queryString.parse(search)
    if (isEmpty(currentQuery.q)) {
      let idx = 0;
      let tempTfmId = null;
      let tempActivityNpwpId = null;
      let tempActivityNonNpwpId = null;
      let isFoundCommissionNpwp = false;
      let isFoundCommissionNonNpwp = false;
      while (
        idx < allCommission.length &&
        (
          isEmpty(tempTfmId) ||
          isEmpty(tempActivityNpwpId) ||
          isEmpty(tempActivityNonNpwpId) ||
          !isFoundCommissionNonNpwp ||
          !isFoundCommissionNpwp
        )
      ) {
        if (!isEmpty(allCommission[idx].tfmId)) {
          tempTfmId = allCommission[idx].tfmId;
        }

        if (!isEmpty(allCommission[idx].activityId) && isEmpty(allCommission[idx].npwp)) {
          tempActivityNonNpwpId = allCommission[idx].activityId;
        }

        if (!isEmpty(allCommission[idx].activityId) && !isEmpty(allCommission[idx].npwp)) {
          tempActivityNpwpId = allCommission[idx].activityId;
        }

        if (!isEmpty(allCommission[idx].npwp) && !isFoundCommissionNpwp) {
          isFoundCommissionNpwp = true;
        }

        if (isEmpty(allCommission[idx].npwp) && !isFoundCommissionNonNpwp) {
          isFoundCommissionNonNpwp = true;
        }
        idx += 1;
      }
      
      setTfmId(tempTfmId);
      setActivityNonNpwpId(tempActivityNonNpwpId);
      setActivityNpwpId(tempActivityNpwpId);
      setIsCommissionNpwpAvail(isFoundCommissionNpwp);
      setIsCommissionNonNpwpAvail(isFoundCommissionNonNpwp);
    }
  }

  const validationId = (usedTfmId, usedActivityNpwpId, usedActivityNonNpwpId) => {
    if (isEmpty(usedTfmId)) {
      return {
        status: "error",
        message: "Mohon isi TFM ID"
      }
    } else if (isEmpty(usedActivityNpwpId) && isCommissionNpwpAvail) {
      return {
        status: "error",
        message: "Mohon isi Activity NPWP ID"
      }
    } else if (isEmpty(usedActivityNonNpwpId) && isCommissionNonNpwpAvail) {
      return {
        status: "error",
        message: "Mohon isi Activity Non NPWP ID"
      }
    } else {
      return {
        status: "success"
      }
    }
  }

  const processPayout = async(tfmId, activityIdNpwp, activityIdNonNpwp) => {
    const isValid = validationId(tfmId, activityIdNpwp, activityIdNonNpwp);
    const currentQuery = queryString.parse(search);
    if (isValid.status === "success") {
      try {
        const { data } = await Axios({
          url: `/admin/commissionPayout/payAll`,
          method: "PATCH",
          data: {
            tfmId,
            activityIdNpwp,
            activityIdNonNpwp,
            period: `${currentQuery.month}-${currentQuery.year}`
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`
          }
        })

        toast({
          title: "Success",
          description: "Komisi berhasil diproses",
          status: "success",
          position: "top",
          duration: 1500,
          isClosable: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      } catch (e) {
        toast({
          title: "Warning",
          description: "Terjadi kesalahan. Mohon coba lagi",
          status: "error",
          position: "top",
          duration: 1500,
          isClosable: false,
        });
      }
    } else {
      toast({
        title: "Warning",
        description: isValid.message,
        status: isValid.status,
        position: "top",
        duration: 1500,
        isClosable: false,
      });
    }
  }

  useEffect(() => {
    if (commission) {
      setDataCommissionPayout(commission);
      setTotalPage(Math.ceil(commission.length / 5));
    }
  }, [commission]);

  useEffect(() => {
    if (allCommission) {
      getTfmActivityId()
    }
  }, [allCommission])

  useEffect(() => {
    setLowerLimit(page * 5);
    setUpperLimit((page + 1) * 5);
  }, [page]);

  useEffect(() => {
    setDataCommissionPayout(commission.slice(lowerLimit, upperLimit));
  }, [upperLimit, lowerLimit, commission]);

  return (
    <Box className="list-commission__container">
      {isLoading && (
        <div
          style={{
            position: "fixed",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 12,
          }}
        >
          <div
            style={{
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0,0,0,0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner color="white" size="lg" />
          </div>
        </div>
      )}
      <HeaderList isPayoutPage={true} />

      <Flex justify="end" mt="1em">
        <Button
          colorScheme="teal"
          size="md"
          onClick={() => handleOpenWithholdingTax({ type: "bulk" })}
        >
          Upload Withholding Tax
        </Button>
      </Flex>
      <Box className="list-commission__content-container">
        <Flex>
          <Flex align="center">
            <Text mr="16px" color="black" fontSize="16px">
              Sort By
            </Text>
            <Select
              width="120px"
              value={sort}
              onChange={(event) => changeSortHandler(event)}
            >
              <option value="userId,desc">A-Z</option>
              <option value="userId,asc">Z-A</option>
              <option value="totalTransfer,asc">Terkecil</option>
              <option value="totalTransfer,desc">Terbesar</option>
            </Select>
          </Flex>
          <Spacer />

          <Flex align={"center"}>
            <Select
              width={"200px"}
              mr={"1rem"}
              onChange={(event) => changeBulkHandler(event)}
              value={bulkAction}
            >
              <option value="">Bulk action</option>
              <option value="data">
                Download {isEmpty(selectedRow) ? "All" : ""} Payout Data
              </option>
              <option value="pay">
                Download {isEmpty(selectedRow) ? "All" : ""} ACH File
              </option>
              <option value="inv">
                Download {isEmpty(selectedRow) ? "All" : ""} Invoice
              </option>
              <option value="tax">
                Download {isEmpty(selectedRow) ? "All" : ""} Tax Report
              </option>
            </Select>

            {bulkAction === "pay" ? (
              <Button
                colorScheme="teal"
                size="md"
                ml="4px"
                onClick={exportHSBCFile}
              >
                Download Payout
              </Button>
            ) : bulkAction === "inv" ? (
              <Button
                colorScheme="teal"
                size="md"
                ml="4px"
                onClick={() => bulkActionHandler(dataReadyToExport)}
              >
                Download Invoice
              </Button>
            ) : bulkAction === "tax" ? (
                <Button
                  colorScheme="teal"
                  size="md"
                  ml="4px"
                  onClick={exportTaxData}
                >
                  Download {isEmpty(selectedRow) ? "All" : ""} Tax
                </Button>
            ) : bulkAction === "data" ? (
                <Button
                  colorScheme="teal"
                  size="md"
                  ml="4px"
                  onClick={exportPayoutData}
                >
                  Download {isEmpty(selectedRow) ? "All" : ""} Data
                </Button>
            ) : (
              <Button colorScheme="teal" size="md" ml="4px">
                Apply
              </Button>
            )}
          </Flex>
        </Flex>

        <InputGroup mt={"1rem"}>
          <InputLeftElement
            pointerEvents="none"
            children={<Search2Icon color={"gray.300"} />}
          />

          <Input
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={(e) => handleSearchData(e)}
          />
        </InputGroup>

        {!isEmpty(dataCommissionPayout) ? (
          <ToolkitProvider
            data={dataCommissionPayout}
            keyField="partner_name"
            columns={columns}
          >
            {(props) => (
              <div className="py-4 table-responsive list-commission__table-container">
                <CheckboxGroup
                  colorScheme="green"
                  defaultValue={selectedRow[page] ? selectedRow[page] : []}
                  onChange={changeEventHandler}
                  value={selectedRow[page] ? selectedRow[page] : []}
                >
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                    trStyle={{ backgroundColor: "white" }}
                  />
                </CheckboxGroup>
              </div>
            )}
          </ToolkitProvider>
        ) : (
          <Text className="empty-state">There is no data entry</Text>
        )}
        <Flex justify={"space-between"} height={"2.5rem"} marginTop="25px">
          <Spacer w={"33%"} />
          <Box w={"33%"}>
            <Button colorScheme="teal" size="md" onClick={handleClickProcessPayout}>
              Process Payout
            </Button>
          </Box>
          {!isEmpty(dataCommissionPayout) && (
            <Box w={"33%"} display={"flex"} justifyContent={"flex-end"}>
              <Pagination
                page={page}
                totalPage={totalPage}
                changePageHandler={changePageHandler}
              />
            </Box>
          )}
        </Flex>
        <AlertModalConfirm
          isOpen={isOpenModal}
          cancelRef={cancelRef}
          onClose={onCloseModal}
          title={"Pay Commission"}
          subtitle={"Apakah kamu yakin ingin melanjutkan pembayaran komisi?"}
          alertHandler={() => payCommissionDetail()}
        />
      </Box>

      {dataReadyToExport.map((value, idx) => (
        <div
          id={`commission-payout-${idx}`}
          style={{
            backgroundColor: "#FFFFFF",
            width: "21cm",
            minHeight: "297mm",
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: "-1",
            position: "absolute",
            left: "-1000px",
            top: 0,
          }}
          key={idx}
        >
          <CommissionPayoutPdf data={value} />
        </div>
      ))}

      {dataDetailReadyToExport.map((value, idx) => (
        <div
          id={`commission-payout-detail-${idx}`}
          style={{
            backgroundColor: "#FFFFFF",
            width: "21cm",
            minHeight: "297mm",
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: "-1",
            position: "absolute",
            left: "-1000px",
            top: 0,
          }}
          key={idx}
        >
          <CommissionPayoutPdf data={value} />
        </div>
      ))}

      <UploadTax
        isOpen={taxModal}
        onClose={handleCloseWithholdingTax}
        uploadType={taxUploadType}
      />
      <ModalInputId
        isOpen={isInputIdOpen}
        onClose={closeInputId}
        tfmId={tfmId}
        activityNpwpId={activityNpwpId}
        activityNonNpwpId={activityNonNpwpId}
        isCommissionNpwpAvail={isCommissionNpwpAvail}
        isCommissionNonNpwpAvail={isCommissionNonNpwpAvail}
        processPayout={processPayout}
      />
    </Box>
  );
}

export default navguard(ListCommissionPayout);
