import { CloseIcon } from "@chakra-ui/icons";
import {
  Flex,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Box,
  IconButton,
  Button,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ModalBody } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import { isEmpty, cloneDeep } from "lodash";
import one from "../../../assets/images/pro3/1.jpg";
import Axios from "../../../services/axios";
import { useDispatch } from "react-redux";
import { getUsersAction } from "../../../store/actions/userAction";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import Loader from "../../loader/Loader";

function VerifyUserModal({
  dataDetail,
  isOpen,
  onClose,
  page,
  searchQuery,
  limit,
}) {
  const { search } = useLocation();
  const currentQuery = queryString.parse(search);
  const [inputData, setInputData] = useState({
    nik: "",
    nikAddress: "",
    npwp: "",
    id: "",
    ktpName: "",
    imgNik: "",
    imgNpwp: "",
  });
  const dispatch = useDispatch();
  const toast = useToast();
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getImgKtp = async (id) => {
    try {
      const { data } = await Axios({
        url: `/admin/user/${id}/ktp`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      const dataUrl = window.URL.createObjectURL(data);
      return dataUrl;
    } catch (err) {
      console.error(err);
    }
  };

  const getImgNpwp = async (id) => {
    try {
      const { data } = await Axios({
        url: `/admin/user/${id}/npwp`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      const dataUrl = window.URL.createObjectURL(data);
      return dataUrl;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const getDataDetail = async () => {
      setIsLoading(true)
      let tempResult = { ...inputData };

      if (!isEmpty(dataDetail) && isOpen) {
        Object.keys(inputData).forEach((key) => {
          if (dataDetail[key]) {
            tempResult[key] = dataDetail[key];
          }
        });
        if (dataDetail.npwp && dataDetail.nik) {
          await Promise.all([
            getImgKtp(dataDetail.id),
            getImgNpwp(dataDetail.id),
          ]).then((res) => {
            tempResult.imgNik = res[0];
            tempResult.imgNpwp = res[1];
          });
        }
        setIsVerified(dataDetail.personalVerificationStatus === "verified");
      } else {
        Object.keys(inputData).forEach((key) => {
          tempResult[key] = "";
        });
        setIsVerified(false);
      }
      setInputData(tempResult);
      setIsLoading(false);
    };
    getDataDetail();
  }, [dataDetail, isOpen]);

  const handleSubmit = async ({ type }) => {
    try {
      await Axios({
        url: `/admin/user/${inputData.id}/personalVerification`,
        data: {
          personalVerificationStatus: type === "approve",
        },
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      toast({
        title: "Success.",
        description: "Berhasil mengubah status.",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });

      if (!currentQuery.sort) {
        currentQuery.sort = "createdAt,desc";
      }

      const queries = queryString.stringify(
        {
          ...currentQuery,
          q: searchQuery,
          limit: limit,
          page: page + 1,
        },
        {
          skipEmptyString: true,
        }
      );
      await getUsersAction(dispatch, queries);

      onClose();
    } catch (err) {
      console.error(err);
      toast({
        title: "Warning.",
        description: "Mohon coba beberapa saat lagi",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Modal isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent maxWidth={"845px"}>
          <ModalHeader p="1rem">
            <Flex align="center">
              <Text fontSize="22px" fontWeight={"600"}>
                Verify Reseller Data
              </Text>
              <Spacer />
              <IconButton
                icon={<CloseIcon fontSize="14px" />}
                variant="ghost"
                onClick={onClose}
              />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="6">
                <img
                  src={inputData.imgNik || one}
                  style={{
                    height: "fit-content",
                    width: "fit-content",
                    objectFit: "cover",
                  }}
                />

                <Box margin="16px 0">
                  <Text as="label" htmlFor="nik">
                    ID Number (KTP)
                  </Text>
                  <Input id="nik" defaultValue={inputData.nik} readOnly />
                </Box>

                <Box margin="16px 0">
                  <Text as="label" htmlFor="nik">
                    Name (KTP)
                  </Text>
                  <Input id="nik" defaultValue={inputData?.ktpName} readOnly />
                </Box>
                <Box>
                  <Text as="label" htmlFor="nikAddress">
                    Address (KTP)
                  </Text>
                  <Input
                    id="nikAddress"
                    defaultValue={inputData.nikAddress}
                    readOnly
                  />
                </Box>
              </Col>
              <Col md="6">
                <img
                  src={inputData.imgNpwp || one}
                  style={{
                    height: "fit-content",
                    width: "fit-content",
                    objectFit: "cover",
                  }}
                />

                <Box margin="16px 0">
                  <Text as="label" htmlFor="npwp">
                    NPWP Number
                  </Text>
                  <Input id="npwp" defaultValue={inputData.npwp} readOnly />
                </Box>
              </Col>
            </Row>

            <Flex margin="16px 0" justifyContent="center">
              {!isEmpty(dataDetail) && isVerified ? (
                <Flex direction="column" alignItems="center">
                  <Text fontSize="20px" color="black">
                    Approved
                  </Text>
                  <Button onClick={() => setIsVerified(false)}>Change</Button>
                </Flex>
              ) : (
                <>
                  <Button
                    mr="8px"
                    width="250px"
                    colorScheme="teal"
                    onClick={() => handleSubmit({ type: "approve" })}
                  >
                    Approve
                  </Button>

                  <Button
                    ml="8px"
                    width="250px"
                    colorScheme="red"
                    onClick={() => handleSubmit({ type: "reject" })}
                  >
                    Reject
                  </Button>
                </>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default VerifyUserModal;
