import "../styles/Categories.scss";

import { Card, CardBody, Col, Input, Row } from "reactstrap";
import {
  Spacer,
  Flex,
  Select,
  Button,
  IconButton,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";

import iconDefault from "../../../assets/images/icon_default.png";
import imageDefault from "../../../assets/images/image_default.png";

const AddCategory = ({
  title,
  isEdit,
  categoryObj,
  listCategory,
  changeHandler,
  submitCategory,
  _handleImgChange,
  deleteImage,
  listGroupCategory,
}) => {
  return (
    <>
      <Card>
        <CardBody>
          <p className="card-title">
            {title ? title : `Add New ${categoryObj.type}`}
          </p>
          <Row>
            <Col xl={12} className="mb-8">
              <p className="card-label">Type</p>
              {isEdit ? (
                <p className="card-value">{categoryObj.type}</p>
              ) : (
                <Select
                  name="type"
                  flex="3"
                  value={categoryObj.type}
                  onChange={(e) => changeHandler(e)}
                >
                  <option value="Group Category">Group Category</option>
                  <option value="Category">Category</option>
                  <option value="Sub Category">Sub Category</option>
                </Select>
              )}
            </Col>
            {categoryObj.type === "Sub Category" && (
              <>
                <Col xl={12} className="mb-8">
                  <p className="card-label">Parent Category</p>
                  <Select
                    name="parentCategory"
                    flex="3"
                    value={
                      categoryObj.parentCategory
                        ? categoryObj.parentCategory
                        : ""
                    }
                    onChange={(e) => changeHandler(e)}
                  >
                    <option value=""></option>
                    {listCategory?.map((category) => (
                      <option key={category.name} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </Select>
                </Col>
                <Col xl={12} className="mb-8">
                  <p className="card-label">Sub Category Name</p>
                  <Input
                    placeholder="Sub Category Name"
                    name="subCategoryName"
                    value={
                      categoryObj.subCategoryName
                        ? categoryObj.subCategoryName
                        : ""
                    }
                    onChange={(e) => changeHandler(e)}
                  />
                </Col>
              </>
            )}
            {categoryObj.type === "Category" && (
              <>
                <Col xl={12} className="mb-8">
                  <p className="card-label">Parent Group Category</p>
                  <Select
                    name="parentGroupCategory"
                    flex="3"
                    value={
                      categoryObj.parentGroupCategory
                        ? categoryObj.parentGroupCategory
                        : ""
                    }
                    onChange={(e) => changeHandler(e)}
                  >
                    <option value=""></option>
                    {listGroupCategory?.map((groupCategory) => (
                      <option key={groupCategory.id} value={groupCategory.id}>
                        {groupCategory.name}
                      </option>
                    ))}
                  </Select>
                </Col>
                <Col xl={12} className="mb-8">
                  <Text className="card-label">Category Name</Text>
                  <Input
                    placeholder="Category Name"
                    name="categoryName"
                    value={
                      categoryObj.categoryName ? categoryObj.categoryName : ""
                    }
                    onChange={(e) => changeHandler(e)}
                  />
                  <Text className="card-label" mt="2">
                    Description
                  </Text>
                  <Textarea
                    value={categoryObj.description}
                    onChange={(e) => changeHandler(e)}
                    name="description"
                  />
                </Col>
              </>
            )}
            {categoryObj.type === "Group Category" && (
              <Col xl={12} className="mb-8">
                <p className="card-label">Group Category Name</p>
                <Input
                  placeholder="Group Category Name"
                  name="groupCategoryName"
                  value={
                    categoryObj.groupCategoryName
                      ? categoryObj.groupCategoryName
                      : ""
                  }
                  onChange={(e) => changeHandler(e)}
                />
                <Text className="card-label" mt="2">
                  Description
                </Text>
                <Textarea
                  value={categoryObj.description}
                  onChange={(e) => changeHandler(e)}
                  name="description"
                />
              </Col>
            )}
            {categoryObj.type === "Sub Category" && (
              <Col xl={12} className="mb-8">
                <p className="card-label">Sub Category Description</p>
                <Textarea
                  borderColor="#CBD5E0"
                  placeholder="Subcategory description"
                  name="description"
                  value={categoryObj.description ? categoryObj.description : ""}
                  onChange={(e) => changeHandler(e)}
                />
              </Col>
            )}
            <Col xl={3} className="mb-8">
              <p className="card-label">Icon</p>
              <div className="add-category">
                <Row className="file-upload-product row">
                  <Col xl="12" className="col">
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      className="box-input-file-full"
                    >
                      {categoryObj.image &&
                        categoryObj.image !== iconDefault && (
                          <IconButton
                            variant="outline"
                            aria-label="Call Sage"
                            onClick={() => deleteImage("saveImage", "image")}
                            icon={<MdClose />}
                            style={{
                              position: "absolute",
                              right: "4px",
                              top: "4px",
                              borderRadius: "50%",
                              fontSize: "24px",
                              width: "24px",
                              height: "24px",
                              color: "red",
                              border: "none",
                              minWidth: "unset",
                              zIndex: "100",
                            }}
                          />
                        )}
                      <input
                        className="upload"
                        type="file"
                        id="icon-img"
                        name="img"
                        accept="image/*"
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "100%",
                        }}
                        onChange={(e) =>
                          _handleImgChange(e, "savedImage", "image")
                        }
                      />
                      <img
                        alt=""
                        src={
                          categoryObj.image ? categoryObj.image : iconDefault
                        }
                        style={{
                          width: "100%",
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            {categoryObj.type !== "Sub Category" && (
              <Col xl={3} className="mb-8">
                <p className="card-label">Icon Active</p>
                <div className="add-category">
                  <Row className="file-upload-product row">
                    <Col xl="12" className="col">
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        className="box-input-file-full"
                      >
                        {categoryObj.iconActive &&
                          categoryObj.iconActive !== iconDefault && (
                            <IconButton
                              variant="outline"
                              aria-label="Call Sage"
                              onClick={() =>
                                deleteImage("savedActiveImage", "iconActive")
                              }
                              icon={<MdClose />}
                              style={{
                                position: "absolute",
                                right: "4px",
                                top: "4px",
                                borderRadius: "50%",
                                fontSize: "24px",
                                width: "24px",
                                height: "24px",
                                color: "red",
                                border: "none",
                                minWidth: "unset",
                                zIndex: "100",
                              }}
                            />
                          )}
                        <input
                          className="upload"
                          type="file"
                          id="icon-img"
                          name="img"
                          accept="image/*"
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            height: "100%",
                          }}
                          onChange={(e) =>
                            _handleImgChange(
                              e,
                              "savedActiveImage",
                              "iconActive"
                            )
                          }
                        />
                        <img
                          alt=""
                          src={
                            categoryObj.iconActive
                              ? categoryObj.iconActive
                              : iconDefault
                          }
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
            <Col xl={6}>
              <p className="card-label">Banner</p>
              <div className="add-category">
                <Row className="file-upload-product row">
                  <Col xl="12" className="col">
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      className="box-input-file-full"
                    >
                      {categoryObj.banner &&
                        categoryObj.banner !== imageDefault && (
                          <IconButton
                            variant="outline"
                            aria-label="Call Sage"
                            onClick={() => deleteImage("savedBanner", "banner")}
                            icon={<MdClose />}
                            style={{
                              position: "absolute",
                              right: "4px",
                              top: "4px",
                              borderRadius: "50%",
                              fontSize: "24px",
                              width: "24px",
                              height: "24px",
                              color: "red",
                              border: "none",
                              minWidth: "unset",
                              zIndex: "100",
                            }}
                          />
                        )}
                      <input
                        className="upload"
                        type="file"
                        id="banner-img"
                        name="img"
                        accept="image/*"
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "100%",
                        }}
                        onChange={(e) =>
                          _handleImgChange(e, "savedBanner", "banner")
                        }
                      />
                      <img
                        alt=""
                        src={
                          categoryObj.banner ? categoryObj.banner : imageDefault
                        }
                        style={{
                          width: "100%",
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xl={12}>
              <Flex>
                <Spacer />
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  backgroundColor={"#FF8084"}
                  color={"white"}
                  mt={4}
                  onClick={() => submitCategory(isEdit)}
                >
                  Submit
                </Button>
              </Flex>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default AddCategory;
