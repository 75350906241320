import {
  Box,
  Text,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Button,
  Checkbox,
  CheckboxGroup,
  useToast,
  Divider,
  Spinner,
  Spacer,
} from "@chakra-ui/react";
import StickerMultiplePDF from "../helpers/stickerMultipleGenerator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { RepeatIcon, Search2Icon } from "@chakra-ui/icons";
import SectionTab from "./components/sectionTab";
import FilterByDate from "./components/filterByDate";
import FilterSort from "./components/filterSort";
import "./styles/list-order.css";
import { useState, useEffect } from "react";
import Pagination from "./components/pagination";
import { useDispatch } from "react-redux";
import dateFormat from "dateformat";
import {
  allOrderListACtion,
  confirmReturnAction,
  orderListACtion,
  paymentProcessAction,
  reRequestPickupAction,
  toShipProcessAction,
} from "../../store/actions/order-list";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import MiniTab from "./components/miniTab";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { find, intersection } from "lodash";
import FilterByDc from "./components/filterByDc";
import navguard from "../auth/navguard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FilterByShippingMethod from "./components/filterByShippingMethod";
import InvoiceOrderPdf from "../helpers/invoiceOrderPdf/index.js";
import PackingSlipPdf from "../helpers/packingSlipPdf";
import Axios from "../../services/axios";

const ListOrder = () => {
  const toast = useToast();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchData, setSearchData] = useState("");
  const currentQuery = queryString.parse(search);
  const [page, setPage] = useState(
    !isEmpty(currentQuery.page) ? Number(currentQuery.page) : 0
  );
  const [tabActive, setTabActive] = useState(
    !isEmpty(currentQuery.tab_active) ? currentQuery.tab_active : "all"
  );
  const [subTabActive, setSubTabActive] = useState(
    !isEmpty(currentQuery.sub_tab) ? currentQuery.sub_tab : "All"
  );
  const [successUpdate, setSuccessUpdate] = useState(null);
  const orders = useSelector(({ orders }) => orders.orders);
  const orderToBeProcessed = useSelector(({ orders }) => orders.allorders);

  const [allData, setAllData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [listValue, setListValue] = useState("");
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllLoading, setIsAllLoading] = useState(false);
  const [dataInvoiceExport, setDataInvoiceExport] = useState([]);
  const [dataPackingSlipExport, setDataPackingSlipExport] = useState([]);

  useEffect(() => {
    if (successUpdate) {
      if (successUpdate.condition) {
        toast({
          title: "Success.",
          description: successUpdate.message,
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Failed.",
          description: successUpdate.message,
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [successUpdate]);

  const formatter = (value) =>
    new Intl.NumberFormat("id-ID", {
      minimumFractionDigits: 0,
      style: "currency",
      currency: "IDR",
    }).format(value);

  const fetchData = async () => {
    const isEarlyBird = currentQuery.tab_active === "Early Bird";

    if (isEarlyBird) {
      delete currentQuery.sort;
    }

    if (currentQuery.tab_active) {
      delete currentQuery.tab_active;
    }

    if (currentQuery.sub_tab) {
      delete currentQuery.sub_tab;
    }

    if (
      localStorage.getItem("workingArea") === "Distribution Center" &&
      localStorage.getItem("warehouseId")
    ) {
      currentQuery.warehouseId = localStorage.getItem("warehouseId");
    }

    let queries = "";
    currentQuery.page = parseInt(currentQuery.page) + 1;
    if (!currentQuery.sort && !isEarlyBird) {
      queries = queryString.stringify({
        ...currentQuery,
        sort: "orderDate,desc",
        limit: 10,
      });
    } else {
      queries = queryString.stringify({
        ...currentQuery,
        limit: 10,
      });
    }

    setIsLoading(true);
    try {
      await dispatch(orderListACtion(queries, isEarlyBird));
    } catch (err) {
      console.error(err.response);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllData = async () => {
    setIsAllLoading(true);
    const currentQuery = queryString.parse(search);

    if (
      localStorage.getItem("workingArea") === "Distribution Center" &&
      localStorage.getItem("warehouseId")
    ) {
      currentQuery.warehouseId = localStorage.getItem("warehouseId");
    }

    if (currentQuery.tab_active || currentQuery.sub_tab) {
      delete currentQuery.tab_active;
      delete currentQuery.sub_tab;
    }
    if (!currentQuery.sort) {
      const queries = queryString.stringify({
        ...currentQuery,
        sort: "orderDate,desc",
      });
      await dispatch(allOrderListACtion(queries));
    } else {
      const queries = queryString.stringify({
        ...currentQuery,
      });
      await dispatch(allOrderListACtion(queries));
    }
    setIsAllLoading(false);
  };

  const processAllData = async () => {
    let tempRow = [];
    orderToBeProcessed.orders.forEach((item) => {
      tempRow = [...tempRow, item.invoiceNumber];
    });

    let isRedirect = false;
    switch (listValue) {
      case "payment":
        for (let index = 0; index < tempRow.length; index++) {
          await dispatch(
            paymentProcessAction({
              invoiceNumber: tempRow[index],
              setSuccessUpdate,
            })
          ).then((res) => {
            if (res && !isRedirect) {
              isRedirect = true;
            }

            if (index === arr.length - 1 && isRedirect) {
              setTabActive("To Ship");
            }
          });
        }
        break;
      case "to ship":
        for (let index = 0; index < tempRow.length; index++) {
          await dispatch(
            toShipProcessAction({
              invoiceNumber: tempRow[index],
              setSuccessUpdate,
            })
          ).then((res) => {
            if (res && !isRedirect) {
              isRedirect = true;
            }

            if (index === arr.length - 1 && isRedirect) {
              setSubTabActive("Processed");
            }
          });
        }
        break;
      case "return":
        for (let index = 0; index < tempRow.length; index++) {
          await dispatch(
            confirmReturnAction({
              invoiceNumber: tempRow[index],
              setSuccessUpdate,
            })
          );
        }
        break;
      case "cancelled by courier":
        for (let index = 0; index < tempRow.length; index++) {
          await dispatch(
            reRequestPickupAction({
              invoiceNumber: tempRow[index],
              setSuccessUpdate,
            })
          );
        }
        break;
      default:
        break;
    }

    await fetchData();
    setSelectedRow({});
  };

  useEffect(() => {
    fetchData();
  }, [search]);

  useEffect(() => {
    if (!isEmpty(orderToBeProcessed)) {
      processAllData();
    }
  }, [orderToBeProcessed]);

  const processHandleEmptySelected = async (value) => {
    setListValue(value);
    fetchAllData();
  };

  const goToDetailHandler = (invoiceNumber) => {
    if (currentQuery.tab_active !== "Early Bird") {
      history.push(`/order-detail/${invoiceNumber}`);
    }
  };

  const changePageHandler = (value) => {
    setPage(value.selected);
  };

  useEffect(() => {
    const temp =
      selectedRow[page]?.length === orders?.orders?.length &&
      orders?.orders?.length !== 0;
    setIsCheckAll(temp);
  }, [page, orders]);

  useEffect(() => {
    setSelectedRow({});
  }, [tabActive, subTabActive]);

  const changeEventHandler = (arr) => {
    const obj = { ...selectedRow };
    let temp = obj[page] || [];
    if (temp.length < arr.length) {
      temp.push(arr[arr.length - 1]);
    } else {
      temp = intersection([...temp], [...arr]);
    }
    obj[page] = temp;
    setSelectedRow(obj);
    setIsCheckAll(temp.length === orders?.orders?.length);
  };

  const objToArrSelectedRow = () => {
    let result = [];
    for (const key in selectedRow) {
      result = [...result, ...selectedRow[key]];
    }
    return result;
  };

  const processHandler = async (value) => {
    const arr = objToArrSelectedRow();
    let isRedirect = false;
    switch (value) {
      case "payment":
        for (let index = 0; index < arr.length; index++) {
          await dispatch(
            paymentProcessAction({
              invoiceNumber: arr[index],
              setSuccessUpdate,
            })
          ).then((res) => {
            if (res && !isRedirect) {
              isRedirect = true;
            }

            if (index === arr.length - 1 && isRedirect) {
              setTabActive("To Ship");
            }
          });
        }
        break;
      case "to ship":
        for (let index = 0; index < arr.length; index++) {
          await dispatch(
            toShipProcessAction({ invoiceNumber: arr[index], setSuccessUpdate })
          ).then((res) => {
            if (res && !isRedirect) {
              isRedirect = true;
            }

            if (index === arr.length - 1 && isRedirect) {
              setSubTabActive("Processed");
            }
          });
        }
        break;
      case "return":
        for (let index = 0; index < arr.length; index++) {
          await dispatch(
            confirmReturnAction({
              invoiceNumber: arr[index],
              setSuccessUpdate,
            })
          );
        }
        break;
      case "cancelled by courier":
        for (let index = 0; index < arr.length; index++) {
          await dispatch(
            reRequestPickupAction({
              invoiceNumber: arr[index],
              setSuccessUpdate,
            })
          );
        }
        break;
      default:
        break;
    }

    await fetchData();
    setSelectedRow({});
  };

  const checkAllCheckbox = (e) => {
    const obj = {};
    if (e.target.checked) {
      const temp = orders.orders.map((item) => item.invoiceNumber);
      obj[page] = temp;
      setSelectedRow({ ...selectedRow, ...obj });
      setIsCheckAll(true);
    } else {
      obj[page] = [];
      setSelectedRow({ ...selectedRow, ...obj });
      setIsCheckAll(false);
    }
  };

  const columns = [
    {
      dataField: "follow",
      formatter: (cell, row) => <Checkbox value={row.invoiceNumber} />,
      headerFormatter: () => (
        <Checkbox onChange={checkAllCheckbox} isChecked={isCheckAll} />
      ),
    },
    {
      dataField: "invoiceNumber",
      text: "Invoice Number",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p
          style={{ color: "black", cursor: "pointer" }}
          onClick={() => goToDetailHandler(cell)}
        >
          {cell}
        </p>
      ),
    },
    {
      dataField: "orderDate",
      text: "Order Date",
      style: { textAlign: "center" },
      sort: true,
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>
          {dateFormat(cell, "dd/mm/yyyy HH:MM:ss")}
        </p>
      ),
    },
    {
      dataField: "orderStatus",
      text: "Status",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "warehouse",
      text: "DC",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>{cell?.warehouseName}</p>
      ),
    },
    {
      dataField: "totalPayment",
      text: "Total",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => formatter(cell),
    },
    {
      dataField: "shippingMethodId",
      text: "Shipping Method",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
  ];

  useEffect(() => {
    if (orders.orders) {
      setAllData([...allData, ...orders.orders]);
    }
  }, [orders]);

  const handleMappingData = () => {
    const arr = objToArrSelectedRow();
    const mapping = arr.map((invoiceNumber) =>
      find(allData, { invoiceNumber })
    );
    return mapping;
  };

  useEffect(() => {
    const tempDataExport = handleMappingData();
    setDataInvoiceExport(tempDataExport);
  }, [selectedRow]);

  const actionChangeHandler = (e) => {
    setSelectedAction(e.target.value);
  };

  useEffect(() => {
    if (tabActive === "To Ship" || tabActive === "Shipping") {
      const tempDataExport = handleMappingData();
      setDataPackingSlipExport(tempDataExport);
    }
  }, [tabActive, selectedRow]);

  const bulkActionHandler = () => {
    const arr = objToArrSelectedRow();
    if (selectedAction === "bulkDownload") {
      const dataExport = handleMappingData();
      handleDownloadInvoice(dataExport, "Invoice");
    } else if (selectedAction === "stickerBulkDownload") {
      const mapping = arr.map((invoiceNumber) =>
        find(allData, { invoiceNumber })
      );
      // const dataExport = handleMappingData();
      // handlerDownloadSticker(dataExport);
      StickerMultiplePDF(mapping);
    } else if (selectedAction === "packingSlipBulkDownload") {
      const dataExport = handleMappingData();
      handleDownloadInvoice(dataExport, "Packing-Slip");
    } else {
      toast({
        title: "Error.",
        description: "Bulk action belum diimplementasi",
        status: "warning",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const searchDataHandler = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setPage(0);
      const queries = queryString.stringify({
        ...currentQuery,
        page: 0,
        q: searchData,
      });

      history.push(`/order-list?${queries}`);
    }
  };

  useEffect(() => {
    if (currentQuery.q) {
      setSearchData(currentQuery.q);
    }
  }, [search]);

  const handleDownloadInvoice = async (datas, type) => {
    setIsLoading(true);
    try {
      let pdf = new jsPDF("p", "in", [8.5, 12]);
      for (let i = 0; i < datas.length; i++) {
        const canvas = await html2canvas(
          document.querySelector(
            `#Bulk-${type}-${datas[i].invoiceNumber.replace(" ", "")}`,
            {
              scale: "2",
            }
          )
        );
        let imgData = canvas.toDataURL("image/png", 0.98);

        if (i !== 0) {
          pdf.addPage();
        }
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        let position = 0;
        let heightLeft = pdfHeight;
        heightLeft -= pdf.internal.pageSize.getHeight();

        pdf.addImage(
          imgData,
          "PNG",
          0,
          position,
          pdfWidth,
          pdfHeight,
          undefined,
          "FAST"
        );

        while (heightLeft >= 0) {
          position = heightLeft - pdfHeight;
          pdf.addPage();
          pdf.addImage(
            imgData,
            "PNG",
            0,
            position,
            pdfWidth,
            pdfHeight,
            undefined,
            "FAST"
          );
          heightLeft -= pdf.internal.pageSize.getHeight();
        }
      }
      pdf.save(`Bulk-${type}-${dateFormat(new Date(), "dd mm yyyy")}.pdf`);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  const refreshShipping = async () => {
    try {
      setIsLoading(true);
      await Axios({
        url: `/admin/update-shipping-process`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      await fetchData();

      toast({
        title: "Success.",
        description: "Berhasil merefresh shipping status.",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    } catch (e) {
      console.error(e);
      toast({
        title: "Warning.",
        description: "Tidak berhasil merefresh shipping status.",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="list-order__container">
      {(isLoading || isAllLoading) && (
        <div
          style={{
            position: "fixed",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 12,
          }}
        >
          <div
            style={{
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0,0,0,0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner color="white" size="lg" />
          </div>
        </div>
      )}
      <Flex width="100%">
        <Text className="list-order__title">List Pesanan</Text>
        <Spacer />
        <Button
          onClick={() => refreshShipping()}
          leftIcon={<RepeatIcon />}
          colorScheme="teal"
        >
          Refresh Shipping
        </Button>
      </Flex>
      <Box className="list-order__content-container">
        <SectionTab
          page={page}
          setPage={setPage}
          tabActive={tabActive}
          setTabActive={setTabActive}
          search={search}
          subTabActive={subTabActive}
          setSubTabActive={setSubTabActive}
        />
        {currentQuery.tab_active !== "Early Bird" && (
          <>
            <Divider
              border="1px"
              mt={5}
              mb={5}
              w="100%"
              colorScheme="#718096"
              borderColor="#718096"
            />
            <Flex justify="space-between">
              <InputGroup maxWidth="300px">
                <InputLeftElement
                  pointerEvents="none"
                  children={<Search2Icon color="gray.300" />}
                />
                <Input
                  placeholder="Search"
                  className="list-order__search"
                  onKeyPress={searchDataHandler}
                  value={searchData}
                  onChange={(event) => setSearchData(event.target.value)}
                />
              </InputGroup>
              <FilterByDate
                setPage={setPage}
                csvData={orders}
                handleMappingData={handleMappingData}
                setIsLoading={setIsLoading}
              />
            </Flex>
            <Divider
              border="1px"
              mt={5}
              mb={5}
              w="100%"
              colorScheme="#718096"
              borderColor="#718096"
            />
            <Flex justify="space-between" align="center" width="100%">
              <FilterSort />
              {/* <Spacer /> */}
              <FilterByDc setPage={setPage} />
            </Flex>
            <Flex justify="end" align="center" width="100%" mt="1em">
              <FilterByShippingMethod setPage={setPage} />
            </Flex>
          </>
        )}
        {tabActive === "payment" && (
          <MiniTab
            list={["All", "Pending", "Paid"]}
            buttonShow="Paid"
            buttonWording="Process Order"
            setSubTabActive={setSubTabActive}
            subTabActive={subTabActive}
            setPage={setPage}
            actionHandler={
              isEmpty(selectedRow[page])
                ? () => processHandleEmptySelected("payment")
                : () => processHandler("payment")
            }
            selectedRow={objToArrSelectedRow()}
          />
        )}
        {tabActive === "To Ship" && (
          <MiniTab
            list={["All", "To Process", "Processed"]}
            buttonShow="To Process"
            buttonWording={
              isEmpty(objToArrSelectedRow())
                ? "Request Pickup All"
                : "Request Pickup"
            }
            setSubTabActive={setSubTabActive}
            subTabActive={subTabActive}
            setPage={setPage}
            actionHandler={
              isEmpty(objToArrSelectedRow())
                ? () => processHandleEmptySelected("to ship")
                : () => processHandler("to ship")
            }
          />
        )}
        {tabActive === "Shipping" && (
          <MiniTab
            list={["All", "In Shipping", "Received", "Cancelled by Courier"]}
            setSubTabActive={setSubTabActive}
            subTabActive={subTabActive}
            setPage={setPage}
            buttonShow={"Cancelled by Courier"}
            buttonWording={
              isEmpty(objToArrSelectedRow())
                ? "Re-request Pickup All"
                : "Re-request Pickup"
            }
            actionHandler={
              isEmpty(objToArrSelectedRow())
                ? () => processHandleEmptySelected("cancelled by courier")
                : () => processHandler("cancelled by courier")
            }
          />
        )}
        {tabActive === "Completed" && (
          <MiniTab
            list={["All"]}
            setSubTabActive={setSubTabActive}
            subTabActive={"All"}
            setPage={setPage}
          />
        )}
        {tabActive === "Cancelled" && (
          <MiniTab
            list={["All"]}
            setSubTabActive={setSubTabActive}
            subTabActive={"All"}
            setPage={setPage}
          />
        )}
        {tabActive === "Return" && (
          <MiniTab
            list={["All", "Requested", "Processing", "Confirmed"]}
            setSubTabActive={setSubTabActive}
            subTabActive={subTabActive}
            setPage={setPage}
            buttonShow="Requested"
            buttonWording={
              isEmpty(objToArrSelectedRow())
                ? "Process Return All"
                : "Process Return"
            }
            actionHandler={
              isEmpty(objToArrSelectedRow())
                ? () => processHandleEmptySelected("return")
                : () => processHandler("return")
            }
          />
        )}
        {tabActive === "Early Bird" && (
          <MiniTab
            list={["All", "Draft", "Payment Pending", "Paid", "Cancelled"]}
            setSubTabActive={setSubTabActive}
            subTabActive={subTabActive}
            setPage={setPage}
            buttonShow="Requested"
            buttonWording={
              isEmpty(objToArrSelectedRow())
                ? "Process Return All"
                : "Process Return"
            }
            actionHandler={
              isEmpty(objToArrSelectedRow())
                ? () => processHandleEmptySelected("return")
                : () => processHandler("return")
            }
          />
        )}

        <Divider
          border="1px"
          mt={5}
          w="100%"
          colorScheme="#718096"
          borderColor="#718096"
        />

        {!isEmpty(orders) ? (
          <ToolkitProvider
            data={orders.orders}
            keyField="partner_name"
            columns={columns}
          >
            {(props) => (
              <div className="py-4 table-responsive list-order__table-container">
                <CheckboxGroup
                  colorScheme="green"
                  defaultValue={selectedRow[page] ? selectedRow[page] : []}
                  onChange={changeEventHandler}
                  value={selectedRow[page] ? selectedRow[page] : []}
                >
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                    trStyle={{ backgroundColor: "white" }}
                  />
                </CheckboxGroup>
              </div>
            )}
          </ToolkitProvider>
        ) : (
          <Text
            textAlign={"center"}
            fontSize="16px"
            color="#000000"
            margin={"1em 0"}
          >
            There is no data entry
          </Text>
        )}
        {!isEmpty(orders) && (
          <Pagination
            page={page}
            totalPage={orders.totalPage}
            changePageHandler={changePageHandler}
          />
        )}
        {currentQuery.tab_active !== "Early Bird" && (
          <Flex align="center">
            <Select
              isDisabled={!selectedRow[page]?.length}
              value={selectedAction}
              onChange={(e) => actionChangeHandler(e)}
              width="200px"
            >
              <option value="">Select action</option>
              <option value="bulkDownload">Download Invoice</option>
              <option
                disabled={subTabActive !== "Processed"}
                value="stickerBulkDownload"
              >
                Download Sticker
              </option>
              <option
                disabled={tabActive !== "To Ship"}
                value="packingSlipBulkDownload"
              >
                Download Packing Slip
              </option>
            </Select>
            <Button
              colorScheme="teal"
              ml="16px"
              isDisabled={!selectedRow[page]?.length}
              onClick={bulkActionHandler}
            >
              Apply
            </Button>
          </Flex>
        )}
      </Box>
      {!isEmpty(dataInvoiceExport) &&
        dataInvoiceExport.map((each, idx) => (
          <div
            id={`Bulk-Invoice-${each.invoiceNumber}`}
            style={{
              backgroundColor: "#FFFFFF",
              width: "210mm",
              marginLeft: "auto",
              marginRight: "auto",
              zIndex: "-1",
              position: "absolute",
              left: "-100vw",
              top: "0",
            }}
            key={idx}
          >
            <InvoiceOrderPdf data={each} />
          </div>
        ))}

      {!isEmpty(dataPackingSlipExport) &&
        dataPackingSlipExport.map((each, idx) => (
          <div
            id={`Bulk-Packing-Slip-${each.invoiceNumber}`}
            style={{
              backgroundColor: "#FFFFFF",
              width: "210mm",
              marginLeft: "auto",
              marginRight: "auto",
              zIndex: "-1",
              position: "absolute",
              left: "-100vw",
              top: "0",
            }}
            key={idx}
          >
            <PackingSlipPdf data={each} />
          </div>
        ))}
    </Box>
  );
};

export default navguard(ListOrder);
