import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Col, Row } from "reactstrap";

function ConfirmationModal({ isOpen, onClose, handleSubmit }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <Text className="modal-title" textAlign={"center"}>
          Terjadi perubahan password
        </Text>
        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3" />
          <Col>
            <Button
              className="modal-save__button"
              width={"100%"}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Col>
          <Col md="3" />
        </Row>
        <Row style={{ alignItems: "center", marginBottom: "1em" }}>
          <Col md="3" />
          <Col>
            <Button
              className="modal-cancel__button"
              width={"100%"}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Col>
          <Col md="3" />
        </Row>
      </ModalContent>
    </Modal>
  );
}

export default ConfirmationModal;
