import {
  Box,
  Input,
  Text,
  Flex,
  Button,
  useToast,
  IconButton,
  ButtonGroup,
  Spacer,
  Hide,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  SearchIcon,
  SmallCloseIcon,
  MinusIcon,
  AddIcon,
} from "@chakra-ui/icons";
import "../styles/add-package__modal.scss";
import Axios from "../../../services/axios";
import iconDefault from "../../../assets/images/icon_default.png";

const AddPackageModal = ({
  setIsModal,
  fetchingProductPackage,
  packageSelected,
}) => {
  const toast = useToast();
  const [query, setQuery] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [showAllSelectedProduct, setShowAllSelectedProduct] = useState(false);
  const [obj, setObj] = useState({
    packageName: "",
    packageType: "New Reseller",
    status: false,
  });
  const imageMaxSize = 2000000;
  const [optionPackageType, setOptionPackageType] = useState([]);

  const fetchDataOptionType = async () => {
    try {
      const { data } = await Axios({
        url: "/reseller-discount/list?type=reseller level",
        method: "GET",
      });

      setOptionPackageType(
        data.data.resellerDiscounts.filter(
          (item) => item.resellerLevel !== "Inactive Reseller"
        )
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataOptionType();
  }, []);

  useEffect(() => {
    if (packageSelected?.id) {
      setObj({
        packageName: packageSelected.name,
        packageType: packageSelected.packageType,
        status: packageSelected.isActive,
      });
      setSelectedProduct(packageSelected.productPackageRecommendations);
    }
  }, [packageSelected]);

  const fetchProduct = async (value) => {
    try {
      const { data } = await Axios({
        method: "GET",
        url: `/product/search?filter_status=Published&q=${value}`,
      });

      setProducts(data.data.products);
    } catch (error) {
      console.error(error);
    }
  };

  const showAllSelectedProductHandler = () => setShowAllSelectedProduct(true);

  const searchHandler = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length > 2) {
      fetchProduct(value);
    } else {
      setProducts([]);
    }
  };

  const removeSelected = (value) => {
    const temp = selectedProduct.filter((item) => item.id !== value.id);
    setSelectedProduct(temp);
  };

  const chooseProductHandler = (value) => {
    let isDuplicated = false;
    selectedProduct.forEach((product) => {
      if (product.productId === value.id) {
        isDuplicated = true;
      }
    });

    value["defaultQuantity"] = 1;
    value["productId"] = value.id;

    if (!isDuplicated) {
      setSelectedProduct([...selectedProduct, value]);
    } else {
      toast({
        title: "Warning.",
        description: "Tidak dapat menambahkan produk yang sama.",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  const submitProductPackageHandler = async () => {
    const products = selectedProduct.map((item) => {
      return {
        productId: item.productId,
        variantId: null,
        defaultQuantity: item.defaultQuantity,
      };
    });

    try {
      await Axios({
        method: packageSelected?.id ? "PATCH" : "POST",
        url: `/package-recommendation/${
          packageSelected.id ? packageSelected.id : ""
        }`,
        data: {
          name: obj.packageName,
          products,
          isActive: obj.status,
          packageType: obj.packageType,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      await fetchingProductPackage(true);

      toast({
        title: "Success!",
        description: "Berhasil menambahkan Product Package",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });

      setIsModal(false);
    } catch (error) {
      console.error(error);

      toast({
        title: "Error!",
        description: "",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }

    // Start of: Upload Icon
    if (packageSelected.id && obj.savedImage) {
      let data = new FormData();
      data.append("image", obj.savedImage);
      try {
        // await Axios({
        //   method: "POST",
        //   url: `/package-recommendation/${packageSelected.id}/uploadIcon`,
        //   data: data,
        // });
        // await fetchingProductPackage(true);
      } catch (error) {
        console.error("Image Upload Error: ", error);

        toast({
          title: "Terjadi kesalahan",
          description: "",
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    // End of: Upload Icon
  };

  const _handleImgChange = (value, fileAttr, srcAttr) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (value.currentTarget.files.item(0).size > imageMaxSize) {
        toast({
          title: "Error.",
          description: "Mohon upload gambar berukuran kurang dari 2MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        const tempCategoryObj = { ...obj };
        let reader = new FileReader();
        const image = value.target.files[0];
        tempCategoryObj[fileAttr] = image;
        reader.onload = () => {
          tempCategoryObj[srcAttr] = reader.result;
          setObj(tempCategoryObj);
        };
        reader.readAsDataURL(image);
      }
    }
  };

  const handleCounter = (idx, isAdd = false) => {
    const tempSelectedProduct = [...selectedProduct];
    const tempObj = tempSelectedProduct[idx];
    if (isAdd) {
      tempObj["defaultQuantity"] += 1;
    } else {
      tempObj["defaultQuantity"] -= 1;
    }

    setSelectedProduct(tempSelectedProduct);
  };

  return (
    <Box className="add-package__container">
      <Box
        className="overlay"
        cursor="pointer"
        onClick={() => setIsModal(false)}
      />
      <Box className="main">
        <Box className="header">
          <Text className="title">Add Package</Text>
          <SmallCloseIcon
            cursor="pointer"
            fontSize="18px"
            color="black"
            onClick={() => setIsModal(false)}
          />
        </Box>
        <Box className="body">
          {packageSelected.id && (
            <Hide>
              <Box className="input-container">
                <Text className="label">Package Icon</Text>
                <Flex
                  width="15%"
                  border="4px dashed #efefef"
                  borderRadius="8px"
                  padding="8px"
                  position="relative">
                  <img
                    src={obj.image || packageSelected.iconPath || iconDefault}
                    alt="main-image__testi"
                    className="main-image"
                    style={{
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                  />
                  <Input
                    type="file"
                    id="icon-img"
                    name="img"
                    accept="image/*"
                    height="100% !important"
                    width="100% !important"
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      opacity: 0,
                      zIndex: 1,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      top: 0,
                    }}
                    onChange={(e) => _handleImgChange(e, "savedImage", "image")}
                  />
                </Flex>
              </Box>
            </Hide>
          )}

          <Box className="input-container">
            <Text className="label">Package Name</Text>
            <Input
              className="input"
              value={obj.packageName}
              onChange={(event) =>
                setObj({
                  ...obj,
                  packageName: event.target.value,
                })
              }
            />
          </Box>
          <Flex>
            <Box mr="32px">
              <Text className="label">Package Type</Text>
              <Box className="package-type__container">
                {optionPackageType.map((item, index) => {
                  return (
                    <Box
                      className={`package-type__item ${index === 0 && "left"} ${
                        index === optionPackageType.length - 1 && "right"
                      } ${obj.packageType === item.resellerLevel && "active"}`}
                      onClick={() =>
                        setObj({
                          ...obj,
                          packageType: item.resellerLevel,
                        })
                      }
                      key={item.id}>
                      {item.resellerLevel}
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Text className="label">Package Status</Text>
              <Box className="package-type__container">
                <Box
                  className={`package-type__item left ${
                    obj.status && "active"
                  }`}
                  onClick={() =>
                    setObj({
                      ...obj,
                      status: true,
                    })
                  }>
                  Active
                </Box>
                <Box
                  className={`package-type__item right ${
                    !obj.status && "active"
                  }`}
                  onClick={() =>
                    setObj({
                      ...obj,
                      status: false,
                    })
                  }>
                  Inactive
                </Box>
              </Box>
            </Box>
          </Flex>
          <Box minHeight="-webkit-fill-available">
            <Box className="choose-product__container">
              <Text className="label">Choose Product</Text>
              <Box position="relative">
                <Box position="absolute" top="8px" left="16px">
                  <SearchIcon />
                </Box>
                <Input
                  className="search-product"
                  pl="40px"
                  value={query}
                  onChange={searchHandler}
                />
              </Box>
            </Box>
            <Box className="products-container">
              {products.length > 0 &&
                products.map((item) => {
                  return (
                    <Text
                      className="product"
                      onClick={() => chooseProductHandler(item)}
                      pb="8px"
                      mb="24px !important"
                      borderBottom="1px solid #eaeaea"
                      key={item?.name}>
                      {item?.name}
                    </Text>
                  );
                })}
            </Box>
          </Box>
          {selectedProduct.length > 0 && (
            <Box className="selected-product__container">
              {showAllSelectedProduct ? (
                <Flex className="selected-product__header">
                  <Text className="selected-product__text">
                    Selected Product
                  </Text>
                  <MinusIcon
                    cursor="pointer"
                    fontSize="16px"
                    color="white"
                    onClick={() => setShowAllSelectedProduct(false)}
                  />
                </Flex>
              ) : (
                <Flex
                  className="selected-product"
                  justify="space-between"
                  align="flex-end"
                  onClick={showAllSelectedProductHandler}
                  cursor="pointer">
                  <Box>
                    <Text className="total-text">TOTAL</Text>
                    <Text className="product-text">
                      {selectedProduct.length} Product Selected
                    </Text>
                  </Box>
                  <Text className="lihat-text">Lihat</Text>
                </Flex>
              )}
              <Box
                style={{
                  maxHeight: showAllSelectedProduct ? "70vh" : 0,
                  overflow: "auto",
                }}
                className="product-selected__container-float">
                <Box className="products-container">
                  {selectedProduct.length > 0 &&
                    selectedProduct.map((item, idx) => {
                      return (
                        <Flex
                          justifyContent="center"
                          align="center"
                          borderBottom="1px solid #efefef"
                          mb="24px"
                          key={item?.name || item?.product?.name}>
                          <Text className="product">
                            {item?.name || item?.product?.name}
                          </Text>
                          <Spacer />
                          <Flex mr={2}>
                            <ButtonGroup
                              size="sm"
                              isAttached
                              variant="outline"
                              justifyContent="center"
                              alignItems="center">
                              <IconButton
                                disabled={item.defaultQuantity < 2}
                                aria-label="Add Counter"
                                icon={<MinusIcon />}
                                onClick={() => handleCounter(idx)}
                              />
                              <Text fontSize="16" m={1}>
                                {item.defaultQuantity}
                              </Text>
                              <IconButton
                                aria-label="Add Counter"
                                icon={<AddIcon />}
                                onClick={() => handleCounter(idx, true)}
                              />
                            </ButtonGroup>
                          </Flex>
                          <SmallCloseIcon
                            background="red"
                            cursor="pointer"
                            fontSize="18px"
                            color="white"
                            borderRadius="3px"
                            onClick={() => removeSelected(item)}
                          />
                        </Flex>
                      );
                    })}
                </Box>
              </Box>
              <Box className="button-submit">
                <Button
                  bg="#ff8084"
                  color="white"
                  _hover={{ backgroundColor: "#fafafa" }}
                  onClick={submitProductPackageHandler}>
                  Submit
                </Button>
              </Box>
            </Box>
          )}
          <Box className="footer"></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddPackageModal;
