import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Text,
  Input,
  Flex,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import Axios from "../../../services/axios";

const AddTestimony = ({
  isOpenAddModal,
  addModalToggleHandler,
  editData,
  setEditData,
  fetchTestimony,
  fetchMainTestimony,
  setIsLoading,
}) => {
  const toast = useToast();
  const imageMaxSize = 1000000;
  const [image, setImage] = useState({});
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [city, setCity] = useState("");
  const [desc, setDesc] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (!isEmpty(editData)) {
      const { avatarPath, name, occupation, city, testimonyText } = editData;
      setImage({ savedImage: avatarPath, image: avatarPath, isEdited: true });
      setName(name);
      setStatus(occupation);
      setCity(city);
      setDesc(testimonyText);
    }
  }, [editData]);

  const closeModalHandler = () => {
    setImage({});
    setIsSubmit(false);
    addModalToggleHandler();
    setEditData({});
    setName("");
    setStatus("");
    setCity("");
    setDesc("");
  };

  const submitDataHandler = async () => {
    setIsSubmit(true);
    if (
      name === "" ||
      status === "" ||
      city === "" ||
      desc === "" ||
      isEmpty(image)
    ) {
      toast({
        title: "Gagal menambahkan!",
        description: "silahkan cek kembali",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setIsLoading(true);
      try {
        let formData = new FormData();
        formData.append("image", image.savedImage);
        const { data } = await Axios({
          method: isEmpty(editData) ? "POST" : "PATCH",
          url: `/reseller-landing/testimony/${editData.id || ""}`,
          data: {
            name,
            occupation: status,
            city,
            testimonyText: desc,
            isActive: isEmpty(editData) ? true : editData.isActive,
            additionalInfo: "",
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });

        const testimonyId = data.data.testimony.id;

        if (!image.isEdited) {
          try {
            await Axios({
              method: "POST",
              url: `/reseller-landing/testimony/${testimonyId}/avatar`,
              data: formData,
              headers: {
                Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              },
            });
          } catch (error) {
            console.log(error);
            toast({
              title: "Error.",
              description: "Berhasil menambahkan! kecuali image!",
              status: "warning",
              position: "top",
              duration: 3000,
              isClosable: true,
            });
            setIsLoading(false);
          }
        }
        closeModalHandler();
      } catch (error) {
        console.log(error);
        setIsLoading(false);

        toast({
          title: "Error.",
          description: "Gagal menambahkan!",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        await fetchMainTestimony();
        await fetchTestimony({ withLoading: true });
      }
    }
  };

  const handleImgChange = (value, fileAttr, srcAttr) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (
        value.currentTarget.files.item(0).size > imageMaxSize ||
        value.currentTarget.files.item(0).type === "image/webp"
      ) {
        toast({
          title: "Error.",
          description:
            value.currentTarget.files.item(0).type === "image/webp"
              ? "File not supported"
              : "Mohon upload gambar berukuran kurang dari 1MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        const tempImg = { ...image };
        tempImg.isEdited = false;
        let reader = new FileReader();
        const getImage = value.target.files[0];
        tempImg[fileAttr] = getImage;
        reader.onload = () => {
          tempImg[srcAttr] = reader.result;
          setImage(tempImg);
        };
        reader.readAsDataURL(getImage);
      }
    }
  };

  return (
    <Modal isOpen={isOpenAddModal} onClose={closeModalHandler}>
      <ModalOverlay />
      <ModalContent>
        <Box p="16px">
          <Text
            fontSize="16px"
            fontWeight="600"
            color="#1a1a1a"
            letterSpacing="0"
            mb="16px">
            Tambah Testimony
          </Text>
          <Box>
            <Box mb={name === "" && isSubmit ? "4px" : "16px"}>
              <Text fontSize="14px" color="#1a1a1a" letterSpacing="0" mb="4px">
                Nama
              </Text>
              <Input
                border="1px solid grey"
                fontSize="12px"
                height="36px"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Box>
            {name === "" && isSubmit && (
              <Text color="red" fontSize="10px" letterSpacing="0" mb="12px">
                Field required!
              </Text>
            )}
            <Flex justify="space-between">
              <Box
                mb={status === "" && isSubmit ? "4px" : "16px"}
                width="100%"
                mr="8px">
                <Text
                  fontSize="14px"
                  color="#1a1a1a"
                  letterSpacing="0"
                  mb="4px">
                  Pekerjaan
                </Text>
                <Input
                  border="1px solid grey"
                  fontSize="12px"
                  height="36px"
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                />
                {status === "" && isSubmit && (
                  <Text color="red" fontSize="10px" letterSpacing="0" mb="12px">
                    Field required!
                  </Text>
                )}
              </Box>
              <Box
                mb={city === "" && isSubmit ? "4px" : "16px"}
                width="100%"
                ml="8px">
                <Text
                  fontSize="14px"
                  color="#1a1a1a"
                  letterSpacing="0"
                  mb="4px">
                  City
                </Text>
                <Input
                  border="1px solid grey"
                  fontSize="12px"
                  height="36px"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
                {city === "" && isSubmit && (
                  <Text color="red" fontSize="10px" letterSpacing="0" mb="12px">
                    Field required!
                  </Text>
                )}
              </Box>
            </Flex>
            <Box mb={desc === "" && isSubmit ? "4px" : "16px"} width="100%">
              <Text fontSize="14px" color="#1a1a1a" letterSpacing="0" mb="4px">
                Description
              </Text>
              <Textarea
                border="1px solid grey"
                fontSize="12px"
                height="36px"
                onChange={(e) => setDesc(e.target.value)}
                value={desc}
              />
            </Box>
            {desc === "" && isSubmit && (
              <Text color="red" fontSize="10px" letterSpacing="0" mb="12px">
                Field required!
              </Text>
            )}
            <Text fontSize="14px" color="#1a1a1a" letterSpacing="0" mb="4px">
              Avatar
            </Text>

            {isEmpty(image) && isSubmit && (
              <Text color="red" fontSize="10px" letterSpacing="0" mb="12px">
                Image required!
              </Text>
            )}
            <Flex justify="space-between" align="flex-end">
              {image.savedImage ? (
                <Box position="relative">
                  <img
                    src={image.image}
                    alt="avatar"
                    style={{
                      height: "60px",
                      width: "60px",
                      objectFit: "cover",
                      borderRadius: "50%",
                      border: "2px solid black",
                    }}
                  />
                  <Box
                    onClick={() => setImage({})}
                    position="absolute"
                    right="-8px"
                    cursor="pointer"
                    top="-8px">
                    <CloseIcon color="red" fontSize="10px" />
                  </Box>
                </Box>
              ) : (
                <Box position="relative" height="60px" width="60px">
                  <Button height="60px" width="60px" background="#efefef">
                    <AddIcon color="white" />
                  </Button>
                  <Input
                    type="file"
                    id="icon-img"
                    name="img"
                    accept="image/*"
                    height="100% !important"
                    width="100% !important"
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      opacity: 0,
                      zIndex: 1,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      top: 0,
                    }}
                    onChange={(e) => handleImgChange(e, "savedImage", "image")}
                  />
                </Box>
              )}
              <Button bg="#ff8084" color="white" onClick={submitDataHandler}>
                Submit
              </Button>
            </Flex>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default AddTestimony;
