import { Flex, Box } from "@chakra-ui/react";
import "./list-promo.css";

const PromoTab = ({
  tabActive,
  setTabActive,
  search,
  subTabActive,
  setSubTabActive,
}) => {
  const changeTabActiveHandler = (value) => {
    setTabActive(value);
    setSubTabActive("All");
  };

  return (
    <Flex className="promo-list__section-tab__container">
      <Box
        className={`promo-list__section-tab__item ${
          tabActive === "promo" && "tab-active"
        }`}
        onClick={() => changeTabActiveHandler("promo")}
      >
        Promo
      </Box>
      <Box
        className={`promo-list__section-tab__item ${
          tabActive === "voucher" && "tab-active"
        }`}
        onClick={() => changeTabActiveHandler("voucher")}
      >
        Voucher
      </Box>
    </Flex>
  );
};

export default PromoTab;
