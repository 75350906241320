import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Text, Flex, IconButton } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import "./list-community.css";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { RepeatIcon } from "@chakra-ui/icons";

const DateFilter = () => {
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const { search } = useLocation();
  const history = useHistory();

  const changeDateHandler = ({ event, type }) => {
    const date = `${event.getFullYear()}-${
      event.getMonth() + 1
    }-${event.getDate()}`;
    const today = `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}`;

    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify({
      ...currentQuery,
      filter_date_min:
        type === "min" ? date : firstDate === "" ? date : firstDate,
      filter_date_max: type === "max" ? date : lastDate === "" ? today : lastDate,
    });

    history.push(`/community/list-community?${queries}`);
  };

  const handleClearDate = () => {
    setFirstDate("");
    setLastDate("");

    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify(
      {
        ...currentQuery,
        filter_date_min: "",
        filter_date_max: "",
      },
      { skipEmptyString: true }
    );

    history.push(`/community/list-community?${queries}`);
  };

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (currentQuery.filter_date_min || currentQuery.filter_date_max) {
      setFirstDate(currentQuery.filter_date_min);
      setLastDate(currentQuery.filter_date_max);
    }
  }, [search]);
  return (
    <Flex className="promo-list__datepicker__container">
      <Text className="promo-list__datepicker-text">Filter Active Date</Text>
      <DatePicker
        selected={firstDate ? new Date(firstDate) : firstDate}
        onChange={(event) => changeDateHandler({ event, type: "min" })}
        dateFormat="dd/MM/yyyy"
        maxDate={lastDate ? new Date(lastDate) : new Date()}
      />
      -
      <DatePicker
        selected={lastDate ? new Date(lastDate) : lastDate}
        onChange={(event) => changeDateHandler({ event, type: "max" })}
        dateFormat="dd/MM/yyyy"
        minDate={new Date(firstDate)}
        maxDate={new Date()}
      />
      <IconButton
        colorScheme="teal"
        variant="outline"
        onClick={handleClearDate}
        icon={<RepeatIcon />}
      />
    </Flex>
  );
};

export default DateFilter;
