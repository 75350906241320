import React, { Fragment, useState, useEffect } from "react";
import Datatable from "./datatableQbee";
import "./style.css";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import OrderInfo from "./order-information";
import DiscInfo from "./discount-information";
import OrderAction from "./order-action";
import { isEmpty } from "lodash";

const ProductInfo = ({ order }) => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    if (!isEmpty(order) && !isEmpty(order.orderDetail)) {
      const temp = order?.orderDetail?.map((item) => {
        return {
          "Product Name": `${item?.name}${
            item?.productType === "Variant"
              ? ` - ${item?.selectedVariant?.name}`
              : ""
          }`,
          Barcode:
            item?.productType === "Variant"
              ? item?.selectedVariant?.barcode
              : item?.barcode,
          "Regular Price":
            item?.productType === "Variant"
              ? item?.selectedVariant?.price
              : item?.price,
          "Final Price":
            item?.productType === "Variant"
              ? item?.selectedVariant?.priceReseller
              : item?.priceReseller,
          Qty: item?.qty,
          "Sub Total":
            item?.qty *
            (item?.productType === "Variant"
              ? item?.selectedVariant?.price
              : item?.price),
        };
      });
      setProducts(temp || []);
    }
  }, [order]);

  return (
    <Fragment>
      <OrderInfo order={order} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="product-info-header">
                <h5>Product Information</h5>
              </CardHeader>
              <CardBody>
                <div id="basicScenario" className="product-list">
                  <Datatable
                    myData={products}
                    pageSize={10}
                    pagination={false}
                    className="-striped -highlight"
                    order={order}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Row>
        <Col lg={6}>
          <DiscInfo order={order} />
        </Col>
        <Col lg={6}>
          <OrderAction order={order} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default ProductInfo;
