import { Flex, IconButton, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import "./styles/date-picker.css";
import { RepeatIcon } from "@chakra-ui/icons";

function FilterByDate({ url, type }) {
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const { search } = useLocation();
  const history = useHistory();
  const dateMinKey = url.includes("list-point")
    ? "filter_created_date_min"
    : type === "redemption"
    ? "minDate"
    : "filter_date_min";
  const dateMaxKey = url.includes("list-point")
    ? "filter_created_date_max"
    : type === "redemption"
    ? "maxDate"
    : "filter_date_max";

  const changeDateHandler = ({ event, type }) => {
    const date = `${event.getFullYear()}-${
      event.getMonth() + 1
    }-${event.getDate()}`;
    const today = `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}`;

    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify({
      ...currentQuery,
      [dateMinKey]: type === "min" ? date : firstDate === "" ? date : firstDate,
      [dateMaxKey]: type === "max" ? date : lastDate === "" ? today : lastDate,
    });

    history.push(`${url}?${queries}`);
  };

  const handleClearDate = () => {
    setFirstDate("");
    setLastDate("");

    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify(
      {
        ...currentQuery,
        [dateMinKey]: "",
        [dateMaxKey]: "",
      },
      { skipEmptyString: true }
    );

    history.push(`${url}?${queries}`);
  };
  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (currentQuery[dateMinKey] || currentQuery[dateMaxKey]) {
      setFirstDate(currentQuery[dateMinKey]);
      setLastDate(currentQuery[dateMaxKey]);
    }
  }, [search]);
  return (
    <Flex className="order-list__datepicker__container">
      <Text className="order-list__datepicker-text">
        {type === "redemption" ? "Submission Date" : "Filter Registered Date"}
      </Text>
      <ReactDatePicker
        selected={firstDate ? new Date(firstDate) : firstDate}
        onChange={(event) => changeDateHandler({ event, type: "min" })}
        dateFormat="dd/MM/yyyy"
        maxDate={lastDate ? new Date(lastDate) : new Date()}
      />
      -
      <ReactDatePicker
        selected={lastDate ? new Date(lastDate) : lastDate}
        onChange={(event) => changeDateHandler({ event, type: "max" })}
        dateFormat="dd/MM/yyyy"
        minDate={new Date(firstDate)}
        maxDate={new Date()}
      />
      <IconButton
        colorScheme="teal"
        variant="outline"
        onClick={handleClearDate}
        icon={<RepeatIcon />}
      />
    </Flex>
  );
}

export default FilterByDate;
