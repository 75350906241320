import React, { useState, useEffect } from "react";

import Breadcrumb from "../common/breadcrumb";
import Loader from "../loader/Loader";
import "./styles/Brands.scss";

import { Col, Container, Row } from "reactstrap";
import { useToast } from "@chakra-ui/react";
import AddBrand from "./component/brands/AddBrand";
import BrandList from "./component/brands/BrandList";

import iconDefault from "../../assets/images/icon_default.png";
import imageDefault from "../../assets/images/image_default.png";
import Axios from "../../services/axios";
import navguard from "../auth/navguard";

const Brands = () => {
  const toast = useToast();
  const imageMaxSize = 2000000;
  const [isLoading, setIsLoading] = useState(false);
  const [fuzzyscore] = useState(75);
  const [query, setQuery] = useState("");
  const [searchType, setSearchType] = useState("Brand");
  const [allBrands, setAllBrands] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [allSeries, setAllSeries] = useState([]);
  const [filteredSeries, setFilteredSeries] = useState([]);
  const [brandObj, setBrandObj] = useState({
    type: "Brand",
    image: iconDefault,
    banner: imageDefault,
    iconActive: imageDefault,
    backgroundColor: null,
    descriptionColor: "black",
    titleColor: "black",
    brandDescription: "",
  });

  const [listBrand, setListBrand] = useState([]);
  const [listSeries, setListSeries] = useState([]);

  const reloadData = async () => {
    setAllBrands(listBrand);
    setFilteredBrands(listBrand);
    await reloadListSeries();
  };

  const reloadSeries = async () => {
    setFilteredSeries(listSeries);
  };

  useEffect(() => {
    const fetchData = async () => {
      await reloadData();
    };

    fetchData();
  }, [listBrand]);

  useEffect(() => {
    const fetchData = async () => {
      await reloadSeries();
    };

    fetchData();
  }, [listSeries]);

  const reloadListSeries = async () => {
    let result;
    for (let i = 0; i < listBrand.length; i += 1) {
      try {
        result = await Axios({
          method: "GET",
          url: `/series`,
        });
        let tempListSeries = result.data.data.seriesList
        setListSeries(tempListSeries.sort( function ( a, b ) { return a.ordering - b.ordering; } ));
        setAllSeries(tempListSeries.sort( function ( a, b ) { return a.ordering - b.ordering; } ))
      } catch (e) {
        setListSeries([]);
        setAllSeries([]);
      }
    }
  };

  const reloadListBrand = async () => {
    try {
      let result = await Axios({
        method: "GET",
        url: `/brand`,
      });
      let tempListBrand = result.data.data.brands
      setListBrand(tempListBrand.sort( function ( a, b ) { return a.ordering - b.ordering; } ));
    } catch (e) {
      setListBrand([]);
    }
  };

  useEffect(() => {
    reloadListBrand();
    reloadListSeries();
  }, []);

  const updateBrandSequence = async (brandData, value) => {
    let ordering = value === "Increase" ? "move-up" : "move-down"
    try {
      setIsLoading(true);
      await Axios({
        method: "PATCH",
        url: `/brand/${brandData.id}/${ordering}`,
      }).then(() => {
        toast({
          title: "Sukses!",
          description: `Data urutan Brand ${brandData.name} berhasil diubah`,
          status: "success",
          position: "top",
          duration: 1500,
          isClosable: true,
          onCloseComplete: () => {
            setIsLoading(false);
            window.location.reload()
          }
        });
      });
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      toast({
        title: "Error.",
        description: error.response.data.errors[0].message,
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateBrandSeriesSequence = async (brandSeriesData, value) => {
    let ordering = value === "Increase" ? "move-up" : "move-down"
    try {
      setIsLoading(true);
      await Axios({
        method: "PATCH",
        url: `/series/${brandSeriesData.id}/${ordering}`,
      }).then(() => {
        toast({
          title: "Sukses!",
          description: `Data urutan Series ${brandSeriesData.name} berhasil diubah`,
          status: "success",
          position: "top",
          duration: 1500,
          isClosable: true,
          onCloseComplete: () => {
            setIsLoading(false);
            window.location.reload()
          }
        });
      });
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      toast({
        title: "Error.",
        description: error.response.data.errors[0].message,
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const searchbyBrand = () => {
    setSearchType("Brand");
  }

  const searchbySeries = () => {
    setSearchType("Series");
  }

  const searchFunc = (tempData, query) => {
    const fuzzball = require("fuzzball");
    const result = [];
    let fuzzballScore;

    tempData.forEach((data) => {
      fuzzballScore = fuzzball.partial_ratio(
        query.toLowerCase(),
        data.name.toLowerCase()
      );

      if (fuzzballScore >= fuzzyscore) {
        result.push(data);
      }
    });

    return result;
  }

  const searchHandler = (event) => {
    let query = event.target.value;
    let tempData;

    tempData = (searchType === "Brand" ? [...allBrands] : [...allSeries]);

    if (searchType === "Brand") {
      if (query === "") {
        setFilteredBrands(allBrands);
      } else {
        let fuzzyResult = searchFunc(tempData, query);
        setFilteredBrands(fuzzyResult);
      }
    } else if (searchType === "Series") {
      if (query === "") {
        setFilteredSeries(allSeries);
      } else {
        let fuzzyResult = searchFunc(tempData, query);
        setFilteredSeries(fuzzyResult);
      }
    }
  };

  const changeHandler = (e) => {
    let temp = { ...brandObj };
    if (e.target.name === "type") {
      delete temp["brandName"];
      delete temp["subCategoryName"];
    }
    temp[e.target.name] = e.target.value;

    setBrandObj(temp);
  };

  const submitValidation = () => {
    let statusValidation = {
      status: "success",
      message: `${brandObj.type} berhasil ditambahkan`,
    };

    if (brandObj.type === "Brand") {
      if (!brandObj.brandName) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon isi nama brand";
      } else if (!brandObj.brandDescription) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon isi description brand";
      }
    } else if (brandObj.type === "Series") {
      if (!brandObj.seriesName) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon isi nama series";
      } else if (!brandObj.description) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon isi deskripsi series";
      } else if (!brandObj.parentBrand) {
        statusValidation.status = "error";
        statusValidation.message = "Mohon pilih parent brand";
      }
    }

    if (brandObj.image === iconDefault) {
      statusValidation.status = "warning";
      statusValidation.message = "Mohon upload icon";
    } else if (brandObj.iconActive === iconDefault) {
      statusValidation.status = "error";
      statusValidation.message = "Mohon upload active icon";
    } else if (
      !brandObj.savedBanner &&
      brandObj.banner === imageDefault
    ) {
      statusValidation.status = "error";
      statusValidation.message = "Mohon upload banner";
    }

    return statusValidation;
  };

  const submitNewBrand = async () => {
    let resultData;
    try {
      await Axios({
        method: "POST",
        url: `/brand`,
        data: {
          name: brandObj.brandName,
          description: brandObj.brandDescription,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }).then((result) => {
        resultData = result.data;
      });

      let brandData = resultData.data;

      if (!resultData.errorStatus) {
        let data = new FormData();
        let dataIconActive = new FormData();
        let banner = new FormData();

        data.append("image", brandObj.savedImage);
        dataIconActive.append("image", brandObj.savedActiveImage);
        banner.append("image", brandObj.savedBanner);

        let config = {
          method: "patch",
          url: `/brand/${brandData.id}/icon`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        let configActiveIcon = {
          method: "patch",
          url: `/brand/${brandData.id}/icon-active`,
          data: dataIconActive,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        let configBanner = {
          method: "patch",
          url: `/brand/${brandData.id}/banner`,
          data: banner,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);
        await Axios(configActiveIcon);
        await Axios(configBanner);

        toast({
          title: "Sukses.",
          description: `${brandObj.type} "${brandObj.brandName}" berhasil ditambahkan`,
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setBrandObj({
          type: "Brand",
        });
        reloadListBrand();
        setIsLoading(false);
      } else {
        toast({
          title: "Error.",
          description: resultData.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
      })
      setIsLoading(false);
    }
  };

  const submitNewSeries = async () => {
    let resultData;
    try {
      const { data } = await Axios({
        method: "POST",
        url: `/series`,
        data: {
          name: brandObj.seriesName,
          description: brandObj.description,
          brandId: brandObj.parentBrand,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      resultData = data;

      if (!resultData.errorStatus) {
        let data = new FormData();
        let dataIconActive = new FormData();
        let banner = new FormData();

        data.append("image", brandObj.savedImage);
        dataIconActive.append("image", brandObj.savedActiveImage);
        banner.append("image", brandObj.savedBanner);

        let config = {
          method: "patch",
          url: `/series/${resultData.data.id}/icon`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        let configActiveIcon = {
          method: "patch",
          url: `/series/${resultData.data.id}/icon-active`,
          data: dataIconActive,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        let configBanner = {
          method: "patch",
          url: `/series/${resultData.data.id}/banner`,
          data: banner,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        };

        await Axios(config);
        await Axios(configActiveIcon);
        await Axios(configBanner);

        toast({
          title: "Sukses.",
          description: `${brandObj.type} "${brandObj.seriesName}" berhasil ditambahkan`,
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setBrandObj({
          type: "Brand",
        });
        reloadListBrand();
        setIsLoading(false);
      } else {
        toast({
          title: "Error.",
          description: resultData.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast({
        title: "Error.",
        description: error.response.data.errors[0].message,
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const submitBrand = () => {
    setIsLoading(true);
    let statusValidation = submitValidation();

    if (statusValidation.status === "success") {
      if (brandObj.type === "Brand") {
        submitNewBrand();
      } else {
        submitNewSeries();
      }
    } else {
      toast({
        title: "Error.",
        description: statusValidation.message,
        status: statusValidation.status,
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const deleteBrand = (selectedId, item) => {
    setIsLoading(true);
    Axios({
      method: "DELETE",
      url: `/brand/${selectedId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    })
      .then((result) => {
        if (!result.data.errorStatus) {
          toast({
            title: "Sukses.",
            description: `Brand "${item}" berhasil dihapus`,
            status: "success",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          setBrandObj({
            type: "Brand",
          });
          reloadListBrand();

          setIsLoading(false);
        } else {
          toast({
            title: "Error.",
            description: result.data.errors[0].message,
            status: "error",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };
  
  const deleteSeries = (selectedId, item) => {
    setIsLoading(true);
    Axios({
      method: "DELETE",
      url: `/series/${selectedId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    })
      .then((result) => {
        if (!result.data.errorStatus) {
          toast({
            title: "Sukses.",
            description: `Series "${item}" berhasil dihapus`,
            status: "success",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          setBrandObj({
            type: "Brand",
          });
          reloadListBrand();
          reloadListSeries();

          setIsLoading(false);
        } else {
          toast({
            title: "Error.",
            description: result.data.errors[0].message,
            status: "error",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast({
          title: "Error.",
          description: error.response.data.errors[0].message,
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  const groupingSeries = (parent) => {
    return filteredSeries.filter(
      (series) => parent === series.brand.name
    );
  };

  const getCountBrandChild = (parent) => {
    let count = 0;
    filteredSeries.forEach((series) => {
      if (parent === series.brand.name) {
        count += 1;
      }
    });

    return count;
  };

  const deleteImage = (fileAttr, srcAttr) => {
    let inputImageElmnt = document.getElementById("icon-img");
    let inputImageElmnt2 = document.getElementById("banner-img");
    const tempCategoryObj = { ...brandObj };
    let defaultValue = iconDefault;
    if (srcAttr === "banner") {
      defaultValue = imageDefault;
      inputImageElmnt2.value = null;
    }
    tempCategoryObj[srcAttr] = defaultValue;
    tempCategoryObj[fileAttr] = undefined;
    inputImageElmnt.value = null;
    setBrandObj(tempCategoryObj);
  };

  const _handleImgChange = (value, fileAttr, srcAttr) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (value.currentTarget.files.item(0).size > imageMaxSize) {
        toast({
          title: "Error.",
          description: "Mohon upload gambar berukuran kurang dari 2MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        const tempCategoryObj = { ...brandObj };
        let reader = new FileReader();
        const image = value.target.files[0];
        tempCategoryObj[fileAttr] = image;
        reader.onload = () => {
          tempCategoryObj[srcAttr] = reader.result;
          setBrandObj(tempCategoryObj);
        };
        reader.readAsDataURL(image);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Breadcrumb title="Brand & Series" parent="Products" />
      <Container fluid={true}>
        <Row>
          <Col sm="5">
            <AddBrand
              listBrand={listBrand}
              brandObj={brandObj}
              changeHandler={changeHandler}
              submitBrand={submitBrand}
              _handleImgChange={_handleImgChange}
              deleteImage={deleteImage}
              setBrandObj={setBrandObj}
            />
          </Col>
          <Col sm="7">
            <BrandList
              query={query}
              searchType={searchType}
              searchbyBrand={searchbyBrand}
              searchbySeries={searchbySeries}
              deleteBrand={deleteBrand}
              deleteSeries={deleteSeries}
              searchHandler={searchHandler}
              filteredBrands={filteredBrands}
              groupingSeries={groupingSeries}
              getCountBrandChild={getCountBrandChild}
              updateBrandSequence={updateBrandSequence}
              updateBrandSeriesSequence={updateBrandSeriesSequence}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default navguard(Brands);
