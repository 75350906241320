import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const LoginProvider = (props) => {
  const router = useHistory();
  const [userToken] = useState(localStorage.getItem("userToken"));

  useEffect(() => {
    if (!userToken) {
      router.replace("/auth/login");
    }
  }, [userToken]);

  return <>{props.children}</>;
};

export default LoginProvider;
