import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Input,
  Text,
  InputGroup,
  InputRightAddon,
  Box,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import Select from "react-select";
import { CardBody, Col, Row } from "reactstrap";
import "./styles/CampaignProductCatalog.css";
import ProductImageUpload from "./ProuctImageUpload";
import { cloneDeep } from "lodash";

function CampaignProductCatalog({
  data,
  idx,
  inputData,
  setInputData,
  handleDeleteCatalog,
  allProduct,
}) {
  const formatter = new Intl.NumberFormat("id-ID");

  const handleChangeInput = ({ e, type }) => {
    let tempInput = cloneDeep(inputData);
    let changeData = tempInput.campaignProducts[idx];
    if (type === "product") {
      let product = e ? e : "";
      changeData[type] = product;
      changeData["price"] = product ? product.price : "";
    } else {
      const { name } = e.target;
      if (name === "discount") {
        let value = e.target.value ? parseFloat(e.target.value) : "";
        if (value > 100) {
          value = 100;
          e.target.value = 100;
        } else if (value < 0) {
          value = 0;
          e.target.value = 0;
        }
        changeData[name] = value;
      }
    }

    changeData["finalPrice"] = changeData["discount"]
      ? parseInt(
          (
            (changeData["price"] * (100 - changeData["discount"])) /
            100
          ).toFixed(0)
        )
      : changeData["price"];

    setInputData(tempInput);
  };

  const onMoveUpProduct = () => {
    const tempCampaignData = inputData.campaignProducts;
    const currentElement = tempCampaignData[idx];

    tempCampaignData.splice(idx, 1);
    tempCampaignData.splice(idx - 1, 0, currentElement);

    setInputData({ ...inputData, campaignProducts: tempCampaignData });
  };

  const onMoveDownProduct = () => {
    const tempCampaignData = inputData.campaignProducts;
    const currentElement = tempCampaignData[idx];

    tempCampaignData.splice(idx, 1);
    tempCampaignData.splice(idx + 1, 0, currentElement);

    setInputData({ ...inputData, campaignProducts: tempCampaignData });
  };

  return (
    <CardBody style={{ paddingTop: "10px" }} key={idx}>
      <Flex className="campaign-catalog__container" w={"100%"}>
        <Box w="3em">
          <IconButton
            disabled={idx === 0}
            onClick={onMoveUpProduct}
            mb={"4px"}
            colorScheme="teal"
            aria-label="Move Up"
            size="sm"
            icon={<ChevronUpIcon size={3} />}
          />
          <IconButton
            disabled={idx === inputData.campaignProducts.length - 1}
            onClick={onMoveDownProduct}
            mt={"4px"}
            colorScheme="teal"
            aria-label="Move Down"
            size="sm"
            icon={<ChevronDownIcon size={3} />}
          />
        </Box>
        <Row style={{ width: "100%" }}>
          <Col md="9">
            <Row>
              <Col md="12" className="campaign-catalog__input-wrapper">
                <Text className="campaign-catalog__label">Select Product</Text>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  options={allProduct}
                  isClearable
                  isSearchable
                  name="product"
                  onChange={(e) => handleChangeInput({ e, type: "product" })}
                  value={data.product}
                />
              </Col>
            </Row>

            <Row>
              <Col md="6" className="campaign-catalog__input-wrapper">
                <Text className="campaign-catalog__label">
                  Campaign Promo Discount
                </Text>
                <InputGroup>
                  <Input
                    placeholder="Percentage Discount"
                    type="number"
                    name="discount"
                    value={data.discount}
                    onChange={(event) => handleChangeInput({ e: event })}
                  />
                  <InputRightAddon children="%" />
                </InputGroup>
              </Col>
              <Col md="6" className="campaign-catalog__input-wrapper">
                <Text className="campaign-catalog__label">
                  Promo Final Price
                </Text>
                <Text className="campaign-catalog__label-price">
                  Rp.
                  {data.finalPrice >= 0
                    ? formatter.format(data.finalPrice)
                    : formatter.format(data.price)}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col md="3">
            {inputData.campaignProducts.length > 1 && (
              <Flex justify="end">
                <IconButton
                  icon={<CloseIcon color="red" />}
                  variant="ghost"
                  onClick={() => handleDeleteCatalog(idx)}
                />
              </Flex>
            )}
            <ProductImageUpload
              data={data}
              idx={idx}
              inputData={inputData}
              setInputData={setInputData}
            />
          </Col>
        </Row>
      </Flex>
    </CardBody>
  );
}

export default CampaignProductCatalog;
