import { Button, Flex, Input, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import queryString from "query-string";
import { useLocation } from 'react-router-dom';
import dateFormat from 'dateformat';
import { Row, Col } from 'reactstrap';
import '../styles/inputId.css';

const ModalInputId = ({
    isOpen,
    onClose,
    tfmId: propsTfmId,
    activityNpwpId: propsActivityNpwpId,
    activityNonNpwpId: propsActivityNonNpwpId,
    isCommissionNpwpAvail,
    isCommissionNonNpwpAvail,
    processPayout
}) => {
    const [tfmId, setTfmId] = useState(propsTfmId)
    const [activityNpwpId, setActivityNpwpId] = useState(propsActivityNpwpId)
    const [activityNonNpwpId, setActivityNonNpwpId] = useState(propsActivityNonNpwpId)
    const { search } = useLocation();
    const [dateSelected, setDateSelected] = useState(null);

    useEffect(() => {
        const query = queryString.parse(search);
        const formattedDate = dateFormat(new Date(query.year, parseInt(query.month)-1), "mmmm yyyy")
        setDateSelected(formattedDate)

        return (() => {
            setTfmId(null);
            setActivityNpwpId(null);
            setActivityNonNpwpId(null);
        })
    }, []);

    const triggerProcessPayout = () => {
        processPayout(tfmId, activityNpwpId, activityNonNpwpId)
    }

    return(
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent
                p={4}
                maxW="600px"
            >
                <Text className='input-id__title'>
                    Process Payout for {dateSelected}
                </Text>
                <Row
                    style={{
                        alignItems: "center",
                        marginBottom: "32px"
                    }}
                >
                    <Col xl={4}>
                        <Text className='input-id__label'>
                            TFM ID*
                        </Text>
                    </Col>
                    <Col xl={8}>
                        <Input
                            placeholder='Write TFM ID for ERP Data'
                            value={tfmId}
                            onChange={(e) => setTfmId(e.target.value)}
                        />

                    </Col>
                </Row>
                <Text className='input-id__label'>
                    <hr/>
                </Text>
                <Row
                    style={{
                        alignItems: "center",
                        marginTop: "16px"
                    }}
                >
                    {isCommissionNpwpAvail && (
                        <>
                            <Col xl={4}>
                                <Text className='input-id__label'>
                                    Activity ID NPWP*
                                </Text>
                            </Col>
                            <Col xl={8}>
                                <Input
                                    placeholder='Write Activity ID for ERP Data'
                                    value={activityNpwpId}
                                    onChange={(e) => setActivityNpwpId(e.target.value)}
                                    style={{
                                        margin: "8px 0"
                                    }}
                                />

                            </Col>
                        </>
                    )}
                    {isCommissionNonNpwpAvail && (
                        <>
                            <Col xl={4}>
                                <Text className='input-id__label'>
                                    Activity ID Non-NPWP*
                                </Text>
                            </Col>
                            <Col xl={8}>
                                <Input
                                    placeholder='Write Activity ID for ERP Data'
                                    value={activityNonNpwpId}
                                    onChange={(e) => setActivityNonNpwpId(e.target.value)}
                                    style={{
                                        margin: "8px 0"
                                    }}
                                />
                            </Col>
                        </>
                    )}
                </Row>
                <Flex
                    justifyContent="center"
                    mt="16px"
                >
                    <Button
                        borderRadius="16px"
                        width="60%"
                        color="#ffffff"
                        bg="#ff8084"
                        onClick={triggerProcessPayout}
                    >
                        Process
                    </Button>
                </Flex>
            </ModalContent>
        </Modal>
    )
};

export default ModalInputId;