import React, { useState } from "react";

import { Link } from "react-router-dom";
import "../../styles/Brands.scss";

import {
  Card,
  CardBody,
  Col,
  Row
} from "reactstrap";

import {
  Spacer,
  Flex,
  Box,
  InputGroup,
  Input,
  InputRightElement,
  InputLeftElement,
  Center,
  Button,
  IconButton,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
} from "@chakra-ui/react";

import {
  Search2Icon,
  ChevronDownIcon,
} from '@chakra-ui/icons';

import {
  MdEdit,
  MdDelete,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown
} from "react-icons/md";

const Brands = ({
  query,
  searchType,
  searchbyBrand,
  searchbySeries,
  deleteBrand,
  deleteSeries,
  searchHandler,
  filteredBrands,
  groupingSeries,
  getCountBrandChild,
  updateBrandSequence,
  updateBrandSeriesSequence
}) => {
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedType, setSelectedType] = useState('');
  const [selectedId, setSelectedId] = useState('');

    const deleteClicked = (idItem, item, type) => {
      if (type === "Brand") {
        if (getCountBrandChild(item) > 0) {
          setIsErrorOpen(true);
          setErrorMessage("Brand tidak bisa dihapus, harap hapus sub kategori terlebih dahulu")
        } else {
          openWarningModal(idItem, item, type)
        }
      } else {
        openWarningModal(idItem, item, type)
      }
    }

    const openWarningModal = (idItem, item, type) => {
      setIsWarningOpen(true);
      setSelectedBrand(item);
      setSelectedType(type);
      setSelectedId(idItem);
    }

    const closeWarningModal = () => {
      setIsWarningOpen(false);
      setSelectedBrand(null)
    }

    const closeErrorModal = () => {
      setIsErrorOpen(false);
      setErrorMessage('');
    }

    const deleteHandler = () => {
      closeWarningModal();
      if (selectedType === "Brand") {
        deleteBrand(selectedId, selectedBrand);
      } else {
        deleteSeries(selectedId, selectedBrand);
      }
    }

	return (
		<>
      <Card>
        <CardBody>
          <Flex className="pb-16">
            <Box width='55%'>
              <p className="card-title">
                Brands & Series
              </p>
            </Box>
            <Spacer />
            <Box width='45%'>
              <InputGroup size='md'>
                <InputLeftElement pointerEvents="none">
                  <Search2Icon color="gray.300" />
                </InputLeftElement>
                <Input
                  type="text"
                  className="form-control search-bar"
                  defaultValue={query}
                  onChange={(e) => searchHandler(e)}
                  placeholder={searchType === "Brand" ? "Search Brands" : "Search Brand Series"}
                  mr='-1rem'
                />
                <InputRightElement width="3rem">
                  <Menu>
                    <MenuButton
                      as={Button}
                      roundedStart="0px"
                      variant="outline"
                      bgColor="transparent"
                      borderBottomColor="transparent"
                      borderTopColor="transparent"
                      borderRightColor="transparent"
                    >
                      <ChevronDownIcon />
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={searchbyBrand}>Search by Brand</MenuItem>
                      <MenuItem onClick={searchbySeries}>Search by Brand Series</MenuItem>
                    </MenuList>
                  </Menu>
                </InputRightElement>
              </InputGroup>
            </Box>
          </Flex>
            <div className="clearfix"></div>
            <div id="basicScenario" className="category">
              <Modal isCentered isOpen={isWarningOpen} onClose={closeWarningModal}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader></ModalHeader>
                  <ModalCloseButton />
                  <ModalHeader textAlign="center">Apakah anda yakin ingin menghapus brand/series ini?</ModalHeader>
                  <h1 style={{textAlign:"center"}}>{selectedBrand}</h1>

                  <Button
                    backgroundColor={'#FF8084'}
                    color={'white'}
                    w="70%"
                    marginLeft="auto"
                    marginRight="auto"
                    marginTop="24px"
                    marginBottom="24px"
                    className="button-main"
                    onClick={() => deleteHandler()}
                  >
                    Hapus
                  </Button>
                </ModalContent>
              </Modal>

              <Modal isCentered isOpen={isErrorOpen} onClose={closeErrorModal}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader></ModalHeader>
                  <ModalCloseButton />
                  <ModalHeader textAlign="center">{errorMessage}</ModalHeader>

                  <Button
                    backgroundColor={'#FF8084'}
                    color={'white'}
                    w="70%"
                    marginLeft="auto"
                    marginRight="auto"
                    marginTop="24px"
                    marginBottom="24px"
                    className="button-main"
                    onClick={() => closeErrorModal()}
                  >
                    Tutup
                  </Button>
                </ModalContent>
              </Modal>
                {filteredBrands?.length > 0 ? (
                  <>
                    {filteredBrands?.map((item, indexBrand) => (
                      <Row key={item.name} className="brand-row">
                        <Col sm="12">
                          <Flex className="pv-8">
                            <p className="brand-title">
                              {item.name}
                            </p>
                            <Spacer />
                            <IconButton
                              onClick={() => deleteClicked(item.id, item.name, 'Brand')}
                              variant="ghost"
                              size="sm"
                              icon={<Icon as={MdDelete} />}
                            />
                            <Link to={`/products/edit-brand/${item.id}`}>
                              <IconButton
                                variant="ghost"
                                size="sm"
                                icon={<Icon as={MdEdit} />}
                              />
                            </Link>
                            <IconButton
                              onClick={() => updateBrandSequence(item, 'Increase')}
                              disabled={
                                item.ordering === 1
                                || item.ordering === 0
                                || indexBrand === 0
                              }
                              variant="ghost"
                              size="sm"
                              fontSize='20px'
                              icon={<Icon as={MdKeyboardArrowUp} />}
                            />
                            <IconButton
                              onClick={() => updateBrandSequence(item, 'Decrease')}
                              disabled={
                                item.ordering === filteredBrands.length 
                                || filteredBrands.length === indexBrand + 1
                              }
                              variant="ghost"
                              size="sm"
                              fontSize='20px'
                              icon={<Icon as={MdKeyboardArrowDown} />}
                            />
                          </Flex>
                        </Col>
                        <Col sm="12">
                          {getCountBrandChild(item.name) > 0 && (
                            <>
                              {groupingSeries(item.name).map((sub, indexSeries) => (
                                <Row key={sub.name}>
                                  <Col sm="12"
                                    style={{
                                      paddingLeft: "24px",
                                      backgroundColor: indexSeries % 2 === 0 ? 'rgba(255,128,132, 0.1)' : null
                                    }}
                                  >
                                    <Flex className="pv-16">
                                      <p className="series-title">
                                        {sub.name}
                                      </p>
                                      <Spacer />
                                      <IconButton
                                        onClick={() => deleteClicked(sub.id, sub.name, 'Series')}
                                        variant="ghost"
                                        size="sm"
                                        icon={<Icon as={MdDelete} />}
                                      />
                                      <Link to={`/products/edit-series/${sub.id}`}>
                                        <IconButton
                                          variant="ghost"
                                          size="sm"
                                          icon={<Icon as={MdEdit} />}
                                        />
                                      </Link>
                                      <IconButton
                                        onClick={() => updateBrandSeriesSequence(sub, 'Increase')}
                                        disabled={
                                          sub.ordering === 1
                                          || sub.ordering === 0
                                          || indexSeries === 0
                                        }
                                        variant="ghost"
                                        size="sm"
                                        fontSize='20px'
                                        icon={<Icon as={MdKeyboardArrowUp} />}
                                      />
                                      <IconButton
                                        onClick={() => updateBrandSeriesSequence(sub, 'Decrease')}
                                        disabled={
                                          sub.ordering === groupingSeries(item.name).length 
                                          || groupingSeries(item.name).length === indexSeries + 1
                                        }
                                        variant="ghost"
                                        size="sm"
                                        fontSize='20px'
                                        icon={<Icon as={MdKeyboardArrowDown} />}
                                      />
                                    </Flex>
                                  </Col>
                                </Row>
                              ))}
                            </>
                          )}
                        </Col>
                      </Row>
                    ))}
                  </>
                )
                :
                (
                  <Center m={8}>
                    There are no data to display
                  </Center>
                )
              }
            </div>
        </CardBody>
      </Card>
		</>
	);
};

export default Brands;
