import React, { useState, useRef } from "react";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  Text,
  Button,
  useToast,
} from "@chakra-ui/react";
import Breadcrumb from "../common/breadcrumb";
import navguard from "../auth/navguard";
import "./styles/list-commission.css";
import {
  fetchUserCommission,
  fetchDetailCommission,
} from "../../store/actions/commissionAction";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import Axios from "../../services/axios";
import CommissionPayoutDetailTable from "./components/tableCommissionPayoutDetail.js";
import { isEmpty } from "lodash";
import AlertModalConfirm from "./components/modalConfirm.js";

const DetailCommissionPayout = () => {
  const [dataCommissionPayout, setDataCommissionPayout] = useState([]);
  const [dataCommissionPayoutDetail, setDataCommissionPayoutDetail] = useState(
    []
  );
  const toast = useToast();
  const { id } = useParams();
  const [idModal, setIdModal] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const cancelRef = useRef();

  const onCloseModal = () => setIsOpenModal(false);

  const openAlertModal = (id) => {
    setIdModal(id);
    setIsOpenModal(true);
  };

  const fetchDataCommissionDetail = async () => {
    try {
      const { data } = await Axios({
        url: `/admin/commissionPayout/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      setDataCommissionPayoutDetail(data.data.commissionDetail);
      setDataCommissionPayout(data.data.commissionPayout);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataCommissionDetail();
  }, [id]);

  const payCommissionDetail = async () => {
    try {
      await Axios({
        url: `/admin/commissionPayout/${id}/pay`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      toast({
        title: "Sucess",
        description: "Berhasil melakukan pembayaran",
        status: "success",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
      fetchDataCommissionDetail();
    } catch (err) {
      console.log(err);
      toast({
        title: "Warning",
        description: "Tidak berhasil melakukan pembayaran",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Breadcrumb title={"Detail Commission Payout"} parent="Commission" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <Flex
              alignItems={"center"}
              justifyContent={"space-between"}
              marginBottom={"-0.5rem"}
            >
              <h5>Commission Payout</h5>

              <Box
                align={"center"}
                flexDir={"column"}
                justifyContent="center"
                border={"1px solid #c4c4c4"}
                boxSizing="border-box"
                borderRadius={"2px"}
                height="67px"
                left={"1240px"}
                top="93px"
                width={"167px"}
                display="flex"
              >
                <Text color={"#000000"} fontSize="30px" fontWeight={700}>
                  {dataCommissionPayout?.isPaid ? " PAID" : "UNPAID"}
                </Text>
              </Box>
            </Flex>
            <Heading as="h2" size="md" marginBottom={"2rem"}>
              {dataCommissionPayout?.invoiceNumber}
            </Heading>
            <Grid templateColumns="repeat(6, 1fr)" gap={4}>
              <GridItem w="100%" h="10" colSpan={1}>
                <Text>Reseller ID</Text>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={2}>
                <InputGroup flex={1}>
                  <Input
                    type="text"
                    placeholder="reseller id"
                    isDisabled
                    value={dataCommissionPayout?.user?.referralCode}
                  />
                </InputGroup>
              </GridItem>

              <GridItem w="100%" h="10" colSpan={1}>
                <Text>KTP</Text>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={2}>
                <InputGroup flex={1}>
                  <Input
                    type="number"
                    placeholder="email"
                    isDisabled
                    value={dataCommissionPayout?.user?.nik}
                  />
                </InputGroup>
              </GridItem>

              <GridItem w="100%" h="10" colSpan={1}>
                <Text>Reseller Name</Text>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={2}>
                <InputGroup flex={1}>
                  <Input
                    type="text"
                    placeholder="referral id"
                    value={`${dataCommissionPayout?.user?.firstName} ${dataCommissionPayout?.user?.lastName}`}
                    isDisabled
                  />
                </InputGroup>
              </GridItem>

              <GridItem w="100%" h="10" colSpan={1}>
                <Text>NPWP</Text>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={2}>
                <InputGroup flex={1}>
                  <Input
                    type="number"
                    placeholder="npwp"
                    isDisabled
                    value={dataCommissionPayout?.user?.npwp}
                  />
                </InputGroup>
              </GridItem>

              <GridItem w="100%" h="10" colSpan={1}>
                <Text>Bank Destination</Text>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={2}>
                <InputGroup flex={1}>
                  <Input
                    type="text"
                    placeholder="bank destination"
                    isDisabled
                    value={dataCommissionPayout?.user?.bank}
                  />
                </InputGroup>
              </GridItem>

              <GridItem w="100%" h="10" colSpan={1}></GridItem>
              <GridItem w="100%" h="10" colSpan={2} textColor="#4C4C4C">
                Tarif yang dikenakan untuk pemotongan pajak yang ditanggung oleh
                reseller adalah {dataCommissionPayout.taxPercentage}%
              </GridItem>

              <GridItem w="100%" h="10" colSpan={1}>
                <Text>Bank Account Number</Text>
              </GridItem>
              <GridItem w="100%" h="10" colSpan={2}>
                <InputGroup flex={1}>
                  <Input
                    type="number"
                    placeholder="accountbank"
                    isDisabled
                    value={dataCommissionPayout?.user?.accountNumber}
                  />
                </InputGroup>
              </GridItem>
            </Grid>
          </CardHeader>
          <CardBody>
            <Heading as="h2" size="md">
              Commission Payout Detail
            </Heading>
            <Divider
              mt={5}
              mb={5}
              borderWidth={1}
              color="black"
              borderColor="black"
              background="black"
            />
            {!isEmpty(dataCommissionPayout) &&
              !isEmpty(dataCommissionPayoutDetail) && (
                <CommissionPayoutDetailTable
                  data={dataCommissionPayoutDetail}
                  dataCommissionPayout={dataCommissionPayout}
                />
              )}
            <Box
              paddingTop={"30px"}
              display="flex"
              justifyContent={"center"}
              alignItems="center"
              hidden={true}
            >
              <Button
                width={"150px"}
                colorScheme="teal"
                size="md"
                borderRadius={"20px"}
                onClick={() => openAlertModal(dataCommissionPayout?.id)}
              >
                Pay
              </Button>
            </Box>
          </CardBody>
          <AlertModalConfirm
            isOpen={isOpenModal}
            cancelRef={cancelRef}
            onClose={onCloseModal}
            title={"Pay Commission"}
            subtitle={"Apakah kamu yakin ingin melanjutkan pembayaran komisi?"}
            alertHandler={() => payCommissionDetail()}
          />
        </Card>
      </Container>
    </>
  );
};

export default navguard(DetailCommissionPayout);
