import { useState, useEffect, useCallback } from "react";
import { Box, Text, Flex, Button, Center, useToast } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import FilterByUserType from "./filteByUserType";
import FilterByRating from "./filterByRating";
import { remove, isEmpty } from "lodash";
import styles from "./styles.module.css";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReviewListData,
  patchProductReviewApproval,
  patchProductReviewPublished,
} from "../../../../store/actions/productReviewAction";
import ReviewImage from "../reviewImage";

const IconStar = ({ active }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6315 6.11934C14.5896 5.99592 14.5125 5.88747 14.4096 5.80738C14.3068 5.72729 14.1828 5.67908 14.0528 5.66868L10.2522 5.36668L8.60751 1.72601C8.55514 1.60875 8.46994 1.50916 8.36221 1.43925C8.25448 1.36934 8.12882 1.3321 8.0004 1.33203C7.87197 1.33196 7.74627 1.36906 7.63846 1.43885C7.53066 1.50864 7.44535 1.60814 7.39285 1.72534L5.74818 5.36668L1.94751 5.66868C1.81982 5.67879 1.69774 5.72548 1.59588 5.80316C1.49403 5.88084 1.41671 5.98622 1.37318 6.1067C1.32964 6.22717 1.32174 6.35763 1.35041 6.48248C1.37908 6.60733 1.44311 6.72127 1.53485 6.81068L4.34351 9.54868L3.35018 13.85C3.32002 13.9802 3.32969 14.1165 3.37793 14.2411C3.42617 14.3657 3.51076 14.473 3.62071 14.549C3.73067 14.6249 3.86093 14.6661 3.99457 14.6671C4.12821 14.6681 4.25908 14.629 4.37018 14.5547L8.00018 12.1347L11.6302 14.5547C11.7437 14.6301 11.8777 14.6689 12.0139 14.6659C12.1502 14.6629 12.2823 14.6183 12.3924 14.538C12.5026 14.4577 12.5855 14.3456 12.63 14.2167C12.6746 14.0879 12.6786 13.9485 12.6415 13.8173L11.4222 9.55068L14.4462 6.82934C14.6442 6.65068 14.7168 6.37201 14.6315 6.11934Z"
      fill={active ? "#FFBB00" : "#CCCCCC"}
    />
  </svg>
);

const IconLikedActive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 21H5V8H4C3.46957 8 2.96086 8.21071 2.58579 8.58579C2.21071 8.96086 2 9.46957 2 10V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21ZM20 8H13L14.122 4.632C14.2221 4.33141 14.2494 4.01135 14.2016 3.69815C14.1538 3.38496 14.0322 3.08761 13.847 2.83059C13.6617 2.57358 13.4181 2.36424 13.1361 2.21984C12.8541 2.07543 12.5418 2.00008 12.225 2H12L7 7.438V21H18L21.912 12.404L22 12V10C22 9.46957 21.7893 8.96086 21.4142 8.58579C21.0391 8.21071 20.5304 8 20 8V8Z"
      fill="#FF7566"
    />
  </svg>
);

const IconCheck = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66632 10.3907L4.47099 8.19539L3.52832 9.13806L6.66632 12.2761L13.1377 5.80473L12.195 4.86206L6.66632 10.3907Z"
      fill="#015352"
    />
  </svg>
);

const IconSort = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 11H16V13H4V11ZM4 6H20V8H4V6ZM4 18H11.235V16H4V18Z"
      fill="#4C4C4C"
    />
  </svg>
);

const IconLikeWhite = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 8H14.388L15.511 4.633C15.713 4.025 15.611 3.351 15.236 2.831C14.861 2.311 14.253 2 13.612 2H12C11.703 2 11.422 2.132 11.231 2.36L6.531 8H4C2.897 8 2 8.897 2 10V19C2 20.103 2.897 21 4 21H17.307C17.7139 20.9986 18.1108 20.8738 18.4452 20.6421C18.7797 20.4103 19.0359 20.0825 19.18 19.702L21.937 12.351C21.9789 12.2387 22.0002 12.1198 22 12V10C22 8.897 21.103 8 20 8ZM4 10H6V19H4V10ZM20 11.819L17.307 19H8V9.362L12.468 4H13.614L12.052 8.683C12.0013 8.83331 11.9871 8.99355 12.0107 9.15043C12.0343 9.3073 12.095 9.45629 12.1877 9.58504C12.2803 9.71379 12.4024 9.8186 12.5436 9.89076C12.6849 9.96293 12.8414 10.0004 13 10H20V11.819Z"
      fill="#808080"
    />
  </svg>
);

const ReviewList = (props) => {
  const { panelNum, showHidden } = props;
  const toast = useToast();
  const { slug } = useParams();
  const dispatch = useDispatch();

  const [filterByUserType, setFilterByUserType] = useState("all");
  const [filterByRating, setFilterByRating] = useState([]);
  const [sort, setSort] = useState("desc");
  const [sortToggle, setSortToggle] = useState(false);

  const [reviews, setReviews] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalReview, setTotalReview] = useState(0);
  const [totalReviewAll, setTotalReviewAll] = useState(0);

  const [hidden, setHidden] = useState(true);

  const limit = 10;

  const onChangeRating = (value) => {
    let temp = [...filterByRating];
    if (filterByRating.includes(value)) {
      remove(temp, (item) => item === value);
    } else {
      temp = [...temp, value];
    }

    setFilterByRating(temp);
  };

  const onChangeSorting = (value) => {
    setSort(value);
    setSortToggle(!sortToggle);
    setPage(0);
  };

  const { productReviewData } = useSelector(
    ({ productReview }) => productReview
  );

  const { productReview } = useSelector(({ productReview }) => productReview);

  const fetchReviewList = async () => {
    try {
      fetchReviewListData(
        dispatch,
        slug,
        page,
        sort,
        limit,
        filterByRating,
        filterByUserType,
        panelNum
      );
    } catch (error) {
      setReviews([]);
    }
  };

  useEffect(() => {
    setReviews(productReviewData?.data?.reviews);
    setTotalPage(productReviewData?.data?.totalPage);
    setTotalReview(productReviewData?.data?.count);
    setTotalReviewAll(productReviewData?.data?.count);
  }, [productReviewData]);

  const filterHiddenReviews = useCallback(() => {
    setReviews(
      reviews.filter((item) => {
        return item.isVisible === true;
      })
    );
  }, [showHidden]);

  useEffect(() => {
    if (showHidden && reviews) {
      filterHiddenReviews();
    } else {
      setReviews(productReviewData?.data?.reviews);
    }
  }, [filterHiddenReviews]);

  useEffect(() => {
    fetchReviewList();
  }, [slug, page, sort, filterByRating, filterByUserType, panelNum]);

  useEffect(() => {
    fetchReviewList();
  }, [productReview]);

  const handlePageClick = (event) => {
    setPage(event.selected);
  };

  const onHandleReviewAction = async (id, isApproved) => {
    try {
      await patchProductReviewApproval(dispatch, id, isApproved);

      toast({
        title: "Success.",
        description: "Approval Success ",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error.",
        description: "Approval failed: " + error,
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const onHandlePublishAction = async (id, isVisible) => {
    try {
      await patchProductReviewPublished(dispatch, id, isVisible);
      setHidden(!hidden);

      toast({
        title: "Success.",
        description: isVisible ? "Publish Success" : "Hide Success",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Error.",
        description: isVisible
          ? "Publish Failed:" + err
          : "Hide Failed: " + err,
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Box className={styles["container"]}>
        <FilterByUserType
          filterByUserType={filterByUserType}
          onChangeFilter={(value) => setFilterByUserType(value)}
        />
        <FilterByRating
          filterByRating={filterByRating}
          onChangeRating={onChangeRating}
        />
        {totalReview > 0 ? (
          <Box mb="28px">
            <Flex justify="space-between" pb="14px">
              <Text className={styles["header-left"]}>
                Semua ulasan ({totalReview})
              </Text>
              <Flex
                align="center"
                position="relative"
                onClick={() => setSortToggle(!sortToggle)}
                cursor="pointer"
              >
                <IconSort />
                <Text className={styles["sort-text"]}>Urutkan</Text>
                {sortToggle && (
                  <Box className={styles["sort-container"]}>
                    <Flex
                      justify="space-between"
                      align="center"
                      onClick={() => onChangeSorting("desc")}
                      p="0 !important"
                    >
                      <Text className={styles["sort-text"]}>Terbaru</Text>
                      {sort === "desc" && <IconCheck />}
                    </Flex>
                    <Flex
                      justify="space-between"
                      align="center"
                      onClick={() => onChangeSorting("asc")}
                      p="0 !important"
                    >
                      <Text className={styles["sort-text"]}>Terlama</Text>
                      {sort === "asc" && <IconCheck />}
                    </Flex>
                  </Box>
                )}
              </Flex>
            </Flex>
            {reviews?.map((item) => {
              //change time language
              moment.locale("id");
              let roundTime = moment(item.createdAt).startOf("day");
              let duration = moment
                .duration(roundTime.diff(moment(new Date())))
                .days();

              return (
                <Flex
                  mb="16px"
                  borderBottom="1px solid #CCCCCC"
                  pb="24px !important"
                  key={item.id}
                >
                  <Box className={styles["image-container"]}>
                    <img
                      alt=""
                      className={styles["image"]}
                      src={item.user?.displayPicturePath || ""}
                    />
                  </Box>

                  <Box flex="1">
                    <Flex
                      align="center"
                      padding="0 !important"
                      mb="12px"
                      position="relative"
                    >
                      <Text className={styles["review-user__name"]}>
                        {item.user
                          ? `${item.user.firstName} ${item.user.lastName}`
                          : item.customerData.customerName}
                      </Text>
                      <Text
                        className={styles["review-user__type"]}
                        style={{
                          textTransform: "capitalize",
                          background:
                            item.reviewerType === "reseller"
                              ? "#E9DBF0"
                              : "#d9ebaf",
                        }}
                      >
                        {item.reviewerType}
                      </Text>
                      <Text className={styles["review-user__time"]}>
                        {duration === 0
                          ? "Hari ini"
                          : duration === -1
                          ? "1 hari yang lalu"
                          : roundTime.fromNow()}
                      </Text>
                    </Flex>

                    <Text className={styles["review-user__time-mobile"]}>
                      {duration === 0
                        ? "Hari ini"
                        : duration === -1
                        ? "1 hari yang lalu"
                        : roundTime.fromNow()}
                    </Text>

                    <Flex className={styles["rating-point__mobile-container"]}>
                      <IconStar active />
                      <Text className={styles["rating-text__mobile"]}>
                        {item.rating}.0
                      </Text>
                    </Flex>

                    <Flex
                      width="96px"
                      justify="space-between"
                      mb="12px"
                      padding="0 !important"
                      display="flex"
                    >
                      {[1, 2, 3, 4, 5].map((number) => {
                        return (
                          <IconStar
                            key={number}
                            active={number <= item.rating}
                          />
                        );
                      })}
                    </Flex>

                    <Text className={styles["review-user__text"]}>
                      {item.review}
                    </Text>
                    {!isEmpty(item?.productReviewMedia) && (
                      <Flex alignItems="center">
                        {item.productReviewMedia.map((each, idx) => {
                          return (
                            <ReviewImage
                              each={each}
                              key={idx}
                              duration={duration}
                              roundTime={roundTime}
                              item={item}
                            />
                          );
                        })}
                      </Flex>
                    )}
                  </Box>
                  <Box flex={0.3}>
                    {panelNum === 1 && (
                      <Center>
                        <Flex align="center" p="0 !important" cursor="pointer">
                          {item.likesCount ? (
                            <IconLikedActive />
                          ) : (
                            <IconLikeWhite />
                          )}
                          <>
                            <Text className={styles["like-number"]}>
                              {item.likesCount}
                            </Text>
                            <Text className={styles["like-text"]}></Text>
                          </>
                        </Flex>
                      </Center>
                    )}

                    {panelNum === 0 ? (
                      <Box>
                        <Button
                          w="100%"
                          colorScheme={"teal"}
                          m={1}
                          onClick={() => onHandleReviewAction(item.id, true)}
                        >
                          Approve
                        </Button>
                        <Button
                          w="100%"
                          colorScheme={"gray"}
                          m={1}
                          onClick={() => onHandleReviewAction(item.id, false)}
                        >
                          Reject
                        </Button>
                      </Box>
                    ) : (
                      <Button
                        w="100%"
                        colorScheme={item.isVisible ? "gray" : "teal"}
                        onClick={() =>
                          onHandlePublishAction(item.id, !item.isVisible)
                        }
                      >
                        {item.isVisible ? "Hide" : "Publish"}
                      </Button>
                    )}
                  </Box>
                </Flex>
              );
            })}
            <div className={`pagination-container ${styles["pagination"]}`}>
              <ReactPaginate
                previousLabel={<ChevronLeftIcon fontSize="24px" />}
                nextLabel={<ChevronRightIcon fontSize="24px" />}
                pageCount={totalPage}
                onPageChange={handlePageClick}
                breakLabel="..."
                breakClassName="pagination__item pagination__item--break"
                pageClassName="pagination__item"
                pageLinkClassName="pagination__item-link"
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
                forcePage={page}
              />
            </div>
          </Box>
        ) : (
          <Flex width="100%" justify="center" mt="100px" mb="64px">
            <Box max-width="502px">
              <Text className={styles["empty-state__title"]}>
                Belum ada ulasan untuk produk ini
              </Text>
            </Box>
          </Flex>
        )}
      </Box>
    </>
  );
};

export default ReviewList;
