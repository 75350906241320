import { Flex, Box } from "@chakra-ui/react";
import React, { useState } from "react";
import ReactSelect from "react-select";
import { FormGroup, Label } from "reactstrap";

function SectionOthers({ userData, setUserData, isEdit }) {
  const [options] = useState([
    {
      label: "Suspend account 10 minutes",
      value: "temporary",
    },
    {
      label: "Suspend account permanent",
      value: "permanent",
    },
    {
      label: "Account active",
      value: false,
    },
  ]);

  //   const [eligibilityOptions] = useState([
  //     {
  //       label: "Eligible",
  //       value: true,
  //     },
  //     {
  //       label: "Not Eligible",
  //       value: false,
  //     },
  //   ]);
  const handleSelectedOption = (e, name) => {
    if (name === "isCommissionEligible") {
      if (e) {
        setUserData({ ...userData, isCommissionEligible: e.value });
      } else {
        setUserData({ ...userData, isCommissionEligible: null });
      }
    } else {
      if (e) {
        setUserData({ ...userData, selectRestrict: e.value });
      } else {
        setUserData({ ...userData, selectRestrict: {} });
      }
    }
  };

  return (
    <Flex mb={2} mt={5}>
      <Box flex="1">
        <h4>Others</h4>
        <FormGroup className="row">
          <Label className="col-xl-3 col-md-4">Restrict Account</Label>
          <ReactSelect
            isDisabled={!isEdit}
            options={options}
            className="col-xl-8 col-md-7 p-0"
            value={options.find(
              (item) => item.value === userData.selectRestrict
            )}
            isClearable
            onChange={(e) => handleSelectedOption(e, "selectRestrict")}
          />
        </FormGroup>
      </Box>
      <Box flex={1}>
        {/* <h4>Commission Eligibility</h4>
    <FormGroup className="row">
      <Label className="col-xl-3 col-md-4">Is Elligible</Label>
      <ReactSelect
        isDisabled={!isEdit}
        options={eligibilityOptions}
        className="col-xl-8 col-md-7 p-0"
        value={eligibilityOptions.find(
          (item) => item.value === userData.isCommissionEligible
        )}
        isClearable
        onChange={(e) => handleSelectedOption(e, "isCommissionEligible")}
      />
    </FormGroup> */}
      </Box>
    </Flex>
  );
}

export default SectionOthers;
