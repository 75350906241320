import { useEffect, useState } from "react";
import {
  Text,
  Flex,
  Box,
  Switch,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Button,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon, Search2Icon } from "@chakra-ui/icons";
import AddTagModal from "./component/AddTagModal";
import ProductListModal from "./component/ProductListTag";
import Pagination from "./component/pagination";
import SkeletonProductTable from "./component/skeletonProductTable";
import "./styles/tagging.scss";
import Axios from "../../services/axios";
import navguard from "../auth/navguard";

const ProductTagging = () => {
  const toast = useToast();
  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [isPopUp, setIsPopUp] = useState(false);
  const [isOpenDeleteHandler, setIsOpenDeleteHandler] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState({});
  const [query, setQuery] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);

  const fetchTagHandler = async ({ selectedPage, useLoading }) => {
    if (useLoading) {
      setIsloading(true);
    }
    let queries = "";
    if (query.length) {
      queries = `q=${query}`;
    }
    try {
      const { data } = await Axios({
        method: "GET",
        url: `/product-tag/list?limit=10&page=${selectedPage + 1}&${queries}`,
      });
      setTags(data.data.tags);
      setTotalPage(data.data.totalPage);
      setIsloading(false);
    } catch (error) {
      console.log(error);
      setIsloading(false);
    }
  };

  const changePageHandler = ({ selected }) => {
    fetchTagHandler({ selectedPage: selected, useLoading: true });
    setPage(selected);
  };

  const showProductHandler = (value) => {
    setSelectedTag(value);
    setIsPopUp(true);
  };

  const changeIsActiveHandler = async ({ data }) => {
    try {
      await Axios({
        method: "PATCH",
        url: `/product-tag/${data.tag}/toggle-active`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      fetchTagHandler({ selectedPage: page, useLoading: true });
      toast({
        title: "Success!",
        description: "Berhasil mengubah status",
        status: "success",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Error!",
        description: "",
        status: "error",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  const deleteTagHandler = async ({ tagName }) => {
    try {
      await Axios({
        method: "DELETE",
        url: `/product-tag/${tagName}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      fetchTagHandler({ selectedPage: 0, useLoading: true });
      setPage(0);
      setIsOpenDeleteHandler(false);
      toast({
        title: "Success!",
        description: "Berhasil menghapus status",
        status: "success",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Error!",
        description: "",
        status: "error",
        position: "top",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  const disableChangeHandler = () => {
    toast({
      title: "Error!",
      description: "Non-aktifkan tag terlebih dahulu!",
      status: "error",
      position: "top",
      duration: 2000,
      isClosable: true,
    });
  };

  const editHandler = (tag) => {
    setSelectedTag(tag);
    setIsModal(true);
  };

  useEffect(() => {
    fetchTagHandler({ selectedPage: 0, useLoading: true }); 
  }, [query]);
  return (
    <div className="product-tagging__wrapper">
      {isModal && (
        <AddTagModal
          setIsModal={setIsModal}
          selectedTag={selectedTag}
          resetTagList={() => {
            setPage(0);
            fetchTagHandler({ selectedPage: 0, useLoading: true });
          }}
        />
      )}
      {isPopUp && (
        <ProductListModal
          setIsPopUp={setIsPopUp}
          products={selectedTag.products}
        />
      )}
      <Modal
        isOpen={isOpenDeleteHandler}
        onClose={() => setIsOpenDeleteHandler(false)}
      >
        <ModalOverlay />
        <ModalContent p="32px" d="flex" justify="center">
          <ModalCloseButton />

          <Box mt="16px">
            <Text className="popup-text__confirmation-package">
              Apakah anda yakin ingin menghapus tag ini?
            </Text>
          </Box>
          <Flex w="100%" justify="center">
            <Button
              mr="16px"
              variant="ghost"
              onClick={() => setIsOpenDeleteHandler(false)}
            >
              Cancel
            </Button>
            <Button
              ml="16px"
              color="white"
              bg="#ff8084"
              onClick={() => deleteTagHandler({ tagName: selectedTag.tag })}
            >
              Yakin
            </Button>
          </Flex>
        </ModalContent>
      </Modal>

      <div className="product-tagging__container">
        <Flex justify="space-between" align="center" className="header">
          <Text className="title">Product Tag</Text>
          <Flex align="center">
            <Box position="relative">
              <Search2Icon
                color="grey"
                position="absolute"
                top="10px"
                zIndex="2"
                left="12px"
                fontSize="12px"
              />
              <Input
                className="input-container"
                placeholder="Cari Tag"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </Box>
            <button
              className="button"
              onClick={() => {
                setIsModal(true);
                setSelectedTag({});
              }}
            >
              <AddIcon />
            </button>
          </Flex>
        </Flex>
        {isLoading && <SkeletonProductTable />}
        {!isLoading && (
          <Flex className="table" flexDirection="column">
            <Flex className="table-head">
              <Box className="table-head__item" width="20%">
                <Text>Tag Name</Text>
              </Box>
              <Box className="table-head__item" width="10%">
                <Text>Status</Text>
              </Box>
              <Box className="table-head__item" width="60%">
                <Text>Tagged Products</Text>
              </Box>
              <Box className="table-head__item" width="10%">
                <Text>Actions</Text>
              </Box>
            </Flex>
            {tags.map((tag, idx) => {
              return (
                <Flex className="table-body" key={tag.id}>
                  <Box className="table-body__item" width="20%">
                    <Text className="text-name">{tag.tag}</Text>
                  </Box>
                  <Box className="table-body__item" width="10%">
                    <Text>{tag.isActive ? "Active" : "Inactive"}</Text>
                  </Box>
                  <Flex
                    className="table-body__item"
                    width="60%"
                    justify="center"
                  >
                    <Text
                      className="text-product"
                      onClick={() => showProductHandler(tag)}
                    >
                      Lihat
                    </Text>
                  </Flex>
                  <Flex
                    className="table-body__item"
                    width="10%"
                    display="flex"
                    align="center"
                    justify="space-between"
                  >
                    <EditIcon
                      color="#3b818d"
                      fontSize="18px"
                      mr="16px"
                      cursor="pointer"
                      onClick={() => {
                        if (tag.isActive) {
                          disableChangeHandler();
                        } else {
                          editHandler(tag);
                        }
                      }}
                    />
                    <DeleteIcon
                      color="#ff8084"
                      fontSize="18px"
                      mr="16px"
                      cursor="pointer"
                      onClick={() => {
                        if (tag.isActive) {
                          disableChangeHandler();
                        } else {
                          setIsOpenDeleteHandler(true);
                          setSelectedTag(tag);
                        }
                      }}
                    />

                    <Switch
                      margin="0"
                      size="sm"
                      mr="24px"
                      className="switch"
                      isChecked={tag.isActive}
                      onChange={(e) =>
                        changeIsActiveHandler({
                          data: tag,
                        })
                      }
                    />
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        )}
        <Pagination
          totalPage={totalPage}
          page={page}
          changePageHandler={changePageHandler}
        />
      </div>
    </div>
  );
};

export default navguard(ProductTagging);
