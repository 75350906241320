import React, { useEffect, useState } from "react";
import "./styles/popup.scss";
import { useSelector, useDispatch } from "react-redux";
import { setShowPopup } from "../../store/actions/stockAction";
import {
  Checkbox,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

import OutsideClickHandler from "react-outside-click-handler";
import {
  warehouseProductAction,
  warehouseProductUpdate,
} from "../../store/actions/warehouseAction";
import { useParams } from "react-router-dom";

const PopupModal = ({ warehouseName, fetchProductInWarehouse }) => {
  const toast = useToast();

  const productsResult = useSelector(
    (state) => state.warehouse.warehouseProductsExclude
  );
  const [products, setProducts] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [fuzzyscore] = useState(75);
  const [query, setQuery] = useState("");
  const [filteredProduct, setFilteredProduct] = useState([]);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (productsResult.length) {
      const temp = [...productsResult];

      temp.forEach((product) => {
        product.isChecked = false;
      });
      setFilteredProduct(temp);
      setProducts(temp);
    }
  }, [productsResult]);

  const closePopup = () => {
    dispatch(setShowPopup(false));
  };

  const queryHandler = (event) => {
    setQuery(event.target.value);
  };

  const applyQueryFilter = () => {
    const fuzzball = require("fuzzball");
    const tempData = [...products];
    let result = [];
    let fuzzballScore;
    if (query === "") {
      return tempData;
    } else {
      tempData.forEach((data) => {
        fuzzballScore = fuzzball.partial_ratio(
          query.toLowerCase(),
          data.name.toLowerCase()
        );
        if (fuzzballScore >= fuzzyscore) {
          result.push(data);
        }
      });
      return result;
    }
  };

  const searchHandler = () => {
    setFilteredProduct(applyQueryFilter());
  };

  const keyenterHandler = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    let isAllSelected = true;
    let idx = 0;
    while (idx < products.length && isAllSelected) {
      if (!products[idx].isChecked) {
        isAllSelected = false;
      } else {
        idx += 1;
      }
    }
    setAllChecked(isAllSelected);
  }, [products]);

  const checkedAllHandler = (event) => {
    const tempProducts = [...products];

    tempProducts.forEach((product) => {
      product.isChecked = event.target.checked;
    });

    setProducts(tempProducts);
  };

  const checkboxOnchange = (event, value) => {
    const tempProducts = [...products];

    tempProducts.forEach((product) => {
      if (product.id === value.id) {
        product.isChecked = event.target.checked;
      }
    });

    setProducts(tempProducts);
  };

  useEffect(() => {
    setFilteredProduct(applyQueryFilter());
  }, [products]);

  const compileInitiateStock = () => {
    const initiateStock = [];

    products.forEach((product) => {
      if (product.isChecked) {
        if (product.productVariantIds.length) {
          product.productVariantIds.forEach((variantId) => {
            initiateStock.push({
              productId: product.id,
              productVariantId: variantId,
              availableStock: 0,
              minSafetyStock: 0,
              qcStock: 0,
            });
          });
        } else {
          initiateStock.push({
            productId: product.id,
            availableStock: 0,
            minSafetyStock: 0,
            qcStock: 0,
          });
        }
      }
    });

    return initiateStock;
  };

  const saveProductHandler = async () => {
    const productsSelected = compileInitiateStock();
    try {
      await dispatch(
        warehouseProductUpdate({ warehouseId: id, stock: productsSelected })
      );
      toast({
        title: "Success.",
        description: "Berhasil menambahkan product.",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      await fetchProductInWarehouse();
      closePopup();
    } catch (err) {
      console.log(err);
      toast({
        title: "Error.",
        description: "Failed! silahkan coba kembali",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    dispatch(warehouseProductAction({ id, isInitiated: false }));
  }, [id, dispatch]);

  return (
    <div className="popup-bg">
      <OutsideClickHandler onOutsideClick={closePopup}>
        <div className="stock-popup">
          <div className="stock-popup-header">
            <div className="stock-popup-header__title">
              <h1>Manage Product </h1>
              <h1 style={{ margin: "0 8px" }}>| </h1>

              <h1>{warehouseName}</h1>
            </div>
            <svg
              id="close-btn"
              onClick={closePopup}
              className="close-btn"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div className="stock-popup-searchbar">
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Search2Icon color="gray.300" />
              </InputLeftElement>
              <Input
                type="text"
                variant="filled"
                placeholder="Cari Produk"
                className="search-container"
                onChange={queryHandler}
                value={query}
                onKeyPress={keyenterHandler}
              />
            </InputGroup>
          </div>
          <div className="stock-popup-productLists">
            <div
              className="stock-popup-products"
              style={{ marginBottom: "16px" }}
            >
              <Checkbox
                className="select-all__checkbox"
                isChecked={allChecked}
                onChange={checkedAllHandler}
              >
                Select All
              </Checkbox>
            </div>
            {filteredProduct.length > 0 &&
              filteredProduct.map((product) => {
                return (
                  <div className="stock-popup-products" key={product.id}>
                    <Checkbox
                      onChange={(event) => checkboxOnchange(event, product)}
                      isChecked={product.isChecked}
                    >
                      {product.name}
                    </Checkbox>
                  </div>
                );
              })}
          </div>
          <div className="button-container">
            <Button
              onClick={saveProductHandler}
              background="#ff8084"
              color="white"
              style={{ float: "right" }}
            >
              Save Product
            </Button>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default PopupModal;
