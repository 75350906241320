import React, { useEffect, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import "./style/feedback.css";
import FilterDate from "./components/filterDate";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../order/components/pagination";
import { useLocation } from "react-router-dom";
import navguard from "../auth/navguard";
import queryString from "query-string";
import Axios from "../../services/axios";
import { isEmpty } from "lodash";
import dateFormat from "dateformat";

function Feedback(props) {
  const [page, setPage] = useState(0);
  // eslint-disable-next-line
  const { search } = useLocation();
  const [dataTable, setDataTable] = useState([]);
  const [totalPage, setTotalPage] = useState();

  const columns = [
    {
      dataField: "createdAt",
      text: "Submission Date",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell) => {
        return (
          <p style={{ color: "black" }}>
            {dateFormat(cell, "dd/mm/yyyy HH:MM:ss")}
          </p>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      dataField: "saran",
      text: "Feedback",
      style: { textAlign: "justify", width: "50%" },
      headerStyle: { textAlign: "center" },
    },
  ];

  const changePageHandler = (value) => {
    setPage(value.selected);
  };

  const fetchData = async () => {
    try {
      const currentQueries = queryString.parse(search);
      const queries = queryString.stringify({
        ...currentQueries,
        page: page + 1,
        limit: 5,
        sort: "createdAt,desc",
      });
      const { data } = await Axios({
        url: `/saran/global/list?${queries}`,
        method: "GET",
      });
      setTotalPage(data.data.totalPage);
      setDataTable(data.data.saran);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    } else {
      fetchData();
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [page]);

  return (
    <Box className="help-center__feedback-container">
      <Text className="help-center__feedback-title">Help center</Text>
      <Box className="help-center__feedback-content-container">
        <Flex justifyContent="space-between">
          <Text className="help-center__feedback-content-title">
            Feedback Reseller
          </Text>
          <FilterDate />
        </Flex>
        {!isEmpty(dataTable) && (
          <ToolkitProvider
            data={dataTable}
            keyField="id"
            columns={columns}
            search
          >
            {(props) => (
              <div className="py-4 table-responsive help-center__feedback-table-container">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                  trStyle={{ backgroundColor: "white" }}
                />
              </div>
            )}
          </ToolkitProvider>
        )}
        {!isEmpty(dataTable) && (
          <Pagination
            page={page}
            changePageHandler={changePageHandler}
            totalPage={totalPage}
          />
        )}
      </Box>
    </Box>
  );
}

export default navguard(Feedback);
