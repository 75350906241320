import React, { Fragment, useState, useEffect, useCallback } from "react";
import Axios from "../../services/axios";

import "./styles/_homeBanner.scss";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  Flex,
  Spacer,
  IconButton,
  useToast,
  Input,
  Text,
  Button,
  Box,
} from "@chakra-ui/react";
import { MdAdd, MdDelete } from "react-icons/md";
import { FaMobileAlt, FaDesktop } from "react-icons/fa";

import one from "../../assets/images/pro3/1.jpg";
import Breadcrumb from "../common/breadcrumb";
import Loader from "../loader/Loader";
import DeleteImage from "./component/warning-delete";
import Articles from "./articles";
import navguard from "../auth/navguard";
import AddNewBanner from "./component/add-new-banner";
import DeleteBanner from "./component/delete-banner";
import FullPreviewModal from "./component/full-preview";

const LandingBanner = () => {
  const toast = useToast();
  const imageMaxSize = 2000000;
  const [isLoading, setIsLoading] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isImageDelete, setIsImageDelete] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const [bannerList, setBannerList] = useState([]);
  const [selectedBannerIndex, setSelectedBannerIndex] = useState(null);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const getBannerList = async () => {
    try {
      const { data } = await Axios({
        method: "GET",
        url: "/main-landing/banner/list",
      });
      let tempBannerList = [];
      if (data) {
        data.data.banners.forEach((item) => {
          tempBannerList.push({
            ...item,
            prevImagePath: item.imagePath,
            prevImageMobilePath: item.imageMobilePath,
            prevTitle: item.title,
            prevSubtitle: item.subtitle,
            prevLink: item.link,
            preview: "Desktop",
            isActive: item.isActive,
            prevIsActive: item.isActive,
            imageMobile: item.imageMobilePath,
          });
        });
      }
      tempBannerList = tempBannerList.sort((a, b) => {
        return a["title"].localeCompare(b["title"]);
      });
      setBannerList(tempBannerList);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchBanner = async () => {
      await getBannerList();
      setIsLoading(false);
    };

    setIsLoading(true);
    fetchBanner();
  }, []);

  const handleMainImageChange = (value, item, index) => {
    value.preventDefault();
    if (value.currentTarget.files.length > 0) {
      if (value.currentTarget.files.item(0).size > imageMaxSize) {
        toast({
          title: "Error.",
          description: "Mohon upload gambar berukuran kurang dari 2MB",
          status: "warning",
          position: "top",
          duration: 3000,
          isClosable: true,
        });

        value.currentTarget.value = null;
      } else {
        let reader = new FileReader();
        const image = value.target.files[0];
        let tempBannerList = [...bannerList];

        if (item.preview === "Desktop") {
          tempBannerList[index].fileImageDesktop = image;
        } else {
          tempBannerList[index].fileImageMobile = image;
        }

        reader.onload = () => {
          if (item.preview === "Desktop") {
            tempBannerList[index].imagePath = reader.result;
          } else {
            tempBannerList[index].imageMobilePath = reader.result;
          }
          setBannerList(tempBannerList);
          let componentImageUploader =
            document.getElementById("main-image-input");
          componentImageUploader.value = null;
        };

        reader.readAsDataURL(image);
      }
    }
  };

  const openDeleteImage = (banner, index) => {
    setSelectedBanner(banner);
    setSelectedBannerIndex(index);
    setIsImageDelete(true);
  };

  const closeDeleteImage = () => {
    setIsImageDelete(false);
    setSelectedBannerIndex(null);
    setSelectedBanner(null);
  };

  const deleteMainImage = () => {
    document.getElementById("main-image-input").value = null;
    let tempBannerList = [...bannerList];

    if (selectedBanner.preview === "Desktop") {
      tempBannerList[selectedBannerIndex].imagePath = one;
      tempBannerList[selectedBannerIndex].fileImageDesktop = undefined;
    } else {
      tempBannerList[selectedBannerIndex].imageMobilePath = one;
      tempBannerList[selectedBannerIndex].fileImageMobile = undefined;
    }

    setBannerList(tempBannerList);
    closeDeleteImage();
  };

  const dataBannerHandler = (e, index) => {
    let tempBannerList = [...bannerList];
    tempBannerList[index][e.target.name] = e.target.value;
    setBannerList(tempBannerList);
  };

  const changePreview = (value, index) => {
    let tempBannerList = [...bannerList];
    tempBannerList[index].preview = value;
    setBannerList(tempBannerList);
  };

  const checkIsBannerEdited = (banner) => {
    return (
      banner.prevTitle !== banner.title ||
      banner.prevSubtitle !== banner.subtitle ||
      banner.prevLink !== banner.link ||
      banner.prevImagePath !== banner.imagePath ||
      banner.prevImageMobilePath !== banner.imageMobilePath
    );
  };

  const updateValidation = () => {
    let statusValidation = {
      status: "success",
      message: "Banner berhasil disubmit",
    };
    let i = 0;
    while (statusValidation.status === "success" && i < bannerList.length) {
      if (!bannerList[i].title) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon isi nama banner";
      } else if (bannerList[i].imagePath === one) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload gambar banner";
      } else if (bannerList[i].imageMobilePath === one) {
        statusValidation.status = "warning";
        statusValidation.message = "Mohon upload gambar mobile banner";
      }

      i += 1;
    }

    return statusValidation;
  };

  const postData = async (id, data) => {
    try {
      let config = {
        method: id ? `PATCH` : `POST`,
        url: `/main-landing/banner${id ? `/${id}` : ""}`,
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };

      await Axios(config);
    } catch (error) {
      throw new Error(error);
    }
  };

  const submitChanges = async () => {
    setIsLoading(true);
    let isAllSuccess = true;
    let responseValidation = updateValidation();
    if (responseValidation.status === "success") {
      const tempBanner = [...bannerList].sort((a, b) =>
        parseInt(a.title) > parseInt(b.title) ? 1 : -1
      );

      for (let i = 0; i < tempBanner.length; i++) {
        if (checkIsBannerEdited(tempBanner[i])) {
          try {
            let data = new FormData();
            if (tempBanner[i].prevImagePath !== tempBanner[i].imagePath) {
              data.append("image", tempBanner[i].fileImageDesktop);
            }

            if (
              tempBanner[i].prevImageMobilePath !==
              tempBanner[i].imageMobilePath
            ) {
              data.append("imageMobile", tempBanner[i].fileImageMobile);
            }
            const tempTitle = i + 1;
            data.append("title", tempTitle);
            data.append("subtitle", tempBanner[i].subtitle);
            data.append("link", tempBanner[i].link);

            await postData(tempBanner[i].id, data);
          } catch (error) {
            isAllSuccess = false;
          }
        } else {
          try {
            let data = new FormData();

            const tempTitle = i + 1;
            data.append("title", tempTitle);

            await postData(tempBanner[i].id, data);
          } catch (error) {
            isAllSuccess = false;
          }
        }
      }

      if (isAllSuccess) {
        toast({
          title: "Success.",
          description: "Banner berhasil diupdate",
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error.",
          description: "Terjadi kesalahan, mohon coba beberapa saat lagi",
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      }
      await getBannerList();
    } else {
      toast({
        title: "Error.",
        description: responseValidation.message,
        status: responseValidation.status,
        position: "top",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const previewOpen = () => {
    setIsPreview(true);
  };

  const previewClose = () => {
    setIsPreview(false);
  };

  const addNewOpen = () => {
    setIsAddNew(true);
  };

  const deleteBanner = (banner) => {
    setSelectedBanner(banner);
    setIsDelete(true);
  };

  const closeDeleteBanner = () => {
    setIsDelete(false);
    setSelectedBanner(null);
  };

  useEffect(() => {
    const checkIsEdited = () => {
      let edited = false;
      if (bannerList.length) {
        let findEdited = bannerList.find((item) => checkIsBannerEdited(item));
        edited = findEdited;
      }
      setIsEdited(edited);
    };

    checkIsEdited();
  }, [bannerList]);

  const getActiveBanner = () => {
    return bannerList;
  };

  return (
    <Fragment>
      {isLoading && <Loader />}
      <Breadcrumb title="Landing Banner" parent="Content" />
      <FullPreviewModal
        previewClose={previewClose}
        isPreview={isPreview}
        getActiveBanner={getActiveBanner}
      />
      <AddNewBanner
        bannerList={bannerList}
        setIsLoading={setIsLoading}
        getBannerList={getBannerList}
        setIsAddNew={setIsAddNew}
        isAddNew={isAddNew}
        isLandingPage
      />

      <DeleteBanner
        setIsLoading={setIsLoading}
        getBannerList={getBannerList}
        closeDeleteBanner={closeDeleteBanner}
        isDelete={isDelete}
        selectedBanner={selectedBanner}
        isLandingPage
      />

      <DeleteImage
        deleteMainImage={deleteMainImage}
        closeDeleteImage={closeDeleteImage}
        isImageDelete={isImageDelete}
        selectedImage={
          selectedBanner?.preview === "Desktop"
            ? selectedBanner.imagePath
            : selectedBanner?.imageMobilePath
        }
        aspectRatio={selectedBanner?.preview === "Desktop" ? "21 / 9" : "1 / 1"}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12" md="12" lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col xl={12}>
                    <Flex>
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Landing Page Content
                      </p>
                      <Spacer />
                      <IconButton
                        mr={4}
                        variant="outline"
                        aria-label="Call Sage"
                        fontSize="20px"
                        onClick={() => addNewOpen()}
                        icon={<MdAdd />}
                      />
                      <Button
                        mr={4}
                        variant="ghost"
                        justifyContent="flex-start"
                        border={"2px solid #FF8084"}
                        color={"#FF8084"}
                        onClick={() => previewOpen()}
                      >
                        Preview
                      </Button>
                      <Button
                        variant="ghost"
                        justifyContent="flex-start"
                        backgroundColor={"#FF8084"}
                        color={"white"}
                        isDisabled={!isEdited}
                        onClick={() => submitChanges()}
                      >
                        Simpan
                      </Button>
                    </Flex>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="12" lg="12">
            {bannerList.map((banner, index) => {
              return (
                <Card key={banner.id}>
                  <CardBody>
                    <Row
                      style={{
                        marginBottom: "16px",
                        paddingBottom: "16px",
                        borderBottom: "2px solid rgba(0,0,0,0.05)",
                      }}
                    >
                      <Col sm="12" md="12" lg="12">
                        <Flex>
                          <Spacer />

                          <Button
                            variant="ghost"
                            justifyContent="flex-start"
                            backgroundColor={"#FF8084"}
                            color={"white"}
                            onClick={() => deleteBanner(banner)}
                          >
                            Hapus
                          </Button>
                        </Flex>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4" md="4" lg="4">
                        <Text
                          style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "8px",
                            color: "black",
                          }}
                        >
                          Urutan ke
                        </Text>
                        <Input
                          mb={4}
                          placeholder="Title"
                          name="title"
                          type="number"
                          onChange={(e) => dataBannerHandler(e, index)}
                          value={banner.title}
                        />
                        <Text
                          style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "8px",
                            marginTop: "16px",
                            color: "black",
                          }}
                        >
                          Link
                        </Text>
                        <Input
                          mb={4}
                          placeholder="Link"
                          name="link"
                          onChange={(e) => dataBannerHandler(e, index)}
                          value={banner.link}
                        />
                      </Col>
                      <Col sm="8" md="8" lg="8">
                        <Flex>
                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              marginBottom: "16px",
                              color: "black",
                            }}
                          >
                            {banner.preview} Preview
                          </p>
                          <Spacer />
                          {banner.preview === "Desktop" &&
                            banner.imagePath !== one && (
                              <IconButton
                                mr={4}
                                variant="outline"
                                aria-label="Call Sage"
                                fontSize="20px"
                                onClick={() => openDeleteImage(banner, index)}
                                icon={<MdDelete />}
                              />
                            )}
                          {banner.preview === "Mobile" &&
                            banner.imageMobilePath !== one && (
                              <IconButton
                                mr={4}
                                variant="outline"
                                aria-label="Call Sage"
                                fontSize="20px"
                                onClick={() => openDeleteImage(banner, index)}
                                icon={<MdDelete />}
                              />
                            )}
                          <IconButton
                            mr={4}
                            variant="outline"
                            aria-label="Call Sage"
                            fontSize="20px"
                            onClick={() => changePreview("Desktop", index)}
                            icon={<FaDesktop />}
                          />
                          <IconButton
                            mr={4}
                            variant="outline"
                            aria-label="Call Sage"
                            fontSize="20px"
                            onClick={() => changePreview("Mobile", index)}
                            icon={<FaMobileAlt />}
                          />
                        </Flex>
                        <Row>
                          <Col xl={banner.preview === "Desktop" ? 12 : 4}>
                            <div className="add-product">
                              <Row className="file-upload-product row">
                                <Col className="col">
                                  <div className="box-input-file-full">
                                    <input
                                      className="upload"
                                      style={{
                                        width: "100%",
                                        aspectRatio: `${
                                          banner.preview === "Desktop"
                                            ? "21 / 9"
                                            : "1 / 1"
                                        }`,
                                        objectFit: "cover",
                                        cursor: "pointer",
                                      }}
                                      type="file"
                                      id="main-image-input"
                                      name="img"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleMainImageChange(e, banner, index)
                                      }
                                    />
                                    <img
                                      src={
                                        banner.preview === "Desktop"
                                          ? banner.imagePath
                                          : banner.imageMobilePath
                                      }
                                      alt=""
                                      style={{
                                        width: "100%",
                                        aspectRatio: `${
                                          banner.preview === "Desktop"
                                            ? "21 / 9"
                                            : "1 / 1"
                                        }`,
                                        objectFit: "cover",
                                      }}
                                      className="img-fluid image_zoom_1 blur-up lazyloaded"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              );
            })}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default navguard(LandingBanner);
