import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import DefaultResellerList from "./components/DefaultResellerList";
import RoleBasedList from "./components/RoleBasedList";
import "./style/ListDiscount.css";
import navguard from "../auth/navguard";

function ResellerDiscount(props) {
  return (
    <Box className="reseller-discount__container">
      <Text className="reseller-discount__title">
        Reseller Discount Management
      </Text>

      <Box className="reseller-discount__table-container">
        <Text className="reseller-discount__table-title">
          Default Reseller Discount
        </Text>
        <DefaultResellerList />
      </Box>

      <Box className="reseller-discount__table-container">
        <Text className="reseller-discount__table-title">
          Role-Based Reseller Discount
        </Text>
        <RoleBasedList />
      </Box>
    </Box>
  );
}

export default navguard(ResellerDiscount);
