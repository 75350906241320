import {
  Box,
  Divider,
  Flex,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  ModalHeader,
  useToast,
  IconButton,
  Button,
} from "@chakra-ui/react";
import React, { Fragment, useEffect, useState } from "react";
import { Tabs } from "react-tabs";
import { Form, FormGroup, Input, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import "./style.css";
import { ColoredChecklistIcon, GrayChecklistIcon } from "./components/icons";
import { passStrengths } from "./components/passStrengthMeter";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  getUserDetailAction,
  cleanUserDetailAction,
  getUserCommunity,
  postUserAction,
  patchUserAction,
  clearUserResponseState,
  fetchUserBadgeProgram,
} from "../../store/actions/userAction";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import dateFormat from "dateformat";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { resetSidebarAction } from "../../store/actions/sideBarAction";
import Tooltip from "../tooltip";
import VerifyUser from "./components/verifyUser";
import { fetchResellerLevel } from "../../store/actions/resellerLevelAction";
import SectionOthers from "./components/sectionOthers";

const TabsetUser = ({ section }) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();

  const isEditPage = window.location.pathname.split("/")[2] === "edit-user";
  const [strengthMeters, setStrengthMeters] = useState([false, false, false]);
  const [password, setPassword] = useState("");
  const [userData, setUserData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [programStartDate, setProgramStartDate] = useState("");
  const [programEndDate, setProgramEndDate] = useState("");
  const [isProgramAvailable, setIsProgramAvailable] = useState(true);
  const [passwordShow, setPasswordShow] = useState(false);
  const [usedProgramList, setUsedProgramList] = useState([]);

  const userDetail = useSelector(({ users }) => users.userDetail);
  const userCommunity = useSelector(({ users }) => users.community);
  const responseCreateUser = useSelector(({ users }) => users.createResponse);
  const badgeProgramList = useSelector(({ users }) => users.badgeProgram);
  const today = new Date();

  const { resellerLevelList } = useSelector((state) => state.resellerLevel);

  useEffect(() => {
    getUserCommunity(dispatch); // eslint-disable-next-line
    clearUserResponseState(dispatch); // eslint-disable-next-line
    fetchUserBadgeProgram(dispatch, "user");
    // eslint-disable-next-line
  }, [dispatch, userDetail]);

  useEffect(() => {
    dispatch(fetchResellerLevel());
  }, []);

  useEffect(() => {
    if (!isEmpty(userDetail?.user?.resellerProgramName)) {
      const findProgram = badgeProgramList.find(
        (item) => item.name === userDetail.user.resellerProgramName
      );
      setIsProgramAvailable(!isEmpty(findProgram));
    } else {
      setIsProgramAvailable(true);
    }
  }, [badgeProgramList, userDetail?.user?.resellerProgramName]);

  useEffect(() => {
    if (
      (userData.selectedResellerStatus === "rising star" &&
        (isEmpty(userData.resellerStatus) ||
          userData.resellerStatus === "registrant")) ||
      userData.resellerStatus === "rising star"
    ) {
      setUsedProgramList([
        ...badgeProgramList.filter((item) =>
          item.name.toLowerCase().includes("early bird")
        ),
      ]);
    } else {
      setUsedProgramList(badgeProgramList);
    }
  }, [badgeProgramList, userData]);

  useEffect(() => {
    if (section === "create") {
      setIsEdit(true);
    }
  }, [section]);

  useEffect(() => {
    if (!isEmpty(userDetail) && section === "edit") {
      setUserData({
        ...userDetail.user,
        selectRestrict: userDetail?.user?.isSuspended
          ? userDetail?.user?.suspensionType
          : userDetail?.user?.isSuspended,
        selectedResellerStatus:
          userDetail.user?.onBoardingData?.selectedResellerStatus,
      });
    } else {
      setUserData({});
    }
  }, [userDetail, section]);

  useEffect(() => {
    if (search) {
      const currentQuery = queryString.parse(search);

      getUserDetailAction(dispatch, currentQuery.id); // eslint-disable-next-line
    }

    return () => {
      cleanUserDetailAction(dispatch);
    }; // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    setStrengthMeters(passStrengths(password));
  }, [password]);

  const [accountBirthDate, setAccountBirthDate] = useState("");

  useEffect(() => {
    if (userData.birthDate) {
      const dateBirthDate = new Date(userData?.birthDate);

      setAccountBirthDate(dateBirthDate);
    }

    if (userData.resellerProgramStartDate) {
      const programStartDate = new Date(userData?.resellerProgramStartDate);

      setProgramStartDate(programStartDate);
    }

    if (userData.resellerProgramEndDate) {
      const programEndDate = new Date(userData?.resellerProgramEndDate);

      setProgramEndDate(programEndDate);
    }
  }, [userData]);

  useEffect(() => {
    if (responseCreateUser?.data?.status === "success") {
      toast({
        title: "Account changed.",
        description: isEditPage
          ? "Information has been updated"
          : "We've created your account for you.",
        position: "top",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      clearUserResponseState(dispatch);
      setTimeout(function () {
        resetSidebarAction(dispatch, true);
        history.push("/users/all-user", false);
      }, 2000);
    } else if (responseCreateUser.status === "Error.") {
      toast({
        title: "Terjadi kesalahan.",
        description: `Cek kembali data yang anda input: ${responseCreateUser.message}`,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [responseCreateUser, isEditPage, history, toast]);

  // Start: variables of submit form
  const { isOpen, onOpen, onClose } = useDisclosure();

  const validateReferralMask = (referralMask) => {
    const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const isValid = specialChars.test(referralMask);
    return !isValid;
  };

  const validateForm = (data) => {
    if (
      data.fullName &&
      data.email &&
      data.phone &&
      data.selectedResellerStatus &&
      data.password
    ) {
      if (data.resellerProgramName) {
        if (
          data.resellerProgramName?.includes("Influencer") &&
          data.maskedReferralCode
        ) {
          return (
            validateReferralMask(data.maskedReferralCode) &&
            data.resellerProgramStartDate &&
            data.resellerProgramEndDate
          );
        }
        return data.resellerProgramStartDate && data.resellerProgramEndDate;
      }
      return true;
    }
    return false;
  };

  const onHandleSubmit = async () => {
    let formattedBirthDate = "";
    let maskedReferralCode = "";
    let formattedStartDate = null;
    let formattedEndDate = null;

    // Formatting birthDate
    if (accountBirthDate) {
      const dateBirthDate = new Date(accountBirthDate);
      formattedBirthDate = dateFormat(dateBirthDate, "yyyy-mm-dd");
    }

    // Formatting Start Date
    if (programStartDate) {
      const dateStartDate = new Date(programStartDate);
      formattedStartDate = dateFormat(dateStartDate, "yyyy-mm-dd");
    }

    // Formatting End Date
    if (programEndDate) {
      const dateEndDate = new Date(programEndDate);
      formattedEndDate = dateFormat(dateEndDate, "yyyy-mm-dd");
    }

    if (userData.resellerProgramName?.includes("Influencer")) {
      maskedReferralCode = userData.maskedReferralCode;
    }

    if (!strengthMeters[0] || !strengthMeters[1] || !strengthMeters[2]) {
      toast({
        title: "Terjadi kesalahan.",
        description: `Password strength belum memenuhi ketentuan`,
        position: "top",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      throw "Password strength tidak memenuhi ketentuan.";
    }

    const dataObjCreate = {
      fullName: userData.fullName,
      email: userData.email,
      phone: userData.phone,
      gender: userData.gender,
      birthDate: formattedBirthDate,
      // communityId: userData.communityId,
      // resellerProgramName: userData.resellerProgramName,
      selectedResellerStatus: userData.selectedResellerStatus,
      // resellerProgramStartDate: formattedStartDate,
      // resellerProgramEndDate: formattedEndDate,
      npwp: userData.npwp,
      nik: userData.nik,
      nikAddress: userData.nikAddress,
      password: password,
      maskedReferralCode,
    };

    const isValid = validateForm(dataObjCreate);
    if (!isValid) {
      toast({
        title: "Terjadi kesalahan.",
        description: `Mohon lengkapi data yang mandatory`,
        position: "top",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const isRequestSuccess = await postUserAction(dispatch, dataObjCreate);
    if (isRequestSuccess !== true) {
      onClose();
      toast({
        title: `Error ${isRequestSuccess.status}`,
        description: `${isRequestSuccess.data.errors[0].message}`,
        position: "top",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const onHandlePatchSubmit = () => {
    let formattedStartDate = null;
    let formattedEndDate = null;
    let maskedReferralCode = "";

    // Formatting Start Dat
    if (programStartDate) {
      const dateStartDate = new Date(programStartDate);
      formattedStartDate = dateFormat(dateStartDate, "yyyy-mm-dd");
    }

    // Formatting End Date
    if (programEndDate) {
      const dateEndDate = new Date(programEndDate);
      formattedEndDate = dateFormat(dateEndDate, "yyyy-mm-dd");
    }

    if (userData.resellerProgramName?.includes("Influencer")) {
      maskedReferralCode = userData.maskedReferralCode;
    }

    const dataObjCreate = {
      fullName: userData.fullName,
      email: userData.email,
      phone: userData.phone,
      // communityId: userData.communityId,
      // resellerProgramName: userData.resellerProgramName,
      // selectedResellerStatus: userData.selectedResellerStatus,
      // resellerProgramStartDate: formattedStartDate,
      // resellerProgramEndDate: formattedEndDate,
      maskedReferralCode,
    };

    if (userData.selectRestrict) {
      dataObjCreate.isSuspended = true;
      dataObjCreate.suspensionType = userData.selectRestrict;
    } else {
      dataObjCreate.isSuspended = false;
      dataObjCreate.suspensionType = null;
    }

    const isValid = validateEditForm(dataObjCreate);
    if (!isValid) {
      toast({
        title: "Terjadi kesalahan.",
        description: `Mohon lengkapi data yang mandatory`,
        position: "top",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    patchUserAction(dispatch, userData.id, dataObjCreate);
  };

  const validateEditForm = (data) => {
    if (data.fullName) {
      if (data.resellerProgramName) {
        return data.resellerProgramStartDate && data.resellerProgramEndDate;
      }
      return true;
    }
    return false;
  };

  const handleChangeResellerStatus = ({ event, type }) => {
    let temp = { ...userData };
    if (
      event.target.value === "rising star" &&
      !temp?.resellerProgramName?.toLowerCase().includes("early bird")
    ) {
      temp["resellerProgramName"] = null;
      setProgramStartDate(null);
      setProgramEndDate(null);
    }

    temp[type] = event.target.value;
    setUserData(temp);
  };

  const isDisabledProgram = () => {
    if (
      !isEmpty(userData.resellerStatus) &&
      userData.resellerStatus !== "registrant"
    ) {
      return !isEdit || isEmpty(userData.selectedResellerStatus);
    } else {
      return !isEdit || isEmpty(userData.selectedResellerStatus);
    }
  };
  const isSpecialProgram = () => {
    if (!isEmpty(userData)) {
      return userData?.resellerProgramName?.includes("Influencer");
    }
    return;
  };
  const changeProgram = (e) => {
    let value = e.target.value;
    if (isEmpty(value)) {
      value = null;
      setUserData({
        ...userData,
        resellerProgramName: value,
        resellerProgramStartDate: null,
        resellerProgramEndDate: null,
      });
      setProgramEndDate(null);
      setProgramStartDate(null);
    } else {
      setUserData({
        ...userData,
        resellerProgramName: value,
      });
    }
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mt={5} p={2}>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Confirm submit Data?</ModalBody>

          <ModalFooter>
            <Button
              variant="teal"
              m={1}
              onClick={isEditPage ? onHandlePatchSubmit : onHandleSubmit}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Tabs>
        <Form className="needs-validation user-add" noValidate="">
          <h4>Account Details</h4>
          <Flex mb={3}>
            {isEditPage && !isEmpty(userData) && (
              <VerifyUser userData={userData} />
            )}
          </Flex>
          <Flex mb={2}>
            <Box flex={1}>
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">
                  Full Name<span>*</span>
                </Label>
                <Input
                  className="form-control col-xl-8 col-md-7"
                  id="validationCustom0"
                  type="text"
                  required=""
                  onChange={(e) =>
                    setUserData({ ...userData, fullName: e.target.value })
                  }
                  value={userData.fullName}
                  disabled={!isEdit}
                />
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">
                  Email<span>*</span>
                </Label>
                <Input
                  className="form-control col-xl-8 col-md-7"
                  id="validationCustom0"
                  type="text"
                  required=""
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                  value={userData.email}
                  disabled={!isEdit}
                />
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">
                  Phone number<span>*</span>
                </Label>
                <Input
                  className="form-control col-xl-8 col-md-7"
                  id="validationCustom1"
                  type="number"
                  required=""
                  onChange={(e) =>
                    setUserData({ ...userData, phone: e.target.value })
                  }
                  value={userData.phone}
                  disabled={!isEdit}
                />
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">Gender</Label>
                <Box>
                  <RadioGroup
                    onChange={(e) => setUserData({ ...userData, gender: e })}
                    value={userData.gender}
                    isDisabled={isEditPage}>
                    <Stack direction="row">
                      <Radio
                        value="male"
                        colorScheme="gray"
                        disabled={isEditPage}>
                        <Text fontSize="1em">Male</Text>
                      </Radio>
                      <Radio
                        value="female"
                        colorScheme="gray"
                        disabled={isEditPage}>
                        <Text fontSize="1em">Female</Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Box>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">Birthdate</Label>
                <DatePicker
                  disabled={isEditPage}
                  selected={accountBirthDate}
                  placeholderText="yyyy-MM-dd"
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setAccountBirthDate(date)}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={new Date()}
                />
              </FormGroup>
              {/* <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">Community</Label>
                <Select
                  placeholder="Choose Community .."
                  w={250}
                  disabled={!isEdit}
                  value={userData.communityId ?? ""}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      communityId: e.target.value,
                    })
                  }>
                  {userCommunity?.communities?.map((item) => {
                    return (
                      <option key={item.code} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Select>
              </FormGroup> */}
              {isEditPage && (
                <FormGroup className="row">
                  <Label className="col-xl-3 col-md-4">Referred By</Label>
                  <Input
                    className="form-control col-xl-8 col-md-7"
                    id="validationCustom1"
                    type="text"
                    required=""
                    value={userData?.onBoardingData?.referralCode}
                    disabled
                  />
                </FormGroup>
              )}
              {/* Password */}
              {!isEditPage && (
                <>
                  <FormGroup className="row">
                    <Label className="col-xl-3 col-md-4">
                      Password<span>*</span>
                    </Label>
                    <Input
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      className="form-control col-xl-8 col-md-7"
                      id="validationCustom1"
                      type={passwordShow ? "text" : "password"}
                      required=""
                    />
                    <IconButton
                      icon={passwordShow ? <ViewIcon /> : <ViewOffIcon />}
                      onClick={() => setPasswordShow(!passwordShow)}
                    />
                  </FormGroup>
                  {/* Start of Password Strength Checker */}

                  <Box w={500} ml="24%">
                    <Flex mt={2} mb={3}>
                      {[...strengthMeters]
                        .sort((a, b) => (a > b ? -1 : 1))
                        .map((item) => (
                          <Box
                            m="0 2px"
                            flex={1}
                            className={
                              item ? "password-strength" : "password-weak"
                            }></Box>
                        ))}
                    </Flex>
                    <Flex p={1}>
                      {strengthMeters[0] ? (
                        <ColoredChecklistIcon />
                      ) : (
                        <GrayChecklistIcon />
                      )}

                      <Text
                        ml={1}
                        color={strengthMeters[0] ? "#4c4c4c" : "#808080"}>
                        Minimal 8 karakter
                      </Text>
                    </Flex>
                    <Flex p={1}>
                      {strengthMeters[1] ? (
                        <ColoredChecklistIcon />
                      ) : (
                        <GrayChecklistIcon />
                      )}
                      <Text
                        ml={1}
                        color={strengthMeters[1] ? "#4c4c4c" : "#808080"}>
                        Gunakan 1 huruf kapital (contoh: A B C D E)
                      </Text>
                    </Flex>
                    <Flex p={1}>
                      <Box>
                        {strengthMeters[2] ? (
                          <ColoredChecklistIcon />
                        ) : (
                          <GrayChecklistIcon />
                        )}
                      </Box>
                      <Text
                        ml={1}
                        color={strengthMeters[2] ? "#4c4c4c" : "#808080"}>
                        Gunakan 1 karakter selain huruf/angka (contoh: . , ? ! -
                        _ @)
                      </Text>
                    </Flex>
                  </Box>
                  {/* End of Password Strength Checker */}
                </>
              )}
              {/* End password */}
            </Box>
            <Box flex={1}>
              {isEditPage && (
                <FormGroup className="row">
                  <Label className="col-xl-3 col-md-4">Referral id</Label>
                  <Input
                    style={{ textTransform: "capitalize" }}
                    className="form-control col-xl-4 col-md-2"
                    type="text"
                    disabled
                    value={userDetail?.user?.referralCode}
                  />
                </FormGroup>
              )}
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4 d-flex align-items-center">
                  Level Entry<span>*</span>
                  <Tooltip
                    content={
                      "Level awal saat pendaftaran dan transaksi perdana"
                    }
                  />
                </Label>
                <Select
                  placeholder="Choose Level ..."
                  w={250}
                  disabled={
                    !isEdit ||
                    (isEditPage &&
                      userDetail?.user?.resellerStatus !== "registrant")
                  }
                  value={userData?.selectedResellerStatus}
                  onChange={(e) =>
                    handleChangeResellerStatus({
                      event: e,
                      type: "selectedResellerStatus",
                    })
                  }>
                  {resellerLevelList.map((data) => (
                    <option
                      value={data.resellerLevel.toLowerCase()}
                      id={data.id}>
                      {data.resellerLevel}
                    </option>
                  ))}
                </Select>
              </FormGroup>
              {section === "edit" && (
                <FormGroup className="row">
                  <Label className="col-xl-3 col-md-4 d-flex align-items-center">
                    Current Level <Tooltip content={"Level aktif reseller"} />
                  </Label>
                  <Input
                    style={{ textTransform: "capitalize" }}
                    className="form-control col-xl-4 col-md-2"
                    type="text"
                    value={userDetail?.user?.resellerStatus}
                    disabled
                  />
                </FormGroup>
              )}

              {/* <FormGroup className="row">
                <Label className="col-xl-3 col-md-4 d-flex align-items-center">
                  Badge Program
                  <Tooltip
                    content={"Program dengan skema dan benefit tertentu"}
                  />
                </Label>
                <Select
                  placeholder="Choose Program ..."
                  w={250}
                  disabled={isDisabledProgram()}
                  value={userData.resellerProgramName ?? ""}
                  onChange={(e) => changeProgram(e)}>
                  {!isProgramAvailable && (
                    <option value={userDetail?.user?.resellerProgramName}>
                      {userDetail?.user?.resellerProgramName}
                    </option>
                  )}
                  {usedProgramList.map((item, i) => (
                    <option value={item.name} key={i}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </FormGroup>
              <FormGroup className="row">
                <Flex></Flex>
                <Label className="col-xl-3 col-md-4 d-flex align-items-center">
                  Start Date
                  {userData.resellerProgramName && <span>* </span>}
                  <Tooltip
                    content={
                      "Tanggal mulai berlaku program reseller. Data digunakan sebagai scheduler"
                    }
                  />
                </Label>
                <DatePicker
                  disabled={isDisabledProgram()}
                  selected={programStartDate}
                  placeholderText="yyyy-MM-dd"
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setProgramStartDate(date)}
                  showYearDropdown="true"
                  showMonthDropdown="true"
                />
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4 d-flex align-items-center">
                  End Date{userData.resellerProgramName && <span>*</span>}{" "}
                  <Tooltip
                    content={
                      "Tanggal akhir berlaku program reseller. Data digunakan sebagai scheduler"
                    }
                  />
                </Label>
                <DatePicker
                  disabled={isDisabledProgram()}
                  selected={programEndDate}
                  placeholderText="yyyy-MM-dd"
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setProgramEndDate(date)}
                  showYearDropdown="true"
                  showMonthDropdown="true"
                />
              </FormGroup> */}
              {isSpecialProgram() && (
                <>
                  <FormGroup className="row">
                    <Label className="col-xl-3 col-md-4 d-flex align-items-center">
                      Masking Referral
                      <Tooltip
                        content={
                          "Referral custom khusus untuk program influencer dan top reseller influencer yang dapat me-masking referral id default reseller"
                        }
                      />
                    </Label>
                    <div className="col-xl-4 col-md-2 p-0">
                      <Input
                        style={{
                          textTransform: "capitalize",
                          width: "100%",
                        }}
                        type="text"
                        className="form-control"
                        value={userData?.maskedReferralCode}
                        defaultValue={userData?.referralCode}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            maskedReferralCode: e.target.value.toUpperCase(),
                          })
                        }
                      />
                      {!validateReferralMask(userData.maskedReferralCode) && (
                        <Text color="red">
                          Masukkan kode tanpa simbol dan spasi
                        </Text>
                      )}
                    </div>
                  </FormGroup>
                </>
              )}
            </Box>
          </Flex>
          <Divider borderWidth={2} />
          <Flex justifyContent={"space-between"} mb={2} mt={5}>
            <Box flex={1}>
              <h4>Personal Data</h4>
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">NPWP Number</Label>
                <Input
                  disabled={isEditPage}
                  placeholder="NPWP"
                  className="form-control col-xl-8 col-md-7"
                  id="validationCustom1"
                  type="number"
                  required=""
                  value={userData?.npwp}
                  onChange={(e) =>
                    setUserData({ ...userData, npwp: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">ID Card Number</Label>
                <Input
                  disabled={isEditPage}
                  placeholder="ID Card Number"
                  className="form-control col-xl-8 col-md-7"
                  id="validationCustom1"
                  type="number"
                  required=""
                  value={userData?.nik}
                  onChange={(e) =>
                    setUserData({ ...userData, nik: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">ID Card Name</Label>
                <Input
                  disabled={isEditPage}
                  placeholder="ID Card Name"
                  className="form-control col-xl-8 col-md-7"
                  id="validationCustom1"
                  type="text"
                  required=""
                  value={userData?.ktpName}
                  onChange={(e) =>
                    setUserData({ ...userData, ktpName: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">ID Card Address</Label>
                <Textarea
                  disabled={isEditPage}
                  placeholder="ID Card Address"
                  className="form-control col-xl-8 col-md-7"
                  id="validationCustom1"
                  type="text"
                  required=""
                  value={userData?.nikAddress}
                  onChange={(e) =>
                    setUserData({ ...userData, nikAddress: e.target.value })
                  }
                />
              </FormGroup>
            </Box>
            <Box flex={1}>
              <h4>Bank Information</h4>
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">Bank Name</Label>
                <Input
                  disabled={true}
                  placeholder="Bank Name"
                  className="form-control col-xl-8 col-md-7"
                  id="validationCustom1"
                  type="text"
                  value={userData?.bank}
                />
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">Account Name</Label>
                <Input
                  disabled={true}
                  placeholder="Account Name"
                  className="form-control col-xl-8 col-md-7"
                  id="validationCustom1"
                  type="text"
                  value={userData?.accountOwner}
                />
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-xl-3 col-md-4">Account Number</Label>
                <Input
                  disabled={true}
                  placeholder="Account Number"
                  className="form-control col-xl-8 col-md-7"
                  id="validationCustom1"
                  type="text"
                  value={userData?.accountNumber}
                />
              </FormGroup>
            </Box>
          </Flex>

          <Divider borderWidth={2} />

          {isEditPage && (
            <SectionOthers
              userData={userData}
              setUserData={setUserData}
              userDetail={userDetail?.user}
              isEdit={isEdit}
            />
          )}
        </Form>
      </Tabs>
      <div className="pull-right">
        {!isEdit && (
          <Button
            type="button"
            colorScheme="gray"
            onClick={() => setIsEdit(true)}>
            Edit
          </Button>
        )}
        {isEdit && (
          <Button type="button" colorScheme="teal" onClick={onOpen}>
            Save
          </Button>
        )}
      </div>
    </Fragment>
  );
};

export default TabsetUser;
