import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { RepeatIcon } from "@chakra-ui/icons";
import { Flex, Text, IconButton } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import "react-datepicker/dist/react-datepicker.css";
import "../style/filterDate.css";

function FilterDate(props) {
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const { search } = useLocation();
  const history = useHistory();

  const changeDateHandler = ({ event, type }) => {
    const date = `${
      event.getMonth() + 1
    }-${event.getDate()}-${event.getFullYear()}`;

    const today = `${
      new Date().getMonth() + 1
    }-${new Date().getDate()}-${new Date().getFullYear()}`;

    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify({
      ...currentQuery,
      filter_created_date_min:
        type === "min" ? date : firstDate === "" ? date : firstDate,
      filter_created_date_max:
        type === "max" ? date : lastDate === "" ? today : lastDate,
    });

    history.push(`/help-center/feedback?${queries}`);
  };

  const handleClearDate = () => {
    setFirstDate("");
    setLastDate("");

    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify(
      {
        ...currentQuery,
        filter_created_date_min: "",
        filter_created_date_max: "",
      },
      { skipEmptyString: true }
    );

    history.push(`/help-center/feedback?${queries}`);
  };

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (
      currentQuery.filter_created_date_min ||
      currentQuery.filter_created_date_max
    ) {
      setFirstDate(currentQuery.filter_created_date_min);
      setLastDate(currentQuery.filter_created_date_max);
    }
  }, [search]);
  return (
    <Flex className="help-center__feedback-datepicker-container">
      <Text className="help-center__feedback-datepicker-text">
        Submission Date
      </Text>
      <DatePicker
        selected={firstDate ? new Date(firstDate) : firstDate}
        onChange={(event) => changeDateHandler({ event, type: "min" })}
        dateFormat="dd/MM/yyyy"
        maxDate={lastDate ? new Date(lastDate) : new Date()}
      />
      -
      <DatePicker
        selected={lastDate ? new Date(lastDate) : lastDate}
        onChange={(event) => changeDateHandler({ event, type: "max" })}
        dateFormat="dd/MM/yyyy"
        minDate={new Date(firstDate)}
        maxDate={new Date()}
      />
      <IconButton
        colorScheme="teal"
        variant="outline"
        onClick={handleClearDate}
        icon={<RepeatIcon />}
      />
    </Flex>
  );
}

export default FilterDate;
