import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";

function ModalConfirmation({
  isOpen,
  onClose,
  handleClick,
  content,
  buttonContent,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent padding="1.5em">
        <Text align="center" fontSize="18px">
          {content}
        </Text>
        <Flex justifyContent="center" mt="1em">
          <Button
            onClick={handleClick}
            bg={"#FF8084"}
            color="#ffff"
            mr="4px"
            width="40%"
          >
            {buttonContent}
          </Button>
          <Button onClick={onClose} width="40%" variant="ghost">
            Cancel
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}

export default ModalConfirmation;
