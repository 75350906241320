import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Flex, Heading, Input, Box, Textarea } from "@chakra-ui/react";

const ProductDataGeneral = ({ product, changeHandler }) => {
  const [dataDetail, setDataDetail] = useState({
    weight: product.weight ? product.weight : "",
    netto: product.netto ? product.netto : "",
    height: product.height ? product.height : "",
    length: product.length ? product.length : "",
    width: product.width ? product.width : "",
    productShape: product.productShape ?? "",
    ingredient: product.ingredient ?? "",
  });

  const [productSecondaryPackaging, setProductSecondaryPackaging] = useState(
    product.productSecondaryPackaging ? product.productSecondaryPackaging : ""
  );
  const [secondaryLength, setSecondaryLength] = useState("");
  const [secondaryWidth, setSecondaryWidth] = useState("");
  const [secondaryHeight, setSecondaryHeight] = useState("");

  useEffect(() => {
    if (product.productSecondaryPackaging) {
      const secondarySplitted = product.productSecondaryPackaging
        .substring(0, product.productSecondaryPackaging.length - 3)
        .split(" x ");
      setSecondaryLength(secondarySplitted[0]);
      setSecondaryWidth(secondarySplitted[1]);
      setSecondaryHeight(secondarySplitted[2]);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setProductSecondaryPackaging(
      `${secondaryLength ?? "-"} x ${secondaryWidth ?? "-"} x ${
        secondaryHeight ?? "-"
      } mm`
    );
  }, [secondaryLength, secondaryWidth, secondaryHeight]);

  useEffect(() => {
    changeHandler({
      target: {
        name: "productSecondaryPackaging",
        value: productSecondaryPackaging,
        type: "text",
      },
    });
    // eslint-disable-next-line
  }, [productSecondaryPackaging]);

  const dataDetailHandler = (e) => {
    const temp = { ...dataDetail };
    if (e.target.name !== "productShape") {
      temp[e.target.name] = parseFloat(e.target.value);
    } else {
      temp[e.target.name] = e.target.value;
    }
    setDataDetail(temp);
    changeHandler(e);
  };

  return (
    <>
      <Row style={{ padding: "16px" }}>
        <Col
          xl={3}
          style={{
            marginBottom: "24px",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Weight in Kg
          </p>
          <Input
            type="number"
            placeholder="Weight in Kg"
            name="weight"
            onChange={(e) => dataDetailHandler(e)}
            value={dataDetail.weight}
          />
        </Col>
        <Col
          xl={3}
          style={{
            marginBottom: "24px",
          }}
        ></Col>
        <Col
          xl={3}
          style={{
            marginBottom: "24px",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Netto
          </p>
          <Flex>
            <Input
              type="number"
              placeholder="Netto"
              name="netto"
              onChange={(e) => dataDetailHandler(e)}
              value={dataDetail.netto}
            />
          </Flex>
        </Col>
        <Col
          xl={12}
          style={{
            marginBottom: "8px",
          }}
        >
          <Heading
            size="md"
            style={{
              color: "black",
              textTransform: "capitalize",
            }}
          >
            Packaging Primer
          </Heading>
        </Col>
        <Col
          xl={4}
          style={{
            marginBottom: "8px",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Panjang in mm
          </p>
          <Input
            type="number"
            placeholder="Panjang in mm"
            name="length"
            onChange={(e) => dataDetailHandler(e)}
            value={dataDetail.length}
          />
        </Col>
        <Col
          xl={4}
          style={{
            marginBottom: "8px",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Lebar in mm
          </p>
          <Input
            type="number"
            placeholder="Lebar in mm"
            name="width"
            onChange={(e) => dataDetailHandler(e)}
            value={dataDetail.width}
          />
        </Col>
        <Col
          xl={4}
          style={{
            marginBottom: "8px",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Tinggi in mm
          </p>
          <Input
            type="number"
            placeholder="Tinggi in mm"
            name="height"
            onChange={(e) => dataDetailHandler(e)}
            value={dataDetail.height}
          />
        </Col>
        <Col
          xl={12}
          style={{
            marginBottom: "8px",
            marginTop: "16px",
          }}
        >
          <Heading
            size="md"
            style={{
              color: "black",
              textTransform: "capitalize",
            }}
          >
            Packaging Sekunder
          </Heading>
        </Col>
        <Col
          xl={4}
          style={{
            marginBottom: "8px",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Panjang in mm
          </p>
          <Input
            type="number"
            placeholder="Panjang in mm"
            name="length"
            onChange={(e) => setSecondaryLength(e.target.value)}
            value={secondaryLength}
          />
        </Col>
        <Col
          xl={4}
          style={{
            marginBottom: "8px",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Lebar in mm
          </p>
          <Input
            type="number"
            placeholder="Lebar in mm"
            name="width"
            onChange={(e) => setSecondaryWidth(e.target.value)}
            value={secondaryWidth}
          />
        </Col>
        <Col
          xl={4}
          style={{
            marginBottom: "8px",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Tinggi in mm
          </p>
          <Input
            type="number"
            placeholder="Tinggi in mm"
            name="height"
            onChange={(e) => setSecondaryHeight(e.target.value)}
            value={secondaryHeight}
          />
        </Col>

        <Col
          xl={4}
          style={{
            marginBottom: "8px",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
              color: "black",
            }}
          >
            Bentuk Bahan
          </p>
          <Input
            type="text"
            placeholder="Bentuk Bahan"
            name="productShape"
            onChange={(e) => dataDetailHandler(e)}
            value={dataDetail.productShape}
          />
        </Col>
        <Col
          xl={12}
          style={{
            marginBottom: "8px",
          }}
        >
          <Flex>
            <Box flex={1}>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginTop: "16px",
                  marginBottom: "8px",
                  color: "black",
                }}
              >
                Ingredients
              </p>

              <Textarea
                placeholder="Ingredients"
                name="ingredient"
                mb={2}
                mt={1}
                onChange={(e) => dataDetailHandler(e)}
                value={product.ingredient}
              />
            </Box>
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default ProductDataGeneral;
