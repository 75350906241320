import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, Container, Input } from "reactstrap";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Select,
  useToast,
} from "@chakra-ui/react";
import { getUsersAction } from "../../store/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import SummarizeData from "./components/summarizeData";
import AllFilters from "./components/allFilters";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import BootstrapTable from "react-bootstrap-table-next";
import { intersection, isEmpty, find } from "lodash";
import dateFormat from "dateformat";
import Pagination from "../order/components/pagination";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { CheckIcon } from "@chakra-ui/icons";
import VerifyUserModal from "./components/verifyUserModal";
import Axios from "../../services/axios";
import { FcLock, FcOk } from "react-icons/fc";

const ListUser = () => {
  const { search } = useLocation();
  const currentQuery = queryString.parse(search);
  const users = useSelector(({ users }) => users.users);
  const userAll = useSelector(({ users }) => users.userAll);

  // Start Bulk Export Variables
  const [bulkData, setBulkData] = useState([]);
  const [bulkAction, setBulkAction] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [limit] = useState(10);
  const [sort, setSort] = useState(currentQuery.sort || "createdAt,desc");
  const [selectedRow, setSelectedRow] = useState({});
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpenModalVerify, setIsOpenModalVerify] = useState(false);
  const [userDetailData, setUserDetailData] = useState({});
  const toast = useToast();

  const populateDataCSV = () => {
    const populated = populateData();
    setBulkData(populated);
  };

  const exportToXLS = () => {
    const populated = populateData();

    const ws = XLSX.utils.json_to_sheet(populated, { skipHeader: 1 });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: ".xlsx" });

    FileSaver.saveAs(data, `${userFileNameHandler()}.xlsx`);
  };

  const changeBulkHandler = (value) => {
    setBulkAction(value);
  };

  const objToArrSelectedRow = () => {
    let result = [];
    for (const key in selectedRow) {
      result = [...result, ...selectedRow[key]];
    }
    return result;
  };

  const handleManualVerification = async () => {
    const datas = objToArrSelectedRow();
    if (!isEmpty(datas)) {
      try {
        for (let i = 0; i < datas.length; i++) {
          await Axios({
            url: `/user-migration-verification/send-verification/${datas[i]}`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          });
        }
        toast({
          title: "Success.",
          description: "Berhasil mengirim verifikasi.",
          status: "success",
          position: "top",
          duration: 1500,
          isClosable: true,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      toast({
        title: "Warning.",
        description: "Harap memilih satu data terlebih dahulu.",
        status: "warning",
        position: "top",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  const populateData = () => {
    let selectedData = [
      [
        "Name",
        "Reseller ID",
        "Referrer by",
        "Email",
        "No Telp",
        "Level Reseller",
        "Level Entry",
        "Badge Program",
        "Gender",
        "Tanggal Lahir",
        "Komunitas",
        "Registered Date",
        "Nomor KTP",
        "Nama KTP",
        "Alamat KTP",
        "Nomor NPWP",
      ],
    ];

    let temp = [];
    if (!isEmpty(selectedRow)) {
      Object.keys(selectedRow).forEach((page) => {
        selectedRow[page].forEach((userId) => {
          const findData = find(userAll, { id: userId });
          if (findData) {
            temp.push([
              findData.name,
              findData.referralCode,
              findData.onBoardingData.referralCode,
              findData.email || "-",
              findData.phone || "-",
              findData.resellerStatus === "registrant"
                ? "inactive"
                : findData.resellerStatus,
              findData.onBoardingData.selectedResellerStatus || "-",
              findData.resellerProgramName || "-",
              findData.gender || "-",
              findData.birthDate || "-",
              findData.community?.name || "-",
              dateFormat(findData.createdAt, "dd/mm/yyyy HH:MM:ss"),
              findData.nik || "-",
              findData.ktpName || "-",
              findData.nikAddress || "-",
              findData.npwp || "-",
            ]);
          }
        });
      });
    } else {
      temp = userAll.map((user) => {
        return [
          user.name,
          user.referralCode,
          user.onBoardingData.referralCode,
          user.email || "-",
          user.phone || "-",
          user.resellerStatus === "registrant"
            ? "inactive"
            : user.resellerStatus,
          user.onBoardingData.selectedResellerStatus || "-",
          user.resellerProgramName || "-",
          user.gender || "-",
          user.birthDate || "-",
          user.community?.name || "-",
          dateFormat(user.createdAt, "dd/mm/yyyy HH:MM:ss"),
          user.nik || "-",
          user.ktpName || "-",
          user.nikAddress || "-",
          user.npwp || "-",
        ];
      });
    }

    selectedData = [...selectedData, ...temp];

    return selectedData;
  };

  const userFileNameHandler = () => {
    const date = new Date();

    const today = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    return `Userlist - ${today}`;
  };
  // End Bulk Export Variables

  const handleOpenVerifyModal = (userData) => {
    setUserDetailData(userData);
    setIsOpenModalVerify(true);
  };

  const handleCloseVerifyModal = () => {
    setUserDetailData({});
    setIsOpenModalVerify(false);
  };

  const columns = [
    {
      dataField: "follow",
      formatter: (cell, row) => <Checkbox value={row.id} />,
      headerFormatter: () => (
        <Checkbox onChange={checkAllCheckbox} isChecked={isCheckAll} />
      ),
    },
    {
      dataField: "referralCode",
      text: "Reseller Id",
      style: { textAlign: "left" },
      headerStyle: { textAlign: "left" },
      formatter: (cell, row) => {
        return (
          <p
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => goToDetailHandler(row.id)}>
            {cell}
          </p>
        );
      },
    },
    {
      dataField: "fullName",
      text: "Name",
      style: { textAlign: "left" },
      headerStyle: { textAlign: "left" },
      formatter: (cell, row) => (
        <Flex align="center" justify="space-between" minWidth="150px">
          <p
            style={{ color: "black", cursor: "pointer", marginRight: "6px" }}
            onClick={() => goToDetailHandler(row.id)}>
            {cell}
          </p>
          {row?.isSuspended ? <FcLock size="18px" /> : <FcOk size="18px" />}
        </Flex>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      style: { textAlign: "left" },
      headerStyle: { textAlign: "left" },
      formatter: (cell, row) => (
        <p
          style={{ color: "black", cursor: "pointer" }}
          onClick={() => goToDetailHandler(row.id)}>
          {cell}
        </p>
      ),
    },
    {
      dataField: "phone",
      text: "Phone",
      style: { textAlign: "left" },
      headerStyle: { textAlign: "left" },
      formatter: (cell, row) => (
        <p
          style={{ color: "black", cursor: "pointer" }}
          onClick={() => goToDetailHandler(row.id)}>
          {cell}
        </p>
      ),
    },
    {
      dataField: "resellerStatus",
      text: "Level",
      style: { textAlign: "left" },
      headerStyle: { textAlign: "left" },
      formatter: (cell, row) => (
        <p
          style={{ color: "black", cursor: "pointer" }}
          onClick={() => goToDetailHandler(row.id)}>
          {cell.split(" ").map((item) => (
            <span style={{ textTransform: "capitalize" }}>
              {item === "registrant" ? "Inactive" : item}{" "}
            </span>
          ))}
        </p>
      ),
    },

    {
      dataField: "personalVerificationStatus",
      text: "Pesonal Data",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p
          style={{ color: "black", cursor: "pointer" }}
          onClick={() => handleOpenVerifyModal(row)}>
          {cell}
        </p>
      ),
    },
    {
      dataField: "resellerProgramName",
      text: "Badge Program",
      style: { textAlign: "left" },
      headerStyle: { textAlign: "left" },
      formatter: (cell, row) => (
        <p
          style={{ color: "black", cursor: "pointer" }}
          onClick={() => goToDetailHandler(row.id)}>
          {cell ? cell : "None"}
        </p>
      ),
    },
    {
      dataField: "",
      text: "Complete",
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      formatter: (cell, row) => (
        <p
          style={{ color: "black", cursor: "pointer" }}
          onClick={() => goToDetailHandler(row.id)}>
          {!isEmpty(row.email) && !isEmpty(row.phone) && (
            <CheckIcon color="#51AF5D" />
          )}
        </p>
      ),
    },
    {
      dataField: "lastLogin",
      text: "Last Login",
      style: { textAlign: "left" },
      sort: true,
      headerStyle: { textAlign: "left" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>
          {cell ? dateFormat(cell, "dd/mm/yyyy HH:MM:ss") : "not logged in"}
        </p>
      ),
    },
    {
      dataField: "createdAt",
      text: "Registered Date",
      style: { textAlign: "left" },
      sort: true,
      headerStyle: { textAlign: "left" },
      formatter: (cell, row) => (
        <p style={{ color: "black" }}>
          {dateFormat(cell, "dd/mm/yyyy HH:MM:ss")}
        </p>
      ),
    },
  ];

  useEffect(() => {
    getUsersAction(dispatch, "", "all"); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (
        currentQuery.only_show_unverified !== null &&
        currentQuery.only_show_unverified !== undefined
      ) {
        if (!currentQuery.only_show_unverified) {
          currentQuery.only_show_unverified = true;
        }
      } else {
        delete currentQuery.only_show_unverified;
      }

      if (
        currentQuery.only_show_uncompleted_data !== null &&
        currentQuery.only_show_uncompleted_data !== undefined
      ) {
        if (!currentQuery.only_show_uncompleted_data) {
          currentQuery.only_show_uncompleted_data = true;
        }
      } else {
        delete currentQuery.only_show_uncompleted_data;
      }

      if (!currentQuery.sort) {
        currentQuery.sort = "createdAt,desc";
      }

      const queriesForAllData = queryString.stringify(
        {
          ...currentQuery,
        },
        {
          skipEmptyString: true,
        }
      );

      const queries = queryString.stringify(
        {
          ...currentQuery,
          limit: limit,
          page: page + 1,
        },
        {
          skipEmptyString: true,
        }
      );
      const paginationData = await getUsersAction(dispatch, queries);
      setTotalPage(paginationData?.totalPage);
      await getUsersAction(dispatch, queriesForAllData, "all");
    };
    fetchData();
    // eslint-disable-next-line
  }, [page, search]);

  const handleConfirmQuery = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const queries = queryString.stringify(
        { ...currentQuery, q: searchQuery },
        { skipEmptyString: true }
      );
      history.push(`/users/all-user?${queries}`);
    }
  };

  const changeEventHandler = (arr) => {
    const obj = { ...selectedRow };
    let temp = obj[page] || [];
    if (temp.length < arr.length) {
      temp.push(arr[arr.length - 1]);
    } else {
      temp = intersection([...temp], [...arr]);
    }
    obj[page] = temp;
    setSelectedRow(obj);
    setIsCheckAll(temp.length === users?.length);
  };

  const checkAllCheckbox = (e) => {
    const obj = {};
    if (e.target.checked) {
      const temp = users.map((item) => item.id);
      obj[page] = temp;
      setSelectedRow({ ...selectedRow, ...obj });
      setIsCheckAll(true);
    } else {
      obj[page] = [];
      setSelectedRow({ ...selectedRow, ...obj });
      setIsCheckAll(false);
    }
  };

  const goToDetailHandler = (id) => {
    history.push(`/users/edit-user/?id=${id}`);
  };

  const changePageHandler = (value) => {
    setPage(value.selected);
  };

  return (
    <Fragment>
      <Breadcrumb title="User List" parent="Users" />

      <Container fluid={true}>
        <Card>
          <SummarizeData />
          <CardBody>
            <AllFilters setSort={setSort} sort={sort} />
            <Flex w="100%">
              <Input
                type="text"
                className="form-control"
                style={{
                  marginRight: "8px",
                }}
                placeholder="Search using reseller id, email, or phone number ..."
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => handleConfirmQuery(e)}
              />
            </Flex>
            <div className="clearfix"></div>
            <Box>
              <ToolkitProvider
                data={users}
                keyField="partner_name"
                columns={columns}>
                {(props) => (
                  <div className="py-4 table-responsive list-order__table-container">
                    <CheckboxGroup
                      colorScheme="green"
                      defaultValue={selectedRow[page] ? selectedRow[page] : []}
                      onChange={changeEventHandler}
                      value={selectedRow[page] ? selectedRow[page] : []}>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        trStyle={{ backgroundColor: "white" }}
                      />
                    </CheckboxGroup>
                  </div>
                )}
              </ToolkitProvider>
              {!isEmpty(users) && (
                <Pagination
                  page={page}
                  totalPage={totalPage}
                  changePageHandler={changePageHandler}
                />
              )}
            </Box>

            <Flex>
              <Select
                width={"200px"}
                mr={"1rem"}
                onChange={(event) => changeBulkHandler(event.target.value)}
                value={bulkAction}>
                <option value="">Bulk Action</option>
                <option value="csv">Export to CSV</option>
                <option value="xls">Export to XLSX</option>
                <option value="manualVerification">Manual Verification</option>
              </Select>
              {bulkAction === "csv" ? (
                <CSVLink
                  data={bulkData}
                  asyncOnClick={true}
                  onClick={populateDataCSV}
                  target="_blank"
                  filename={userFileNameHandler()}>
                  <Button colorScheme="teal" size="md" ml="4px">
                    Download CSV
                  </Button>
                </CSVLink>
              ) : bulkAction === "xls" ? (
                <Button
                  colorScheme="teal"
                  size="md"
                  ml="4px"
                  onClick={exportToXLS}>
                  Download Excel
                </Button>
              ) : bulkAction === "manualVerification" ? (
                <Button
                  onClick={handleManualVerification}
                  colorScheme="teal"
                  size="md"
                  ml="4px">
                  Send Manual Verification
                </Button>
              ) : null}
            </Flex>
          </CardBody>
        </Card>
      </Container>
      <VerifyUserModal
        dataDetail={userDetailData}
        isOpen={isOpenModalVerify}
        onClose={handleCloseVerifyModal}
        page={page}
        searchQuery={searchQuery}
        limit={limit}
      />
    </Fragment>
  );
};

export default ListUser;
