import { Flex, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

function FilterType({ url }) {
  const history = useHistory();
  const { search } = useLocation();
  const [pointType, setPointType] = useState("");

  const changeFilterType = (event) => {
    setPointType(event.target.value);
    const currentQueries = queryString.parse(search);
    const queries = queryString.stringify(
      {
        ...currentQueries,
        filter_point_type: event.target.value,
      },
      { skipEmptyString: true }
    );

    history.push(`${url}?${queries}`);
  };

  useEffect(() => {
    const currentQueries = queryString.parse(search);
    if (currentQueries.filter_point_type) {
      setPointType(currentQueries.filter_point_type);
    }
  }, [search]);

  return (
    <Flex align={"center"}>
      <Text mr={"10px"} fontSize="16px" color={"black"}>
        Filter by Type
      </Text>
      <Select width="240px" onChange={changeFilterType} value={pointType}>
        <option value={""}>(Choose Type)</option>
        <option value="redemption">Redemption</option>
        <option value="collection">Collection</option>
      </Select>
    </Flex>
  );
}

export default FilterType;
