import Axios from "../../services/axios";
import {
  SET_PRODUCT_REVIEW_APPROVAL,
  SET_PRODUCT_REVIEW_DATA,
  SET_REVIEWED_PRODUCT_DATA,
} from "../actionTypes";

const setProductReviewApproval = (data) => {
  return { type: SET_PRODUCT_REVIEW_APPROVAL, payload: data };
};

export const patchProductReviewApproval = async (dispatch, id, isApproved) => {
  try {
    const { data } = await Axios({
      method: "PATCH",
      url: `/product-review/setApproval/${id}`,
      data: isApproved
        ? {
            isVisible: true,
            isReviewed: true,
          }
        : {
            isVisible: false,
            isReviewed: true,
          },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    dispatch(setProductReviewApproval(data));
  } catch (err) {
    console.error("[Approval Error]:", err);
    throw new Error("Failed");
  }
};

export const patchProductReviewPublished = async (dispatch, id, isVisible) => {
  try {
    const { data } = await Axios({
      method: "PATCH",
      url: `/product-review/setApproval/${id}`,
      data: {
        isVisible: isVisible,
        isReviewed: true,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    dispatch(setProductReviewApproval(data));
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const fetchReviewListData = async (
  dispatch,
  slug,
  page,
  sort,
  limit,
  filterByRating,
  filterByUserType,
  panelNum
) => {
  try {
    const { data } = await Axios({
      url: `/product/${slug}/review?page=${
        page + 1
      }&limit=${limit}&sort=createdAt,${sort}${
        filterByRating.length ? `&filterRating=${filterByRating}` : ""
      }&filterReviewerType=${filterByUserType}&filterIsReviewed=${
        panelNum !== 0
      }&filterIsVisible=${panelNum !== 0 && false | true}`,
      method: "GET",
    });

    dispatch({ type: SET_PRODUCT_REVIEW_DATA, payload: data });
  } catch (e) {
    console.error("[error]:", e);
    throw e;
  }
};

export const fetchUnreviewedData = async (dispatch, slug) => {
  try {
    const { data } = await Axios({
      url: `/product/${slug}/review?filterIsReviewed=false`,
      method: "GET",
    });

    dispatch({ type: SET_REVIEWED_PRODUCT_DATA, payload: data });
  } catch (e) {
    console.error("Error: ", e);
    throw e;
  }
};
