import { Select, Flex, Text } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useEffect, useState } from "react";
const FilterSort = () => {
  const history = useHistory();
  const [sort, setSort] = useState("orderDate,desc");
  const { search } = useLocation();

  const changeSortHandler = (event) => {
    setSort(event.target.value);
    const currentQuery = queryString.parse(search);
    const queries = queryString.stringify({
      ...currentQuery,
      sort: event.target.value,
    });

    history.push(`/order-list?${queries}`);
  };

  useEffect(() => {
    const currentQuery = queryString.parse(search);
    if (currentQuery.sort) {
      setSort(currentQuery.sort);
    }
  }, [search]);

  return (
    <Flex align="center">
      <Text mr="16px" color="black" fontSize="16px">
        Sort By
      </Text>
      <Select width="120px" onChange={changeSortHandler} value={sort}>
        <option value="orderDate,desc">Terbaru</option>
        <option value="orderDate,asc">Terlama</option>
        <option value="totalPayment,asc">Terkecil</option>
        <option value="totalPayment,desc">Terbesar</option>
      </Select>
    </Flex>
  );
};

export default FilterSort;
