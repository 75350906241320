import {
  POST_GROWTH_POINT,
  SET_EMPTY_GP_USER,
  SET_GP_DETAIL_USER,
  SET_GP_USER_BY_REFERRAL,
  SET_GP_USER_TOTAL_GP,
  SET_GROWTH_POINT,
  SET_GROWTH_POINT_LOG,
} from "../actionTypes";
import Axios from "../../services/axios";

export const fetchGrowthPoint = async (dispatch, queries) => {
  try {
    const { data } = await Axios({
      url: `/admin/user/list?${queries}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    dispatch({
      type: SET_GROWTH_POINT,
      payload: {
        users: data.data.users,
        totalPage: data.data.totalPage,
        totalData: data.data.totalData,
      },
    });
  } catch (error) {
    console.error("[ERROR]", error);
  }
};

export function fetchGrowthPointLog(queries, userId) {
  return async (dispatch) => {
    try {
      const { data } = await Axios({
        url: `/admin/user/${userId}/growth-point?${queries}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      dispatch({
        type: SET_GROWTH_POINT_LOG,
        payload: {
          growthPointLog: data.data.growthPoint,
          totalData: data.data.totalData,
          totalPage: data.data.totalPage,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
}

export const setGpUser = (dispatch, values) => {
  try {
    dispatch({
      type: SET_GP_DETAIL_USER,
      payload: values,
    });
  } catch (err) {
    console.error("[ERROR] error set gp detail user", err);
  }
};

export const fetchGpTotal = async (dispatch, id) => {
  try {
    const { data } = await Axios({
      url: `/admin/user/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    dispatch({
      type: SET_GP_USER_TOTAL_GP,
      payload: data.data.user,
    });
  } catch (err) {
    console.error("[ERROR]error fetch Growth Point Total: ", err);
  }
};

export const postGrowthPoint = async (dispatch, postData) => {
  try {
    const { data } = await Axios({
      url: `/growth-point`,
      method: "POST",
      data: postData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    dispatch({
      type: POST_GROWTH_POINT,
      payload: data.data.growthPointId,
    });
  } catch (err) {
    return { message: "[ERROR]error fetch Growth Point Total" };
  }
};

export const setUserByReferral = async (dispatch, refCode) => {
  try {
    const { data } = await Axios({
      url: `/admin/user/byReferralId/${refCode}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    dispatch({
      type: SET_GP_USER_BY_REFERRAL,
      payload: data.data.user,
    });

    return data.data.user;
  } catch (err) {
    return { message: "[ERROR]error fetch Growth Point Total" };
  }
};

export const emptyGpUser = (dispatch) => {
  try {
    dispatch({
      type: SET_EMPTY_GP_USER,
      payload: {},
    });
  } catch (err) {
    console.error("[ERROR] error set gp detail user", err);
  }
};
