import { CREATE_FAQ, SET_FAQ_LIST } from "../actionTypes";
import Axios from "../../services/axios";

export const getFaqsAction = async (dispatch, queries) => {
  try {
    const { data } = await Axios({
      url: `/admin/faq/list?limit=10&${queries}&sort=order,asc`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    dispatch({
      type: SET_FAQ_LIST,
      payload: data.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const createFaqAction = async (dispatch, valueData) => {
  try {
    const { data } = await Axios({
      url: `/admin/faq`,
      method: "POST",
      data: valueData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    dispatch({
      type: CREATE_FAQ,
      payload: data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const editFaqAction = async (faqId, valueData) => {
  try {
    await Axios({
      url: `/admin/faq/${faqId}`,
      method: "PUT",
      data: valueData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const moveQuestionUp = async (faqId) => {
  try {
    await Axios({
      url: `/admin/faq/${faqId}/up`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const moveQuestionDown = async (faqId) => {
  try {
    await Axios({
      url: `/admin/faq/${faqId}/down`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const toggleQuestion = async (faqId) => {
  try {
    await Axios({
      url: `/admin/faq/${faqId}/toggle`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const deleteQuestion = async (faqId) => {
  try {
    await Axios({
      url: `/admin/faq/${faqId}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};
